import { MessageAttachmentModelBody } from '../../api/messaging/clientMessagingAPI';
import { FormValuesModel as CreateThreadFormModel } from '../../components/ModalDialogs/TemplateModalDialogs/Messaging/SendMessageModal/InnerForm';

export interface NewThreadBodyModel {
  category: number;
  subject: string;
  body: string;
  to: MessageRecipient[];
  attachments?: MessageAttachmentModelBody[];
}

export interface MessageRecipient {
  id: string;
  name: string;
}

const clientMessagingAdapter = {
  generateNewMessageBody: (
    values: CreateThreadFormModel,
  ): NewThreadBodyModel => {
    return {
      category: values.categoryId as number,
      subject: String(values.subject || '').trim(),
      body: String(values.message || '').trim(),
      attachments: values.attachments?.map((attachment) => {
        return {
          documentId: attachment.id,
          contentType: attachment.file?.contentType || '',
          fileId: attachment.file?.id || '',
          name: attachment.file?.name || '',
        };
      }),
      to: values.recipients,
    };
  },
};

export { clientMessagingAdapter };
