import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { FormikHelpers } from 'formik';
import { IKeyRelationshipTemplate } from '../../../../../typings/application/key-relationship-templates';
import { newOwnershipValidationSchema } from '../../../../../validations/accountManagement/clientProfile';
import { FormValuesModel as ContactShortInfoFormValues } from 'components/Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as OrganizationShortInfoFormValues } from 'components/Forms/TemplateForms/CRM/OrganizationShortInfoForm';

// constants
import { OnboardingEntryTypes } from '../../../../../enums/onboarding/crm';

// components
import Form from '@core_components/Form';
import Button from '../../../../DesignSystem/Core/Button';
import InnerForm from './InnerForm';
import SubmitButton from '../../../../Buttons/SubmitButton';
import { Col, Row } from 'antd';

interface IProps {
  initialFormValues: FormValuesModel;
  onCancel: () => void;
  onSubmit: (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;
}

export type ExistingOwnershipModel = {
  id: string;
  type: OnboardingEntryTypes;

  keyRelationshipTemplate: {
    id: string;
    name: string;
  } | null;

  contact: {
    firstName: string;
    middleName: string;
    lastName: string;
  } | null;

  organization: {
    name: string;
  } | null;
};

export type ExistingEntryModel = {
  id: string | null;

  type: OnboardingEntryTypes | null;

  keyRelationshipTemplate: {
    id: string;
    name: string;
  } | null;

  contact: {
    firstName: string;
    lastName: string;
    middleName: string;
  } | null;

  organization: {
    name: string;
  } | null;
};

export interface FormValuesModel {
  isFromExistingEntry: boolean | null;

  template: {
    id: string | null;
    visibleFor: OnboardingEntryTypes | null;
    template: IKeyRelationshipTemplate | null;
  };

  existingEntry: ExistingEntryModel;
  existingEntries: ExistingOwnershipModel[];
  clientGroupEntryType: OnboardingEntryTypes;

  contact: ContactShortInfoFormValues | null;
  organization: OrganizationShortInfoFormValues | null;
}

const OwnershipRelationshipForm = ({
  initialFormValues,
  onSubmit,
  onCancel,
}: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      confirmExitWithoutSaving
      initialValues={initialFormValues}
      validationSchema={newOwnershipValidationSchema}
      renderForm={
        <>
          <InnerForm />

          <Row justify="end" gutter={[16, 16]}>
            <Col>
              <StyledButton size="large" onClick={onCancel}>
                {t('cancel', { ns: 'common' })}
              </StyledButton>
            </Col>

            <Col>
              <StyledSubmitButton type="primary">
                {t('add', { ns: 'common' })}
              </StyledSubmitButton>
            </Col>
          </Row>
        </>
      }
    />
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`;

export default OwnershipRelationshipForm;
