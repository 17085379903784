import * as Yup from 'yup';
import { NULLABLE_STRING_VALIDATION_RULE } from 'validations/common';

export const UserPermissionsValidationSchema = Yup.object().shape({
  accountPermissions: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'onboarding:personal_info_modal.form_fields.account_permissions',
    },
  }),
  adminPermissionType: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'onboarding:personal_info_modal.form_fields.account_administrator',
    },
  }),
});
