import ApiConfig from '../config/api';
import { IUploadFile } from '../typings/documents/documents';
import { generateUniqId } from './utils';
import { SessionHelpers } from './session';
import { LocalStorageHelpers } from './storages/localStorage';
import { MAX_UPLOAD_DOCUMENT_SIZE, UploadStatus } from '../constants/documents';

export interface IChunkHash {
  [key: string]: IChunkItem;
}

export interface IChunkItem {
  index: number;
  file: Blob;
  status: UploadStatus;
  uploadPercentage: number;
}

const DocumentHelpers = {
  getChunkSize: function (documentSize: number) {
    if (documentSize >= 16777216) {
      // size 16mb - more, chunk size - 3mb
      return 3145728;
    } else {
      // size - 16mb, chunk size - 1mb
      return 1048576;
    }
  },

  splitFileToChunks: function (
    fileData: File,
    chunkSize: number,
    fileId: string,
  ) {
    const fileChunkList: IChunkHash = {};

    let cur = 0;
    let index = 1;
    while (cur < fileData.size) {
      const next = cur + chunkSize;
      fileChunkList[`${fileId}-${index}`] = {
        index: index - 1,
        file: fileData.slice(cur, next),
        status: UploadStatus.WAIT,
        uploadPercentage: 0,
      };
      index += 1;
      cur = next;
    }

    return fileChunkList;
  },

  getFileFromDataURL: function (dataurl: any, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  },

  getDownloadDocumentLink: function (
    documentId: string,
    fileId: string,
    fingerprint: string,
  ): string {
    const token = LocalStorageHelpers.getAccessToken();
    return `${ApiConfig.dmsApi}/${documentId}/download/${fileId}?tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
  },

  downloadDocumentByURL: async function (
    downloadURL: string,
    fileName: string,
  ) {
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName || '';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  downloadDocument: async function (
    documentId: string,
    fileId: string,
    fileName: string,
  ) {
    const fingerprint = await SessionHelpers.generateFingerprint();
    this.downloadDocumentByURL(
      this.getDownloadDocumentLink(documentId, fileId, fingerprint),
      fileName,
    );
  },

  verifyFilesThatCanBeUploaded: function (files: File[]): {
    approved: File[];
    rejected: { file: File; reason: 'empty' | 'max_size' }[];
  } {
    return files.reduce(
      (
        acc: {
          approved: File[];
          rejected: { file: File; reason: 'empty' | 'max_size' }[];
        },
        next,
      ) => {
        if (next.size && next.size <= MAX_UPLOAD_DOCUMENT_SIZE) {
          acc.approved.push(next);
        }

        if (!next.size) {
          acc.rejected.push({ file: next, reason: 'empty' });
        }

        if (next.size > MAX_UPLOAD_DOCUMENT_SIZE) {
          acc.rejected.push({ file: next, reason: 'max_size' });
        }

        return acc;
      },
      { approved: [], rejected: [] },
    );
  },

  formatFileToLocalDocument: function (
    file: File,
    tags: string[],
    relatedTo?: string,
  ): IUploadFile {
    return {
      id: generateUniqId(),
      originalName: file.name,
      name: file.name,
      tags,
      file,
      relatedTo,
    };
  },
};

export { DocumentHelpers };
