import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { RequestStatuses } from 'enums/connections/connections';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  warning: [RequestStatuses.Pending],
  success: [RequestStatuses.Accepted],
  error: [RequestStatuses.Rejected, RequestStatuses.Cancelled],
  info: [],
};

interface IProps {
  status: RequestStatuses;
}

const RequestStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('connections');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      content={t(`requests.request_statuses.${status}`)}
    />
  );
});

export default RequestStatus;
