import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { message } from 'antd';
import { StateModel } from 'redux/reducers';
import { setChangeRequest } from 'redux/actions/personalDetails';
import { useDispatch, useSelector } from 'react-redux';
import { personalDetailsAPIAdapter } from 'apiAdapters/profile/personalDetailsAPIAdapter';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';
import {
  ChangeRequestStatuses,
  personalDetailsAPI,
} from 'api/profile/personalDetailsAPI';

// components
import SourceOfWealthForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/SourceOfWealthForm';

const SourceOfWealth = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useDispatch();
  const { changeRequest, contact } = useSelector<
    StateModel,
    PersonalDetailsStateModel
  >((state) => state.personalDetails);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (contact) {
      const employmentInformation =
        changeRequest?.changes?.contactKYCChange?.sow?.employmentInformation ||
        contact.employmentInformation;
      const employmentHistoryValue = employmentInformation
        ? {
            isInheritedFromRelationships: false,
            employerName: employmentInformation.employerName || '',
            occupation: employmentInformation.occupation || '',
            natureOfBusiness: employmentInformation.natureOfBusiness || '',
            isSelfEmployed:
              typeof employmentInformation.isSelfEmployed === 'boolean'
                ? employmentInformation.isSelfEmployed
                : null,
            isBusinessUnderOwnName:
              typeof employmentInformation.isBussinessUnderOwnName === 'boolean'
                ? employmentInformation.isBussinessUnderOwnName
                : null,
          }
        : {
            isInheritedFromRelationships: false,
            employerName: '',
            occupation: '',
            natureOfBusiness: '',
            isSelfEmployed: null,
            isBusinessUnderOwnName: false,
          };

      return {
        isSave: false,
        submitActionType: null,
        isBasedOnExistingRelationship: false,
        sourceOfWealthCategories:
          changeRequest?.changes?.contactKYCChange?.sow
            ?.sourceOfWealthCategories || contact.sourceOfWealthCategories,
        sourceOfWealthDescription:
          changeRequest?.changes?.contactKYCChange?.sow
            ?.sourceOfWealthDescription || contact.sourceOfWealthDescription,
        employmentHistory: [employmentHistoryValue],
      };
    } else {
      return null;
    }
  }, [contact, changeRequest]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (changeRequest) {
      const response = await personalDetailsAPI.submitKYCSOWTab(
        changeRequest._id,
        personalDetailsAPIAdapter.formatKYCSOWFormValuesAPIPayload(values),
      );

      message.success(t('success_save'));
      dispatch(setChangeRequest(response));
    }
  };

  return initialFormValues ? (
    <SourceOfWealthForm
      showOnlySaveButton
      disabled={
        !changeRequest ||
        changeRequest.status !== ChangeRequestStatuses.InProgress
      }
      initialFormValues={initialFormValues}
      onSubmit={handleSubmit}
    />
  ) : null;
};
export default SourceOfWealth;
