import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import relationshipsAPI from '../../../../../../api/relationships/relationshipsAPI';
import { message } from 'antd';
import { AssociationEntries } from '../../../../../../enums/onboarding/crm';
import { OnboardingStatusModel } from '../../../../../../typings/onboarding/onboarding';
import { relationshipsAPIAdapter } from '../../../../../../apiAdapters/relationships/relationshipsAPIAdapter';

// components
import {
  default as SetupInitialRelationshipsModalTemplate,
  FormValuesModel,
} from '../../../../../../components/ModalDialogs/TemplateModalDialogs/Onboarding/SetupInitialRelationshipsModal';

interface IProps {
  onboardingData: OnboardingStatusModel;
  onSetup: () => void;
}

const SetupInitialRelationshipsModal = ({
  onboardingData,
  onSetup,
}: IProps) => {
  const { t } = useTranslation('onboarding');

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      organizationTypeName:
        onboardingData.item?.keyRelationshipInstance?.name || '',
      organizationTypeCategoryId:
        onboardingData.item?.keyRelationshipInstance?.templateId || '',
      activeApplicationScopeId: onboardingData.relationshipScopeId,
      relationshipsToOrganizationId: onboardingData.crmItemId,
      relationshipsFromContactId: onboardingData.contactId,
      relationships: [
        {
          id: '',
          relationshipTemplate: null,
          additionalFields: [],
        },
      ],
      documentAssociation: {
        id: onboardingData.contactId,
        type: AssociationEntries.Contact,
      },

      isPEP: null,
      pepInformation: '',
    };
  }, [onboardingData]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedRequestBody =
      relationshipsAPIAdapter.generateRequestBodyForCreateInitialRelationshipModal(
        values,
      );
    await relationshipsAPI.updateRelationships(formattedRequestBody);
    message.success(t('personal_info_modal.success_submit_message'));
    onSetup();
  };

  return (
    <SetupInitialRelationshipsModalTemplate
      isVisible={true}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      closeCallback={console.log}
    />
  );
};

export default SetupInitialRelationshipsModal;
