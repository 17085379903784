import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useSidebarMenu from 'hooks/useSidebarMenu';
import useCurrentRoute from 'hooks/useCurrentRoute';
import withApplications from 'hoc/withApplications';
import withVerifyStatusCheck from 'hoc/withVerifyStatusCheck';
import withPrivateRouteCheck from 'hoc/withPrivateRouteCheck';
import withAccessCheckForRoute from 'hoc/withAccessCheckForRoute';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { MainLayoutContextProvider } from './MainLayoutContext';

// components
import HeaderBar from './HeaderBar';
import FooterBar from 'components/Layout/FooterBar';
import SidebarMenu from './SidebarMenu';
import WelcomeCard from 'modules/Dashboard/WelcomeCard';
import PageRenderer from './PageRenderer';
import SidebarForMobile from './SidebarForMobile';
import { Col, Row } from 'antd';

const MainLayout = () => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const currentRoute = useCurrentRoute();
  const menuItems = useSidebarMenu(
    !!currentRoute?.metadata?.renderRouteComponentOnly,
  );
  const breakpoint = useBreakpoint();
  const isMobileResolution = !breakpoint.xl;
  const shouldRenderSidebar =
    !!menuItems.length &&
    !isMobileResolution &&
    !currentRoute?.metadata?.renderRouteComponentOnly;
  const isWelcomeModalVisible = useSelector<StateModel, boolean>(
    (state) => state.app.isWelcomeModalVisible,
  );

  return (
    <MainLayoutContextProvider>
      <HeaderBar
        isMobileResolution={isMobileResolution}
        openDrawerCallback={() => setDrawerStatus(true)}
      />
      <StyledRow>
        {shouldRenderSidebar && (
          <Col flex="1 1" xs={24} sm={24} md={5}>
            <SidebarMenu menuItems={menuItems} />
          </Col>
        )}
        <Col flex="1 0" xs={24} sm={24} md={shouldRenderSidebar ? 19 : 24}>
          <PageRenderer route={currentRoute} />
        </Col>
      </StyledRow>
      <FooterBar />
      {isMobileResolution ? (
        <SidebarForMobile
          isOpen={drawerStatus}
          closeCallback={() => setDrawerStatus(false)}
        />
      ) : null}
      {isWelcomeModalVisible && (
        <BlurLayout>
          <WelcomeCard />
        </BlurLayout>
      )}
    </MainLayoutContextProvider>
  );
};

const StyledRow = styled(Row)`
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.headerHeight};
  min-height: calc(
    100vh - ${({ theme }) => theme.headerHeight} -
      ${({ theme }) => theme.footerHeight} -
      ${({ theme }) => theme.footerMargin}
  );

  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    margin-top: ${({ theme }) => theme.headerHeightMobile};

    min-height: calc(
      100vh - ${({ theme }) => theme.headerHeightMobile} -
        ${({ theme }) => theme.footerHeight} -
        ${({ theme }) => theme.footerMargin}
    );
  }
`;

const BlurLayout = styled.div`
  z-index: 9999;

  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  padding-top: 100px;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(2px);
`;

export default withPrivateRouteCheck()(
  withVerifyStatusCheck()(
    withApplications()(withAccessCheckForRoute()(MainLayout)),
  ),
);
