import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { userAPI } from '../../../../api/profile/userAPI';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { BrowserHelpers } from '../../../../helpers/browser';
import { ChallengeForLoginModel } from '../../../../typings/profile/fido';
import { StateModel as AuthStateModel } from '../../../../redux/reducers/auth';

// components
import Text from '@core_components/Text';
import Content from './Content';
import CollapseOptions from './CollapseOptions';
import BrowserIsNotSupported from '../../../../components/Additional/Auth/TwoFactorVerification/BrowserIsNotSupported';

const FidoAuthentication = () => {
  const canUseWebAuthn = BrowserHelpers.isWebAuthnSupported();

  const { t } = useTranslation('auth');
  const { temporaryToken } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );
  const { response: challenge, loading } = useFetch(
    () =>
      temporaryToken && canUseWebAuthn
        ? userAPI.getChallengeForLogin(temporaryToken)
        : null,
    [temporaryToken, canUseWebAuthn],
  );

  return (
    <>
      <StyledTitle variant="h5" gutterBottom>
        {t('two_factor_authentication.fido.title')}
      </StyledTitle>

      {canUseWebAuthn ? (
        <Content
          loadingChallenge={loading}
          challenge={challenge?.assertionChallenge as ChallengeForLoginModel}
        />
      ) : (
        <BrowserIsNotSupported />
      )}
      <CollapseOptions />
    </>
  );
};

const StyledTitle = styled(Text)`
  text-align: center;
`;

export default FidoAuthentication;
