import React, { useState } from 'react';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { approvalGroupsAPI } from '../../../../api/approvalManagement/approvalGroupsAPI';

// components
import { default as InnerTable } from '../../../../components/Tables/TableTemplates/AccountManagement/ApprovalGroupsTable';

interface IProps {
  onActionsClick: (key: string, id: string) => void;
  updateTableTrigger?: any;
  hasPermissionForEditing: boolean;
}

const ApprovalGroupsTable = ({
  onActionsClick,
  updateTableTrigger,
  hasPermissionForEditing,
}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      approvalGroupsAPI.fetchApprovalGroups({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage, updateTableTrigger],
  );

  return (
    <InnerTable
      data={response?.data || []}
      current={currentPage}
      total={response?.total || 0}
      loading={loading}
      onPaginationChange={setCurrentPage}
      onActionsClick={onActionsClick}
      hasPermissionForEditing={hasPermissionForEditing}
    />
  );
};

export default ApprovalGroupsTable;
