import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import OTPField from '../../../../../components/Forms/FormComponents/OTPField';
import FormField from '@core_components/FormField';
import { Typography } from 'antd';

const RequestResetPasswordSms = () => {
  const { t } = useTranslation('auth');

  return (
    <StyledVerificationWrapper>
      <StyledParagraph>
        {t('reset_password.steps.otp_verification.phone.description')}
      </StyledParagraph>

      <OTPFieldWrapper>
        <FormField
          name="verificationSmsCode"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </OTPFieldWrapper>
    </StyledVerificationWrapper>
  );
};

const StyledVerificationWrapper = styled.div`
  text-align: center;
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.marginMd} 0;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
`;

export default RequestResetPasswordSms;
