import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import relationshipsAPI from '../../../../../api/relationships/relationshipsAPI';
import { message } from 'antd';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { generateUniqId } from 'helpers/utils';
import { relationshipsAPIAdapter } from '../../../../../apiAdapters/relationships/relationshipsAPIAdapter';
import { StateModel as ApplicationsStateModel } from '../../../../../redux/reducers/applications';
import {
  NewUserFromExistingContactModel,
  userManagementAPINew,
} from '../../../../../api/accountManagement/userManagementAPINew';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
  OnboardingEntryTypes,
} from '../../../../../enums/onboarding/crm';

// components
import NewUserModalDialog, {
  FormValuesModel,
} from '../../../../../components/ModalDialogs/TemplateModalDialogs/Onboarding/NewUserModalDialog';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasAdded?: boolean) => void;
}

const AddUserModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('account_management');
  const { activeApplication } = useSelector<StateModel, ApplicationsStateModel>(
    (state) => state.applications,
  );

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      relationshipScopeId: activeApplication?.model.relationshipScopes
        ?.active as string,
      approvedRelationshipScopeId: activeApplication?.model.relationshipScopes
        ?.approved as string,
      clientGroupId: activeApplication?.clientGroup?._id as string,
      isFromExistingContact: null,
      contact: {
        id: '',
        firstName: '',
        lastName: '',
        middleName: '',
        isPEP: null,
        pepInformation: '',
        phone: '',
        email: '',
        disabledIsOnlineUser: true,
        isPermissionsSelectAvailable: true,
        isOnlineUser: true,
        adminPermissionType: null,
        accountPermissions: null,
      },
      clientGroupEntryType:
        activeApplication?.crmItemType as OnboardingEntryTypes,

      relationships: [
        {
          customId: generateUniqId(),

          id: null,

          relationshipTemplate: {
            id: null,
            childRelationshipLabel: '',
          },

          parent: {
            id: null,
            type: OnboardingEntryTypes.Contact,
          },

          child: {
            id: null,
            type: null,
            label: '',
          },

          additionalFields: [],
        },
      ],
    };
  }, [activeApplication]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.isFromExistingContact) {
      const newUser: NewUserFromExistingContactModel = {
        contactId: values.contact.id as string,
        onlineAccess: {
          adminPermissionType: values.contact
            .adminPermissionType as AdministrationPermissionTypes,
          permission: values.contact
            .accountPermissions as AccountUserPermissionTypes,
        },
      };
      await userManagementAPINew.createNewUserFromExistingContact(
        values.clientGroupId,
        newUser,
      );
      message.success(t('user_management.add_user.success_submit_message'));
    } else {
      const formattedBody =
        relationshipsAPIAdapter.generateRequestBodyForNewUser(values);
      await relationshipsAPI.updateRelationships(formattedBody);
      message.success(t('user_management.add_user.success_submit_message'));
    }
  };

  return (
    <NewUserModalDialog
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
    />
  );
};

export default AddUserModal;
