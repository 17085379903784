import { IProfile } from 'typings/profile/profile';

const profileAPIAdapter = {
  fetchProfile: (data: IProfile) => {
    return {
      ...data,
      applications: data.applications.map((app) => {
        if (app.onboardingProcess) {
          app.onboardingProcess.item = app.model;
        }
        return app;
      }),
    };
  },
};

export { profileAPIAdapter };
