import APIConfig from '../../config/api';
import { IEntity } from '../../typings/application/entity';
import { APIService } from '../axiosInstance';
import { ContactModel } from '../../typings/application/contact';
import { FetchResponseModel } from '../../typings/common';
import { OnboardingEntryTypes } from '../../enums/onboarding/crm';
import { RelationshipTemplate } from '../../typings/application/relationship-template';
import { RelationshipComparationTypes } from '../../typings/crm/relationships';
import {
  AdditionalFieldAnswerModel,
  ModifyRelationshipsBodyModel,
} from '../../typings/relationships/relationships';

interface FetchRelationshipsParams {
  itemId: string;
  relationshipScopeId: string;
  relationshipTemplateId: string;
}

interface RelationshipEntry {
  id: string;
  type: OnboardingEntryTypes;
  contact: ContactModel;
  organization: IEntity;
}

export interface RelationshipModel {
  additionalFields: AdditionalFieldAnswerModel[];
  child: RelationshipEntry;
  comparationResult: '' | RelationshipComparationTypes;
  parent: RelationshipEntry;
  relationshipScopeId: string;
  relationshipTemplate: RelationshipTemplate;
  relationshipTemplateId: string;
  _id: string;
}

const relationshipsAPI = {
  updateRelationships: (body: ModifyRelationshipsBodyModel) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-group-profile/batch`,
      body,
    );
  },

  fetchRelationships: (params: FetchRelationshipsParams) => {
    return APIService.get<FetchResponseModel<RelationshipModel>>(
      `${APIConfig.crmApi}/relationships`,
      { params },
    ).then(({ data }) => data);
  },
};

export default relationshipsAPI;
