import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import moment, { Moment } from 'moment';
import { RoutePaths } from 'routes/routes';
import { changeView } from '../../../../redux/actions/view';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { transactionsAPI } from '../../../../api/accounting/transactionsAPI';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import ViewTransactionDetailModal from './ViewTransactionModal';
import { DatePicker, Form } from 'antd';
import { default as TemplateTransactionsTable } from '../../../../components/Tables/TableTemplates/Accounts/TransactionsTable/index';

const FormItem = Form.Item;

// TODO: Remove any when antd is updated
const { RangePicker } = DatePicker as any;

interface IProps {
  accountNumber?: string;
}

const TransactionsTable = ({ accountNumber = '' }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('accounts');

  const [current, setCurrentPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment().utc().add(-1, 'M'),
    moment().utc(),
  ]);

  useEffect(() => {
    dispatch(
      changeView(() => {
        navigate({
          pathname: RoutePaths.Accounts_AccountActivity,
          search: `?accountNumber=${accountNumber}&startDate=${dateRange[0].startOf('day').unix()}&endDate=${dateRange[1]
            .endOf('day')
            .unix()}&page=${current}`,
        });
      }),
    );
  }, [accountNumber, dateRange, current]);

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchJournalEntries({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        toDate: dateRange[1].utc().endOf('day').unix(),
        fromDate: dateRange[0].utc().startOf('day').unix(),
        accountNumber: accountNumber || '',
      }),
    [current, dateRange, accountNumber, updateTableTrigger],
  );

  const handleActivityDateChange = (dateRange: any) => {
    if (dateRange) {
      setDateRange(dateRange);
    }
  };

  const onActionsClick = (key: string, transactionId: number) => {
    switch (key) {
      case 'view': {
        setSelectedTransactionId(transactionId);
        break;
      }
    }
  };

  const handleCloseDetailDialog = (wasUpdated?: boolean) => {
    if (wasUpdated) {
      updateTable({});
    }
    setSelectedTransactionId(null);
  };

  return (
    <>
      <FormItemWrapper
        label={t('account_activity.activity_date_range')}
        labelAlign="left"
      >
        <RangePicker
          allowClear={false}
          value={dateRange}
          onChange={handleActivityDateChange}
          format={DEFAULT_DATE_FORMAT}
        />
      </FormItemWrapper>

      <TemplateTransactionsTable
        current={current}
        total={response?.total || 0}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setCurrentPage}
        onActionsClick={onActionsClick}
      />

      <ViewTransactionDetailModal
        visible={!!selectedTransactionId}
        closeCallback={handleCloseDetailDialog}
        journalEntryId={selectedTransactionId}
      />
    </>
  );
};

const FormItemWrapper = styled(FormItem)`
  margin: 30px 5px 20px 5px;
`;

export default TransactionsTable;
