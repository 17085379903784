import React from 'react';

// helpers
import { AutocompleteOption } from '@core_components/Autocomplete';
import { createAccountSchema } from '../../../../../validations/accounting/accounts';

// constants
import { AccountTypes } from '../../../../../enums/onboarding/crm';
import { WireRangeKeys } from '../../../../Forms/FormComponents/SelectInputs/Accounting/WireRangesSelect';
import { AccountProcessingTypes } from '../../../../../enums/accounting/transfers';
import { InitialDepositRangeKeys } from '../../../../Forms/FormComponents/SelectInputs/Accounting/InitialDepositRangeSelect';
import { EstimatedAmountFundsRangeKeys } from '../../../../Forms/FormComponents/SelectInputs/Accounting/EstimatedAmountFundsRangeSelect';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  loadingInitialData?: boolean;
}

export interface FormValuesModel {
  accountType: AccountProcessingTypes | null;

  circleAccount: {
    accountName: string;
    currencyId: string | null;
    predefinedCurrencyOption?: AutocompleteOption<string>;
  };

  fiatAccount: {
    id?: string;
    accountName: string;
    purpose: string;
    type: AccountTypes | null;
    currencyId: number | null;
    initialDeposit: InitialDepositRangeKeys | null;
    initialDepositOrigin: string | null;
    jurisdictions: string[];
    incomingWiresNumber: WireRangeKeys | null;
    estimatedIncomingFunds: EstimatedAmountFundsRangeKeys | null;
    outgoingWiresNumber: WireRangeKeys | null;
    estimatedOutgoingFunds: EstimatedAmountFundsRangeKeys | null;
  };
}

const AddEditAccountModalDialog = ({
  title,
  isVisible,
  onSubmit,
  closeCallback,
  loadingInitialData,
  initialValues,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={createAccountSchema}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={!!loadingInitialData}>
            <InnerForm />
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default AddEditAccountModalDialog;
