import { CANADA_DISTRICTS, UNITED_STATES_DISTRICTS } from 'constants/districts';

export const TransfersHelpers = {
  getDistrict: function (country: string, district: string) {
    switch (country) {
      case 'US':
        return UNITED_STATES_DISTRICTS[district];
      case 'CA':
        return CANADA_DISTRICTS[district];
    }

    return district;
  },

  getTransferTemplateName: function (templateId: string, templateName: string) {
    return `${templateId} - ${templateName}`;
  },
};
