import React from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { ApprovalEntryNewModel } from '../../../../../../../../../typings/approvalManagement/accountManagement';

// components
import Text from '@core_components/Text';
import StatusLabel, {
  LABEL_STATUSES,
} from '../../../../../../../../../components/Typography/StatusLabel';

export type CircleServiceTemplateDataModel = {
  connected: boolean;
};

interface IProps {
  approvalEntry: ApprovalEntryNewModel<CircleServiceTemplateDataModel>;
}

const CircleService = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  return (
    <Text variant="body1">
      {`${t('pending_approvals.approval_details_modal.changes_section.circle_service_changes_template.action')} `}

      {approvalEntry.changes.new?.connected ? (
        <StatusLabel
          status={LABEL_STATUSES.success}
          content={t(
            'pending_approvals.approval_details_modal.changes_section.circle_service_changes_template.actions.connect',
          )}
        />
      ) : (
        <StatusLabel
          status={LABEL_STATUSES.error}
          content={t(
            'pending_approvals.approval_details_modal.changes_section.circle_service_changes_template.actions.disconnect',
          )}
        />
      )}
    </Text>
  );
};

export default CircleService;
