import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { DateHelpers } from '../../../../../../../../helpers/date';
import { IApprovalMatrixRecord } from '../../../../../../../../typings/accounting/transaction';
import { ApprovalEntryNewModel } from '../../../../../../../../typings/approvalManagement/accountManagement';

// constants
import { ApprovalWorkflowChangeTypes } from '../../../../../../../../enums/approvalManagement/approvalManagement';

// components
import ApprovalMatrix from '../../../../../../../../components/Tables/ApprovalMatrix';
import ApprovalEntryStatus from 'components/Additional/Statuses/ApprovalEntryStatus';
import { Table } from 'antd';

interface IProps {
  approvalEntry: ApprovalEntryNewModel;
}

const EntryDetails = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo(() => {
    return [
      { key: 'title', dataIndex: 'title', width: 200 },
      { key: 'value', dataIndex: 'value' },
    ];
  }, []);

  const tableData = useMemo(() => {
    const { entry } = approvalEntry;
    const formattedApprovalState: IApprovalMatrixRecord[][] =
      approvalEntry.approvalProgressStateSets.map((e) => e.progressStateItems);

    return [
      {
        title: t(
          'pending_approvals.approval_details_modal.details_section.date',
        ),
        value: DateHelpers.formatDateToString(entry.createdAt),
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.details_section.entry',
        ),
        value: t(`pending_approvals.approval_entry_types.${entry.type}`),
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.details_section.type',
        ),
        value: t(
          `pending_approvals.change_types.${String(ApprovalWorkflowChangeTypes[entry.changeType])}`,
        ),
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.details_section.status',
        ),
        value: <ApprovalEntryStatus status={approvalEntry.entry.status} />,
      },
      {
        title: t(
          'pending_approvals.approval_details_modal.details_section.approval_matrix',
        ),
        value: <ApprovalMatrix transactionApprovals={formattedApprovalState} />,
      },
    ];
  }, [approvalEntry]);

  return (
    <Table
      bordered
      size="middle"
      showHeader={false}
      pagination={false}
      scroll={{ x: 'max-content' }}
      columns={tableColumns}
      dataSource={tableData}
    />
  );
};

export default EntryDetails;
