import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { FormikHelpers } from 'formik';

// components
import {
  default as TemplateConfirmChangeModal,
  FormValuesModel,
} from '../../../../components/ModalDialogs/TemplateModalDialogs/Profile/ConfirmChangeModal';
interface IProps {
  isVisible: boolean;
  closeCallback: (isSuccess?: boolean) => void;
  callbackMethod: (otpCode: string) => Promise<void>;
}

const ConfirmChangeModal = ({
  isVisible,
  closeCallback,
  callbackMethod,
}: IProps) => {
  const { t } = useTranslation(['profile', 'common', 'custom_errors']);

  const handleSubmit = async (
    { code }: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    try {
      await callbackMethod(code || '');
    } catch (error: any) {
      if (error.response?.data?.code === 'wrong_token') {
        helpers.setFieldError(
          'code',
          t('two_factor_authentication.invalid_otp_code', {
            ns: 'custom_errors',
          }),
        );
      } else {
        throw error;
      }
    }
  };

  const initialFormValues: FormValuesModel = useMemo(
    () => ({ code: null }),
    [],
  );

  return (
    <TemplateConfirmChangeModal
      isVisible={isVisible}
      initialValues={initialFormValues}
      closeCallback={closeCallback}
      onSubmit={handleSubmit}
    />
  );
};
export default ConfirmChangeModal;
