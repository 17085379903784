import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { ActivationEmailEnum } from '../../../../enums/profile/auth';
import { SigninSubmitUsernameResponseModel } from '../../../../api/profile/userAPI';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import ActivateUser from './ActivateUser';
import AuthContainer from '../../../../components/Additional/AuthContainer';
import SignInUsername from './SignInUsername';
import SignInPassword from './SignInPassword';
import { Trans } from 'react-i18next';

export enum SignInTabs {
  CheckUsername = 'check_username',
  SignIn = 'signin',
  EnableUser = 'enable_user',
}

const SignInSteps = () => {
  const { t } = useTranslation('auth');
  const [username, setUsername] = useState('');
  const [currentStep, setCurrentStep] = useState<SignInTabs>(
    SignInTabs.CheckUsername,
  );

  const redirectToStep = (code: ActivationEmailEnum) => {
    switch (code) {
      case ActivationEmailEnum.OK: {
        setCurrentStep(SignInTabs.SignIn);
        break;
      }
      case ActivationEmailEnum.ACTIVATION_REQUIRED: {
        setCurrentStep(SignInTabs.EnableUser);
        break;
      }
    }
  };

  const title = useMemo(
    () => t(`signin.steps.${currentStep}.title`),
    [currentStep],
  );

  const footer = useMemo(() => {
    switch (currentStep) {
      case SignInTabs.CheckUsername:
        return (
          <StyledFooterText>
            <Trans
              t={t}
              i18nKey="signin.signup_link"
              components={[<Link key="link" to={RoutePaths.Invitation_Root} />]}
            />
          </StyledFooterText>
        );

      case SignInTabs.EnableUser:
        return (
          <Link onClick={() => setCurrentStep(SignInTabs.CheckUsername)}>
            {t('phone_verification.sign_out')}
          </Link>
        );

      default:
        return null;
    }
  }, [currentStep]);

  const CurrentStepComponent = useMemo(() => {
    switch (currentStep) {
      case SignInTabs.CheckUsername:
        return (
          <SignInUsername
            onSubmit={(
              username: string,
              response: SigninSubmitUsernameResponseModel,
            ) => {
              setUsername(username);
              if (response.status === ActivationEmailEnum.ACTIVATION_REQUIRED) {
                redirectToStep(ActivationEmailEnum.ACTIVATION_REQUIRED);
              } else {
                redirectToStep(ActivationEmailEnum.OK);
              }
            }}
          />
        );
      case SignInTabs.SignIn:
        return <SignInPassword username={username} />;

      case SignInTabs.EnableUser:
        return <ActivateUser username={username} />;
    }
  }, [currentStep]);

  return (
    <AuthContainer footerText={footer}>
      <div>
        {currentStep !== SignInTabs.EnableUser && (
          <StyledTitleText variant="h5" weight="semi-bold">
            {title}
          </StyledTitleText>
        )}
      </div>
      <div>{CurrentStepComponent}</div>
    </AuthContainer>
  );
};

const StyledTitleText = styled(Text)`
  display: block;
  text-align: center;
`;

const StyledFooterText = styled(Text)`
  display: flex;
  white-space: pre;
`;

export default SignInSteps;
