import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import { Col, Row } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');

  return (
    <Row gutter={[16, 0]}>
      <Col span={24}>
        <FormField
          label={t(
            'applicant_information.form_fields.organization.entity_name',
          )}
          name="legalName"
          component={FormInput}
        />
      </Col>
    </Row>
  );
};

export default InnerForm;
