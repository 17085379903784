import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../../../../../resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import Button from '../../../../../../../../DesignSystem/Core/Button';
import IconSVG from '../../../../../../../../DesignSystem/Core/IconSVG';
import { ReactComponent as InfoIcon } from '../../../../../../../../../resources/icons/remix-icons/information-line.svg';

interface IProps {
  onRetryClick: () => void;
}

const ErrorWithRetryButton = ({ onRetryClick }: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <>
      <StyledIconSVG
        component={InfoIcon}
        color={colorsTheme.colorWhite}
        size="large"
      />

      <StyledText variant="body1" gutterBottom>
        {t(
          'security.two_step_verification.security_key.register_new_key.register_key_step.error_in_registration',
        )}
      </StyledText>

      <Button size="large" onClick={onRetryClick}>
        {t(
          'security.two_step_verification.security_key.register_new_key.register_key_step.retry_button',
        )}
      </Button>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

export default ErrorWithRetryButton;
