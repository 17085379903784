import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import Text from '@core_components/Text';
import ContactDetails from './ContactDetails';
import { Trans } from 'react-i18next';
import { Col, Row, Tooltip } from 'antd';

const InnerForm = memo(() => {
  const { t } = useTranslation(['connections', 'common']);
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <DescriptionContainer>
        <StyleText>
          <StyledTrans
            t={t}
            values={{ clientGroupName: values.data?.clientGroupName || '' }}
            i18nKey="requests.view_request_modal.share_contact_request.description"
            components={[
              <StyledTooltip
                key="client-group-tooltip"
                placement="bottom"
                title={t('key_words.client_group', { ns: 'common' })}
              />,
              <Text
                variant="span"
                key="clientGroup"
                weight="semi-bold"
                color={colorsTheme.colorWhite}
              />,
              <StyledTooltip
                key="client-group-tooltip"
                placement="bottom"
                title={t('key_words.client_group', { ns: 'common' })}
              />,
              <StyledTooltip
                key="client-group-tooltip"
                placement="bottom"
                title={t('key_words.client_group', { ns: 'common' })}
              />,
            ]}
          />
        </StyleText>
      </DescriptionContainer>
      <Row wrap={false}>
        <StyledCol flex={1}>
          <StyledText variant="h5">
            {t(
              'requests.view_request_modal.share_contact_request.existing_contact_details',
            )}
          </StyledText>
          <ContactDetails fieldName="data.existingContact" />
        </StyledCol>
        <StyledCol flex={1}>
          <StyledText variant="h5">
            {t(
              'requests.view_request_modal.share_contact_request.new_contact_details',
            )}
          </StyledText>
          <ContactDetails fieldName="data.newContact" />
        </StyledCol>
      </Row>
    </>
  );
});

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const StyledCol = styled(Col)`
  margin-right: ${({ theme }) => theme.marginMd};
`;

const DescriptionContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const StyledTrans = styled(Trans)<any>`
  white-space: pre-wrap;
`;

const StyleText = styled(Text)`
  white-space: pre-wrap;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px dotted ${theme.colorWarning}`};
  width: fit-content;
`;

export default InnerForm;
