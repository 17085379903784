import React, { useMemo } from 'react';

// helpers
import { ContactModel } from '../../../../../../../../../typings/application/contact';

// components
import IdentificationForIndividualShortForm, {
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/IdentificationForIndividualShortForm';

interface IProps {
  contact: ContactModel;
}

const Identification = ({ contact }: IProps) => {
  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      submitActionType: null,
      isSave: false,
      isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
      pepInformation: contact.pepInformation || '',
    };
  }, [contact]);

  const handleSubmit = async () => {
    // TODO: decide if submit will be needed here
  };

  return (
    <IdentificationForIndividualShortForm
      showOnlySaveButton
      disabled
      onSubmit={handleSubmit}
      initialFormValues={initialFormValues}
    />
  );
};

export default Identification;
