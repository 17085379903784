import React, { memo } from 'react';

// components
import ClientGroups from 'modules/Connections/ClientGroups';

const ClientGroupsPage = memo(() => {
  return <ClientGroups />;
});

export default ClientGroupsPage;
