import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import PageButton from '../../../../components/DesignSystem/Common/Buttons/PageButton';
import AddTransferTemplateModal from '../AddTransferTemplateModal';

interface IProps {
  onAdd: () => void;
}

const AddTransferTemplateButton = ({ onAdd }: IProps) => {
  const { t } = useTranslation('transfers');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = (wasAdded?: boolean) => {
    wasAdded && onAdd();
    setIsModalOpen(false);
  };

  return (
    <>
      <PageButton onClick={() => setIsModalOpen(true)}>
        {t('transfer_templates.transfer_template_modal.new.button_title')}
      </PageButton>
      <AddTransferTemplateModal
        isVisible={isModalOpen}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default AddTransferTemplateButton;
