import * as yup from 'yup';

// constants
import { STRING_VALIDATION_RULE } from '../common';
import { CONFIRMATION_REASON_MAX_LENGTH } from '../../constants/global';

export const ConfirmationMessageValidationSchema = yup.object().shape({
  reason: STRING_VALIDATION_RULE.max(CONFIRMATION_REASON_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'common:reason', number: CONFIRMATION_REASON_MAX_LENGTH },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'common:reason' },
  }),
});
