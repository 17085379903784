import React, { useMemo } from 'react';

// Helpers & Utils
import useTranslation from '../../../../../hooks/useTranslation';
import { SelectProps } from '../../../../DesignSystem/Core/Select';

// Components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps;

type OptionValues = 'true' | 'false';

// TODO: currently this component does not meet our typings, so we have to do as any in some places
// This is because it stores boolean flags to forms, but our select field does not allow to have boolean as a value for options
// So this component is kind of layer between a form and select field, where it formats a value for different sides
const TrueFalseSelect = ({ value, onChange, ...rest }: IProps) => {
  const { t } = useTranslation('common');

  const formattedValue = useMemo<OptionValues | undefined>(() => {
    let result: OptionValues | undefined = undefined;
    const formattedValue = value as any;

    if (formattedValue === true) {
      result = 'true';
    } else if (formattedValue === false) {
      result = 'false';
    }

    return result;
  }, [value]);

  const getFormattedNewFormValue = (newValue: OptionValues): boolean | null => {
    let result = null;

    if (newValue === 'true') {
      result = true;
    } else if (newValue === 'false') {
      result = false;
    }

    return result;
  };

  const handleChange = (value: OptionValues) => {
    const formattedValue = getFormattedNewFormValue(value);
    onChange && onChange(formattedValue as any);
  };

  const options = useMemo<any[]>(() => {
    const items: Array<{ key: OptionValues; name: string }> = [
      { key: 'true', name: t('yes') },
      { key: 'false', name: t('no') },
    ];

    return items.map((e) => ({
      id: e.key,
      label: e.name,
    }));
  }, [t]);

  return (
    <FormSelect
      {...rest}
      value={formattedValue}
      onChange={handleChange as any}
      options={options}
    />
  );
};

export default TrueFalseSelect;
