import React from 'react';

// helpers
import styled from 'styled-components';
import useQueryParam from '../../hooks/useQueryParam';
import useTranslation from '../../hooks/useTranslation';
import { userAPI } from '../../api/profile/userAPI';
import { useDispatch } from 'react-redux';
import { initializeApp } from '../../redux/actions/app';
import { LocalStorageHelpers } from '../../helpers/storages/localStorage';
import { ActivateAccountValidationSchema } from '../../validations/profile/auth';

// components
import Text from '@core_components/Text';
import Form from '@core_components/Form';
import FormField from '@core_components/FormField';
import SubmitButton from '../../components/Buttons/SubmitButton';
import AuthContainer from '../../components/Additional/AuthContainer';
import FormInputPassword from '@common_components/Form/FormInputPassword';
import SetupPasswordField from '../../components/Forms/FormComponents/SetupPasswordField';

interface FormValuesModel {
  password: string;
  passwordConfirm: string;
}

const ActivateAccountPage = () => {
  const { t } = useTranslation(['auth', 'common']);

  const dispatch = useDispatch();
  const token = useQueryParam({ param: 'token', noParamRedirect: '/' });
  const userId = useQueryParam({ param: 'userId', noParamRedirect: '/' });

  const initialFormValues: FormValuesModel = {
    password: '',
    passwordConfirm: '',
  };

  const handleSubmit = async (values: FormValuesModel) => {
    const response = await userAPI.setupPassword({
      _id: userId,
      password: values.password,
      tempToken: token,
    });

    LocalStorageHelpers.setAuthenticationTokens(
      response.jwt,
      response.refreshToken,
    );
    dispatch(initializeApp());
  };

  return (
    <StyledAuthContainer>
      <div>
        <TitleText variant="h4" gutterBottom>
          {t('activate_account.title')}
        </TitleText>
        <Text variant="body1" gutterBottom>
          {t('activate_account.create_password_description')}
        </Text>
      </div>
      <Form<FormValuesModel>
        onSubmit={handleSubmit}
        initialValues={initialFormValues}
        validationSchema={ActivateAccountValidationSchema}
        confirmExitWithoutSaving
        renderForm={
          <>
            <FormField
              label={t('activate_account.form_fields.password')}
              name="password"
              component={SetupPasswordField}
            />
            <FormField
              label={t('activate_account.form_fields.confirm_password')}
              name="passwordConfirm"
              component={FormInputPassword}
            />
            <SubmitButton fullWidth type="primary">
              {t('save', { ns: 'common' })}
            </SubmitButton>
          </>
        }
      />
    </StyledAuthContainer>
  );
};

const StyledAuthContainer = styled(AuthContainer)`
  text-align: center;
`;
const TitleText = styled(Text)`
  display: block;
  text-align: center;
`;

export default ActivateAccountPage;
