import React, { useMemo } from 'react';

// helpers
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';

// components
import SignOutButton from './SignOutButton';
import ActionsWithNavItems from './ActionsWithNavItems';

const ProfileActions = () => {
  const location = useLocation();
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );

  const actionsNode = useMemo(() => {
    if (
      [RoutePaths.VerificationPage, RoutePaths.SelectApplicationPage].includes(
        location.pathname as RoutePaths,
      )
    ) {
      return <SignOutButton />;
    }

    switch (layoutVariant) {
      case 'NoClientGroup':
        return <SignOutButton />;

      case 'OnboardingClientGroup':
      case 'ApprovedClientGroup':
        return <ActionsWithNavItems />;

      default:
        return null;
    }
  }, [layoutVariant, location.pathname]);

  return actionsNode;
};

export default ProfileActions;
