import React from 'react';

// helpers
import styled from 'styled-components';
import { StateModel } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from '../../redux/reducers/auth';
import {
  USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL,
  USER_TAG_IS_SKIPPED_VERIFICATION_SMS,
} from 'constants/userTags';

// components
import VerifyEmail from '../../modules/Auth/VerifyEmail';
import PhoneVerification from '../../modules/Auth/PhoneVerification';

const VerificationPage = () => {
  const { verificationStatuses, profileData } = useSelector<
    StateModel,
    AuthStateModel
  >((store) => store.auth);

  const renderContent = () => {
    const { isEmailVerified, isPhoneVerified } = verificationStatuses;

    if (
      !isEmailVerified &&
      !profileData?.tags.includes(USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL)
    ) {
      return <VerifyEmail />;
    } else if (
      !isPhoneVerified &&
      !profileData?.tags.includes(USER_TAG_IS_SKIPPED_VERIFICATION_SMS)
    ) {
      return <PhoneVerification />;
    }
  };

  return <Wrapper>{renderContent()}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default VerificationPage;
