import { PermissionCheckModel } from '../typings/profile/security';
import { ProfilePermissionModel } from '../typings/profile/profile';

const PermissionHelpers = {
  hasAllPermissions: function (
    userPermissions: Map<string, ProfilePermissionModel>,
    expectedPermissions: PermissionCheckModel[] = [],
  ) {
    return expectedPermissions.every(({ permissionKey }) => {
      return userPermissions.has(permissionKey);
    });
  },

  hasSomePermission: function (
    userPermissions: Map<string, ProfilePermissionModel>,
    expectedPermissions: PermissionCheckModel[] = [],
  ) {
    return expectedPermissions.some(({ permissionKey }) => {
      return userPermissions.has(permissionKey);
    });
  },
};

export { PermissionHelpers };
