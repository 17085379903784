import React from 'react';

// components
import Dashboard from '../../modules/Dashboard';

const DashboardPage = () => {
  return <Dashboard />;
};

export default DashboardPage;
