import React from 'react';

// constants
import { ApprovalWorkflowChangeTypes } from '../../../../../../../../../enums/approvalManagement/approvalManagement';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import {
  ApprovalEntryNewModel,
  ApprovalRulesTemplateBaseModel,
} from '../../../../../../../../../typings/approvalManagement/accountManagement';

// components
import SectionIntro from '../../../../../../../../../components/DesignSystem/Common/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export interface AccountManagementApprovalRuleTemplateData {
  approvalRule: ApprovalRulesTemplateBaseModel;
}

interface IProps {
  approvalEntry: ApprovalEntryNewModel<AccountManagementApprovalRuleTemplateData>;
}

const AccountManagementApprovalRule = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderContent = () => {
    switch (approvalEntry.entry.changeType) {
      case ApprovalWorkflowChangeTypes.Create:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .new as AccountManagementApprovalRuleTemplateData
              }
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Delete:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.removed')}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .new as AccountManagementApprovalRuleTemplateData
              }
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Edit:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.old')}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .old as AccountManagementApprovalRuleTemplateData
              }
            />

            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .new as AccountManagementApprovalRuleTemplateData
              }
            />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default AccountManagementApprovalRule;
