import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

// components
import InfoTooltip from 'components/Tooltips/InfoTooltip';

const StructureBlockedStatus = () => {
  const { t } = useTranslation('onboarding');

  return (
    <StyledWrapper>
      <InfoTooltip
        iconSize="middle"
        tooltipContent={t('application.structure_blocked_description')}
      />
      {t('application.structure_blocked_status')}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
  color: ${({ theme }) => theme.colorWarning};
  font-size: ${({ theme }) => theme.fontSizeLg};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.marginSm};
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default StructureBlockedStatus;
