import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import {
  ConditionModel,
  LimitModel,
} from 'components/Forms/TemplateForms/Approval/TransactionRulesMatrixForm';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import { FormValuesModel as INewTransactionRuleFormValues } from 'components/ModalDialogs/TemplateModalDialogs/AccountManagement/TransactionRulesModal';
import {
  TransactionAccountModel,
  TransactionApprovalTemplateModel,
} from '../../typings/approvalManagement/accountManagement';
import {
  ApprovalRuleSetModel,
  INewTransactionRuleTemplate,
  ITransactionApprovalRuleEdit,
} from '../../typings/approvalManagement/accountManagement';

const transactionRulesAPI = {
  fetchTransactionRules: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<TransactionApprovalTemplateModel>>(
      `${APIConfig.approvalApi}/transaction/template`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchApprovalRuleById: (approvalRuleId: string) => {
    return APIService.get<ITransactionApprovalRuleEdit>(
      `${APIConfig.approvalApi}/transaction/template/${approvalRuleId}`,
    ).then(({ data }) => data);
  },

  addTransactionRule: (newRule: INewTransactionRuleTemplate) => {
    return APIService.post(
      `${APIConfig.approvalApi}/transaction/template`,
      newRule,
    ).then(({ data }) => data);
  },

  updateTransactionRule: (updatedRule: ITransactionApprovalRuleEdit) => {
    return APIService.patch(
      `${APIConfig.approvalApi}/transaction/template/${updatedRule.id}`,
      updatedRule,
    ).then(({ data }) => data);
  },

  fetchTransactionRuleAccounts: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<TransactionAccountModel>>(
      `${APIConfig.approvalApi}/transaction/template/accounts`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  deleteTransactionRuleById: (ruleId: string) => {
    return APIService.delete(
      `${APIConfig.approvalApi}/transaction/template/${ruleId}`,
    ).then(({ data }) => data);
  },
};

// ------ HELPERS -----

const generateNewTransactionRule = (
  formValues: INewTransactionRuleFormValues,
) => {
  const { bankAccounts, conditions, limits } = formValues;

  const result = {
    bankAccounts: bankAccounts || [],
    approvalRuleSets: getFormattedApprovalRulesSets(conditions, limits),
  } as INewTransactionRuleTemplate;

  return result;
};

const getFormattedApprovalRulesSets = (
  conditions: ConditionModel[],
  limits: LimitModel[],
) => {
  const result: ApprovalRuleSetModel[] = conditions.map(
    ({ conditions: innerConditions }, outerIndex) => ({
      approvalRuleItems: innerConditions.map(
        ({ requiredApproversCount, approvalGroup }) => ({
          minimunApprovalThreashold: requiredApproversCount,
          approvalGroup: { id: approvalGroup },
        }),
      ),
      approvalThreasholds: limits.map(
        ({ from = 0, to = 0, toInfinite }, innerIndex) => ({
          threasholdMin: from,
          threasholdMax: toInfinite ? -1 : to,
          ticked: conditions[outerIndex].limitStatuses[innerIndex].isChecked,
        }),
      ),
    }),
  );

  return result;
};

const transactionAPIHelpers = {
  generateNewTransactionRule,
};

export { transactionRulesAPI, transactionAPIHelpers };
