import React from 'react';

// components
import ResetPassword from '../../modules/Auth/ResetPassword';

const ResetPasswordPage = () => {
  return <ResetPassword />;
};

export default ResetPasswordPage;
