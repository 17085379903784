import React, { useMemo } from 'react';

// helpers
import { AccountsHelpers } from '../../../../../../helpers/accounts';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { transactionRulesAPI } from '../../../../../../api/accounting/transactionRulesAPI';
import { IApprovalRuleAccount } from '../../../../../../typings/approvalManagement/accountManagement';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

// TODO: rework logic for value part, currently we store the whole account object but instead it should be an array of account numbers
export interface AccountsForTransactionRulesAutocompleteProps
  extends AutocompleteProps<IApprovalRuleAccount> {
  value?: any;
}

const AccountsForTransactionRulesAutocomplete = ({
  value,
  initialValue,
  ...rest
}: AccountsForTransactionRulesAutocompleteProps) => {
  const formattedValue = useMemo(() => {
    return value && value.length
      ? value.map((e: any) => e.bankAccountNumber)
      : undefined;
  }, [value]);

  const fetchOptions = async () => {
    const { data } = await transactionRulesAPI.fetchTransactionRuleAccounts({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
    });

    let formattedOptions = data.map((e) => ({
      id: e.accountNumber,
      label: AccountsHelpers.generateAccountFullName(
        e.accountName,
        e.accountNumber,
        e.currencyCode,
      ),
      model: {
        bankAccountNumber: e.accountNumber,
        bankAccountName: e.accountName,
        bankAccountCurrency: e.currencyCode,
      },
    }));

    if (initialValue) {
      formattedOptions = [...(initialValue as any), ...formattedOptions];
    }

    return formattedOptions;
  };

  return (
    <FormAutocomplete
      {...rest}
      value={formattedValue}
      fetchData={fetchOptions}
      initialValue={initialValue}
      mode="multiple"
    />
  );
};

export default AccountsForTransactionRulesAutocomplete;
