import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ResetTabs } from '../..';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';

const RequestResetPasswordUsername = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <StyledText variant="body1" gutterBottom>
        {t(
          `reset_password.steps.${ResetTabs.RequestResetPasswordUsername}.description`,
        )}
      </StyledText>

      <FormField
        name="username"
        label={t(
          `reset_password.steps.${ResetTabs.RequestResetPasswordUsername}.form_fields.username`,
        )}
        component={FormInput}
      />
    </>
  );
};

const StyledText = styled(Text)`
  text-align: center;
`;

export default RequestResetPasswordUsername;
