import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import Text from '@core_components/Text';

interface IProps {
  isVerified: boolean;
}

const VerifiedStatusText = ({ isVerified }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <StyledText
      variant="body1"
      color={isVerified ? colorsTheme.colorPrimaryD2 : colorsTheme.colorError}
    >
      {isVerified ? t('verified') : t('not_verified')}
    </StyledText>
  );
};

const StyledText = styled(Text)`
  // to make sure this is everywhere in the same format
  text-transform: none !important;
`;

export default VerifiedStatusText;
