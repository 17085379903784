import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { BankAccountTemplateData } from '..';

// components
import { Table } from 'antd';

interface IProps {
  data: BankAccountTemplateData;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo(() => {
    return [
      { key: 'title', dataIndex: 'title', width: 170 },
      { key: 'value', dataIndex: 'value' },
    ];
  }, []);

  const dataSource = useMemo(() => {
    return [
      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.bank_account_changes_template.account_name',
        ),
        value: data.bankAccount.accountName,
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.bank_account_changes_template.account_number',
        ),
        value: data.bankAccount.accountNumber,
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.bank_account_changes_template.currency',
        ),
        value: data.bankAccount.accountCurrency,
      },
    ];
  }, [data]);

  return (
    <Table
      bordered
      size="middle"
      showHeader={false}
      pagination={false}
      scroll={{ x: 'max-content' }}
      dataSource={dataSource}
      columns={tableColumns}
    />
  );
};

export default InnerTemplate;
