import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ISession } from '../../../../../typings/profile/security';
import { DateHelpers } from 'helpers/date';
import { colorsTheme } from '../../../../../resources/theme/styled/colors';
import { SessionHelpers } from '../../../../../helpers/session';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { ReactComponent as ComputerIcon } from '../../../../../resources/icons/remix-icons/computer-line.svg';

export type IProps = RequiredPropsForTableModel<ISession>;

const SessionHistoryTable = (props: IProps) => {
  const { t } = useTranslation('profile');

  const renderSessionDuration = (session: ISession) => {
    const duration = moment.utc(
      moment(session.updatedAt).diff(session.createdAt),
    );
    return `${duration.format('H')}:${duration.format('mm')}:${duration.format('ss')}`;
  };

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('security.sessions.session_history.table.signin_date'),
        key: 'signInDate',
        render: (record: ISession) =>
          DateHelpers.formatDateToString(record.createdAt, 'llll'),
      },

      {
        title: t('security.sessions.session_history.table.device'),
        key: 'device',
        render: (record: ISession) => (
          <Text variant="body1">
            <StyledIconSVG
              component={ComputerIcon}
              color={colorsTheme.colorWhite}
            />
            {SessionHelpers.getDeviceName(record.device)}
          </Text>
        ),
      },

      {
        title: t('security.sessions.session_history.table.session_duration'),
        key: 'duration',
        render: renderSessionDuration,
      },
    ];
  }, []);

  return <Table columns={columns} {...props} />;
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default SessionHistoryTable;
