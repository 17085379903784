import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { message } from 'antd';
import { FormikProps } from 'formik';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';

// components
import ApprovalGroupModal, {
  ApprovalGroupFormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Approval/ApprovalGroupModal';

interface IProps {
  isVisible: boolean;
  approvalGroupId: string | null;
  closeCallback: (wasModified?: boolean) => void;
}

const UpdateApprovalGroup = ({
  isVisible,
  approvalGroupId,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const { response } = useFetch(
    () =>
      approvalGroupId
        ? onboardingAPI.fetchApprovalGroupById(approvalGroupId)
        : null,
    [approvalGroupId],
  );

  const initialFormValues = useMemo<ApprovalGroupFormValuesModel | null>(() => {
    if (!response) {
      return null;
    }

    const participants = response.participants.map((e) => ({
      userId: e.contactId,
      userFullName: e.name,
    }));

    return {
      name: response.name,
      description: response.description,
      participants,
      initialParticipantOptions: participants.map((e) => ({
        id: e.userId,
        label: e.userFullName,
        model: e,
      })),
    };
  }, [response]);

  const handleFormSubmit = async (values: ApprovalGroupFormValuesModel) => {
    if (approvalGroupId) {
      await onboardingAPI.updateApprovalGroupById(approvalGroupId, {
        name: values.name,
        description: values.description as string,
        participants: values.participants.map((e) => e.userId),
      });

      message.success(
        t(
          'administration.administration_rules.approval_groups.edit.success_message',
        ),
      );
    }
  };

  const handleDeleteCallback = async (
    form: FormikProps<ApprovalGroupFormValuesModel>,
  ) => {
    if (approvalGroupId) {
      await onboardingAPI.deleteApprovalGroupById(approvalGroupId);
      message.success(
        t(
          'administration.administration_rules.approval_groups.edit.success_delete_message',
        ),
      );
      await form.resetForm({
        values: {} as any,
        errors: {},
        touched: {},
        isSubmitting: false,
        isValidating: false,
        status: undefined,
        submitCount: 0,
      });
      closeCallback(true);
    }
  };

  return (
    <ApprovalGroupModal
      approvalWorkflowType="onboarding-approval-group"
      title={t(
        'administration.administration_rules.approval_groups.edit.title',
      )}
      onSubmit={handleFormSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onDelete={handleDeleteCallback}
    />
  );
};

export default UpdateApprovalGroup;
