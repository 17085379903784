// helpers
import { PermissionedAccessModel } from '.';

// Permission keys
export type DashboardPermissionKeys =
  | 'Dashboard_AccountsSection_View'
  | 'Dashboard_LastTransfersSection_View';

// Permission config
export const DashboardPermissions: Record<
  DashboardPermissionKeys,
  PermissionedAccessModel
> = {
  Dashboard_AccountsSection_View: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Accounting:Account:Read' }],
  },

  Dashboard_LastTransfersSection_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Account:Read' }],
  },
};
