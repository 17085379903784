import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { IOnboardingAccount } from 'typings/onboarding/account';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface OnboardingBankAccountsAutocompleteProps
  extends AutocompleteProps<IOnboardingAccount> {
  applicationId: string;
  skipAssociatedWithApprovalRule?: boolean;
}

const OnboardingBankAccountsAutocomplete = ({
  applicationId,
  skipAssociatedWithApprovalRule,
  ...rest
}: OnboardingBankAccountsAutocompleteProps) => {
  const { t } = useTranslation('onboarding');
  const activeClientGroupName = useSelector<StateModel, string>(
    (state) =>
      state.applications.activeApplication?.clientGroup?.clientGroupName || '',
  );

  const fetchOptions = async (searchText: string) => {
    const { data } = await onboardingAPI.fetchOnboardingBankAccounts(
      applicationId,
      skipAssociatedWithApprovalRule,
    );

    return data.map((e) => ({
      id: e._id,
      label: `${activeClientGroupName} ${e.currencyIsoCode} (${t(
        'administration.transaction_rules.introduction.purpose_of_bank_account',
      )} ${e.purpose})`,
      model: e,
    }));
  };

  return <FormAutocomplete {...rest} fetchData={fetchOptions} />;
};

export default OnboardingBankAccountsAutocomplete;
