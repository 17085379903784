import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

// components
import Text from '@core_components/Text';
import Button from '../../../../components/DesignSystem/Core/Button';
import ChangePasswordRuleModal from './ChangePasswordModal';
import { Col, Row } from 'antd';
import { CardWrapper } from 'components/Additional/CardWrapper';

export interface IFormValues {
  password: string;
  passwordConfirm: string;
}

const ChangePassword = () => {
  const { t } = useTranslation('profile');
  const [isVisible, setModalVisible] = useState(false);

  return (
    <CardWrapper>
      <Row gutter={[16, 16]}>
        <TitleCol xl={18} lg={18} md={12} sm={24} xs={24}>
          <Text variant="h4" weight="semi-bold">
            {t('security.change_password.title')}
          </Text>
        </TitleCol>

        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <StyledButton
            size="large"
            type="bordered"
            onClick={() => setModalVisible(true)}
          >
            {t('security.change_password.change_password_button')}
          </StyledButton>
        </Col>
      </Row>

      <ChangePasswordRuleModal
        isVisible={isVisible}
        closeCallback={() => setModalVisible(false)}
      />
    </CardWrapper>
  );
};

const TitleCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ChangePassword;
