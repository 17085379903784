import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Tooltip } from 'antd';
import { ReactComponent as ErrorIcon } from 'resources/icons/remix-icons/close-circle-line.svg';

export interface TableRecord {
  id: string;
  name: string;
  description: string;
  members: { id: string; name: string }[];
  errors: string[];
}

export type TableActions = 'view';

interface IProps extends RequiredPropsForTableModel<TableRecord> {
  onActionsClick?: (key: TableActions, id: TableRecord) => void;
}

const OnboardingApprovalGroupsTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo<TableColumnModel[]>(
    () => [
      {
        key: 'name',
        title: t('approval_groups.table.name'),
        width: '20%',
        render: (record: TableRecord) => {
          const name = (
            <DivAlignCenter>
              <EllipsisTooltip
                maxTextContainerWidth="200px"
                title={record.name}
              >
                {record.name}
              </EllipsisTooltip>
              {record.errors.length ? (
                <StyledTooltip
                  title={record.errors.map((e, i) => (
                    <Text key={i}>{e}</Text>
                  ))}
                >
                  <StyledIconSVG
                    color={colorsTheme.colorError}
                    component={ErrorIcon}
                  />
                </StyledTooltip>
              ) : null}
            </DivAlignCenter>
          );

          return onActionsClick ? (
            <Link onClick={() => onActionsClick('view', record)}>{name}</Link>
          ) : (
            name
          );
        },
      },

      {
        key: 'description',
        title: t('approval_groups.table.description'),
        width: '30%',
        render: (record: TableRecord) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="300px"
              title={record.description}
            >
              {record.description}
            </EllipsisTooltip>
          );
        },
      },

      {
        key: 'members',
        title: t('approval_groups.table.members'),
        width: '30%',
        render: (record: TableRecord) => {
          const users = record.members.map((member) => member.name).join(', ');
          return (
            <EllipsisTooltip maxTextContainerWidth="350px" title={users}>
              {users}
            </EllipsisTooltip>
          );
        },
      },
    ],
    [onActionsClick],
  );

  return <Table columns={tableColumns} {...rest} />;
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;
export default OnboardingApprovalGroupsTable;
