import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from 'typings/common';

// components
import { Descriptions } from 'antd';

export type DescriptionItem = {
  label: React.ReactNode;
  description: React.ReactNode;
  hidden?: boolean;
};

export interface DescriptionSectionProps extends StyledComponentProps {
  data: DescriptionItem[];
  size?: 'small' | 'middle';
  bordered?: boolean;
  background?: string;
}

const DescriptionSection = memo(
  ({
    data,
    size = 'middle',
    bordered = true,
    background,
    ...rest
  }: DescriptionSectionProps) => {
    return (
      <StyledDescriptions
        size={size}
        bordered={bordered}
        background={background}
        colon={false}
        column={1}
        labelStyle={{ width: 265, maxWidth: 265 }}
        {...rest}
      >
        {data.map((e, i) =>
          !e.hidden ? (
            <Descriptions.Item key={i} label={e.label} {...rest}>
              {e.description}
            </Descriptions.Item>
          ) : null,
        )}
      </StyledDescriptions>
    );
  },
);

const StyledDescriptions = styled(Descriptions)<{ background?: string }>`
  .ant-descriptions-view,
  .ant-descriptions-row,
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    border-color: ${({ theme }) => theme.colorDarkL4};
  }

  .ant-descriptions-item-label {
    background-color: unset !important;
    color: ${({ theme }) => theme.colorLight};
  }

  .ant-descriptions-item-content {
    background-color: unset;
    color: ${({ theme }) => theme.colorWhite};
  }

  background: ${({ theme, background }) =>
    background || theme.dragAndDropBackground};
`;

export default DescriptionSection;
