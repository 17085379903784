import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IApprovalGroupShort } from 'typings/approvalManagement/accountManagement';
import { SelectOption, SelectProps } from '@core_components/Select';
import { ConditionModel, FormValuesModel, TableColumnBaseModel } from '.';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import IconSVG from '@core_components/IconSVG';
import Checkbox from '@core_components/Checkbox';
import FormField from '@core_components/FormField';
import FormSelect from '@common_components/Form/FormSelect';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import { Col, Row } from 'antd';
import { ReactComponent as DeleteIcon } from 'resources/icons/remix-icons/delete-bin-6-line.svg';

interface IProps extends TableColumnBaseModel {
  record: any;
  rowIndex: number;
  approvalGroups: IApprovalGroupShort[];
  handleAddAnd: (toPosition: number) => void;
}

const ContentCell = ({
  dataIndex,
  rowIndex,
  colIndex,
  record,
  handleAddAnd,
  approvalGroups,
}: IProps) => {
  const { t } = useTranslation('account_management');
  const formik = useFormikContext<FormValuesModel>();

  const getApproversRequiredOptionsArray = (
    innerIndex: number,
  ): SelectOption[] => {
    const result: SelectOption[] = [];
    const approvalGroupsCopy = approvalGroups.slice();
    const { approvalGroup } =
      formik.values.conditions[rowIndex].conditions[innerIndex];
    const selectedGroupIdx = approvalGroupsCopy.findIndex(
      (el) => el.id === approvalGroup,
    );

    if (selectedGroupIdx === -1) {
      return result;
    }

    const participantsCount =
      approvalGroupsCopy[selectedGroupIdx].participants.length;

    result.push(
      ...Array.from(Array(participantsCount), (_, i) => ({
        id: i + 1,
        label: i + 1,
      })),
    );

    return result;
  };

  const approvalGroupFieldOptions = useMemo<
    SelectOption<IApprovalGroupShort>[]
  >(() => {
    return approvalGroups.map((e) => {
      return {
        id: e.id,
        disabled: !e.isActive,
        model: e,
        label: e.isActive ? (
          e.name
        ) : (
          <StyledApprovalGroupOptionWrapper>
            <StyledApprovalGroupOptionTextWrapper>
              {e.name}
            </StyledApprovalGroupOptionTextWrapper>
            <InfoTooltip
              tooltipContent={t(
                'approval_groups.approval_group_in_review_warning_message',
              )}
            />
          </StyledApprovalGroupOptionWrapper>
        ),
      };
    });
  }, [approvalGroups, t]);

  const handleGroupSelect = (
    fieldName: string,
    newGroupId: string,
    innerIndex: number,
  ) => {
    const { approvalGroup } =
      formik.values.conditions[rowIndex].conditions[innerIndex];

    if (!approvalGroup || newGroupId === approvalGroup) {
      formik.setFieldValue(fieldName, newGroupId);
    } else {
      formik.setFieldValue(fieldName, newGroupId);
      formik.setFieldValue(
        `conditions.${rowIndex}.conditions.${innerIndex}.requiredApproversCount`,
        0,
      );
    }
  };

  const onDeleteRow = (conditionIndex: number) => {
    const approvalRulesCopy = formik.values.conditions.slice();
    approvalRulesCopy.splice(conditionIndex, 1);
    formik.setFieldValue('conditions', approvalRulesCopy);
  };

  const onDeleteAnd = (rowIndex: number, endEntryIndex: number) => {
    const approvalRulesCopy = formik.values.conditions.slice();
    approvalRulesCopy[rowIndex].conditions.splice(endEntryIndex, 1);
    formik.setFieldValue('conditions', approvalRulesCopy);
  };

  const renderContentCell = () => {
    let result = null;

    switch (dataIndex) {
      case 'conditions':
        {
          const isLastRowInDataSource =
            rowIndex === formik.values.conditions.length - 1;
          const shouldRenderDeleteSectionBtn =
            formik.values.conditions.length > 1;

          result = (
            <>
              {shouldRenderDeleteSectionBtn && (
                <EndAlignWrapper onClick={() => onDeleteRow(rowIndex)}>
                  <StyledIconSVG
                    component={DeleteIcon}
                    color={colorsTheme.colorError}
                  />
                </EndAlignWrapper>
              )}

              {record.conditions.map((_: any, innerIndex: number) => {
                const approvalGroupFieldName = `conditions.${rowIndex}.conditions.${innerIndex}.approvalGroup`;
                const isApproversDisabled =
                  !formik.values.conditions[rowIndex].conditions[innerIndex]
                    .approvalGroup;
                const shouldRenderANDBtn =
                  innerIndex === record.conditions.length - 1;
                const shouldRenderAND =
                  innerIndex < record.conditions.length - 1;
                const shouldRenderOR =
                  !isLastRowInDataSource &&
                  innerIndex === record.conditions.length - 1;

                return (
                  <RelativeWrapper key={`${rowIndex}-${innerIndex}`}>
                    <StyledRow gutter={[16, 16]}>
                      <Col span={24}>
                        <FormField<SelectProps<IApprovalGroupShort>>
                          name={approvalGroupFieldName}
                          component={FormSelect}
                          shouldShowErrorMessage={false}
                          additionalProps={{
                            options: approvalGroupFieldOptions,
                            onChange: (value) =>
                              handleGroupSelect(
                                approvalGroupFieldName,
                                value,
                                innerIndex,
                              ),
                          }}
                        />
                      </Col>

                      <Col span={24}>
                        <FormField<SelectProps>
                          name={`conditions.${rowIndex}.conditions.${innerIndex}.requiredApproversCount`}
                          disabled={isApproversDisabled}
                          component={StyledSelect}
                          shouldShowErrorMessage={false}
                          additionalProps={{
                            options:
                              getApproversRequiredOptionsArray(innerIndex),
                          }}
                        />
                      </Col>
                    </StyledRow>

                    {shouldRenderAND && (
                      <AndContainer>
                        {t('approval_rules.and')}
                        <StyledIconSVG
                          component={DeleteIcon}
                          color={colorsTheme.colorError}
                          onClick={() => onDeleteAnd(rowIndex, innerIndex + 1)}
                        />
                      </AndContainer>
                    )}

                    {shouldRenderOR && (
                      <ORContainer>
                        <Text variant="body1" weight="semi-bold">
                          {t('approval_rules.or')}
                        </Text>
                      </ORContainer>
                    )}

                    {shouldRenderANDBtn && (
                      <AlignEndWrapper>
                        <Button onClick={() => handleAddAnd(rowIndex)}>
                          {t('approval_rules.add_and_button')}
                        </Button>
                      </AlignEndWrapper>
                    )}
                  </RelativeWrapper>
                );
              })}
            </>
          );
        }
        break;

      case 'limitStatuses':
        {
          const checkboxValue =
            formik.values.conditions[rowIndex].limitStatuses[colIndex]
              .isChecked;

          result = (
            <AlignCenterWrapper>
              <Checkbox
                value={checkboxValue}
                onChange={handleLimitCheckboxChange}
              />
            </AlignCenterWrapper>
          );
        }
        break;
    }

    return result;
  };

  const handleLimitCheckboxChange = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;

    const conditionsCopy = formik.values.conditions.slice();
    conditionsCopy[rowIndex].limitStatuses[colIndex].isChecked = value;

    formik.setFieldValue(
      `conditions.${rowIndex}.limitStatuses.${colIndex}.isChecked`,
      value,
    );

    const isLimitStillUsed = value || isLimitUsed(colIndex, conditionsCopy);
    formik.setFieldValue(`limits[${colIndex}].isUsed`, isLimitStillUsed);
  };

  // Check if the limit is using in at least of one condition
  const isLimitUsed = (
    limitIndex: number,
    updatedConditions: ConditionModel[],
  ) => {
    let isUsing = false;

    for (let i = 0; i < updatedConditions.length; i++) {
      const { limitStatuses } = updatedConditions[i];

      if (limitStatuses[limitIndex].isChecked) {
        isUsing = true;
        break;
      }
    }

    return isUsing;
  };

  return <td>{renderContentCell()}</td>;
};

const StyledRow = styled(Row)`
  margin: ${({ theme }) => `${theme.marginSm} 0`};
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const AlignCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlignEndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AndContainer = styled.div`
  font-weight: 600;
  margin-left: ${({ theme }) => theme.marginSm};
`;

const ORContainer = styled.div`
  position: absolute;
  bottom: -33px;
  left: 0;

  width: 55px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.tableCardBackground};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginSm};
  cursor: pointer;
`;

const EndAlignWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledApprovalGroupOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledApprovalGroupOptionTextWrapper = styled.div`
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSelect = styled(FormSelect)`
  width: 100%;
`;

export default ContentCell;
