import React from 'react';

// helpers
import { AutocompleteProps } from '@core_components/Autocomplete';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface CurrencyAutocompleteProps extends AutocompleteProps<string> {
  chain?: string;
}

const CurrencyAutocomplete = ({
  chain,
  ...rest
}: CurrencyAutocompleteProps) => {
  const fetchOptions = async () => {
    const response = await transferTemplatesAPI.fetchCurrenciesByNetwork(chain);
    return response.map((e) => ({
      id: e,
      label: e,
      model: e,
    }));
  };

  return <FormAutocomplete {...rest} fetchData={fetchOptions} />;
};

export default CurrencyAutocomplete;
