import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { ApprovalEntryNewModel } from '../../../../../../../typings/approvalManagement/accountManagement';

// components
import EntryDetails from './EntryDetails';
import SectionIntro from '../../../../../../../components/DesignSystem/Common/Texts/SectionIntro';

interface IProps {
  approvalEntry: ApprovalEntryNewModel;
}

const ApprovalEntryDetailsSection = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  return (
    <>
      <SectionIntro
        titleVariant="h4"
        title={t(
          'pending_approvals.approval_details_modal.details_section.title',
        )}
      />
      <EntryDetails approvalEntry={approvalEntry} />
    </>
  );
};

export default ApprovalEntryDetailsSection;
