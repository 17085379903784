import React, { memo } from 'react';

// helpers
import { Moment } from 'moment';
import { ResetUsernameValidationSchema } from 'validations/profile/auth';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';

export type StepKeys = 'user_data' | 'email_confirmation';

export type FormValuesModel = {
  step: StepKeys;
  user: {
    name: string;
    email: string;
    dateOfBirth: Moment | null;
    clientGroup: string;
  };
  emailConfirmation: {
    otp: string;
  };
};

type IProps = RequiredPropsForFormModel<FormValuesModel>;

const ForgotUsernameForm = memo(
  ({ onSubmit, initialValues, onSubmitError, ...rest }: IProps) => {
    return (
      <Form<FormValuesModel>
        {...rest}
        onSubmit={onSubmit}
        initialValues={initialValues}
        onSubmitError={onSubmitError}
        validationSchema={ResetUsernameValidationSchema}
        renderForm={<InnerForm />}
      />
    );
  },
);

export default ForgotUsernameForm;
