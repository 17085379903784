import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
} from 'enums/onboarding/crm';

// components
import StatusLabel from 'components/Typography/StatusLabel';
import UpdatedPermissionsInfo from './UpdatedPermissionsInfo';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type ClientGroupUserUpdateTypes =
  | 'create'
  | 'deactivate'
  | 'update_permissions';

export type ClientGroupUserChange = {
  action: ClientGroupUserUpdateTypes;
  clientGroupUserId: string;
  clientGroupUserName: string;
  newPermissions: {
    adminPermissionType: AdministrationPermissionTypes;
    type: AccountUserPermissionTypes;
  } | null;
  oldPermissions: {
    adminPermissionType: AdministrationPermissionTypes;
    type: AccountUserPermissionTypes;
  } | null;
};

type IProps = RequiredPropsForTableModel<ClientGroupUserChange>;

const STATUS_MAP = {
  info: [],
  warning: ['update_permissions'],
  success: ['create'],
  error: ['deactivate'],
};

const ClientGroupUserChangesTable = (props: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo<TableColumnModel[]>(
    () => [
      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.name',
        ),
        key: 'name',
        render: (record: ClientGroupUserChange) => record.clientGroupUserName,
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.action',
        ),
        key: 'action',
        render: (record: ClientGroupUserChange) => (
          <StatusLabel
            statusMap={STATUS_MAP}
            status={record.action}
            content={t(
              `pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.user_change_types.${record.action}`,
            )}
          />
        ),
      },
    ],
    [],
  );

  const expandableRow = useMemo(
    () => ({
      rowExpandable: (record: ClientGroupUserChange) =>
        record.action === 'update_permissions',
      expandedRowRender: (record: ClientGroupUserChange) => (
        <UpdatedPermissionsInfo record={record} />
      ),
    }),
    [],
  );

  return <Table {...props} expandable={expandableRow} columns={tableColumns} />;
};

export default ClientGroupUserChangesTable;
