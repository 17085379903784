import { PermissionedAccessModel } from '.';

// Permission keys
export type OnboardingPermissionKeys = 'Onboarding_Pages';

// Permission config
export const OnboardingPermissions: Record<
  OnboardingPermissionKeys,
  PermissionedAccessModel
> = {
  Onboarding_Pages: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'CRM:OnboardingProcess:Read' },
      { permissionKey: 'CRM:Application:Read' },
    ],
  },
};
