import React, { Ref } from 'react';

// helpers
import { FormikHelpers, FormikProps } from 'formik';
import { SourceOfWealthValidationSchema } from '../../../../../validations/onboarding-new/source-of-wealth';
import { FormValuesModel as EmploymentHistoryFormValues } from '../EmploymentHistoryShortForm';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  isBasedOnExistingRelationship?: boolean;
  sourceOfWealthCategories: string[];
  sourceOfWealthDescription: string;
  employmentHistory: EmploymentHistoryFormValues[];
}

type SubmitType = (
  values: FormValuesModel,
  helpers: FormikHelpers<FormValuesModel>,
) => Promise<void>;

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>> | undefined;
  initialFormValues: FormValuesModel | null;
  onSubmit: SubmitType | null;

  disabled?: boolean;
  showOnlySaveButton?: boolean;
}

const SourceOfWealthForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      confirmExitWithoutSaving
      onSubmit={onSubmit ? onSubmit : console.log}
      disabled={disabled}
      initialValues={initialFormValues}
      validationSchema={SourceOfWealthValidationSchema}
      renderForm={
        <>
          <InnerForm />
          <HideIfDisabledForm>
            <SubmitButtons showOnlySaveButton={showOnlySaveButton} />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default SourceOfWealthForm;
