import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { UsernameValidationSchema } from '../../../../../../validations/profile/auth';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import SubmitButton from '../../../../../Buttons/SubmitButton';

export interface FormValuesModel {
  username: string;
}

interface IProps {
  initialFormValues: FormValuesModel;
  handleSubmit: (values: FormValuesModel) => Promise<void>;
}

const SignInUsernameForm = ({ handleSubmit, initialFormValues }: IProps) => {
  const { t } = useTranslation('auth');

  return (
    <Form<FormValuesModel>
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      validationSchema={UsernameValidationSchema}
      renderForm={
        <>
          <InnerForm />
          <SubmitButton fullWidth type="primary">
            {t('signin.steps.check_username.submit_button')}
          </SubmitButton>
        </>
      }
    />
  );
};

export default SignInUsernameForm;
