import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FetchResponseModel } from '../../typings/common';
import {
  ApprovalActions,
  TransactionTypes,
} from '../../enums/approvalManagement/approvalManagement';

export interface ApprovalActivityEntryModel {
  action: ApprovalActions;
  entry: TransactionTypes;
  userName: string;
  fromAccountName: string;
  recipientAccountName: string;
  date: string;
}

const approvalTransactionsAPI = {
  approveTransaction: (
    workflowId: string,
    reason: string,
    skipNotification?: boolean,
  ) => {
    return APIService.post(
      `${APIConfig.approvalApi}/transaction/workflows/${workflowId}/approve`,
      {
        reason,
        actionType: skipNotification ? 'batch' : undefined,
      },
    ).then(({ data }) => data);
  },

  rejectTransaction: (
    workflowId: string,
    reason: string,
    skipNotification?: boolean,
  ) => {
    return APIService.post(
      `${APIConfig.approvalApi}/transaction/workflows/${workflowId}/reject`,
      {
        reason,
        actionType: skipNotification ? 'batch' : undefined,
      },
    ).then(({ data }) => data);
  },

  // TODO: discuss what this endpoint will return,
  // whether it can be approved activity only for transactions or for something else as well
  fetchApprovalActivity: () => {
    return new Promise<FetchResponseModel<any>>((resolve) =>
      setTimeout(() => resolve({ data: [], total: 0 }), 500),
    );
  },
};

export { approvalTransactionsAPI };
