import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { setUserTags } from 'redux/actions/auth';
import { colorsTheme } from 'resources/theme/styled/colors';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import { ONBOARDING_TRANSACTION_RULES_INTRODUCTION_SUBMITTED } from 'constants/userTags';

// components
import Text from '@core_components/Text';
import Summary from './Summary';
import IconSVG from '@core_components/IconSVG';
import AsyncButton from '@common_components/Buttons/AsyncButton';
import SectionIntro from '@common_components/Texts/SectionIntro';
import Introduction from './Introduction';
import ApprovalGroups from './ApprovalGroups';
import ApprovalRules from './ApprovalRules';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import { CardWrapper } from 'components/Additional/CardWrapper';
import { Col, Divider, Row } from 'antd';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

interface IProps {
  onboardingData: OnboardingStatusModel;
}
type StepKeys =
  | 'introduction'
  | 'approval-groups'
  | 'approval-rules'
  | 'summary';

const TransactionRules = ({ onboardingData }: IProps) => {
  const { t } = useTranslation(['onboarding', 'common']);
  const dispatch = useDispatch();
  const userTags = useSelector<StateModel, string[]>(
    (store) => store.auth.profileData?.tags || [],
  );
  const [stepStatuses, setStepStatuses] = useState<Record<StepKeys, boolean>>({
    introduction: isCompleted('introduction'),
    'approval-groups': isCompleted('approval-groups'),
    'approval-rules': isCompleted('approval-rules'),
    summary: isCompleted('summary'),
  });

  const steps: {
    key: StepKeys;
    title: React.ReactNode;
    component: React.ReactNode;
  }[] = [
    {
      key: 'introduction',
      title: (
        <DivAlignCenter>
          {t('administration.transaction_rules.introduction.title')}
          {stepStatuses['introduction'] ? (
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          ) : null}
        </DivAlignCenter>
      ),
      component: <Introduction />,
    },

    {
      key: 'approval-groups',
      title: (
        <DivAlignCenter>
          {t('administration.transaction_rules.approval_groups.title')}
          {stepStatuses['approval-groups'] ? (
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          ) : null}
        </DivAlignCenter>
      ),
      component: (
        <ApprovalGroups
          onAction={() => handleTabsDataChange('approval-groups')}
        />
      ),
    },

    {
      key: 'approval-rules',
      title: (
        <DivAlignCenter>
          {t('administration.transaction_rules.approval_rules.title')}
          {stepStatuses['approval-rules'] ? (
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          ) : null}
        </DivAlignCenter>
      ),
      component: (
        <ApprovalRules
          applicationId={onboardingData.applicationId}
          onAction={() => handleTabsDataChange('approval-rules')}
        />
      ),
    },

    {
      key: 'summary',
      title: (
        <DivAlignCenter>
          {t('administration.transaction_rules.summary.title')}
          {stepStatuses['summary'] ? (
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorPrimary}
            />
          ) : null}
        </DivAlignCenter>
      ),
      component: <Summary />,
    },
  ] as const;

  function handleTabsDataChange(step: StepKeys) {
    switch (step) {
      case 'approval-groups':
        {
          if (stepStatuses['approval-groups']) {
            setStepStatuses((prev) => ({
              ...prev,
              'approval-groups': false,
              'approval-rules': false,
              summary: false,
            }));
          }
        }
        break;

      case 'approval-rules':
        {
          if (stepStatuses['approval-rules']) {
            setStepStatuses((prev) => ({
              ...prev,
              'approval-rules': false,
              summary: false,
            }));
          }
        }
        break;
    }
  }

  const getInitialStep = (): StepKeys => {
    if (!isCompleted('introduction')) {
      return 'introduction';
    }

    if (!isCompleted('approval-groups')) {
      return 'approval-groups';
    }

    if (!isCompleted('approval-rules')) {
      return 'approval-rules';
    }

    return 'summary';
  };

  function isCompleted(step: StepKeys) {
    switch (step) {
      case 'introduction':
        return userTags.includes(
          ONBOARDING_TRANSACTION_RULES_INTRODUCTION_SUBMITTED,
        );

      case 'approval-groups':
        return onboardingData.approvalConfigurationCompletedSteps
          .transactionApprovalGroups;

      case 'approval-rules':
        return (
          onboardingData.approvalConfigurationCompletedSteps
            .transactionApprovalGroups &&
          onboardingData.approvalConfigurationCompletedSteps
            .transactionApprovalRules
        );

      case 'summary':
        return onboardingData.approvalConfigurationCompletedSteps
          .transactionApprovalRules;
    }
  }

  const [selectedStep, setSelectedStep] = useState<StepKeys>(getInitialStep());

  const content = useMemo(() => {
    const step = steps.find((e) => e.key === selectedStep);
    return step ? step.component : null;
  }, [selectedStep]);

  const handleNext = async () => {
    switch (selectedStep) {
      case 'introduction': {
        await userAPI.setUserTags([
          ONBOARDING_TRANSACTION_RULES_INTRODUCTION_SUBMITTED,
        ]);
        dispatch(
          setUserTags([
            ...userTags,
            ONBOARDING_TRANSACTION_RULES_INTRODUCTION_SUBMITTED,
          ]),
        );
        setStepStatuses((prev) => ({ ...prev, introduction: true }));
        setSelectedStep('approval-groups');
        break;
      }

      case 'approval-groups': {
        await onboardingAPI.submitTransactionApprovalGroupsStep();
        setStepStatuses((prev) => ({ ...prev, 'approval-groups': true }));
        setSelectedStep('approval-rules');
        break;
      }

      case 'approval-rules': {
        await onboardingAPI.submitTransactionApprovalRulesStep();
        setStepStatuses((prev) => ({
          ...prev,
          'approval-rules': true,
          summary: true,
        }));
        setSelectedStep('summary');
        break;
      }
    }
  };

  const canSelectStep = (step: StepKeys) => {
    switch (step) {
      case 'introduction':
        return true;

      case 'approval-groups':
        return stepStatuses['introduction'];

      case 'approval-rules':
        return stepStatuses['approval-groups'];

      case 'summary':
        return stepStatuses['approval-rules'];
    }
  };

  return (
    <CardWrapper>
      <Wrapper>
        <div>
          <SectionIntro
            title={
              <DivAlignCenter>
                {t('administration.transaction_rules.title')}
              </DivAlignCenter>
            }
          />
          <Row wrap={false}>
            <Col span={19}>
              <Text gutterBottom>
                {t('administration.transaction_rules.description')}
              </Text>
              {content}
            </Col>
            <Col span={1}>
              <DividerWrapper>
                <StyledDivider type="vertical" />
              </DividerWrapper>
            </Col>
            <Col span={4}>
              {steps.map((e) => (
                <StyledText
                  key={e.key}
                  disabled={!canSelectStep(e.key)}
                  active={selectedStep == e.key}
                  onClick={() => canSelectStep(e.key) && setSelectedStep(e.key)}
                >
                  {e.title}
                </StyledText>
              ))}
            </Col>
          </Row>
        </div>
        <div>
          <HideIfDisabledForm>
            {selectedStep !== 'summary' ? (
              <StyledAsyncButton size="middle" onClick={handleNext}>
                {t('next', { ns: 'common' })}
              </StyledAsyncButton>
            ) : null}
          </HideIfDisabledForm>
        </div>
      </Wrapper>
    </CardWrapper>
  );
};

const StyledText = styled(Text)<{ active?: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-bottom: ${({ theme }) => theme.marginXXs};
  transition: ${({ theme }) => theme.defaultTransition};

  ${({ active }) => `color: ${active ? colorsTheme.colorPrimary : ''}`};

  &:hover {
    color: ${({ theme }) => theme.colorPrimary};
  }
`;

const StyledDivider = styled(Divider)`
  height: 100%;
  border-color: rgba(66, 81, 91, 0.7);
`;

const Wrapper = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledAsyncButton = styled(AsyncButton)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const DividerWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
`;

export default TransactionRules;
