import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { userAPI } from '../../../../../../api/profile/userAPI';
import { message } from 'antd';
import { SecurityKeyModel } from '../../../../../../typings/profile/fido';

// components
import SecurityKeyModal, {
  FormValuesModel,
} from '../../../../../../components/ModalDialogs/TemplateModalDialogs/UserProfile/SecurityKeyModal';

interface IProps {
  securityKey: SecurityKeyModel | null;
  isVisible: boolean;
  closeCallback: (wasUpdated?: boolean) => void;
}

const EditSecurityKeyModal = ({
  securityKey,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('profile');

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!securityKey) {
      return null;
    }

    const { name } = securityKey;

    return { name };
  }, [securityKey]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (securityKey) {
      await userAPI.updateSecurityKeyById(securityKey._id, {
        name: values.name,
      });
      message.success(
        t(
          'security.two_step_verification.security_key.edit_key.success_submit_message',
        ),
      );
    }
  };

  return (
    <SecurityKeyModal
      title={t('security.two_step_verification.security_key.edit_key.title')}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default EditSecurityKeyModal;
