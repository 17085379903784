import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import Text from '@core_components/Text';
import OTPField from '../../../../../components/Forms/FormComponents/OTPField';
import FormField from '@core_components/FormField';

const SecurityCodeVerification = () => {
  const { t } = useTranslation('auth');

  return (
    <StyledVerificationWrapper>
      <Text>
        {t('reset_password.steps.otp_verification.email.description')}
      </Text>
      <OTPFieldWrapper>
        <FormField
          name="verificationCode"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </OTPFieldWrapper>
    </StyledVerificationWrapper>
  );
};

const StyledVerificationWrapper = styled.div`
  text-align: center;
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginMd};
  margin-bottom: 0;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

export default SecurityCodeVerification;
