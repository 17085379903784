import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { ContactHelpers } from '../../../../../../../helpers/crm/contact';
import { useFormikContext } from 'formik';
import { IAdditionalField } from '../../../../../../../typings/application/relationship-template';
import { OnboardingEntryTypes } from '../../../../../../../enums/onboarding/crm';
import { FormValuesModel, OwnershipFormItem } from '../..';

// components
import FormField from '@core_components/FormField';
import DeleteButton from '../../../../../../DesignSystem/Common/Buttons/DeleteButton';
import AdditionalField from '../../../../../../Forms/FormComponents/AdditionalField';
import HideIfDisabledForm from '../../../../../../Forms/HideIfDisabledForm';
import Table, { TableColumnModel } from '@core_components/Table';

const OwnershipList = () => {
  const { t } = useTranslation('account_management');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const renderAdditionalField = (item: IAdditionalField, rowIndex: number) => {
    return (
      <FormField
        key={item.name}
        name={`ownerships.${rowIndex}.additionalFields.${item.name}.value`}
        component={AdditionalField}
        shouldShowErrorMessage={false}
        additionalProps={{
          field: {
            type: item.type,
            options: item.options.selectOptions,
            formDocument: item.options.formDocument,
            documentAssociation: {
              id: values.relationshipTo.id,
              type: values.relationshipTo.type,
            },
          },
        }}
      />
    );
  };

  const renderDelete = (rowIndex: number, record: OwnershipFormItem) => {
    return (
      <HideIfDisabledForm hideIfSubmitting>
        <CenterAlign>
          <DeleteButton onClick={() => deleteOwnerShip(record, rowIndex)} />
        </CenterAlign>
      </HideIfDisabledForm>
    );
  };

  const deleteOwnerShip = (record: OwnershipFormItem, index: number) => {
    deleteOwnershipByIndex(index);
  };

  const deleteOwnershipByIndex = (index: number) => {
    const arrayCopy = [...values.ownerships];
    arrayCopy.splice(index, 1);
    setFieldValue('ownerships', arrayCopy);
  };

  const columns = useMemo<TableColumnModel[]>(() => {
    const additionalFieldColumns =
      values.ownershipTemplate.additionalFields
        .filter(({ options }) => options.onboardingRequired)
        .map((field) => {
          return {
            width: '230px',
            title: field.name,
            key: `additional-field-${field.name}`,
            render: (record: OwnershipFormItem, _: any, rowIndex: number) =>
              renderAdditionalField(field, rowIndex),
          };
        }) || [];

    return [
      {
        title: t('client_profile.ownership_modal.table.name'),
        key: 'name',
        render: (record: OwnershipFormItem) =>
          record.type === OnboardingEntryTypes.Contact
            ? ContactHelpers.getFormattedContactName(
                record.contact?.firstName || '',
                record.contact?.lastName || '',
                record.contact?.middleName || '',
              )
            : record.organization?.name,
      },
      {
        title: t('client_profile.ownership_modal.table.type'),
        key: 'type',
        render: (record: OwnershipFormItem) =>
          record.type === OnboardingEntryTypes.Contact
            ? t('client_profile.ownership_modal.table.contact_type')
            : record.keyRelationshipTemplate.name,
      },
      ...additionalFieldColumns,
      {
        key: 'delete',
        width: 60,
        render: (record: OwnershipFormItem, _: any, rowIndex: number) =>
          renderDelete(rowIndex, record),
      },
    ];
  }, [values]);

  return <StyledTable data={values.ownerships} columns={columns} bordered />;
};

const StyledTable = styled(Table)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const CenterAlign = styled.div`
  display: flex;
  justify-content: center;
`;

export default OwnershipList;
