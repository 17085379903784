import React, { ReactNode } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { StyledComponentProps } from 'typings/common';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';

// components
import Text from '@core_components/Text';
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { Col, Row } from 'antd';
import { CardWrapper } from 'components/Additional/CardWrapper';
import { ReactComponent as GroupIcon } from 'resources/icons/remix-icons/group-line.svg';

interface IProps extends StyledComponentProps {
  children?: ReactNode;
  clientGroup: {
    name: string;
    type: OnboardingEntryTypes;
    entityType: string;
  };
  onClick?: () => void;
  removeMargin?: boolean;
}

const ClientGroupDetails = ({
  clientGroup,
  children,
  removeMargin,
  ...rest
}: IProps) => {
  const { t } = useTranslation('connections');

  return (
    <StyledCardWrapper noMargin={removeMargin} {...rest}>
      <Row gutter={[10, 10]}>
        <Col flex={1} span={24}>
          <Content>
            <StyledIconSVG
              size="middle"
              component={GroupIcon}
              color={colorsTheme.colorWhite}
            />
            <StyledDivAlignCenter>
              <Text variant="h6" weight="bold" color={colorsTheme.colorWhite}>
                {clientGroup.name}
              </Text>
              <Text>
                {t('client_groups.type')}:&nbsp;
                <StyledText variant="span" weight="semi-bold">
                  {clientGroup.type}
                </StyledText>
              </Text>
              <Text>
                {t('client_groups.entity_type')}:&nbsp;
                <Text variant="span" weight="semi-bold">
                  {clientGroup.entityType}
                </Text>
              </Text>
            </StyledDivAlignCenter>
          </Content>
        </Col>
        {children && <Col>{children}</Col>}
      </Row>
    </StyledCardWrapper>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
  margin-top: 3px;
`;

const StyledText = styled(Text)`
  text-transform: capitalize;
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  flex-direction: column;
  align-items: start;
`;

const Content = styled.div`
  display: flex;
  align-items: start;
`;

const StyledCardWrapper = styled(CardWrapper)`
  width: 100%;
  padding: ${({ theme }) => theme.paddingXl};
`;

export default ClientGroupDetails;
