import { RefObject, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

type ScrollDirection = 'top' | 'bottom';

function useInfinityScroll(
  onCallback: () => Promise<any>,
  ref: RefObject<HTMLElement>,
  hasMore = false,
  scrollDirection: ScrollDirection,
  isDisabled = false,
) {
  const handleScroll = useCallback(
    debounce((e) => {
      if (ref.current && e.target) {
        if (ref.current.scrollTop === 0) {
          ref.current.scrollTop = 1;
        }
        if (
          Math.abs(e.target.scrollTop) <= 100 &&
          hasMore &&
          scrollDirection === 'top'
        ) {
          onCallback();
        } else if (
          e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <
            100 &&
          hasMore &&
          scrollDirection === 'bottom'
        ) {
          onCallback();
        }
      }
    }, 250),
    [onCallback, ref],
  );

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('scroll', handleScroll);

      return function () {
        if (ref.current) {
          ref.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [ref, handleScroll]);

  useEffect(() => {
    if (!isDisabled) {
      onCallback();
    }
  }, [isDisabled]);
}
export default useInfinityScroll;
