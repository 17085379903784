import React, { useState, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../resources/theme/styled/colors';
import { accountNotificationsAPI } from '../../../../api/profile/accountNotificationsAPI';
import { NotificationPreferenceModel } from '../../../../typings/profile/accountNotifications';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../../components/DesignSystem/Core/IconSVG';
import LoadingWrapper from '../../../../components/WrapperComponents/LoadingWrapper';
import { CardWrapper } from 'components/Additional/CardWrapper';
import { ReactComponent as CheckIcon } from '../../../../resources/icons/remix-icons/check-line.svg';
import { Col, Divider, message, Row, Switch } from 'antd';

interface INotificationPreference {
  key: string;
  loading: boolean;
  isEmailEnabled: boolean;
  isSmsEnabled: boolean;
  title: string;
  helperMessage: string;
}

enum NotificationsType {
  Email = 0,
  SMS,
}

const NotificationPreferences = () => {
  const { t } = useTranslation(['profile', 'common']);
  const [notificationPreferences, setNotificationPreferences] = useState<
    INotificationPreference[]
  >([]);
  const { response, loading } = useFetch(
    () => accountNotificationsAPI.fetchNotificationPreferences(),
    [],
  );

  useEffect(() => {
    if (response) {
      const formattedPreferences = response.data.map((e) => ({
        key: e.code,
        isEmailEnabled: e.emailEnabled,
        isSmsEnabled: e.smsEnabled,
        loading: false,
        title: t(
          `account_notifications.notification_preferences.${e.code}`,
        ) as string,
        helperMessage: t(
          `account_notifications.notification_preferences.${e.code}_description`,
        ),
      }));

      setNotificationPreferences(formattedPreferences);
    }
  }, [response]);

  const handleToggleChange = async (
    newState: boolean,
    rowIndex: number,
    type: NotificationsType,
  ) => {
    setNotificationPreferences((prev) => {
      const newPreferences = [...prev];
      newPreferences[rowIndex] = { ...newPreferences[rowIndex], loading: true };
      return newPreferences;
    });

    try {
      const preferencesCopy = [...notificationPreferences];
      const updatedNotification = { ...preferencesCopy[rowIndex] };

      if (type === NotificationsType.Email) {
        updatedNotification.isEmailEnabled = newState;
      } else {
        updatedNotification.isSmsEnabled = newState;
      }

      const updatedReference: NotificationPreferenceModel = {
        code: updatedNotification.key,
        smsEnabled: updatedNotification.isSmsEnabled,
        emailEnabled: updatedNotification.isEmailEnabled,
      };

      await accountNotificationsAPI.changeNotificationStatus(updatedReference);

      setNotificationPreferences((prev) => {
        const newPreferences = [...prev];
        newPreferences[rowIndex] = updatedNotification;
        return newPreferences;
      });

      message.success(t('success_edit', { ns: 'common' }));
    } finally {
      setNotificationPreferences((prev) => {
        const newPreferences = [...prev];
        newPreferences[rowIndex] = {
          ...newPreferences[rowIndex],
          loading: false,
        };
        return newPreferences;
      });
    }
  };

  const renderNotificationPreferences = (
    notifications: INotificationPreference[],
  ) => {
    if (!notifications.length) {
      return null;
    }

    return notifications.map((e, i) => (
      <>
        <Row key={i}>
          <Col flex="80%">
            <StyledParagraph>{e.title}</StyledParagraph>
            <StyledSubParagraph>{e.helperMessage}</StyledSubParagraph>
          </Col>

          <StyledToggleCol flex="10%">
            <Switch
              loading={e.loading}
              checked={e.isSmsEnabled}
              checkedChildren={
                <IconSVG component={CheckIcon} color={colorsTheme.colorWhite} />
              }
              onChange={(value: boolean) =>
                handleToggleChange(value, i, NotificationsType.SMS)
              }
            />
          </StyledToggleCol>
          <StyledToggleCol flex="10%">
            <Switch
              loading={e.loading}
              checked={e.isEmailEnabled}
              checkedChildren={
                <IconSVG component={CheckIcon} color={colorsTheme.colorWhite} />
              }
              onChange={(value: boolean) =>
                handleToggleChange(value, i, NotificationsType.Email)
              }
            />
          </StyledToggleCol>
        </Row>
        <StyledDivider />
      </>
    ));
  };

  return (
    <CardWrapper>
      <Row justify="end">
        <Col flex="80%">
          <Text variant="h4" weight="semi-bold" gutterBottom>
            {t('account_notifications.notification_preferences_section_title')}
          </Text>
        </Col>
        <Col flex="10%">
          <CustomTitle variant="h4" weight="semi-bold">
            {t('account_notifications.sms')}
          </CustomTitle>
        </Col>
        <Col flex="10%">
          <CustomTitle variant="h4" weight="semi-bold">
            {t('account_notifications.email')}
          </CustomTitle>
        </Col>
      </Row>
      <LoadingWrapper loading={loading}>
        {renderNotificationPreferences(notificationPreferences)}
      </LoadingWrapper>
    </CardWrapper>
  );
};

const StyledToggleCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledParagraph = styled(Row)`
  margin-bottom: 0px !important;
`;

const StyledSubParagraph = styled(Row)`
  margin-bottom: 0px !important;
  font-size: 13px;
  color: ${({ theme }) => theme.colorLightD1};
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0px;
`;

const CustomTitle = styled(Text)`
  display: flex;
  justify-content: flex-end;
`;

export default NotificationPreferences;
