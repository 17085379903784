import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import { securityAPI } from '../../../../../api/profile/securityAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import SessionHistoryTable from '../../../../../components/Tables/TableTemplates/User/SessionHistoryTable';

interface IProps {
  updateViewTrigger: any;
}

const SessionHistory = ({ updateViewTrigger }: IProps) => {
  const [currentPage, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      securityAPI.fetchUserSessions({
        limit: DEFAULT_TABLE_LIMIT,
        page: currentPage,
        inactive: true,
      }),
    [currentPage, updateViewTrigger],
  );

  return (
    <SessionHistoryTable
      loading={loading}
      current={currentPage}
      total={response?.total}
      data={response?.data || []}
      onPaginationChange={setPage}
    />
  );
};

export default SessionHistory;
