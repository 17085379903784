import { RequestModel } from 'modules/Connections/Requests';
import { FetchResponseModel } from 'typings/common';
import {
  RequestFromQuery,
  ShareContactRequestBody,
} from 'api/connections/connectionsAPI';
import { FormValuesModel as KycVerificationFormValuesModel } from 'components/ModalDialogs/TemplateModalDialogs/Connections/KYCVerificationModal';

const connectionsAdapter = {
  fetchRequests: (
    response: FetchResponseModel<RequestFromQuery>,
  ): FetchResponseModel<RequestModel> => {
    const data: RequestModel[] = response.data.map((request) => ({
      id: request._id,
      name: request.details.clientGroupName,
      status: request.status,
      type: request.type,
      requestedAt: request._audit.createdAt,
      details: {
        clientGroupId: request.details.clientGroupId,
        clientGroupName: request.details.clientGroupName,
      },
    }));

    return { total: response.total, data };
  },

  generateShareContactBody: (
    values: KycVerificationFormValuesModel,
  ): ShareContactRequestBody => {
    return {
      clientGroupJoinRequestData: {
        questionnaireTemplateAnswers: values.questions.map((question) => ({
          questionKey: question.questionKey,
          answer: !!question.answer,
        })),
      },
    };
  },
};

export { connectionsAdapter };
