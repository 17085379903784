const colorsTheme = {
  colorDarkD1: '#1c2025',
  colorDark: '#1d2228',
  colorDarkL1: '#242f39',
  colorDarkL2: '#202f3b',
  colorDarkL3: '#2a353d',
  colorDarkL4: '#37424a',

  // ** ---------------------- **

  colorPrimaryD2: '#04A260',
  colorPrimaryD1: '#28B864',
  colorPrimary: '#00C573',

  // ** ---------------------- **

  colorLightD1: '#848d93',
  colorLight: '#c6cccf',
  colorLightL1: '#dfe3e5',

  // ** ---------------------- **

  colorWhite: '#ffffff',

  // ** ---------------------- **

  colorBlack: '#000000',

  // ** ---------------------- **

  colorAdditional: '#5e6a70',
  colorAdditionalL1: '#798d99',

  // ** ---------------------- **

  colorSuccessD2: '#359D85',
  colorSuccessD1: '#3AAD92',
  colorSuccess: '#40bea1',
  colorSuccessL1: '#77D0C3',
  colorSuccessL2: '#83D4C8',

  // ** ---------------------- **

  colorErrorD2: '#B4475E',
  colorErrorD1: '#C64E67',
  colorError: '#da5671',
  colorErrorL1: '#CC9FAD',
  colorErrorL2: '#D1A8B4',

  // ** ---------------------- **

  colorWarningD2: '#A88040',
  colorWarningD1: '#B98D46',
  colorWarning: '#CC9B4D',
  colorWarningL1: '#D1A45D',
  colorWarningL2: '#D5AC6C',

  // ** ---------------------- **

  colorInfoD2: '#378BB9',
  colorInfoD1: '#3D99CC',
  colorInfo: '#43a8e0',
  colorInfoL1: '#54B0E3',
  colorInfoL2: '#64B7E6',

  // ** ---------------------- **
};

export { colorsTheme };
