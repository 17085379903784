import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { MessageRecipient } from '../../../../../../apiAdapters/messaging/clientMessagingAdapter';
import { useFormikContext } from 'formik';
import { ShortDocumentModel } from 'components/Forms/FormComponents/UploadDocumentField';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import MessageCategorySelect from '../../../../../Forms/FormComponents/SelectInputs/Messaging/MessageCategorySelect';
import RecipientAutocomplete, {
  RecipientAutocompleteProps,
} from '../../../../../Forms/FormComponents/Autocompletes/Messaging/RecipientAutocomplete';

export interface FormValuesModel {
  categoryId?: number;
  subject?: string;
  message?: string;
  recipients: MessageRecipient[];
  hideRecipientsField?: boolean;
  attachments: ShortDocumentModel[];
}

interface IProps {
  categoryId?: number;
  subject?: string;
}

const InnerForm = ({ categoryId, subject }: IProps) => {
  const { t } = useTranslation('profile');
  const { values } = useFormikContext<FormValuesModel>();
  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id as string,
  );
  const clientGroupId = useSelector<StateModel, string>(
    (state) => state.applications.activeApplication?.clientGroupId as string,
  );

  return (
    <>
      <FormField
        name="categoryId"
        label={t('messages.send_message.form_fields.category')}
        disabled={!!categoryId}
        component={MessageCategorySelect}
      />

      <FormField
        name="subject"
        label={t('messages.send_message.form_fields.subject')}
        disabled={!!subject}
        component={FormInput}
      />

      {!values.hideRecipientsField && (
        <FormField<RecipientAutocompleteProps>
          name="recipients"
          label={t('messages.send_message.form_fields.recipients')}
          component={RecipientAutocomplete}
          additionalProps={{
            clientGroupId,
            currentUserId,
          }}
        />
      )}

      <FormField
        name="message"
        label={t('messages.send_message.form_fields.message')}
        component={FormInputTextArea}
        additionalProps={{ autoSize: { minRows: 8, maxRows: 8 } }}
      />

      <FormField
        name="attachments"
        component={DocumentsField}
        additionalProps={{ isMultiple: true, isColumnReverse: true }}
      />
    </>
  );
};

export default InnerForm;
