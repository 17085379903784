import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const SendMessageValidationSchema = yup.object().shape({
  categoryId: yup.number().required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'profile:messages.send_message.form_fields.category' },
  }),
  subject: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'profile:messages.send_message.form_fields.subject' },
  }),
  message: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'profile:messages.send_message.form_fields.message' },
  }),
  attachments: yup.array().notRequired(),
});

export const ReplyFormValidationSchema = yup.object().shape({
  message: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'profile:messages.send_message.form_fields.message' },
  }),
  attachments: yup.array().notRequired(),
});
