import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { message } from 'antd';
import { profileAPI } from 'api/profile/profileAPI';
import { useDispatch } from 'react-redux';
import { FormValuesModel } from '..';
import { FormikHelpers, useField } from 'formik';
import { setPhoneNumberStartVerificationDate } from 'redux/actions/auth';

// components
import Button from '@core_components/Button';
import VerifyPhoneNumberModal, {
  VerifyPhoneNumberFormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Profile/VerifyPhoneNumberModal';

interface IProps {
  fieldName: string;
  onVerify: () => void;
}

const VerifyPhoneNumber = ({ fieldName, onVerify }: IProps) => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();
  const [field] = useField<FormValuesModel>(fieldName);
  const [isVisible, setIsVisible] = useState(false);

  const verifyEmailInitialFormValues =
    useMemo<VerifyPhoneNumberFormValuesModel>(
      () => ({
        code: '',
        phoneNumber: field.value.value,
      }),
      [field],
    );

  const handleVerify = async ({
    code,
    phoneNumber,
  }: VerifyPhoneNumberFormValuesModel) => {
    await profileAPI.verifyPhoneNumber(code, phoneNumber as string);
    setIsVisible(false);
    message.success(t('personal_details.verify_phone.success_submit_message'));
    onVerify();
  };

  const resendOTP = async () => {
    const response = await profileAPI.resendPhoneNumberOTP(
      field.value.value as string,
    );
    dispatch(
      setPhoneNumberStartVerificationDate(
        response.verification.verificationStartOn,
      ),
    );
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: VerifyPhoneNumberFormValuesModel,
    formikHelpers: FormikHelpers<VerifyPhoneNumberFormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1005006': {
        formikHelpers.setFieldError(
          'code',
          t('1005006', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        {t('personal_details.verify_phone.verify_button')}
      </Button>
      <VerifyPhoneNumberModal
        isVisible={isVisible}
        closeCallback={() => setIsVisible(false)}
        initialValues={verifyEmailInitialFormValues}
        onSubmit={handleVerify}
        onResendOTP={resendOTP}
        onSubmitError={handleOnSubmitError}
      />
    </>
  );
};

export default VerifyPhoneNumber;
