import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { NewBlockchainTransferModel } from '../../../../../typings/accounting/transfer';
import { blockchainTransferValidationSchema } from '../../../../../validations/accounting/transfers';

// components
import InnerForm from './InnerForm';
import SubmitButton from '../../../../Buttons/SubmitButton';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import { Col, Row } from 'antd';

type IProps = RequiredPropsForFormModel<NewBlockchainTransferModel>;

const SubmitButtonGridSizes = { xl: 4, lg: 6, md: 8, sm: 24, xs: 24 };

const BlockchainTransferForm = ({
  initialValues,
  onSubmit,
  disabled,
}: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Form<NewBlockchainTransferModel>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={blockchainTransferValidationSchema}
      confirmExitWithoutSaving
      disabled={disabled}
      renderForm={
        <>
          <InnerForm />

          <Row justify="end">
            <Col {...SubmitButtonGridSizes}>
              <StyledSubmitButton type="primary">
                {t('submit')}
              </StyledSubmitButton>
            </Col>
          </Row>
        </>
      }
    />
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
  padding: 0px;
  min-width: 0px;
`;

export default BlockchainTransferForm;
