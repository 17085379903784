import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';

// helpers
import { accountManagementRulesAdapter } from '../../apiAdapters/accountManagement/accountManagementRulesAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  IApprovalGroupShort,
  IGroupParticipantShort,
  INewTransactionApprovalGroup,
  ApprovalRulesTemplateModel,
  ApprovalRulesTemplateBaseModel,
  ApprovalRuleTriggerTypeModel,
  ApprovalEntryModel,
  ApprovalEntryNewModel,
} from '../../typings/approvalManagement/accountManagement';

interface FetchApprovalsParams extends FetchParamsModel {
  status: string | null;
  searchByUserNameQuery: string;
}

interface FetchApprovalGroupsParams extends FetchParamsModel {
  isActiveOnly?: boolean;
}

interface FetchApprovalStatusesResponse {
  items: { name: string; value: number }[];
}

// TODO:
// 4. Integrate GET approval entry by Id (getApprovalEntryById)
// 5. Integrate PATCH approval entry by Id (approveApprovalById)
// 6. Integrate PATCH approval entry by Id (rejectApprovalById)

const accountManagementRulesAPI = {
  // ------ Approval groups --------
  fetchApprovalGroups: (params: FetchApprovalGroupsParams) => {
    return APIService.get<FetchResponseModel<IApprovalGroupShort>>(
      `${APIConfig.approvalApi}/account-management/groups`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchApprovalGroupParticipants: () => {
    return APIService.get<FetchResponseModel<IGroupParticipantShort>>(
      `${APIConfig.approvalApi}/account-management/groups/participants`,
    ).then(({ data }) => data);
  },

  fetchApprovalGroupById: (groupId: string) => {
    return APIService.get<IApprovalGroupShort>(
      `${APIConfig.approvalApi}/account-management/groups/${groupId}`,
    ).then(({ data }) => data);
  },

  createNewApprovalGroup: (newGroup: INewTransactionApprovalGroup) => {
    return APIService.post(
      `${APIConfig.approvalApi}/account-management/groups`,
      newGroup,
    ).then(({ data }) => data);
  },

  editApprovalGroup: (updatedGroup: IApprovalGroupShort) => {
    return APIService.patch(
      `${APIConfig.approvalApi}/account-management/groups/${updatedGroup.id}`,
      updatedGroup,
    ).then(({ data }) => data);
  },

  fetchApprovalWorkflowStatuses: () => {
    return APIService.get<FetchApprovalStatusesResponse>(
      `${APIConfig.approvalApi}/account-management/workflows/status`,
    ).then(({ data }) => data);
  },

  fetchApprovalEntries: (params: FetchApprovalsParams) => {
    return APIService.get<FetchResponseModel<ApprovalEntryModel>>(
      `${APIConfig.approvalApi}/account-management/workflows`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  approveApprovalById: (entryId: string, reason: string) => {
    return APIService.post<FetchResponseModel<ApprovalEntryModel>>(
      `${APIConfig.approvalApi}/account-management/workflows/${entryId}/approve`,
      { reason },
    ).then(({ data }) => data);
  },

  rejectApprovalById: (entryId: string, reason: string) => {
    return APIService.post<FetchResponseModel<ApprovalEntryModel>>(
      `${APIConfig.approvalApi}/account-management/workflows/${entryId}/reject`,
      { reason },
    ).then(({ data }) => data);
  },

  // ---- Template Rules ----
  fetchTemplateApprovalRules: () => {
    return APIService.get<ApprovalRulesTemplateModel>(
      `${APIConfig.approvalApi}/account-management/template`,
    ).then(({ data }) =>
      accountManagementRulesAdapter.getTemplateApprovalRules(data),
    );
  },

  createInitialApprovalRulesTemplate: (
    newTemplate: ApprovalRulesTemplateBaseModel,
  ) => {
    return APIService.post<boolean>(
      `${APIConfig.approvalApi}/account-management/template`,
      newTemplate,
    ).then(({ data }) => data);
  },

  updateApprovalRulesTemplate: (
    updatedTemplate: ApprovalRulesTemplateModel,
  ) => {
    return APIService.patch<boolean>(
      `${APIConfig.approvalApi}/account-management/template/${updatedTemplate.id}`,
      updatedTemplate,
    ).then(({ data }) => data);
  },

  fetchApprovalRuleTriggerTypes: () => {
    return APIService.get<ApprovalRuleTriggerTypeModel[]>(
      `${APIConfig.approvalApi}/account-management/template/types`,
    ).then(({ data }) => data);
  },

  getApprovalWorkflowById: (approvalWorkflowId: string) => {
    return APIService.get(
      `${APIConfig.approvalApi}/account-management/workflows/${approvalWorkflowId}`,
    ).then<ApprovalEntryNewModel>(({ data }) =>
      accountManagementRulesAdapter.getApprovalWorkflow(data),
    );
  },
};

export { accountManagementRulesAPI };
