import React, { useMemo } from 'react';

// helpers
import { ContactHelpers } from '../../../../../../helpers/crm/contact';
import { OnboardingEntryTypes } from '../../../../../../enums/onboarding/crm';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import {
  AutocompleteEventOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';
import {
  ClientGroupMemberModel,
  userManagementAPINew,
} from '../../../../../../api/accountManagement/userManagementAPINew';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface RecipientAutocompleteProps
  extends AutocompleteProps<ClientGroupMemberModel> {
  currentUserId: string;
  clientGroupId: string;
  value?: any[];
}

// TODO: rework this field, need to remove the logic with custom on change handling
const RecipientAutocomplete = ({
  currentUserId,
  clientGroupId,
  value,
  onChange,
  ...rest
}: RecipientAutocompleteProps) => {
  const formattedFieldValue = useMemo(() => {
    let result: string[] = [];

    if (value && value.length) {
      result = value.map((e) => e.id);
    }

    return result;
  }, [value]);

  const fetchOptions = async () => {
    const { data } = await userManagementAPINew.fetchClientGroupMembers(
      clientGroupId,
      {
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        page: 1,
        type: OnboardingEntryTypes.Contact,
        has_online_access: true,
      },
    );

    const mappedData = data.filter(
      (user) => user.contact.associations?.auth !== currentUserId,
    );

    return mappedData.map((member) => ({
      id: member.contact.associations?.auth,
      label: ContactHelpers.getFormattedContactName(
        member.contact.firstName,
        member.contact.lastName,
        member.contact.middleName,
      ),
      model: member,
    }));
  };

  const handleChangeRecipients = (
    newOptions: AutocompleteEventOption<ClientGroupMemberModel>[],
  ) => {
    const newValueFormatted = newOptions.reduce<{ id: string; name: string }[]>(
      (acc, next) => {
        if (next.model) {
          acc.push({
            id: next.model.contact.associations?.auth as string,
            name: ContactHelpers.getFormattedContactName(
              next.model.contact.firstName as string,
              next.model.contact.lastName as string,
              next.model.contact.middleName as string,
            ),
          });
        }

        return acc;
      },
      [],
    );

    onChange && onChange(newValueFormatted as any);
  };

  return (
    <FormAutocomplete
      {...rest}
      mode="multiple"
      value={formattedFieldValue}
      onChange={(_, option) =>
        option &&
        handleChangeRecipients(
          option as AutocompleteEventOption<ClientGroupMemberModel>[],
        )
      }
      fetchData={fetchOptions}
    />
  );
};

export default RecipientAutocomplete;
