import React, { useState } from 'react';

// helpers & constants
import useFetch from '../../../../../../hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';
import { blockchainAddressesAPI } from '../../../../../../api/accounting/blockchainAddressesAPI';

// components
import CircleAddressesTable from '../../../../../Tables/TableTemplates/Accounts/CircleAddressesTable';

interface IProps {
  accountNumber: string;
  updateTableTrigger: any;
}

const AccountAddressesTable = ({
  accountNumber,
  updateTableTrigger,
}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      blockchainAddressesAPI.fetchAddressesByAccountNumber(accountNumber, {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [accountNumber, currentPage, updateTableTrigger],
  );

  return (
    <CircleAddressesTable
      loading={loading}
      current={currentPage}
      total={response?.total || 0}
      data={response?.data || []}
      onPaginationChange={setCurrentPage}
    />
  );
};

export default AccountAddressesTable;
