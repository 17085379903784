import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { securityAPI } from '../../../../../api/profile/securityAPI';

// components
import { message } from 'antd';
import ChangePasswordModal, {
  FormValuesModel,
} from '../../../../../components/ModalDialogs/TemplateModalDialogs/UserProfile/ChangePasswordModal';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasModified?: boolean) => void;
}

const ChangePasswordRuleModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('profile');

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };
  }, [isVisible]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const data = {
      oldPassword: values.oldPassword,
      password: values.newPassword,
    };
    await securityAPI.changePassword(data);
    message.success(t('security.change_password.success_submit_message'));
  };

  return (
    <ChangePasswordModal
      title={t('security.change_password.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default ChangePasswordRuleModal;
