import React, { useMemo } from 'react';

// helpers
import { useFormikContext } from 'formik';
import { OwnershipFormItem } from '../../..';
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from '../../../../../../../../enums/onboarding/crm';
import { FormValuesModel as OwnershipStructureFormValuesModel } from '../../..';

// components
import OwnershipRelationshipForm, {
  FormValuesModel,
} from '../../../../../../../Forms/TemplateForms/ClientProfile/OwnershipRelationshipForm';

interface IProps {
  onAdd: (newOwnership: OwnershipFormItem) => void;
  onCancel: () => void;
}

const AddOwnershipRelationshipForm = ({ onAdd, onCancel }: IProps) => {
  const { values: OwnershipStructureFormValues } =
    useFormikContext<OwnershipStructureFormValuesModel>();

  const initialFormValues = useMemo<FormValuesModel>(() => {
    const filteredExistingEntries =
      OwnershipStructureFormValues.allowedEntriesForOwnerships.filter((e) => {
        return !OwnershipStructureFormValues.ownerships.some(
          (o) => e.id === o.contact?.id || e.id === o.organization?.id,
        );
      });

    return {
      isFromExistingEntry: null,
      template: {
        id: null,
        visibleFor: null,
        template: null,
      },
      existingEntries: filteredExistingEntries,
      existingEntry: {
        id: null,
        type: null,
        keyRelationshipTemplate: null,
        contact: null,
        organization: null,
      },
      contact: null,
      organization: null,
      clientGroupEntryType: OwnershipStructureFormValues.clientGroupEntryType,
    };
  }, [OwnershipStructureFormValues]);

  const handleSubmit = async (values: FormValuesModel) => {
    const {
      isFromExistingEntry,
      contact,
      organization,
      existingEntry,
      template,
    } = values;

    const additionalFields: Record<
      string,
      { value: string; isOwnershipField: boolean; type: AdditionalFieldTypes }
    > = OwnershipStructureFormValues.ownershipTemplate.additionalFields.reduce<
      Record<
        string,
        { value: string; isOwnershipField: boolean; type: AdditionalFieldTypes }
      >
    >((acc, e) => {
      acc[e.name] = {
        value: '',
        type: e.type,
        isOwnershipField:
          OwnershipStructureFormValues.ownershipFieldKey === e.name,
      };
      return acc;
    }, {});

    const contactEntry =
      isFromExistingEntry && existingEntry.type === OnboardingEntryTypes.Contact
        ? {
            id: existingEntry.id as string,
            firstName: existingEntry.contact?.firstName as string,
            lastName: existingEntry.contact?.lastName as string,
            middleName: existingEntry.contact?.middleName as string,
            isPEP: null,
            pepInformation: '',
            email: '',
            phone: '',
            isPermissionsSelectAvailable: true,
            adminPermissionType: null,
            isOnlineUser: null,
            accountPermissions: null,
          }
        : template.visibleFor === OnboardingEntryTypes.Contact
          ? contact
          : null;

    const organizationEntry =
      isFromExistingEntry &&
      existingEntry.type === OnboardingEntryTypes.Organization
        ? {
            id: existingEntry.id as string,
            name: existingEntry.organization?.name as string,
            isRegulated: null,
            regulatedCountries: [],
          }
        : template.visibleFor === OnboardingEntryTypes.Organization
          ? organization
          : null;

    const type = isFromExistingEntry
      ? (existingEntry.type as OnboardingEntryTypes)
      : (template.visibleFor as OnboardingEntryTypes);

    const keyRelationshipTemplate = isFromExistingEntry
      ? {
          id: existingEntry.keyRelationshipTemplate?.id as string,
          name: existingEntry.keyRelationshipTemplate?.name as string,
        }
      : {
          id: template.template?._id as string,
          name: template.template?.name as string,
        };

    const ownershipItem: OwnershipFormItem = {
      type,
      contact: contactEntry,
      organization: organizationEntry,
      additionalFields,
      keyRelationshipTemplate,
      isFromExistingEntry: isFromExistingEntry || undefined,
    };

    onAdd(ownershipItem);
  };

  return (
    <OwnershipRelationshipForm
      initialFormValues={initialFormValues}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
};

export default AddOwnershipRelationshipForm;
