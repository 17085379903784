import React, { useMemo } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from '../../../../../../enums/onboarding/crm';
import { keyRelationshipTemplatesAPI } from '../../../../../../api/crm/keyRelationshipTemplatesAPI';

// components
import UserInfoStep from './UserInfoStep';
import ChooseTypeStep from './ChooseTypeStep';
import ChooseOrganizationTypeStep from './ChooseOrganizationTypeStep';
import { Carousel } from 'antd';

const InnerForm = () => {
  const { setFieldValue, values } = useFormikContext<FormValuesModel>();

  const { response: organizationTypes } = useFetch(
    () =>
      keyRelationshipTemplatesAPI.fetchOnboardingKeyRelationshipTemplates({
        page: 1,
        limit: 100,
      }),
    [],
  );

  const handleUserTypeSelect = (type: OnboardingEntryTypes) => {
    setFieldValue('accountType', type);
    setFieldValue('step', values.step + 1);
    setFieldValue('entityDescription', '');
  };

  const handleOrganizationTypeSelect = (
    organizationTypeId: string,
    description: string,
  ) => {
    setFieldValue('keyRelationshipTemplateId', organizationTypeId);
    setFieldValue('step', values.step + 1);
    setFieldValue('entityDescription', description);
  };

  const renderContent = useMemo(() => {
    switch (values.step) {
      case 0: {
        return <ChooseTypeStep onSelect={handleUserTypeSelect} />;
      }
      case 1: {
        if (values.accountType === OnboardingEntryTypes.Organization) {
          return (
            <ChooseOrganizationTypeStep
              organizationTypes={organizationTypes?.data || []}
              onSelect={handleOrganizationTypeSelect}
            />
          );
        }
        return <UserInfoStep />;
      }
      case 2: {
        return <UserInfoStep />;
      }
      default:
        return null;
    }
  }, [values.step, values.accountType, organizationTypes]);

  return (
    <Carousel dots={false} effect="fade">
      <div>{renderContent}</div>
    </Carousel>
  );
};

export default InnerForm;
