import React, { useContext } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { VerificationContext } from '..';

// components
import Button from '@core_components/Button';
import { Col, Row } from 'antd';

const ButtonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

const SubmitButtons = () => {
  const { t } = useTranslation('common');

  const context = useContext(VerificationContext);

  const onSaveAndExitClick = async () => {
    if (context?.refState.current) {
      await context.refState.current.setValues(
        {
          ...context.refState.current.values,
          isSave: true,
          submitActionType: 'save-exit',
        },
        false,
      );
      context.refState.current.submitForm();
    }
  };

  const onBackClick = async () => {
    if (context?.refState.current) {
      await context.refState.current.setValues(
        {
          ...context.refState.current.values,
          isSave: true,
          submitActionType: 'save-back',
        },
        false,
      );
      context.refState.current.submitForm();
    }
  };

  return (
    <StyledRow justify="end" gutter={[16, 16]}>
      <Col {...ButtonGridSizes}>
        <StyledButton type="bordered" size="large" onClick={onBackClick}>
          {t('back')}
        </StyledButton>
      </Col>
      <Col {...ButtonGridSizes}>
        <StyledButton type="primary" size="large" onClick={onSaveAndExitClick}>
          {t('save_and_exit')}
        </StyledButton>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default SubmitButtons;
