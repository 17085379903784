import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInputPassword from '@common_components/Form/FormInputPassword';
import SetupPasswordField from '../../../../../Forms/FormComponents/SetupPasswordField';

const InnerForm = () => {
  const { t } = useTranslation('profile');

  return (
    <>
      <FormField
        name="oldPassword"
        component={SetupPasswordField}
        label={t('security.change_password.form_fields.old_password')}
      />

      <FormField
        name="newPassword"
        component={SetupPasswordField}
        label={t('security.change_password.form_fields.password')}
      />

      <FormField
        name="confirmNewPassword"
        component={FormInputPassword}
        label={t('security.change_password.form_fields.confirm_password')}
      />
    </>
  );
};

export default InnerForm;
