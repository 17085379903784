import React from 'react';

// helpers
import { TransfersHelpers } from 'helpers/transfers/transfers';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';
import { TransferTemplateStatuses } from 'enums/accounting/templates';
import { ITransferTemplateShortModel } from '../../../../../../typings/accounting/transfer';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export type WireTransferTemplateAutocompleteProps =
  AutocompleteProps<ITransferTemplateShortModel>;

const WireTransferTemplateAutocomplete = (
  props: WireTransferTemplateAutocompleteProps,
) => {
  // TODO: update limit to default one when infinite scroll will be implemented
  const fetchOptions = async (searchQuery?: string) => {
    const { data } =
      await transferTemplatesAPI.getInternationalTransferTemplates({
        limit: 10000,
        page: 1,
        status: TransferTemplateStatuses.Active,
        name: searchQuery,
      });

    return data.map((e) => ({
      id: e.id,
      label: TransfersHelpers.getTransferTemplateName(String(e.id), e.name),
      model: e,
    }));
  };

  return <FormAutocomplete {...props} fetchData={fetchOptions} />;
};

export default WireTransferTemplateAutocomplete;
