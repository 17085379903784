import { Route } from 'routes/routes';
import { RoutePaths } from 'routes/routes';
import { LayoutVariants } from 'redux/reducers/settings';
import { PERMISSION_KEYS } from 'config/permissions';
import { UserPermissionModel } from 'redux/reducers/userAccess';

const RoutesHelpers = {
  generateFullRoutePathArray: (pathName: string) => {
    const pathArray = pathName.split('/').filter((el) => el);

    let path = '';
    const result: string[] = [];

    pathArray.forEach((e, i) => {
      if (i === 0) {
        path = `/${e}`;
      } else {
        path = `${path}/${e}`;
      }

      result.push(path);
    });

    return result;
  },

  getMainRoutes: (routes: Route[]) => {
    return routes.find((e) => e.path === RoutePaths.Root)?.children || [];
  },

  filterRoutes: (routes: Route[], isAllowed: (route: Route) => boolean) => {
    function filterRoutes(routeList: Route[]): Route[] {
      return routeList.reduce<Route[]>((acc, next) => {
        if (isAllowed(next)) {
          const route: Route = { ...next, children: [] };
          if (next.children) {
            const filteredChildren = filterRoutes(next.children);
            if (filteredChildren.length) {
              route.children = filteredChildren.slice();
            }
          }
          acc.push(route);
        } else if (next.children) {
          const filteredChildren = filterRoutes(next.children);
          if (filteredChildren.length) {
            acc.push(...filteredChildren);
          }
        }
        return acc;
      }, []);
    }

    return filterRoutes(routes);
  },

  findRouteByPath: (routes: Route[], path: string): Route | undefined => {
    function findRouteByPathRecursion(
      pathArray: string[],
      routes: Route[],
    ): Route | undefined {
      const route = routes.find(({ path }) => path === pathArray[0]);

      if (pathArray.length == 1) {
        return route;
      }

      return route?.children
        ? findRouteByPathRecursion(pathArray.slice(1), route.children)
        : undefined;
    }

    // Handle dashboard route
    if (path === RoutePaths.Root) {
      const route = routes.find(({ path }) => path === RoutePaths.Root);

      if (route?.children?.length) {
        const indexRoute = route.children.find(
          ({ path, index }) => path === RoutePaths.Root && index,
        );

        if (indexRoute) {
          return indexRoute;
        }
      }

      return route;
    }

    const pathArray = RoutesHelpers.generateFullRoutePathArray(path);

    const parentRoute = routes.find(({ path }) => path === pathArray[0]);

    if (!parentRoute) {
      return findRouteByPathRecursion(
        pathArray,
        RoutesHelpers.getMainRoutes(routes),
      );
    } else {
      return findRouteByPathRecursion(pathArray, routes);
    }
  },

  isAuthorized: (
    path: string,
    layout: LayoutVariants | null,
    permissions: Record<PERMISSION_KEYS, UserPermissionModel>,
    routes: Route[],
  ): {
    status: boolean;
    redirectPath: string;
  } => {
    const route = RoutesHelpers.findRouteByPath(routes, path);

    if (!route) {
      return {
        status: false,
        redirectPath: RoutePaths.Root,
      };
    }

    let redirectPath = '';

    switch (layout) {
      case 'ApprovedClientGroup':
        redirectPath = RoutePaths.Root;
        break;

      case 'OnboardingClientGroup':
        redirectPath = RoutePaths.OnboardingPage;
        break;

      case 'NoClientGroup':
        redirectPath = RoutePaths.UserProfile_Root;
        break;
    }

    // Check if user has access to the route based on layout
    if (route.access?.layout && layout) {
      const hasLayoutAccess = route.access.layout === layout;
      if (!hasLayoutAccess) {
        return {
          status: false,
          redirectPath,
        };
      }
    }

    if (route.access?.permission) {
      const hasPermissionAccess =
        permissions[route.access.permission].isAllowed;

      if (!hasPermissionAccess) {
        return {
          status: false,
          redirectPath,
        };
      }
    }

    return {
      status: true,
      redirectPath: '',
    };
  },
};

export { RoutesHelpers };
