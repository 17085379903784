import serviceAPI from 'api/service/serviceAPI';
import { Dispatch } from 'redux';
import { StateModel } from '../reducers';
import { getMe, signOut } from './auth';
import { SessionHelpers } from 'helpers/session';
import { isAccessTokenAlive } from 'helpers/utils';
import { ClientGroupStatuses } from '../../enums/onboarding/crm';
import { LocalStorageHelpers } from '../../helpers/storages/localStorage';
import { SessionStorageHelper } from 'helpers/storages/sessionStorage';
import { USER_TAG_IS_LOGGED_IN_FIRST_TIME } from '../../constants/userTags';

// Actions
export enum ActionType {
  SET_BADGES = 'app/SET_BADGES',
  SET_INITIALIZED_STATUS = 'app/SET_INITIALIZED_STATUS',
  SET_ACTIVITY_TIMESTAMP = 'app/SET_ACTIVITY_TIMESTAMP',
  SET_INSTRUMENTATION_KEY = 'app/SET_INSTRUMENTATION_KEY',
  SET_WELCOME_MODAL_VISIBILITY_STATUS = 'app/SET_WELCOME_MODAL_VISIBILITY_STATUS',
  CLEAN_UP = 'app/CLEAN_UP',
}

// Action creators
export const setInitializedStatus = (newStatus: boolean) => ({
  type: ActionType.SET_INITIALIZED_STATUS,
  payload: { status: newStatus },
});

export const setLastActivityTimestamp = (lastActivityTimestamp: number) => ({
  type: ActionType.SET_ACTIVITY_TIMESTAMP,
  payload: { lastActivityTimestamp },
});

export const cleanUpAppReducer = () => ({
  type: ActionType.CLEAN_UP,
  payload: null,
});

export const setWelcomeModalVisibilityStatus = (status: boolean) => ({
  type: ActionType.SET_WELCOME_MODAL_VISIBILITY_STATUS,
  payload: { status },
});

export const initializeApp = () => async (dispatch: any) => {
  dispatch(setInitializedStatus(false));

  const accessToken = LocalStorageHelpers.getAccessToken();
  const geolocationStatus = await SessionHelpers.getGeolocationStatus();

  if (geolocationStatus === 'denied') {
    SessionStorageHelper.clearUserSession();
  }

  // This is edge case that shouldn't happen
  // 'other-no-access' status can be returned when the user hasn't made a decision with a geolocation yet
  if (geolocationStatus === 'other-no-access') {
    const currentSession = SessionStorageHelper.getUserSession();
    if (currentSession && currentSession.geolocation) {
      SessionStorageHelper.clearUserSession();
    }
  }

  if (!accessToken) {
    dispatch(setInitializedStatus(true));
  } else {
    if (isAccessTokenAlive(accessToken)) {
      dispatch(initializeAppForAuthorizedUser());
    } else {
      LocalStorageHelpers.clear();
      dispatch(setInitializedStatus(true));
    }
  }
};

// TODO:
// 1) Update the logic for checking if the client group is approved (need to check from the user permissions)
export const initializeWelcomeModalStatus =
  () => (dispatch: Dispatch, getState: () => StateModel) => {
    const { auth, applications } = getState();

    const isUserLoggedInFirstTime = auth.profileData?.tags.includes(
      USER_TAG_IS_LOGGED_IN_FIRST_TIME,
    );
    const isActiveApplicationApproved =
      applications.activeApplication?.clientGroup?.status ===
      ClientGroupStatuses.Approved;

    if (isUserLoggedInFirstTime && isActiveApplicationApproved) {
      dispatch(setWelcomeModalVisibilityStatus(true));
    }
  };

export const initializeAppForAuthorizedUser = () => async (dispatch: any) => {
  try {
    await dispatch(initializeInstrumentationKey());
    await dispatch(getMe());
    dispatch(setInitializedStatus(true));
    dispatch(initializeWelcomeModalStatus());
  } catch {
    dispatch(signOut());
  }
};

const initializeInstrumentationKey = () => async (dispatch: Dispatch) => {
  const response = await serviceAPI.fetchFrontendConfig();

  dispatch({
    type: ActionType.SET_INSTRUMENTATION_KEY,
    payload: { instrumentationKey: response.insights.key },
    key: 'instrumentationKey',
  });
};

export const getBadges = () => async (dispatch: Dispatch) => {
  const response = await serviceAPI.fetchBadges();

  dispatch({
    type: ActionType.SET_BADGES,
    payload: {
      badges: {
        pendingTransactionsCount: response.pendingApprovalTransactions,
        pendingClientRequestsCount: response.clientRequests,
        pendingAccountManagementWorkflowsCount:
          response.pendingAccountManagementWorkflows,
      },
    },
    key: 'badges',
  });
};
