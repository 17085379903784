import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { LimitModel } from 'components/Forms/TemplateForms/Approval/TransactionRulesMatrixForm';
import { colorsTheme } from 'resources/theme/styled/colors';
import { AccountsHelpers } from 'helpers/accounts';

// components
import Text from '@core_components/Text';

interface IProps {
  dataIndex: string;
  record: LimitModel;
}

const HeaderCell = ({ dataIndex, record }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderContent = () => {
    switch (dataIndex) {
      case 'limit':
        return (
          <>
            <Text variant="body1">
              {`${t('approval_rules.bank_accounts.table.from_range_value')}: `}
              <Text variant="body1" color={colorsTheme.colorWarning}>
                {AccountsHelpers.formatAmountToLocaleString(record.from)}
              </Text>
            </Text>

            <Text variant="body1">
              {`${t('approval_rules.bank_accounts.table.to_range_value')}: `}
              <Text variant="body1" color={colorsTheme.colorWarning}>
                {record.to === -1
                  ? t('approval_rules.bank_accounts.table.and_above_option')
                  : AccountsHelpers.formatAmountToLocaleString(record.to || 0)}
              </Text>
            </Text>
          </>
        );

      default:
        return null;
    }
  };
  return <th>{renderContent()}</th>;
};

export default HeaderCell;
