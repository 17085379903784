import * as yup from 'yup';

// constants
import { CHECK_COUNTRY } from '../../constants/regularExpressions';
import { TransferTemplateTypes } from '../../enums/accounting/transfers';
import { OTHER_BANK_ACCOUNT_OPTION } from '../../components/Forms/FormComponents/Autocompletes/Accounting/RecipientBankAccountAutocomplete';
import {
  CONFIRMATION_REASON_MAX_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  TRANSFER_REFERENCE_MAX_LENGTH,
} from '../../constants/global';
import {
  NULLABLE_NUMBER_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  SWIFT_RELATED_TEXT_VALIDATION_RULE,
} from '../common';

const bankDetailsSchema = yup.object().shape({
  bankCodeType: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  bankCode: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.bank_code',
      number: 256,
    },
  }).required('form:validation_messages.required'),
  bankName: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.bank_name',
      number: 256,
    },
  }).required('form:validation_messages.required'),
  country: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  address: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.address',
      number: 256,
    },
  }),
  city: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(256, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_city',
      number: 256,
    },
  }),
  postCode: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(32, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_postcode',
      number: 32,
    },
  }),
  district: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_district',
      number: 50,
    },
  }),
});

export const newTransferTemplateSchema = yup.object().shape({
  eventDate: NULLABLE_STRING_VALIDATION_RULE,
  templateName: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  templateType: yup.mixed().required('form:validation_messages.required'),
  transferFields: yup
    .object()
    .when('templateType', {
      is: TransferTemplateTypes.Internal,
      then: (fieldSchema: any) =>
        fieldSchema.shape({
          accountName: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          accountNumber: STRING_VALIDATION_RULE.max(23, {
            key: 'form:validation_messages.max_characters',
            values: { number: 23 },
          }).required('form:validation_messages.required'),
          recipientReference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
            TRANSFER_REFERENCE_MAX_LENGTH,
            {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'transfers:transfer_templates.transfer_template_modal.form_fields.internal_transfer_template.recipient_reference',
                number: TRANSFER_REFERENCE_MAX_LENGTH,
              },
            },
          ),
        }),
    })
    .when('templateType', {
      is: TransferTemplateTypes.Wire,
      then: (fieldSchema: any) =>
        fieldSchema.shape({
          accountName: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(35, {
            key: 'form:validation_messages.max_characters_with_label',
            values: { label: 'common:account_name', number: 35 },
          }).required('form:validation_messages.required'),
          accountNumber: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(34, {
            key: 'form:validation_messages.max_characters_with_label',
            values: { label: 'common:account_name', number: 34 },
          }).required('form:validation_messages.required'),
          country: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          beneficiaryCity: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(20, {
            key: 'form:validation_messages.max_characters_with_label',
            values: {
              label:
                'transfers:transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_city',
              number: 20,
            },
          }),
          beneficiaryDistrict: STRING_VALIDATION_RULE.when('country', {
            is: (country: string) => CHECK_COUNTRY.test(country),
            then: (schema) =>
              schema.required('form:validation_messages.required'),
          }).max(20, {
            key: 'form:validation_messages.max_characters_with_label',
            values: {
              label:
                'transfers:transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_district',
              number: 20,
            },
          }),
          beneficiaryPostCode: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(10, {
            key: 'form:validation_messages.max_characters_with_label',
            values: {
              label:
                'transfers:transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_postcode',
              number: 10,
            },
          }),
          address: SWIFT_RELATED_TEXT_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ).max(35, {
            key: 'form:validation_messages.max_characters_with_label',
            values: {
              label:
                'transfers:transfer_templates.transfer_template_modal.form_fields.international_transfer_template.address',
              number: 35,
            },
          }),
          beneficiaryBank: bankDetailsSchema,
          intermediaryBank: yup.object().when('useIntermediary', {
            is: true,
            then: () => bankDetailsSchema,
          }),
          reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
            TRANSFER_REFERENCE_MAX_LENGTH,
            {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'transfers:transfer_templates.transfer_template_modal.form_fields.international_transfer_template.reference',
                number: TRANSFER_REFERENCE_MAX_LENGTH,
              },
            },
          ),
        }),
    })
    .when('templateType', {
      is: TransferTemplateTypes.Blockchain,
      then: (fieldSchema: any) =>
        fieldSchema.shape({
          networkChain: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          beneficiaryAddress: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          beneficiaryTag: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
          processingType: STRING_VALIDATION_RULE.required(
            'form:validation_messages.required',
          ),
        }),
    }),
});

export const internalTransferValidationSchema = yup.object().shape({
  amount: NULLABLE_NUMBER_VALIDATION_RULE.min(0).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.amount' },
  }),

  transactionCurrencyId: NULLABLE_NUMBER_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.currency' },
  }),

  valueDate: yup.date().required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.value_date' },
  }),

  transactionPurpose: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
    DESCRIPTION_MAX_LENGTH,
    {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'transfers:internal_transfer.form_fields.purpose',
        number: DESCRIPTION_MAX_LENGTH,
      },
    },
  ).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.purpose' },
  }),

  sender: yup
    .object()
    .shape({
      accountNumber: NULLABLE_STRING_VALIDATION_RULE.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'transfers:internal_transfer.form_fields.from' },
      }),
      reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
        TRANSFER_REFERENCE_MAX_LENGTH,
        {
          key: 'form:validation_messages.max_characters_with_label',
          values: {
            label:
              'transfers:internal_transfer.form_fields.from_account_reference',
            number: TRANSFER_REFERENCE_MAX_LENGTH,
          },
        },
      ),
    })
    .test(
      'same_accounts',
      'The from account must be different than the recipient account',
      function (value, context) {
        return context.parent.recipient.accountNumber && value.accountNumber
          ? context.parent.recipient.accountNumber !== value.accountNumber
          : true;
      },
    ),

  recipient: yup
    .object()
    .shape({
      accountNumber: NULLABLE_STRING_VALIDATION_RULE.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'transfers:internal_transfer.form_fields.to' },
      }),

      reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
        TRANSFER_REFERENCE_MAX_LENGTH,
        {
          key: 'form:validation_messages.max_characters_with_label',
          values: {
            label:
              'transfers:internal_transfer.form_fields.to_account_reference',
            number: TRANSFER_REFERENCE_MAX_LENGTH,
          },
        },
      ),

      templateId: NULLABLE_NUMBER_VALIDATION_RULE.when('accountNumber', {
        is: (accountNumber: any) => accountNumber === OTHER_BANK_ACCOUNT_OPTION,
        then: (schema) =>
          schema.required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'transfers:internal_transfer.form_fields.transfer_template',
            },
          }),
      }),
    })
    .test(
      'same_accounts',
      'The to account must be different than the sender account',
      function (value, context) {
        return context.parent.sender.accountNumber && value.accountNumber
          ? context.parent.sender.accountNumber !== value.accountNumber
          : true;
      },
    ),
});

export const wireTransferValidationSchema = yup.object().shape({
  amount: NULLABLE_NUMBER_VALIDATION_RULE.min(0).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.amount' },
  }),

  transactionCurrencyId: NULLABLE_NUMBER_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.currency' },
  }),

  valueDate: yup.date().required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.value_date' },
  }),

  transactionPurpose: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
    DESCRIPTION_MAX_LENGTH,
    {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'transfers:international_transfer.form_fields.purpose',
        number: DESCRIPTION_MAX_LENGTH,
      },
    },
  ).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:international_transfer.form_fields.purpose' },
  }),

  sender: yup.object().shape({
    accountNumber: NULLABLE_STRING_VALIDATION_RULE.required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'transfers:internal_transfer.form_fields.from' },
    }),
    reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
      TRANSFER_REFERENCE_MAX_LENGTH,
      {
        key: 'form:validation_messages.max_characters_with_label',
        values: {
          label:
            'transfers:internal_transfer.form_fields.from_account_reference',
          number: TRANSFER_REFERENCE_MAX_LENGTH,
        },
      },
    ),
  }),

  recipient: yup.object().shape({
    reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
      TRANSFER_REFERENCE_MAX_LENGTH,
      {
        key: 'form:validation_messages.max_characters_with_label',
        values: {
          label:
            'transfers:international_transfer.form_fields.transfer_template',
          number: TRANSFER_REFERENCE_MAX_LENGTH,
        },
      },
    ),

    templateId: NULLABLE_NUMBER_VALIDATION_RULE.required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'transfers:international_transfer.form_fields.transfer_template',
      },
    }),
  }),
});

export const blockchainTransferValidationSchema = yup.object().shape({
  amount: NULLABLE_NUMBER_VALIDATION_RULE.min(0).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.amount' },
  }),

  transactionCurrencyId: NULLABLE_NUMBER_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.currency' },
  }),

  valueDate: yup.date().required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.value_date' },
  }),

  transactionPurpose: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
    DESCRIPTION_MAX_LENGTH,
    {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'transfers:internal_transfer.form_fields.purpose',
        number: DESCRIPTION_MAX_LENGTH,
      },
    },
  ).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'transfers:internal_transfer.form_fields.purpose' },
  }),

  sender: yup
    .object()
    .shape({
      accountNumber: NULLABLE_STRING_VALIDATION_RULE.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'transfers:internal_transfer.form_fields.from' },
      }),
      reference: SWIFT_RELATED_TEXT_VALIDATION_RULE.max(
        DESCRIPTION_MAX_LENGTH,
        {
          key: 'form:validation_messages.min_characters_with_label',
          values: {
            label:
              'transfers:internal_transfer.form_fields.from_account_reference',
            number: DESCRIPTION_MAX_LENGTH,
          },
        },
      ),
    })
    .test(
      'same_accounts',
      'The from account must be different than the recipient account',
      function (value, context) {
        return context.parent.recipient.accountNumber && value.accountNumber
          ? context.parent.recipient.accountNumber !== value.accountNumber
          : true;
      },
    ),
});

export const reviewMultipleTransactionsValidationSchema = yup.object().shape({
  reason: STRING_VALIDATION_RULE.max(CONFIRMATION_REASON_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'common:reason', number: CONFIRMATION_REASON_MAX_LENGTH },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'common:reason' },
  }),
});
