export enum RequestStatuses {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
}

export enum RequestTypes {
  HandoffApplication = 'application-handoff',
  AcceleratedDueDiligence = 'client-group-join',
}
