import APIConfig from '../../config/api';
import { IProfile } from '../../typings/profile/profile';
import { APIService } from '../axiosInstance';
import { profileAPIAdapter } from 'apiAdapters/profile/profileAPIAdapter';
import { PhoneVerificationDataModel } from '../../typings/application/contact';

export interface UpdatePhoneNumberResponseModel {
  number: string;
  verification: PhoneVerificationDataModel;
}

const profileAPI = {
  fetchMe: () => {
    return APIService.get<IProfile>(`${APIConfig.crmApi}/users/profile`).then(
      (response) => profileAPIAdapter.fetchProfile(response.data),
    );
  },

  verifyPhoneNumber: (code: string, phoneNumber: string) => {
    return APIService.post(`${APIConfig.crmApi}/users/phone/verify`, {
      code,
      phoneNumber,
    }).then((response) => response.data);
  },

  resendPhoneNumberOTP: (phoneNumber: string) => {
    return APIService.post<UpdatePhoneNumberResponseModel>(
      `${APIConfig.crmApi}/users/phone/start-verification`,
      { phoneNumber },
    ).then((response) => response.data);
  },
};

export { profileAPI };
