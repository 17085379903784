import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';

import { FormValuesModel as InvitationVerifyFormValues } from '../../components/Forms/TemplateForms/Invitation/InvitationVerifyForm';
import { FormValuesModel as InvitationRequestFormValues } from '../../components/Forms/TemplateForms/Invitation/InvitationRequestForm';

interface InvitationResponseModel {
  isValid: boolean;
}

const invitationAPI = {
  verifyCode: (body: InvitationVerifyFormValues) => {
    return APIService.post<InvitationResponseModel>(
      `${APIConfig.crmPublicApi}/invitation/verify`,
      body,
    ).then(({ data }) => data);
  },

  generateCode: (body: InvitationRequestFormValues) => {
    return APIService.post(`${APIConfig.crmPublicApi}/invitation`, body).then(
      ({ data }) => data,
    );
  },
};

export { invitationAPI };
