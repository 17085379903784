import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { OnboardingEntryTypes } from '../../../../../../../enums/onboarding/crm';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

// components
import AddButton from '../../../../../../DesignSystem/Common/Buttons/AddButton';
import DeleteButton from '../../../../../../DesignSystem/Common/Buttons/DeleteButton';
import SectionIntro from '../../../../../../DesignSystem/Common/Texts/SectionIntro';
import HideIfDisabledForm from '../../../../../../Forms/HideIfDisabledForm';
import RelationshipTemplateForm, {
  FormValuesModel as RelationshipFormValuesModel,
} from '../../../../../../Forms/TemplateForms/CRM/RelationshipTemplateForm';
import { Card } from 'antd';

const RelationshipsSection = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();
  const selectedRelationshipIds = useMemo(
    () => values.relationships.map((e) => e.id),
    [values.relationships],
  );

  const renderRelationships = (
    fieldArrayRenderProps: FieldArrayRenderProps,
  ) => {
    const initialRelationshipFieldValue: RelationshipFormValuesModel = {
      id: '',
      additionalFields: [],
      relationshipTemplate: null,
    };

    return (
      <>
        {values.relationships.map((_, i) => (
          <StyledCard
            key={i}
            title={t(
              'personal_info_modal.relationships.relationship_name_with_number',
              { number: i + 1 },
            )}
            extra={
              values.relationships.length > 1 && (
                <HideIfDisabledForm hideIfSubmitting>
                  <DeleteButton
                    onClick={() => fieldArrayRenderProps.remove(i)}
                  />
                </HideIfDisabledForm>
              )
            }
          >
            <RelationshipTemplateForm
              fieldName={`relationships.${i}`}
              childNode={{
                id: values.relationshipsToOrganizationId,
                type: OnboardingEntryTypes.Organization,
              }}
              parentNode={{
                id: values.relationshipsFromContactId,
                type: OnboardingEntryTypes.Contact,
              }}
              activeScopeId={values.activeApplicationScopeId}
              documentAssociation={values.documentAssociation}
              hideRelationshipIds={selectedRelationshipIds}
            />
          </StyledCard>
        ))}

        <HideIfDisabledForm hideIfSubmitting>
          <AddButton
            onClick={() =>
              fieldArrayRenderProps.push(initialRelationshipFieldValue)
            }
          >
            {t('personal_info_modal.relationships.add_relationship_button')}
          </AddButton>
        </HideIfDisabledForm>
      </>
    );
  };

  return (
    <>
      <SectionIntro
        title={t('structure.relationships.title')}
        titleVariant="h4"
      />

      <FieldArray name="relationships">
        {(fieldArrayRenderProps: FieldArrayRenderProps) =>
          renderRelationships(fieldArrayRenderProps)
        }
      </FieldArray>
    </>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default RelationshipsSection;
