import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import useUserAccess from '../../../../../../hooks/useUserAccess';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// constants
import { AccountProcessingTypes } from '../../../../../../enums/accounting/transfers';

// components
import FormField from '@core_components/FormField';
import FiatBankAccountForm from './FiatBankAccountForm';
import CircleBankAccountForm from './CircleBankAccountForm';
import BankAccountProcessingType, {
  IProps as BankAccountProcessingTypeProps,
} from '../../../../../Forms/FormComponents/SelectInputs/Accounting/BankAccountProcessingType';

const InnerForm = () => {
  const { t } = useTranslation('accounts');
  const { values } = useFormikContext<FormValuesModel>();

  const [hasFiatAccountCreateAccess, hasBlockchainAccountCreateAccess] =
    useUserAccess([
      'Accounts_FiatAccount_Create',
      'Accounts_BlockchainAccount_Create',
    ]);

  const renderContent = (accountType: AccountProcessingTypes) => {
    switch (accountType) {
      case AccountProcessingTypes.Native:
        return <FiatBankAccountForm />;

      case AccountProcessingTypes.Circle:
        return <CircleBankAccountForm />;

      default:
        return 'Not implemented';
    }
  };

  return (
    <>
      <FormField<BankAccountProcessingTypeProps>
        name="accountType"
        label={t('account_summary.create_account.form_fields.account_type')}
        component={BankAccountProcessingType}
        additionalProps={{
          hideBlockchainOption: !hasBlockchainAccountCreateAccess,
          hideFiatAccountOption: !hasFiatAccountCreateAccess,
        }}
      />

      {values.accountType && renderContent(values.accountType)}
    </>
  );
};

export default InnerForm;
