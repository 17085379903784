import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import useBreadcrumbs, { BreadcrumbModel } from 'hooks/useBreadcrumbs';
import { Route } from 'routes/routes';
import { useMainLayoutContext } from '../../MainLayoutContext';
import { LocalizationNamespaces } from 'constants/localization';

// components
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';
import { CardWrapper } from 'components/Additional/CardWrapper';
import { Outlet, useNavigate } from 'react-router-dom';
import { Breadcrumb, PageHeader } from 'antd';

interface IProps {
  route: Route;
}

const PageContent = ({ route }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(LocalizationNamespaces);
  const { pageLayoutOptions } = useMainLayoutContext();
  const breadcrumbs = useBreadcrumbs();
  const canRenderContentInCard = !route?.metadata?.notRenderContentInCard;
  const shouldRenderGoBackButton = route?.metadata?.renderGoBackButton;

  const renderBreadcrumbs = (breadcrumbsArray: BreadcrumbModel[]) => {
    if (!breadcrumbs.length) {
      return null;
    }

    return (
      <Breadcrumb>
        {breadcrumbsArray.map(({ title, path }) => (
          <Breadcrumb.Item key={path} onClick={() => navigate(path)}>
            {t(title.key, { ns: title.ns })}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  if (!route) {
    return null;
  }

  return (
    <StyledPageHeader
      title={
        !route.metadata?.notShowPageTitle ? (
          <>
            {pageLayoutOptions.title ||
              t(route.title.key, { ns: route.title.ns })}{' '}
            {pageLayoutOptions.appendToTitle}
            <RenderBadgeByRoutePath badgePosition="top" path={route.path} />
          </>
        ) : undefined
      }
      extra={
        typeof pageLayoutOptions.extra == 'function'
          ? pageLayoutOptions.extra()
          : pageLayoutOptions.extra
      }
      onBack={shouldRenderGoBackButton ? () => navigate(-1) : undefined}
      subTitle={
        typeof pageLayoutOptions.description == 'function'
          ? pageLayoutOptions.description()
          : pageLayoutOptions.description
      }
    >
      {!route.metadata?.notShowPageTitle ? (
        <>
          {renderBreadcrumbs(breadcrumbs)}
          {typeof pageLayoutOptions.content == 'function'
            ? pageLayoutOptions.content()
            : pageLayoutOptions.content}
        </>
      ) : null}
      {canRenderContentInCard ? (
        <CardWrapper size="small">
          <Outlet />
        </CardWrapper>
      ) : (
        <Outlet />
      )}
    </StyledPageHeader>
  );
};

const StyledPageHeader = styled(PageHeader)`
  /* Leave a space for box shadow when we need to focus element (User guide case) */
  .ant-page-header-heading-title {
    padding: 5px;
  }
`;

export default PageContent;
