import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import { StateModel } from '../../../redux/reducers';
import { clientGroupsAPI } from '../../../api/crm/clientGroupsAPI';
import { updateActiveApplication } from '../../../redux/actions/applications';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationStatuses,
  ClientGroupReviewStatuses,
} from '../../../enums/onboarding/crm';

// components
import Button from '../../../components/DesignSystem/Core/Button';
import SubmitConfirmationModal from './SubmitConfirmationModal';
import { Col, message, Modal, Row } from 'antd';

interface IProps {
  updateTrigger?: any;
  onDiscardChanges?: (newScopeId: string, approvedScopeId: string) => void;

  onSubmit?: () => void;
}

const ClientGroupActions = ({
  updateTrigger,
  onSubmit,
  onDiscardChanges,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const dispatch = useDispatch();
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const clientGroupId = useSelector<StateModel, string>(
    (state) => state.applications.activeApplication?.clientGroup?._id || '',
  );
  const applicationStatus = useSelector<StateModel, ApplicationStatuses | null>(
    (state) => state.applications.activeApplicationStatus,
  );

  const { response } = useFetch(
    () => clientGroupsAPI.fetchProfile(clientGroupId),
    [updateTrigger],
  );

  const isButtonVisible =
    applicationStatus !== ApplicationStatuses.InAccountManagementReview &&
    response?.isStructureChanged &&
    (response?.clientGroup.reviewStatus ===
      ClientGroupReviewStatuses.Reviewed ||
      response?.clientGroup.reviewStatus ===
        ClientGroupReviewStatuses.NotReviewed);

  const handleSubmit = () => {
    setConfirmationVisible(true);
  };

  const handleCloseConfirmation = (wasSubmitted?: boolean) => {
    setConfirmationVisible(false);
    wasSubmitted && onSubmit && onSubmit();
  };

  const discardChanges = async () => {
    const response =
      await clientGroupsAPI.discardClientProfileChanges(clientGroupId);
    message.success(
      t('client_group_actions.discard_changes.success_discard_message'),
    );
    dispatch(updateActiveApplication());
    onDiscardChanges && onDiscardChanges(response.active, response.approved);
  };

  const confirmDiscardChanges = () => {
    Modal.confirm({
      content: t('client_group_actions.discard_changes.description'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: discardChanges,
    });
  };

  return isButtonVisible ? (
    <StyledRow justify="end" gutter={[16, 16]}>
      <Col>
        <Button danger onClick={confirmDiscardChanges}>
          {t('client_group_actions.discard_changes.discard_button')}
        </Button>
      </Col>
      <Col>
        <Button onClick={handleSubmit}>
          {t('client_group_actions.submit_for_review.submit_for_review_button')}
        </Button>
      </Col>
      <SubmitConfirmationModal
        visible={isConfirmationVisible}
        closeCallback={handleCloseConfirmation}
        clientGroupId={clientGroupId}
      />
    </StyledRow>
  ) : null;
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

export default ClientGroupActions;
