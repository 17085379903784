import * as Yup from 'yup';

import { NULLABLE_STRING_VALIDATION_RULE } from '../common';

export const createAddressValidationSchema = Yup.object().shape({
  currency: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'accounts:account_activity.send_wire_instructions.circle.blockchain_address_modal.form_fields.currency',
    },
  }),

  network: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'accounts:account_activity.send_wire_instructions.circle.blockchain_address_modal.form_fields.network',
    },
  }),
});
