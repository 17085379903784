import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';
import { AdministrationPermissionTypes } from '../../../../../../enums/onboarding/crm';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface IProps extends SelectProps {
  hideLimitedPermission?: boolean;
}

const AdministrationPermissionsSelect = ({
  hideLimitedPermission,
  ...rest
}: IProps) => {
  const { t } = useTranslation(['onboarding', 'form']);

  const options = useMemo<SelectOption[]>(() => {
    const options = hideLimitedPermission
      ? [
          AdministrationPermissionTypes.Admin,
          AdministrationPermissionTypes.None,
        ]
      : [
          AdministrationPermissionTypes.Admin,
          AdministrationPermissionTypes.None,
          AdministrationPermissionTypes.LimitedAdmin,
        ];

    return options.map((key) => ({
      id: key,
      label: t(`user_management.administration_permissions.${key}`),
    }));
  }, [t, hideLimitedPermission]);

  return <FormSelect {...rest} options={options} />;
};

export default AdministrationPermissionsSelect;
