import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';

const InnerForm = () => {
  const { t } = useTranslation('invitation');

  return (
    <FormField
      name="email"
      component={FormInput}
      label={t('invitation_request.form_fields.email')}
    />
  );
};

export default InnerForm;
