import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { message } from 'antd';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import {
  CreateUpdateOnboardingApprovalRule,
  onboardingAPI,
  OnboardingApprovalGroup,
} from 'api/onboarding/onboardingAPI';

// components
import OnboardingTransactionRulesModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Onboarding/OnboardingTransactionRulesModal';

interface IProps {
  isVisible: boolean;
  approvalRuleId: string | null;
  applicationId: string;
  closeCallback: (wasModified?: boolean) => void;
}

const UpdateApprovalRule = ({
  isVisible,
  applicationId,
  approvalRuleId,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('onboarding');

  const activeClientGroupName = useSelector<StateModel, string>(
    (state) =>
      state.applications.activeApplication?.clientGroup?.clientGroupName || '',
  );

  const { response } = useFetch(
    () =>
      isVisible
        ? onboardingAPI.fetchApprovalGroups(
            {
              page: 1,
              limit: 100,
            },
            'onboarding-transaction',
          )
        : null,
    [isVisible],
  );

  const { response: approvalRuleResponse } = useFetch(
    () =>
      approvalRuleId
        ? onboardingAPI.fetchApprovalRuleById(approvalRuleId)
        : null,
    [approvalRuleId],
  );

  const initialValues = useMemo((): FormValuesModel | null => {
    if (!isVisible || !response || !approvalRuleResponse) {
      return null;
    }

    const { associatedAccounts, ruleSets, criteriaList } = approvalRuleResponse;

    const approvalGroupsMap = response.data.reduce<
      Record<string, OnboardingApprovalGroup>
    >((acc, next) => {
      acc[next._id] = next;
      return acc;
    }, {});

    return {
      applicationId,
      bankAccounts: associatedAccounts.map((e) => e._id),
      initialBankAccounts: associatedAccounts.map((e) => ({
        id: e._id,
        label: `${activeClientGroupName} ${e.currencyIsoCode} (${t(
          'administration.transaction_rules.introduction.purpose_of_bank_account',
        )} ${e.purpose})`,
      })),
      limits: criteriaList.map((e) => ({
        from: e.range?.min || 0,
        to: !e.range?.max ? undefined : e.range?.max || 0,
        toInfinite: !e.range?.max,
        isUsed: true,
      })),
      conditions: ruleSets.map((e, conditionIndex) => {
        const limitStatuses = criteriaList.map((c) => ({
          isChecked: c.activeSetIndices.includes(conditionIndex),
        }));

        return {
          conditions: e.items.map((c) => ({
            approvalGroup: c.group.id,
            requiredApproversCount:
              approvalGroupsMap[c.group.id].participants?.length <
              c.minimumThreshold
                ? 0
                : c.minimumThreshold,
          })),
          limitStatuses,
        };
      }),
      approvalGroups: response.data.map((e) => ({
        id: e._id,
        name: e.name,
        description: e.description,
        participants: e.participants.map((p) => ({
          userId: p.contactId,
          userFullName: p.name,
        })),
        isActive: true,
      })),
    };
  }, [
    isVisible,
    applicationId,
    activeClientGroupName,
    approvalRuleResponse,
    response,
  ]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (approvalRuleId) {
      const formattedData: CreateUpdateOnboardingApprovalRule = {
        type: 'onboarding-transaction',
        associatedAccountIds: values.bankAccounts,
        ruleSets: values.conditions.map((e) => ({
          items: e.conditions.map((c) => ({
            groupId: c.approvalGroup,
            minimumThreshold: c.requiredApproversCount,
          })),
        })),
        criteriaList: values.limits.map((e, limitIndex) => {
          const activeSetIndices = values.conditions.reduce<number[]>(
            (acc, next, conditionIndex) => {
              if (next.limitStatuses[limitIndex].isChecked) {
                acc.push(conditionIndex);
              }
              return acc;
            },
            [],
          );

          return {
            type: 'range',
            range: {
              min: e.from,
              max: e.toInfinite ? null : e.to || 0,
            },
            activeSetIndices,
          };
        }),
      };

      await onboardingAPI.updateApprovalRuleById(approvalRuleId, formattedData);
      message.success(
        t(
          'administration.transaction_rules.approval_rules.edit.success_message',
        ),
      );
    }
  };

  const handleDeleteCallback = async (form: FormikProps<FormValuesModel>) => {
    if (approvalRuleId) {
      await onboardingAPI.deleteApprovalRuleById(approvalRuleId);
      message.success(
        t(
          'administration.transaction_rules.approval_rules.edit.success_delete_message',
        ),
      );
      await form.resetForm({
        values: {} as any,
        errors: {},
        touched: {},
        isSubmitting: false,
        isValidating: false,
        status: undefined,
        submitCount: 0,
      });
      closeCallback(true);
    }
  };

  return (
    <OnboardingTransactionRulesModal
      title={t('administration.transaction_rules.approval_rules.edit.title')}
      isVisible={isVisible}
      onSubmit={handleSubmit}
      closeCallback={closeCallback}
      initialValues={initialValues}
      onDelete={handleDeleteCallback}
    />
  );
};

export default UpdateApprovalRule;
