import React, { useMemo } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import useTranslation from '../../hooks/useTranslation';

// components
import Tabs from '../../components/Tabs/Tabs';
import OverdraftTab from '../../modules/AccountManagement/LimitConfiguration/OverdraftTab';
import FeeScheduleTab from '../../modules/AccountManagement/LimitConfiguration/FeeScheduleTab';

const LimitConfiguration = () => {
  const { t } = useTranslation('account_management');

  const [hasFeeScheduleTabAccess, hasOverdraftTabAccess] = useUserAccess([
    'AccountManagement_LimitConfiguration_FeeSchedule_View',
    'AccountManagement_LimitConfiguration_Overdraft_View',
  ]);

  const tabs = useMemo(() => {
    return [
      {
        key: 'fee-schedule',
        title: t('fee_schedule.title'),
        content: <FeeScheduleTab />,
        hidden: !hasFeeScheduleTabAccess,
      },
      {
        key: 'overdraft',
        title: t('overdraft.title'),
        content: <OverdraftTab />,
        hidden: !hasOverdraftTabAccess,
      },
    ];
  }, [hasFeeScheduleTabAccess, hasOverdraftTabAccess]);

  return <Tabs tabs={tabs} />;
};

export default LimitConfiguration;
