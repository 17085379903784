import React, { useMemo } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import { banksAPI } from '../../../../../../api/accounting/banksAPI';
import { IBankCodeType } from '../../../../../../typings/accounting/bank';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps<IBankCodeType>;

const BankCodeTypeSelect = (props: IProps) => {
  const { response, loading } = useFetch(
    () => banksAPI.fetchBankCodeTypes(),
    [],
  );

  const options = useMemo<SelectOption<IBankCodeType>[]>(() => {
    if (!response) {
      return [];
    }

    return response.data.map((e) => ({
      id: e.id,
      label: e.title,
      model: e,
    }));
  }, [response]);

  return <FormSelect {...props} options={options} loading={loading} />;
};

export default BankCodeTypeSelect;
