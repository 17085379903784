import React, { useMemo } from 'react';

// helpers
import { IEntity } from '../../../../../../../../../typings/application/entity';

// components
import IdentificationForOrganizationShortForm, {
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/IdentificationForOrganizationShortForm';

interface IProps {
  organization: IEntity;
}

const Identification = ({ organization }: IProps) => {
  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      submitActionType: null,
      isSave: false,
      isRegulated:
        typeof organization.isRegulated === 'boolean'
          ? organization.isRegulated
          : null,
      regulationCountry: organization.regulationCountry,
    };
  }, [organization]);

  const handleOnSubmit = async () => {
    // TODO: discuss if submit is needed here
  };

  return (
    <IdentificationForOrganizationShortForm
      disabled
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
      showOnlySaveButton
    />
  );
};

export default Identification;
