import React, { useMemo } from 'react';

// helpers
import {
  SigninSubmitUsernameResponseModel,
  userAPI,
} from '../../../../../api/profile/userAPI';

// components
import SignInUsernameForm, {
  FormValuesModel,
} from '../../../../../components/Forms/TemplateForms/Auth/SignIn/SignInUsernameForm';

interface IProps {
  onSubmit: (
    username: string,
    response: SigninSubmitUsernameResponseModel,
  ) => void;
}

const SignInUsername = ({ onSubmit }: IProps) => {
  const initialFormValues: FormValuesModel = useMemo(
    () => ({
      username: '',
    }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    const payload = await userAPI.checkUsername({ username: values.username });
    if (payload) {
      onSubmit(values.username, payload);
    }
  };

  return (
    <SignInUsernameForm
      handleSubmit={handleSubmit}
      initialFormValues={initialFormValues}
    />
  );
};

export default SignInUsername;
