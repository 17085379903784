import React from 'react';

// components
import SignInForm from '../../modules/Auth/SignInForm';

const SignInPage = () => {
  return <SignInForm />;
};

export default SignInPage;
