import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { InternalTransferTemplateDataModel } from '../../../../../../../../typings/accounting/transfer';

// components
import EllipsisTooltip from '../../../../../../../Tooltips/EllipsisTooltip';
import { Card, Col, Row } from 'antd';

interface IProps {
  template: InternalTransferTemplateDataModel;
}

const InternalTransferTemplateShortInfo = memo(({ template }: IProps) => {
  const { t } = useTranslation('transfers');

  return (
    <StyledCard>
      <Row>
        <StyledCol
          span={10}
        >{`${t('internal_transfer.account_name')}:`}</StyledCol>
        <StyledCol span={14}>
          <EllipsisTooltip
            title={template.accountName}
            placement="left"
            maxTextContainerWidth="250px"
          >
            {template.accountName}
          </EllipsisTooltip>
        </StyledCol>
        <StyledCol
          span={10}
        >{`${t('internal_transfer.account_number')}:`}</StyledCol>
        <StyledCol span={14}>
          <EllipsisTooltip
            title={template.accountNumber}
            placement="left"
            maxTextContainerWidth="250px"
          >
            {template.accountNumber}
          </EllipsisTooltip>
        </StyledCol>
      </Row>
    </StyledCard>
  );
});

const StyledCard = styled(Card)`
  margin-bottom: 10px;
`;

const StyledCol = styled(Col)`
  padding: 4px;
`;

export default InternalTransferTemplateShortInfo;
