import { useState, useEffect } from 'react';

// helpers
import { StateModel } from '../redux/reducers';
import { useSelector } from 'react-redux';
import { StateModel as UserAccessStateModel } from '../redux/reducers/userAccess';

// constants
import { PERMISSION_KEYS } from '../config/permissions';

const useUserAccess = (permissionKeys: PERMISSION_KEYS[]) => {
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );

  const [userAccessByPermissions, setUserAccessByPermissions] = useState<
    boolean[]
  >(getUserAccessPermissions(permissionKeys));

  useEffect(() => {
    if (permissionKeys.length) {
      const result = getUserAccessPermissions(permissionKeys);
      setUserAccessByPermissions(result);
    } else {
      setUserAccessByPermissions([]);
    }
  }, permissionKeys);

  function getUserAccessPermissions(keys: PERMISSION_KEYS[]) {
    return keys.map((key) => hasPermission(key));
  }

  function hasPermission(permissionKey: PERMISSION_KEYS) {
    return permissions[permissionKey].isAllowed;
  }

  return userAccessByPermissions.slice();
};

export default useUserAccess;
