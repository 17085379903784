import React, { useMemo } from 'react';

// helpers
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';

// components
import IdentificationTabIsNotEditableInNonKYCFormTooltip from 'components/Tooltips/TemplateTooltips/IdentifcationTabIsNotEditableInNonKYCFormTooltip';
import IdentificationForIndividualShortForm, {
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/IdentificationForIndividualShortForm';

const Identification = () => {
  const { contact } = useSelector<StateModel, PersonalDetailsStateModel>(
    (state) => state.personalDetails,
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!contact) {
      return null;
    }

    return {
      submitActionType: null,
      isSave: null,
      isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
      pepInformation: contact.pepInformation || '',
    };
  }, [contact]);

  const handleSubmit = async () => {
    // TODO: decide if submit will be needed here
  };

  return (
    <IdentificationForIndividualShortForm
      showOnlySaveButton
      disabled
      onSubmit={handleSubmit}
      initialFormValues={initialFormValues}
      mainSectionTooltip={<IdentificationTabIsNotEditableInNonKYCFormTooltip />}
    />
  );
};

export default Identification;
