import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { IApprovalGroupShort } from '../../../../../../typings/approvalManagement/accountManagement';
import { accountManagementRulesAPI } from '../../../../../../api/accountManagement/accountManagementRulesAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import InfoTooltip from '../../../../../Tooltips/InfoTooltip';
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export type ApprovalRuleType =
  | 'account-management'
  | 'onboarding-account-management';

export interface ApprovalGroupsAutocompleteProps
  extends AutocompleteProps<IApprovalGroupShort> {
  approvalRuleType: ApprovalRuleType;
  showOnlyActiveApprovalGroups?: boolean;
}

const ApprovalGroupsAutocomplete = ({
  showOnlyActiveApprovalGroups,
  approvalRuleType,
  ...rest
}: ApprovalGroupsAutocompleteProps) => {
  const { t } = useTranslation('account_management');

  const fetchOptions = async () => {
    let approvalGroups: IApprovalGroupShort[] = [];

    switch (approvalRuleType) {
      case 'account-management':
        {
          const response = await accountManagementRulesAPI.fetchApprovalGroups({
            page: 1,
            limit: AUTOCOMPLETE_RESULT_LIMIT,
            isActiveOnly: showOnlyActiveApprovalGroups,
          });

          approvalGroups = response.data.slice();
        }
        break;

      case 'onboarding-account-management':
        {
          const response = await onboardingAPI.fetchApprovalGroups(
            {
              page: 1,
              limit: AUTOCOMPLETE_RESULT_LIMIT,
            },
            'onboarding-account-management',
          );

          approvalGroups = response.data.map((e) => ({
            id: e._id,
            name: e.name,
            description: e.description,
            participants: e.participants.map((p) => ({
              userId: p.contactId,
              userFullName: p.name,
            })),
            isActive: true,
          }));
        }
        break;
    }

    return approvalGroups.map((e) => ({
      id: e.id,
      model: e,
      disabled: !e.isActive,
      label: e.isActive ? (
        e.name
      ) : (
        <StyledApprovalGroupOptionWrapper>
          <StyledApprovalGroupOptionTextWrapper>
            {e.name}
          </StyledApprovalGroupOptionTextWrapper>
          <InfoTooltip
            tooltipContent={t(
              'approval_groups.approval_group_in_review_warning_message',
            )}
          />
        </StyledApprovalGroupOptionWrapper>
      ),
    }));
  };

  return <FormAutocomplete {...rest} fetchData={fetchOptions} />;
};

const StyledApprovalGroupOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledApprovalGroupOptionTextWrapper = styled.div`
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default ApprovalGroupsAutocomplete;
