import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FeeScheduleModel } from '../../typings/limitConfiguration/feeSchedule';
import { FetchResponseModel } from '../../typings/common';

export const feeScheduleAPI = {
  fetchDefaultFeeSchedules: () => {
    return APIService.get<FetchResponseModel<FeeScheduleModel>>(
      `${APIConfig.accountingApi}/client-groups/fee-schedules`,
    ).then(({ data }) => data);
  },
};
