import moment from 'moment';
import { FetchResponseModel } from '../../typings/common';
import { ClientGroupUserStatuses } from '../../enums/onboarding/crm';
import {
  IClientGroupUser,
  IClientGroupUserStatus,
} from '../../typings/approvalManagement/accountManagement';

const userManagementAdapter = {
  fetchUsers: (data: FetchResponseModel<IClientGroupUser>) => {
    const formattedUsers = data.data.map(userManagementAdapter.fetchUser);
    return { data: formattedUsers, total: data.total };
  },

  fetchUser: (data: IClientGroupUser) => {
    const { name, ttl } = data.status;
    const status: IClientGroupUserStatus = {
      ttl,
      name:
        name === ClientGroupUserStatuses.Invited &&
        moment().utc().isAfter(moment(ttl))
          ? ClientGroupUserStatuses.InvitationExpired
          : name,
    };
    return { ...data, status };
  },
};

export { userManagementAdapter };
