import React, { useMemo } from 'react';

// constants
import { TWO_COL_GRID_SIZES } from '../../../../../../constants/grids';
import { MAX_ADDRESSES_COUNT } from '../../../../../../constants/onboarding';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { AddressItemModel, FormValuesModel } from '..';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import AddButton from '../../../../../DesignSystem/Common/Buttons/AddButton';
import InfoTooltip from '../../../../../Tooltips/InfoTooltip';
import SectionIntro from '../../../../../DesignSystem/Common/Texts/SectionIntro';
import AddressesForm from './AddressesForm';
import DivAlignCenter from '../../../../../Additional/DivAlignCenter';
import TrueFalseSelect from '../../../../FormComponents/SelectInputs/TrueFalseSelect';
import FormErrorTooltip from '../../../../FormErrorTooltip';
import HideIfDisabledForm from '../../../../HideIfDisabledForm';
import CountrySelect, {
  IProps as CountrySelectProps,
} from '../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import { Col, Row } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const renderAddresses = (arrayHelper: FieldArrayRenderProps) => {
    const newAddressData: AddressItemModel = {
      isPrimary: false,
      type: null,
      country: null,
      city: '',
      street: '',
      state: '',
      postalCode: '',
    };

    return (
      <>
        {values.addresses.map((_, i) => (
          <AddressesForm
            key={i}
            index={i}
            onRemoveClick={() => arrayHelper.remove(i)}
          />
        ))}

        {values.addresses.length < MAX_ADDRESSES_COUNT && (
          <HideIfDisabledForm hideIfSubmitting>
            <AddButton onClick={() => arrayHelper.push(newAddressData)}>
              {t('identification.add_address_button')}
            </AddButton>
          </HideIfDisabledForm>
        )}
      </>
    );
  };

  const sectionTitle = useMemo(() => {
    const title = t('identification.title');

    if (values.disabledRegulated) {
      return (
        <DivAlignCenter>
          {title}
          <StyledInfoTooltip
            tooltipContent={t('identification.regulated_tooltip')}
          />
        </DivAlignCenter>
      );
    }

    return title;
  }, [values.disabledRegulated]);

  return (
    <>
      <SectionIntro
        title={sectionTitle}
        description={t('identification.description_entity')}
      />
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES} pull={0}>
          <FormField
            label={t(
              'identification.form_fields.organization.identification_questions.is_regulated',
            )}
            name="isRegulated"
            component={TrueFalseSelect}
            disabled={values.disabledRegulated}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          {values.isRegulated && (
            <FormField<CountrySelectProps>
              label={t(
                'identification.form_fields.organization.identification_questions.regulated_in_countries',
              )}
              name="regulationCountry"
              component={CountrySelect}
              disabled={values.disabledRegulated}
              additionalProps={{ mode: 'multiple' }}
            />
          )}
        </Col>

        <Col {...TWO_COL_GRID_SIZES} pull={0}>
          <FormField
            label={t(
              'identification.form_fields.organization.identification_questions.can_issue_bearer_shares',
            )}
            name="canIssueBearerShares"
            labelTooltip={t(
              'identification.form_fields.organization.identification_questions.can_issue_bearer_shares_tooltip',
            )}
            component={TrueFalseSelect}
          />
        </Col>
      </Row>
      <SectionIntro
        title={t('identification.address_information_section_title')}
        titleVariant="h4"
        description={t(
          'identification.address_information_for_organization_description',
        )}
      />
      <FormErrorTooltip<FormValuesModel> errorKey="addresses" />
      <FieldArray name="addresses" render={renderAddresses} />
    </>
  );
};

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
