import React, { useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import { accountsAPI } from '../../../api/accounting/accountsAPI';
import { BankAccountStatuses } from 'enums/accounts/accounts';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';

// components
import AccountsTable from '../../../components/Tables/TableTemplates/Accounts/AccountTable';

interface IProps {
  updateAccountsTrigger?: any;
}

const AccountSummary = ({ updateAccountsTrigger }: IProps) => {
  const [current, setCurrentPage] = useState(1);
  const [activeFilters, setActiveFilters] = useState<{
    status: BankAccountStatuses[] | undefined;
  }>({
    status: [
      BankAccountStatuses.Active,
      BankAccountStatuses.Processing,
      BankAccountStatuses.PendingApproval,
    ],
  });

  const { response, loading } = useFetch(
    () =>
      accountsAPI.fetchAccounts({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        status:
          activeFilters.status && activeFilters.status.length
            ? activeFilters.status.join(',')
            : undefined,
      }),
    [current, updateAccountsTrigger, activeFilters],
  );

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'status':
        setActiveFilters((prev) => ({
          ...prev,
          status: value as BankAccountStatuses[],
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <AccountsTable
      data={response?.data || []}
      total={response?.total || 0}
      current={current}
      loading={loading}
      onPaginationChange={setCurrentPage}
      filters={activeFilters}
      onFilterChange={onFilterChange}
    />
  );
};

export default AccountSummary;
