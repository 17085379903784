import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

// components
import Text from '@core_components/Text';
import { Tooltip } from 'antd';

const ChangeRequestInProcessingTooltip = memo(() => {
  const { t } = useTranslation('profile');

  return (
    <Tooltip
      title={t('personal_details.processing_status.description')}
      placement="bottom"
    >
      <StyledText variant="body1">
        {t('personal_details.processing_status.title')}
      </StyledText>
    </Tooltip>
  );
});

const StyledText = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => theme.colorWarning};
`;

export default ChangeRequestInProcessingTooltip;
