import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StyledComponentProps } from 'typings/common';

// components
import Text from '@core_components/Text';
import InfoTooltip from 'components/Tooltips/InfoTooltip';

type IProps = StyledComponentProps;

const PhoneNumberTooltip = (props: IProps) => {
  const { t } = useTranslation('account_management');

  return (
    <InfoTooltip
      {...props}
      tooltipContent={
        <Text variant="body1">
          {t('structure.relationships.phone_number_description')}
        </Text>
      }
    />
  );
};

export default PhoneNumberTooltip;
