import React, { useMemo } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps<string>;

const BlockchainNetworkSelect = (props: IProps) => {
  const { response, loading } = useFetch(
    () => transferTemplatesAPI.fetchBlockchainNetworks(),
    [],
  );

  const options = useMemo<SelectOption<string>[]>(() => {
    if (!response) {
      return [];
    }

    return response.data.map((e) => ({
      id: e,
      label: e,
    }));
  }, [response]);

  return <FormSelect {...props} loading={loading} options={options} />;
};

export default BlockchainNetworkSelect;
