import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';

// constants
import { TWO_COL_GRID_SIZES } from '../../../../../../../constants/grids';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import SectionIntro from '@common_components/Texts/SectionIntro';
import CurrencySelect from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/CurrencySelect';
import WireRangesSelect from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/WireRangesSelect';
import AccountTypeSelect from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/AccountTypeSelect';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import InitialDepositRangeSelect from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/InitialDepositRangeSelect';
import EstimatedAmountFundsRangeSelect from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/EstimatedAmountFundsRangeSelect';
import CountrySelect, {
  IProps as CountrySelectProps,
} from '../../../../../../Forms/FormComponents/SelectInputs/CRM/CountrySelect';
import { Col, Row } from 'antd';

const FiatBankAccountForm = () => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      <FormField
        label={t('account_information.form_fields.purpose_of_account')}
        name="fiatAccount.purpose"
        component={FormInputTextArea}
        additionalProps={{ autoSize: { minRows: 2, maxRows: 6 } }}
      />

      <FormField
        disabled
        label={t('account_information.form_fields.account_type')}
        name="fiatAccount.type"
        component={AccountTypeSelect}
      />

      <FormField
        label={t('account_information.form_fields.currency')}
        name="fiatAccount.currencyId"
        component={CurrencySelect}
      />

      <FormField
        label={t('account_information.form_fields.expected_initial_deposit')}
        name="fiatAccount.initialDeposit"
        component={InitialDepositRangeSelect}
      />

      <FormField
        label={t('account_information.form_fields.source_of_initial_deposit')}
        name="fiatAccount.initialDepositOrigin"
        component={CountrySelect}
      />

      <SectionIntro
        title={t('account_information.estimated_monthly_account_activity')}
        titleVariant="h4"
      />
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <Text variant="body1">
            {t('account_information.form_fields.jurisdictions')}
          </Text>
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField<CountrySelectProps>
            name="fiatAccount.jurisdictions"
            component={CountrySelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>

      <StyledRow gutter={[16, 0]}>
        <Col offset={4} span={10}>
          <Text variant="body1">{t('account_information.volume')}</Text>
        </Col>

        <Col span={10}>
          <Text variant="body1">{t('account_information.amount')}</Text>
        </Col>
      </StyledRow>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Text variant="body1" weight="semi-bold">
            {t('account_information.incoming_activity')}
          </Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name="fiatAccount.incomingWiresNumber"
            component={WireRangesSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name="fiatAccount.estimatedIncomingFunds"
            component={EstimatedAmountFundsRangeSelect}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Text variant="body1" weight="semi-bold">
            {t('account_information.outgoing_activity')}
          </Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name="fiatAccount.outgoingWiresNumber"
            component={WireRangesSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name="fiatAccount.estimatedOutgoingFunds"
            component={EstimatedAmountFundsRangeSelect}
          />
        </Col>
      </Row>
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default FiatBankAccountForm;
