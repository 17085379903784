import React from 'react';

// helpers
import styled from 'styled-components';
import { Route } from 'routes/routes';

// components
import PageContent from './PageContent';
import { Outlet, useLocation } from 'react-router-dom';

interface IProps {
  route: Route | null;
}

const PageRenderer = ({ route }: IProps) => {
  const location = useLocation();

  if (!route || location.pathname !== route.path) {
    return null;
  }

  return !route?.metadata?.renderRouteComponentOnly ? (
    <ContentBodyWrapper>
      <PageContent route={route} />
    </ContentBodyWrapper>
  ) : (
    <Outlet />
  );
};

const ContentBodyWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.containerPadding};
  margin-bottom: ${({ theme }) => theme.marginXl};

  @media screen and (max-width: ${({ theme }) => theme.mobileScreen}) {
    padding: ${({ theme }) => theme.paddingMd};
  }
`;

export default PageRenderer;
