import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { StyledComponentProps } from '../../../../typings/common';

// components
import Spinner from '../Spinner';
import IconSVG from '@core_components/IconSVG';
import { Select as SelectAntD } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../resources/icons/remix-icons/close-line.svg';

export interface SelectOption<Option = unknown> {
  id: string | number;
  label: React.ReactNode;
  model?: Option;
  hidden?: boolean;
  disabled?: boolean;
}

export interface SelectProps<Option = unknown> extends StyledComponentProps {
  value?: string | number;
  options?: SelectOption<Option>[];

  mode?: 'multiple';
  size?: SelectSizes;
  loading?: boolean;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onChange?: (value: string, option?: Option) => void;
  onSelect?: (
    value: string,
    option: {
      key: string;
      children: React.ReactNode;
      model?: Option;
      value: string;
    },
  ) => void;
  disabled?: boolean;
  placeholder?: string;
}

export type SelectSizes = 'large' | 'middle' | 'small';

function Select<Option = unknown>({
  value,
  options,
  placeholder,
  disabled,
  size = 'large',
  onChange,
  onBlur,
  onSelect,
  mode,
  loading,
  ...rest
}: SelectProps<Option>) {
  const { t } = useTranslation('form');

  const modifiedValue = useMemo(() => {
    if (!value || !options || !options.length) {
      return undefined;
    }

    return value;
  }, [value, options, loading]);

  const fieldPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    if (mode === 'multiple') {
      return t('select_multiple_options');
    } else {
      return t('select_option');
    }
  }, [mode, placeholder, t]);

  const renderOptions = (items: SelectOption<Option>[]) => {
    return items.reduce<React.ReactNode[]>((acc, next) => {
      if (!next.hidden) {
        acc.push(
          <StyledSelectAntD.Option
            key={next.id}
            value={next.id}
            model={next.model}
            disabled={next.disabled}
          >
            {next.label}
          </StyledSelectAntD.Option>,
        );
      }

      return acc;
    }, []);
  };

  return (
    <StyledSelectAntD
      {...rest}
      allowClear
      showArrow
      clearIcon={
        <StyledIconSVG
          component={CloseIcon}
          color={colorsTheme.colorWhite}
          size="extra-small"
        />
      }
      mode={mode}
      size={size}
      value={modifiedValue}
      onBlur={onBlur}
      loading={loading}
      disabled={disabled || loading}
      onChange={onChange as any}
      onSelect={onSelect as any}
      placeholder={fieldPlaceholder}
      showSearch
      optionFilterProp="children"
      filterOption={(input: string, option: any) =>
        option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >=
        0
      }
      notFoundContent={
        loading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : undefined
      }
    >
      {renderOptions(options || [])}
    </StyledSelectAntD>
  );
}

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSelectAntD = styled(SelectAntD)`
  .ant-select-clear {
    background-color: ${({ theme }) => theme.colorWarning};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default Select;
