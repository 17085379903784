import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../../../resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import { FormValuesModel, RegisterSecurityKeySteps } from '../..';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import IconSVG from '@core_components/IconSVG';
import { ReactComponent as KeyIcon } from '../../../../../../../resources/icons/remix-icons/key-line.svg';

const InitialStep = () => {
  const { t } = useTranslation(['profile', 'common']);
  const { setFieldValue } = useFormikContext<FormValuesModel>();

  const handleNextClick = () => {
    setFieldValue('currentStep', RegisterSecurityKeySteps.RegisterKey);
  };

  return (
    <>
      <StyledKeyIcon
        component={KeyIcon}
        size="large"
        color={colorsTheme.colorWhite}
      />
      <StyledText variant="body1" gutterBottom>
        {t(
          'security.two_step_verification.security_key.register_new_key.welcome_step.description',
        )}
      </StyledText>
      <StyledButton size="large" onClick={handleNextClick}>
        {t('next', { ns: 'common' })}
      </StyledButton>
    </>
  );
};

const StyledKeyIcon = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default InitialStep;
