const fontsTheme = {
  fontSizeLg: '24px',
  fontSizeBg: '20px',
  fontSizeMd: '18px',
  fontSizeSm: '16px',
  fontSizeXs: '14px',
  fontSizeXXs: '12px',
};

export { fontsTheme };
