import React, { useMemo } from 'react';

// helpers
import { PendingTransaction } from 'typings/accounting/transaction';

// components
import ReviewMultipleTransactionsModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Transfers/ReviewMultipleTransactionsModal';

interface IProps {
  action: 'approve' | 'reject' | null;
  selectedTransactions: Record<string, PendingTransaction>;
  closeCallback: (wasSubmitted?: boolean) => void;
}

const ReviewTransactionsModal = ({
  action,
  selectedTransactions,
  closeCallback,
}: IProps) => {
  const initialValues = useMemo<FormValuesModel | null>(() => {
    if (!action) {
      return null;
    }

    return {
      action,
      reason: '',
      step: 'confirm-action',
      transactions: Object.entries(selectedTransactions).map(
        ([id, transaction]) => ({
          id,
          workflowId: transaction.workflowId,
          description: transaction.description,
        }),
      ),
      completed: 0,
      result: null,
    };
  }, [action, selectedTransactions]);

  return (
    <ReviewMultipleTransactionsModal
      onSubmit={console.log}
      isVisible={!!initialValues}
      closeCallback={closeCallback}
      initialValues={initialValues}
    />
  );
};

export default ReviewTransactionsModal;
