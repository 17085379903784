import React, { useMemo } from 'react';

// helpers
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface RangeOptionModel {
  min: number | null;
  max: number | null;
}

export type WireRangeKeys =
  | 'volume1'
  | 'volume2'
  | 'volume3'
  | 'volume4'
  | 'volume5'
  | 'volume6'
  | 'volume7'
  | 'volume8'
  | 'volume9'
  | 'volume10'
  | 'volume11';

export const WireRangeOptions: Record<WireRangeKeys, RangeOptionModel> = {
  volume1: {
    min: 0,
    max: 20,
  },
  volume2: {
    min: 21,
    max: 40,
  },
  volume3: {
    min: 41,
    max: 60,
  },
  volume4: {
    min: 61,
    max: 80,
  },
  volume5: {
    min: 81,
    max: 100,
  },
  volume6: {
    min: 101,
    max: 120,
  },
  volume7: {
    min: 121,
    max: 140,
  },
  volume8: {
    min: 141,
    max: 160,
  },
  volume9: {
    min: 161,
    max: 180,
  },
  volume10: {
    min: 181,
    max: 200,
  },
  volume11: {
    min: 200,
    max: null,
  },
};

export type IProps = SelectProps;

const WireRangesSelect = (props: IProps) => {
  const options = useMemo<SelectOption[]>(() => {
    const getOptionLabel = (key: WireRangeKeys) => {
      const { min, max } = WireRangeOptions[key];

      if (typeof min !== 'number' || max === min) {
        return `${max}`;
      }

      if (typeof max !== 'number') {
        return `>${min}`;
      }

      return `${min}-${max}`;
    };

    return Object.keys(WireRangeOptions).map((key) => ({
      id: key,
      label: getOptionLabel(key as WireRangeKeys),
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
};

export default WireRangesSelect;
