import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';

const circleAPI = {
  getIsConnectedStatus: () => {
    return APIService.get<{ isActive: boolean; isConnected: boolean }>(
      `${APIConfig.accountingApi}/circle/is-connected`,
    ).then(({ data }) => data);
  },

  connectService: (apiKey: string) => {
    return APIService.post(
      `${APIConfig.accountingApi}/accounts/circle/connect`,
      { apiKey },
    ).then(({ data }) => data);
  },

  disconnectService: () => {
    return APIService.post(
      `${APIConfig.accountingApi}/accounts/circle/disconnect`,
    ).then(({ data }) => data);
  },
};

export { circleAPI };
