import { EntityTypes } from '../../enums/onboarding/crm';
import { IEntityName } from '../../typings/application/entity';

const EntityHelpers = {
  getEntityNameByNameType: function (
    names: IEntityName[],
    kind = EntityTypes.Legal,
  ): string {
    const nameItem = names?.find((el) => el.type === kind);
    return nameItem?.name || '';
  },
};

export { EntityHelpers };
