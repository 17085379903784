import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import SectionDescriptionText from '../../SectionDescriptionText';

const SetupBankAccount = () => {
  const { t } = useTranslation('dashboard');

  return (
    <SectionDescriptionText>
      {t('welcome_section.setup_accounts.description')}
    </SectionDescriptionText>
  );
};

export default SetupBankAccount;
