import React, { useCallback, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { debounce } from 'lodash';
import { colorsTheme } from 'resources/theme/styled/colors';
import { StyledComponentProps } from '../../../../typings/common';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from 'constants/global';

// components
import Spinner from '../Spinner';
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { Select as SelectAntD } from 'antd';
import { ReactComponent as CloseIcon } from '../../../../resources/icons/remix-icons/close-line.svg';

type Value = string | number;

export type AutocompleteOption<Option = unknown> = {
  id: Value;
  label: React.ReactNode;
  model?: Option;
  disabled?: boolean;
};

export type AutocompleteEventOption<Option = unknown> = {
  key: string;
  value: string;
  disabled?: boolean;
  children: React.ReactNode;
  model?: Option;
};

export interface AutocompleteProps<Option = unknown>
  extends StyledComponentProps {
  value?: string | number | string[] | number[];
  initialValue?: AutocompleteOption<Option> | AutocompleteOption<Option>[];

  minSearchLength?: number;
  excludeElementIds?: Value[];

  mode?: 'multiple';
  size?: AutocompleteSizes;
  disabled?: boolean;
  placeholder?: string;
  noInitialOptionsText?: string;

  appendToMenu?: React.ReactNode;

  onBlur?: React.FocusEventHandler<HTMLElement>;
  refreshAutocompleteTrigger?: any;

  fetchData?: (searchQuery: string) => Promise<AutocompleteOption<Option>[]>;
  onChange?: (
    value: Value | Value[],
    option?:
      | AutocompleteEventOption<Option>
      | AutocompleteEventOption<Option>[],
  ) => void;
  onSelect?: (value: Value, option: AutocompleteEventOption<Option>) => void;
  onDeselect?: (value: Value, option: AutocompleteEventOption<Option>) => void;
  onClear?: () => void;
}

export type AutocompleteSizes = 'large' | 'middle' | 'small';

function Autocomplete<Option = unknown>({
  value,
  initialValue,
  placeholder,
  disabled,
  noInitialOptionsText,
  size = 'large',
  onChange,
  onBlur,
  onSelect,
  onDeselect,
  mode,
  excludeElementIds,
  minSearchLength,
  fetchData,
  appendToMenu,
  refreshAutocompleteTrigger,
  ...rest
}: AutocompleteProps<Option>) {
  const { t } = useTranslation(['form', 'common']);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [availableOptions, setAvailableOptions] = useState<
    AutocompleteOption<Option>[]
  >([]);

  useFetch(async () => {
    if (!isLoading && refreshAutocompleteTrigger) {
      await fetchAndSaveOptions('');
    }
  }, [refreshAutocompleteTrigger]);

  const formattedValue = useMemo(() => {
    if (value) {
      return value;
    }

    return undefined;
  }, [value]);

  const fieldPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    if (mode === 'multiple') {
      return t('select_multiple_options');
    } else {
      return t('select_option');
    }
  }, [mode, placeholder, t]);

  const fetchAndSaveOptions = async (searchQuery: string) => {
    if (fetchData) {
      const options = await fetchData(searchQuery);
      setAvailableOptions(options);
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce(fetchAndSaveOptions, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [fetchData],
  );

  const onSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);

    if (fetchData) {
      if (!minSearchLength || searchQuery.length >= minSearchLength) {
        setLoading(true);
        debouncedFetchData(searchQuery);
      } else {
        setAvailableOptions([]);
        setLoading(false);
      }
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLElement> = async () => {
    if (!availableOptions.length) {
      await onSearch('');
    }
  };

  const renderOptions = (
    items: AutocompleteOption<Option>[],
    initialOptions?: AutocompleteOption<Option> | AutocompleteOption<Option>[],
    skipItemIds?: Value[],
  ): JSX.Element[] | null => {
    if (isLoading) {
      return null;
    }

    let result: JSX.Element[] | null = null;

    if (!items.length) {
      if (initialOptions) {
        const formattedOptions = Array.isArray(initialOptions)
          ? initialOptions
          : [initialOptions];
        if (formattedOptions.length) {
          result = formattedOptions.map((e) => (
            <SelectAntD.Option
              key={e.id}
              value={e.id}
              model={e.model}
              disabled={e.disabled}
            >
              {e.label}
            </SelectAntD.Option>
          ));
        }
      }
    } else {
      let itemsCopy = items.slice();

      if (skipItemIds) {
        itemsCopy = itemsCopy.filter((e) => !skipItemIds.includes(e.id));
      }

      return itemsCopy.map((e) => (
        <SelectAntD.Option
          key={e.id}
          value={e.id}
          model={e.model}
          disabled={e.disabled}
        >
          {e.label}
        </SelectAntD.Option>
      ));
    }

    return result;
  };

  return (
    <StyledSelectAntD
      {...rest}
      showArrow
      showSearch
      allowClear
      filterOption={false}
      clearIcon={
        <StyledIconSVG
          component={CloseIcon}
          color={colorsTheme.colorWhite}
          size="extra-small"
        />
      }
      value={formattedValue}
      mode={mode}
      size={size}
      disabled={disabled}
      onFocus={handleFocus}
      onBlur={onBlur}
      onChange={onChange as any}
      onSelect={onSelect as any}
      onDeselect={onDeselect as any}
      onSearch={onSearch}
      placeholder={fieldPlaceholder}
      loading={isLoading}
      dropdownRender={(menu) => (
        <>
          {appendToMenu}
          {menu}
        </>
      )}
      notFoundContent={
        isLoading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <StyledDivAlignCenter>
            {noInitialOptionsText && !searchQuery
              ? noInitialOptionsText
              : t('no_data', { ns: 'common' })}
          </StyledDivAlignCenter>
        )
      }
    >
      {renderOptions(availableOptions || [], initialValue, excludeElementIds)}
    </StyledSelectAntD>
  );
}

const StyledSelectAntD = styled(SelectAntD)`
  .ant-select-clear {
    background-color: ${({ theme }) => theme.colorWarning};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  justify-content: center;
  height: 130px;
`;

export default Autocomplete;
