import * as yup from 'yup';

// constants
import { WEBSITE_REG } from '../../constants/regularExpressions';
import { AdditionalFieldValidationSchema } from './structure';
import {
  CRM_CONTACT_NAME_MAX_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '../../constants/global';
import {
  EMAIL_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NOT_REQUIRED_BOOLEAN_VALIDATION_RULE,
  CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE,
} from '../common';

export const ApplicantInformationOrganizationShortValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: yup.boolean().nullable().defined(),

    legalName: CRM_NAME_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label:
          'onboarding:applicant_information.form_fields.organization.entity_name',
        number: NAME_MAX_LENGTH,
      },
    }).required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label:
          'onboarding:applicant_information.form_fields.organization.entity_name',
      },
    }),
  });

export const ApplicantInformationOrganizationValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: yup.boolean().nullable().defined(),

    legalName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.max(
      NAME_MAX_LENGTH,
      {
        key: 'form:validation_messages.max_characters_with_label',
        values: {
          label:
            'onboarding:applicant_information.form_fields.organization.entity_name',
          number: NAME_MAX_LENGTH,
        },
      },
    ).required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label:
          'onboarding:applicant_information.form_fields.organization.entity_name',
      },
    }),

    tradeName: STRING_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
      key: 'form:validation_messages.min_characters_with_label',
      values: {
        label:
          'onboarding:applicant_information.form_fields.organization.trade_name',
        number: NAME_MIN_LENGTH,
      },
    }).max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label:
          'onboarding:applicant_information.form_fields.organization.trade_name',
        number: NAME_MAX_LENGTH,
      },
    }),

    registrationNumber: CRM_NAME_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label:
          'onboarding:applicant_information.form_fields.organization.registration_number',
        number: NAME_MAX_LENGTH,
      },
    }).when('isSave', {
      is: false,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.organization.registration_number',
          },
        }),
    }),

    dateOfIncorporation: yup
      .mixed()
      .nullable()
      .when('isSave', {
        is: false,
        then: () =>
          yup
            .date()
            .typeError({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.organization.date_of_incorporation',
              },
            })
            .required({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.organization.date_of_incorporation',
              },
            }),
      }),

    jurisdiction: yup.mixed().when('isSave', {
      is: false,
      then: () =>
        STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.organization.country_of_incorporation',
          },
        }),
    }),

    industryCodes: yup.array().when('isSave', {
      is: false,
      then: () =>
        yup
          .array()
          .of(STRING_VALIDATION_RULE)
          .required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:applicant_information.form_fields.organization.industry_codes',
            },
          })
          .min(1, {
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:applicant_information.form_fields.organization.industry_codes',
            },
          }),
    }),

    natureOfBusiness: yup.mixed().when('isSave', {
      is: false,
      then: () =>
        STRING_VALIDATION_RULE.max(500, {
          key: 'form:validation_messages.max_characters_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.organization.nature_of_business',
            number: 500,
          },
        }).required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.organization.nature_of_business',
          },
        }),
    }),

    numberOfEmployees: yup
      .mixed()
      .nullable()
      .when('isSave', {
        is: false,
        then: (schema) =>
          schema.required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:applicant_information.form_fields.organization.number_of_employees',
            },
          }),
      }),

    numberOfOffices: yup
      .mixed()
      .nullable()
      .when('isSave', {
        is: false,
        then: (schema) =>
          schema.required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:applicant_information.form_fields.organization.number_of_offices',
            },
          }),
      }),

    socialMedia: yup.object().shape({
      facebookLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.facebook_link' },
        },
      }),

      linkedInLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.linkedin_link' },
        },
      }),

      twitterLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.twitter_link' },
        },
      }),

      websiteLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.website_link' },
        },
      }),
    }),
  });

export const ApplicantInformationIndividualShortValidationSchema = yup
  .object()
  .shape({
    firstName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.first_name',
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.first_name',
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.first_name',
              },
            }),
      },
    ),

    middleName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.middle_name',
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.middle_name',
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            }),
      },
    ),

    lastName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.last_name',
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.last_name',
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.last_name',
              },
            }),
      },
    ),

    emails: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.email_address.type',
          },
        }),
        value: EMAIL_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.email_address.address',
          },
        }),
      }),
    ),

    phoneNumbers: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.phone_number.type',
          },
        }),
        value: STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.phone_number.phone_number',
          },
        }),
      }),
    ),
  });

export const ApplicantInformationIndividualValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: yup.boolean().nullable().defined(),

    firstName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.first_name',
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.first_name',
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.first_name',
              },
            }),
      },
    ),

    middleName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.middle_name',
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.middle_name',
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            }),
      },
    ),

    lastName: CRM_NAME_NO_SPECIAL_CHARACTERS_VALIDATION_RULE.when(
      'isContactBaseInfoFormDisabled',
      {
        is: false,
        then: (schema) =>
          schema
            .min(NAME_MIN_LENGTH, {
              key: 'form:validation_messages.min_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.last_name',
                number: NAME_MIN_LENGTH,
              },
            })
            .max(CRM_CONTACT_NAME_MAX_LENGTH, {
              key: 'form:validation_messages.max_characters_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.last_name',
                number: CRM_CONTACT_NAME_MAX_LENGTH,
              },
            })
            .required({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.last_name',
              },
            }),
      },
    ),

    dateOfBirth: yup
      .date()
      .nullable()
      .when(['isSave', 'isContactBaseInfoFormDisabled'], {
        is: (isSave: boolean, isContactBaseInfoFormDisabled: boolean | null) =>
          !isContactBaseInfoFormDisabled ? !isSave : false,
        then: (schema) =>
          schema
            .typeError({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.date_of_birth',
              },
            })
            .required({
              key: 'form:validation_messages.required_with_label',
              values: {
                label:
                  'onboarding:applicant_information.form_fields.individual.date_of_birth',
              },
            }),
      }),

    countryOfBirth: STRING_VALIDATION_RULE.when(
      ['isSave', 'isContactBaseInfoFormDisabled'],
      {
        is: (
          isSave: boolean,
          isContactBaseInfoFormDisabled: boolean | undefined,
        ) => (!isContactBaseInfoFormDisabled ? !isSave : false),
        then: (schema) =>
          schema.required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:applicant_information.form_fields.individual.country_of_birth',
            },
          }),
      },
    ),

    gender: NULLABLE_STRING_VALIDATION_RULE.when(
      ['isSave', 'isContactBaseInfoFormDisabled'],
      {
        is: (
          isSave: boolean,
          isContactBaseInfoFormDisabled: boolean | undefined,
        ) => (!isContactBaseInfoFormDisabled ? !isSave : false),
        then: (schema) =>
          schema.required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:applicant_information.form_fields.individual.gender',
            },
          }),
      },
    ),

    emails: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.email_address.type',
          },
        }),
        value: EMAIL_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.email_address.address',
          },
        }),
      }),
    ),

    phoneNumbers: yup.array().of(
      yup.object().shape({
        type: NULLABLE_STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.phone_number.type',
          },
        }),
        value: STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:applicant_information.form_fields.individual.phone_number.phone_number',
          },
        }),
      }),
    ),

    socialMedia: yup.object().shape({
      facebookLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.facebook_link' },
        },
      }),

      linkedInLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.linkedin_link' },
        },
      }),

      twitterLink: STRING_VALIDATION_RULE.matches(WEBSITE_REG, {
        message: {
          key: 'form:validation_messages.invalid_value_with_label',
          values: { label: 'onboarding:applicant_information.twitter_link' },
        },
      }),
    }),
  });

// TODO: set type - yup.SchemaOf<SetupInitialRelationshipsFormValuesModel>
export const SetupInitialRelationshipsFormValidationSchema = yup
  .object()
  .shape({
    organizationTypeName: STRING_VALIDATION_RULE.required(),
    organizationTypeCategoryId: STRING_VALIDATION_RULE.required(),
    accountAdmin: NOT_REQUIRED_BOOLEAN_VALIDATION_RULE,
    accountPermissions: yup.mixed().notRequired().nullable(),
    isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),

    pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
      is: true,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),
    relationships: yup.array().of(
      yup.object().shape({
        id: STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'common:relationship' },
        }),
        additionalFields: yup.array().of(AdditionalFieldValidationSchema),
      }),
    ),
  });
