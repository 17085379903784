import APIConfig from '../../config/api';

import { APIService } from '../axiosInstance';

interface FetchInsightsModel {
  insights: {
    key: string;
    connectionString: string;
  };
}

interface FetchBadgesModel {
  clientRequests: number;
  pendingAccountManagementWorkflows: number;
  pendingApprovalTransactions: number;
}

const serviceAPI = {
  fetchFrontendConfig: () => {
    return APIService.get<FetchInsightsModel>(
      `${APIConfig.serviceApi}/frontend/config`,
    ).then(({ data }) => data);
  },

  fetchBadges: () => {
    return APIService.get<FetchBadgesModel>(
      `${APIConfig.serviceApi}/badges`,
    ).then(({ data }) => data);
  },
};

export default serviceAPI;
