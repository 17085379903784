import React, { useEffect, useMemo, useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { newTransferTemplateSchema } from '../../../../../validations/accounting/transfers';
import { INewTransferTemplateFormValues } from '../../../../../typings/accounting/transfer';

// components
import Button from '../../../../DesignSystem/Core/Button';
import InnerForm from './InnerForm';
import WarningIconWithText from '../../../../Additional/WarningIconWithText';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Col, Row } from 'antd';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<INewTransferTemplateFormValues> {
  title: string;
  isEditMode?: boolean;
  disabledTemplateTypeField?: boolean;
  onDuplicateClick?: () => void;
}

const TransferTemplateModal = ({
  isVisible,
  title,
  closeCallback,
  disabled,
  onSubmit,
  initialValues,
  isEditMode,
  disabledTemplateTypeField,
  onDuplicateClick,
  onSubmitError,
}: IProps) => {
  const formRef = useRef<any>();
  const { t } = useTranslation(['common', 'transfers']);
  const [isEditActive, setIsEditActive] = useState(false);

  useEffect(() => {
    if (!isVisible && isEditActive) {
      setIsEditActive(false);
    }
  }, [isVisible]);

  const handleBackToReadOnlyClick = () => {
    if (formRef.current.dirty) {
      formRef.current.resetForm(initialValues);
    }

    setIsEditActive(false);
  };

  const renderExtendedButton = () => {
    if (!isEditMode) {
      return;
    }

    return isEditActive ? (
      <StyledButton
        size="large"
        type="bordered"
        onClick={handleBackToReadOnlyClick}
      >
        {t('back_to_read_only_button')}
      </StyledButton>
    ) : (
      <Row gutter={[16, 16]}>
        {onDuplicateClick && (
          <Col>
            <StyledButton
              size="large"
              type="bordered"
              onClick={onDuplicateClick}
            >
              {t(
                'transfer_templates.transfer_template_modal.duplicate.duplicate_button',
                { ns: 'transfers' },
              )}
            </StyledButton>
          </Col>
        )}
        <Col>
          <Button
            size="large"
            disabled={disabled}
            onClick={() => setIsEditActive(true)}
          >
            {t('edit_button')}
          </Button>
        </Col>
      </Row>
    );
  };

  const modalTitle = useMemo(() => {
    if (disabled) {
      return (
        <TitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledWarningInfoNot2FA
            text={t(
              'transfer_templates.transfer_template_modal.warning_not_2fa',
              { ns: 'transfers' },
            )}
          />
        </TitleContainer>
      );
    }

    return title;
  }, [disabled, title]);

  return (
    <Form
      enableReinitialize
      innerRef={formRef}
      onSubmit={onSubmit}
      disabled={disabled}
      initialValues={isVisible ? initialValues : null}
      validationSchema={newTransferTemplateSchema}
      onSubmitError={onSubmitError}
      renderForm={
        <ModalDialog
          title={modalTitle}
          isVisible={isVisible}
          closeCallback={closeCallback}
          cancelButtonProps={isEditMode ? { hidden: !isEditActive } : undefined}
          extendedFooterButtonsPosition="before"
          extendedFooterButtons={renderExtendedButton()}
          submitButtonProps={{ hidden: isEditMode && !isEditActive }}
          closeModalAfterSubmit={!onSubmitError}
        >
          <InnerForm
            disabledTemplateTypeField={disabledTemplateTypeField}
            isEditMode={isEditMode}
            isViewOnly={isEditMode && !isEditActive}
          />
        </ModalDialog>
      }
    />
  );
};

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default TransferTemplateModal;
