import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../../../resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import { TransferTemplateTypes } from '../../../../../../../enums/accounting/transfers';
import {
  IInternationalTransferTemplate,
  ITransferTemplateShortModel,
  NewWireTransferModel,
  WireTransferTemplateData,
} from '../../../../../../../typings/accounting/transfer';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import IconSVG from '../../../../../../DesignSystem/Core/IconSVG';
import FormField from '@core_components/FormField';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import DivAlignCenter from '../../../../../../Additional/DivAlignCenter';
import TransferTemplate from './TransferTemplate';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import AddTransferTemplateModal from '../../../../../../../modules/Transfers/Templates/AddTransferTemplateModal';
import WireTransferTemplateAutocomplete, {
  WireTransferTemplateAutocompleteProps,
} from '../../../../../FormComponents/Autocompletes/Accounting/WireTransferTemplateAutocomplete';
import { ReactComponent as PlusIcon } from '../../../../../../../resources/icons/remix-icons/add-line.svg';

const TransferToSideForm = () => {
  const { t } = useTranslation('transfers');
  const { values, setValues } = useFormikContext<NewWireTransferModel>();

  const [updateTemplatesTrigger, updateTemplates] = useState({});
  const [isAddTemplateModalVisible, setIsAddTemplateModalVisible] =
    useState(false);

  const onAddTemplateClick = () => {
    setIsAddTemplateModalVisible(true);
  };

  const handleAddTemplateModalClose = (
    wasCreated?: boolean,
    newTemplate?: IInternationalTransferTemplate,
  ) => {
    wasCreated && updateTemplates({});
    if (newTemplate) {
      const formattedTemplate: WireTransferTemplateData = {
        templateId: newTemplate.id,
        templateName: newTemplate.templateName,
        accountName: newTemplate.beneficiaryAccountName,
        accountNumber: newTemplate.beneficiaryAccountNumber,
        city: newTemplate.beneficiaryCity,
        district: newTemplate.beneficiaryDistrict || '',
        address: newTemplate.beneficiaryAddress1,
        country: newTemplate.beneficiaryCountry,
        postCode: newTemplate.beneficiaryPostCode,
        useIntermediary: !!newTemplate.intermediaryBankCodeId,
        beneficiaryBank: {
          bankCodeType: newTemplate.beneficiaryBankCodeType as number,
          bankCodeTypeName: newTemplate.beneficiaryBankCodeTypeName || '',
          bankCode: newTemplate.beneficiaryBankCode,
          bankName: newTemplate.beneficiaryBankName,
          country: newTemplate.beneficiaryBankCountry,
          address: newTemplate.beneficiaryBankAddress1,

          city: newTemplate.beneficiaryBankCity,
          district: newTemplate.beneficiaryBankDistrict,
          postCode: newTemplate.beneficiaryBankPostCode,
        },
        intermediaryBank: {
          bankCodeType: newTemplate.intermediaryBankCodeType as number,
          bankCodeTypeName: newTemplate.intermediaryBankCodeTypeName || '',
          bankCode: newTemplate.intermediaryBankCode,
          bankName: newTemplate.intermediaryBankName,
          country: newTemplate.intermediaryBankCountry,
          address: newTemplate.intermediaryBankAddress1,

          city: newTemplate.intermediaryBankCity,
          district: newTemplate.intermediaryBankDistrict,
          postCode: newTemplate.intermediaryBankPostCode,
        },
      };

      setValues({
        ...values,
        recipient: {
          templateId: newTemplate.id,
          reference: newTemplate.reference || '',
          template: formattedTemplate,
        },
      });
    }

    setIsAddTemplateModalVisible(false);
  };

  const handleTemplateSelect = (
    newTemplateId: number,
    newTemplate?: ITransferTemplateShortModel,
  ) => {
    if (newTemplate) {
      const { additionalData, id, name } = newTemplate;
      const {
        accountName,
        accountNumber,
        beneficiaryCountry,
        beneficiaryCity,
        beneficiaryDistrict,
        beneficiaryAddress1,
        beneficiaryPostCode,
        beneficiaryBankData,
        intermediaryBankData,
        recipientReference,
      } = additionalData;
      // TODO: bank code type should not be a 0, need to investigate
      const formattedTemplate: WireTransferTemplateData = {
        accountName,
        templateId: id,
        templateName: name,
        accountNumber,
        address: beneficiaryAddress1,
        country: beneficiaryCountry,
        city: beneficiaryCity,
        district: beneficiaryDistrict,
        postCode: beneficiaryPostCode,
        useIntermediary: !!intermediaryBankData,
        beneficiaryBank: {
          bankCodeType: 0,
          bankCodeTypeName:
            beneficiaryBankData?.generalBankData?.bankCodeTypeName || '',
          bankCode: beneficiaryBankData?.generalBankData?.bankCode || '',
          bankName: beneficiaryBankData?.generalBankData?.bankName || '',
          country: beneficiaryBankData?.bankCountry || '',
          address: beneficiaryBankData?.bankAddress1 || '',
          city: beneficiaryBankData?.bankCity || '',
          district: beneficiaryBankData?.bankDistrict || '',
          postCode: beneficiaryBankData?.bankPostCode || '',
        },
        intermediaryBank: {
          bankCodeType: 0,
          bankCodeTypeName:
            intermediaryBankData?.generalBankData?.bankCodeTypeName || '',
          bankCode: intermediaryBankData?.generalBankData?.bankCode || '',
          bankName: intermediaryBankData?.generalBankData?.bankName || '',
          country: intermediaryBankData?.bankCountry || '',
          address: intermediaryBankData?.bankAddress1 || '',
          city: intermediaryBankData?.bankCity || '',
          district: intermediaryBankData?.bankDistrict || '',
          postCode: intermediaryBankData?.bankPostCode || '',
        },
      };

      setValues({
        ...values,
        recipient: {
          templateId: newTemplateId,
          reference: recipientReference || '',
          template: formattedTemplate,
        },
      });
    }
  };

  const handleClearTemplate = () => {
    setValues({
      ...values,
      recipient: {
        templateId: null,
        reference: '',
        template: null,
      },
    });
  };

  return (
    <>
      <FormField<WireTransferTemplateAutocompleteProps>
        disabled={!values.sender.accountNumber || !values.sender.processingType}
        name="recipient.templateId"
        label={t('international_transfer.form_fields.transfer_template')}
        component={WireTransferTemplateAutocomplete}
        additionalProps={{
          initialValue: values.recipient.templateIdInitialValue,
          refreshAutocompleteTrigger: updateTemplatesTrigger,
          onClear: handleClearTemplate,
          onSelect: (newValue, option) =>
            option.model && handleTemplateSelect(newValue as any, option.model),
          appendToMenu: (
            <OptionWrapper
              className="ant-select-item ant-select-item-option"
              onClick={onAddTemplateClick}
            >
              <Link weight="semi-bold">
                <DivAlignCenter>
                  <StyledIconSVG
                    component={PlusIcon}
                    color={colorsTheme.colorWhite}
                  />
                  {t('international_transfer.add_transfer_template_button')}
                </DivAlignCenter>
              </Link>
            </OptionWrapper>
          ),
        }}
      />

      {!!values.recipient.template && (
        <>
          <TransferTemplate template={values.recipient.template} />
          <FormField
            label={
              <DivAlignCenter>
                <StyledText>
                  {t('international_transfer.form_fields.reference')}
                </StyledText>
                <InfoTooltip
                  tooltipContent={t(
                    'international_transfer.tooltips.reference',
                  )}
                />
              </DivAlignCenter>
            }
            name="recipient.reference"
            component={StyledTextArea}
          />
        </>
      )}

      <AddTransferTemplateModal
        returnFullModelAfterSubmit
        templateType={TransferTemplateTypes.Wire}
        isVisible={isAddTemplateModalVisible}
        closeCallback={(wasAdded, newTemplate) =>
          handleAddTemplateModalClose(
            wasAdded,
            newTemplate as IInternationalTransferTemplate,
          )
        }
      />
    </>
  );
};

const OptionWrapper = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colorLightD1}`};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledTextArea = styled(FormInputTextArea)`
  width: 100%;
  height: 100px;
`;

const StyledText = styled(Text)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default TransferToSideForm;
