export const TWO_COL_GRID_SIZES = { xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };
export const TWO_COL_XL_GRID_SIZES = { xl: 12, lg: 24, md: 24, sm: 24, xs: 24 };
export const TREE_COL_GRID_SIZES = { xl: 8, lg: 8, md: 24, sm: 24, xs: 24 };
export const FILTER_BAR_FIELDS_GRID_SIZES = {
  xl: 6,
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24,
};
