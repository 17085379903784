import React, { useMemo } from 'react';

// helpers
import { RoutePaths } from 'routes/routes';
import { BadgeModel } from 'redux/reducers/app';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';

// components
import PendingBadge from '../PendingBadge';

interface IProps {
  path: RoutePaths;
  badgePosition?: 'center' | 'top';
}

const RenderBadgeByRoutePath = ({ path, badgePosition }: IProps) => {
  const badges = useSelector<StateModel, BadgeModel>(
    (state) => state.app.badges,
  );
  const newMessagesCount = useSelector<StateModel, number>(
    (state) => state.notifications.newMessagesCount,
  );

  const badgeNode = useMemo(() => {
    let result = null;

    switch (path) {
      case RoutePaths.Accounts:
      case RoutePaths.Accounts_PendingTransactions:
        if (badges && badges.pendingTransactionsCount > 0) {
          result = (
            <PendingBadge
              position={badgePosition}
              pendingCount={badges.pendingTransactionsCount}
            />
          );
        }
        break;

      case RoutePaths.AccountManagement:
      case RoutePaths.AccountManagement_AccountManagementRules:
        if (badges && badges.pendingAccountManagementWorkflowsCount > 0) {
          result = (
            <PendingBadge
              position={badgePosition}
              pendingCount={badges.pendingAccountManagementWorkflowsCount}
            />
          );
        }
        break;

      case RoutePaths.Connections:
      case RoutePaths.Connections_Requests:
        if (badges && badges.pendingClientRequestsCount > 0) {
          result = (
            <PendingBadge
              position={badgePosition}
              pendingCount={badges.pendingClientRequestsCount}
            />
          );
        }
        break;

      case RoutePaths.UserProfile_Messages:
        if (newMessagesCount) {
          result = (
            <PendingBadge
              position={badgePosition}
              pendingCount={newMessagesCount}
            />
          );
        }
        break;
    }

    return result;
  }, [path, badges, newMessagesCount, badgePosition]);

  return badgeNode;
};

export default RenderBadgeByRoutePath;
