import React, { memo, useCallback } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import AsyncButton from '@common_components/Buttons/AsyncButton';
import FormDatePicker from '@common_components/Form/FormDatePicker';

const UserDataStep = memo(() => {
  const form = useFormikContext<FormValuesModel>();
  const { t } = useTranslation('auth');

  const onNextClick = useCallback(async () => {
    const errors = await form.validateForm();
    if (!Object.keys(errors).length) {
      form.setFieldValue('step', 'email_confirmation');
    }
  }, [form]);

  return (
    <>
      <FormField
        name="user.email"
        label={t('reset_username.form_fields.email')}
        component={FormInput}
      />
      <FormField
        name="user.name"
        label={t('reset_username.form_fields.name')}
        component={FormInput}
      />
      <FormField
        name="user.clientGroup"
        label={t('reset_username.form_fields.client_group')}
        component={FormInput}
      />
      <FormField
        name="user.dateOfBirth"
        label={t('reset_username.form_fields.date_of_birth')}
        component={StyledFormDatePicker}
        additionalProps={{
          disabledDate: (d: any) => !d || d.isAfter(new Date()),
        }}
      />
      <AsyncButton size="large" fullWidth onClick={onNextClick}>
        {t('reset_username.submit_user_data_step_btn')}
      </AsyncButton>
    </>
  );
});

const StyledFormDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

export default UserDataStep;
