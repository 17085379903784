import React, { useContext, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../../../../../../typings/application/entity';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { onboardingAPI } from '../../../../../../../../../../api/onboarding/onboardingAPI';
import { VerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation';
import { onboardingAPIAdapter } from '../../../../../../../../../../apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from '../../../../../../../../../../typings/onboarding/onboarding';

// component
import { message } from 'antd';
import IdentificationForOrganizationForm, {
  AddressItemModel,
  FormValuesModel,
} from '../../../../../../IdentificationForOrganizationForm';

interface IProps {
  applicationId: string;
  onboardingStatus: OnboardingStatusModel;
  isViewOnly?: boolean;
  onAction?: (isCompleted: boolean, actionType: ActionKeys | null) => void;
}

const Identification = ({
  onboardingStatus,
  applicationId,
  isViewOnly,
  onAction,
}: IProps) => {
  const { t } = useTranslation('common');
  const context = useContext(VerificationContext);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    let addresses: AddressItemModel[] = [
      {
        isPrimary: true,
        type: null,
        country: null,
        city: '',
        street: '',
        state: '',
        postalCode: '',
      },
    ];

    if (onboardingStatus) {
      const organizationData = onboardingStatus.item as IEntity;

      if (organizationData.addresses?.length) {
        addresses = organizationData.addresses.map((address) => ({
          _id: address._id,
          isPrimary: !!address.isPrimary,
          type: address.type || null,
          country: address.country || null,
          city: address.city,
          street: address.street,
          state: address.state,
          postalCode: address.postalCode,
        }));
      }

      return {
        submitActionType: null,
        isSave: false,
        isRegulated:
          typeof organizationData.isRegulated === 'boolean'
            ? organizationData.isRegulated
            : null,
        regulationCountry: organizationData.regulationCountry,
        canIssueBearerShares:
          typeof organizationData.canIssueBearerShares === 'boolean'
            ? organizationData.canIssueBearerShares
            : null,
        addresses: addresses,
        disabledRegulated: true,
      };
    } else {
      return {
        submitActionType: null,
        isSave: false,
        isRegulated: null,
        regulationCountry: [],
        canIssueBearerShares: null,
        addresses,
        disabledRegulated: true,
      };
    }
  }, [onboardingStatus]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody = onboardingAPIAdapter.submitIdentificationStep(values);
    await onboardingAPI.submitIdentificationStep(
      formattedBody,
      applicationId,
      onboardingStatus.item._id,
    );
    message.success(t('success_save'));

    onAction && onAction(true, values.submitActionType);
  };

  return (
    <IdentificationForOrganizationForm
      formRef={context?.refState}
      disabled={isViewOnly}
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
      showOnlySaveButton
    />
  );
};

export default Identification;
