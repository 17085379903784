import React, { useCallback, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { colorsTheme } from '../../../resources/theme/styled/colors';

// components
import Button from '../../DesignSystem/Core/Button';
import IconSVG from '../../DesignSystem/Core/IconSVG';
import InfoTooltip from '../../Tooltips/InfoTooltip';
import DivAlignCenter from '../../Additional/DivAlignCenter';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as EllipsisIcon } from '../../../resources/icons/remix-icons/more-fill.svg';

interface IProps {
  menu: ITableMenuItem[];
  onSelect: (key: string) => void;
  disabled?: boolean;
}

export interface ITableMenuItem {
  key: string;
  name: string;
  hidden?: boolean;
  style?: any;
  danger?: boolean;
}

const TableActions = ({ menu, onSelect, disabled }: IProps) => {
  const { t } = useTranslation('common');

  const menuItems = useMemo(() => {
    let result: ITableMenuItem[] = [];

    if (menu.length) {
      result = menu.filter((e) => !e.hidden);
    }

    return result;
  }, [menu]);

  const renderContent = useCallback(() => {
    let result = null;

    if (menuItems.length === 1) {
      const { name, key, danger } = menuItems[0];

      result = (
        <SingleOptionWrapper>
          <DivAlignCenter>
            <Button
              disabled={disabled}
              onClick={() => onSelect(key)}
              type="bordered"
              danger={danger}
            >
              {name}
            </Button>
            {disabled ? (
              <StyledTooltip
                tooltipContent={t('disabled_by_2fa_button_information')}
              />
            ) : null}
          </DivAlignCenter>
        </SingleOptionWrapper>
      );
    } else if (menuItems.length) {
      const dropdownOverlay = (
        <MenuWrapper onClick={({ key }) => onSelect(key as string)}>
          {menuItems.map(({ key, name, ...rest }) => (
            <Menu.Item key={key} {...rest}>
              {name}
            </Menu.Item>
          ))}
        </MenuWrapper>
      );

      result = (
        <Dropdown
          overlay={dropdownOverlay}
          placement="bottomRight"
          arrow
          trigger={['click']}
        >
          <IconSVG
            size="middle"
            color={colorsTheme.colorWhite}
            component={EllipsisIcon}
          />
        </Dropdown>
      );
    }

    return result;
  }, [menuItems, disabled]);

  return <>{renderContent()}</>;
};

const MenuWrapper = styled(Menu)`
  min-width: 150px;
`;

const SingleOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default TableActions;
