import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '..';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import FormField from '@core_components/FormField';
import TrueFalseSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseSelect';
import FormErrorTooltip from 'components/Forms/FormErrorTooltip';
import { Col, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';

const InnerForm = memo(() => {
  const { t } = useTranslation('connections');
  const { values } = useFormikContext<FormValuesModel>();

  const renderQuestions = () => {
    return values.questions.map((question, index: number) => (
      <FormField
        key={question.questionKey}
        label={t(
          `requests.kyc_verification_modal.form_fields.${question.questionKey}`,
        )}
        name={`questions.${index}.answer`}
        component={TrueFalseSelect}
      />
    ));
  };

  return (
    <>
      <StyledText>
        {t('requests.kyc_verification_modal.description')}
      </StyledText>
      <FieldArray name="questions" render={renderQuestions} />
      <Row wrap={false} justify="start">
        <Col flex="30px">
          <FormField
            name="agreement"
            component={Checkbox}
            shouldShowErrorMessage={false}
          />
        </Col>
        <Col flex="auto">
          <Text>
            {t('requests.kyc_verification_modal.form_fields.agreement')}
          </Text>
        </Col>
      </Row>
      <FormErrorTooltip errorKey="agreement" />
    </>
  );
});

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default InnerForm;
