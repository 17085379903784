import React, { useMemo } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { useDispatch } from 'react-redux';
import { message, Modal } from 'antd';
import { AccountsHelpers } from '../../../../../helpers/accounts';
import { approvalGroupsAPI } from '../../../../../api/approvalManagement/approvalGroupsAPI';
import { AutocompleteOption } from '@core_components/Autocomplete';
import {
  ConditionModel,
  LimitModel,
} from 'components/Forms/TemplateForms/Approval/TransactionRulesMatrixForm';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import {
  transactionAPIHelpers,
  transactionRulesAPI,
} from '../../../../../api/accounting/transactionRulesAPI';
import {
  IApprovalRuleAccount,
  ITransactionApprovalRuleEdit,
} from '../../../../../typings/approvalManagement/accountManagement';

// components
import TransactionRulesModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/AccountManagement/TransactionRulesModal';

interface IProps extends RequiredPropsForModalDialogModel {
  transactionRuleId?: string | null;
}

const AddTransactionRulesModal = ({
  isVisible,
  transactionRuleId,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const dispatch = useDispatch();

  const { response: fetchGroupsResponse } = useFetch(
    () =>
      isVisible
        ? approvalGroupsAPI.fetchApprovalGroups({
            page: 1,
            limit: 100,
            isActiveOnly: true,
          })
        : null,
    [isVisible],
  );

  const { response: fetchApprovalRuleResponse } = useFetch(
    () =>
      transactionRuleId
        ? transactionRulesAPI.fetchApprovalRuleById(transactionRuleId)
        : null,
    [transactionRuleId],
  );

  const initialValues = useMemo((): FormValuesModel | null => {
    if (
      !isVisible || transactionRuleId
        ? !fetchApprovalRuleResponse || !fetchGroupsResponse
        : !fetchGroupsResponse
    ) {
      return null;
    }

    // generate valid values as initial values
    function formatLimitsArray(
      template: ITransactionApprovalRuleEdit,
    ): LimitModel[] {
      const { approvalRuleSets } = template;

      return approvalRuleSets[0].approvalThreasholds.map(
        ({ threasholdMax, threasholdMin }, i) => {
          const toInfinite = threasholdMax === -1;

          const isUsed = Boolean(
            approvalRuleSets.find((e) => e.approvalThreasholds[i].ticked),
          );

          return {
            from: Number(threasholdMin),
            to: toInfinite ? 0 : Number(threasholdMax),
            isUsed,
            toInfinite,
          };
        },
      );
    }

    function formatConditionsArray(
      template: ITransactionApprovalRuleEdit,
    ): ConditionModel[] {
      const { approvalRuleSets } = template;

      return approvalRuleSets.map((ruleSet) => ({
        conditions: ruleSet.approvalRuleItems.map(
          ({ approvalGroup, minimunApprovalThreashold }) => ({
            approvalGroup: approvalGroup.id,
            requiredApproversCount: minimunApprovalThreashold,
          }),
        ),
        limitStatuses: ruleSet.approvalThreasholds.map(({ ticked }) => ({
          isChecked: !!ticked,
        })),
      }));
    }

    const bankAccounts = fetchApprovalRuleResponse?.bankAccounts?.length
      ? fetchApprovalRuleResponse.bankAccounts
      : [];
    let initialBankAccounts: AutocompleteOption<IApprovalRuleAccount>[] = [];

    if (
      fetchApprovalRuleResponse &&
      fetchApprovalRuleResponse.bankAccounts.length
    ) {
      initialBankAccounts = fetchApprovalRuleResponse.bankAccounts.map(
        ({ bankAccountName, bankAccountNumber, bankAccountCurrency }) => ({
          id: bankAccountNumber,
          label: AccountsHelpers.generateAccountFullName(
            bankAccountName,
            bankAccountNumber,
            bankAccountCurrency,
          ),
          model: {
            bankAccountNumber,
            bankAccountName,
            bankAccountCurrency,
          },
        }),
      );
    }

    const limits = fetchApprovalRuleResponse
      ? formatLimitsArray(fetchApprovalRuleResponse)
      : [{ from: 0, to: undefined, toInfinite: true, isUsed: false }];

    const conditions = fetchApprovalRuleResponse
      ? formatConditionsArray(fetchApprovalRuleResponse)
      : [
          {
            conditions: [{ approvalGroup: '', requiredApproversCount: 0 }],
            limitStatuses: [{ isChecked: false }],
          },
        ];

    return {
      bankAccounts,
      limits,
      conditions,
      initialBankAccounts,
      approvalGroups: fetchGroupsResponse?.data || [],
    };
  }, [
    isVisible,
    transactionRuleId,
    fetchApprovalRuleResponse,
    fetchGroupsResponse,
  ]);

  const handleSubmit = async (values: FormValuesModel) => {
    const newRule = transactionAPIHelpers.generateNewTransactionRule(values);
    transactionRuleId
      ? await transactionRulesAPI.updateTransactionRule({
          id: transactionRuleId,
          ...newRule,
        })
      : await transactionRulesAPI.addTransactionRule(newRule);
    dispatch(getBadges());
    message.success(t('success_submit_for_account_management_review_message'));
  };

  const onDeleteClick = () => {
    if (transactionRuleId) {
      Modal.confirm({
        title: t('approval_rules.bank_accounts.delete_rule.title'),
        content: t(
          'approval_rules.bank_accounts.delete_rule.confirm_delete_message',
        ),
        okText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
        onOk: async () => {
          await transactionRulesAPI.deleteTransactionRuleById(
            transactionRuleId,
          );
          message.success(
            t('success_submit_for_account_management_review_message'),
          );
          closeCallback(true);
        },
      });
    }
  };

  return (
    <TransactionRulesModal
      isVisible={isVisible}
      onSubmit={handleSubmit}
      onDelete={transactionRuleId ? onDeleteClick : undefined}
      initialValues={initialValues}
      closeCallback={closeCallback}
      title={
        transactionRuleId
          ? t('approval_rules.bank_accounts.approval_rule_modal.edit.title')
          : t('approval_rules.bank_accounts.approval_rule_modal.new.title')
      }
    />
  );
};

export default AddTransactionRulesModal;
