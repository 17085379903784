import React from 'react';

// components
import GraphChart, {
  GraphData,
} from '../../../../../../../../../../components/Charts/GraphChart';

interface IProps {
  graph: GraphData;
}

const InnerTree = ({ graph }: IProps) => {
  return <GraphChart data={graph} />;
};

export default InnerTree;
