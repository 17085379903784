import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../resources/theme/styled/colors';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// constants
import {
  UploadDocumentMethods,
  VerificationDocumentPhotoTypes,
  VerificationDocumentTypes,
} from '../../../../enums/profile/accountRecovery';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../../components/DesignSystem/Core/IconSVG';
import ChooseDocumentTypeForm from './ChooseDocumentTypeForm';
import UploadVerificationDocumentForm from './UploadVerificationDocumentForm';
import { ReactComponent as FileIcon } from '../../../../resources/icons/remix-icons/file-line.svg';

export interface DocumentRequirementModel {
  steps: {
    type: VerificationDocumentPhotoTypes;
    icon: React.ReactNode;
    text: string;
  }[];
}

export const VerificationDocumentRequirements: Record<
  VerificationDocumentTypes,
  DocumentRequirementModel
> = {
  [VerificationDocumentTypes.Passport]: {
    steps: [
      {
        type: VerificationDocumentPhotoTypes.Front,
        icon: <IconSVG component={FileIcon} color={colorsTheme.colorWhite} />,
        text: 'account_recovery.id_verification_step.documents.passport.first_page_description',
      },
      {
        type: VerificationDocumentPhotoTypes.Selfie,
        icon: <IconSVG component={FileIcon} color={colorsTheme.colorWhite} />,
        text: 'account_recovery.id_verification_step.documents.selfie.description',
      },
    ],
  },
};

const IDVerificationStep = () => {
  const { t } = useTranslation('auth');

  const { values } = useFormikContext<FormValuesModel>();

  const renderContent = (
    selectedType: VerificationDocumentTypes | null,
    uploadDocumentMethod: UploadDocumentMethods | null,
  ) => {
    let form = null;
    let title = '';

    if (!selectedType && uploadDocumentMethod) {
      title = t('account_recovery.id_verification_step.select_id_type');
      form = <ChooseDocumentTypeForm />;
    } else if (selectedType && uploadDocumentMethod) {
      title = t(
        'account_recovery.id_verification_step.complete_id_verification_steps',
      );
      form = <UploadVerificationDocumentForm />;
    }

    return (
      <>
        <StyledText variant="h5" weight="semi-bold" gutterBottom>
          {title}
        </StyledText>

        {form}
      </>
    );
  };

  return (
    <>{renderContent(values.idVerificationType, values.uploadDocumentMethod)}</>
  );
};

const StyledText = styled(Text)`
  text-align: center;
`;

export default IDVerificationStep;
