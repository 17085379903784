import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { ICurrency } from '../../typings/accounting/currency';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchCurrenciesParams extends FetchParamsModel {
  isExternal: boolean | 'all';
  isAccount: boolean | 'all';
  isActive: boolean | 'all';
}

const currenciesAPI = {
  fetchCurrencies: (params: FetchCurrenciesParams) => {
    return APIService.get(`${APIConfig.accountingApi}/currencies`, {
      params,
    }).then<FetchResponseModel<ICurrency>>(({ data }) => data);
  },
};

export { currenciesAPI };
