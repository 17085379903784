import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from '../../../../../../../enums/onboarding/crm';

// components
import FormField from '@core_components/FormField';
import SectionIntro from '../../../../../../DesignSystem/Common/Texts/SectionIntro';
import OnlineAccessForm from '../../../../../../Forms/TemplateForms/CRM/OnlineAccessForm';
import ClientGroupMembersAutocomplete, {
  ClientGroupMembersAutocompleteProps,
} from '../../../../../../Forms/FormComponents/Autocompletes/CRM/ClientGroupMembersAutocomplete';

const FromExistingContact = () => {
  const { t } = useTranslation('account_management');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <FormField<ClientGroupMembersAutocompleteProps>
        name="contact.id"
        label={t('user_management.add_user.form_fields.existing_contact')}
        component={ClientGroupMembersAutocomplete}
        additionalProps={{
          clientGroupId: values.clientGroupId,
          entryType: OnboardingEntryTypes.Contact,
          hasOnlineAccess: false,
          relationshipScopeId: values.approvedRelationshipScopeId,
          noInitialOptionsText: t(
            'user_management.add_user.no_existing_contacts',
          ),
        }}
      />

      <SectionIntro
        title={t('user_management.add_user.user_permissions_section_title')}
      />
      <OnlineAccessForm namePrefix="contact" />
    </>
  );
};

export default FromExistingContact;
