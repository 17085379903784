import React from 'react';

// ** helpers utils **
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IApprovalMatrixRecord } from '../../../typings/accounting/transaction';

// ** components **
import { Table } from 'antd';
import ContentCell from './ContentCell';

interface IProps {
  transactionApprovals: IApprovalMatrixRecord[][] | null;
}

const ApprovalMatrix = ({ transactionApprovals }: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = [
    {
      width: '300px',
      key: 'group-info',
      dataIndex: 'group-info',
    },
    {
      width: '250px',
      title: t('approval_matrix.matrix.status'),
      key: 'status',
      dataIndex: 'status-info',
    },
    {
      width: '250px',
      title: t('approval_matrix.matrix.note'),
      key: 'note',
      dataIndex: 'note-info',
    },
  ];

  const formatTableColumns = (): any[] =>
    tableColumns.map((col) => ({
      ...col,
      onCell: (record: any, rowIndex: number) => {
        if (!transactionApprovals) return;

        return {
          style: { minWidth: col.width },
          record,
          rowIndex,
          isOrText: rowIndex < transactionApprovals.length - 1,
          dataIndex: col.dataIndex,
        };
      },
    }));

  return (
    <StyledTable
      scroll={{ x: 'max-content' }}
      bordered={true}
      pagination={false}
      columns={formatTableColumns()}
      dataSource={transactionApprovals || []}
      className="disable-table-row-hover"
      components={{ body: { cell: ContentCell } }}
    />
  );
};

const StyledTable = styled(Table)`
  .ant-table {
    margin: 0 !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colorDarkL4}`};
  }
`;

export default ApprovalMatrix;
