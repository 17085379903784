import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import relationshipsAPI from '../../../../../../../api/relationships/relationshipsAPI';
import { message } from 'antd';
import { IEntity } from '../../../../../../../typings/application/entity';
import { StateModel } from '../../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { IApplication } from 'typings/application/applications';
import { GraphHelpers } from '../../../../../../../helpers/graph';
import { ContactModel } from '../../../../../../../typings/application/contact';
import { EntityHelpers } from '../../../../../../../helpers/crm/entity';
import { RelationshipHelpers } from '../../../../../../../helpers/crm/relationship';
import {
  GraphData,
  GraphNode,
} from '../../../../../../../components/Charts/GraphChart';
import { OnboardingStatusModel } from '../../../../../../../typings/onboarding/onboarding';
import { AdditionalFieldHelpers } from 'helpers/additionalField';
import { ExistingOwnershipModel } from '../../../../../../../components/Forms/TemplateForms/ClientProfile/OwnershipRelationshipForm';
import { relationshipsAPIAdapter } from '../../../../../../../apiAdapters/relationships/relationshipsAPIAdapter';
import { IRelationshipTemplateModel } from '../../../../../../../typings/application/relationship-template';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from '../../../../../../../enums/onboarding/crm';

// components
import DivAlignCenter from '../../../../../../../components/Additional/DivAlignCenter';
import WarningIconWithText from '../../../../../../../components/Additional/WarningIconWithText';
import {
  default as TemplateOwnershipStructureModal,
  FormValuesModel,
  OwnershipFormItem,
} from '../../../../../../../components/ModalDialogs/TemplateModalDialogs/Onboarding/OwnershipStructureModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  node: GraphNode | null;
  graphData: GraphData;
  onboardingData: OnboardingStatusModel;
  isViewOnly?: boolean;
}

const OwnershipStructureModal = ({
  node,
  graphData,
  isVisible,
  onboardingData,
  isViewOnly,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('account_management');

  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );
  const activeApplication = useSelector<StateModel, IApplication | null>(
    (state) => state.applications.activeApplication,
  );

  const modalTitle = useMemo(() => {
    let result = '';

    if (isVisible && node) {
      const ownershipRelationships =
        RelationshipHelpers.findOwnershipKeyRelationships(
          node.model.keyRelationshipTemplate?.keyRelations || [],
        );

      // TODO: need to investigate if it's possible to have multiple ownerships
      if (ownershipRelationships.length) {
        result = t('client_profile.ownership_modal.title', {
          name: ownershipRelationships[0].relationshipTemplate.parentName,
        });

        if (!isTwoFactorVerificationEnabled) {
          return (
            <DivAlignCenter>
              <StyledTitle>{result}</StyledTitle>
              <StyledWarningInfoNot2FA
                text={t('client_profile.ownership_modal.warning_not_2fa')}
              />
            </DivAlignCenter>
          );
        }
      }
    }

    return result;
  }, [t, node, isVisible, isTwoFactorVerificationEnabled]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    let result: FormValuesModel | null = null;

    if (isVisible && node) {
      const ownershipKeyRelationships =
        node.model.keyRelationshipTemplate &&
        RelationshipHelpers.findOwnershipKeyRelationships(
          node.model.keyRelationshipTemplate.keyRelations || [],
        );

      // TODO: need to investigate if it's possible to have multiple ownerships
      if (ownershipKeyRelationships?.length) {
        const ownershipKeyRelationship = ownershipKeyRelationships[0];

        const ownershipFieldKey = ownershipKeyRelationship.options
          ?.additionalFields
          ? Object.keys(ownershipKeyRelationship.options?.additionalFields)[0]
          : '';

        const ownershipTemplate: IRelationshipTemplateModel = {
          ...ownershipKeyRelationship.relationshipTemplate,
          additionalFields:
            ownershipKeyRelationship.relationshipTemplate.additionalFields
              .filter((e) => e.options.onboardingRequired)
              .map((e) => ({
                ...e,
                options: {
                  ...e.options,
                  selectOptions: e.options.selectOptions.length
                    ? e.options.selectOptions.map((e) =>
                        JSON.parse(e.valueJSON),
                      )
                    : [],
                },
              })),
        };

        const relatedEdges = graphData.edges.filter((e) => {
          return (
            e.target === node.id &&
            e.relationship.relationshipTemplateId === ownershipTemplate._id
          );
        });

        const ownerships: OwnershipFormItem[] = relatedEdges.map((edge) => {
          const additionalFields: Record<
            string,
            {
              value: string;
              isOwnershipField: boolean;
              type: AdditionalFieldTypes;
            }
          > = ownershipTemplate.additionalFields.reduce<
            Record<
              string,
              {
                value: string;
                isOwnershipField: boolean;
                type: AdditionalFieldTypes;
              }
            >
          >((acc, e) => {
            const filledAdditionalField =
              edge.relationship.additionalFields.find(
                (i) => i.name === e.name && i.type === e.type,
              );

            acc[e.name] = {
              value: AdditionalFieldHelpers.formatFromJSON(
                e.type,
                filledAdditionalField?.valueJSON || '',
              ),
              type: e.type,
              isOwnershipField: ownershipFieldKey === e.name,
            };

            return acc;
          }, {});

          let contact = null;
          let organization = null;

          const countOfAvailableEdges = GraphHelpers.getCountOfAvailableEdges(
            graphData.edges,
            edge.source,
          );

          const edgeNode = graphData.nodes.find((e) => e.id === edge.source);

          if (edgeNode?.entryType === OnboardingEntryTypes.Contact) {
            const contactData = edgeNode.model as ContactModel;
            contact = {
              id: contactData._id,
              firstName: contactData.firstName,
              lastName: contactData.lastName,
              middleName: contactData.middleName,
              isPEP: contactData.isPEP,
              pepInformation: contactData.pepInformation,
              phone: contactData.phoneNumbers.length
                ? contactData.phoneNumbers.find((e) => e.isPrimary)?.number ||
                  ''
                : '',
              email: contactData.emails.length
                ? contactData.emails.find((e) => e.isPrimary)?.address || ''
                : '',
              isOnlineUser: !!contactData.onlineAccess,
              isPermissionsSelectAvailable: true,
              adminPermissionType: contactData.onlineAccess
                ? contactData.onlineAccess.adminPermissionType
                : null,
              accountPermissions: contactData.onlineAccess
                ? contactData.onlineAccess.permissionType
                : null,
              clientUserId: contactData?.onlineAccess?.clientGroupUserId,
              isOnlineContactWithLastRelationship:
                countOfAvailableEdges === 1 && !!contactData?.onlineAccess,
            };
          } else if (
            edgeNode?.entryType === OnboardingEntryTypes.Organization
          ) {
            const organizationData = edgeNode.model as IEntity;
            const name = EntityHelpers.getEntityNameByNameType(
              organizationData.names,
            );
            organization = {
              id: organizationData._id,
              name,
              isRegulated:
                typeof organizationData.isRegulated === 'boolean'
                  ? organizationData.isRegulated
                  : null,
              regulatedCountries: organizationData.regulationCountry,
            };
          }

          return {
            id: edgeNode?.id as string,
            type: edgeNode?.entryType as OnboardingEntryTypes,
            relationshipId: edge.relationship._id,
            contact,
            organization,
            additionalFields,
            keyRelationshipTemplate: {
              id: edgeNode?.model.keyRelationshipTemplate?._id as string,
              name: edgeNode?.model.keyRelationshipTemplate?.name as string,
            },
          };
        });

        const allowedEntriesForOwnerships: ExistingOwnershipModel[] =
          graphData.nodes.reduce<ExistingOwnershipModel[]>((acc, next) => {
            // if item is not ownership already
            const notSelectedNode = node.id !== next.id;

            if (notSelectedNode) {
              const hasOwnershipRelationship = graphData.edges.some(
                (e) =>
                  e.target === next.id &&
                  e.relationship._id ===
                    ownershipKeyRelationship.relationshipTemplateId,
              );

              if (!hasOwnershipRelationship) {
                const contact = next.model as ContactModel;
                const organization = next.model as IEntity;

                acc.push({
                  id: next.id,
                  type: next.entryType,
                  keyRelationshipTemplate: {
                    id: next.model.keyRelationshipTemplate?._id as string,
                    name: next.model.keyRelationshipTemplate?.name as string,
                  },
                  contact:
                    next.entryType === OnboardingEntryTypes.Contact
                      ? {
                          firstName: contact.firstName,
                          middleName: contact.middleName,
                          lastName: contact.lastName,
                        }
                      : null,
                  organization:
                    next.entryType === OnboardingEntryTypes.Organization
                      ? {
                          name: EntityHelpers.getEntityNameByNameType(
                            organization.names,
                          ),
                        }
                      : null,
                });
              }
            }
            return acc;
          }, []);

        result = {
          ownershipFieldKey,
          ownershipTemplate,
          ownerships,
          relationshipScopeId: onboardingData.relationshipScopeId,
          allowedEntriesForOwnerships,
          clientGroupId: onboardingData.clientGroupId,
          clientGroupEntryType:
            activeApplication?.crmItemType as OnboardingEntryTypes,
          relationshipTo: {
            id: node.id,
            type: node.entryType,
          },
        };
      }
    }

    return result;
  }, [isVisible, node, onboardingData, graphData]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedRequestBody =
      relationshipsAPIAdapter.generateRequestBodyForEditOwnershipStructure(
        values,
        initialFormValues as FormValuesModel,
      );

    await relationshipsAPI.updateRelationships(formattedRequestBody);
    message.success(t('client_profile.ownership_modal.success_submit_message'));
  };

  return (
    <TemplateOwnershipStructureModal
      title={modalTitle}
      isVisible={isVisible}
      disabled={!isTwoFactorVerificationEnabled}
      onSubmit={handleSubmit}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      isViewOnly={isViewOnly}
    />
  );
};

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default OwnershipStructureModal;
