import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Trans } from 'react-i18next';
import { Button, Tooltip } from 'antd';

export interface IProps extends RequiredPropsForModalDialogModel {
  onDontShowButton: () => Promise<void>;
  onOk: () => Promise<void>;
}

const PotentialContactDuplicationModal = memo(
  ({ isVisible, onOk, closeCallback, onDontShowButton }: IProps) => {
    const { t } = useTranslation(['onboarding', 'common']);

    return (
      <ModalDialog
        width={650}
        title={t('application_documentation.potential_duplication_modal.title')}
        isVisible={isVisible}
        hideFooterButtons
        closeCallback={closeCallback}
      >
        <StyledText>
          <Trans
            t={t}
            i18nKey="application_documentation.potential_duplication_modal.description"
            components={[
              <StyledTooltip
                key="client-group-tooltip"
                placement="bottom"
                title={t('key_words.client_group', { ns: 'common' })}
              />,
            ]}
          />
        </StyledText>
        <StyledDivAlignCenter>
          <StyledButton type="primary" onClick={onOk}>
            {t('ok', { ns: 'common' })}
          </StyledButton>
          <Link onClick={onDontShowButton}>
            {t(
              'application_documentation.potential_duplication_modal.button_text',
            )}
          </Link>
        </StyledDivAlignCenter>
      </ModalDialog>
    );
  },
);

const StyledText = styled(Text)`
  margin-bottom: ${({ theme }) => theme.marginMd};
  white-space: pre-wrap;
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginMd};
`;

const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px dotted ${theme.colorWarning}`};
  width: fit-content;
`;

const StyledButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default PotentialContactDuplicationModal;
