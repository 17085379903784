import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { StyledComponentProps } from '../../../../typings/common';

// components
import InfoTooltip from '../../InfoTooltip';

type IProps = StyledComponentProps;

const AccountAdministratorTooltip = (props: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <InfoTooltip
      {...props}
      tooltipContent={t(
        'personal_info_modal.account_administrator_description',
      )}
    />
  );
};

export default AccountAdministratorTooltip;
