import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '../../../../../helpers/date';
import { AccountsHelpers } from '../../../../../helpers/accounts';
import { TransactionBase } from '../../../../../typings/accounting/transaction';
import { JournalEntryTypes } from '../../../../../enums/approvalManagement/approvalManagement';
import { AccountProcessingTypes } from '../../../../../enums/accounting/transfers';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import DocumentLink from '../../../../Typography/DocumentLink';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import DerivedFromCircleTooltip from '../../../../Tooltips/TemplateTooltips/DerivedFromCircleTooltip';
import TransactionProcessedByBankTooltip from 'components/Tooltips/TemplateTooltips/TransactionProcessedByBankTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<TransactionBase> {
  onActionsClick: (key: string, id: number) => void;
}

const TransactionsTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('accounts');

  const renderDocuments = (record: TransactionBase) => {
    return record.documents.map(({ dmsId, name, linkToDownload }) => (
      <div key={dmsId}>
        <DocumentLink text={name} fileId={linkToDownload} documentId={dmsId} />
      </div>
    ));
  };

  const renderDescription = ({
    id,
    description,
    sourceId,
    derivedFrom,
    reconciliationStatus,
  }: TransactionBase) => {
    if (!description) {
      return;
    }

    let result = null;

    if (derivedFrom === AccountProcessingTypes.Circle) {
      result = (
        <>
          {description}
          <DerivedFromCircleTooltip derivedEntryType="transaction" />
        </>
      );
    } else {
      if (
        [
          JournalEntryTypes.InternalTransfer,
          JournalEntryTypes.WireTransfer,
          JournalEntryTypes.BlockchainTemplate,
        ].includes(sourceId)
      ) {
        result = (
          <Link onClick={() => onActionsClick('view', id)}>{description}</Link>
        );
      } else {
        result = <Text variant="body1">{description}</Text>;
      }
    }

    const shouldShowTooltip = reconciliationStatus === 'UNRECONCILED';

    return (
      <DivAlignCenter>
        {result} {shouldShowTooltip && <TransactionProcessedByBankTooltip />}
      </DivAlignCenter>
    );
  };

  const renderTransactionType = ({ sourceId }: TransactionBase) => {
    return (
      <Text variant="body1">
        {t(`journal_entry_types.${JournalEntryTypes[sourceId]}`)}
      </Text>
    );
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('account_activity.transactions_table.date'),
        key: 'valueDate',
        width: '140px',
        render: (record: TransactionBase) =>
          DateHelpers.formatTimestampDateToUTCString(record.valueDate),
      },
      {
        title: t('account_activity.transactions_table.description'),
        key: 'description',
        width: 350,
        render: renderDescription,
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('account_activity.transactions_table.documents'),
        key: 'documents',
        render: renderDocuments,
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('account_activity.transactions_table.transaction_type'),
        key: 'type',
        width: '180px',
        render: renderTransactionType,
        mobileCardProps: { hide: true, isCardTitle: true },
      },
      {
        title: t('account_activity.transactions_table.debit'),
        key: 'debit',
        render: (record: TransactionBase) =>
          record.debitAmount
            ? AccountsHelpers.convertAmountBigIntToLocaleString(
                record.debitAmount,
              )
            : '',
      },
      {
        title: t('account_activity.transactions_table.credit'),
        key: 'credit',
        render: (record: TransactionBase) =>
          record.creditAmount
            ? AccountsHelpers.convertAmountBigIntToLocaleString(
                record.creditAmount,
              )
            : '',
      },
      {
        title: t('account_activity.transactions_table.balance'),
        key: 'balance',
        render: (record: TransactionBase) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.balance),
      },
    ];
  }, [onActionsClick]);

  return <Table {...rest} columns={tableColumns} />;
};
export default TransactionsTable;
