import React, { useEffect } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import DeleteButton from '../../../../../../DesignSystem/Common/Buttons/DeleteButton';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import CountrySelect from '../../../../../../Forms/FormComponents/SelectInputs/CRM/CountrySelect';
import { FormValuesModel } from '../..';

interface IProps {
  dataIndex: string;
  index: number;
  record: any;
  canDelete: boolean;
  isDisabled: boolean;
  onDelete: () => void;
}

const TableCell = ({
  dataIndex,
  index,
  canDelete,
  isDisabled,
  onDelete,
}: IProps) => {
  const { t } = useTranslation('form');

  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  // handle change departure date event (departure date can not be bigger than return date)
  // if the new departure date is bigger than return date
  // than we just need to clear return date
  useEffect(() => {
    const { departureDate, returnDate } = values.countries[index];
    if (departureDate && returnDate) {
      const shouldResetReturnDate = moment(departureDate).isAfter(returnDate);

      if (shouldResetReturnDate) {
        setFieldValue(`countries.${index}.returnDate`, null);
      }
    }
  }, [values.countries[index].departureDate]);

  // departure date should be the same or bigger date
  // than return date from previous row
  const getDepartureDisabledDate = (current: any) => {
    if (
      index === 0 ||
      values.countries.length === 1 ||
      !values.countries[index - 1].returnDate
    ) {
      return false;
    }

    return moment(current).isBefore(values.countries[index - 1].returnDate);
  };

  // return date should be the same or bigger date than departure date
  const getReturnDisabledDate = (current: any) =>
    moment(current).isBefore(values.countries[index].departureDate);

  const renderColContent = () => {
    switch (dataIndex) {
      case 'country':
        return (
          <FormField
            name={`countries.${index}.country`}
            disabled={isDisabled}
            component={CountrySelect}
          />
        );

      case 'departure_date':
        return (
          <FormField
            name={`countries.${index}.departureDate`}
            placeholder={t('select_date')}
            disabled={isDisabled}
            component={StyledDatePicker}
            additionalProps={{ disabledDate: getDepartureDisabledDate }}
          />
        );

      case 'return_date': {
        const isDepartureDate = !!values.countries[index].departureDate;

        return (
          <FormField
            name={`countries.${index}.returnDate`}
            placeholder={t('select_date')}
            disabled={isDisabled || !isDepartureDate}
            component={StyledDatePicker}
            additionalProps={{ disabledDate: getReturnDisabledDate }}
          />
        );
      }

      case 'delete':
        return !canDelete ? null : (
          <CenterAlign>
            <DeleteButton onClick={onDelete} />
          </CenterAlign>
        );
      default:
        return null;
    }
  };

  return <td>{renderColContent()}</td>;
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const CenterAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default TableCell;
