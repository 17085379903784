const BrowserHelpers = {
  isWebAuthnSupported: function () {
    return typeof window.PublicKeyCredential !== 'undefined';
  },

  isSafari: function () {
    const userAgent = (navigator?.userAgent ?? '').toLowerCase();
    const isSafari = /version\/(\d+).+?safari/.test(userAgent);
    return isSafari;
  },
};

export { BrowserHelpers };
