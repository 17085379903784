const AccountsHelpers = {
  convertAmountFromBigIntToInt: function (amount: string | number): number {
    return +amount / 10000;
  },

  convertAmountFromIntToBigInt: function (amount: number): number {
    return Math.round(amount * 10000);
  },

  formatAmountToLocaleString: function (
    amount: number,
    minDigits = 2,
    maxDigits = 2,
  ) {
    if (typeof amount === 'undefined') {
      return '';
    }

    return amount.toLocaleString(undefined, {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });
  },

  convertAmountBigIntToLocaleString: function (
    amount: string | number,
    minDigits = 2,
    maxDigits = 2,
  ): string {
    const amountInt = this.convertAmountFromBigIntToInt(amount);
    return this.formatAmountToLocaleString(amountInt, minDigits, maxDigits);
  },

  generateAccountFullName: function (
    accountName: string,
    accountNumber: string,
    currencyName: string,
    balanceBigInt?: number,
  ) {
    const formattedBalance = balanceBigInt
      ? this.convertAmountBigIntToLocaleString(balanceBigInt)
      : '';
    return `${accountNumber} - ${accountName} ${currencyName} ${formattedBalance}`;
  },

  roundNumber: function (number: number, precision: number, isDown?: boolean) {
    const factor = Math.pow(10, precision);

    const tempNumber = number * factor;
    let roundedTempNumber = 0;

    if (isDown) {
      roundedTempNumber = Math.round(-tempNumber) * -1;
    } else {
      roundedTempNumber = Math.round(tempNumber);
    }

    return roundedTempNumber / factor;
  },
};

export { AccountsHelpers };
