import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useField } from 'formik';

// constants
import { TWO_COL_GRID_SIZES } from '../../../../../constants/grids';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import TrueFalseSelect from '../../../FormComponents/SelectInputs/TrueFalseSelect';
import AreYouPepTooltip from '../../../../Tooltips/TemplateTooltips/AreYouPepTooltip';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import PhoneNumberTooltip from 'components/Tooltips/TemplateTooltips/PhoneNumberTooltip';
import FormInputPhoneNumber from '@common_components/Form/FormInputPhoneNumber';
import OnlineAccessForm, {
  FormValuesModel as OnlineAccessFormValuesModel,
} from '../OnlineAccessForm';
import { Col, Row } from 'antd';

interface IProps {
  namePrefix: string;
  disabled?: boolean;
  hideAccessFieldsWhenNotOnline?: boolean;
}

export interface FormValuesModel extends OnlineAccessFormValuesModel {
  id?: string;

  firstName: string;
  lastName: string;
  middleName: string;

  email: string;
  disableEmail?: boolean;

  phone: string;
  disablePhoneNumber?: boolean;

  isPEP: boolean | null;
  pepInformation: string;

  isPermissionsSelectAvailable: boolean;
}

/* Related validation schema: onboarding-new/structure/ContactShortInfoFormValidationSchema **/
const ContactShortInfoForm = ({
  namePrefix,
  disabled,
  hideAccessFieldsWhenNotOnline,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [field] = useField<FormValuesModel>(namePrefix);

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span="24">
          <FormField
            disabled={disabled}
            label={t(
              'structure.relationships.form_fields.individual.first_name',
            )}
            name={`${namePrefix}.firstName`}
            component={FormInput}
          />
        </Col>

        <Col span="24">
          <FormField
            disabled={disabled}
            label={t(
              'structure.relationships.form_fields.individual.middle_name',
            )}
            name={`${namePrefix}.middleName`}
            component={FormInput}
          />
        </Col>

        <Col span="24">
          <FormField
            disabled={disabled}
            label={t(
              'structure.relationships.form_fields.individual.last_name',
            )}
            name={`${namePrefix}.lastName`}
            component={FormInput}
          />
        </Col>

        <Col span="24">
          <FormField
            disabled={disabled || field.value.disableEmail}
            name={`${namePrefix}.email`}
            label={t(
              'structure.relationships.form_fields.individual.email_address',
            )}
            component={FormInput}
          />
        </Col>

        <Col span="24">
          <FormField
            disabled={disabled || field.value.disablePhoneNumber}
            name={`${namePrefix}.phone`}
            label={
              <TitleWrapper>
                <Text>
                  {t(
                    'structure.relationships.form_fields.individual.phone_number',
                  )}
                </Text>{' '}
                <PhoneNumberTooltip />
              </TitleWrapper>
            }
            component={FormInputPhoneNumber}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES} pull={0}>
          <FormField
            label={
              <DivAlignCenter>
                {t('structure.relationships.form_fields.individual.is_pep')}{' '}
                <AreYouPepTooltip />
              </DivAlignCenter>
            }
            disabled={disabled}
            name={`${namePrefix}.isPEP`}
            component={TrueFalseSelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          {field.value?.isPEP && (
            <FormField
              disabled={disabled}
              label={t(
                'structure.relationships.form_fields.individual.pep_information',
              )}
              name={`${namePrefix}.pepInformation`}
              component={FormInputTextArea}
            />
          )}
        </Col>
      </Row>

      {field.value?.isPermissionsSelectAvailable && (
        <OnlineAccessForm
          namePrefix={namePrefix}
          disabled={disabled}
          hideAccessFieldsWhenNotOnline={hideAccessFieldsWhenNotOnline}
        />
      )}
    </>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.marginXXs};
`;

export default ContactShortInfoForm;
