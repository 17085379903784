import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { ApprovalStatusMap } from '../..';

// components
import Text from '@core_components/Text';
import { Col, Row, Select } from 'antd';

interface IProps {
  onFilterChange: (key: string, value: string) => void;
  selectedStatus: string | null;
  approvalStatuses: ApprovalStatusMap;
}

// TODO: add ability to filter by user name
const FilterBar = ({
  selectedStatus,
  onFilterChange,
  approvalStatuses,
}: IProps) => {
  const { t } = useTranslation('account_management');

  const filterGridSizes = { xl: 6, lg: 12, md: 24, sm: 24, xs: 24 };

  const renderOptions = (approvalStatuses: ApprovalStatusMap) => {
    const result = Object.keys(approvalStatuses).map((key) => {
      return (
        <Select.Option key={key} value={key}>
          {t(`pending_approvals.approval_statuses.${key}`)}
        </Select.Option>
      );
    });

    return result;
  };

  return (
    <Row gutter={[16, 16]}>
      {/* <Col {...filterGridSizes}>
        <Row>
          <Col span={24}>
            <Text variant='body1'>{t('account_management_rules.approvals.search_by_user')}</Text>
          </Col>
          <Col span={24}>
            <Input
              size='large'
              placeholder={t('account_management_rules.approvals.search_by_user_ph')}
              onChange={e => onFilterChange('search-by-name', e.target.value)}
            />
          </Col>
        </Row>
      </Col> */}

      <Col {...filterGridSizes}>
        <Row>
          <Col span={24}>
            <Text variant="body1">
              {t('pending_approvals.table.filter_bar.filter_by_status')}
            </Text>
          </Col>
          <Col span={24}>
            <StyledSelect
              value={selectedStatus || undefined}
              size="large"
              onChange={(value: any) =>
                onFilterChange('filter-by-status', value)
              }
            >
              {renderOptions(approvalStatuses)}
            </StyledSelect>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default FilterBar;
