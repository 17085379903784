import * as yup from 'yup';
import { AccountProcessingTypes } from '../../enums/accounting/transfers';
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  TRANSFER_REFERENCE_MAX_LENGTH,
} from '../../constants/global';

// constants
import {
  EMAIL_VALIDATION_RULE,
  ACCOUNT_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  NULLABLE_NUMBER_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
} from '../common';

export const createAccountSchema = yup.object().shape({
  accountType: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'accounts:account_summary.create_account.form_fields.account_type',
    },
  }),

  circleAccount: yup.object().when('accountType', {
    is: AccountProcessingTypes.Circle,
    then: (schema) =>
      schema.shape({
        accountName: ACCOUNT_NAME_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'accounts:account_summary.create_account.form_fields.account_name',
          },
        }),

        accountNumber: NULLABLE_STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'accounts:account_summary.create_account.form_fields.account_number',
          },
        }),

        currencyId: NULLABLE_NUMBER_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'accounts:account_summary.create_account.form_fields.currency',
          },
        }),

        accountCategoryId: NULLABLE_NUMBER_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'accounts:account_summary.create_account.form_fields.account_category',
          },
        }),
      }),
  }),

  fiatAccount: yup.object().when('accountType', {
    is: AccountProcessingTypes.Native,
    then: (schema) =>
      schema.shape({
        purpose: STRING_VALIDATION_RULE.min(DESCRIPTION_MIN_LENGTH, {
          key: 'form:validation_messages.min_characters_with_label',
          values: {
            label:
              'onboarding:account_information.form_fields.purpose_of_account',
            number: DESCRIPTION_MIN_LENGTH,
          },
        })
          .max(DESCRIPTION_MAX_LENGTH, {
            key: 'form:validation_messages.max_characters_with_label',
            values: {
              label:
                'onboarding:account_information.form_fields.purpose_of_account',
              number: DESCRIPTION_MAX_LENGTH,
            },
          })
          .required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:account_information.form_fields.purpose_of_account',
            },
          }),

        type: yup
          .mixed()
          .nullable()
          .required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label: 'onboarding:account_information.form_fields.account_type',
            },
          }),

        currencyId: NULLABLE_NUMBER_VALIDATION_RULE.typeError({
          key: 'form:validation_messages.required_with_label',
          values: {
            label: 'onboarding:account_information.form_fields.currency',
          },
        }).required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label: 'onboarding:account_information.form_fields.currency',
          },
        }),

        initialDeposit: yup
          .mixed()
          .nullable()
          .required({
            key: 'form:validation_messages.required_with_label',
            values: { label: 'common:value' },
          }),

        initialDepositOrigin: NULLABLE_STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'common:value' },
        }),

        jurisdictions: yup
          .array()
          .min(1, {
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:account_information.form_fields.jurisdictions_title',
            },
          })
          .required({
            key: 'form:validation_messages.required_with_label',
            values: {
              label:
                'onboarding:account_information.form_fields.jurisdictions_title',
            },
          }),

        incomingWiresNumber: yup
          .mixed()
          .nullable()
          .required({
            key: 'form:validation_messages.required_with_label',
            values: { label: 'common:value' },
          }),

        estimatedIncomingFunds: yup
          .mixed()
          .nullable()
          .required({
            key: 'form:validation_messages.required_with_label',
            values: { label: 'common:value' },
          }),

        outgoingWiresNumber: yup
          .mixed()
          .nullable()
          .required({
            key: 'form:validation_messages.required_with_label',
            values: { label: 'common:value' },
          }),

        estimatedOutgoingFunds: yup
          .mixed()
          .nullable()
          .required({
            key: 'form:validation_messages.required_with_label',
            values: { label: 'common:value' },
          }),
      }),
  }),
});

export const receiveMoneyNativeValidation = yup.object().shape({
  fullName: STRING_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters_with_label',
    values: {
      label:
        'accounts:account_activity.send_wire_instructions.native.form_fields.fullName',
      number: NAME_MIN_LENGTH,
    },
  })
    .max(NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label:
          'accounts:account_activity.send_wire_instructions.native.form_fields.fullName',
        number: NAME_MAX_LENGTH,
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label:
          'accounts:account_activity.send_wire_instructions.native.form_fields.fullName',
      },
    }),
  email: EMAIL_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'accounts:account_activity.send_wire_instructions.native.form_fields.email',
    },
  }),
  message: STRING_VALIDATION_RULE,
  reference: STRING_VALIDATION_RULE.max(TRANSFER_REFERENCE_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'accounts:account_activity.send_wire_instructions.native.form_fields.reference',
      number: TRANSFER_REFERENCE_MAX_LENGTH,
    },
  }),
});
