import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { getBadges } from 'redux/actions/app';
import { StateModel } from '../../../../../redux/reducers';
import { IApprovalGroupUser } from '../../../../../typings/accounting/transaction';
import { updateActiveApplication } from 'redux/actions/applications';
import { useDispatch, useSelector } from 'react-redux';
import { accountManagementRulesAPI } from '../../../../../api/accountManagement/accountManagementRulesAPI';
import { ApprovalProgressStateModel } from '../../../../../typings/approvalManagement/accountManagement';
import {
  AccountManagementApprovalStatuses,
  ApprovalStatuses,
  ApprovalWorkflowEntryTypes,
} from '../../../../../enums/approvalManagement/approvalManagement';

// components
import Button from '../../../../../components/DesignSystem/Core/Button';
import InnerContent from './InnerContent';
import DivAlignCenter from '../../../../../components/Additional/DivAlignCenter';
import LoadingWrapper from '../../../../../components/WrapperComponents/LoadingWrapper';
import WarningIconWithText from '../../../../../components/Additional/WarningIconWithText';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import ConfirmationWithReason, {
  FormValuesModel,
} from '../../../../../components/ModalDialogs/ConfirmationWithReason';
import { Col, Divider, message, Row } from 'antd';

enum ACTION_TYPE {
  APPROVE = 1,
  REJECT,
}

interface IProps extends RequiredPropsForModalDialogModel {
  workflowId: string | null;
  disabled?: boolean;
}

const ApprovalEntryModal = ({
  workflowId,
  isVisible,
  disabled,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const dispatch = useDispatch();
  const [selectedAction, setSelectedAction] = useState<ACTION_TYPE | null>(
    null,
  );

  // Needed to close the confirmation modal dialog
  // if main modal was closed
  useEffect(() => {
    if (selectedAction && !isVisible) {
      setSelectedAction(null);
    }
  }, [isVisible, selectedAction]);

  const myUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );
  const buttonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

  const modalConfirmationTitle = useMemo(() => {
    return selectedAction === ACTION_TYPE.APPROVE
      ? t('confirm_approve_title', { ns: 'common' })
      : t('confirm_reject_title', { ns: 'common' });
  }, [selectedAction]);

  const submitButtonText = useMemo(() => {
    return selectedAction === ACTION_TYPE.APPROVE
      ? t('approve', { ns: 'common' })
      : t('reject', { ns: 'common' });
  }, [selectedAction]);

  const initialValues = useMemo<FormValuesModel>(() => {
    return { reason: '' };
  }, []);

  const { response, loading } = useFetch(
    () =>
      workflowId
        ? accountManagementRulesAPI.getApprovalWorkflowById(workflowId)
        : null,
    [workflowId],
  );

  const myMemberInApprovalProgress = useMemo(() => {
    let member: IApprovalGroupUser | undefined;

    response?.approvalProgressStateSets.forEach(
      (state: ApprovalProgressStateModel) => {
        state.progressStateItems.forEach(({ members }) => {
          member =
            members.find(
              (member: IApprovalGroupUser) => member.userId === myUserId,
            ) || member;
        });
      },
    );

    return member;
  }, [response, myUserId]);

  const handleSubmit = async (values: FormValuesModel) => {
    const { reason } = values;

    if (selectedAction === ACTION_TYPE.APPROVE) {
      await accountManagementRulesAPI.approveApprovalById(
        workflowId as string,
        reason,
      );
    } else {
      await accountManagementRulesAPI.rejectApprovalById(
        workflowId as string,
        reason,
      );
    }

    const response = await accountManagementRulesAPI.getApprovalWorkflowById(
      workflowId as string,
    );

    if (
      (response.entry.status === AccountManagementApprovalStatuses.Approved ||
        response.entry.status === AccountManagementApprovalStatuses.Rejected) &&
      response.entry.type ===
        ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_UPDATE_CLIENTGROUP
    ) {
      dispatch(updateActiveApplication(false, false));
    }

    const messageText =
      selectedAction === ACTION_TYPE.APPROVE
        ? t('pending_approvals.approval_details_modal.approve_success_message')
        : t('pending_approvals.approval_details_modal.reject_success_message');

    dispatch(getBadges());
    message.success(messageText);
    setSelectedAction(null);
    closeCallback(true);
  };

  const modalTitle = useMemo(() => {
    if (
      myMemberInApprovalProgress?.approvalAction === ApprovalStatuses.Pending &&
      disabled
    ) {
      return (
        <DivAlignCenter>
          <StyledTitle>
            {t('pending_approvals.approval_details_modal.title')}
          </StyledTitle>
          <StyledWarningInfoNot2FA
            text={t('pending_approvals.approval_details_modal.warning_not_2fa')}
          />
        </DivAlignCenter>
      );
    }

    return t('pending_approvals.approval_details_modal.title');
  }, [myMemberInApprovalProgress?.approvalAction, disabled]);

  return (
    <ModalDialog
      title={modalTitle}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
      submitButtonProps={{ hidden: true }}
      cancelButtonProps={{
        text: t('close', { ns: 'common' }),
        hidden:
          myMemberInApprovalProgress?.approvalAction ===
          ApprovalStatuses.Pending,
      }}
    >
      <LoadingWrapper loading={loading}>
        {response && (
          <>
            <InnerContent approvalEntry={response} />

            {myMemberInApprovalProgress?.approvalAction ===
              ApprovalStatuses.Pending && (
              <>
                <Divider />
                <Row gutter={[16, 16]} justify="end">
                  <Col {...buttonGridSizes}>
                    <StyledButton
                      disabled={disabled}
                      danger
                      size="large"
                      onClick={() => setSelectedAction(ACTION_TYPE.REJECT)}
                    >
                      {t('reject', { ns: 'common' })}
                    </StyledButton>
                  </Col>

                  <Col {...buttonGridSizes}>
                    <StyledButton
                      disabled={disabled}
                      size="large"
                      onClick={() => setSelectedAction(ACTION_TYPE.APPROVE)}
                    >
                      {t('approve', { ns: 'common' })}
                    </StyledButton>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </LoadingWrapper>

      <ConfirmationWithReason
        title={modalConfirmationTitle}
        onSubmit={handleSubmit}
        isVisible={!!selectedAction}
        initialValues={initialValues}
        closeCallback={() => setSelectedAction(null)}
        submitButtonText={submitButtonText}
      />
    </ModalDialog>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default ApprovalEntryModal;
