import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ClientGroupUserStatuses } from 'enums/onboarding/crm';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  info: [ClientGroupUserStatuses.InvitationExpired],
  warning: [
    ClientGroupUserStatuses.Onboarding,
    ClientGroupUserStatuses.ReadyForReview,
    ClientGroupUserStatuses.InReview,
    ClientGroupUserStatuses.AccountManagementReview,
    ClientGroupUserStatuses.Invited,
  ],
  success: [ClientGroupUserStatuses.Approved, ClientGroupUserStatuses.Enabled],
  error: [
    ClientGroupUserStatuses.Rejected,
    ClientGroupUserStatuses.Deactivated,
  ],
};

interface IProps {
  status: ClientGroupUserStatuses;
}

const UserStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('account_management');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      content={t(`user_management.user_statuses.${status}`, status)}
    />
  );
});

export default UserStatus;
