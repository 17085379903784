import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { circleAPI } from '../../../../api/accounting/circleAPI';
import { StateModel } from '../../../../redux/reducers';
import { colorsTheme } from 'resources/theme/styled/colors';
import { setCircleStatus } from '../../../../redux/actions/auth';
import { OnboardingStatuses } from '../../../../enums/onboarding/crm';
import { ExternalServiceStatuses } from '../../../../redux/reducers/auth';
import { useDispatch, useSelector } from 'react-redux';

// components
import Text from '@core_components/Text';
import Button from '../../../../components/DesignSystem/Core/Button';
import IconSVG from '../../../../components/DesignSystem/Core/IconSVG';
import ConnectCircle from './ConnectCircle';
import PendingApprovalTooltip from '../../../../components/Tooltips/TemplateTooltips/ExternalServices/PendingApprovalTooltip';
import PendingOnboardingTooltip from '../../../../components/Tooltips/TemplateTooltips/ExternalServices/PendingOnboardingTooltip';
import StatusLabel, {
  LABEL_STATUSES,
} from '../../../../components/Typography/StatusLabel';
import { Col, message, Modal, Row } from 'antd';
import { ReactComponent as CircleIcon } from '../../../../resources/icons/external-services/circle-service-logo.svg';

const STATUS_MAP: Record<
  LABEL_STATUSES,
  ExternalServiceStatuses[] | undefined
> = {
  info: [],
  warning: ['pending_synchronization'],
  success: ['connected'],
  error: ['disconnected'],
};

const ConnectedStatus = memo(
  ({
    status,
    isOnboardingActive,
  }: {
    status: ExternalServiceStatuses;
    isOnboardingActive: boolean;
  }) => {
    const { t } = useTranslation('account_management');

    function getLabel(
      connectionStatus: ExternalServiceStatuses,
    ): React.ReactNode {
      switch (connectionStatus) {
        case 'connected':
          return t('external_services.statuses.connected');

        case 'disconnected':
          return t('external_services.statuses.disconnected');

        case 'pending_synchronization': {
          let result = null;

          if (isOnboardingActive) {
            result = (
              <>
                <PendingOnboardingTooltip />
                {` ${t('external_services.statuses.pending_onboarding_approval')}`}
              </>
            );
          } else {
            result = (
              <>
                <PendingApprovalTooltip />
                {` ${t('external_services.statuses.pending_review_or_synchronization')}`}
              </>
            );
          }

          return result;
        }
      }
    }

    return (
      <StatusLabel
        statusMap={STATUS_MAP}
        status={status}
        content={getLabel(status)}
      />
    );
  },
);

interface IProps {
  disabled?: boolean;
}

const ManageCircleService = memo(({ disabled }: IProps) => {
  const { t } = useTranslation('account_management');
  const dispatch = useDispatch();
  const circleStatus = useSelector<StateModel, ExternalServiceStatuses>(
    (state) => state.auth.externalServices.circle,
  );

  const isOnboardingActive = useSelector<StateModel, boolean>((state) => {
    return !!(
      state.applications.activeApplication?.onboardingProcess &&
      state.applications.activeApplication?.onboardingProcess?.status !==
        OnboardingStatuses.Approved
    );
  });

  const canDisconnect = isOnboardingActive
    ? true
    : circleStatus === 'connected';
  const isCircleConnected = circleStatus !== 'disconnected';

  const onDisconnectClick = () => {
    Modal.confirm({
      title: t('external_services.circle.disconnect_circle_modal.title'),
      content: t(
        'external_services.circle.disconnect_circle_modal.description',
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await circleAPI.disconnectService();
        message.success(
          t('external_services.circle.disconnect_circle_modal.success_message'),
        );
        dispatch(setCircleStatus('disconnected'));
      },
    });
  };

  return (
    <Row gutter={[24, 24]} align="middle" wrap={false}>
      <Col flex="100px">
        <IconSVG component={CircleIcon} size="large" />
      </Col>

      <Col flex="auto">
        <Text variant="h5" color={colorsTheme.colorWhite} weight="semi-bold">
          {t('external_services.circle.title')}
        </Text>
        <Text variant="body1">{t('external_services.circle.description')}</Text>
      </Col>

      <Col flex="190px">
        {isCircleConnected ? (
          <>
            <ConnectedStatus
              status={circleStatus}
              isOnboardingActive={isOnboardingActive}
            />

            {canDisconnect && !disabled && (
              <StyledButton danger onClick={onDisconnectClick}>
                {t('external_services.disconnect_button')}
              </StyledButton>
            )}
          </>
        ) : (
          <ConnectCircle disabled={disabled} />
        )}
      </Col>
    </Row>
  );
});

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ManageCircleService;
