import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { userAPI } from 'api/profile/userAPI';
import { StateModel } from 'redux/reducers';
import { RoutePaths } from 'routes/routes';
import { securityAPI } from '../../../../api/profile/securityAPI';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { ResetTabs, FormValuesModel } from '..';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import ResendOtp from '../../../../components/Additional/ResendOtp';
import SubmitButton from '../../../../components/Buttons/SubmitButton';
import AuthContainer from '../../../../components/Additional/AuthContainer';
import RequestResetPassword from './RequestResetPassword';
import RequestResetPasswordSms from './RequestResetPasswordSms';
import SecurityCodeVerification from './SecurityCodeVerification';
import SecuritySelfieVerification from './SecuritySelfieVerification';
import RequestResetPasswordUsername from './RequestResetPasswordUsername';

const ResetPasswordSteps = () => {
  const { t } = useTranslation(['auth', 'common']);
  const { values } = useFormikContext<FormValuesModel>();
  const { temporaryToken } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const title = useMemo(() => {
    switch (values.currentStep) {
      case ResetTabs.RequestResetPasswordUsername:
        return t('reset_password.steps.request_reset_password_username.title');
      case ResetTabs.CodeVerification:
        return t('reset_password.steps.otp_verification.email.title');
      case ResetTabs.RequestResetPasswordSMS:
        return t('reset_password.steps.otp_verification.phone.title');
      case ResetTabs.SelfieVerification:
        return t('reset_password.steps.selfie_verification.title');
      case ResetTabs.ResetPassword:
        return t('reset_password.steps.new_password.title');
    }
  }, [values.currentStep]);

  const renderCurrentTab = (currentStep: ResetTabs) => {
    switch (currentStep) {
      case ResetTabs.RequestResetPasswordUsername:
        return <RequestResetPasswordUsername />;
      case ResetTabs.CodeVerification:
        return <SecurityCodeVerification />;
      case ResetTabs.RequestResetPasswordSMS:
        return <RequestResetPasswordSms />;
      case ResetTabs.SelfieVerification:
        return <SecuritySelfieVerification />;
      case ResetTabs.ResetPassword:
        return <RequestResetPassword />;
    }
  };

  const footer = useMemo(() => {
    if (values.currentStep === ResetTabs.RequestResetPasswordUsername) {
      return (
        <FooterLinkWrapper>
          <Link to={RoutePaths.Auth_Root}>
            {t('reset_password.go_back_to_signin')}
          </Link>
        </FooterLinkWrapper>
      );
    }

    return null;
  }, [values.currentStep]);

  const resendEmailOtp = async () => {
    await userAPI.requestResetUsername({ username: values.username });
  };

  const resendSmsOtp = async () => {
    if (temporaryToken) {
      await securityAPI.resendSMSOTPforResetPasswordProcess(temporaryToken);
    }
  };

  const resendContent = useMemo(() => {
    switch (values.currentStep) {
      case ResetTabs.CodeVerification:
        return (
          <ResendOtp
            key="emailVerification"
            resendOtpCallback={resendEmailOtp}
            successMessage={t(
              'reset_password.steps.otp_verification.email.otp_sent_message',
            )}
          />
        );
      case ResetTabs.RequestResetPasswordSMS:
        return (
          <ResendOtp
            key="smsVerification"
            resendOtpCallback={resendSmsOtp}
            successMessage={t(
              'reset_password.steps.otp_verification.phone.otp_sent_message',
            )}
          />
        );
    }
  }, [values.currentStep, temporaryToken]);

  return (
    <StyledAuthContainer footerText={footer}>
      <Title variant="h5" weight="semi-bold" gutterBottom>
        {title}
      </Title>

      <div>
        {renderCurrentTab(values.currentStep)}

        {values.currentStep !== ResetTabs.SelfieVerification && (
          <>
            <StyledSubmitBtn fullWidth>
              {values.currentStep === ResetTabs.ResetPassword
                ? t('reset_password.reset_password_button')
                : t('next', { ns: 'common' })}
            </StyledSubmitBtn>
            {resendContent}
          </>
        )}
      </div>
    </StyledAuthContainer>
  );
};

const Title = styled(Text)`
  display: block;
  text-align: center;
`;

const FooterLinkWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginXs};
  text-align: center;
`;

const StyledSubmitBtn = styled(SubmitButton)`
  margin-top: ${({ theme }) => theme.marginXs};
`;

const StyledAuthContainer = styled(AuthContainer)`
  min-height: 385px !important;
`;

export default ResetPasswordSteps;
