import React, { useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../hooks/useTranslation';
import { StateModel } from '../../redux/reducers';
import { RoutePaths } from 'routes/routes';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TwoFactorVerificationTypes } from '../../enums/profile/auth';
import { StateModel as AuthStateModel } from '../../redux/reducers/auth';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import AuthContainer from '../../components/Additional/AuthContainer';
import Authenticator from '../../modules/Auth/TwoStepAuthentication/Authenticator';
import FidoAuthentication from '../../modules/Auth/TwoStepAuthentication/FidoAuthentication';

const TwoFactorAuthentication = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const { twoFactorVerificationData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  useEffect(() => {
    if (!twoFactorVerificationData) {
      navigate(RoutePaths.Auth_Root);
    }
  }, [twoFactorVerificationData]);

  const renderContent = (type: TwoFactorVerificationTypes) => {
    switch (type) {
      case TwoFactorVerificationTypes.Authenticator:
        return <Authenticator />;

      case TwoFactorVerificationTypes.Fido:
        return <FidoAuthentication />;
    }
  };

  return (
    <AuthContainer
      footerText={
        <LinkWrapper>
          <Link to={RoutePaths.Auth_Root}>
            {t('two_factor_authentication.go_back_to_signin')}
          </Link>
        </LinkWrapper>
      }
    >
      <div>
        <Title variant="h4" gutterBottom weight="semi-bold">
          {t('two_factor_authentication.title')}
        </Title>
      </div>

      <Wrapper>
        {twoFactorVerificationData &&
          renderContent(twoFactorVerificationData?.type)}
      </Wrapper>
    </AuthContainer>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Text)`
  display: block;
  text-align: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginXs};
`;

export default TwoFactorAuthentication;
