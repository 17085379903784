import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { FormValuesModel } from '../..';

// constants
import { colorsTheme } from '../../../../../../../resources/theme/styled/colors';
import { MessageCategories } from '../../../../../../../enums/messaging/messages';
import { TWO_COL_GRID_SIZES } from '../../../../../../../constants/grids';

// components
import Text from '@core_components/Text';
import Checkbox from '../../../../../../DesignSystem/Core/Checkbox';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import SendMessage from '../../../../../../../modules/Profile/Messages/SendMessage';
import SectionIntro from '@common_components/Texts/SectionIntro';
import AdditionalField from '../../../../../FormComponents/AdditionalField';
import DescriptionWithLinks from '@common_components/Texts/DescriptionWithLinks';
import DocumentReviewStatus from 'components/Additional/CRM/DocumentReviewStatus';
import CertificationRequiredLabel from '../../../../../../Typography/CertificationRequiredLabel';
import { Col, Row } from 'antd';

interface IProps {
  canSendMessage?: boolean;
}

const AdditionalFieldsSection = ({ canSendMessage }: IProps) => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        title={t('application_documentation.title')}
        appendToTitle={
          canSendMessage && (
            <SendMessage
              categoryId={MessageCategories.Applications}
              hideRecipientsField
            />
          )
        }
      />

      {values.additionalFields.map((e, i) => (
        <StyledRow key={i} gutter={[16, 16]}>
          <Col {...TWO_COL_GRID_SIZES}>
            <FieldLabelWrapper>
              <StyledAdditionalFieldLabel
                variant="h6"
                color={colorsTheme.colorWhite}
                weight="semi-bold"
              >
                {e.name}
              </StyledAdditionalFieldLabel>
              {!!e.options.documentRequiresCertification && (
                <CertificationRequiredLabel />
              )}
            </FieldLabelWrapper>
            <DescriptionWithLinks description={e.description} />
          </Col>

          <Col {...TWO_COL_GRID_SIZES}>
            {e.review ? <DocumentReviewStatus reviewResult={e.review} /> : null}
            {!e.options.onboardingRequired && (
              <FormField
                name={`additionalFields.${i}.isNotProvidedReason`}
                component={Checkbox}
                shouldShowErrorMessage={false}
                disabled={e.review?.isValid}
                additionalProps={{
                  field: {
                    type: e.type,
                    options: e.options.selectOptions,
                    canProvideReason: !e.options.onboardingRequired,
                  },
                  children: t(
                    'application_documentation.not_applicable_or_available',
                  ),
                }}
              />
            )}
            {e.isNotProvidedReason ? (
              <FormField
                name={`additionalFields.${i}.notProvidedReason`}
                disabled={e.review?.isValid}
                component={FormInput}
              />
            ) : (
              <FormField
                name={`additionalFields.${i}.value`}
                component={AdditionalField}
                disabled={e.review?.isValid}
                placeholder={t(
                  'application_documentation.additional_document_field_placeholder',
                  {
                    name: e.name,
                  },
                )}
                additionalProps={{
                  field: {
                    type: e.type,
                    tags: [e.name],
                    options: e.options.selectOptions,
                    canProvideReason: !e.options.onboardingRequired,
                    formDocument: e.options.formDocument,
                    documentAssociation: values.documentsAssociation,
                  },
                }}
              />
            )}
          </Col>
        </StyledRow>
      ))}
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const FieldLabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledAdditionalFieldLabel = styled(Text)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default AdditionalFieldsSection;
