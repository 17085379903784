// ----- Timeouts & Delays -----
export const REQ_TIMEOUT = 20000;
export const NOTIFICATION_DURATION = 5;
export const DOCUMENT_UPLOAD_TIMEOUT = 30000;
export const SMALL_NOTIFICATION_DURATION = 1;
export const AUTOCOMPLETE_DEBOUNCE_DELAY = 300;

// ------ Limits -------
export const LIST_RESULT_LIMIT = 50;
export const DEFAULT_TABLE_LIMIT = 10;
export const AUTOCOMPLETE_RESULT_LIMIT = 25;
export const MESSAGES_RESULT_LIMIT = 50;

// ------ Formatting constants ---------
export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FROM_DATE_FORMAT = 'hh:mm A';
export const DEFAULT_FULL_DATE_FORMAT = 'DD MMMM YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm';
export const DEFAULT_FULL_DATE_TIME_FORMAT = 'DD MMMM YYYY, HH:mm A';
export const DEFAULT_DATE_TIME_FORMAT_UTC = 'DD MMM YYYY, HH:mm UTC';

// ------ Validation ---------
export const CONFIRMATION_REASON_MAX_LENGTH = 1000;

export const NAME_MIN_LENGTH = 1;
export const NAME_MAX_LENGTH = 50;
export const CRM_CONTACT_NAME_MAX_LENGTH = 40;

export const DESCRIPTION_MIN_LENGTH = 3;
export const TRANSFER_REFERENCE_MAX_LENGTH = 140;
export const DESCRIPTION_MAX_LENGTH = 2000;

export const BIG_DESCRIPTION_MAX_LENGTH = 10000;
