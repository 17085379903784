import moment from 'moment';
import { DEFAULT_TIMEOUT } from '../constants/onboarding';
import { useEffect, useState } from 'react';

const useTimer = (sendDate?: Date, timeout: number = DEFAULT_TIMEOUT) => {
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [startTimeoutDate, setStartTimeoutDate] = useState<Date | undefined>();

  useEffect(() => {
    if (sendDate) {
      startTimer(sendDate);
    }
  }, [sendDate]);

  useEffect(() => {
    if (startTimeoutDate) {
      const timeLeft = timeout - moment(new Date()).diff(startTimeoutDate);
      setSecondsLeft(timeLeft > 0 ? Math.ceil(timeLeft / 1000) : 0);
    }
  }, [startTimeoutDate]);

  useEffect(() => {
    clearTimer();

    if (secondsLeft > 0) {
      setTimerId(
        setTimeout(() => {
          setSecondsLeft(secondsLeft - 1);
        }, 1000),
      );
    }

    return () => {
      clearTimer();
    };
  }, [secondsLeft]);

  const clearTimer = () => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }
  };

  const startTimer = (date?: Date) => {
    setStartTimeoutDate(date ? date : new Date());
    setSecondsLeft(0);
  };

  return { secondsLeft, startTimer, clearTimer };
};

export default useTimer;
