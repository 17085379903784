import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { DateHelpers } from 'helpers/date';
import { colorsTheme } from 'resources/theme/styled/colors';
import { RequestModel } from '..';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { RequestStatuses, RequestTypes } from 'enums/connections/connections';

// components
import Text from '@core_components/Text';
import RequestCard from 'components/Additional/Requests/RequestCard';
import RequestStatus from 'components/Additional/Statuses/RequestStatus';
import ViewHandoffRequest from './ViewHandoffRequest';
import ViewClientGroupRequest from './ViewClientGroupRequest';
import { Trans } from 'react-i18next';
import { ReactComponent as ShareIcon } from 'resources/icons/remix-icons/user-shared-line.svg';

interface IProps {
  data: RequestModel;
  updateRequests: () => void;
}

const Request = memo(({ data, updateRequests }: IProps) => {
  const { t } = useTranslation('connections');

  const component = useMemo(() => {
    switch (data.type) {
      case RequestTypes.AcceleratedDueDiligence:
        return (
          <RequestCard
            icon={ShareIcon}
            title={
              <Trans
                t={t}
                values={{ requestName: data.name }}
                i18nKey="requests.share_contact_request.title"
                components={[
                  <Text
                    key={data.name}
                    variant="span"
                    weight="bold"
                    color={colorsTheme.colorWhite}
                  />,
                ]}
              />
            }
            type={t(`requests.request_types.${data.type}`)}
            status={<RequestStatus key={data.status} status={data.status} />}
            requestedAt={DateHelpers.formatDateToString(
              data.requestedAt,
              DEFAULT_DATE_TIME_FORMAT,
            )}
            actions={
              data.status == RequestStatuses.Pending ? (
                <ViewClientGroupRequest
                  data={data}
                  updateRequests={updateRequests}
                />
              ) : undefined
            }
          />
        );

      case RequestTypes.HandoffApplication:
        return (
          <RequestCard
            icon={ShareIcon}
            title={
              <Trans
                t={t}
                values={{ requestName: data.name }}
                i18nKey="requests.handoff_application_request.title"
                components={[
                  <Text
                    key={data.name}
                    variant="span"
                    weight="bold"
                    color={colorsTheme.colorWhite}
                  />,
                ]}
              />
            }
            type={t(`requests.request_types.${data.type}`)}
            status={<RequestStatus key={data.status} status={data.status} />}
            requestedAt={DateHelpers.formatDateToString(
              data.requestedAt,
              DEFAULT_DATE_TIME_FORMAT,
            )}
            actions={
              data.status == RequestStatuses.Pending ? (
                <ViewHandoffRequest
                  data={data}
                  updateRequests={updateRequests}
                />
              ) : undefined
            }
          />
        );

      default:
        return null;
    }
  }, [data, updateRequests]);

  return component;
});

export default Request;
