import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../../../resources/theme/styled/colors';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import Text from '@core_components/Text';
import Button from '../../../../../../DesignSystem/Core/Button';
import IconSVG from '../../../../../../DesignSystem/Core/IconSVG';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import { ReactComponent as CheckIcon } from '../../../../../../../resources/icons/remix-icons/check-line.svg';

const SetupNameForKeyStep = () => {
  const { t } = useTranslation(['profile', 'common']);
  const { submitForm } = useFormikContext<FormValuesModel>();

  return (
    <>
      <IconSVG
        component={CheckIcon}
        size="large"
        color={colorsTheme.colorPrimary}
      />

      <StyledText variant="h5" color={colorsTheme.colorSuccess} gutterBottom>
        {t('success', { ns: 'common' })}
      </StyledText>

      <FormField
        name="name"
        component={FormInput}
        label={t(
          'security.two_step_verification.security_key.register_new_key.setup_name_key.form_fields.name',
        )}
      />

      <Button size="large" onClick={submitForm}>
        {t('save', { ns: 'common' })}
      </Button>
    </>
  );
};

const StyledText = styled(Text)`
  text-align: center;
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default SetupNameForKeyStep;
