import { ApprovalRuleMatrixFormValuesModel } from '../../modules/AccountManagement/AccountManagementRules/ApprovalRulesTab/TransfersApprovalSection/ApprovalRuleMatrix';
import {
  ApprovalEntryChangesModel,
  ApprovalEntryModel,
  ApprovalEntryNewModel,
  ApprovalProgressStateModel,
  ApprovalRuleSetBaseModel,
  ApprovalRuleSetRuleModel,
  ApprovalRuleSetThresholdModel,
  ApprovalRulesTemplateBaseModel,
  ApprovalRulesTemplateModel,
} from '../../typings/approvalManagement/accountManagement';
import { generateUniqId } from 'helpers/utils';
import { BankAccountTemplateData } from '../../modules/AccountManagement/AccountManagementRules/ApprovalsTab/ApprovalEntryModal/InnerContent/ChangeDifferenceSection/Templates/BankAccount';
import { ApprovalGroupTemplateData } from '../../modules/AccountManagement/AccountManagementRules/ApprovalsTab/ApprovalEntryModal/InnerContent/ChangeDifferenceSection/Templates/ApprovalGroup';
import { ApprovalWorkflowEntryTypes } from '../../enums/approvalManagement/approvalManagement';
import { TransactionRuleTemplateData } from '../../modules/AccountManagement/AccountManagementRules/ApprovalsTab/ApprovalEntryModal/InnerContent/ChangeDifferenceSection/Templates/TransactionRule';
import { ClientGroupTemplateDataModel } from '../../modules/AccountManagement/AccountManagementRules/ApprovalsTab/ApprovalEntryModal/InnerContent/ChangeDifferenceSection/Templates/ClientGroup';
import { CircleServiceTemplateDataModel } from '../../modules/AccountManagement/AccountManagementRules/ApprovalsTab/ApprovalEntryModal/InnerContent/ChangeDifferenceSection/Templates/CirlceService';
import { AccountManagementApprovalRuleTemplateData } from '../../modules/AccountManagement/AccountManagementRules/ApprovalsTab/ApprovalEntryModal/InnerContent/ChangeDifferenceSection/Templates/AccountManagementApprovalRule';

interface ApprovalWorkflowResponse extends ApprovalEntryModel {
  approvalProgressStateSets: ApprovalProgressStateModel[];
  entryData: string;
}

const accountManagementRulesAdapter = {
  createInitialApprovalRulesTemplate: (
    formValues: ApprovalRuleMatrixFormValuesModel,
  ): ApprovalRulesTemplateBaseModel => {
    const result = formatApprovalTemplate(formValues);
    return result;
  },

  updateApprovalRulesTemplate: (
    formValues: ApprovalRuleMatrixFormValuesModel,
  ): ApprovalRulesTemplateModel => {
    const result = formatApprovalTemplate(
      formValues,
    ) as ApprovalRulesTemplateModel;
    return result;
  },

  getTemplateApprovalRules: (template: ApprovalRulesTemplateModel) => {
    let result: ApprovalRuleMatrixFormValuesModel | null = null;

    if (template) {
      result = {
        id: template.id,
        isActive: template.IsActive,
        approvalRuleType: 'account-management',
        approvalConditions: template.approvalRuleSets.map((e) => {
          const actionStatuses = getActionsStatuses(e);
          return {
            actionStatuses,
            approvalGroupConditions: e.approvalRuleItems.map((condition) => ({
              customUniqId: generateUniqId(),
              approvalGroupId: condition.approvalGroup.id,
              requiredApprovers: condition.minimunApprovalThreashold,
              // TODO: set number of members in approval group
              maximumRequiredApprovers: condition.minimunApprovalThreashold,
            })),
          };
        }),
      };
    }

    return result;
  },

  getApprovalWorkflow: (
    workflow: ApprovalWorkflowResponse,
  ): ApprovalEntryNewModel => {
    const entry = {
      id: workflow.id,
      type: workflow.entryType,
      createdAt: workflow.createdAt,
      status: workflow.status,
      templateCode: workflow.templateCode,
      changeType: workflow.value,
    };

    const data = JSON.parse(workflow.entryData);
    let changes: ApprovalEntryChangesModel = {
      old: null,
      new: null,
    };

    switch (entry.type) {
      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_GROUP:
      case ApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_GROUP:
        {
          changes = {
            old: data.before
              ? {
                  groupName: data.before.name,
                  participants: data.before.participants?.length
                    ? data.before.participants
                        .map((e: any) => e.userFullName)
                        .join(', ')
                    : '',
                  description: data.before.description,
                }
              : null,
            new: data.after
              ? {
                  groupName: data.after.name,
                  participants: data.after.participants?.length
                    ? data.after.participants
                        .map((e: any) => e.userFullName)
                        .join(', ')
                    : '',
                  description: data.after.description,
                }
              : null,
          } as ApprovalEntryChangesModel<ApprovalGroupTemplateData>;
        }
        break;

      case ApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_RULE:
        {
          changes = {
            old: data.before
              ? {
                  id: data.before.id,
                  accounts: data.before.accounts,
                  approvalRuleSets: data.before.approvalRuleSets,
                }
              : null,
            new: data.after
              ? {
                  id: data.after.id,
                  accounts: data.after.accounts,
                  approvalRuleSets: data.after.approvalRuleSets,
                }
              : null,
          } as ApprovalEntryChangesModel<TransactionRuleTemplateData>;
        }
        break;

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_RULE:
        {
          changes = {
            old: data.before
              ? {
                  approvalRule: {
                    approvalRuleSets: data.before.approvalRuleSets,
                  },
                }
              : null,
            new: data.after
              ? {
                  approvalRule: {
                    approvalRuleSets: data.after.approvalRuleSets,
                  },
                }
              : null,
          } as ApprovalEntryChangesModel<AccountManagementApprovalRuleTemplateData>;
        }
        break;

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_NEW_BANKACCOUNT:
        {
          // TODO: backend return wrong model without before and after
          changes = {
            old: null,
            new: {
              bankAccount: {
                accountCurrency: data.accountCurrency,
                accountName: data.accountName,
                accountNumber: data.accountNumber,
              },
            },
          } as ApprovalEntryChangesModel<BankAccountTemplateData>;
        }
        break;

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_UPDATE_CLIENTGROUP:
        {
          changes = {
            old: null,
            new: {
              approvedRelationshipScopeId: data.approvedRelationshipScopeId,
              clientGroupId: data.clientGroupId,
              relationshipScopeId: data.relationshipScopeId,
              clientGroupUserChanges: data.clientGroupUserChanges || [],
            },
          } as ApprovalEntryChangesModel<ClientGroupTemplateDataModel>;
        }
        break;

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_CIRCLE_INTEGRATION:
        {
          changes = {
            old: null,
            new: {
              connected: true,
            },
          } as ApprovalEntryChangesModel<CircleServiceTemplateDataModel>;
        }
        break;
    }

    return {
      entry,
      approvalProgressStateSets: workflow.approvalProgressStateSets,
      changes,
    };
  },
};

// TODO: rework part related to the threasholds
const formatApprovalTemplate = (
  formValues: ApprovalRuleMatrixFormValuesModel,
) => {
  const approvalRuleSets: ApprovalRuleSetBaseModel[] =
    formValues.approvalConditions.map((e) => {
      const approvalThreasholds: ApprovalRuleSetThresholdModel[] = Object.keys(
        e.actionStatuses,
      ).map((key: any) => ({
        threasholdMin: +key,
        threasholdMax: +key,
        ticked: e.actionStatuses[key] as boolean,
      }));

      const approvalRuleItems: ApprovalRuleSetRuleModel[] =
        e.approvalGroupConditions.map((condition) => ({
          minimunApprovalThreashold: condition.requiredApprovers as number,
          approvalGroup: { id: condition.approvalGroupId as string },
        }));

      return { approvalThreasholds, approvalRuleItems };
    });

  return { id: formValues.id || undefined, approvalRuleSets };
};

const getActionsStatuses = (approvalRuleSet: ApprovalRuleSetBaseModel) => {
  const result: any = {};
  const { approvalThreasholds } = approvalRuleSet;

  approvalThreasholds.forEach(({ threasholdMin, ticked }) => {
    result[threasholdMin] = ticked;
  });

  return result;
};

export { accountManagementRulesAdapter };
