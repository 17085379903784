import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { BrowserHelpers } from '../../../../../../helpers/browser';
import {
  AssertionForLoginModel,
  GetChallengeForLoginResponseModel,
} from '../../../../../../typings/profile/fido';

// components
import Text from '@core_components/Text';
import Content from './Content';
import BrowserIsNotSupported from '../../../../../Additional/Auth/TwoFactorVerification/BrowserIsNotSupported';

interface IProps {
  onAssert: () => void;
  getChallenge: () => Promise<GetChallengeForLoginResponseModel>;
  submitAssertion: (assertion: AssertionForLoginModel) => Promise<any>;
}

const SignChallenge = ({ getChallenge, submitAssertion, onAssert }: IProps) => {
  const canUseWebAuthn = BrowserHelpers.isWebAuthnSupported();
  const { t } = useTranslation('profile');
  const { response: challenge, loading } = useFetch(
    () => (canUseWebAuthn ? getChallenge() : null),
    [getChallenge],
  );

  return (
    <>
      <StyledText
        variant="h5"
        weight="semi-bold"
        color={colorsTheme.colorWhite}
      >
        {t(
          'security.two_step_verification.security_key.register_new_key.register_key_step.title',
        )}
      </StyledText>

      {canUseWebAuthn ? (
        <Content
          loadingChallenge={loading}
          challenge={challenge}
          onAssert={onAssert}
          submitAssertion={submitAssertion}
        />
      ) : (
        <BrowserIsNotSupported />
      )}
    </>
  );
};

const StyledText = styled(Text)`
  text-align: center;
`;

export default SignChallenge;
