import React, { useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { useFormikContext } from 'formik';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { DISABLED_FORM_STATUS } from 'constants/form';

// components
import Text from '@core_components/Text';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import UpdateApprovalGroup from './UpdateApprovalGroup';
import CreateApprovalGroup from './CreateApprovalGroup';
import { Col, Row } from 'antd';
import OnboardingApprovalGroupsTable, {
  TableRecord,
  TableActions,
} from 'components/Tables/TableTemplates/AccountManagement/OnboardingApprovalGroupsTable';

interface IProps {
  onAction: () => void;
}

const ApprovalGroups = ({ onAction }: IProps) => {
  const { t } = useTranslation(['onboarding', 'server_errors']);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [updateTableTrigger, setUpdateTableTrigger] = useState({});
  const { status } = useFormikContext();
  const isFormDisabled = status === DISABLED_FORM_STATUS;

  const { response, loading } = useFetch(async () => {
    const data = await onboardingAPI.fetchApprovalGroups(
      {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      },
      'onboarding-transaction',
    );

    const formattedData: TableRecord[] = data.data.map((e) => ({
      id: e._id,
      name: e.name,
      description: e.description,
      members: e.participants
        ? e.participants.map((p) => ({ id: p.contactId, name: p.name }))
        : [],
      errors: e.validationErrors.length
        ? e.validationErrors.map((e) => t(e.code + '', { ns: 'server_errors' }))
        : [],
    }));

    return {
      total: data.total,
      data: formattedData,
    };
  }, [currentPage, updateTableTrigger]);

  const handleDataUpdate = () => {
    setUpdateTableTrigger({});
    onAction();
  };

  const handleActionsClickCallback = (
    key: TableActions,
    record: TableRecord,
  ) => {
    switch (key) {
      case 'view':
        setSelectedGroupId(record.id);
        break;
    }
  };

  const handleCloseCallback = (wasModified?: boolean) => {
    setSelectedGroupId(null);
    wasModified && handleDataUpdate();
  };

  return (
    <LoadingWrapper loading={!response}>
      <Text
        weight="semi-bold"
        color={colorsTheme.colorWhite}
        variant="h5"
        gutterBottom
      >
        {t('administration.transaction_rules.approval_groups.title')}
      </Text>
      <Text gutterBottom>
        {t('administration.transaction_rules.approval_groups.description')}
      </Text>
      {response && !response.data.length ? (
        <HideIfDisabledForm>
          <CreateApprovalGroup onAction={handleDataUpdate} />
        </HideIfDisabledForm>
      ) : (
        <>
          <HideIfDisabledForm>
            <Row justify="end">
              <Col>
                <CreateApprovalGroup onAction={handleDataUpdate} />
              </Col>
            </Row>
          </HideIfDisabledForm>
          <OnboardingApprovalGroupsTable
            data={response?.data || []}
            total={response?.total || 0}
            current={currentPage}
            loading={loading}
            onPaginationChange={setCurrentPage}
            onActionsClick={
              !isFormDisabled ? handleActionsClickCallback : undefined
            }
          />
          <UpdateApprovalGroup
            isVisible={!!selectedGroupId}
            approvalGroupId={selectedGroupId}
            closeCallback={handleCloseCallback}
          />
        </>
      )}
    </LoadingWrapper>
  );
};

export default ApprovalGroups;
