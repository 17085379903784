export enum AccountRecoveryRequestStatuses {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
}

export enum VerificationDocumentTypes {
  Passport = 'passport',
}

export enum UploadDocumentMethods {
  Webcam = 'webcam',
  Upload = 'upload',
}

export enum VerificationDocumentPhotoTypes {
  Front = 'Front',
  Back = 'Back',
  Selfie = 'Selfie',
}

export enum ACCOUNT_RECOVERY_STATUS {
  UserNotFound = 'user_does_not_exist',
  Verifying = 'verifying',
  Ok = 'ok',
}
