import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { ColumnsType } from 'antd/lib/table';
import { generateUniqId } from 'helpers/utils';
import { useFormikContext } from 'formik';
import { ApprovalRuleType } from 'components/Forms/FormComponents/Autocompletes/AccountManagement/ApprovalGroupsAutocomplete';
import { AutocompleteOption } from '@core_components/Autocomplete';
import {
  ApprovalRuleTriggerTypeModel,
  IApprovalGroupShort,
} from 'typings/approvalManagement/accountManagement';

// components
import Text from '@core_components/Text';
import ContentCell from './ContentCell';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import { Button, Table } from 'antd';

export interface FormValuesModel {
  approvalRuleType: ApprovalRuleType;
  approvalConditions: ApprovalRuleEntryModel[];
}

export type ApprovalRuleEntryModel = {
  approvalGroupConditions: ApprovalGroupConditionModel[];
  actionStatuses: { [key: number]: boolean };
};

export interface ApprovalGroupConditionModel {
  customUniqId: string;
  approvalGroupId: string | null;
  requiredApprovers: number | null;
  maximumRequiredApprovers: number;
  initialAutocompleteValue?: AutocompleteOption<IApprovalGroupShort>;
}

export enum ApprovalTemplateColumns {
  ApprovalGroupCondition = 'approval-group-condition',
  ApprovalRuleTriggerTypes = 'approval-trigger-types',
}

export interface TableColumnModel {
  width: string;
  title: React.ReactNode | string;
  key: string;
  dataIndex: ApprovalTemplateColumns;
  approvalTriggerId?: number;
}

interface IProps {
  triggerTypes: ApprovalRuleTriggerTypeModel[];
}

const AccountManagementRulesMatrixForm = ({ triggerTypes }: IProps) => {
  const { t } = useTranslation('account_management');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const tableComponents = useMemo(() => ({ body: { cell: ContentCell } }), []);

  const handleOnAddORClick = () => {
    const approvalRulesCopy = values.approvalConditions.slice();
    const actionStatuses: { [key: number]: boolean } = {};

    triggerTypes.forEach(({ id }) => {
      actionStatuses[id] = true;
    });

    const initialApprovalRule: ApprovalRuleEntryModel = {
      approvalGroupConditions: [
        {
          customUniqId: generateUniqId(),
          approvalGroupId: null,
          requiredApprovers: null,
          maximumRequiredApprovers: 0,
        },
      ],
      actionStatuses,
    };

    approvalRulesCopy.push(initialApprovalRule);
    setFieldValue('approvalConditions', approvalRulesCopy);
  };

  const tableColumns = useMemo(() => {
    const initialColumns: TableColumnModel[] = [
      {
        key: 'approval-group-condition',
        width: '40%',
        title: t(
          'approval_rules.account_management.form_fields.approval_group_condition',
        ),
        dataIndex: ApprovalTemplateColumns.ApprovalGroupCondition,
      },
    ];
    const approvalTriggerTypes: TableColumnModel[] = triggerTypes.map((e) => ({
      key: `action-type-${e.name}`,
      width: '20%',
      title: (
        <StyledText weight="semi-bold">
          {t(`approval_rules.account_management.types.${e.name}`)}
        </StyledText>
      ),
      dataIndex: ApprovalTemplateColumns.ApprovalRuleTriggerTypes,
      approvalTriggerId: e.id,
    }));
    initialColumns.push(...approvalTriggerTypes);
    return initialColumns.map((col, index: number) => ({
      ...col,
      onCell: (record: ApprovalRuleEntryModel, rowIndex: number) => ({
        title: col.title,
        dataIndex: col.dataIndex,
        colIndex: index > 0 ? index - 1 : 0,
        record,
        rowIndex,
        approvalTriggerId: col.approvalTriggerId || null,
      }),
    }));
  }, [triggerTypes]);

  return (
    <>
      <Table
        bordered
        size="large"
        scroll={{ x: 'max-content' }}
        columns={tableColumns as ColumnsType<ApprovalRuleEntryModel>}
        pagination={false}
        className="disable-table-row-hover"
        dataSource={values.approvalConditions}
        components={tableComponents}
      />
      <HideIfDisabledForm>
        <ButtonsWrapper>
          <Button onClick={handleOnAddORClick}>
            {t('approval_rules.add_or_button')}
          </Button>
        </ButtonsWrapper>
      </HideIfDisabledForm>
    </>
  );
};

const ButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
  display: flex;
  justify-content: flex-start;
`;

const StyledText = styled(Text)`
  text-align: center;
  text-transform: uppercase;
`;

export default AccountManagementRulesMatrixForm;
