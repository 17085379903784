import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { applicationsAdapter } from '../../apiAdapters/application/applicationsAdapter';
import { FetchParamsModel, FetchResponseModel } from 'typings/common';
import {
  IApplication,
  ISelectApplicationModel,
} from '../../typings/application/applications';

interface ISelectApplicationBody {
  applicationId: string;
}

const applicationsAPI = {
  selectApplication: (body: ISelectApplicationBody) => {
    return APIService.post<ISelectApplicationModel>(
      `${APIConfig.crmApi}/applications/select`,
      body,
      {
        addRefreshTokenToHeader: true,
      },
    ).then(({ data }) => data);
  },

  fetchActiveApplication: () => {
    return APIService.get<IApplication>(
      `${APIConfig.crmApi}/applications/active`,
    ).then<IApplication>(({ data }) =>
      applicationsAdapter.getApplication(data),
    );
  },

  fetchApplications: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IApplication>>(
      `${APIConfig.crmApi}/applications`,
      { params },
    ).then((response) => applicationsAdapter.fetchApplications(response.data));
  },
};

export { applicationsAPI };
