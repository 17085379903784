import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { IClientGroupUser } from '../../typings/approvalManagement/accountManagement';
import { userManagementAdapter } from '../../apiAdapters/accountManagement/userManagementAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

export interface IEnableUserBody {
  callbackUrl: string;
  parameterName: string;
}

const userManagementAPI = {
  fetchUsers: (clientGroupId: string, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IClientGroupUser>>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/users`,
      {
        params,
      },
    ).then(({ data }) => userManagementAdapter.fetchUsers(data));
  },

  enableUser: (
    clientGroupId: string,
    contactId: string,
    body: IEnableUserBody,
  ) => {
    return APIService.patch(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/users/${contactId}/enable`,
      body,
    ).then(({ data }) => data);
  },

  deactivateUser: (clientGroupId: string, contactId: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/users/${contactId}`,
    ).then(({ data }) => data);
  },
};

export { userManagementAPI };
