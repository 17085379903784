import thunk from 'redux-thunk';
import rootReducer, { StateModel } from '../reducers';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, Store, Action } from 'redux';

// Activate Redux DevTools only in dev mode
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const logger = createLogger();
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

export function configureStore(initialState: StateModel): Store<
  StateModel,
  Action
> & {
  dispatch: unknown;
} {
  let middleware = null;

  // Add logger only for dev env
  if (process.env.REACT_APP_STAGE === 'local') {
    middleware = [logger, thunk, routeMiddleware];
  } else {
    middleware = [thunk, routeMiddleware];
  }

  const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...(middleware as any))),
  );

  return store;
}

export { history };
