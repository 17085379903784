import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import TransferTemplateStatusSelect from 'modules/Transfers/Templates/TransferTemplatesTable/FIlterBar/TransferTemplateStatusSelect';
import { Col, Row } from 'antd';

export type FilterKeys = 'filter_by_status';

interface IProps {
  selectedStatus?: string;
  onFilterChange: (key: FilterKeys, value?: string) => void;
}

const FilterBar = ({ selectedStatus, onFilterChange }: IProps) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Col span={24}>
          <Text color={colorsTheme.colorWhite} variant="body1">
            {t('transfer_templates.filter_bar.status')}
          </Text>
        </Col>
        <Col span={24}>
          <TransferTemplateStatusSelect
            value={selectedStatus}
            onChange={(newValue) =>
              onFilterChange('filter_by_status', newValue)
            }
          />
        </Col>
      </Col>
    </Row>
  );
};
export default FilterBar;
