import { IEntity } from '../../typings/application/entity';
import { IApplication } from 'typings/application/applications';
import { ContactModel } from '../../typings/application/contact';
import { IClientGroup } from '../../typings/application/client-group';
import { FetchResponseModel } from 'typings/common';
import { OnboardingStatusModel } from '../../typings/onboarding/onboarding';
import {
  ClientGroupStatuses,
  OnboardingEntryTypes,
} from '../../enums/onboarding/crm';

export interface IApplicationResponse {
  _id: string;
  crmItemId: string;
  isPrimary: boolean;
  crmItemType: OnboardingEntryTypes;
  onboardingProcessId: string;
  model: ContactModel | IEntity;
  onboardingProcess: OnboardingStatusModel;
  clientGroup?: IClientGroup;
  status: ClientGroupStatuses;
  reviewStatus: string;
  _audit: {
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;
  };
}

const applicationsAdapter = {
  getApplication: (application: IApplication): any => {
    return {
      ...application,
      onboardingProcess: {
        ...application.onboardingProcess,
        item: application.model,
      },
    };
  },

  fetchApplications: (
    data: FetchResponseModel<IApplication>,
  ): FetchResponseModel<IApplication> => {
    return {
      data: data.data.map((e) => applicationsAdapter.getApplication(e)),
      total: data.total,
    };
  },
};

export { applicationsAdapter };
