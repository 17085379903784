import React, { useEffect } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useQueryParam from 'hooks/useQueryParam';
import { useNavigate } from 'react-router-dom';
import { transactionsAPI } from 'api/accounting/transactionsAPI';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { default as WireTransferForm } from '../../modules/Transfers/WireTransfer';

const WireTransfer = () => {
  const navigate = useNavigate();
  const transactionIdFromQuery = useQueryParam({ param: 'transactionId' });
  const journalEntryIdFromQuery = useQueryParam({ param: 'journalEntryId' });

  const { response, loading, error } = useFetch(
    () =>
      journalEntryIdFromQuery || transactionIdFromQuery
        ? transactionsAPI.fetchWireTransferPrefillData(
            journalEntryIdFromQuery,
            transactionIdFromQuery,
          )
        : null,
    [journalEntryIdFromQuery, transactionIdFromQuery],
  );

  useEffect(() => {
    if (journalEntryIdFromQuery || transactionIdFromQuery) {
      if (error || response) {
        navigate({ search: '' });
      }
    }
  }, [journalEntryIdFromQuery, transactionIdFromQuery, error, response]);

  return (
    <LoadingWrapper loading={loading}>
      <WireTransferForm prefillData={response} />
    </LoadingWrapper>
  );
};

export default WireTransfer;
