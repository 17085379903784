import React, { useEffect } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import useTranslation from '../../hooks/useTranslation';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import Text from '@core_components/Text';
import Tabs from '../../components/Tabs/Tabs';
import ApprovalRulesTab from '../../modules/AccountManagement/TransactionRules/ApprovalRulesTab';
import ApprovalGroupsTab from '../../modules/AccountManagement/TransactionRules/ApprovalGroupsTab';

const TransactionRules = () => {
  const { t } = useTranslation('account_management');
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      content: <Text>{t('transaction_rules.description')}</Text>,
    }));

    return () => clearContext();
  }, [setPageLayoutOptions, clearContext]);

  const [hasApprovalGroupsViewAccess, hasApprovalRulesViewAccess] =
    useUserAccess([
      'AccountManagement_TransactionRules_ApprovalGroups_View',
      'AccountManagement_TransactionRules_ApprovalRules_View',
    ]);

  const tabs = [
    {
      key: 'approval-groups',
      title: t('approval_groups.title'),
      content: <ApprovalGroupsTab />,
      hidden: !hasApprovalGroupsViewAccess,
    },
    {
      key: 'approval-rules',
      title: t('approval_rules.title'),
      content: <ApprovalRulesTab />,
      hidden: !hasApprovalRulesViewAccess,
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default TransactionRules;
