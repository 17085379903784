import React from 'react';

// components
import RelationshipsSection from './RelationshipsSection';
import ContactShortInfoForm from '../../../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';

const FromNewContact = () => {
  return (
    <>
      <ContactShortInfoForm namePrefix="contact" />
      <RelationshipsSection />
    </>
  );
};

export default FromNewContact;
