import React from 'react';

// helpers
import { industryCodesAPI } from 'api/crm/industryCodesAPI';
import { AutocompleteProps } from '@core_components/Autocomplete';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export type IProps = AutocompleteProps;

const IndustryCodesAutocomplete = (props: IProps) => {
  const fetchIndustryCodes = async (searchText: string) => {
    const { data } = await industryCodesAPI.fetchIndustryCodes({
      search: searchText.trim(),
      limit: -1,
    });

    return data.map((industryCode) => ({
      id: industryCode.code,
      label: industryCode.code,
      model: industryCode,
    }));
  };

  return <FormAutocomplete {...props} fetchData={fetchIndustryCodes} />;
};

export default IndustryCodesAutocomplete;
