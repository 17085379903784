import React, { useState, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { colorsTheme } from '../../../../resources/theme/styled/colors';
import { useSelector } from 'react-redux';
import { StateModel as UploadStateModel } from '../../../../redux/reducers/upload';

// constants
import {
  ALLOWED_UPLOAD_TYPES,
  UploadStatus,
} from '../../../../constants/documents';
import {
  IDocumentAssociationInfo,
  IDocumentFile,
} from '../../../../typings/documents/documents';

// components
import IconSVG from '../../../DesignSystem/Core/IconSVG';
import UploadDocumentButton from './UploadDocumentButton';
import Button, { ButtonSizes } from '../../../DesignSystem/Core/Button';
import { Col, Row, Select } from 'antd';
import { ReactComponent as UploadIcon } from '../../../../resources/icons/remix-icons/upload-2-line.svg';

interface IProps {
  name?: string;
  onChange: (value: ShortDocumentModel[]) => void;
  association: IDocumentAssociationInfo;
  size?: ButtonSizes;
  value?: ShortDocumentModel[];
  disabled?: boolean;
  placeholder?: string;
  tags?: string[];
}

export interface ShortDocumentModel {
  id: string;
  name: string;
  file?: IDocumentFile;
  fileId?: string;
}

interface LocalFieldValueModel {
  value: string;
  label: string;
}

const UploadDocumentField = ({
  name,
  value,
  onChange,
  disabled,
  association,
  tags = [],
  size = 'large',
  ...rest
}: IProps) => {
  const { t } = useTranslation(['documents', 'form']);

  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const [isLoading, setLoading] = useState(false);

  // This is local input value variable
  // we need to store it separate variable, because original value includes full infromation about document
  // but for Select field component we need to put only {value: document id, label: document name}[]
  const localFieldValue: LocalFieldValueModel[] | undefined = useMemo(
    () => getFormattedFieldValue(value),
    [value],
  );

  const handleUpload = (documents: ShortDocumentModel[]) => {
    const newValue = value ? [...value, ...documents] : documents;
    onChange(newValue);
  };

  // We need to format the original value (IDocumentBase) into the model that Select component supports (LocalFieldValueModel)
  function getFormattedFieldValue(value?: ShortDocumentModel[]) {
    let result = undefined;

    if (value && Array.isArray(value)) {
      result = value.map((e) => ({ value: e.id, label: e.name }));
    }

    return result;
  }

  const handleOnFieldChange = (newValue: LocalFieldValueModel[]) => {
    let result = undefined;

    const newValueIds = newValue.map((e) => e.value);

    result = value?.filter(({ id }) => newValueIds.includes(id));

    onChange(result || []);
  };

  const isUploadInProgress = () => {
    return uploadState.status === UploadStatus.ACTIVE && isLoading;
  };

  return (
    <>
      <StyledRow gutter={[0, 16]} justify="end">
        <StyledSelectCol xl={18} lg={18} md={16} sm={16} xs={24}>
          <StyledSelect
            {...rest}
            placeholder={t('select_document', { ns: 'form' })}
            labelInValue
            dropdownStyle={{ display: 'none' }}
            size={size}
            mode="tags"
            value={localFieldValue}
            onChange={handleOnFieldChange}
            disabled={disabled || isUploadInProgress()}
            onInputKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // Prevent entering event
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </StyledSelectCol>

        <StyledCol xl={6} lg={6} md={8} sm={8} xs={24}>
          <UploadDocumentButton
            name={name as string}
            association={association}
            tags={tags}
            accept={ALLOWED_UPLOAD_TYPES}
            setLoading={setLoading}
            onUpload={handleUpload}
          >
            <StyledButton
              size={size}
              disabled={disabled}
              loading={isUploadInProgress()}
            >
              <StyledIconSVG
                component={UploadIcon}
                color={colorsTheme.colorDark}
              />
              {t('upload_button')}
            </StyledButton>
          </UploadDocumentButton>
        </StyledCol>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: 0 !important;
`;

const StyledCol = styled(Col)`
  max-width: 200px;
`;

const StyledSelectCol = styled(Col)`
  max-width: 100%;
  flex: 1;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 200px;
  min-width: 0px;
  height: 100%;
  max-height: 40px;
  min-height: 40px;
  padding: 0px;
`;

// Hide blinking cursor, since the user cannot enter anything into this input field
const StyledSelect = styled(Select)<any>`
  input {
    color: transparent !important;
  }
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default UploadDocumentField;
