import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from '../../../../../resources/theme/styled/colors';
import { FormValuesModel } from '../../../../../components/ModalDialogs/TemplateModalDialogs/Profile/EnableAuthenticatorModal';

// components
import Text from '@core_components/Text';
import Button from '../../../../../components/DesignSystem/Core/Button';
import IconSVG from '../../../../../components/DesignSystem/Core/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import Default2FALabel from '../Default2FALabel';
import ManageAuthenticatorModal from './ManageAuthenticatorModal';
import EnableAuthenticatorModal from './EnableAuthenticatorModal';
import { Col, Row } from 'antd';
import { ReactComponent as SmartphoneIcon } from '../../../../../resources/icons/remix-icons/smartphone-line.svg';

interface IProps {
  isDefault: boolean;
}

const Authenticator = ({ isDefault }: IProps) => {
  const { t } = useTranslation('profile');

  const isAuthenticatorEnabled = useSelector<StateModel, boolean>(
    (state) => !!state.auth.profileData?.isTwoFactorAuthenticationEnabled,
  );
  const [isEnableAuthenticatorModal, setIsEnableAuthenticatorModal] =
    useState(false);
  const [isManageAuthenticatorVisible, setIsManageAuthenticatorVisible] =
    useState(false);

  const handleCloseManageAuthenticatorModal = () => {
    setIsManageAuthenticatorVisible(false);
  };

  const handleCloseEnableAuthenticatorModal = () => {
    setIsEnableAuthenticatorModal(false);
  };

  const initialFormValues: FormValuesModel = useMemo(
    () => ({
      code: null,
    }),
    [],
  );

  const renderButton = (isAuthenticatorEnabled?: boolean) => {
    return isAuthenticatorEnabled ? (
      <StyledButton
        size="large"
        type="bordered"
        onClick={() => setIsManageAuthenticatorVisible(true)}
      >
        {t('security.two_step_verification.manage_button')}
      </StyledButton>
    ) : (
      <StyledButton
        size="large"
        onClick={() => setIsEnableAuthenticatorModal(true)}
      >
        {t('security.two_step_verification.enable_button')}
      </StyledButton>
    );
  };

  return (
    <StyledRow gutter={[16, 0]}>
      <Col xl={18} lg={18} md={12} sm={24} xs={24}>
        <TitleWrapper>
          <StyledIconSVG
            component={SmartphoneIcon}
            color={colorsTheme.colorWhite}
            size="middle"
          />
          <TextWrapper>
            <Text
              variant="subtitle1"
              color={colorsTheme.colorWhite}
              weight="semi-bold"
            >
              <DivAlignCenter>
                {t('security.two_step_verification.authenticator.title')}{' '}
                {isDefault && <Default2FALabel />}
              </DivAlignCenter>
            </Text>
            <Text variant="body1">
              {t('security.two_step_verification.authenticator.description')}
            </Text>
          </TextWrapper>
        </TitleWrapper>
      </Col>

      <Col xl={6} lg={6} md={12} sm={24} xs={24}>
        {renderButton(isAuthenticatorEnabled)}
      </Col>
      <ManageAuthenticatorModal
        isVisible={isManageAuthenticatorVisible}
        closeCallback={handleCloseManageAuthenticatorModal}
      />
      <EnableAuthenticatorModal
        initialFormValues={initialFormValues}
        isVisible={isEnableAuthenticatorModal}
        closeCallback={handleCloseEnableAuthenticatorModal}
      />
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default Authenticator;
