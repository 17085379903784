import { DateHelpers } from 'helpers/date';
import { ExpiringDocument } from 'typings/application/applications';
import { SocialMediaTypes } from '../../enums/onboarding/crm';
import { ISocialMediaModel } from '../../typings/application/entity';
import {
  IInformationFilled,
  RangeOptionModel,
} from '../../typings/onboarding/onboarding';

const OnboardingHelpers = {
  getNumberOfInitialOnboardingStep: function (
    onboardingFilledInformation: IInformationFilled,
    stepKeys: Array<keyof IInformationFilled>,
  ): number {
    const index = stepKeys.findIndex(
      (key) => !onboardingFilledInformation[key],
    );
    return index !== -1 ? index : stepKeys.length - 1;
  },

  findSocialMediaByType: function (
    type: SocialMediaTypes,
    socialMedia: ISocialMediaModel[] = [],
  ) {
    const socialMediaItem = socialMedia.find((el) => el.type == type);
    return socialMediaItem?.path || '';
  },

  getKeyOfRangeByRangeOption: function (
    option: RangeOptionModel,
    options: Record<string, RangeOptionModel>,
  ) {
    const { min, max } = option;

    return Object.keys(options).find(
      (key) =>
        (options[key].min === min || (!options[key]?.min && !min)) &&
        (options[key]?.max === max || (!options[key]?.max && !max)),
    );
  },

  formatExpiringDocumentToPassportFormItemModel: function (
    document: ExpiringDocument,
  ) {
    return {
      _id: document._id,
      country: document.details.passportDetails.issuingCountry || '',
      number: document.details.passportDetails.number || '',
      issuedAt: document.details.passportDetails.issuedAt
        ? DateHelpers.formatDateToUTC(document.details.passportDetails.issuedAt)
        : null,
      expirationDate: document.expirationDate
        ? DateHelpers.formatDateToUTC(document.expirationDate)
        : null,
      review: document.review,
      document: document.details.passportDetails.documents.map((doc) => ({
        id: doc.id,
        name: doc.files[0].name,
        fileId: doc.files[0].id,
        file: null,
      })),
    };
  },
};

export { OnboardingHelpers };
