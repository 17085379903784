import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { TWO_COL_GRID_SIZES } from '../../../../../../constants/grids';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import SectionIntro from '../../../../../DesignSystem/Common/Texts/SectionIntro';
import CountrySelect from '../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import NumberOfOfficesSelect from '../../../../FormComponents/SelectInputs/CRM/NumberOfOfficesSelect';
import NumberOfEmployeesSelect from '../../../../FormComponents/SelectInputs/CRM/NumberOfEmployeesSelect';
import IndustryCodesAutocomplete from 'components/Forms/FormComponents/Autocompletes/CRM/IndustryCodesAutocomplete';
import { Col, Row } from 'antd';

// Icons
import WebsiteIcon from '../../../../../../resources/images/social-media-icons/website.png';
import TwitterIcon from '../../../../../../resources/images/social-media-icons/twitter.png';
import FacebookIcon from '../../../../../../resources/images/social-media-icons/facebook.png';
import LinkedInIcon from '../../../../../../resources/images/social-media-icons/linkedIn.png';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.entity_name',
            )}
            name="legalName"
            component={FormInput}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.trade_name',
            )}
            name="tradeName"
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.registration_number',
            )}
            name="registrationNumber"
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.date_of_incorporation',
            )}
            name="dateOfIncorporation"
            component={StyledDatePicker}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isAfter(new Date()),
            }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.country_of_incorporation',
            )}
            name="jurisdiction"
            component={CountrySelect}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            name="industryCodes"
            label={t(
              'applicant_information.form_fields.organization.industry_codes',
            )}
            component={StyledIndustryCodesSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>

        <Col span={24}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.nature_of_business',
            )}
            name="natureOfBusiness"
            component={FormInputTextArea}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.number_of_employees',
            )}
            name="numberOfEmployees"
            component={NumberOfEmployeesSelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'applicant_information.form_fields.organization.number_of_offices',
            )}
            name="numberOfOffices"
            component={NumberOfOfficesSelect}
          />
        </Col>
      </Row>

      <SectionIntro
        gutterTop
        title={t('applicant_information.social_media_section.title')}
        titleVariant="h4"
        description={t(
          'applicant_information.social_media_section.description',
        )}
      />
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <StyledSocialMediaIcon src={WebsiteIcon} alt="Website icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.websiteLink"
            component={FormInput}
            placeholder="https://my-website.com/"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <StyledSocialMediaIcon src={TwitterIcon} alt="Twitter icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.twitterLink"
            component={FormInput}
            placeholder="https://twitter.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <StyledSocialMediaIcon src={FacebookIcon} alt="Facebook icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.facebookLink"
            component={FormInput}
            placeholder="https://www.facebook.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <StyledSocialMediaIcon src={LinkedInIcon} alt="LinkedIn icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.linkedInLink"
            component={FormInput}
            placeholder="https://www.linkedin.com/in/username"
          />
        </Col>
      </Row>
    </>
  );
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledIndustryCodesSelect = styled(IndustryCodesAutocomplete)`
  width: 100%;
`;

const StyledSocialMediaIcon = styled.img`
  width: 40px;
  height: auto;
`;

export default InnerForm;
