import React from 'react';

// helpers
import { SendMessageValidationSchema } from '../../../../../validations/messaging';

// components
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import InnerForm, { FormValuesModel } from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  sendButtonTitle: string;
  categoryId?: number;
  subject?: string;
}

const SendMessageModal = ({
  title,
  sendButtonTitle,
  isVisible,
  categoryId,
  subject,
  closeCallback,
  onSubmit,
  initialValues,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={SendMessageValidationSchema}
      renderForm={
        <ModalDialog
          width="700px"
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{ text: sendButtonTitle }}
        >
          <InnerForm categoryId={categoryId} subject={subject} />
        </ModalDialog>
      }
    />
  );
};

export default SendMessageModal;
