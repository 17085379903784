import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { Moment } from 'moment';
import { FormikHelpers } from 'formik';
import { ShortDocumentModel } from '../../../FormComponents/UploadDocumentField';
import { wireTransferValidationSchema } from '../../../../../validations/accounting/transfers';
import {
  TransferSenderModel,
  WireTransferRecipientModel,
} from '../../../../../typings/accounting/transfer';

// components
import InnerForm from './InnerForm';
import SubmitButton from '../../../../Buttons/SubmitButton';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import { Col, Row } from 'antd';

export interface FormValuesModel {
  valueDate: Moment;
  amount: number | null;
  transactionPurpose: string;
  sender: TransferSenderModel;
  documents: ShortDocumentModel[];
  transactionCurrencyId: number | null;
  recipient: WireTransferRecipientModel;
}

interface IProps extends RequiredPropsForFormModel<FormValuesModel> {
  emptyTransactionFormValues: FormValuesModel;
}

const SubmitButtonGridSizes = { xl: 4, lg: 6, md: 8, sm: 24, xs: 24 };

const WireTransferForm = ({
  initialValues,
  onSubmit,
  disabled,
  emptyTransactionFormValues,
}: IProps) => {
  const { t } = useTranslation('common');

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    await onSubmit(values, helpers);
    if (emptyTransactionFormValues) {
      helpers.resetForm({
        values: emptyTransactionFormValues as FormValuesModel,
        errors: {},
      });
    }
  };

  return (
    <Form<FormValuesModel>
      validationSchema={wireTransferValidationSchema}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      confirmExitWithoutSaving
      disabled={disabled}
      renderForm={
        <>
          <InnerForm />
          <Row justify="end">
            <Col {...SubmitButtonGridSizes}>
              <StyledSubmitButton type="primary">
                {t('submit', { ns: 'common' })}
              </StyledSubmitButton>
            </Col>
          </Row>
        </>
      }
    />
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
  padding: 0px;
  min-width: 0px;
`;

export default WireTransferForm;
