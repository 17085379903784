import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { message } from 'antd';
import { getBadges } from 'redux/actions/app';
import { useDispatch } from 'react-redux';
import { approvalGroupsAPI } from '../../../../api/approvalManagement/approvalGroupsAPI';

// components
import ApprovalGroupModal, {
  ApprovalGroupFormValuesModel,
} from '../../../../components/ModalDialogs/TemplateModalDialogs/Approval/ApprovalGroupModal';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasCreated?: boolean) => void;
}

const AddApprovalGroupModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('account_management');
  const dispatch = useDispatch();

  const initialFormValues: ApprovalGroupFormValuesModel = {
    name: '',
    description: '',
    participants: [],
  };

  const handleFormSubmit = async (values: ApprovalGroupFormValuesModel) => {
    await approvalGroupsAPI.addTransactionApprovalGroup({
      name: values.name,
      description: values.description as string,
      participants: values.participants,
    });
    dispatch(getBadges());
    message.success(t('success_submit_for_account_management_review_message'));
  };

  return (
    <ApprovalGroupModal
      approvalWorkflowType="transactions"
      title={t('approval_groups.approval_group_modal.new.title')}
      onSubmit={handleFormSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default AddApprovalGroupModal;
