import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import relationshipsAPI from '../../../../../../../../../api/relationships/relationshipsAPI';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { message } from 'antd';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { IApplication } from 'typings/application/applications';
import { FormikHelpers } from 'formik';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { relationshipsAPIAdapter } from '../../../../../../../../../apiAdapters/relationships/relationshipsAPIAdapter';

// components
import AddNewNodeModalDialog, {
  FormValuesModel,
} from '../../../../../../../../../components/ModalDialogs/TemplateModalDialogs/Onboarding/AddNewNodeModalDialog';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasCreated?: boolean) => void;
  data: null | {
    clientGroupId: string;
    activeApplicationScopeId: string;
  };
}

const CreateRelationshipModal = ({
  isVisible,
  data,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation(['onboarding', 'custom_errors']);

  const activeApplication = useSelector<StateModel, IApplication | null>(
    (state) => state.applications.activeApplication,
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible || !data) {
      return null;
    }

    const { clientGroupId, activeApplicationScopeId } = data;

    return {
      clientGroupId,
      activeApplicationScopeId,

      template: {
        id: null,
        visibleFor: null,
      },

      contact: {
        isPermissionsSelectAvailable: true,
        firstName: '',
        lastName: '',
        middleName: '',
        isPEP: null,
        pepInformation: '',
        isOnlineUser: null,
        adminPermissionType: null,
        accountPermissions: null,
        phone: '',
        email: '',
      },

      clientGroupEntryType:
        activeApplication?.crmItemType as OnboardingEntryTypes,

      organization: null,

      canAddOwnerships: false,
      relationships: [],
    };
  }, [isVisible, data, activeApplication]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedRequestBody =
      relationshipsAPIAdapter.generateRequestBodyToCreateNode(values);
    await relationshipsAPI.updateRelationships(formattedRequestBody);
    message.success(t('structure.relationships.add_modal.success_add'));
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1001007': {
        formikHelpers.setFieldError(
          'contact.email',
          t('1001007', { ns: 'server_errors' }),
        );
        break;
      }

      case '1016010': {
        formikHelpers.setFieldError(
          'contact.email',
          t('1016010', { ns: 'server_errors' }),
        );
        break;
      }

      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <AddNewNodeModalDialog
      title={t('new_node_modal.title')}
      onSubmit={handleSubmit}
      onSubmitError={handleOnSubmitError}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default CreateRelationshipModal;
