import React, { ReactNode } from 'react';

// helpers
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { IGroupParticipantShort } from '../../../../../typings/approvalManagement/accountManagement';
import { ApprovalGroupValidationSchema } from '../../../../../validations/accountManagement/approvalGroups';

// components
import Button from '@core_components/Button';
import InnerForm from './InnerForm';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface ApprovalGroupFormValuesModel {
  name: string;
  disabled?: boolean;
  description: string | undefined;
  participants: IGroupParticipantShort[];
  initialParticipantOptions?: AutocompleteOption<IGroupParticipantShort>[];
}

export type ApprovalWorkflowType =
  | 'transactions'
  | 'account-management-transactions'
  | 'onboarding-approval-group';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<ApprovalGroupFormValuesModel> {
  title: ReactNode;
  approvalWorkflowType: ApprovalWorkflowType;
  isEditMode?: boolean;
  loadingInitialData?: boolean;
  onDelete?: (form: FormikProps<ApprovalGroupFormValuesModel>) => Promise<void>;
}

const ApprovalGroupModal = ({
  title,
  isVisible,
  closeCallback,
  initialValues,
  onSubmit,
  disabled,
  approvalWorkflowType,
  loadingInitialData,
  isEditMode,
  onDelete,
}: IProps) => {
  const { t } = useTranslation('common');
  return (
    <Form
      enableReinitialize
      disabled={disabled}
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={ApprovalGroupValidationSchema}
      renderForm={(form) => (
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          extendedFooterButtonsPosition={onDelete ? 'before' : undefined}
          extendedFooterButtons={
            onDelete ? (
              <Button
                danger
                size="large"
                type="bordered"
                onClick={() => onDelete(form)}
              >
                {t('delete', { ns: 'common' })}
              </Button>
            ) : null
          }
        >
          <LoadingWrapper loading={!!loadingInitialData}>
            <InnerForm
              approvalWorkflowType={approvalWorkflowType}
              isEditMode={isEditMode}
            />
          </LoadingWrapper>
        </ModalDialog>
      )}
    />
  );
};

export default ApprovalGroupModal;
