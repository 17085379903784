import React from 'react';

// helpers
import { userAPI } from '../../../../../../../api/profile/userAPI';
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import SignChallengeWithSecurityKeyModal from '../../../../../../../components/ModalDialogs/TemplateModalDialogs/UserProfile/SignChallengeWithSecurityKeyModal';

interface IProps {
  keyId: string | null;
  isVisible: boolean;
  closeCallback: (wasRemoved?: boolean) => void;
}

const DeleteSecurityKeyModal = ({
  keyId,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('profile');

  const getChallenge = () => {
    return userAPI.getChallengeForRemovingKey(keyId as string);
  };

  return (
    <SignChallengeWithSecurityKeyModal
      title={t('security.two_step_verification.security_key.remove_key.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      getChallenge={getChallenge}
      submitAssertion={(assertion) =>
        userAPI.removeFidoKey(keyId as string, assertion)
      }
    />
  );
};

export default DeleteSecurityKeyModal;
