import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ActionButtons, { ActionTypes } from './ActionButtons';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type ContactDetailsData = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isPep: boolean;
};

export interface FormValuesModel {
  actionType: ActionTypes | null;
  data: {
    newContact: ContactDetailsData;
    existingContact: ContactDetailsData;
    clientGroupName: string;
  };
}

export interface IProps
  extends RequiredPropsForFormModel<FormValuesModel>,
    RequiredPropsForModalDialogModel {}

const ShareContactRequestModal = memo(
  ({ onSubmit, closeCallback, isVisible, initialValues }: IProps) => {
    const { t } = useTranslation('connections');

    return (
      <Form<FormValuesModel>
        disabled
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={isVisible ? initialValues : null}
        renderForm={
          <ModalDialog
            width="1200px"
            title={t('requests.view_request_modal.share_contact_request.title')}
            isVisible={isVisible}
            submitButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            closeCallback={closeCallback}
            extendedFooterButtonsPosition="before"
            extendedFooterButtons={<ActionButtons />}
            hideFooterButtons={!initialValues}
          >
            <LoadingWrapper loading={!initialValues}>
              <InnerForm />
            </LoadingWrapper>
          </ModalDialog>
        }
      />
    );
  },
);

export default ShareContactRequestModal;
