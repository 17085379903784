import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { AccountProcessingTypes } from '../../../../../../enums/accounting/transfers';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface IProps extends SelectProps {
  hideBlockchainOption?: boolean;
  hideFiatAccountOption?: boolean;
}

const BankAccountProcessingType = ({
  hideBlockchainOption,
  hideFiatAccountOption,
  ...rest
}: IProps) => {
  const { t } = useTranslation('accounts');

  const options = useMemo<SelectOption[]>(() => {
    return [
      {
        id: AccountProcessingTypes.Circle,
        label: t(`account_processing_types.${AccountProcessingTypes.Circle}`),
        hidden: hideBlockchainOption,
      },

      {
        id: AccountProcessingTypes.Native,
        label: t(`account_processing_types.${AccountProcessingTypes.Native}`),
        hidden: hideFiatAccountOption,
      },
    ];
  }, [hideBlockchainOption, hideFiatAccountOption]);

  return <FormSelect {...rest} options={options} />;
};

export default BankAccountProcessingType;
