import React from 'react';

// helpers
import { StateModel } from '../../redux/reducers';
import { useSelector } from 'react-redux';

// components
import ManageCircleService from '../../modules/ExternalServices/Circle/ManageCircleSection';

const ExternalServices = () => {
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  return (
    <>
      <ManageCircleService disabled={!isTwoFactorVerificationEnabled} />
    </>
  );
};

export default ExternalServices;
