import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import Button from '../../../../DesignSystem/Core/Button';
import SuccessButton from '../../../../Buttons/SuccessButton';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Col, Row } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  onSubmit: () => void;
}

const SubmitConfirmationModal = ({
  isVisible,
  closeCallback,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  return (
    <ModalDialog
      title={t('submit_for_review', { ns: 'common' })}
      width={500}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
      footer={
        <Row justify="end" gutter={[16, 0]}>
          <Col>
            <Button onClick={() => closeCallback()} danger disabled={isLoading}>
              {t('cancel', { ns: 'common' })}
            </Button>
          </Col>
          <Col>
            <SuccessButton onClick={handleSubmit} loading={isLoading}>
              {t(
                'client_group_actions.submit_for_review.submit_for_review_button',
              )}
            </SuccessButton>
          </Col>
        </Row>
      }
    >
      <StyledModalContent>
        {t('client_group_actions.submit_for_review.description')}
      </StyledModalContent>
    </ModalDialog>
  );
};

const StyledModalContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSm};
  white-space: pre-line;
`;

export default SubmitConfirmationModal;
