import React, { useEffect, useMemo, useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import { signOut } from 'redux/actions/auth';
import { ActionKeys } from '../../../components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { StateModel } from '../../../redux/reducers';
import { onboardingAPI } from '../../../api/onboarding/onboardingAPI';
import { OnboardingHelpers } from '../../../helpers/crm/onboarding';
import { ClientGroupStatuses } from '../../../enums/onboarding/crm';
import { updateActiveApplication } from '../../../redux/actions/applications';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as ApplicationsStateModel } from '../../../redux/reducers/applications';
import {
  IInformationFilled,
  OnboardingStatusModel,
} from '../../../typings/onboarding/onboarding';

// components
import StructureStep from './Steps/Structure';
import Administration from './Steps/Administration';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import IdentificationStep from './Steps/Identification';
import GeneralInformationStep from './Steps/GeneralInformation';
import AccountInformationStep from './Steps/AccountInformation';
import ApplicationDocumentation from './Steps/ApplicationDocumentation';
import ApplicationInReviewStatus from '../Components/ApplicationInReviewStatus';
import ApplicationNotSubmittedStatus from '../Components/ApplicationNotSubmittedStatus';
import StepsBar, { StepItemModel } from '../Components/StepsBar';
import { Col, Modal, Row } from 'antd';

type OrganizationStepKeys =
  | 'generalInformation'
  | 'identification'
  | 'accountInformation'
  | 'relationshipTree'
  | 'administration'
  | 'applicationDocumentation';

const StepKeys: Array<keyof IInformationFilled> = [
  'generalInformation',
  'identification',
  'accountInformation',
  'relationshipTree',
  'administration',
  'applicationDocumentation',
];

const Organization = () => {
  const { t } = useTranslation('onboarding');
  const dispatch = useDispatch();
  const scrollPointRef = useRef<any>(null);
  const currentContactId = useSelector<StateModel, string>(
    (state) => state.auth.contactData?._id as string,
  );
  const { activeApplication } = useSelector<StateModel, ApplicationsStateModel>(
    (state) => state.applications,
  );
  const [currentStep, setCurrentStep] = useState<number | null>(null);
  const isViewOnly =
    activeApplication?.clientGroup?.status !== ClientGroupStatuses.Onboarding;

  const {
    response: onboardingStatusResponse,
    loading: onboardingStatusLoading,
  } = useFetch(
    () =>
      activeApplication
        ? onboardingAPI.fetchStatusForOnboardingItem(
            activeApplication.crmItemId,
            activeApplication._id,
          )
        : null,
    [activeApplication],
  );

  const onboardingData = useMemo<OnboardingStatusModel | null>(() => {
    if (!onboardingStatusResponse) {
      return null;
    }
    return {
      ...onboardingStatusResponse,
      contactId: currentContactId,
      applicationId: activeApplication?._id || '',
      relationshipScopeId: activeApplication?.model.relationshipScopes
        ?.active as string,
    } as OnboardingStatusModel;
  }, [onboardingStatusResponse]);

  const steps = useMemo<StepItemModel<OrganizationStepKeys>[]>(() => {
    const result: StepItemModel<OrganizationStepKeys>[] = [
      {
        key: 'generalInformation',
        title: t('applicant_information.applicant.title'),
      },
      { key: 'identification', title: t('identification.title') },
      { key: 'accountInformation', title: t('account_information.title') },
      { key: 'relationshipTree', title: t('structure.title') },
      { key: 'administration', title: t('administration.title') },
      {
        key: 'applicationDocumentation',
        title: t('application_documentation.title'),
      },
    ] as StepItemModel<OrganizationStepKeys>[];

    return result;
  }, [t, onboardingData]);

  //Set initial step number
  useEffect(() => {
    if (currentStep === null && onboardingData) {
      const allowedSteps = StepKeys.filter(
        (stepKey) =>
          steps.findIndex(
            (i: StepItemModel<OrganizationStepKeys>) => i.key === stepKey,
          ) !== -1,
      );

      setCurrentStep(
        OnboardingHelpers.getNumberOfInitialOnboardingStep(
          onboardingData.informationFilled,
          allowedSteps,
        ),
      );
    }
  }, [onboardingData]);

  useEffect(() => {
    if (currentStep !== null) {
      scrollPointRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep]);

  const handleStepAction = async (action: ActionKeys | null) => {
    if (!onboardingData || currentStep === null) {
      return;
    }

    switch (action) {
      case 'save-back':
        setCurrentStep(currentStep - 1);
        break;

      case 'save-exit':
        dispatch(signOut());
        break;

      case 'submit':
        {
          await dispatch(updateActiveApplication());

          if (currentStep !== steps.length - 1) {
            setCurrentStep(currentStep + 1);
          } else {
            Modal.info({
              icon: '',
              closable: true,
              content: t('application_documentation.success_submit', {
                ns: 'onboarding',
              }),
              okText: t('ok', { ns: 'common' }),
              width: 520,
            });
          }
        }
        break;
    }
  };

  const renderStep = (step: number | null) => {
    if (!onboardingData || !activeApplication || step === null) {
      return;
    }

    switch (steps[step as number].key) {
      case 'generalInformation':
        return (
          <GeneralInformationStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 'identification':
        return (
          <IdentificationStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 'accountInformation':
        return (
          <AccountInformationStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 'relationshipTree':
        return (
          <StructureStep
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isEditRelationshipStructureDisabled={
              activeApplication.clientGroup.isEditRelationshipStructureDisabled
            }
            isViewOnly={
              isViewOnly ||
              activeApplication.clientGroup.isEditRelationshipStructureDisabled
            }
          />
        );

      case 'administration':
        return (
          <Administration
            onboardingData={onboardingData}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      case 'applicationDocumentation':
        return (
          <ApplicationDocumentation
            application={activeApplication}
            onAction={handleStepAction}
            isViewOnly={isViewOnly}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div ref={scrollPointRef} />
      <StepsBar
        steps={steps}
        currentStep={currentStep !== null ? currentStep : undefined}
        onStepSelect={isViewOnly ? setCurrentStep : undefined}
      />

      <Row justify="center">
        <Col xl={20} lg={20} md={22} xs={22}>
          <ContentWrapper>
            {isViewOnly ? (
              <ApplicationInReviewStatus />
            ) : (
              <ApplicationNotSubmittedStatus />
            )}
            <LoadingWrapper loading={onboardingStatusLoading}>
              {renderStep(currentStep)}
            </LoadingWrapper>
          </ContentWrapper>
        </Col>
      </Row>
    </>
  );
};

const ContentWrapper = styled.div`
  padding-top: ${({ theme }) => theme.paddingMd};
`;

export default Organization;
