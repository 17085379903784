import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../DesignSystem/Core/IconSVG';
import { Trans } from 'react-i18next';
import { Popover } from 'antd';
import { ReactComponent as InfoIcon } from '../../../../resources/icons/remix-icons/information-line.svg';

interface IProps {
  contact: string;
}

const HandoffApplicationTooltip = ({ contact }: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <Popover
      content={
        <>
          <Text gutterBottom>
            <Trans
              t={t}
              values={{ contact }}
              i18nKey="node_modal.handoff_application.handoff_application_tooltip.line_1"
              components={[
                <Text key="contact_name" weight="semi-bold" variant="span" />,
              ]}
            />
          </Text>
          <Text gutterBottom>
            <Trans
              t={t}
              values={{ contact }}
              i18nKey="node_modal.handoff_application.handoff_application_tooltip.line_2"
              components={[
                <Text key="contact_name" weight="semi-bold" variant="span" />,
              ]}
            />
          </Text>
          <Text>
            {t(
              'node_modal.handoff_application.handoff_application_tooltip.line_3',
            )}
          </Text>
        </>
      }
      placement="rightTop"
      overlayStyle={{ maxWidth: '600px' }}
    >
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.paddingXs};
`;

export default HandoffApplicationTooltip;
