import React, { useCallback, useContext, useState } from 'react';

export type PageLayoutOptions = {
  title?: string | any;
  appendToTitle?: string | React.ReactNode;
  extra?: any;
  content?: any;
  description?: any;
};

interface MainLayoutContextData {
  pageLayoutOptions: PageLayoutOptions;
  clearContext: () => void;
  setPageLayoutOptions: React.Dispatch<React.SetStateAction<PageLayoutOptions>>;
}

// * Context
const MainLayoutContext = React.createContext<MainLayoutContextData>(
  {} as MainLayoutContextData,
);

// * Provider
export const MainLayoutContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [pageLayoutOptions, setPageLayoutOptions] = useState<PageLayoutOptions>(
    {
      title: '',
      appendToTitle: '',
      content: null,
      extra: null,
      description: null,
    },
  );

  const clearContext = useCallback(() => {
    setPageLayoutOptions({
      title: '',
      appendToTitle: '',
      content: null,
      extra: null,
      description: null,
    });
  }, []);

  return (
    <MainLayoutContext.Provider
      value={{ pageLayoutOptions, setPageLayoutOptions, clearContext }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

// * Hook
export function useMainLayoutContext(): MainLayoutContextData {
  const context = useContext(MainLayoutContext);
  return context;
}
