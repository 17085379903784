import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { BlockchainAddressModel } from '../../typings/accounting/accounts';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface NewAddressModel {
  currency: string;
  chain: string;
}

const blockchainAddressesAPI = {
  fetchAddressesByAccountNumber: (
    accountNumber: string,
    params: FetchParamsModel,
  ) => {
    return APIService.get(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/circle/addresses`,
      { params },
    ).then<FetchResponseModel<BlockchainAddressModel>>(({ data }) => data);
  },

  createNewAddressForAccount: (
    accountNumber: string,
    address: NewAddressModel,
  ) => {
    return APIService.post(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/circle/addresses`,
      address,
    ).then(({ data }) => data);
  },
};

export { blockchainAddressesAPI };
