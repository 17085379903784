import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';
import { FormValuesModel } from '../../../components/ModalDialogs/TemplateModalDialogs/Messaging/SendMessageModal/InnerForm';

// components
import Button from '../../../components/DesignSystem/Core/Button';
import SendMessageModal from '../../../components/ModalDialogs/TemplateModalDialogs/Messaging/SendMessageModal';

interface IProps {
  categoryId?: number;
  hideRecipientsField?: boolean;
  createThread: (values: FormValuesModel) => void;
}

const SendMessage = ({
  categoryId,
  hideRecipientsField,
  createThread,
}: IProps) => {
  const { t } = useTranslation('profile');
  const [isVisible, setVisible] = useState(false);

  const initialValues: FormValuesModel = useMemo(() => {
    return {
      categoryId: categoryId as number,
      subject: '',
      message: '',
      attachments: [],
      recipients: [],
      hideRecipientsField,
    };
  }, [categoryId, hideRecipientsField]);

  const handleSubmit = async (values: FormValuesModel) => {
    await createThread(values);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        {t('messages.send_message.send_message_button')}
      </Button>

      <SendMessageModal
        initialValues={initialValues}
        title={t('messages.send_message.title')}
        sendButtonTitle={t('messages.send_message.send_message_button')}
        isVisible={isVisible}
        closeCallback={() => setVisible(false)}
        onSubmit={handleSubmit}
        categoryId={categoryId}
      />
    </>
  );
};

export default SendMessage;
