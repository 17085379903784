import React, { memo } from 'react';

// components
import Requests from 'modules/Connections/Requests';

const RequestsPage = memo(() => {
  return <Requests />;
});

export default RequestsPage;
