import React, { useEffect, useState } from 'react';

// helpers
import { userAPI } from '../../../../../api/profile/userAPI';
import { StateModel } from '../../../../../redux/reducers';
import { FIDOHelpers } from '../../../../../helpers/fido';
import { initializeApp } from '../../../../../redux/actions/app';
import { BrowserHelpers } from '../../../../../helpers/browser';
import { setTemporaryToken } from 'redux/actions/auth';
import { LocalStorageHelpers } from '../../../../../helpers/storages/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from '../../../../../redux/reducers/auth';
import {
  AssertionForLoginModel,
  ChallengeForLoginModel,
} from '../../../../../typings/profile/fido';

// components
import LoadingWrapper from '../../../../../components/WrapperComponents/LoadingWrapper';
import ErrorWithRetryButton from './ErrorWithRetryButton';
import ContentWithUseKeyButton from './ContentWithUseKeyButton';

interface IProps {
  loadingChallenge: boolean;
  challenge?: ChallengeForLoginModel;
}

const Content = ({ challenge, loadingChallenge }: IProps) => {
  const dispatch = useDispatch();
  const isSafari = BrowserHelpers.isSafari();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { temporaryToken } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  useEffect(() => {
    // Safari browser is not allowing WebAuthn usage without user interaction
    // To use WebAuthn user will have to click on special button (by that trigger onClick event where we can run ceremony)
    if (challenge && temporaryToken && !isSafari) {
      runFidoCeremony();
    }
  }, [challenge, temporaryToken, isSafari]);

  async function runFidoCeremony() {
    if (challenge) {
      setIsError(false);
      setIsLoading(true);
      const publicKey = FIDOHelpers.preformatChallengeForLogin(challenge);

      await navigator.credentials
        .get({ publicKey })
        .then(async (assertion) => {
          if (assertion) {
            const formattedAssertion = FIDOHelpers.publicKeyCredentialToJSON(
              assertion,
            ) as AssertionForLoginModel;
            const { jwt, refreshToken } = await userAPI.loginWithFido(
              formattedAssertion,
              temporaryToken as string,
            );
            LocalStorageHelpers.setAuthenticationTokens(jwt, refreshToken);
            dispatch(setTemporaryToken(null));
            dispatch(initializeApp());
          }
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <LoadingWrapper loading={isLoading || loadingChallenge}>
      {isSafari && !isError ? (
        <ContentWithUseKeyButton onTryClick={runFidoCeremony} />
      ) : (
        <ErrorWithRetryButton onRetryClick={runFidoCeremony} />
      )}
    </LoadingWrapper>
  );
};

export default Content;
