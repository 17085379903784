import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';

export let reactPlugin: ReactPlugin;
export let appInsights: ApplicationInsights;

const createTelemetryService = () => {
  const initialize = (instrumentationKey: string) => {
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided');
    }

    try {
      reactPlugin = new ReactPlugin();

      appInsights = new ApplicationInsights({
        config: {
          enableCorsCorrelation: true,
          instrumentationKey,
          maxBatchInterval: 0,
          disableFetchTracking: false,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: {
              history: null,
            },
          },
        },
      });

      appInsights.loadAppInsights();
      appInsights.addTelemetryInitializer((telemetryItem) => {
        if (telemetryItem.tags) {
          telemetryItem.tags['ai.cloud.role'] = 'UI Client';
        }
      });
    } catch {
      console.error('Failed to connect with application insights');
    }
  };

  return {
    initialize,
    appInsights,
    reactPlugin,
  };
};

export const AppInsightsService = createTelemetryService();

export const getAppInsights = () => {
  return appInsights;
};

export const setUserInfoToTelemetryContext = (
  userId: string,
  ipAddress = '',
) => {
  if (!appInsights) {
    return;
  }

  appInsights.context.device.ip = ipAddress;
  appInsights.context.user.authenticatedId = userId;
};

export const clearAuthenticatedUserTelemetryContext = () => {
  if (!appInsights) {
    return;
  }

  appInsights.clearAuthenticatedUserContext();
};

export const trackException = (severity: SeverityLevel, exception: string) => {
  if (process.env.REACT_APP_STAGE === 'local') {
    return;
  }

  const insights = getAppInsights();

  if (insights) {
    insights.trackException({
      severityLevel: severity,
      exception: new Error(exception),
    });
  }
};
