import React, { useMemo } from 'react';

// helpers
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';
import {
  CANADA_DISTRICTS,
  UNITED_STATES_DISTRICTS,
} from '../../../../../../constants/districts';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface IProps extends SelectProps {
  country: string;
}

const DistrictSelect = ({ country, ...rest }: IProps) => {
  const options = useMemo<SelectOption[]>(() => {
    if (country === 'CA') {
      return Object.keys(CANADA_DISTRICTS).map((key) => ({
        id: key,
        label: CANADA_DISTRICTS[key],
      }));
    }

    return Object.keys(UNITED_STATES_DISTRICTS).map((key) => ({
      id: key,
      label: UNITED_STATES_DISTRICTS[key],
    }));
  }, [country]);

  return <FormSelect {...rest} options={options} />;
};

export default DistrictSelect;
