import { PermissionedAccessModel } from '.';

// Permission keys
export type ProfilePermissionKeys =
  | 'Profile_Messages_View'
  | 'Profile_Messages_Thread_View'
  | 'Profile_TravelNotifications_View'
  | 'Profile_AccountNotifications_View'
  | 'Profile_Requests_View';

// Permission config
export const ProfilePermissions: Record<
  ProfilePermissionKeys,
  PermissionedAccessModel
> = {
  Profile_Messages_View: {
    flag: 'USER_HAS_CLIENT_GROUPS',
    requiredAllPermissions: true,
    permissions: [],
  },

  Profile_Messages_Thread_View: {
    flag: 'USER_HAS_CLIENT_GROUPS',
    requiredAllPermissions: true,
    permissions: [],
  },

  Profile_TravelNotifications_View: {
    flag: 'USER_HAS_CLIENT_GROUPS',
    requiredAllPermissions: true,
    permissions: [],
  },

  Profile_AccountNotifications_View: {
    flag: 'USER_HAS_CLIENT_GROUPS',
    requiredAllPermissions: true,
    permissions: [],
  },

  Profile_Requests_View: {
    flag: 'USER_WITHOUT_CLIENT_GROUPS',
    requiredAllPermissions: true,
    permissions: [],
  },
};
