import React, { memo } from 'react';

// helpers
import { StateModel } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { OnboardingEntryTypes } from '../../enums/onboarding/crm';

// components
import Individual from '../../modules/Onboarding/Individual';
import Organization from '../../modules/Onboarding/Organization';

const OnboardingPage = memo(() => {
  const onboardingType = useSelector<
    StateModel,
    OnboardingEntryTypes | undefined
  >((state) => state.applications.activeApplication?.onboardingProcess?.type);

  const renderOnboardingComponent = () => {
    let result = null;

    switch (onboardingType) {
      case OnboardingEntryTypes.Contact:
        result = <Individual />;
        break;
      case OnboardingEntryTypes.Organization:
        result = <Organization />;
        break;
    }

    return result;
  };

  return <>{renderOnboardingComponent()}</>;
});

export default OnboardingPage;
