import * as yup from 'yup';

// constants
import {
  ContactShortInfoFormValidationSchema,
  RelationshipFormValidationSchema,
} from '../onboarding-new/structure';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from '../common';

export const NewUserValidationSchema = yup.object().shape({
  clientGroupId: STRING_VALIDATION_RULE,
  relationshipScopeId: STRING_VALIDATION_RULE,
  isFromExistingContact: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  relationships: yup.array().when('isFromExistingContact', {
    is: false,
    then: (schema) =>
      schema
        .of(RelationshipFormValidationSchema)
        .min(1, 'form:validation_messages.required'),
  }),
  contact: yup.object().when('isFromExistingContact', {
    is: false,
    then: () => ContactShortInfoFormValidationSchema,
    otherwise: () =>
      yup.object().shape({
        id: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        isOnlineUser: NULLABLE_BOOLEAN_VALIDATION_RULE.when(
          'isPermissionsSelectAvailable',
          {
            is: true,
            then: (schema) =>
              schema.required('form:validation_messages.required'),
          },
        ),

        adminPermissionType: NULLABLE_STRING_VALIDATION_RULE.when(
          'isOnlineUser',
          {
            is: true,
            then: (schema) =>
              schema.required('form:validation_messages.required'),
          },
        ),

        accountPermissions: NULLABLE_STRING_VALIDATION_RULE.when(
          'isOnlineUser',
          {
            is: true,
            then: (schema) =>
              schema.required('form:validation_messages.required'),
          },
        ),
      }),
  }),
});
