import { APIService } from './axiosInstance';
import APIConfig from '../config/api';

import { IDocumentBase, ITag } from '../typings/documents/documents';
import { DOCUMENT_UPLOAD_TIMEOUT } from '../constants/global';
import { FetchResponseModel } from '../typings/common';

interface IFetchTagsResponse {
  tags: ITag[];
}

interface IHeaders {
  'Content-Type': string;
  Authorization?: string;
}

const documentsAPI = {
  fetchDocuments: (body: any) => {
    return APIService.get<FetchResponseModel<IDocumentBase>>(
      `${APIConfig.dmsApi}`,
      body,
    ).then((response) => response.data);
  },

  uploadDocument: (
    formData: any,
    uploadProgressCallback: (percentage: number) => void,
    token?: string,
  ) => {
    const headers: IHeaders = {
      'Content-Type': 'multipart/form-data',
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return APIService.post(`${APIConfig.dmsApi}/upload`, formData, {
      headers,
      addFingerprintToHeaders: true,
      timeout: DOCUMENT_UPLOAD_TIMEOUT,
      onUploadProgress: uploadProgressCallback,
    }).then((response) => response.data);
  },

  // TODO: delete API and all related components, since we are not using this at all
  fetchTags: (search: string) => {
    return APIService.get<IFetchTagsResponse>(
      `${APIConfig.dmsApi}/api/v1/tag/autocomplete`,
      { params: { search } },
    ).then((response) => response.data);
  },
};

export { documentsAPI };
