import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { TransactionTypes } from 'enums/approvalManagement/approvalManagement';
import { TransactionDocument } from 'typings/accounting/transaction';
import {
  TransactionSourceTypes,
  TransferApprovalStatuses,
} from 'enums/accounting/transfers';

// components
import ExportToPDF from './ExportToPDF';
import DocumentsValue from '../../DocumentsValue';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import TransactionStatus from 'components/Additional/Statuses/TransactionStatus';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import WireTransferTemplateInfo, {
  WireTemplate,
} from '../../WireTransferTemplateInfo';
import { Col, Row } from 'antd';

export type WireTransactionSenderData = {
  type: TransactionSourceTypes;
  purpose: string;
  amount: string;
  postingDate: string;
  valueDate: string;
  requestedBy: string;
  status: TransferApprovalStatuses;
  workflowExpiresAt: number | null;
  relatedDocuments: TransactionDocument[];
  template: WireTemplate | null;
  transactionNumber: string;
  senderReference: string;
  orderingCustomer: {
    accountName: string;
    accountNumber: string;
    address: string;
    reference: string;
  };
};

interface IProps {
  transactionId: number | null;
  journalEntryId: number | null;
  transaction: WireTransactionSenderData;
  onUpload?: () => void;
}

const ValueWrapper = memo(({ children }) => {
  return (
    <EllipsisTooltip maxTextContainerWidth="500px" title={children}>
      {children}
    </EllipsisTooltip>
  );
});

const Sender = memo(
  ({ transaction, transactionId, journalEntryId, onUpload }: IProps) => {
    const { t } = useTranslation('accounts');

    const exportToPDFProps = useMemo<{
      type: 'transaction' | 'journal-entry';
      entryId: number;
    } | null>(() => {
      if (
        ![
          TransferApprovalStatuses.Completed,
          TransferApprovalStatuses.Processing,
          TransferApprovalStatuses.InReview,
          TransferApprovalStatuses.AMLReview,
          TransferApprovalStatuses.PendingTransmit,
        ].includes(transaction.status)
      ) {
        return null;
      }

      if (transactionId) {
        return {
          type: 'transaction',
          entryId: transactionId,
        };
      } else if (journalEntryId) {
        return {
          type: 'journal-entry',
          entryId: journalEntryId,
        };
      } else {
        return null;
      }
    }, [transaction, transactionId, journalEntryId]);

    const descriptionData = useMemo<DescriptionItem[]>(() => {
      let result: DescriptionItem[] = [];
      const {
        type,
        amount,
        purpose,
        template,
        valueDate,
        requestedBy,
        postingDate,
        relatedDocuments,
        orderingCustomer,
        transactionNumber,
        senderReference,
      } = transaction;

      result = [
        {
          label: t(
            'transaction_details_modal.transaction_details.internal.transaction_number',
          ),
          description: transactionNumber,
        },
        {
          label: t(
            'transaction_details_modal.transaction_details.internal.type',
          ),
          description: t(`types.${TransactionTypes[type]}`),
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.purpose',
          ),
          description: purpose,
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.international.sender_reference',
          ),
          description: senderReference,
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.ordering_customer',
          ),
          description: (
            <StyledInnerDescriptionSection
              size="small"
              bordered={false}
              background="none"
              data={[
                {
                  label: t(
                    'transaction_details_modal.transaction_details.internal.account_name',
                  ),
                  description: (
                    <ValueWrapper>{orderingCustomer.accountName}</ValueWrapper>
                  ),
                },

                {
                  label: t(
                    'transaction_details_modal.transaction_details.internal.account_number',
                  ),
                  description: (
                    <ValueWrapper>
                      {orderingCustomer.accountNumber}
                    </ValueWrapper>
                  ),
                },

                {
                  label: t(
                    'transaction_details_modal.transaction_details.internal.address',
                  ),
                  description: (
                    <ValueWrapper>{orderingCustomer.address}</ValueWrapper>
                  ),
                },

                {
                  label: t(
                    'transaction_details_modal.transaction_details.internal.from_account_reference',
                  ),
                  description: (
                    <ValueWrapper>{orderingCustomer.reference}</ValueWrapper>
                  ),
                },
              ]}
            />
          ),
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.international.beneficiary',
          ),
          description: template ? (
            <WireTransferTemplateInfo displayTemplateName template={template} />
          ) : null,
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.amount',
          ),
          description: amount,
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.posting_date',
          ),
          description: postingDate,
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.value_date',
          ),
          description: valueDate,
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.requested_by',
          ),
          description: requestedBy,
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.status',
          ),
          description: (
            <TransactionStatus
              transaction={{
                status: transaction.status,
                expiresAt: transaction.workflowExpiresAt || undefined,
              }}
            />
          ),
        },

        {
          label: t(
            'transaction_details_modal.transaction_details.internal.documents',
          ),
          description: (
            <DocumentsValue
              transactionDocuments={relatedDocuments}
              journalEntryId={journalEntryId}
              onUpload={onUpload}
            />
          ),
        },
      ];

      return result;
    }, [t, transaction, journalEntryId, onUpload]);

    return (
      <>
        {exportToPDFProps && (
          <StyledRow justify="end" gutter={[16, 16]}>
            <Col>
              <ExportToPDF
                type={exportToPDFProps.type}
                entryId={exportToPDFProps.entryId}
              />
            </Col>
          </StyledRow>
        )}
        <DescriptionSection data={descriptionData} />
      </>
    );
  },
);

const StyledInnerDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 250px !important;
    max-width: 250px !important;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default Sender;
