import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { TransfersHelpers } from 'helpers/transfers/transfers';
import { TransferTemplateTypes } from 'enums/accounting/transfers';

// components
import Link from '@common_components/Texts/Link';
import TwoColRow from 'components/Additional/TwoColRow';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Divider } from 'antd';

export type WireTemplate = {
  templateId: number;
  templateName: string;
  canAccessTemplate: boolean;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;
  beneficiaryCountry: string;
  beneficiaryAddress: string;
  beneficiaryBankCode: string;
  beneficiaryBankName: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress: string;
  intermediaryBankCode: string;
  intermediaryBankName: string;
  intermediaryBankCountry: string;
  intermediaryBankAddress: string;
  reference: string;
  beneficiaryBankCodeTypeName: string;
  intermediaryBankCodeTypeName: string;
  beneficiaryCity: string;
  beneficiaryDistrict: string;
  beneficiaryPostCode: string;
  beneficiaryBankCity: string;
  beneficiaryBankDistrict: string;
  beneficiaryBankPostCode: string;
  intermediaryBankCity: string;
  intermediaryBankDistrict: string;
  intermediaryBankPostCode: string;
};

interface IProps {
  template: WireTemplate;
  hideReference?: boolean;
  displayTemplateName?: boolean;
  maxValueWidthForTooltip?: string;
}

const EnhancedTwoColRow = memo(
  ({
    label,
    value,
    maxTextContainerWidth,
  }: {
    label: React.ReactNode;
    value: React.ReactNode;
    maxTextContainerWidth?: string;
  }) => {
    return (
      <TwoColRow
        labelWidth="230px"
        label={label}
        value={
          <EllipsisTooltip
            title={value}
            maxTextContainerWidth={maxTextContainerWidth}
          >
            {value}
          </EllipsisTooltip>
        }
      />
    );
  },
);

const WireTransferTemplateInfo = memo(
  ({
    template,
    hideReference,
    displayTemplateName,
    maxValueWidthForTooltip,
  }: IProps) => {
    const { t } = useTranslation('transfers');

    return (
      <>
        {displayTemplateName && (
          <EnhancedTwoColRow
            maxTextContainerWidth={maxValueWidthForTooltip}
            label={t(
              'transfer_templates.wire_transfer_template_details_section.transfer_template',
            )}
            value={
              template.canAccessTemplate ? (
                <Link
                  to={{
                    pathname: RoutePaths.Transfers_TransferTemplates,
                    search: `?templateId=${template.templateId}&templateType=${TransferTemplateTypes.Wire}`,
                  }}
                >
                  {TransfersHelpers.getTransferTemplateName(
                    String(template.templateId),
                    template.templateName as string,
                  )}
                </Link>
              ) : (
                template.templateName
              )
            }
          />
        )}
        <StyledDivider orientation="left">
          {t(
            'transfer_templates.wire_transfer_template_details_section.beneficiary_section',
          )}
        </StyledDivider>
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.account_name',
          )}
          value={template.beneficiaryAccountName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.account_number',
          )}
          value={template.beneficiaryAccountNumber}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.country',
          )}
          value={template.beneficiaryCountry}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.address',
          )}
          value={template.beneficiaryAddress}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.city',
          )}
          value={template.beneficiaryCity}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.district',
          )}
          value={template.beneficiaryDistrict}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.post_code',
          )}
          value={template.beneficiaryPostCode}
        />

        <StyledDivider orientation="left">
          {t(
            'transfer_templates.wire_transfer_template_details_section.beneficiary_bank_section',
          )}
        </StyledDivider>
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code',
          )}
          value={template.beneficiaryBankCode}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code_type',
          )}
          value={template.beneficiaryBankCodeTypeName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_name',
          )}
          value={template.beneficiaryBankName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.country',
          )}
          value={template.beneficiaryBankCountry}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.address',
          )}
          value={template.beneficiaryBankAddress}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.city',
          )}
          value={template.beneficiaryBankCity}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.district',
          )}
          value={template.beneficiaryBankDistrict}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.post_code',
          )}
          value={template.beneficiaryBankPostCode}
        />

        <StyledDivider orientation="left">
          {t(
            'transfer_templates.wire_transfer_template_details_section.intermediary_bank_section',
          )}
        </StyledDivider>
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code',
          )}
          value={template.intermediaryBankCode}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code_type',
          )}
          value={template.intermediaryBankCodeTypeName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_name',
          )}
          value={template.intermediaryBankName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.country',
          )}
          value={template.intermediaryBankCountry}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.address',
          )}
          value={template.intermediaryBankAddress}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.city',
          )}
          value={template.intermediaryBankCity}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.district',
          )}
          value={template.intermediaryBankDistrict}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.post_code',
          )}
          value={template.intermediaryBankPostCode}
        />

        {!hideReference && (
          <>
            <StyledDivider />
            <EnhancedTwoColRow
              maxTextContainerWidth={maxValueWidthForTooltip}
              label={t(
                'transfer_templates.wire_transfer_template_details_section.reference',
              )}
              value={template.reference}
            />
          </>
        )}
      </>
    );
  },
);

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

export default WireTransferTemplateInfo;
