import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { ContactAddressTypes } from '../../../../../../enums/onboarding/crm';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps;

const ContactAddressTypeSelect = (props: IProps) => {
  const { t } = useTranslation('onboarding');

  const options = useMemo<SelectOption[]>(
    () =>
      Object.values(ContactAddressTypes).map((key) => ({
        id: key,
        label: t(`identification.contact_address_types.${key}`),
      })),
    [t],
  );

  return <FormSelect {...props} options={options} />;
};

export default ContactAddressTypeSelect;
