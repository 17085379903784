import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { userAPI } from '../../../../../../api/profile/userAPI';
import { StateModel } from '../../../../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from '../../../../../../enums/profile/auth';
import { StateModel as AuthStateModel } from '../../../../../../redux/reducers/auth';
import {
  setDefaultAuthenticator,
  setFidoAuthenticatorEnabledStatus,
} from '../../../../../../redux/actions/auth';

// components
import Button from '../../../../../../components/DesignSystem/Core/Button';
import SignChallengeWithSecurityKeyModal from '../../../../../../components/ModalDialogs/TemplateModalDialogs/UserProfile/SignChallengeWithSecurityKeyModal';
import { message } from 'antd';

const DisableFIDOAuthentication = () => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onDisableFIDOClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = (wasSigned?: boolean) => {
    if (wasSigned) {
      message.success(
        t(
          'security.two_step_verification.security_key.disable_key.success_submit',
        ),
      );
      dispatch(setFidoAuthenticatorEnabledStatus(false));
      if (
        profileData?.default2FAType === TwoFactorVerificationTypes.Fido &&
        profileData.isTwoFactorAuthenticationEnabled
      ) {
        dispatch(
          setDefaultAuthenticator(TwoFactorVerificationTypes.Authenticator),
        );
      }
    }

    setIsModalVisible(false);
  };

  return (
    <>
      <StyledButton size="large" danger onClick={onDisableFIDOClick}>
        {t('security.two_step_verification.disable_button')}
      </StyledButton>

      <SignChallengeWithSecurityKeyModal
        title={t(
          'security.two_step_verification.security_key.disable_key.title',
        )}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        getChallenge={userAPI.getChallengeForDisablingFIDO}
        submitAssertion={userAPI.disableSecurityKey2FA}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default DisableFIDOAuthentication;
