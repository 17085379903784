import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { transactionsAPI } from '../../../../../api/accounting/transactionsAPI';
import { transactionsAdapter } from 'apiAdapters/accounting/transactionsAdapter';
import { IApprovalMatrixRecord } from '../../../../../typings/accounting/transaction';

// components
import TransactionDetailsModalDialog, {
  TransactionData,
} from '../../../../../components/ModalDialogs/TemplateModalDialogs/Accounts/TransactionDetailsModalDialog';

interface IProps {
  visible: boolean;
  journalEntryId: number | null;
  closeCallback: (wasUpdated?: boolean) => void;
}

const ViewTransactionDetailModal = ({
  visible,
  closeCallback,
  journalEntryId,
}: IProps) => {
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );
  const [transactionApprovals, setTransactionApprovals] = useState<
    IApprovalMatrixRecord[][] | null
  >(null);

  const { response: fetchTransactionResponse } = useFetch(
    () =>
      journalEntryId
        ? transactionsAPI.fetchJournalEntryById(journalEntryId)
        : null,
    [journalEntryId],
  );

  const { response: fetchTransactionApprovalsResponse } = useFetch(
    () =>
      fetchTransactionResponse?.workflowId
        ? transactionsAPI.fetchTransactionApprovalsStatus(
            fetchTransactionResponse?.workflowId,
          )
        : null,
    [fetchTransactionResponse?.workflowId],
  );

  useEffect(() => {
    if (fetchTransactionApprovalsResponse) {
      const approvalsArray =
        fetchTransactionApprovalsResponse.approvalProgressStateSets.map(
          (e) => e.progressStateItems,
        );
      setTransactionApprovals(approvalsArray);
    }
  }, [fetchTransactionApprovalsResponse]);

  const initialData = useMemo<TransactionData | null>(() => {
    let result: TransactionData | null = null;

    if (fetchTransactionResponse) {
      const formattedTransaction =
        transactionsAdapter.formatJournalEntryForTransactionDetailsModal(
          fetchTransactionResponse,
        );

      result = {
        ...formattedTransaction,
        journalEntryId,
        approvalWorkflowId: fetchTransactionResponse.workflowId,
        transactionApprovals: fetchTransactionResponse.isSender
          ? transactionApprovals
          : null,
      };
    }

    return result;
  }, [journalEntryId, fetchTransactionResponse, transactionApprovals]);

  const handleCancelTransaction = async () => {
    if (journalEntryId) {
      await transactionsAPI.cancelTransaction(journalEntryId);
      closeCallback(true);
    }
  };

  return (
    <TransactionDetailsModalDialog
      isVisible={visible}
      isTFAEnabled={isTwoFactorVerificationEnabled}
      closeCallback={closeCallback}
      initialData={initialData}
      onCancelTransaction={handleCancelTransaction}
    />
  );
};

export default ViewTransactionDetailModal;
