import React, { useState, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import {
  ConditionModel,
  LimitModel,
} from 'components/Forms/TemplateForms/Approval/TransactionRulesMatrixForm';
import {
  ITransactionApprovalRule,
  TransactionApprovalTemplateModel,
} from 'typings/approvalManagement/accountManagement';

// components
import Text from '@core_components/Text';
import HeaderCell from './HeaderCell';
import ContentCell from './ContentCell';
import { Table } from 'antd';

type TransactionTemplateType =
  | ITransactionApprovalRule
  | TransactionApprovalTemplateModel;

interface IProps {
  transactionRule: TransactionTemplateType;
}

const TransactionApprovalRulesMatrix = ({ transactionRule }: IProps) => {
  const { t } = useTranslation('account_management');

  const [remainedLimitsCount, setRemainedLimitsCount] = useState(0);
  const [initialRules, setInitialRules] = useState<{
    conditions: ConditionModel[];
    limits: LimitModel[];
  } | null>(null);

  useEffect(() => {
    if (!transactionRule) return;

    const formattedRule = getFormattedRules(transactionRule);

    // get remain limits count
    const remainedLimits =
      transactionRule.approvalRuleSets[0].approvalThreasholds.length - 3;

    setRemainedLimitsCount(remainedLimits > 0 ? remainedLimits : 0);

    setInitialRules(formattedRule);
  }, [transactionRule]);

  const getFormattedRules = (transactionRule: TransactionTemplateType) => ({
    limits: formatLimitsArray(transactionRule),
    conditions: formatConditionsArray(transactionRule),
  });

  // Display first 3 limits
  const formatLimitsArray = (
    template: TransactionTemplateType,
  ): LimitModel[] => {
    const { approvalRuleSets } = template;

    const limits = approvalRuleSets[0].approvalThreasholds.slice(0, 3);

    return limits.map(({ threasholdMax, threasholdMin }) => {
      const toInfinite = threasholdMax === -1 && threasholdMin === -1;

      return {
        from: Number(threasholdMin),
        to: Number(threasholdMax),
        isUsed: false,
        toInfinite,
      };
    });
  };

  const formatConditionsArray = (
    template: TransactionTemplateType,
  ): ConditionModel[] => {
    const { approvalRuleSets } = template;

    return approvalRuleSets.map((ruleSet) => ({
      conditions: ruleSet.approvalRuleItems.map(
        ({ approvalGroup, minimunApprovalThreashold }) => ({
          approvalGroup: approvalGroup.id,
          approvalGroupFull: approvalGroup,
          requiredApproversCount: minimunApprovalThreashold,
        }),
      ),
      limitStatuses: ruleSet.approvalThreasholds.map(({ ticked }) => ({
        isChecked: !!ticked,
      })),
    }));
  };

  const getColumns = () => {
    if (!initialRules) return [];

    let result: any[] = [
      {
        key: 'conditions',
        dataIndex: 'condition',
        width: 300,
      },
    ];

    const numberOfLimitColumns = initialRules?.limits.length || 0;

    const limitColumns = Array.from(Array(numberOfLimitColumns), (_, i) => ({
      key: `limit-${i}`,
      width: 250,
      dataIndex: 'limit',
    }));

    result = [...result, ...limitColumns];

    result = result.map((col, index: number) => {
      const baseProps = {
        colIndex: index > 0 ? index - 1 : 0,
        dataIndex: col.dataIndex,
      };

      return {
        ...col,
        onHeaderCell: () => ({
          ...baseProps,
          record: initialRules?.limits[index - 1],
        }),
        onCell: (record: ConditionModel, rowIndex: number) => ({
          ...baseProps,
          record,
          isLastRow: rowIndex === initialRules.conditions.length - 1,
        }),
      };
    });

    return result;
  };

  return (
    <>
      {initialRules && (
        <TableWrapper>
          <StyledTable
            bordered
            className="disable-table-row-hover"
            size="small"
            dataSource={initialRules.conditions}
            columns={getColumns()}
            pagination={false}
            components={{
              header: { cell: HeaderCell },
              body: { cell: ContentCell },
            }}
          />
          {!!remainedLimitsCount && (
            <StyledTableHelperText variant="body1">
              {`+ ${remainedLimitsCount} ${t(
                remainedLimitsCount > 1
                  ? 'approval_rules.bank_accounts.table.more_ranges'
                  : 'approval_rules.bank_accounts.table.more_range',
              )}`}
            </StyledTableHelperText>
          )}
        </TableWrapper>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTableHelperText = styled(Text)`
  padding-left: 20px;
  padding-top: 20px;
`;

const StyledTable = styled(Table)`
  display: flex;
  overflow: auto;

  .ant-table {
    margin: 0 !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colorDarkL4}`};

    table {
      width: auto;
    }
  }
`;

export default TransactionApprovalRulesMatrix;
