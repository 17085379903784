import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { securityAPI } from '../../../../api/profile/securityAPI';
import { setSessionDuration } from '../../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';

// components
import Text from '@core_components/Text';
import { CardWrapper } from 'components/Additional/CardWrapper';
import { message, Modal, Select } from 'antd';

const { Option } = Select;

const SessionSettings = () => {
  const { t } = useTranslation(['profile', 'common']);

  const [currentSessionTimeout, setCurrentSessionTimeout] = useState(0);
  const dispatch = useDispatch();

  const profileSessionDuration = useSelector<StateModel, number>(
    (state) => state.auth.profileData?.sessionDuration || 0,
  );

  useEffect(() => {
    setCurrentSessionTimeout(profileSessionDuration || 60 * 15);
  }, [profileSessionDuration]);

  const sessionExpireTimeouts = [
    { title: `1 ${t('security.sessions.session_settings.minute')}`, value: 60 },
    {
      title: `5 ${t('security.sessions.session_settings.minutes')}`,
      value: 60 * 5,
    },
    {
      title: `10 ${t('security.sessions.session_settings.minutes')}`,
      value: 60 * 10,
    },
    {
      title: `15 ${t('security.sessions.session_settings.minutes')}`,
      value: 60 * 15,
    },
    {
      title: `30 ${t('security.sessions.session_settings.minutes')}`,
      value: 60 * 30,
    },
    {
      title: `45 ${t('security.sessions.session_settings.minutes')}`,
      value: 60 * 45,
    },
    {
      title: `1 ${t('security.sessions.session_settings.hour')}`,
      value: 60 * 60,
    },
  ];

  const renderExpireTimeoutOptions = () =>
    sessionExpireTimeouts.map(({ title, value }) => (
      <Option key={value} value={value}>
        {title}
      </Option>
    ));

  const handleTimeoutChange = (value: any) => {
    if (value === currentSessionTimeout) return;

    const selectedOptionTitle = sessionExpireTimeouts.find(
      (e) => e.value === value,
    )?.title;

    Modal.confirm({
      content: t(
        'security.sessions.session_settings.change_session.confirmation_message',
        { session: selectedOptionTitle },
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => changeSessionTimeout(value),
    });
  };

  const changeSessionTimeout = async (newTimeout: number) => {
    await securityAPI.setSessionDuration(newTimeout);
    setCurrentSessionTimeout(newTimeout);
    dispatch(setSessionDuration(newTimeout));
    message.success(
      t(
        'security.sessions.session_settings.change_session.success_change_message',
      ),
    );
  };

  return (
    <CardWrapper>
      <Text variant="h4" weight="semi-bold">
        {t('security.sessions.session_settings.title')}
      </Text>

      <Text variant="body1">
        {t('security.sessions.session_settings.logout_after_of_inactivity')}
        <StyledSelect
          value={currentSessionTimeout}
          onSelect={handleTimeoutChange}
        >
          {renderExpireTimeoutOptions()}
        </StyledSelect>
        {`, ${t('security.sessions.session_settings.of_inactivity')}.`}
      </Text>
    </CardWrapper>
  );
};

const StyledSelect = styled(Select)`
  width: 150px;
`;

export default SessionSettings;
