import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';

// components
import Link from '@common_components/Texts/Link';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import { Col, Row } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation('auth');

  return (
    <FormField
      name="username"
      component={FormInput}
      label={
        <StyledRow justify="space-between">
          <Col>{t('signin.steps.check_username.form_fields.username')}</Col>
          <Col>
            <Link to={RoutePaths.Auth_ForgotUsername}>
              {t('reset_username.forgot_username_btn')}
            </Link>
          </Col>
        </StyledRow>
      }
    />
  );
};

const StyledRow = styled(Row)`
  width: 100%;
`;

export default InnerForm;
