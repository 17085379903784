import React, { useEffect, useState } from 'react';

// helpers
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import TravelNotificationsTable from '../../modules/Profile/TravelNotifications/TravelNotificationsTable';
import AddTravelNotificationRule from '../../modules/Profile/TravelNotifications/TravelNotificationsTable/AddTravelNotificationModal';

const TravelNotifications = () => {
  const [updateTableTrigger, updateTable] = useState({});
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      extra: <AddTravelNotificationRule onAdd={() => updateTable({})} />,
    }));
    return () => clearContext();
  }, [clearContext, setPageLayoutOptions]);

  return <TravelNotificationsTable updateTableTrigger={updateTableTrigger} />;
};

export default TravelNotifications;
