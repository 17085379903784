import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { IApplication } from 'typings/application/applications';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import ClientGroupDetails from 'components/Additional/DetailsSections/Connections/ClientGroupDetails';

interface IProps {
  isSelected: boolean;
  application: IApplication;
  onSwitchClick: () => void;
}

const ClientGroupItem = memo(
  ({ application, isSelected, onSwitchClick }: IProps) => {
    const { t } = useTranslation('connections');

    return (
      <StyledClientGroupDetails
        removeMargin
        clientGroup={{
          name: application.clientGroup.clientGroupName,
          type: application.crmItemType,
          entityType:
            application.onboardingProcess?.item?.keyRelationshipInstance
              ?.name || '',
        }}
      >
        {isSelected ? (
          <StyledStatus>{t('client_groups.current_group')}</StyledStatus>
        ) : (
          <Button onClick={onSwitchClick}>
            {t('client_groups.switch_button')}
          </Button>
        )}
      </StyledClientGroupDetails>
    );
  },
);

const StyledStatus = styled(Text)`
  color: ${({ theme }) => theme.colorPrimaryD2};
`;

const StyledClientGroupDetails = styled(ClientGroupDetails)`
  .ant-row {
    align-items: center;
  }
`;

export default ClientGroupItem;
