import React, { useMemo } from 'react';

// helpers
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface IProps extends SelectProps {
  numOfParticipantsInApprovalGroup: number;
}

const RequiredApprovalsCountSelect = ({
  numOfParticipantsInApprovalGroup,
  ...rest
}: IProps) => {
  const options = useMemo<SelectOption[]>(() => {
    let result: { key: number; name: number }[] = [];

    if (numOfParticipantsInApprovalGroup > 0) {
      result = Array.from(Array(numOfParticipantsInApprovalGroup), (_, i) => ({
        key: i + 1,
        name: i + 1,
      }));
    }

    return result.map((e) => ({
      id: e.key,
      label: e.name,
    }));
  }, [numOfParticipantsInApprovalGroup]);

  return <FormSelect {...rest} options={options} />;
};

export default RequiredApprovalsCountSelect;
