import React, { useState, memo, useEffect } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import { accountsAPI } from '../../../api/accounting/accountsAPI';
import { IAccountModel } from '../../../typings/onboarding/account';

// components
import AccountInfoSection from './AccountInfoSection';
import TransfersTable from '../LastTransfers/TransfersTable';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { Divider } from 'antd';
import { CardWrapper } from 'components/Additional/CardWrapper';

const AccountDetails = memo(() => {
  const [selectedAccount, setSelectedAccount] = useState<IAccountModel | null>(
    null,
  );

  const { response, loading } = useFetch(() => {
    return accountsAPI.fetchAccounts({ limit: 100, page: 1 });
  }, []);

  useEffect(() => {
    if (!selectedAccount && response?.data?.length) {
      setSelectedAccount(response.data[0]);
    }
  }, [selectedAccount, response]);

  return (
    <CardWrapper>
      <LoadingWrapper loading={loading || !selectedAccount}>
        {selectedAccount && (
          <>
            <AccountInfoSection
              accounts={response?.data || []}
              selectedAccount={selectedAccount}
              onAccountSelect={setSelectedAccount}
            />
            <Divider />
            <TransfersTable
              selectedAccountNumber={selectedAccount.accountNumber}
            />
          </>
        )}
      </LoadingWrapper>
    </CardWrapper>
  );
});

export default AccountDetails;
