import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IApprovalGroupShort } from '../../../../../typings/approvalManagement/accountManagement';

// components
import Link from '@common_components/Texts/Link';
import StatusLabel from '../../../../Typography/StatusLabel';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<IApprovalGroupShort> {
  onActionsClick: (key: string, id: string) => void;
  hasPermissionForEditing: boolean;
}

const ApprovalGroupsTable = ({
  onActionsClick,
  hasPermissionForEditing,
  ...rest
}: IProps) => {
  const { t } = useTranslation('account_management');

  const renderGroupStatus = ({ isActive, isDeleted }: IApprovalGroupShort) => {
    let result = null;

    if (isDeleted) {
      result = (
        <StatusLabel
          status="error"
          content={t('approval_groups.approval_group_statuses.Deactivated')}
        />
      );
    } else {
      result = isActive ? (
        <StatusLabel
          status="success"
          content={t('approval_groups.approval_group_statuses.Active')}
        />
      ) : (
        <StatusLabel
          status="warning"
          content={t('approval_groups.approval_group_statuses.PendingReview')}
        />
      );
    }

    return result;
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('approval_groups.table.name'),
        key: 'name',
        width: '30%',
        render: (record: IApprovalGroupShort) =>
          hasPermissionForEditing && record.isActive && !record.isDeleted ? (
            <Link onClick={() => onActionsClick('edit', record.id)}>
              {record.name}
            </Link>
          ) : (
            record.name
          ),
      },
      {
        title: t('approval_groups.table.description'),
        width: '30%',
        key: 'description',
        dataIndex: 'description',
      },
      {
        title: t('approval_groups.table.members'),
        width: '30%',
        key: 'members',
        render: (record: IApprovalGroupShort) =>
          record.participants.map((e) => e.userFullName).join(', '),
      },
      {
        title: t('approval_groups.table.status'),
        key: 'status',
        width: '10%',
        render: renderGroupStatus,
      },
    ];
  }, [hasPermissionForEditing, onActionsClick]);

  return <Table {...rest} columns={tableColumns} />;
};

export default ApprovalGroupsTable;
