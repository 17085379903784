import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { GetAuthenticatorConfigResponse } from '../../../../../../api/profile/securityAPI';

// components
import Text from '@core_components/Text';
import OTPField from '../../../../../Forms/FormComponents/OTPField';
import FormField from '@core_components/FormField';
import { Col, Divider, Row } from 'antd';

interface IProps {
  responseData: GetAuthenticatorConfigResponse;
}

const InnerForm = ({ responseData }: IProps) => {
  const { t } = useTranslation(['profile', 'custom_errors']);

  return (
    <>
      <StyledSubTitleText variant="subtitle1">
        {t(
          'security.two_step_verification.authenticator.enable_authenticator.description',
        )}
      </StyledSubTitleText>

      <Divider />

      <Row wrap={false} gutter={[20, 20]}>
        <Col flex="50px">
          <StepCircle>1</StepCircle>
        </Col>
        <Col flex="auto">
          <Text variant="body1" weight="semi-bold">
            {t(
              'security.two_step_verification.authenticator.enable_authenticator.step_one.title',
            )}
          </Text>
          <Text variant="body1">
            {t(
              'security.two_step_verification.authenticator.enable_authenticator.step_one.description',
            )}
          </Text>
        </Col>
      </Row>

      <Divider />

      <Row wrap={false} gutter={[20, 20]}>
        <Col flex="50px">
          <StepCircle>2</StepCircle>
        </Col>
        <Col flex="auto">
          <Row>
            <Col span={16}>
              <Text variant="body1" weight="semi-bold">
                {t(
                  'security.two_step_verification.authenticator.enable_authenticator.step_two.title',
                )}
              </Text>

              <Text variant="body1">
                {t(
                  'security.two_step_verification.authenticator.enable_authenticator.step_two.options_title',
                )}
              </Text>

              <Text variant="body1">
                {t(
                  'security.two_step_verification.authenticator.enable_authenticator.step_two.option1',
                )}
              </Text>

              <Text variant="body1">
                {t(
                  'security.two_step_verification.authenticator.enable_authenticator.step_two.option2',
                )}
              </Text>

              <Text variant="body1">
                {t(
                  'security.two_step_verification.authenticator.enable_authenticator.step_two.option3',
                )}
              </Text>

              <StyledParagraphManualOption variant="body1">
                {t(
                  'security.two_step_verification.authenticator.enable_authenticator.step_two.manual_entry_option',
                )}
              </StyledParagraphManualOption>

              <AuthenticatorSeedCode variant="h6">
                {responseData.seedCode}
              </AuthenticatorSeedCode>
            </Col>

            <BarCodeCol span={8}>
              <ImageWrapper>
                <QRCodeImage src={responseData.qrCode} />
              </ImageWrapper>
            </BarCodeCol>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row wrap={false} gutter={[20, 20]}>
        <Col flex="50px">
          <StepCircle>3</StepCircle>
        </Col>
        <Col flex="auto">
          <Text variant="body1" weight="semi-bold">
            {t(
              'security.two_step_verification.authenticator.enable_authenticator.step_tree.title',
            )}
          </Text>
          <Text variant="body1">
            {t(
              'security.two_step_verification.authenticator.enable_authenticator.step_tree.description',
            )}
          </Text>
          <OTPFieldWrapper>
            <FormField
              name="code"
              component={StyledOTPField}
              additionalProps={{ isNumberInput: true }}
            />
          </OTPFieldWrapper>
        </Col>
      </Row>
    </>
  );
};

const AuthenticatorSeedCode = styled(Text)`
  padding: ${({ theme }) => theme.paddingSm};
  background-color: ${({ theme }) => theme.colorDark};
  border-radius: ${({ theme }) => theme.borderRadiusRegular};
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacingForCodes};
`;

const BarCodeCol = styled(Col)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QRCodeImage = styled.img`
  width: 120px;
  height: 120px;
  cursor: pointer;
`;

const StyledSubTitleText = styled(Text)`
  text-align: center;
`;

const StyledParagraphManualOption = styled(Text)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  font-size: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorAdditionalL1};
`;

const OTPFieldWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  margin-right: 50px;
  width: 300px;
`;

export default InnerForm;
