import React from 'react';

// helpers
import { AutocompleteProps } from '@core_components/Autocomplete';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';
import { BlockchainTransferTemplateModel } from '../../../../../../typings/accounting/transfer';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface BlockchainTransferTemplatesAutocompleteProps
  extends AutocompleteProps<BlockchainTransferTemplateModel> {
  updateTemplatesTrigger?: any;
}

const BlockchainTransferTemplatesAutocomplete = (
  props: BlockchainTransferTemplatesAutocompleteProps,
) => {
  const fetchOptions = async () => {
    const { data } = await transferTemplatesAPI.getBlockchainTransferTemplates({
      limit: 10000,
      page: 1,
    });

    return data.map((e) => ({
      id: e.id,
      label: e.name,
      model: e,
    }));
  };

  return <FormAutocomplete {...props} fetchData={fetchOptions} />;
};

export default BlockchainTransferTemplatesAutocomplete;
