export enum TransferTemplateTypes {
  Internal = 1,
  Wire,
  Blockchain,
}

export enum TransferApprovalStatuses {
  PendingApprovals = 1,
  Completed,
  Rejected,
  Processing,
  InReview,
  AMLReview,
  Failed,
  Cancelled,
  PendingTransmit = 13,
  BankRejected = 14,
  Scheduled = 16,
  Expired = 17,
}

export enum AccountProcessingTypes {
  Native = 'NATIVE',
  Circle = 'CIRCLE',
}

export enum TransferTemplatePermissions {
  Public = 'Public',
  Private = 'Private',
}

export enum TransactionSourceTypes {
  InternalTransfer = 1,
  WireTransfer,
  BlockchainTransfer,
}
