import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { EmailValidationSchema } from '../../../../../validations/profile/auth';

// components
import InnerForm from './InnerForm';
import SubmitButton from '../../../../Buttons/SubmitButton';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';

export interface FormValuesModel {
  email: string;
}

type IProps = RequiredPropsForFormModel<FormValuesModel>;

const InvitationRequestForm = ({ onSubmit, initialValues }: IProps) => {
  const { t } = useTranslation('invitation');

  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={EmailValidationSchema}
      renderForm={
        <>
          <InnerForm />
          <SubmitButton fullWidth type="primary">
            {t('invitation_request.send_email_button')}
          </SubmitButton>
        </>
      }
    />
  );
};

export default InvitationRequestForm;
