import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../../resources/theme/styled/colors';
import { useFormikContext } from 'formik';

// components
import Button from '../../../../../DesignSystem/Core/Button';
import IconSVG from '../../../../../DesignSystem/Core/IconSVG';
import { Col, Row } from 'antd';
import { ReactComponent as ArrowRightIcon } from '../../../../../../resources/icons/remix-icons/arrow-right-s-line.svg';

export interface SubmitButtonsFormValuesModel {
  isSave: boolean | null;
  submitActionType: ActionKeys | null;
}

const ButtonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };

export type ActionKeys = 'save-exit' | 'save-back' | 'submit' | 'save';

interface IProps {
  showBackButton?: boolean;
  showOnlySaveButton?: boolean;
}

const SubmitButtons = ({
  showBackButton = true,
  showOnlySaveButton,
}: IProps) => {
  const { t } = useTranslation('common');
  const { values, submitForm, isSubmitting, setValues } =
    useFormikContext<SubmitButtonsFormValuesModel>();

  const onSaveAndExitClick = async () => {
    await setValues(
      { ...values, isSave: true, submitActionType: 'save-exit' },
      false,
    );
    submitForm();
  };

  const onSaveAndContinueClick = async () => {
    await setValues(
      { ...values, isSave: false, submitActionType: 'submit' },
      false,
    );
    submitForm();
  };

  const onBackClick = async () => {
    await setValues(
      { ...values, isSave: true, submitActionType: 'save-back' },
      false,
    );
    submitForm();
  };

  const onSaveClick = async () => {
    await setValues(
      { ...values, isSave: false, submitActionType: 'save' },
      false,
    );
    submitForm();
  };

  return (
    <Row justify="end" gutter={[16, 16]}>
      {!showOnlySaveButton && showBackButton && (
        <Col {...ButtonGridSizes}>
          <StyledButton
            size="large"
            type="bordered"
            onClick={onBackClick}
            loading={!!(values.isSave && isSubmitting)}
            disabled={isSubmitting}
          >
            {t('back')}
          </StyledButton>
        </Col>
      )}

      {showOnlySaveButton ? (
        <Col {...ButtonGridSizes}>
          <StyledButton
            size="large"
            onClick={onSaveClick}
            loading={!values.isSave && isSubmitting}
            disabled={isSubmitting}
          >
            {t('save')}
          </StyledButton>
        </Col>
      ) : (
        <>
          <Col {...ButtonGridSizes}>
            <StyledButton
              size="large"
              onClick={onSaveAndExitClick}
              loading={!!(values.isSave && isSubmitting)}
              disabled={isSubmitting}
            >
              {t('save_and_exit')}
            </StyledButton>
          </Col>
          <Col {...ButtonGridSizes}>
            <StyledButton
              size="large"
              onClick={onSaveAndContinueClick}
              loading={!values.isSave && isSubmitting}
              disabled={isSubmitting}
            >
              {t('save_and_continue')}{' '}
              <IconSVG
                component={ArrowRightIcon}
                color={colorsTheme.colorDark}
              />
            </StyledButton>
          </Col>
        </>
      )}
    </Row>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default SubmitButtons;
