import React from 'react';

// helpers
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { OnboardingStatusModel } from '../../../../../../../../../typings/onboarding/onboarding';

// components
import ApplicationDocumentation from '../Tabs/ApplicationDocumentation';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  onSave: (isCompleted: boolean) => void;
  onAction?: (actionType: ActionKeys | null) => void;

  isViewOnly?: boolean;
  canSendMessage?: boolean;
  canSubmitForReview?: boolean;
  onSubmitForReview?: () => void;
}

const CurrentOrganization = ({
  onboardingStatus,
  isViewOnly,
  canSubmitForReview,
  canSendMessage,
  onSave,
  onAction,
  onSubmitForReview,
}: IProps) => {
  return (
    <ApplicationDocumentation
      canSendMessage={canSendMessage}
      canSubmitForReview={canSubmitForReview}
      showSubmitForReview
      onboardingStatus={onboardingStatus}
      onSave={onSave}
      isViewOnly={isViewOnly}
      onAction={onAction}
      onSubmitForReview={onSubmitForReview}
    />
  );
};

export default CurrentOrganization;
