import React from 'react';

// components
import FormField from '@core_components/FormField';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';

const InnerForm = () => {
  return (
    <>
      <FormField
        name="message"
        component={FormInputTextArea}
        additionalProps={{
          autoSize: { minRows: 4, maxRows: 4 },
        }}
      />
      <FormField
        name="attachments"
        component={DocumentsField}
        additionalProps={{ isMultiple: true, isColumnReverse: true }}
      />
    </>
  );
};

export default InnerForm;
