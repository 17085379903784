import { documentsAPI } from '../../api/documentsAPI';
import { GetDataStatusEnum } from '../../enums/common';

// Actions
export enum ActionType {
  SET_DOCUMENTS = 'documents/SET_DOCUMENTS',
  SET_STATUS = 'documents/SET_STATUS',
  DESTROY = 'documents/DESTROY',
  CLEAN_UP = 'documents/CLEAN_UP',
}

// Action creators
export const getDocuments = (limit: number) => async (dispatch: any) => {
  dispatch({
    type: ActionType.SET_STATUS,
    payload: GetDataStatusEnum.PENDING,
    key: 'documents',
  });

  try {
    const documents = await documentsAPI.fetchDocuments({
      params: { pg: 0, limit },
    });

    dispatch({
      type: ActionType.SET_DOCUMENTS,
      payload: documents.data,
      key: 'documents',
    });

    dispatch({
      type: ActionType.SET_STATUS,
      payload: GetDataStatusEnum.SUCCESS,
      key: 'documents',
    });
  } catch {
    // TODO: Handle error
  }
};

export const destroy = () => (dispatch: any) => {
  dispatch({
    type: ActionType.DESTROY,
  });
};

export const cleanUpDocumentsReducer = () => ({
  type: ActionType.CLEAN_UP,
  payload: null,
});
