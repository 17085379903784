import { PermissionCheckModel } from '../../typings/profile/security';
import {
  ProfilePermissionKeys,
  ProfilePermissions,
} from './profilePermissions';
import {
  AccountsPermissionKeys,
  AccountsPermissions,
} from './accountsPermissions';
import {
  TransfersPermissionKeys,
  TransfersPermissions,
} from './transfersPermissions';
import {
  DashboardPermissionKeys,
  DashboardPermissions,
} from './dashboardPermissions';
import {
  OnboardingPermissionKeys,
  OnboardingPermissions,
} from './onboardingPermissions';
import {
  ApplicationPermissionKeys,
  ApplicationPermissions,
} from './applicationPermissions';
import {
  ConnectionsPermissionKeys,
  ConnectionsPermissions,
} from './connectionsPermissions';
import {
  AccountManagementPermissionKeys,
  AccountManagementPermissions,
} from './accountManagementPermissions';

export interface PermissionedAccessModel {
  requiredAllPermissions: boolean;
  permissions: PermissionCheckModel[];
  flag?: ALLOWED_FLAGS;
}

export type ALLOWED_FLAGS =
  | 'IS_CIRCLE_CONNECTED'
  | 'USER_HAS_CLIENT_GROUPS'
  | 'USER_WITHOUT_CLIENT_GROUPS';

export type PERMISSION_KEYS =
  | OnboardingPermissionKeys
  | AccountManagementPermissionKeys
  | ApplicationPermissionKeys
  | AccountsPermissionKeys
  | TransfersPermissionKeys
  | DashboardPermissionKeys
  | ConnectionsPermissionKeys
  | ProfilePermissionKeys;

export const permissionsConfig: Record<
  PERMISSION_KEYS,
  PermissionedAccessModel
> = {
  ...OnboardingPermissions,
  ...AccountManagementPermissions,
  ...ApplicationPermissions,
  ...AccountsPermissions,
  ...TransfersPermissions,
  ...DashboardPermissions,
  ...ConnectionsPermissions,
  ...ProfilePermissions,
};
