import { LayoutVariants } from 'redux/reducers/settings';

// Actions
export enum ActionType {
  SET_LANGUAGE = 'settings/SET_LANGUAGE',
  SET_LAYOUT_VARIANT = 'settings/SET_LAYOUT_VARIANT',
  CLEAN_UP = 'settings/CLEAN_UP',
}

// Action creators
export const setLanguage = (lang: string) => ({
  type: ActionType.SET_LANGUAGE,
  payload: lang,
  key: 'language',
});

export const setLayoutVariant = (variant: LayoutVariants | null) => ({
  type: ActionType.SET_LAYOUT_VARIANT,
  payload: { variant },
});

export const cleanUpSettingsReducer = () => ({
  type: ActionType.CLEAN_UP,
  payload: null,
});
