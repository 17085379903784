// helpers
import APIConfig from '../../config/api';
import { IEntity } from '../../typings/application/entity';
import { GraphData } from '../../components/Charts/GraphChart';
import { APIService } from '../axiosInstance';
import { ExpiringDocument } from '../../typings/application/applications';
import { RelationshipsGraph } from '../../typings/relationshipsGraph';
import { IOnboardingAccount } from '../../typings/onboarding/account';
import { onboardingAPIAdapter } from '../../apiAdapters/onboarding/onboardingAPIAdapter';
import { IKeyRelationshipTemplate } from '../../typings/application/key-relationship-templates';
import {
  ContactModel,
  ContactShortModel,
} from '../../typings/application/contact';
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from '../../enums/onboarding/crm';
import {
  AuditData,
  FetchParamsModel,
  FetchResponseModel,
} from '../../typings/common';
import {
  EmailAddress,
  IDocumentForm,
  OnboardingStatusModel,
  PhoneNumber,
} from '../../typings/onboarding/onboarding';
import {
  AccountInformationStepBodyModel,
  ApplicantInformationIndividualRequestBodyModel,
  ApplicantInformationOrganizationRequestBodyModel,
  ApplicationDocumentationBodyModel,
  IdentificationForIndividualRequestBodyModel,
  IdentificationRequestBodyModel,
  SourceOfWealthRequestBodyModel,
} from '../../typings/onboarding/onboarding';

export interface ApplicationDocumentationAnswerModel {
  _id: string;
  itemId: string;
  itemType: 'contact' | 'organization';
  clientGroupId: string;
  keyRelationshipTemplateId: string;

  field: {
    id?: string;
    name: string;
    type: AdditionalFieldTypes;
    description?: string;
    valueJSON: string;
  };

  review?: {
    isValid: boolean;
    comment?: string;
  };
}

export interface FetchApplicationDocumentationStepResponseModel {
  answers: { key: string; answers: ApplicationDocumentationAnswerModel[] }[];
}

export interface AnswerModel {
  _id: string;
  itemId: string;
  itemType: OnboardingEntryTypes;
  clientGroupId: string;

  answer: null | {
    isProvided: boolean;
    valueJSON: string;
    reason: string;
  };

  review: null | {
    isValid: boolean;
    comment: string;
  };

  field: {
    type: AdditionalFieldTypes;
    name: string;
    relatedTo: 'any' | 'contact' | 'organization';
    isTemplateField: boolean;
    placeholder: string;
    description: string;
    options: {
      selectOptions: { valueJSON: string; type: string }[];
      onboardingRequired: boolean;
      documentRequiresCertification: boolean;
      isForRegulatedOnly: boolean;
      formDocument: IDocumentForm;
    };
  };

  sources: {
    type: 'relationship' | 'client' | 'admin';
    id: string;
    templateId: string;
  }[];
}

export interface FetchOnboardingAnswersResponseModel {
  admin: FetchResponseModel<AnswerModel>;
  client: FetchResponseModel<AnswerModel>;
  relationship_keyRelationshipTemplate: FetchResponseModel<AnswerModel>;
}

export interface StartOnboardingBodyModel {
  type?: OnboardingEntryTypes;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  keyRelationshipTemplateId?: string;
  behaveOfSombodyElse?: boolean;
  code: string;
}

export interface UpdateContactShortInfoRequestBodyModel {
  firstName: string;
  lastName: string;
  middleName: string;
  isPEP?: boolean | null;
  pepInformation?: string;
  emails?: EmailAddress[];
  phoneNumbers?: PhoneNumber[];
}

export interface UpdateOrganizationShortInfoRequestBodyModel {
  legalName: string;
  isRegulated: boolean | null;
  regulationCountry: string[];
}

export interface ValidateStructureErrorModel {
  code: '1016001' | '1016002' | '1016003' | '1016004' | '1016006';
  message: string;
  details?: { total: string; equalTo: string; min?: string };
  relationshipTemplateId: string;
  type: 'warning' | 'critical';
  item: ContactModel | IEntity;
  keyRelationshipTemplate: IKeyRelationshipTemplate;
}

export interface ValidateStructureResponseModel {
  isValid: boolean;
  errors: ValidateStructureErrorModel[];
}

export type AccountManagementConfigFromQuery = {
  approversConfig: {
    min: number;
    max: number;
    selected: number;
  };
  participants: ContactShortModel[];
};

export type ApprovalGroupTypes =
  | 'onboarding-transaction'
  | 'onboarding-account-management';
export type ApprovalRuleTypes =
  | 'onboarding-transaction'
  | 'onboarding-account-management';

export type OnboardingApprovalGroup = {
  _id: string;
  _audit: AuditData;
  name: string;
  description: string;
  type: ApprovalGroupTypes;
  status: string;
  validationErrors: { code: number; message: string }[];
  participants: {
    contactId: string;
    name: string;
  }[];
};

export type CreateUpdateOnboardingApprovalRule = {
  type: ApprovalRuleTypes;
  associatedAccountIds?: string[];
  ruleSets: { items: { groupId: string; minimumThreshold: number }[] }[];
  criteriaList: {
    type: 'range' | 'key';
    activeSetIndices: number[];
    range?: { min: number; max: number | null };
    key?: string | number;
  }[];
};

export type OnboardingApprovalRuleFromQuery = {
  _id: string;
  _audit: AuditData;
  type: ApprovalRuleTypes;
  associatedAccounts: {
    _id: string;
    type: string;
    clientGroupId: string;
    currencyId: number;
    currencyName: string;
    currencyIsoCode: string;
    purpose: string;
  }[];
  ruleSets: {
    items: {
      group: {
        id: string;
        name: string;
        participants: {
          contactId: string;
          name: string;
        }[];
      };
      minimumThreshold: number;
    }[];
  }[];
  criteriaList: {
    type: 'range' | 'key';
    activeSetIndices: number[];
    range?: { min: number; max: number | null };
    key?: string;
  }[];
  validationErrors?: { code: number; message: string }[];
};

interface FetchExpiringDocumentParamsModel extends FetchParamsModel {
  entityIds: string;
}

function getApplicationHeaders({
  crmItemId,
  applicationId,
}: {
  crmItemId?: string;
  applicationId?: string;
}) {
  const headers: any = {};

  if (crmItemId) {
    headers.crmItemId = crmItemId;
  }

  if (applicationId) {
    headers.applicationId = applicationId;
  }

  return headers;
}

const onboardingAPI = {
  startOnboardingProcess: (body: StartOnboardingBodyModel) => {
    return APIService.post(`${APIConfig.crmPublicApi}/onboarding/start`, body, {
      addFingerprintToHeaders: true,
      addGeolocationDataToHeaders: true,
    }).then((response) => response?.data);
  },

  fetchStatusForOnboardingItem: (crmItemId: string, applicationId: string) => {
    return APIService.get<OnboardingStatusModel>(
      `${APIConfig.crmApi}/onboarding/status`,
      {
        headers: getApplicationHeaders({ crmItemId, applicationId }),
      },
    ).then((response) => response?.data);
  },

  fetchAccountInformationStepData: (applicationId: string) => {
    return APIService.get<FetchResponseModel<IOnboardingAccount>>(
      `${APIConfig.crmApi}/onboarding/accounts/my`,
      {
        headers: getApplicationHeaders({ applicationId }),
      },
    ).then(({ data }) =>
      onboardingAPIAdapter.fetchAccountInformationStepData(data.data),
    );
  },

  fetchApplicationDocumentationStepAnswers: (
    clientGroupId: string,
    crmItemId: string,
  ) => {
    return APIService.get<FetchOnboardingAnswersResponseModel>(
      `${APIConfig.crmApi}/client-documentation-items/${crmItemId}/grouped`,
      {
        params: { clientGroupId, limit: 100, page: 1 },
      },
    ).then((response) => response?.data);
  },

  fetchStructureStepRelationships: (
    onboardingItemId: string,
    relationshipScopeId: string,
    applicantOrganizationId: string,
    applicantContactId: string,
    relationshipScopeIdToCompareWith?: string,
    isApplicationUnderReview?: boolean,
  ) => {
    return APIService.get<RelationshipsGraph>(
      `${APIConfig.crmApi}/relationships/${onboardingItemId}/structure`,
      {
        params: { relationshipScopeId, relationshipScopeIdToCompareWith },
      },
    ).then<GraphData>(({ data }) =>
      onboardingAPIAdapter.fetchStructureStepRelationships(
        data,
        applicantOrganizationId,
        applicantContactId,
        isApplicationUnderReview,
      ),
    );
  },

  fetchExpiringDocuments: (params: FetchExpiringDocumentParamsModel) => {
    return APIService.get<FetchResponseModel<ExpiringDocument>>(
      `${APIConfig.crmApi}/expiring-document`,
      { params },
    ).then((response) => response?.data);
  },

  submitApplicantInformationOrganizationStep: (
    body: ApplicantInformationOrganizationRequestBodyModel,
    applicationId: string,
    crmItemId?: string,
  ) => {
    return APIService.post(`${APIConfig.crmApi}/onboarding/application`, body, {
      headers: getApplicationHeaders({ applicationId, crmItemId }),
    });
  },

  submitApplicantInformationIndividualStep: (
    body: ApplicantInformationIndividualRequestBodyModel,
    applicationId: string,
    crmItemId?: string,
  ) => {
    return APIService.post(`${APIConfig.crmApi}/onboarding/application`, body, {
      headers: getApplicationHeaders({ applicationId, crmItemId }),
    });
  },

  submitIdentificationStep: (
    body: IdentificationRequestBodyModel,
    applicationId: string,
    crmItemId?: string,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/identification`,
      body,
      {
        headers: getApplicationHeaders({ applicationId, crmItemId }),
      },
    );
  },

  submitIdentificationForIndividualStep: (
    body: IdentificationForIndividualRequestBodyModel,
    applicationId: string,
    crmItemId?: string,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/identification`,
      body,
      {
        headers: getApplicationHeaders({
          applicationId,
          crmItemId,
        }),
      },
    );
  },

  submitSourceOfWealthForIndividualStep: (
    body: SourceOfWealthRequestBodyModel,
    applicationId: string,
    crmItemId?: string,
  ) => {
    return APIService.post(`${APIConfig.crmApi}/onboarding/history`, body, {
      headers: getApplicationHeaders({ applicationId, crmItemId }),
    });
  },

  submitAccountInformationStep: (
    body: AccountInformationStepBodyModel,
    applicationId: string,
  ) => {
    return APIService.post(`${APIConfig.crmApi}/onboarding/accounts`, body, {
      headers: getApplicationHeaders({ applicationId }),
    });
  },

  submitStructureStep: (applicationId?: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/relationships/submit`,
      undefined,
      {
        headers: getApplicationHeaders({ applicationId }),
      },
    );
  },

  submitApplicationDocumentationStep: (
    body: ApplicationDocumentationBodyModel,
  ) => {
    return APIService.patch(
      `${APIConfig.crmApi}/client-documentation-items/batch`,
      body,
    );
  },

  submitApplicationForReview: (clientGroupId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/submit`,
    );
  },

  submitClientsOnboardingProcess: (onboardingProcessId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/process/${onboardingProcessId}/submit`,
    ).then((response) => response?.data);
  },

  updateContactShortInfo: (
    contactId: string,
    body: UpdateContactShortInfoRequestBodyModel,
  ) => {
    return APIService.patch(
      `${APIConfig.crmApi}/contacts/${contactId}`,
      body,
    ).then((response) => response?.data);
  },

  updateOrganizationShortInfo: (
    organizationId: string,
    body: UpdateOrganizationShortInfoRequestBodyModel,
  ) => {
    return APIService.patch(
      `${APIConfig.crmApi}/entities/${organizationId}/short`,
      body,
    ).then((response) => response?.data);
  },

  validateStructureChart: (
    onboardingItemId: string,
    relationshipScopeId: string,
  ) => {
    return APIService.get<ValidateStructureResponseModel>(
      `${APIConfig.crmApi}/relationships/${onboardingItemId}/structure/validate`,
      {
        params: { relationshipScopeId },
      },
    ).then((res) => res.data);
  },

  createOnboardingProcess: (crmItemId: string) => {
    return APIService.post(`${APIConfig.crmApi}/onboarding/process`, {
      crmItemId,
    }).then((response) => response?.data);
  },

  deleteOnboardingProcess: (onboardingProcessId: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/onboarding/process/${onboardingProcessId}`,
    ).then((response) => response?.data);
  },

  handoffApplicationToContact: (contactId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/users/client-requests/application-handoff`,
      { contactId },
    ).then((response) => response?.data);
  },

  fetchOnboardingBankAccounts: (
    applicationId: string,
    skipAssociatedWithApprovalRule?: boolean,
  ) => {
    return APIService.get<FetchResponseModel<IOnboardingAccount>>(
      `${APIConfig.crmApi}/onboarding/accounts/my`,
      {
        headers: getApplicationHeaders({ applicationId }),
        params: { skipAssociatedWithApprovalRule },
      },
    ).then((response) => response.data);
  },

  fetchApprovalGroups: (params: FetchParamsModel, type: ApprovalGroupTypes) => {
    return APIService.get<FetchResponseModel<OnboardingApprovalGroup>>(
      `${APIConfig.crmApi}/onboarding/approval-groups`,
      {
        params: { ...params, type },
      },
    ).then((response) => response?.data);
  },

  fetchApprovalGroupById: (approvalGroupId: string) => {
    return APIService.get<OnboardingApprovalGroup>(
      `${APIConfig.crmApi}/onboarding/approval-groups/${approvalGroupId}`,
    ).then((response) => response?.data);
  },

  createApprovalGroup: (data: {
    name: string;
    description: string;
    type: ApprovalGroupTypes;
    participants: string[];
  }) => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/approval-groups`,
      data,
    ).then((response) => response?.data);
  },

  updateApprovalGroupById: (
    approvalGroupId: string,
    data: { name: string; description: string; participants: string[] },
  ) => {
    return APIService.patch(
      `${APIConfig.crmApi}/onboarding/approval-groups/${approvalGroupId}`,
      data,
    ).then((response) => response?.data);
  },

  deleteApprovalGroupById: (approvalGroupId: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/onboarding/approval-groups/${approvalGroupId}`,
    ).then((response) => response?.data);
  },

  submitTransactionApprovalGroupsStep: () => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/approval-groups/submit-transaction-groups`,
    ).then((response) => response?.data);
  },

  submitAccountManagementApprovalGroupsStep: () => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/approval-groups/submit-account-management-groups`,
    ).then((response) => response?.data);
  },

  fetchApprovalRules: (params: FetchParamsModel, type: ApprovalRuleTypes) => {
    return APIService.get<FetchResponseModel<OnboardingApprovalRuleFromQuery>>(
      `${APIConfig.crmApi}/onboarding/approval-rules`,
      {
        params: { ...params, type },
      },
    ).then((response) => response?.data);
  },

  fetchApprovalRuleById: (approvalRuleId: string) => {
    return APIService.get<OnboardingApprovalRuleFromQuery>(
      `${APIConfig.crmApi}/onboarding/approval-rules/${approvalRuleId}`,
    ).then((response) => response?.data);
  },

  createApprovalRule: (data: CreateUpdateOnboardingApprovalRule) => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/approval-rules`,
      data,
    ).then((response) => response?.data);
  },

  updateApprovalRuleById: (
    approvalRuleId: string,
    data: CreateUpdateOnboardingApprovalRule,
  ) => {
    return APIService.patch(
      `${APIConfig.crmApi}/onboarding/approval-rules/${approvalRuleId}`,
      data,
    ).then((response) => response?.data);
  },

  deleteApprovalRuleById: (approvalRuleId: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/onboarding/approval-rules/${approvalRuleId}`,
    ).then((response) => response?.data);
  },

  submitTransactionApprovalRulesStep: () => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/approval-rules/submit-transaction-rules`,
    ).then((response) => response?.data);
  },

  submitAccountManagementApprovalRulesStep: () => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/approval-rules/submit-account-management-rules`,
    ).then((response) => response?.data);
  },

  submitAdministrationStep: () => {
    return APIService.post(
      `${APIConfig.crmApi}/onboarding/administration/submit`,
    ).then((response) => response?.data);
  },
};

export { onboardingAPI };
