import React, { memo, useMemo } from 'react';

// helpers
import { TransactionData } from '..';

// components
import ApprovalSection from './ApprovalSection';
import {
  default as InternalTransactionSender,
  InternalTransactionSenderData,
} from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Sender';
import {
  default as InternalTransactionRecipient,
  InternalTransactionRecipientData,
} from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Recipient';
import {
  default as WireTransactionSender,
  WireTransactionSenderData,
} from 'components/Additional/DetailsSections/Accounting/WireTransaction/Sender';
import {
  default as WireTransactionRecipient,
  WireTransactionRecipientData,
} from 'components/Additional/DetailsSections/Accounting/WireTransaction/Recipient';

interface IProps {
  initialData: TransactionData;
  onReview?: () => void;
  onUploadDocuments?: () => void;
}

const InnerContent = memo(
  ({ initialData, onReview, onUploadDocuments }: IProps) => {
    const content = useMemo(() => {
      switch (initialData.type) {
        case 'internal-sender':
          return (
            <InternalTransactionSender
              onUpload={onUploadDocuments}
              transaction={
                initialData.transaction as InternalTransactionSenderData
              }
              journalEntryId={initialData.journalEntryId}
            />
          );

        case 'internal-recipient':
          return (
            <InternalTransactionRecipient
              onUpload={onUploadDocuments}
              transaction={
                initialData.transaction as InternalTransactionRecipientData
              }
              journalEntryId={initialData.journalEntryId}
            />
          );

        case 'wire-sender':
          return (
            <WireTransactionSender
              onUpload={onUploadDocuments}
              transaction={initialData.transaction as WireTransactionSenderData}
              transactionId={initialData.transactionId}
              journalEntryId={initialData.journalEntryId}
            />
          );

        case 'wire-recipient':
          return (
            <WireTransactionRecipient
              onUpload={onUploadDocuments}
              transaction={
                initialData.transaction as WireTransactionRecipientData
              }
              journalEntryId={initialData.journalEntryId}
            />
          );

        default:
          return null;
      }
    }, [initialData]);

    return (
      <>
        {content}
        <ApprovalSection initialData={initialData} onReview={onReview} />
      </>
    );
  },
);

export default InnerContent;
