import * as Yup from 'yup';
import { OTP_VALIDATION_RULE, STRING_VALIDATION_RULE } from '../common';

export const Enable2FAuthenticatorValidationSchema = Yup.object().shape({
  code: OTP_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'common:one_time_password' },
  }),
});

export const RegisterSecurityKeyValidationSchema = Yup.object().shape({
  name: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'profile:security.two_step_verification.security_key.edit_key.form_fields.name',
    },
  }),
});

export const UpdateSecurityKeyValidationSchema = Yup.object().shape({
  name: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'profile:security.two_step_verification.security_key.edit_key.form_fields.name',
    },
  }),
});
