import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { WireDetailsModel } from '../../../../../../api/accounting/accountsAPI';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DescriptionSection, {
  DescriptionItem as DescriptionItemModel,
} from '@core_components/DescriptionSection';
import { Col, Row } from 'antd';

interface IProps {
  data: WireDetailsModel;
}

const WireDetails = ({ data }: IProps) => {
  const { t } = useTranslation('accounts');

  const descriptionsData = useMemo<{
    beneficiary: DescriptionItemModel[];
    beneficiaryBank: DescriptionItemModel[];
    intermediaryBank: DescriptionItemModel[];
    reference: DescriptionItemModel[];
  }>(() => {
    return {
      beneficiary: [
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.account_name',
              )}
              :
            </>
          ),
          description: data.beneficiaryAccountName,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.account_number',
              )}
              :
            </>
          ),
          description: data.beneficiaryAccountNumber,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.address',
              )}
              :
            </>
          ),
          description: data.beneficiaryAddress,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.city',
              )}
              :
            </>
          ),
          description: data.beneficiaryCity,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.district',
              )}
              :
            </>
          ),
          description: data.beneficiaryDistrict,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.postcode',
              )}
              :
            </>
          ),
          description: data.beneficiaryPostCode,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.country',
              )}
              :
            </>
          ),
          description: COUNTRY_CODES[data.beneficiaryCountry] || '',
        },
      ],
      beneficiaryBank: [
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_name',
              )}
              :
            </>
          ),
          description: data.beneficiaryBankName,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_address',
              )}
              :
            </>
          ),
          description: data.beneficiaryBankAddress,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_city',
              )}
              :
            </>
          ),
          description: data.beneficiaryBankCity,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_district',
              )}
              :
            </>
          ),
          description: data.beneficiaryBankDistrict,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_postcode',
              )}
              :
            </>
          ),
          description: data.beneficiaryBankPostCode,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_country',
              )}
              :
            </>
          ),
          description: COUNTRY_CODES[data.beneficiaryBankCountry] || '',
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_code_type',
              )}
              :
            </>
          ),
          description: data.beneficiaryBankCodeType,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.bank_code',
              )}
              :
            </>
          ),
          description: data.beneficiaryBankCode,
        },
      ],
      intermediaryBank: [
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_name',
              )}
              :
            </>
          ),
          description: data.intermediaryBankName,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_address',
              )}
              :
            </>
          ),
          description: data.intermediaryBankAddress,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_city',
              )}
              :
            </>
          ),
          description: data.intermediaryBankCity,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_district',
              )}
              :
            </>
          ),
          description: data.intermediaryBankDistrict,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_postcode',
              )}
              :
            </>
          ),
          description: data.intermediaryBankPostCode,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_country',
              )}
              :
            </>
          ),
          description: COUNTRY_CODES[data.intermediaryBankCountry] || '',
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_code_type',
              )}
              :
            </>
          ),
          description: data.intermediaryBankCodeType,
        },
        {
          label: (
            <>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.bank_code',
              )}
              :
            </>
          ),
          description: data.intermediaryBankCode,
        },
      ],
      reference: [
        {
          label: '',
          description: data.reference,
        },
      ],
    };
  }, [data]);

  return (
    <Wrapper>
      <StyledRow>
        <Col span={6}>
          {t(
            'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary.title',
          )}
          :
        </Col>
        <Col span={14}>
          <DescriptionSection
            size="small"
            background="transparent"
            bordered={false}
            data={descriptionsData.beneficiary}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span={6}>
          {t(
            'account_activity.send_wire_instructions.native.wire_instrucations_details.beneficiary_bank.title',
          )}
          :
        </Col>
        <Col span={18}>
          <DescriptionSection
            size="small"
            background="transparent"
            bordered={false}
            data={descriptionsData.beneficiaryBank}
          />
        </Col>
      </StyledRow>
      {data.isManualBeneficiary && (
        <Row>
          <Col span={6}>
            {t(
              'account_activity.send_wire_instructions.native.wire_instrucations_details.reference',
            )}
            :
          </Col>
          <Col span={18}>
            <DescriptionSection
              size="small"
              background="transparent"
              bordered={false}
              data={descriptionsData.reference}
            />
          </Col>
        </Row>
      )}
      {data.intermediaryBankCode && (
        <>
          <Row>
            <Col span={6}>
              {t(
                'account_activity.send_wire_instructions.native.wire_instrucations_details.intermediary_bank.title',
              )}
              :
            </Col>
            <Col span={18}>
              <DescriptionSection
                size="small"
                background="transparent"
                bordered={false}
                data={descriptionsData.intermediaryBank}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormField
                name="reference"
                component={FormInput}
                label={t(
                  'account_activity.send_wire_instructions.native.form_fields.reference',
                )}
              />
            </Col>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colorDarkL2};
  padding: ${({ theme }) => theme.paddingXl};
  border-radius: ${({ theme }) => theme.borderRadiusRegular};
  border: 2px solid ${({ theme }) => theme.cardBorderColor};
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

export default WireDetails;
