import React, { useEffect, useState } from 'react';

// helpers
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import AddSecurityKey from '../../modules/Profile/Security/TwoFactorAuthentication/ManageSecurityKeys/AddSecurityKey';
import ManageSecurityKeysTable from '../../modules/Profile/Security/TwoFactorAuthentication/ManageSecurityKeys/ManageSecurityKeysTable';

const ManageSecurityKeys = () => {
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const [updateKeysTrigger, updateKeys] = useState({});

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      extra: () => <AddSecurityKey onAdd={() => updateKeys({})} />,
    }));
    return () => clearContext();
  }, [setPageLayoutOptions, clearContext]);

  return <ManageSecurityKeysTable updateTableTrigger={updateKeysTrigger} />;
};

export default ManageSecurityKeys;
