import React, { useMemo } from 'react';

//  helpers
import { useTranslation } from 'react-i18next';
import { BlockchainAddressModel } from '../../../../../typings/accounting/accounts';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<BlockchainAddressModel>;

const CircleAddressesTable = (props: IProps) => {
  const { t } = useTranslation('accounts');

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        key: 'address',
        title: t(
          'account_activity.send_wire_instructions.circle.addresses_table.address',
        ),
        render: (record: BlockchainAddressModel) => record.address,
      },

      {
        key: 'address_tag',
        title: t(
          'account_activity.send_wire_instructions.circle.addresses_table.address_tag',
        ),
        render: (record: BlockchainAddressModel) => record.addressTag,
      },

      {
        key: 'network',
        title: t(
          'account_activity.send_wire_instructions.circle.addresses_table.network',
        ),
        render: (record: BlockchainAddressModel) => record.chain,
      },

      {
        key: 'currency',
        title: t(
          'account_activity.send_wire_instructions.circle.addresses_table.currency',
        ),
        render: (record: BlockchainAddressModel) => record.currency,
      },
    ];
  }, []);

  return <Table {...props} columns={columns} />;
};

export default CircleAddressesTable;
