import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import Text from '@core_components/Text';
import FormErrorTooltip from '../../../../../../Forms/FormErrorTooltip';

const TotalPercentage = () => {
  const { t } = useTranslation('account_management');
  const { values } = useFormikContext<FormValuesModel>();

  const totalPercentage = useMemo(() => {
    let result = 0;

    if (values.ownerships.length) {
      result = values.ownerships.reduce((total: number, ownership) => {
        if (ownership.additionalFields[values.ownershipFieldKey].value) {
          const fomattedNumber =
            Number(
              ownership.additionalFields[values.ownershipFieldKey].value,
            ) || 0;
          return total + fomattedNumber;
        }

        return total;
      }, 0);
    }

    return result;
  }, [values]);

  return (
    <>
      <StyledLabelText variant="label1" color={colorsTheme.colorAdditionalL1}>
        {t('client_profile.ownership_modal.table.total_label', {
          field: values.ownershipFieldKey,
        })}
      </StyledLabelText>
      <StyledValueText variant="body1">
        {(+totalPercentage).toFixed(2)}
      </StyledValueText>
      <FormErrorTooltip errorKey="ownerships" />
    </>
  );
};

const StyledLabelText = styled(Text)`
  display: inline-block;
`;

const StyledValueText = styled(Text)`
  display: inline-block;
  padding-left: ${({ theme }) => theme.paddingSm};
`;

export default TotalPercentage;
