import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// compnents
import Text from '@core_components/Text';
import IconSVG from '@core_components/IconSVG';
import OTPField from 'components/Forms/FormComponents/OTPField';
import FormField from '@core_components/FormField';
import SubmitButton from 'components/Buttons/SubmitButton';
import { Divider } from 'antd';
import { ReactComponent as VerifiedIcon } from 'resources/icons/remix-icons/shield-check-line.svg';

export interface FormValuesModel {
  code: string | null;
}

const TFAVerificationForm = () => {
  const { t } = useTranslation(['profile', 'common']);

  return (
    <ContentWrapper>
      <StyledIcon
        component={VerifiedIcon}
        color={colorsTheme.colorWhite}
        size="large"
      />

      <Text variant="h5" weight="semi-bold" gutterBottom gutterTop>
        {t(
          'security.two_step_verification.authenticator.confirm_settings_change.second_title',
        )}
      </Text>

      <StyledAlignCenterText variant="body1">
        {t(
          'security.two_step_verification.authenticator.confirm_settings_change.second_title_description',
        )}
      </StyledAlignCenterText>

      <Divider />

      <StyledAlignCenterText variant="label1">
        {t(
          'security.two_step_verification.authenticator.confirm_settings_change.form_fields.otp_code',
        )}
      </StyledAlignCenterText>

      <OTPFieldWrapper>
        <FormField
          name="code"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </OTPFieldWrapper>

      <SubmitButton type="primary">
        {t('submit', { ns: 'common' })}
      </SubmitButton>
    </ContentWrapper>
  );
};

const StyledIcon = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledAlignCenterText = styled(Text)`
  display: flex;
  justify-content: center;
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

export default TFAVerificationForm;
