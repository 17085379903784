import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { onboardingAPI } from '../../../../../../../../../api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from '../../../../../../../../../apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from '../../../../../../../../../typings/onboarding/onboarding';

// components
import LoadingWrapper from '../../../../../../../../../components/WrapperComponents/LoadingWrapper';
import ApplicationDocumentationForm, {
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/ApplicationDocumentationForm';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  isViewOnly: boolean;
  canComplete: boolean;
  onSave: () => void;
  onComplete: () => void;
}

const ApplicationDocumentation = ({
  onboardingStatus,
  isViewOnly,
  canComplete,
  onSave,
  onComplete,
}: IProps) => {
  const { t } = useTranslation(['common', 'onboarding']);
  const [updateAnswersTrigger, updateAnswers] = useState<any>();

  const { response: answersResponse, loading: answersLoader } = useFetch(
    () =>
      onboardingAPI.fetchApplicationDocumentationStepAnswers(
        onboardingStatus.clientGroupId as string,
        onboardingStatus.crmItemId,
      ),
    [onboardingStatus, updateAnswersTrigger],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!answersResponse) {
      return null;
    }

    return onboardingAPIAdapter.generateDataForApplicationDocumentationFromOnboardingAnswers(
      answersResponse,
      onboardingStatus,
    );
  }, [answersResponse, onboardingStatus]);

  const onSubmit = async (values: FormValuesModel) => {
    switch (values.submitActionType) {
      case 'save':
        {
          await submitApplicationDocumentationStep(values);
          message.success(t('success_save'));
          updateAnswers({});
          onSave();
        }
        break;

      case 'submit':
        {
          await submitApplicationDocumentationStep(values);
          await onboardingAPI.submitClientsOnboardingProcess(
            onboardingStatus._id,
          );
          message.success(t('success_submit'));
          onComplete();
        }
        break;
    }
  };

  async function submitApplicationDocumentationStep(values: FormValuesModel) {
    const formattedBody =
      onboardingAPIAdapter.submitApplicationDocumentationStep(
        values,
        initialFormValues as FormValuesModel,
        onboardingStatus.clientGroupId as string,
        onboardingStatus.crmItemId,
        onboardingStatus.type,
      );

    if (formattedBody.operations.length) {
      await onboardingAPI.submitApplicationDocumentationStep(formattedBody);
    }
  }

  return (
    <LoadingWrapper loading={answersLoader}>
      {initialFormValues && (
        <ApplicationDocumentationForm
          canSubmitForReview={canComplete}
          disabled={isViewOnly}
          onSubmit={onSubmit}
          initialFormValues={initialFormValues}
          submitButtonText={t('confirm_complete')}
        />
      )}
    </LoadingWrapper>
  );
};

export default ApplicationDocumentation;
