import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { ITransferTemplateShortModel } from '../../../../../../../typings/accounting/transfer';

// constants
import { TransferTemplateTypes } from '../../../../../../../enums/accounting/transfers';

// components
import Text from '@core_components/Text';
import EllipsisTooltip from '../../../../../../Tooltips/EllipsisTooltip';
import { Col, Row } from 'antd';

interface IProps {
  template: ITransferTemplateShortModel;
}

const TemplateDetail = memo(
  ({ title, details }: { title: string; details: string[] }) => {
    return (
      <Row>
        <Col xxl={10} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Text variant="body1">{title}</Text>
        </Col>
        <Col flex="auto">
          {details.map((e, i) => (
            <EllipsisTooltip
              placement="top"
              overlayStyle={{ maxWidth: '400px' }}
              maxTextContainerWidth="285px"
              title={e}
              key={i}
            >
              {e}
            </EllipsisTooltip>
          ))}
        </Col>
      </Row>
    );
  },
);

const TemplateShortInfo = memo(({ template }: IProps) => {
  const { t } = useTranslation('transfers');

  const renderTemplate = ({
    templateTypeId,
    additionalData,
  }: ITransferTemplateShortModel) => {
    switch (templateTypeId) {
      case TransferTemplateTypes.Internal:
        return (
          <>
            <TemplateDetail
              title={`${t('transfer_templates.transfer_template_modal.form_fields.internal_transfer_template.account_name')}:`}
              details={[additionalData?.accountName]}
            />
            <TemplateDetail
              title={`${t('transfer_templates.transfer_template_modal.form_fields.internal_transfer_template.account_number')}:`}
              details={[additionalData?.accountNumber]}
            />
          </>
        );

      case TransferTemplateTypes.Wire:
        return (
          <>
            <TemplateDetail
              title={`${t('transfer_templates.transfer_template_modal.form_fields.international_transfer_template.account_name')}:`}
              details={[additionalData?.accountName]}
            />
            <TemplateDetail
              title={`${t('transfer_templates.transfer_template_modal.form_fields.international_transfer_template.account_number')}:`}
              details={[additionalData?.accountNumber]}
            />
            {additionalData?.beneficiaryBankData && (
              <TemplateDetail
                title={`${t('transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_bank')}:`}
                details={[
                  additionalData.beneficiaryBankData.bankCode,
                  additionalData.beneficiaryBankData.bankName,
                ]}
              />
            )}
            {additionalData?.intermediaryBankData && (
              <TemplateDetail
                title={`${t('transfer_templates.transfer_template_modal.form_fields.international_transfer_template.intermediary_bank')}:`}
                details={[
                  additionalData.intermediaryBankData.bankCode,
                  additionalData.intermediaryBankData.bankName,
                ]}
              />
            )}
          </>
        );

      case TransferTemplateTypes.Blockchain:
        return (
          <>
            <TemplateDetail
              title={`${t('transfer_templates.transfer_template_modal.form_fields.blockchain_transfer_template.network')}:`}
              details={[additionalData.chain]}
            />
            <TemplateDetail
              title={`${t('transfer_templates.transfer_template_modal.form_fields.blockchain_transfer_template.address')}:`}
              details={[additionalData.address]}
            />
            <TemplateDetail
              title={`${t('transfer_templates.transfer_template_modal.form_fields.blockchain_transfer_template.address_tag')}:`}
              details={[additionalData.tag]}
            />
          </>
        );
    }
  };

  return <>{renderTemplate(template)}</>;
});

export default TemplateShortInfo;
