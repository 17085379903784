import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import Button from '../../../../../../DesignSystem/Core/Button';
import TableCell from './TableCell';
import Table, { TableColumnModel } from '@core_components/Table';

interface IProps {
  isDisabledFields?: boolean;
  handleAdd: () => void;
  handleDelete: (index: number) => void;
}

const TravelNotificationTable = ({
  isDisabledFields,
  handleAdd,
  handleDelete,
}: IProps) => {
  const { t } = useTranslation('profile');

  const { values, errors, dirty } = useFormikContext<FormValuesModel>();

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    const baseTableColumns = [
      {
        width: '50%',
        title: t(
          'travel_notifications.add_travel_notification_modal.form_fields.country',
        ),
        key: 'country',
        dataIndex: 'country',
      },
      {
        title: t(
          'travel_notifications.add_travel_notification_modal.form_fields.departure_date',
        ),
        key: 'departureDate',
        dataIndex: 'departure_date',
      },
      {
        title: t(
          'travel_notifications.add_travel_notification_modal.form_fields.return_date',
        ),
        key: 'returnDate',
        dataIndex: 'return_date',
      },
      {
        key: 'delete',
        width: '5%',
        dataIndex: 'delete',
      },
    ];

    return baseTableColumns.map((col: any) => ({
      ...col,
      onCell: (record: any, index: number) => ({
        index,
        record,
        dataIndex: col.dataIndex,
        canDelete: index >= 1,
        onDelete: () => handleDelete(index),
        isDisabled: isDisabledFields || index !== values.countries.length - 1,
      }),
    }));
  }, [values.countries]);

  const canAddNewCountry = () => !Object.keys(errors).length && dirty;

  return (
    <>
      <Table
        bordered
        size="small"
        columns={tableColumns}
        data={values.countries}
        components={{ body: { cell: TableCell } }}
      />

      <ButtonWrapper>
        <Button
          onClick={handleAdd}
          disabled={!canAddNewCountry() || isDisabledFields}
        >
          {t('travel_notifications.add_travel_notification_modal.add_country')}
        </Button>
      </ButtonWrapper>
    </>
  );
};
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: ${({ theme }) => theme.marginXs};
`;

export default TravelNotificationTable;
