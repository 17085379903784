import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import WarningWithPopover from '../WarningIconWithPopover';

const BankAccountIsNotApproved = memo(() => {
  const { t } = useTranslation('accounts');

  return (
    <WarningWithPopover
      popoverContent={t('account_activity.account_is_not_approved_tooltip')}
    />
  );
});

export default BankAccountIsNotApproved;
