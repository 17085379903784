import React, { useCallback, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useUserAccess from '../../../hooks/useUserAccess';
import useTranslation from '../../../hooks/useTranslation';
import { StateModel } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from 'resources/theme/styled/colors';
import { clientGroupsAPI } from '../../../api/crm/clientGroupsAPI';

// components
import Text from '@core_components/Text';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import UserStepsSection from './UserStepsSection';
import { CardWrapper } from 'components/Additional/CardWrapper';

// images
import DarkBanner from '../../../resources/images/background-images/dark-banner.png';

export type StepKeys =
  | 'twoFactorAuthentication'
  | 'setupClientUser'
  | 'setupBankAccount';

const WelcomeToProvaSection = () => {
  const { t } = useTranslation('dashboard');
  const { response, loading } = useFetch(
    () => clientGroupsAPI.fetchClientGroupDashboardStatuses(),
    [],
  );

  const is2FAEnabled = useSelector<StateModel, boolean>(
    (state) =>
      !!(
        state.auth.profileData?.isTwoFactorFidoEnabled ||
        state.auth.profileData?.isTwoFactorAuthenticationEnabled
      ),
  );

  const [stepStatuses, setStepStatuses] = useState<Partial<
    Record<StepKeys, boolean>
  > | null>(null);

  const [
    hasCreateUserAccess,
    hasEnableUserAccess,
    hasTransactionApprovalGroupCreateAccess,
    hasTransactionApprovalRuleCreateAccess,
  ] = useUserAccess([
    'AccountManagement_UserManagement_Create',
    'AccountManagement_UserManagement_EnableUser',
    'AccountManagement_TransactionRules_ApprovalGroups_Create',
    'AccountManagement_TransactionRules_ApprovalRules_Create',
  ]);

  useEffect(() => {
    if (response) {
      const result: Partial<Record<StepKeys, boolean>> = {
        twoFactorAuthentication: is2FAEnabled,
      };

      if (hasCreateUserAccess && hasEnableUserAccess) {
        result['setupClientUser'] = response.first_user_added;
      }

      if (
        hasTransactionApprovalGroupCreateAccess &&
        hasTransactionApprovalRuleCreateAccess
      ) {
        result['setupBankAccount'] = response.first_account_created;
      }

      setStepStatuses(result);
    }
  }, [
    response,
    is2FAEnabled,
    hasCreateUserAccess,
    hasEnableUserAccess,
    hasTransactionApprovalGroupCreateAccess,
    hasTransactionApprovalRuleCreateAccess,
  ]);

  const areAllStepsCompleted = useMemo(() => {
    if (!stepStatuses) {
      return true;
    }

    const notCompletedStep = Object.keys(stepStatuses).find((key) => {
      return !stepStatuses[key as StepKeys];
    });

    return !notCompletedStep;
  }, [stepStatuses]);

  const handleSkipStep = useCallback(
    (stepKey: StepKeys) => {
      setStepStatuses((prev) => {
        return prev && { ...prev, [stepKey]: true };
      });
    },
    [stepStatuses],
  );

  return (
    <StyledCardWrapper>
      <LoadingWrapper loading={loading}>
        <Text variant="h3" weight="semi-bold" color={colorsTheme.colorWhite}>
          {t('welcome_section.title')}
        </Text>
        {!areAllStepsCompleted && stepStatuses && (
          <UserStepsSection
            stepStatuses={stepStatuses}
            onSkipStep={handleSkipStep}
          />
        )}
      </LoadingWrapper>
    </StyledCardWrapper>
  );
};

const StyledCardWrapper = styled(CardWrapper)`
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};

  background-image: url(${DarkBanner});
  background-size: cover;
  background-position: center;

  .ant-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Add base styles for child antd Steps components */
  .ant-steps {
    .ant-steps-item {
      .ant-steps-item-icon {
        width: 28px;
        height: 28px;
        line-height: 30px;
      }

      .ant-steps-item-title {
        font-size: ${({ theme }) => theme.fontSizeSm};
        font-weight: 600;
      }

      .ant-steps-item-description {
        font-weight: 600;
        color: ${({ theme }) => theme.colorLightD1};
      }
    }

    /* Update styles for active step container */

    /* Update styles for finished step container */
    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background: ${({ theme }) => theme.colorPrimary};

        .ant-steps-icon {
          color: #000;
        }
      }

      .ant-steps-item-title {
        color: ${({ theme }) => theme.colorPrimary};
      }
    }

    // Custom class for pending steps
    .step-pending {
      .ant-steps-item-icon {
        background: ${({ theme }) => theme.colorInfo}!important;
        border-color: ${({ theme }) => theme.colorInfo}!important;

        .ant-steps-icon {
          color: #000;
        }
      }

      .ant-steps-item-content .ant-steps-item-title {
        color: ${({ theme }) => theme.colorInfo}!important;
      }
    }

    .ant-steps-item-active {
      .ant-steps-item-icon {
        background: ${({ theme }) => theme.colorWhite};
        border-color: ${({ theme }) => theme.colorWhite};

        .ant-steps-icon {
          color: #000;
        }
      }

      .ant-steps-item-content .ant-steps-item-title {
        color: ${({ theme }) => theme.colorWhite};
      }
    }
  }
`;

export default WelcomeToProvaSection;
