import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { FormikHelpers } from 'formik';
import { ActivateUserValidationSchema } from '../../../../../../validations/profile/auth';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';

interface IProps {
  username: string;
  initialFormValues: FormValuesModel;
  handleSubmit: (
    values: FormValuesModel,
    form: FormikHelpers<any>,
  ) => Promise<void>;
}

export interface FormValuesModel {
  otpCode: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ActivateUserForm = ({
  username,
  initialFormValues,
  handleSubmit,
}: IProps) => {
  const { t } = useTranslation('server_errors');

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201020': {
        formikHelpers.setFieldError(
          'otpCode',
          t('1201020', { ns: 'server_errors' }),
        );
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form<FormValuesModel>
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      onSubmitError={handleOnSubmitError}
      validationSchema={ActivateUserValidationSchema}
      renderForm={<InnerForm username={username} />}
    />
  );
};

export default ActivateUserForm;
