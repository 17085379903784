import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { userAPI } from '../../../../../api/profile/userAPI';
import { StateModel } from '../../../../../redux/reducers';
import { setDefaultAuthenticator } from '../../../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from '../../../../../enums/profile/auth';
import { StateModel as AuthStateModel } from '../../../../../redux/reducers/auth';

// components
import AuthenticatorTypeSelect from './AuthenticatorTypeSelect';
import { message } from 'antd';

const ChangeDefaultAuthenticator = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('profile');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const value = useMemo(() => {
    if (profileData) {
      if (
        profileData?.default2FAType === TwoFactorVerificationTypes.Fido &&
        profileData?.isTwoFactorFidoEnabled
      ) {
        return TwoFactorVerificationTypes.Fido;
      } else if (
        profileData?.default2FAType ===
          TwoFactorVerificationTypes.Authenticator &&
        profileData?.isTwoFactorAuthenticationEnabled
      ) {
        return TwoFactorVerificationTypes.Authenticator;
      }
    }
  }, [profileData]);

  const canUpdateAuthenticator =
    profileData?.isTwoFactorFidoEnabled &&
    profileData?.isTwoFactorAuthenticationEnabled;

  const handleDefaultAuthenticatorSelect = async (
    authenticatorType: TwoFactorVerificationTypes,
  ) => {
    setIsSubmitting(true);
    await userAPI.changeDefaultAuthenticator(authenticatorType);
    dispatch(setDefaultAuthenticator(authenticatorType));
    message.success(
      t('security.two_step_verification.success_update_default_option'),
    );
    setIsSubmitting(false);
  };

  return (
    <AuthenticatorTypeSelect
      value={value}
      onSelect={handleDefaultAuthenticatorSelect}
      loading={isSubmitting}
      disabled={!canUpdateAuthenticator || isSubmitting}
    />
  );
};

export default ChangeDefaultAuthenticator;
