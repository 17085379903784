import React from 'react';

// helpers
import { FormikHelpers } from 'formik';
import { AccountInformationStepValidationSchema } from '../../../../../validations/onboarding-new/account-information';

// constants
import { AccountTypes } from '../../../../../enums/onboarding/crm';
import { WireRangeKeys } from '../../../FormComponents/SelectInputs/Accounting/WireRangesSelect';
import { InitialDepositRangeKeys } from '../../../FormComponents/SelectInputs/Accounting/InitialDepositRangeSelect';
import { EstimatedAmountFundsRangeKeys } from '../../../FormComponents/SelectInputs/Accounting/EstimatedAmountFundsRangeSelect';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

export interface AccountFormItemModel {
  id?: string;
  purpose: string;
  type: AccountTypes | null;
  currencyId: number | null;
  initialDeposit: InitialDepositRangeKeys | null;
  initialDepositOrigin: string | null;
  jurisdictions: string[];
  incomingWiresNumber: WireRangeKeys | null;
  estimatedIncomingFunds: EstimatedAmountFundsRangeKeys | null;
  outgoingWiresNumber: WireRangeKeys | null;
  estimatedOutgoingFunds: EstimatedAmountFundsRangeKeys | null;
}

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  accounts: AccountFormItemModel[];
}

interface IProps {
  initialFormValues: FormValuesModel;
  onSubmit: (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;

  disabled?: boolean;
  showOnlySaveButton?: boolean;
}

const AccountInformationForm = ({
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      disabled={disabled}
      validationSchema={AccountInformationStepValidationSchema}
      renderForm={
        <>
          <InnerForm />

          <HideIfDisabledForm>
            <SubmitButtons showOnlySaveButton={showOnlySaveButton} />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default AccountInformationForm;
