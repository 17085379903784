import React from 'react';

// components
import { default as BlockchainTransferForm } from '../../modules/Transfers/BlockchainTransfer';

const BlockchainTransfer = () => {
  return <BlockchainTransferForm />;
};

export default BlockchainTransfer;
