import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../resources/theme/styled/colors';
import { AuthenticatorActionEnum } from '../../../../../modules/Profile/Security/TwoFactorAuthentication/Authenticator/ManageAuthenticatorModal';

// components
import Text from '@core_components/Text';
import Button from '../../../../DesignSystem/Core/Button';
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Col, Row } from 'antd';
import { ReactComponent as SmartphoneIcon } from '../../../../../resources/icons/remix-icons/smartphone-line.svg';

interface IProps extends RequiredPropsForModalDialogModel {
  handleActionClick: (action: AuthenticatorActionEnum) => void;
}

const ManageAuthenticatorModal = ({
  isVisible,
  closeCallback,
  handleActionClick,
}: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <ModalDialog
      width={650}
      title={t(
        'security.two_step_verification.authenticator.manage_authenticator_title',
      )}
      isVisible={isVisible}
      hideFooterButtons
      closeCallback={closeCallback}
    >
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <Row wrap={false}>
            <Col flex="45px">
              <IconSVG
                component={SmartphoneIcon}
                color={colorsTheme.colorWhite}
                size="middle"
              />
            </Col>
            <Col flex="auto">
              <Text variant="body1" weight="semi-bold">
                {t('security.two_step_verification.authenticator.title')}
              </Text>
              <Text variant="body1">
                {t('security.two_step_verification.enabled_status')}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <StyledButton
            size="large"
            type="bordered"
            onClick={() =>
              handleActionClick(AuthenticatorActionEnum.CHANGE_DEVICE)
            }
          >
            {t(
              'security.two_step_verification.authenticator.change_device_button',
            )}
          </StyledButton>
        </Col>

        <Col span={6}>
          <StyledButton
            size="large"
            type="bordered"
            onClick={() => handleActionClick(AuthenticatorActionEnum.DISABLE)}
          >
            {t('security.two_step_verification.disable_button')}
          </StyledButton>
        </Col>
      </Row>
    </ModalDialog>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  min-width: 0px;
`;

export default ManageAuthenticatorModal;
