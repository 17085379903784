// helpers
import { PermissionedAccessModel } from '.';

// Permission keys
export type AccountsPermissionKeys =
  | 'Accounts_View'
  | 'Accounts_AccountSummary_View'
  | 'Accounts_FiatAccount_Create'
  | 'Accounts_BlockchainAccount_Create'
  | 'Accounts_AccountActivity_View'
  | 'Accounts_PendingTransactions_View'
  | 'Accounts_PendingTransactions_Review'
  | 'Accounts_TransactionModal_UploadDocuments';

// Permission config
export const AccountsPermissions: Record<
  AccountsPermissionKeys,
  PermissionedAccessModel
> = {
  Accounts_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:Account:Create' },
      { permissionKey: 'Accounting:Transaction:Read' },
      { permissionKey: 'Approval:TransactionWorkflow:Read' },
      { permissionKey: 'Approval:TransactionWorkflow:ApproveReject' },
    ],
  },

  Accounts_AccountSummary_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:Account:Create' },
    ],
  },

  Accounts_FiatAccount_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Account:Create' }],
  },

  Accounts_BlockchainAccount_Create: {
    flag: 'IS_CIRCLE_CONNECTED',
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Account:Create' }],
  },

  Accounts_AccountActivity_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Account:Read' }],
  },

  Accounts_PendingTransactions_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:Transaction:Read' },
      { permissionKey: 'Approval:TransactionWorkflow:Read' },
    ],
  },

  Accounts_PendingTransactions_Review: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Approval:TransactionWorkflow:Read' },
      { permissionKey: 'Approval:TransactionWorkflow:ApproveReject' },
    ],
  },

  Accounts_TransactionModal_UploadDocuments: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:JournalEntry:UploadDocument' }],
  },
};
