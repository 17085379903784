import React, { useMemo } from 'react';

// helpers
import { ContactHelpers } from '../../../../../../helpers/crm/contact';
import { OnboardingEntryTypes } from '../../../../../../enums/onboarding/crm';
import { ExistingOwnershipModel } from '../../../../TemplateForms/ClientProfile/OwnershipRelationshipForm';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface IProps extends SelectProps<ExistingOwnershipModel> {
  entries: ExistingOwnershipModel[];
}

const ExistingRelationshipSelect = ({ entries, ...rest }: IProps) => {
  const options = useMemo<SelectOption<ExistingOwnershipModel>[]>(
    () =>
      entries.map((e) => ({
        id: e.id,
        model: e,
        label:
          e.type === OnboardingEntryTypes.Contact
            ? ContactHelpers.getFormattedContactName(
                e.contact?.firstName || '',
                e.contact?.lastName || '',
                e.contact?.middleName || '',
              )
            : e.organization?.name,
      })),
    [entries],
  );

  return <FormSelect {...rest} options={options} />;
};

export default ExistingRelationshipSelect;
