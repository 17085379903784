import React, { useEffect } from 'react';

// helpers
import moment from 'moment';
import useTranslation from './hooks/useTranslation';
import useBrowserTabTitle from './hooks/useBrowserTabTitle';
import withJWTTokenCompare from 'hoc/withJWTTokenCompare';
import { styled } from 'styled-components';
import { userAPI } from 'api/profile/userAPI';
import { signOut } from 'redux/actions/auth';
import { StateModel } from './redux/reducers';
import { useLocation } from 'react-router-dom';
import { reactPlugin } from 'services/azure-app-insights/service';
import { initializeApp } from './redux/actions/app';
import { decodeJWTToken } from 'helpers/utils';
import { LocalStorageHelpers } from 'helpers/storages/localStorage';
import { LocalizationNamespaces } from './constants/localization';
import { useDispatch, useSelector } from 'react-redux';

// components
import LoadingPage from './pages/AdditionalPages/LoadingPage';
import RouteRenderer from './routes/RouteRenderer';

const REFRESH_TOKEN_BY_MS = 1000 * 60;

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const { t, ready } = useTranslation(LocalizationNamespaces);
  const isAuthorized = useSelector<StateModel>(
    (state) => state.auth.isAuthorized,
  );
  const isAppInitialized = useSelector<StateModel>(
    (state) => state.app.isInitialized,
  );

  const isApplicationLoading = useSelector<StateModel>(
    (state) =>
      !state.applications.activeApplication &&
      (state.applications.applicationsLoading ||
        state.applications.activeApplicationLoading),
  );

  // Get browser tab title based on the current location path
  const browserTabTitle = useBrowserTabTitle();

  // Update browser tab title if the current path has been changed
  useEffect(() => {
    if (!ready) {
      document.title = 'Loading...';
    }

    if (browserTabTitle && ready && t) {
      const { key, ns } = browserTabTitle;
      document.title = `${t(key, { ns })} | Tenet`;
    }
  }, [t, browserTabTitle, ready]);

  useEffect(() => {
    dispatch(initializeApp());
  }, []);

  useEffect(() => {
    if (isAppInitialized && isAuthorized) {
      refreshAccessTokenIfNeeded();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isAppInitialized, isAuthorized, timerRef]);

  useEffect(() => {
    if (reactPlugin) {
      // Assign the location object to track route changes
      reactPlugin.trackPageView({
        name: location.pathname, // Use pathname for the page name
        uri: location.pathname, // URI of the current page
      });
    }
  }, [location.pathname]);

  const getDifference = (token: string) => {
    const decodedAccessToken = decodeJWTToken(token);
    return decodedAccessToken
      ? moment(decodedAccessToken.exp * 1000).diff(
          moment(),
          'milliseconds',
          true,
        )
      : 0;
  };

  const refreshAccessToken = async () => {
    try {
      const { jwt, refreshToken } = await userAPI.refreshTokens(
        LocalStorageHelpers.getAccessToken(),
        LocalStorageHelpers.getRefreshToken(),
      );

      LocalStorageHelpers.setAuthenticationTokens(jwt, refreshToken);
      return jwt;
    } catch {
      dispatch(signOut(true));
    }
  };

  const refreshAccessTokenIfNeeded = async () => {
    const accessToken = LocalStorageHelpers.getAccessToken();
    const difference = getDifference(accessToken);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (difference < REFRESH_TOKEN_BY_MS) {
      const newToken = await refreshAccessToken();
      if (newToken) {
        const differenceNewToken = getDifference(newToken);
        timerRef.current = setTimeout(
          refreshAccessTokenIfNeeded,
          differenceNewToken - REFRESH_TOKEN_BY_MS,
        );
      }
    } else {
      timerRef.current = setTimeout(
        refreshAccessTokenIfNeeded,
        difference - REFRESH_TOKEN_BY_MS,
      );
    }
  };

  if (!isAppInitialized || isApplicationLoading || !(!!ready && !!t)) {
    return <LoadingPage />;
  }

  return (
    <StyledWrapper className="app">
      <RouteRenderer />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  overflow: hidden;
`;

export default withJWTTokenCompare()(App);
