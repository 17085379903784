import React, { memo, useMemo } from 'react';

// helpers
import {
  ClientGroupTemplateDataModel,
  ClientGroupUserChangeFromQuery,
} from '..';

// components
import ClientGroupUserChangesTable, {
  ClientGroupUserChange,
  ClientGroupUserUpdateTypes,
} from 'components/Tables/TableTemplates/AccountManagement/ClientGroupUserChangesTable';
import { Card } from 'antd';

interface IProps {
  approvalEntry: ClientGroupTemplateDataModel;
}

const UserChangesSection = memo(({ approvalEntry }: IProps) => {
  const formattedData = useMemo<ClientGroupUserChange[]>(() => {
    function getActionType(
      e: ClientGroupUserChangeFromQuery,
    ): ClientGroupUserUpdateTypes {
      if (!e.newPermissions && e.oldPermissions) {
        return 'deactivate';
      }

      if (e.newPermissions && !e.oldPermissions) {
        return 'create';
      }

      return 'update_permissions';
    }

    return approvalEntry.clientGroupUserChanges.map((e) => {
      return {
        action: getActionType(e),
        clientGroupUserId: e.clientGroupUserId,
        clientGroupUserName: e.clientGroupUserName,
        newPermissions: e.newPermissions,
        oldPermissions: e.oldPermissions,
      };
    });
  }, [approvalEntry]);

  return (
    <Card>
      <ClientGroupUserChangesTable data={formattedData} />
    </Card>
  );
});

export default UserChangesSection;
