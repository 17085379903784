import React from 'react';

// helpers
import { ApprovalEntryNewModel } from '../../../../../../typings/approvalManagement/accountManagement';

// components
import ApprovalEntryDetailsSection from './ApprovalEntryDetailsSection';
import ChangeDifferenceSection from './ChangeDifferenceSection';

interface IProps {
  approvalEntry: ApprovalEntryNewModel;
}

const InnerContent = ({ approvalEntry }: IProps) => {
  return (
    <>
      <ApprovalEntryDetailsSection approvalEntry={approvalEntry} />
      <ChangeDifferenceSection approvalEntry={approvalEntry} />
    </>
  );
};

export default InnerContent;
