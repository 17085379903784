import React from 'react';

// helpers
import styled from 'styled-components';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { LocalDocumentValueModel } from '..';

// constants
import { colorsTheme } from '../../../../../resources/theme/styled/colors';
import { UploadStatus } from '../../../../../constants/documents';

// components
import Text from '@core_components/Text';
import Spinner from '../../../../DesignSystem/Core/Spinner';
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import DocumentLink from '../../../../Typography/DocumentLink';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import { Col, Row, Spin } from 'antd';
import { ReactComponent as FailedIcon } from '../../../../../resources/icons/remix-icons/file-warning-line.svg';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/remix-icons/delete-bin-6-line.svg';
import { ReactComponent as WaitingFileIcon } from '../../../../../resources/icons/remix-icons/file-history-line.svg';

interface IProps {
  document: LocalDocumentValueModel;
  onDelete?: () => void;
}

const DocumentItem = ({ document, onDelete }: IProps) => {
  const uploadStatus = useSelector<StateModel, UploadStatus>((state) => {
    if (!document.localDocumentId) {
      return UploadStatus.DONE;
    } else {
      return (
        state.upload.files[document.localDocumentId]?.status ||
        UploadStatus.DONE
      );
    }
  });

  const renderActionsByStatus = (status: UploadStatus) => {
    switch (status) {
      case UploadStatus.WAIT:
        return (
          <IconSVG
            component={WaitingFileIcon}
            color={colorsTheme.colorLightD1}
          />
        );

      case UploadStatus.FAILED:
        return (
          <>
            <StyledIconWithRightGutter
              component={FailedIcon}
              color={colorsTheme.colorError}
            />
            <IconSVG
              component={DeleteIcon}
              onClick={onDelete}
              color={colorsTheme.colorError}
            />
          </>
        );

      case UploadStatus.DONE:
        return (
          <IconSVG
            component={DeleteIcon}
            onClick={onDelete}
            color={colorsTheme.colorError}
          />
        );

      default:
        return <Spin indicator={<Spinner />} />;
    }
  };

  return (
    <StyledRow align="middle" wrap={false}>
      <Col>
        <EllipsisTooltip title={document.label} maxTextContainerWidth="300px">
          {document.documentId && document.fileId ? (
            <DocumentLink
              key={document.localDocumentId}
              text={document.label}
              documentId={document.documentId}
              fileId={document.fileId}
            />
          ) : (
            <Text variant="body1">{document.label}</Text>
          )}
        </EllipsisTooltip>
      </Col>

      <Col flex="40px">
        <HideIfDisabledForm hideIfSubmitting>
          {onDelete && (
            <ActionNodeWrapper>
              {renderActionsByStatus(uploadStatus)}
            </ActionNodeWrapper>
          )}
        </HideIfDisabledForm>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXXs};
`;

const ActionNodeWrapper = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconWithRightGutter = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default DocumentItem;
