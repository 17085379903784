import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { accountManagementRulesAPI } from '../../../../api/accountManagement/accountManagementRulesAPI';

// components
import ApprovalsTable from './ApprovalsTable';
import ApprovalEntryModal from './ApprovalEntryModal';
import { Spin } from 'antd';

export interface ApprovalStatusMap {
  [key: number]: string;
}

const ApprovalsTab = () => {
  const [updateTableTrigger, updateTable] = useState({});
  const [approvalStatuses, setApprovalStatuses] = useState<ApprovalStatusMap>(
    {},
  );
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | null>(
    null,
  );
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const { response, loading } = useFetch(
    () => accountManagementRulesAPI.fetchApprovalWorkflowStatuses(),
    [],
  );

  useEffect(() => {
    if (response) {
      const result: ApprovalStatusMap = {};

      response.items.map(({ name, value }) => {
        result[value] = name;
      });

      setApprovalStatuses(result);
    }
  }, [response]);

  const handleModalClose = (shouldUpdateTable?: boolean) => {
    setSelectedWorkflowId(null);
    shouldUpdateTable && updateTable({});
  };

  const handleApprovalSelect = (approvalId: string) => {
    setSelectedWorkflowId(approvalId);
  };

  if (loading) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  return (
    <>
      <ApprovalsTable
        onApprovalSelect={handleApprovalSelect}
        approvalStatuses={approvalStatuses}
        updateTableTrigger={updateTableTrigger}
      />
      <ApprovalEntryModal
        isVisible={!!selectedWorkflowId}
        disabled={!isTwoFactorVerificationEnabled}
        workflowId={selectedWorkflowId}
        closeCallback={handleModalClose}
      />
    </>
  );
};

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;

  padding-top: ${({ theme }) => theme.paddingMd};
`;

export default ApprovalsTab;
