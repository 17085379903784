import React, { useEffect, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { IEntity } from '../../../../../typings/application/entity';
import { ContactModel } from '../../../../../typings/application/contact';
import { OnboardingStatusModel } from '../../../../../typings/onboarding/onboarding';

// constants
import {
  OnboardingEntryTypes,
  OnboardingStatuses,
} from '../../../../../enums/onboarding/crm';

// components
import Individual from './Content/Individual';
import Organization from './Content/Organization';
import SharedIconWithText from 'components/Additional/SharedIconWithText';
import IndividualWithoutKeyRelationship from './Content/IndividualWithoutKeyRelationship';
import OrganizationWithoutKeyRelationship from './Content/OrganizationWithoutKeyRelationship';

interface IProps {
  item: ContactModel | IEntity;
  itemType: OnboardingEntryTypes;
  onboardingStatus: OnboardingStatusModel | null;
}

const OnboardingFormSection = ({
  onboardingStatus,
  item,
  itemType,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    const isDisabled =
      !onboardingStatus ||
      onboardingStatus.status === OnboardingStatuses.Approved ||
      onboardingStatus.status === OnboardingStatuses.InReview ||
      onboardingStatus.status ===
        OnboardingStatuses.InAccountManagementReview ||
      onboardingStatus.status === OnboardingStatuses.Submitted;

    setIsViewOnly(isDisabled);
  }, [onboardingStatus]);

  const handleSubmit = () => {
    setIsViewOnly(true);
  };

  const renderContent = () => {
    switch (itemType) {
      case OnboardingEntryTypes.Contact: {
        return onboardingStatus ? (
          <>
            {onboardingStatus.isShared && (
              <SharedIconWithText
                text={t('application_documentation.shared_data')}
              />
            )}
            <Individual
              onboardingStatus={{ ...onboardingStatus, item }}
              isViewOnly={isViewOnly}
              onSubmit={handleSubmit}
            />
          </>
        ) : (
          <IndividualWithoutKeyRelationship contact={item as ContactModel} />
        );
      }

      case OnboardingEntryTypes.Organization:
        return onboardingStatus ? (
          <Organization
            onboardingStatus={{ ...onboardingStatus, item }}
            isViewOnly={isViewOnly}
            onSubmit={handleSubmit}
          />
        ) : (
          <OrganizationWithoutKeyRelationship organization={item as IEntity} />
        );
    }
  };

  return <>{renderContent()}</>;
};

export default OnboardingFormSection;
