import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../../redux/reducers';

import { colorsTheme } from '../../../../../../resources/theme/styled/colors';
import { generateUniqId } from '../../../../../../helpers/utils';
import { FormValuesModel } from '../../..';
import { DocumentHelpers } from '../../../../../../helpers/documents';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as UploadStateModel } from '../../../../../../redux/reducers/upload';
import {
  refreshUploadState,
  uploadFiles,
} from '../../../../../../redux/actions/upload';
import {
  RESET_AUTHENTICATION_REQUEST_TAGS,
  UploadStatus,
} from '../../../../../../constants/documents';
import {
  VerificationDocumentPhotoTypes,
  VerificationDocumentTypes,
} from '../../../../../../enums/profile/accountRecovery';

// constants
import { VerificationDocumentRequirements } from '../..';

// components
import Text from '@core_components/Text';
import Button from '../../../../../../components/DesignSystem/Core/Button';
import Webcam from 'react-webcam';
import IconSVG from '../../../../../../components/DesignSystem/Core/IconSVG';
import { Col, Row, Steps } from 'antd';
import { ReactComponent as CheckIcon } from '../../../../../../resources/icons/remix-icons/check-line.svg';

const { Step }: any = Steps;

const WebcamSnapshot = () => {
  const { t } = useTranslation(['common', 'auth']);

  // ---- contants ----
  const { values, setFieldValue, submitForm } =
    useFormikContext<FormValuesModel>();

  const webcamRef = useRef<any>(null);
  const dispatch = useDispatch();
  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const [isReady, setIsReady] = useState(false);
  const [tempSnapshot, setTempSnapshot] = useState<string | null>(null);

  const currentStep = useMemo(
    () => Object.keys(values.snapshots || {}).length || 0,
    [values.snapshots],
  );

  const activeSnapshotType = useMemo(() => {
    if (!values.idVerificationType) {
      return null;
    }

    return (
      VerificationDocumentRequirements[values.idVerificationType].steps[
        currentStep
      ]?.type || null
    );
  }, [values.idVerificationType, currentStep]);

  const isUploadedAllPhotos = useMemo(
    () =>
      Boolean(
        values.idVerificationType &&
          values.snapshots &&
          VerificationDocumentRequirements[values.idVerificationType].steps
            .length === Object.keys(values.snapshots).length,
      ),
    [values.idVerificationType, values.snapshots],
  );

  // configs, constants
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };
  // ---- end contants ----

  // ---- life cycle hooks ----
  useEffect(() => {
    const { isFinished, files } = uploadState;

    if (isFinished && values.idVerificationType) {
      const newSnapshotId = files[Object.keys(files)[0]].dataBaseId || '';
      onSnapshotUpload(newSnapshotId, activeSnapshotType, values.snapshots);
    }

    dispatch(refreshUploadState());
  }, [uploadState.isFinished]);
  // ---- end life cycle hooks ----

  // --- handlers ----
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setTempSnapshot(imageSrc || null);
  }, [webcamRef]);

  const handleSaveScreenshot = (
    photoType: VerificationDocumentPhotoTypes,
    tempFile: string | null,
  ) => {
    if (!tempFile) {
      return;
    }

    const generatedFile = DocumentHelpers.getFileFromDataURL(
      tempFile,
      'document_name',
    );

    const formattedField = {
      id: generateUniqId(),
      originalName: generatedFile.name,
      name: generatedFile.name,
      tags: RESET_AUTHENTICATION_REQUEST_TAGS,
      file: generatedFile,
    };

    dispatch(uploadFiles([formattedField], undefined, photoType));
  };

  const onSnapshotUpload = (
    newSnapshotId: string,
    photoType: VerificationDocumentPhotoTypes | null,
    oldSnapshots: any,
  ) => {
    if (!photoType) {
      return;
    }

    const newSnapshots = {
      ...oldSnapshots,
      [photoType]: newSnapshotId,
    };

    setFieldValue('snapshots', newSnapshots);
    setTempSnapshot(null);
    setIsReady(false);

    const isUploadedAllVerificationDocuments =
      values.idVerificationType &&
      Object.keys(newSnapshots).length ===
        VerificationDocumentRequirements[values.idVerificationType].steps
          .length;

    // the case when user has uploaded all photos
    // here we need to submit a form
    if (isUploadedAllVerificationDocuments) {
      submitForm();
    }
  };
  // ---end handlers ----

  // --- render methods ---
  const renderActionButtons = (
    activeSnapshotType: VerificationDocumentPhotoTypes | null,
    isUploading: boolean,
  ) => {
    let result = null;

    if (activeSnapshotType) {
      if (!isReady) {
        result = (
          <StyledButton onClick={() => setIsReady(true)}>
            {t('i_am_ready')}
          </StyledButton>
        );
      } else if (!tempSnapshot) {
        result = (
          <StyledButton onClick={capture}>{t('take_photo')}</StyledButton>
        );
      } else {
        result = (
          <Row justify="center" gutter={[20, 0]}>
            <Col>
              <StyledButton
                disabled={isUploading}
                onClick={() => setTempSnapshot(null)}
              >
                {t('retake')}
              </StyledButton>
            </Col>

            <Col>
              <StyledButton
                loading={isUploading}
                onClick={() =>
                  handleSaveScreenshot(activeSnapshotType, tempSnapshot)
                }
              >
                {t('save')}
              </StyledButton>
            </Col>
          </Row>
        );
      }
    }

    return result;
  };

  const renderSteps = (
    verificationType: VerificationDocumentTypes,
    activeStep: number,
  ) => {
    return VerificationDocumentRequirements[verificationType].steps.map(
      ({ icon }, i) => (
        <Step
          key={i}
          icon={
            <IconWrapper isActive={false}>
              {activeStep > i ? (
                <IconSVG color={colorsTheme.colorWhite} component={CheckIcon} />
              ) : (
                icon
              )}
            </IconWrapper>
          }
        />
      ),
    );
  };

  const renderStepHelperText = (
    verificationType: VerificationDocumentTypes,
    stepNumber: number,
  ) => {
    const { text } =
      VerificationDocumentRequirements[verificationType].steps[stepNumber];
    return <Text variant="body1">{t(text, { ns: 'auth' })}</Text>;
  };
  // --- end render methods ---

  return (
    <WebCamWrapper>
      <Webcam
        audio={false}
        width="100%"
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />

      {!isUploadedAllPhotos && (
        <AdditionalOverlay
          blur={!isReady}
          backgroundImage={tempSnapshot || undefined}
        >
          <StepsWrapper>
            <Steps
              labelPlacement="vertical"
              type="default"
              current={currentStep}
            >
              {values.idVerificationType &&
                renderSteps(values.idVerificationType, currentStep)}
            </Steps>
          </StepsWrapper>

          <div>
            {!isReady &&
              values.idVerificationType &&
              renderStepHelperText(values.idVerificationType, currentStep)}
          </div>

          <div>
            {renderActionButtons(
              activeSnapshotType,
              uploadState.status === UploadStatus.ACTIVE,
            )}
          </div>
        </AdditionalOverlay>
      )}
    </WebCamWrapper>
  );
};

const StepsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-steps-item-icon {
    width: 37px;
  }
`;

const WebCamWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  overflow: hidden;
  padding: 60px 0;
  min-height: 440px;
`;

const AdditionalOverlay = styled.div<{ blur: boolean; backgroundImage?: any }>`
  position: absolute;
  top: 0px;
  left: 0px;

  padding: ${({ theme }) => theme.paddingMd};

  background: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-position: center;

  backdrop-filter: ${({ blur }) => blur && 'blur(8px)'};
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizeXs};
  text-transform: none;
`;

const IconWrapper = styled.div<{ isActive: boolean }>`
  width: 35px;
  height: 35px;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: ${({ theme }) => theme.defaultTransition};

  border: 1.5px;
  border-radius: 50%;
  border: ${({ theme, isActive }) =>
    `1.5px solid ${isActive ? theme.colorPrimary : theme.cardBorderColor}`};

  font-size: 15px;
`;

export default WebcamSnapshot;
