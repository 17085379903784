import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from 'helpers/date';
import { ITransferTemplateShortModel } from '../../../../../typings/accounting/transfer';
import {
  AccountProcessingTypes,
  TransferTemplatePermissions,
  TransferTemplateTypes,
} from '../../../../../enums/accounting/transfers';

// components
import Link from '@common_components/Texts/Link';
import TemplateShortInfo from './Components/TemplateShortInfo';
import TransferTemplateStatus from 'components/Additional/Statuses/TransferTemplateStatus';
import DerivedFromCircleTooltip from '../../../../Tooltips/TemplateTooltips/DerivedFromCircleTooltip';
import Table, { RequiredPropsForTableModel } from '@core_components/Table';

interface IProps
  extends RequiredPropsForTableModel<ITransferTemplateShortModel> {
  onActionsClick: (
    key: ActionKeys,
    record: ITransferTemplateShortModel,
  ) => void;
}

export type ActionKeys = 'view-details';

const TransferTemplatesTable = memo(({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('transfers');

  const renderTemplateName = (record: ITransferTemplateShortModel) => {
    const isDerivedFromCircle =
      record.additionalData.derivedFrom === AccountProcessingTypes.Circle;

    if (isDerivedFromCircle) {
      return (
        <>
          {record.name} <DerivedFromCircleTooltip derivedEntryType="template" />
        </>
      );
    } else {
      return (
        <Link onClick={() => onActionsClick('view-details', record)}>
          {record.name}
        </Link>
      );
    }
  };

  const renderTemplatePermissions = ({
    isOnlyMe,
  }: ITransferTemplateShortModel) => {
    const permission = isOnlyMe
      ? TransferTemplatePermissions.Private
      : TransferTemplatePermissions.Public;
    return t(`transfer_templates.transfer_template_permissions.${permission}`);
  };

  const tableColumns = useMemo(() => {
    return [
      {
        title: t('transfer_templates.table.date'),
        key: 'date',
        width: 200,
        render: (record: ITransferTemplateShortModel) =>
          DateHelpers.formatTimestampDateToString(record.creationDate),
      },
      {
        title: t('transfer_templates.table.id'),
        key: 'id',
        width: 100,
        render: (record: ITransferTemplateShortModel) => record.id,
      },
      {
        title: t('transfer_templates.table.name'),
        key: 'name',
        width: 200,
        render: renderTemplateName,
      },

      {
        title: t('transfer_templates.table.type'),
        key: 'templateTypeId',
        width: 150,
        mobileCardProps: { isCardTitle: true },
        render: (record: ITransferTemplateShortModel) =>
          t(
            `transfer_templates.transfer_template_types.${TransferTemplateTypes[record.templateTypeId]}`,
          ),
      },

      {
        title: t('transfer_templates.table.status'),
        key: 'status',
        width: 150,
        render: (record: ITransferTemplateShortModel) => (
          <TransferTemplateStatus status={record.additionalData.status} />
        ),
      },

      {
        title: t('transfer_templates.table.permissions'),
        key: 'isOnlyMe',
        width: 150,
        render: renderTemplatePermissions,
      },

      {
        title: t('transfer_templates.table.template_details'),
        key: 'additionalData',
        width: 250,
        render: (record: ITransferTemplateShortModel) => (
          <TemplateShortInfo template={record} />
        ),
        mobileCardProps: { hideFromMainSection: true },
      },
    ];
  }, [onActionsClick]);

  return <Table {...rest} columns={tableColumns} />;
});

export default TransferTemplatesTable;
