import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { AccountManagementApprovalStatuses } from 'enums/approvalManagement/approvalManagement';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  info: [AccountManagementApprovalStatuses.All],
  warning: [AccountManagementApprovalStatuses.PendingApproval],
  success: [AccountManagementApprovalStatuses.Approved],
  error: [AccountManagementApprovalStatuses.Rejected],
};

interface IProps {
  status: AccountManagementApprovalStatuses;
}

const ApprovalEntryStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('connections');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      content={t(`pending_approvals.approval_statuses.${status}`)}
    />
  );
});

export default ApprovalEntryStatus;
