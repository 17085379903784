import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';

// components
import Button from '../../../../../../components/DesignSystem/Core/Button';

const ManageSecurityKeys = () => {
  const { t } = useTranslation('profile');
  const navigate = useNavigate();

  return (
    <StyledButton
      size="large"
      onClick={() =>
        navigate(RoutePaths.UserProfile_SignInAndSecurity_ManageSecurityKeys)
      }
    >
      {t('security.two_step_verification.manage_button')}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ManageSecurityKeys;
