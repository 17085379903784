import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// constants
import {
  TREE_COL_GRID_SIZES,
  TWO_COL_GRID_SIZES,
} from '../../../../../../constants/grids';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import GenderSelect from 'components/Forms/FormComponents/SelectInputs/CRM/GenderSelect';
import SectionIntro from '../../../../../DesignSystem/Common/Texts/SectionIntro';
import CountrySelect from '../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import EmailsSection from './EmailsSection';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import PhoneNumbersSection from './PhoneNumbersSection';
import { Col, Row } from 'antd';

import TwitterIcon from '../../../../../../resources/images/social-media-icons/twitter.png';
import FacebookIcon from '../../../../../../resources/images/social-media-icons/facebook.png';
import LinkedInIcon from '../../../../../../resources/images/social-media-icons/linkedIn.png';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...TREE_COL_GRID_SIZES}>
          <FormField
            label={t('applicant_information.form_fields.individual.first_name')}
            disabled={!!values.isContactBaseInfoFormDisabled}
            name="firstName"
            component={FormInput}
          />
        </Col>

        <Col {...TREE_COL_GRID_SIZES}>
          <FormField
            disabled={!!values.isContactBaseInfoFormDisabled}
            label={t(
              'applicant_information.form_fields.individual.middle_name',
            )}
            name="middleName"
            component={FormInput}
          />
        </Col>

        <Col {...TREE_COL_GRID_SIZES}>
          <FormField
            disabled={!!values.isContactBaseInfoFormDisabled}
            label={t('applicant_information.form_fields.individual.last_name')}
            name="lastName"
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            disabled={!!values.isContactBaseInfoFormDisabled}
            label={t(
              'applicant_information.form_fields.individual.date_of_birth',
            )}
            name="dateOfBirth"
            component={StyledDatePicker}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isAfter(new Date()),
            }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            disabled={!!values.isContactBaseInfoFormDisabled}
            label={t(
              'applicant_information.form_fields.individual.country_of_birth',
            )}
            name="countryOfBirth"
            component={CountrySelect}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            disabled={!!values.isContactBaseInfoFormDisabled}
            label={t('applicant_information.form_fields.individual.gender')}
            name="gender"
            component={GenderSelect}
          />
        </Col>
      </Row>

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('applicant_information.contact_information_section.title')}
        description={t(
          'applicant_information.contact_information_section.description',
        )}
      />
      <EmailsSection
        displayTooltipInTitle={values.displayTooltipsForPhoneAndEmailSections}
      />
      <PhoneNumbersSection
        displayTooltipInTitle={values.displayTooltipsForPhoneAndEmailSections}
      />
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('applicant_information.social_media_section.title')}
        description={t(
          'applicant_information.social_media_section.description',
        )}
      />
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={TwitterIcon} alt="Twitter icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.twitterLink"
            component={FormInput}
            placeholder="https://twitter.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={FacebookIcon} alt="Facebook icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.facebookLink"
            component={FormInput}
            placeholder="https://www.facebook.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={LinkedInIcon} alt="LinkedIn icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.linkedInLink"
            component={FormInput}
            placeholder="https://www.linkedin.com/in/username"
          />
        </Col>
      </Row>
    </>
  );
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const SocialMediaIcon = styled.img`
  width: 40px;
  height: auto;
`;

export default InnerForm;
