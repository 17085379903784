import * as yup from 'yup';
import { NULLABLE_BOOLEAN_VALIDATION_RULE } from 'validations/common';

export const KYCVerificationSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      answer: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
        'form:validation_messages.required',
      ),
    }),
  ),

  agreement: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:warnings.share_due_diligence_agreement_must_be_accepted',
  ).oneOf(
    [true],
    'form:warnings.share_due_diligence_agreement_must_be_accepted',
  ),
});
