import React from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { accountsAPI } from '../../../../../api/accounting/accountsAPI';
import { receiveMoneyNativeValidation } from '../../../../../validations/accounting/accounts';

// components
import InnerForm from './InnerForm';
import WireDetails from './WireDetails';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Divider } from 'antd';

export interface FormValuesModel {
  email: string;
  message?: string;
  fullName: string;
  reference?: string;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  accountNumber: string;
}

const ReceiveMoneyNativeModalDialog = ({
  title,
  isVisible,
  accountNumber,
  initialValues,
  onSubmit,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('common');
  const { response, loading } = useFetch(
    () =>
      isVisible
        ? accountsAPI.fetchWireInstructionsByAccount(accountNumber)
        : null,
    [isVisible],
  );

  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={receiveMoneyNativeValidation}
      renderForm={
        <ModalDialog
          width={1250}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{ text: t('send') }}
        >
          <>
            <InnerForm />
            <Divider />
            <LoadingWrapper loading={loading}>
              {response && <WireDetails data={response} />}
            </LoadingWrapper>
          </>
        </ModalDialog>
      }
    />
  );
};

export default ReceiveMoneyNativeModalDialog;
