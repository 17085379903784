import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { StateModel } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { ApplicationStatuses } from '../../../enums/onboarding/crm';

// constants
import PendingApprovalTooltip from '../../Tooltips/TemplateTooltips/PendingApprovalTooltip';

const ClientGroupReviewStatus = () => {
  const applicationStatus = useSelector<StateModel, ApplicationStatuses | null>(
    ({ applications }) => applications.activeApplicationStatus,
  );

  const content = useMemo(() => {
    switch (applicationStatus) {
      case ApplicationStatuses.InAccountManagementReview:
        return (
          <PendingApprovalTooltip showIcon approvalType="account_management" />
        );

      case ApplicationStatuses.InReview:
        return <PendingApprovalTooltip showIcon approvalType="internal" />;

      default:
        return null;
    }
  }, [applicationStatus]);

  return <StyledWrapper>{content}</StyledWrapper>;
};

const StyledWrapper = styled.div`
  margin-left: ${({ theme }) => theme.marginSm};
  display: flex;
  align-items: center;
`;

export default ClientGroupReviewStatus;
