import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { StyledComponentProps } from '../../../../typings/common';

// components
import { InputNumber as AntDInputNumber } from 'antd';

export type InputSizes = 'large' | 'middle' | 'small';

export interface InputNumberProps extends StyledComponentProps {
  name?: string;
  size?: InputSizes;
  value?: number;
  disabled?: boolean;
  placeholder?: string;

  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: string | number | null | undefined) => void;
}

const InputNumber = memo(
  ({
    value,
    onChange,
    onBlur,
    disabled,
    placeholder,
    name,
    size,
    ...rest
  }: InputNumberProps) => {
    const { t } = useTranslation('form');

    return (
      <AntDInputNumber
        size={size}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder || t('enter_value')}
        {...rest}
      />
    );
  },
);

export default InputNumber;
