import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../resources/theme/styled/colors';
import { StyledComponentProps } from '../../../../typings/common';

// components
import IconSVG from '../../../DesignSystem/Core/IconSVG';
import DivAlignCenter from '../../../Additional/DivAlignCenter';
import { ReactComponent as InfoIcon } from '../../../../resources/icons/remix-icons/information-line.svg';

type IProps = StyledComponentProps;

const CannotDeleteLastApplicantRelationshipWarning = memo((props: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <DivAlignCenter {...props}>
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
      {t(
        'structure.relationships.cannot_delete_last_applicant_relationship_warning',
      )}
    </DivAlignCenter>
  );
});

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default CannotDeleteLastApplicantRelationshipWarning;
