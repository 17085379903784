import APIConfig from '../../config/api';
import { APIService } from 'api/axiosInstance';
import { ContactModel } from 'typings/application/contact';
import { connectionsAdapter } from 'apiAdapters/connections/connectionsAdapter';
import { RequestStatuses, RequestTypes } from 'enums/connections/connections';
import { FetchParamsModel, FetchResponseModel } from 'typings/common';

export type QuestionQuery = {
  key: string;
  text: string;
};

export type ShareContactRequestBody = {
  clientGroupJoinRequestData: {
    questionnaireTemplateAnswers: { answer: boolean; questionKey: string }[];
  };
};

export type QuestionnaireTemplateFromQuery = {
  description: string;
  name: string;
  questions: QuestionQuery[];
  _audit: {
    createdAt: string;
    createdBy: string;
    updatedAt: string;
  };
  _id: string;
};

export type RequestFromQuery = {
  creator: {
    _id: string;
    name: string;
  };
  details: {
    clientGroupId: string;
    clientGroupName: string;
    questionnaireTemplate: QuestionnaireTemplateFromQuery | null;
    relatedContact: ContactModel | null;
    relatedContactId: string;
  };
  recepientId: string;
  status: RequestStatuses;
  type: RequestTypes;
  _audit: {
    createdAt: string;
    createdBy: string;
    updatedAt: string;
  };
  _id: string;
};

const connectionsAPI = {
  fetchRequests: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<RequestFromQuery>>(
      `${APIConfig.crmApi}/users/client-requests`,
      {
        params,
      },
    ).then(({ data }) => connectionsAdapter.fetchRequests(data));
  },

  fetchRequestById: (id: string) => {
    return APIService.get<RequestFromQuery>(
      `${APIConfig.crmApi}/users/client-requests/${id}`,
    ).then(({ data }) => data);
  },

  rejectClientGroupRequest: (clientRequestId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/users/client-requests/${clientRequestId}/reject`,
    ).then(({ data }) => data);
  },

  shareClientGroupRequest: (
    clientRequestId: string,
    payload: ShareContactRequestBody,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/users/client-requests/${clientRequestId}/accept`,
      payload,
    ).then(({ data }) => data);
  },

  declineHandoffRequest: (clientRequestId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/users/client-requests/${clientRequestId}/reject`,
    ).then(({ data }) => data);
  },

  acceptHandoffRequest: (clientRequestId: string) => {
    return APIService.post<{ applicationId?: string }>(
      `${APIConfig.crmApi}/users/client-requests/${clientRequestId}/accept`,
    ).then(({ data }) => data);
  },
};

export { connectionsAPI };
