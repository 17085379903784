import * as yup from 'yup';

// constants
import { NULLABLE_STRING_VALIDATION_RULE } from '../common';

const TravelNotificationSchema = yup.object().shape({
  country: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'profile:travel_notifications.add_travel_notification_modal.form_fields.country',
    },
  }),
  departureDate: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'profile:travel_notifications.add_travel_notification_modal.form_fields.departure_date',
    },
  }),
  returnDate: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'profile:travel_notifications.add_travel_notification_modal.form_fields.return_date',
    },
  }),
});

export const AddTravelNotificationSchema = yup.object().shape({
  countries: yup.array().of(TravelNotificationSchema),
});
