import React, { useMemo, useState } from 'react';

// helpers
import { userAPI } from '../../../../../../api/profile/userAPI';
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import PageButton from '../../../../../../components/DesignSystem/Common/Buttons/PageButton';
import { message } from 'antd';
import RegisterSecurityKeyModal, {
  FormValuesModel,
  RegisterSecurityKeySteps,
} from '../../../../../../components/ModalDialogs/TemplateModalDialogs/UserProfile/RegisterSecurityKeyModal';

interface IProps {
  onAdd: () => void;
}

const AddSecurityKey = ({ onAdd }: IProps) => {
  const { t } = useTranslation('profile');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      name: '',
      currentStep: RegisterSecurityKeySteps.Initial,
      securityKeyCredInfo: null,
    };
  }, []);

  const handleModalClose = (wasRegistered?: boolean) => {
    if (wasRegistered) {
      // TODO: update status in redux
    }
    setIsModalVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.securityKeyCredInfo) {
      await userAPI.registerSecurityKey({
        ...values.securityKeyCredInfo,
        name: values.name,
      });
      message.success(
        t(
          'security.two_step_verification.security_key.add_new_key.success_submit_message',
        ),
      );
      onAdd();
    }
  };

  return (
    <>
      <PageButton onClick={() => setIsModalVisible(true)}>
        {t('security.two_step_verification.security_key.add_new_key_button')}
      </PageButton>

      <RegisterSecurityKeyModal
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        title={t(
          'security.two_step_verification.security_key.add_new_key.title',
        )}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default AddSecurityKey;
