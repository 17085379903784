import moment from 'moment';
import { Moment } from 'moment';
import { DEFAULT_DATE_FORMAT } from '../constants/global';

const DateHelpers = {
  formatDateToString: function (
    date: Moment | string | number | Date,
    toFormat = DEFAULT_DATE_FORMAT,
    utc?: boolean,
  ) {
    if (utc) {
      return moment.utc(date).format(toFormat);
    } else {
      return moment(date).format(toFormat);
    }
  },

  formatDateToTimestampWithTruncatedTime: function (
    date: Moment | string | number,
  ) {
    return moment(date).startOf('day').unix();
  },

  formatTimestampDateToString: function (
    timestamp: number,
    format = DEFAULT_DATE_FORMAT,
  ) {
    const result =
      timestamp > 0 ? this.formatDateToString(timestamp * 1000, format) : '';
    return result;
  },

  formatTimestampDateToUTCString: function (
    timestamp: number,
    format = DEFAULT_DATE_FORMAT,
  ) {
    if (!timestamp) {
      return '';
    }

    const formattedTimestamp = moment.utc(timestamp * 1000);
    return DateHelpers.formatDateToString(formattedTimestamp, format);
  },

  formatMinutesToUIText: function (
    minutes: number,
    labels: { minute: string; minutes: string; hour: string; hours: string },
  ) {
    let result = '';

    if (minutes < 60) {
      result = `${minutes} ${minutes === 1 ? labels.minute : labels.minutes}`;
    } else if (minutes === 60) {
      result = `1 ${labels.hour}`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const minuteLabel =
        remainingMinutes === 1 ? labels.minute : labels.minutes;
      const minutesPart = remainingMinutes
        ? ` ${remainingMinutes} ${minuteLabel}`
        : '';
      if (hours < 2) {
        result = `${hours} ${labels.hour}${minutesPart}`;
      } else {
        result = `${hours} ${labels.hours}${minutesPart}`;
      }
    }

    return result;
  },

  formatToYYYYMMDD: function (date: Moment | Date | string | number): string {
    return moment(date).format('YYYY-MM-DD');
  },

  formatDateToUTC: function (date: Moment | Date | string | number) {
    const newDate = typeof date === 'string' ? new Date(date) : date;
    return moment.utc(newDate);
  },
};

export { DateHelpers };
