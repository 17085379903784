import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { FormikProps } from 'formik';
import { generateUniqId } from 'helpers/utils';
import { accountManagementRulesAPI } from '../../../../../api/accountManagement/accountManagementRulesAPI';
import { accountManagementRulesAdapter } from '../../../../../apiAdapters/accountManagement/accountManagementRulesAdapter';
import {
  ApprovalRuleTriggerTypeModel,
  IApprovalGroupShort,
} from '../../../../../typings/approvalManagement/accountManagement';

// components
import Text from '@core_components/Text';
import PageButton from '../../../../../components/DesignSystem/Common/Buttons/PageButton';
import ApprovalRuleMatrix, {
  ApprovalRuleMatrixFormValuesModel,
} from './ApprovalRuleMatrix';
import { Card, message, Modal } from 'antd';

interface IProps {
  initialTemplate: ApprovalRuleMatrixFormValuesModel | null;
  approvalGroups: IApprovalGroupShort[];
  approvalRuleTriggerTypes: ApprovalRuleTriggerTypeModel[];
  onModify: () => void;

  hasEditAccess: boolean;
  hasCreateAccess: boolean;
}

const TransfersApprovalSection = ({
  initialTemplate,
  approvalGroups,
  approvalRuleTriggerTypes,
  hasCreateAccess,
  hasEditAccess,
  onModify,
}: IProps) => {
  const { t } = useTranslation(['account_management', 'form', 'common']);
  const [isEditMode, setIsEditMode] = useState(!initialTemplate || false);
  const [isCreateNewSection, setIsCreateNewSection] =
    useState(!initialTemplate);

  const initialFormValues: ApprovalRuleMatrixFormValuesModel = useMemo(() => {
    if (initialTemplate) {
      const initialData = { ...initialTemplate };

      initialData.approvalConditions = initialData.approvalConditions.map(
        (condition) => {
          // To show predefined values, we need to save more information about selected groups
          // This is going to be removed when API will be updated
          const approvalGroupConditions = condition.approvalGroupConditions.map(
            (ag) => {
              // TODO: remove this when API will be updated (API should return this information)
              const approvalGroupFull = approvalGroups.find(
                (e) => e.id === ag.approvalGroupId,
              );
              const maximumRequiredApprovers =
                approvalGroupFull?.participants.length || 0;
              const initialAutocompleteValue = {
                id: approvalGroupFull?.id as string,
                label: approvalGroupFull?.name,
              };

              return {
                ...ag,
                maximumRequiredApprovers,
                initialAutocompleteValue,
                customUniqId: generateUniqId(),
              };
            },
          );

          return { ...condition, approvalGroupConditions };
        },
      );

      return initialData;
    } else {
      // Init approval trigger types
      // By default each of them should be equal to true (enabled)
      const actionStatuses: { [key: number]: boolean } = {};
      approvalRuleTriggerTypes.forEach(({ id }) => (actionStatuses[id] = true));

      const initialApprovalRule = {
        actionStatuses,
        approvalGroupConditions: [
          {
            customUniqId: generateUniqId(),
            approvalGroupId: null,
            requiredApprovers: null,
            maximumRequiredApprovers: 0,
          },
        ],
      };

      return {
        id: null,
        approvalRuleType: 'account-management',
        isInitialTemplate: !initialTemplate,
        approvalConditions: [initialApprovalRule],
      };
    }
  }, [initialTemplate]);

  const handleSubmit = async (values: ApprovalRuleMatrixFormValuesModel) => {
    if (values.id) {
      await editTemplate(values);
    } else {
      await createNewTemplate(values);
    }
    message.success(t('success_submit_for_account_management_review_message'));
    setIsEditMode(false);
    onModify();
  };

  const createNewTemplate = (values: ApprovalRuleMatrixFormValuesModel) => {
    const formattedTemplateData =
      accountManagementRulesAdapter.createInitialApprovalRulesTemplate(values);
    return accountManagementRulesAPI.createInitialApprovalRulesTemplate(
      formattedTemplateData,
    );
  };

  const editTemplate = (values: ApprovalRuleMatrixFormValuesModel) => {
    const formattedTemplateData =
      accountManagementRulesAdapter.updateApprovalRulesTemplate(values);
    return accountManagementRulesAPI.updateApprovalRulesTemplate(
      formattedTemplateData,
    );
  };

  // newStatus = true : turn on edit mode
  // newStatus = false : cancel editing, in this case if something was changed we should show confirmation modal dialog
  const handleEditStatusChange = (
    newStatus: boolean,
    wasChanged: boolean,
    form: FormikProps<ApprovalRuleMatrixFormValuesModel>,
  ) => {
    if (newStatus) {
      setIsEditMode(newStatus);
    } else {
      if (wasChanged) {
        Modal.confirm({
          title: t('leave_form_without_saving.title', { ns: 'form' }),
          content: t('leave_form_without_saving.description', { ns: 'form' }),
          okText: t('yes', { ns: 'common' }),
          cancelText: t('no', { ns: 'common' }),
          onOk: () => {
            form.resetForm();
            setIsEditMode(newStatus);
          },
        });
      } else {
        setIsEditMode(newStatus);
      }
    }
  };

  return (
    <>
      {isCreateNewSection ? (
        hasCreateAccess && (
          <StyledCard>
            <Text variant="h5" weight="semi-bold">
              {t('approval_rules.initial_approval_rule')}
            </Text>
            <PageButton onClick={() => setIsCreateNewSection(false)}>
              {t('approval_rules.create_initial_rule_button')}
            </PageButton>
          </StyledCard>
        )
      ) : (
        <ApprovalRuleMatrix
          onSubmit={handleSubmit}
          isEditActive={isEditMode}
          hasEditAccess={hasEditAccess}
          initialValues={initialFormValues}
          onEditStatusChange={handleEditStatusChange}
          approvalRuleTriggerTypes={approvalRuleTriggerTypes}
        />
      )}
    </>
  );
};

const StyledCard = styled(Card)`
  .ant-card-body {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export default TransfersApprovalSection;
