import React, { memo } from 'react';

// helpers
import styled from 'styled-components';

// components
import { Avatar as AntDAvatar } from 'antd';

export interface AvatarProps {
  icon: React.ReactNode;
}

const Avatar = memo(({ icon }: AvatarProps) => {
  return <StyledAntDAvatar icon={icon} />;
});

const StyledAntDAvatar = styled(AntDAvatar)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Avatar;
