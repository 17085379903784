import React, { createContext, useRef } from 'react';

// helpers
import { IApplication } from '../../../../../typings/application/applications';

// constants
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';

// components
import SubmitButtons from './SubmitButtons';
import ApplicationDocumentationWithRelationships from '../../../../../components/Forms/TemplateForms/Onboarding/ApplicationDocumentationWithRelationships';

interface IProps {
  isViewOnly: boolean;
  application: IApplication;
  onAction: (key: ActionKeys | null) => void;
}

export const VerificationContext = createContext<{ refState: any } | null>({
  refState: null,
});

const ApplicationDocumentation = ({
  isViewOnly,
  application,
  onAction,
}: IProps) => {
  const ref = useRef(null);

  return (
    <VerificationContext.Provider value={{ refState: ref }}>
      <ApplicationDocumentationWithRelationships
        application={application}
        disabled={isViewOnly}
        onAction={onAction}
        canSendMessage
      />

      {!isViewOnly && <SubmitButtons />}
    </VerificationContext.Provider>
  );
};

export default ApplicationDocumentation;
