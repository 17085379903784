import React, { useState } from 'react';

// helpers & utils
import useFetch from '../../../../../hooks/useFetch';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { clientMessagingAPI } from '../../../../../api/messaging/clientMessagingAPI';
import { ThreadWithLastMessageModel } from '../../../../../typings/messaging/messages';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import MessagesTable, {
  ActionKeys,
} from '../../../../../components/Tables/TableTemplates/Messages/MessagesTable';

interface IProps {
  updateMessagesTrigger?: any;
  threadPagePath?: RoutePaths;
}

const SentTab = ({
  updateMessagesTrigger,
  threadPagePath = RoutePaths.UserProfile_Messages_Thread,
}: IProps) => {
  const navigate = useNavigate();
  const [currentPage, setPage] = useState(1);

  const { response, loading } = useFetch(() => {
    return clientMessagingAPI.fetchSentMessages({
      limit: DEFAULT_TABLE_LIMIT,
      page: currentPage,
    });
  }, [currentPage, updateMessagesTrigger]);

  const handleActionsClick = (
    key: ActionKeys,
    record: ThreadWithLastMessageModel,
  ) => {
    switch (key) {
      case 'view_thread': {
        navigate({
          pathname: threadPagePath,
          search: `?id=${record.threadId}`,
        });
      }
    }
  };

  return (
    <MessagesTable
      current={currentPage}
      loading={loading}
      total={response?.total || 0}
      data={response?.data || []}
      onPaginationChange={setPage}
      onActionsClick={handleActionsClick}
    />
  );
};

export default SentTab;
