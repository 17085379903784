import React from 'react';

// components
import SignInSteps from './SignInSteps';

const SignInForm = () => {
  return <SignInSteps />;
};

export default SignInForm;
