import React from 'react';

// helpers
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';

// components
import TransactionRules from './TransactionRules';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import AdministrationRules from './AdministrationRules';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

export type FormValuesModel = SubmitButtonsFormValuesModel;

interface IProps extends RequiredPropsForFormModel<FormValuesModel> {
  onboardingData: OnboardingStatusModel;
}

const AdministrationForm = ({
  initialValues,
  onSubmit,
  disabled,
  onboardingData,
  onSubmitError,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving
      disabled={disabled}
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
      initialValues={initialValues}
      renderForm={
        <>
          <TransactionRules onboardingData={onboardingData} />
          <AdministrationRules onboardingData={onboardingData} />
          <HideIfDisabledForm>
            <SubmitButtons />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default AdministrationForm;
