import { Dispatch } from 'redux';
import { StateModel } from '../reducers';
import { ProfilePermissionModel } from '../../typings/profile/profile';

// Actions
export enum ActionType {
  INIT_USER_PERMISSIONS = 'userAccess/INIT_USER_PERMISSIONS',
  CLEAN_UP = 'userAccess/CLEAN_UP',
}

// Action creators
export const initUserPermissions = (
  userPermissionsList: ProfilePermissionModel[],
) => {
  return (dispatch: Dispatch, getState: () => StateModel) => {
    const state = getState();

    dispatch({
      type: ActionType.INIT_USER_PERMISSIONS,
      payload: { userPermissionsList, state },
    });
  };
};

export const cleanUpUserAccessReducer = () => ({
  type: ActionType.CLEAN_UP,
  payload: null,
});
