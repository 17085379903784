import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import { IKeyRelationshipTemplate } from '../../typings/application/key-relationship-templates';

interface ISearchParams extends FetchParamsModel {
  search?: string;
  isVisible?: boolean;
}

const keyRelationshipTemplatesAPI = {
  fetchKeyRelationshipTemplates: (params: ISearchParams) => {
    return APIService.get<FetchResponseModel<IKeyRelationshipTemplate>>(
      `${APIConfig.crmApi}/key-relationship-templates`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchOnboardingKeyRelationshipTemplates: (params: ISearchParams) => {
    return APIService.get<FetchResponseModel<IKeyRelationshipTemplate>>(
      `${APIConfig.crmPublicApi}/key-relationship-templates`,
      {
        params,
      },
    ).then(({ data }) => data);
  },
};

export { keyRelationshipTemplatesAPI };
