export const USER_TAG_IS_LOGGED_IN_FIRST_TIME = 'isUserLoggedInFirstTime';
export const USER_TAG_IS_SKIPPED_VERIFICATION_SMS =
  'isUserSkippedVerificationSms';
export const USER_TAG_IS_SKIPPED_VERIFICATION_EMAIL =
  'isUserSkippedVerificationEmail';
export const USER_TAG_IS_OFF_POTENTIAL_CONTACT_DUPLICATION_MODAL =
  'isUserOffPotentialContactDuplicationModal';
export const ONBOARDING_TRANSACTION_RULES_INTRODUCTION_SUBMITTED =
  'onboardingTransactionRulesIntroductionSubmitted';
export const ONBOARDING_ADMINISTRATION_RULES_INTRODUCTION_SUBMITTED =
  'onboardingAdministrationRulesIntroductionSubmitted';
