import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { IAccountModel } from '../../../../../../typings/onboarding/account';
import { blockchainAddressesAPI } from '../../../../../../api/accounting/blockchainAddressesAPI';

// components
import AddBlockchainAddressModalDialog, {
  FormValuesModel,
} from '../../BlockchainAddressModalDialog';

interface IProps {
  account: IAccountModel;
  isVisible: boolean;
  closeCallback: (wasAdded?: boolean) => void;
}

const AddBlockchainAddressModal = ({
  isVisible,
  closeCallback,
  account,
}: IProps) => {
  const { t } = useTranslation('accounts');

  const initialFormValues = useMemo<FormValuesModel>(
    () => ({
      currency: null,
      network: null,
    }),
    [account],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    await blockchainAddressesAPI.createNewAddressForAccount(
      account.accountNumber,
      {
        currency: values.currency as string,
        chain: values.network as string,
      },
    );

    message.success(
      t(
        'account_activity.send_wire_instructions.circle.blockchain_address_modal.add.success_submit_message',
      ),
    );
  };

  return (
    <AddBlockchainAddressModalDialog
      title={t(
        'account_activity.send_wire_instructions.circle.blockchain_address_modal.add.title',
      )}
      isVisible={isVisible}
      closeCallback={closeCallback}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
    />
  );
};

export default AddBlockchainAddressModal;
