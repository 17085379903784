import React, { useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import { transactionsAPI } from '../../../api/accounting/transactionsAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';
import { TransactionStatuses } from '../../../enums/accounts/accounts';

// components
import PendingTransactionsTable from './PendingTransactionsTable';

const PendingTransactions = () => {
  const [currentPage, setPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [activeFilters, setActiveFilters] = useState<{
    status: TransactionStatuses[] | undefined;
    fromAccount: string | undefined;
  }>({
    status: [
      TransactionStatuses.Pending,
      TransactionStatuses.Processing,
      TransactionStatuses.Scheduled,
    ],
    fromAccount: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchPendingTransactions({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        accountNumber: activeFilters.fromAccount || '',
        status:
          activeFilters.status && activeFilters.status.length
            ? activeFilters.status.join(',')
            : '',
      }),
    [currentPage, activeFilters, updateTableTrigger],
  );

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'from_account':
        setActiveFilters((prev) => ({
          ...prev,
          fromAccount: (value as string) || undefined,
        }));
        break;

      case 'status':
        setActiveFilters((prev) => ({
          ...prev,
          status: value as TransactionStatuses[],
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  const updateTableValues = (switchToFirstPage?: boolean) => {
    if (switchToFirstPage) {
      setPage(1);
    }

    updateTable({});
  };

  return (
    <PendingTransactionsTable
      total={response?.total || 0}
      current={currentPage}
      loading={loading}
      data={response?.data || []}
      onPaginationChange={setPage}
      filters={activeFilters}
      updateTableCallback={updateTableValues}
      onFilterChange={onFilterChange}
    />
  );
};

export default PendingTransactions;
