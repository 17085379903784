import * as yup from 'yup';
import { AdditionalFieldTypes } from 'enums/onboarding/crm';
import {
  ContactShortInfoFormValidationSchema,
  OrganizationShortInfoFormValidationSchema,
} from 'validations/onboarding-new/structure';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from '../common';

const mapRules = (map: any, rule: any) =>
  Object.keys(map).reduce((newMap, key) => {
    return { ...newMap, [key]: rule };
  }, {});

export const ownershipStructureValidationSchema = yup.object().shape({
  ownershipFieldKey: STRING_VALIDATION_RULE.required(),
  ownerships: yup
    .array()
    .of(
      yup.object().shape({
        additionalFields: yup.lazy((map) => {
          return yup.object(
            mapRules(
              map,
              yup.object({
                isOwnershipField: yup.boolean(),
                value: yup
                  .mixed()
                  .when(['isOwnershipField'], (values, schema, value): any => {
                    if (values[0]) {
                      return yup
                        .number()
                        .typeError('form:validation_messages.only_numbers')
                        .min(0, {
                          key: 'form:validation_messages.min_number_value_with_label',
                          values: { label: 'common:percentage', number: 0 },
                        })
                        .max(100, {
                          key: 'form:validation_messages.max_number_value_label',
                          values: { label: 'common:percentage', number: 100 },
                        })
                        .required({
                          key: 'form:validation_messages.required_with_label',
                          values: { label: 'common:percentage' },
                        });
                    }

                    switch (value.parent.type) {
                      case AdditionalFieldTypes.Form:
                      case AdditionalFieldTypes.Document:
                        return yup
                          .array()
                          .min(1, 'form:validation_messages.required')
                          .required('form:validation_messages.required');

                      default:
                        return schema.required(
                          'form:validation_messages.required',
                        );
                    }
                  }),
              }),
            ),
          );
        }),
      }),
    )
    .test(
      'ownerships',
      {
        key: 'custom_errors:account_management.invalid_shareholder_total_value',
      },
      (value, form) => {
        if (!value?.length) {
          return true;
        }

        const { parent } = form;

        const totalAmount = value.reduce((total: number, ownership: any) => {
          if (ownership.additionalFields[parent.ownershipFieldKey].value) {
            const fomattedNumber =
              Number(
                ownership.additionalFields[parent.ownershipFieldKey].value,
              ) || 0;
            return total + fomattedNumber;
          }

          return total;
        }, 0);

        return !(totalAmount > 100);
      },
    ),
});

export const newOwnershipValidationSchema = yup.object().shape({
  isFromExistingEntry: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),

  existingEntry: yup.object().when('isFromExistingEntry', {
    is: true,
    then: (schema) =>
      schema.shape({
        id: NULLABLE_STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
      }),
    otherwise: (schema) => schema,
  }),

  template: yup.object().when('isFromExistingEntry', {
    is: false,
    then: (schema) =>
      schema.shape({
        id: NULLABLE_STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:structure.relationships.form_fields.relationship_type',
          },
        }),
      }),
    otherwise: (schema) => schema,
  }),

  contact: yup
    .object()
    .nullable()
    .when('isFromExistingEntry', {
      is: false,
      then: () => ContactShortInfoFormValidationSchema.nullable(),
      otherwise: (schema) => schema,
    }),

  organization: yup
    .object()
    .nullable()
    .when('isFromExistingEntry', {
      is: false,
      then: () => OrganizationShortInfoFormValidationSchema.nullable(),
      otherwise: (schema) => schema,
    }),
});
