import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { overdraftAPI } from '../../../../api/accountManagement/overdraftAPI';

// components
import OverdraftTable from '../../../../components/Tables/TableTemplates/AccountManagement/OverdraftTable';

const OverdraftTab = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { response, loading } = useFetch(
    () => overdraftAPI.fetchOverdrafts(),
    [],
  );

  return (
    <OverdraftTable
      data={response?.data || []}
      total={response?.total || 0}
      loading={loading}
      current={currentPage}
      onPaginationChange={setCurrentPage}
    />
  );
};

export default OverdraftTab;
