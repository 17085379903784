import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { OnboardingStatusModel } from '../../../../../../../../../typings/onboarding/onboarding';

// components
import Button from '../../../../../../../../DesignSystem/Core/Button';
import AddNewNodeModal from '../../../ModalDialogs/AddNewNodeModal';

interface IProps {
  onboardingData: OnboardingStatusModel;
  closeCallback: (wasSubmitted?: boolean) => void;
}

const CreateRelationshipWithNewNodes = ({
  onboardingData,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalClose = (wasSubmitted?: boolean) => {
    setIsModalVisible(false);
    closeCallback(wasSubmitted);
  };

  return (
    <>
      <Button onClick={() => setIsModalVisible(true)}>
        {t('new_node_modal.add_node_button')}
      </Button>

      <AddNewNodeModal
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        data={{
          clientGroupId: onboardingData.clientGroupId,
          activeApplicationScopeId: onboardingData.relationshipScopeId,
        }}
      />
    </>
  );
};

export default CreateRelationshipWithNewNodes;
