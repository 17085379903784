import React, { memo, useCallback, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { generateUniqId } from 'helpers/utils';
import { ContactHelpers } from '../../../../../../../helpers/crm/contact';
import { FormValuesModel } from '../..';
import { OnboardingEntryTypes } from '../../../../../../../enums/onboarding/crm';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

// components
import AddButton from '../../../../../../DesignSystem/Common/Buttons/AddButton';
import SectionIntro from '../../../../../../DesignSystem/Common/Texts/SectionIntro';
import FormErrorTooltip from '../../../../../../Forms/FormErrorTooltip';
import HideIfDisabledForm from '../../../../../../Forms/HideIfDisabledForm';
import RelationshipForm, {
  FormValuesModel as RelationshipFormValuesModel,
} from '../../../../../../Forms/TemplateForms/CRM/RelationshipForm';
import { Card } from 'antd';

const RelationshipsSection = memo(() => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const relationshipFromValue = useMemo(() => {
    let result = '';

    if (values.template.visibleFor) {
      if (values.template.visibleFor === OnboardingEntryTypes.Contact) {
        result = ContactHelpers.getFormattedContactName(
          values.contact?.firstName || '',
          values.contact?.lastName || '',
          values.contact?.middleName,
        );
      } else {
        result = values.organization?.name || '';
      }
    }

    return result;
  }, [values.contact, values.organization, values.template]);

  const selectedRelationshipTemplates = useMemo<
    Record<string, string[]>
  >(() => {
    return values.relationships.reduce<Record<string, string[]>>(
      (acc, next) => {
        if (next.relationshipTemplate.id && next.child.id) {
          if (acc[next.child.id] && acc[next.child.id].length) {
            acc[next.child.id].push(next.relationshipTemplate.id);
          } else {
            acc[next.child.id] = [next.relationshipTemplate.id];
          }
        }

        return acc;
      },
      {},
    );
  }, [values.relationships]);

  const renderRelationships = useCallback(
    (fieldArrayRenderProps: FieldArrayRenderProps) => {
      function getNewRelationship(): RelationshipFormValuesModel {
        return {
          customId: generateUniqId(),
          id: null,

          relationshipTemplate: {
            id: null,
            childRelationshipLabel: '',
          },

          parent: {
            id: '',
            type: values.template.visibleFor,
          },

          child: {
            id: null,
            type: null,
            label: '',
          },

          additionalFields: [],
        };
      }

      return (
        <>
          {values.relationships.map((rel, i) => (
            <StyledCard key={rel.customId}>
              <RelationshipForm
                canAddOwnerships={values.canAddOwnerships}
                fieldName={`relationships.${i}`}
                clientGroupId={values.clientGroupId}
                activeScopeId={values.activeApplicationScopeId}
                removeCallback={() => fieldArrayRenderProps.remove(i)}
                relationshipIndex={i + 1}
                clientGroupEntryType={
                  values.clientGroupEntryType as OnboardingEntryTypes
                }
                relationshipFromValue={relationshipFromValue}
                selectedRelationshipTemplateIds={
                  rel.child.id
                    ? selectedRelationshipTemplates[rel.child.id] || []
                    : []
                }
              />
            </StyledCard>
          ))}

          <HideIfDisabledForm hideIfSubmitting>
            <AddButton
              onClick={() => fieldArrayRenderProps.push(getNewRelationship())}
            >
              {t('node_modal.relationships.add_new_button')}
            </AddButton>
          </HideIfDisabledForm>
        </>
      );
    },
    [values, selectedRelationshipTemplates, relationshipFromValue],
  );

  return (
    <>
      <SectionIntro
        title={t('node_modal.relationships.section_title')}
        titleVariant="h4"
      />
      <StyledErrorWrapper>
        <FormErrorTooltip<FormValuesModel> errorKey="relationships" />
      </StyledErrorWrapper>
      <FieldArray name="relationships" render={renderRelationships} />
    </>
  );
});

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledErrorWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default RelationshipsSection;
