import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Space, Spin } from 'antd';

const LoadingPage = () => {
  return (
    <ContentWrapper>
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default LoadingPage;
