import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

// components
import { Col, Layout, Row, Steps } from 'antd';

const { Step }: any = Steps;

interface IProps {
  steps: StepItemModel[];
  currentStep?: number;
  initialStep?: number;

  // allows to change step by clicking on title
  onStepSelect?: (stepNumber: number) => void;
}

export interface StepItemModel<StepKeys = string> {
  key: StepKeys;
  title: string;
  hidden?: boolean;
}

const StepsBar = memo(
  ({ steps, initialStep, currentStep, onStepSelect }: IProps) => {
    const breakpoint = useBreakpoint();
    const stepsDirection = !breakpoint.xl ? 'vertical' : 'horizontal';

    const renderSteps = (stepsArray: StepItemModel[]) => {
      return stepsArray.reduce<JSX.Element[]>((acc, value) => {
        const { hidden, key, title } = value;
        if (!hidden) {
          acc.push(
            <Step
              status={onStepSelect ? 'finish' : undefined}
              key={key}
              title={title}
            />,
          );
        }
        return acc;
      }, []);
    };

    return (
      <StyledWrapper>
        <StyledRow justify="center">
          <Col xl={20} lg={20} md={22} xs={22}>
            <Steps
              initial={initialStep}
              current={currentStep}
              direction={stepsDirection}
              onChange={onStepSelect}
            >
              {renderSteps(steps)}
            </Steps>
          </Col>
        </StyledRow>
      </StyledWrapper>
    );
  },
);

const StyledWrapper = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: auto;
  min-height: 100px;
  padding: 0;

  background-color: ${({ theme }) => theme.colorDarkD1};
  border-bottom: ${({ theme }) => `1px solid ${theme.colorDarkL4}`};

  @media (max-width: 480px) {
    padding-bottom: 4px;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

export default StepsBar;
