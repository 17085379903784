import React from 'react';

// ** Helpers utils **
import styled from 'styled-components';
import { darkTheme } from '../../../resources/theme/styled';

export enum LABEL_STATUSES {
  info = 'info',
  warning = 'warning',
  success = 'success',
  error = 'error',
}

type StatusType = string | number;

interface IProps {
  statusMap?: Record<LABEL_STATUSES, StatusType[] | undefined>;
  status: StatusType;
  content?: React.ReactNode;
}

const StatusLabel = ({ statusMap, status, content }: IProps) => {
  const statusKey =
    Object.values(LABEL_STATUSES).find((key) => {
      const statuses = statusMap ? statusMap[key] : [];
      return statuses?.includes(status);
    }) || status;

  const getColor = (): string | undefined => {
    switch (statusKey) {
      case LABEL_STATUSES.info:
        return darkTheme.colorLightD1;

      case LABEL_STATUSES.warning:
        return darkTheme.colorWarning;

      case LABEL_STATUSES.success:
        return darkTheme.colorPrimaryD1;

      case LABEL_STATUSES.error:
        return darkTheme.colorError;
    }

    return undefined;
  };

  return <StyledStatus color={getColor()}>{content || status}</StyledStatus>;
};

const StyledStatus = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`;

export default StatusLabel;
