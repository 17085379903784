import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';

const InnerForm = () => {
  const { t } = useTranslation('account_management');

  return (
    <>
      <FormField
        name="circleAPIKey"
        label={t(
          'external_services.circle.connect_circle_modal.form_fields.circle_api_key',
        )}
        component={FormInput}
      />
    </>
  );
};

export default InnerForm;
