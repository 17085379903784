import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { message } from 'antd';
import { FormikHelpers } from 'formik';
import { TransferTemplateTypes } from '../../../../enums/accounting/transfers';
import {
  BlockchainTransferTemplate,
  IInternalTransferTemplate,
  IInternationalTransferTemplate,
  INewBlockchainTransferRecipientFormValues,
  INewTransferTemplateFormValues,
} from '../../../../typings/accounting/transfer';
import {
  transferTemplatesAPI,
  transferTemplatesApiHelpers,
} from '../../../../api/accounting/transferTemplatesAPI';

// components
import TransferTemplateModal from '../../../../components/ModalDialogs/TemplateModalDialogs/Transfers/TransferTemplateModal';

interface IProps {
  isVisible: boolean;
  closeCallback: (
    wasAdded?: boolean,
    newTemplate?:
      | IInternalTransferTemplate
      | IInternationalTransferTemplate
      | BlockchainTransferTemplate,
  ) => void;
  templateType?: TransferTemplateTypes;
  returnFullModelAfterSubmit?: boolean;
}

const AddTransferTemplateModal = ({
  isVisible,
  closeCallback,
  templateType,
  returnFullModelAfterSubmit,
}: IProps) => {
  const { t } = useTranslation(['transfers', 'server_errors']);

  const initialFormValues: INewTransferTemplateFormValues = useMemo(
    () => ({
      templateName: '',
      templateType: templateType || '',
      permissions: 'onlyMe',
      templateTypeObject: null,
      transferFields: {
        accountNumber: '',
        accountName: '',
        recipientReference: '',
        country: '',
        beneficiaryCity: '',
        beneficiaryDistrict: '',
        beneficiaryPostCode: '',
        useIntermediary: false,
        reference: '',
        beneficiaryBank: {
          bankCodeType: null,
          bankCode: '',
          bankName: '',
          country: '',
          address: '',
        },
        intermediaryBank: {
          bankCodeType: null,
          bankCode: '',
          bankName: '',
          country: '',
          address: '',
        },
      },
    }),
    [templateType],
  );

  const handleSubmit = async (values: INewTransferTemplateFormValues) => {
    let newTemplate:
      | IInternalTransferTemplate
      | IInternationalTransferTemplate
      | BlockchainTransferTemplate
      | undefined;

    switch (values.templateType) {
      case TransferTemplateTypes.Internal:
        {
          const requestBody =
            transferTemplatesApiHelpers.generateInternalTemplateRequestBody(
              values,
            );
          const newTemplateId =
            await transferTemplatesAPI.createInternalTransferTemplate(
              requestBody,
            );

          if (returnFullModelAfterSubmit) {
            newTemplate =
              await transferTemplatesAPI.getInternalTransferTemplate(
                newTemplateId,
              );
          }
        }
        break;

      case TransferTemplateTypes.Wire:
        {
          const requestBody =
            transferTemplatesApiHelpers.generateInternationalTemplateRequestBody(
              values,
            );
          const newTemplateId =
            await transferTemplatesAPI.createInternationalTransferTemplate(
              requestBody,
            );

          if (returnFullModelAfterSubmit) {
            newTemplate =
              await transferTemplatesAPI.getInternationalTransferTemplate(
                newTemplateId,
              );
          }
        }
        break;

      case TransferTemplateTypes.Blockchain:
        {
          const requestBody =
            transferTemplatesApiHelpers.generateBlockchainTemplateRequestBody(
              values as INewTransferTemplateFormValues<INewBlockchainTransferRecipientFormValues>,
            );

          const newTemplateId =
            await transferTemplatesAPI.createBlockchainTransferTemplate(
              requestBody,
            );

          if (returnFullModelAfterSubmit) {
            newTemplate =
              await transferTemplatesAPI.getBlockchainTransferTemplate(
                newTemplateId,
              );
          }
        }
        break;
    }

    message.success(
      t(
        'transfer_templates.transfer_template_modal.new.success_submit_message',
      ),
    );
    closeCallback(true, newTemplate);
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    values: INewTransferTemplateFormValues,
    formikHelpers: FormikHelpers<INewTransferTemplateFormValues>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    // Account does not exist error
    if (errorCode === '1312034') {
      formikHelpers.setFieldError(
        'transferFields.accountNumber',
        t('1312034', { ns: 'server_errors' }),
      );
    } else {
      ErrorHandlerService.handleError(error);
    }
  };

  return (
    <TransferTemplateModal
      isVisible={isVisible}
      closeCallback={closeCallback}
      title={t('transfer_templates.transfer_template_modal.new.title')}
      initialValues={initialFormValues}
      disabledTemplateTypeField={typeof templateType !== 'undefined'}
      onSubmit={handleSubmit}
      onSubmitError={handleOnSubmitError}
    />
  );
};

export default AddTransferTemplateModal;
