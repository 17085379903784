import React, { memo, useState } from 'react';

// helpers
import APIConfig from 'config/api';
import useTranslation from 'hooks/useTranslation';
import { SessionHelpers } from 'helpers/session';
import { DocumentHelpers } from 'helpers/documents';
import { LocalStorageHelpers } from 'helpers/storages/localStorage';

// components
import Button from '@core_components/Button';

interface IProps {
  accountNumber: string;
  accountTransfersParams: AccountTransfersParamsModel;
}

export interface AccountTransfersParamsModel {
  page: number;
  endDate: number;
  startDate: number;
  accountNumber: string;
}

const ExportToPDF = memo(
  ({ accountNumber, accountTransfersParams }: IProps) => {
    const { t } = useTranslation('common');
    const [loader, setLoader] = useState(false);

    const onExportClick = async () => {
      setLoader(true);
      const token = LocalStorageHelpers.getAccessToken();
      const fingerprint = await SessionHelpers.generateFingerprint();
      const link = `${APIConfig.accountingApi}/report/client-statement/${accountNumber}/pdf?fromDate=${
        accountTransfersParams.startDate
      }&toDate=${accountTransfersParams.endDate}&tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
      await DocumentHelpers.downloadDocumentByURL(link, accountNumber);
      setLoader(false);
    };

    return (
      <Button loading={loader} onClick={onExportClick}>
        {t('export_to_pdf')}
      </Button>
    );
  },
);

export default ExportToPDF;
