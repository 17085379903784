import React, { useEffect } from 'react';

// helpers
import { StateModel } from 'redux/reducers';
import { fetchPersonalDetails } from 'redux/actions/personalDetails';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ContentForKYCUser from './Contents/ContentForKYCUser';
import ContentForNonKYCUser from './Contents/ContentForNonKYCUser';

const PersonalDetailsForm = () => {
  const dispatch = useDispatch();
  const { loading, isVerified, contact } = useSelector<
    StateModel,
    PersonalDetailsStateModel
  >((state) => state.personalDetails);

  useEffect(() => {
    dispatch(fetchPersonalDetails());
  }, [dispatch]);

  return (
    <LoadingWrapper loading={loading}>
      {contact ? (
        isVerified ? (
          <ContentForKYCUser />
        ) : (
          <ContentForNonKYCUser />
        )
      ) : null}
    </LoadingWrapper>
  );
};

export default PersonalDetailsForm;
