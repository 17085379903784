import { IKeyRelation } from '../../typings/application/key-relationship-templates';
import { Relationship } from '../../typings/relationshipsGraph';
import { RelationshipTemplate } from '../../typings/application/relationship-template';
import { AdditionalFieldHelpers } from '../additionalField';
import { IKeyRelationshipInstance } from '../../typings/onboarding/onboarding';
import {
  AdditionalFieldTypes,
  RelationshipTrackTypes,
} from '../../enums/onboarding/crm';

const RelationshipHelpers = {
  isKeyRelationshipOwnership: function (keyRelationship: IKeyRelation) {
    return keyRelationship.options?.additionalFields
      ? Object.keys(keyRelationship.options?.additionalFields).some(
          (key) =>
            typeof keyRelationship.options?.additionalFields[key]?.equalTo !==
            'undefined',
        )
      : undefined;
  },

  findOwnershipKeyRelationships: function (
    keyRelationships: IKeyRelation[],
  ): IKeyRelation[] {
    return keyRelationships.filter((keyRelation) =>
      this.isKeyRelationshipOwnership(keyRelation),
    );
  },

  isRelationshipTemplateOwnership: function (
    relTemplate: RelationshipTemplate,
    keyRelationship: IKeyRelationshipInstance,
  ): boolean {
    const ownershipKeyRelationships = this.findOwnershipKeyRelationships(
      keyRelationship.keyRelations,
    );
    return ownershipKeyRelationships.some(
      (e) => e.relationshipTemplateId === relTemplate._id,
    );
  },

  getRelationshipName: function (
    relationship: { childName: string; parentName: string },
    trackType?: RelationshipTrackTypes,
  ) {
    let result = '';

    if (trackType) {
      result =
        trackType === RelationshipTrackTypes.Parent
          ? relationship.parentName
          : trackType === RelationshipTrackTypes.Child
            ? relationship.childName
            : '';
    } else {
      result = `${relationship.parentName} / ${relationship.childName}`;
    }

    return result;
  },

  getRelationshipLabel(
    relationship: Relationship,
    relTemplate: RelationshipTemplate,
  ) {
    let result = '';

    const relationshipName = this.getRelationshipName(
      relTemplate,
      RelationshipTrackTypes.Parent,
    );
    const percentageField = relationship.additionalFields.find(
      ({ type }) => type === AdditionalFieldTypes.Percentage,
    )?.name;

    if (percentageField) {
      const additionalField = relationship.additionalFields.find(
        ({ name }) => name === percentageField,
      );
      const relationshipAdditionalFieldValue =
        AdditionalFieldHelpers.formatFromJSON(
          additionalField?.type || AdditionalFieldTypes.String,
          additionalField?.valueJSON || '""',
        );
      result = `${relationshipAdditionalFieldValue}% - ${relationshipName}`;
    } else {
      result = relationshipName;
    }

    return result;
  },
};

export { RelationshipHelpers };
