import i18n from '../../i18n';
import { message } from 'antd';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { trackException } from 'services/azure-app-insights/service';
import { NOTIFICATION_DURATION } from '../../constants/global';
import {
  ApiErrorResponseModel,
  TOO_MANY_REQUESTS_STATUS,
} from '../../api/axiosInstance';

let isInitialized = false;

export type ErrorFromServer = PromiseRejectionEvent & ApiErrorResponseModel;

const ErrorHandlerService = {
  getErrorCodeFromError: function (error: ErrorFromServer) {
    const { response, reason } = error;

    return (
      response?.data?.code ||
      response?.data?.error?.code ||
      reason?.response?.data?.code ||
      reason?.response?.data?.error?.code
    );
  },

  getDetailsObject: function (error: ErrorFromServer) {
    const { response } = error;

    if (response?.data && response?.data.details) {
      return response.data.details;
    }
  },

  getErrorMessage: function (error: ErrorFromServer): string {
    let result = '';

    if (!i18n) {
      return '';
    }

    // here are 2 cases:
    // 1. error object has an code property so we can show an error message by this code (e.g. 1001002 - Contact with this email already exists)
    // 2. no error code, so in this case we should show our generic error message (Oops, something went wrong!) :)

    // get an error code from received error

    if (error && error?.response?.status === TOO_MANY_REQUESTS_STATUS) {
      result = i18n.t(`response_status_errors.${TOO_MANY_REQUESTS_STATUS}`, {
        ns: 'custom_errors',
      });
    } else {
      const errorMessageCode = ErrorHandlerService.getErrorCodeFromError(error);

      if (
        errorMessageCode &&
        i18n.exists(String(errorMessageCode), { ns: 'server_errors' })
      ) {
        result = i18n.t(errorMessageCode, { ns: 'server_errors' });
      } else {
        result = i18n.t('generic_error_message', { ns: 'custom_errors' });
      }
    }

    return result;
  },

  handleError: function (error: PromiseRejectionEvent | ApiErrorResponseModel) {
    ErrorHandlerService.displayErrorMessage(
      ErrorHandlerService.getErrorMessage(
        error as PromiseRejectionEvent & ApiErrorResponseModel,
      ),
    );
  },

  handleOnErrorEvent: function (error: ErrorEvent | any) {
    if (error) {
      // This error means that ResizeObserver was not able to deliver all observations within a single animation frame.
      // It is benign (site will not break)
      // Posts that explains that issue:
      // https://github.com/WICG/resize-observer/issues/38
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
      if (/resizeobserver/.test(error?.message || error || '').toString()) {
        error.preventDefault();
        error.stopImmediatePropagation();
      } else {
        const errorMessage = error?.message || error;
        trackException(SeverityLevel.Error, errorMessage);
      }
    }
  },

  displayErrorMessage: function (text: string) {
    message.error(text, NOTIFICATION_DURATION);
  },

  initialize: function () {
    if (!isInitialized) {
      window.addEventListener('error', ErrorHandlerService.handleOnErrorEvent);
      window.addEventListener(
        'unhandledrejection',
        ErrorHandlerService.handleError,
      );
      isInitialized = true;
    }
  },

  stop: function () {
    if (isInitialized) {
      window.removeEventListener(
        'error',
        ErrorHandlerService.handleOnErrorEvent,
      );
      window.removeEventListener(
        'unhandledrejection',
        ErrorHandlerService.handleError,
      );
      isInitialized = false;
    }
  },
};

export default ErrorHandlerService;
