import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import Text from '@core_components/Text';

const Default2FALabel = () => {
  const { t } = useTranslation('common');
  return (
    <StyledText
      variant="body1"
      color={colorsTheme.colorPrimary}
    >{`(${t('default')})`}</StyledText>
  );
};

const StyledText = styled(Text)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default Default2FALabel;
