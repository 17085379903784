import React, { useEffect, useState } from 'react';

// helpers
import { signOut } from 'redux/actions/auth';
import { StateModel } from 'redux/reducers';
import { initializeApp } from 'redux/actions/app';
import { decodeJWTToken } from 'helpers/utils';
import { LocalStorageHelpers } from 'helpers/storages/localStorage';
import { useDispatch, useSelector } from 'react-redux';

// Custom HOC component to resolve token sharing issue
const withJWTTokenCompare = () => (Component: any) => {
  const WithJWTTokenCompare = (componentProps: any) => {
    const dispatch = useDispatch();
    const isAuthorized = useSelector<StateModel>(
      (state) => state.auth.isAuthorized,
    );
    const [initialToken, setInitialToken] = useState(
      LocalStorageHelpers.getAccessToken(),
    );

    useEffect(() => {
      setInitialToken(LocalStorageHelpers.getAccessToken());
    }, [isAuthorized]);

    const getUserIdFromToken = (token: string) => {
      if (!token) {
        return null;
      }

      const decodedToken = decodeJWTToken(token);
      return decodedToken ? decodedToken.userId : null;
    };

    useEffect(() => {
      const handleFocus = () => {
        const currentLocalStorageToken = LocalStorageHelpers.getAccessToken();

        const initialUserId = getUserIdFromToken(initialToken);
        const localStorageUserId = getUserIdFromToken(currentLocalStorageToken);

        // The user is not authorized
        if (!initialUserId && !localStorageUserId) {
          return;
        }

        // The user is logged out or logged in
        if (!initialUserId || !localStorageUserId) {
          setInitialToken(LocalStorageHelpers.getAccessToken());
          if (!localStorageUserId && initialUserId) {
            dispatch(signOut());
          } else {
            dispatch(initializeApp());
          }
        }

        // The user is logged in but  the token was updated
        if (initialUserId !== localStorageUserId) {
          setInitialToken(LocalStorageHelpers.getAccessToken());
          dispatch(initializeApp());
        }
      };

      window.addEventListener('focus', handleFocus);

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('focus', handleFocus);
      };
    }, [initialToken]);

    return <Component {...componentProps} />;
  };

  return WithJWTTokenCompare;
};

export default withJWTTokenCompare;
