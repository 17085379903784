import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../../resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../../../DesignSystem/Core/IconSVG';
import OTPField from '../../../../../Forms/FormComponents/OTPField';
import FormField from '@core_components/FormField';
import { Divider } from 'antd';
import { ReactComponent as VerifiedIcon } from '../../../../../../resources/icons/remix-icons/shield-check-line.svg';

const InnerForm = () => {
  const { t } = useTranslation(['profile', 'common', 'custom_errors']);

  return (
    <>
      <HeaderContentWrapper>
        <StyledIconSVG
          component={VerifiedIcon}
          color={colorsTheme.colorWhite}
          size="large"
        />
        <Text variant="h6" weight="semi-bold">
          {t(
            'security.two_step_verification.authenticator.confirm_settings_change.second_title',
          )}
        </Text>
        <Text variant="body1">
          {t(
            'security.two_step_verification.authenticator.confirm_settings_change.second_title_description',
          )}
        </Text>
      </HeaderContentWrapper>

      <Divider />

      <StyledOTPLabelText variant="body1" gutterBottom>
        {t(
          'security.two_step_verification.authenticator.confirm_settings_change.form_fields.otp_code',
        )}
      </StyledOTPLabelText>

      <OTPFieldWrapper>
        <FormField
          name="code"
          component={StyledOTPField}
          additionalProps={{ isNumberInput: true }}
        />
      </OTPFieldWrapper>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const HeaderContentWrapper = styled.div`
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledOTPLabelText = styled(Text)`
  display: flex;
  justify-content: center;
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

export default InnerForm;
