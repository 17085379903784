import i18n from 'i18n';
import { clientGroupsAPI } from 'api/crm/clientGroupsAPI';
import { IContactEmail, IContactPhone } from 'typings/application/contact';

const ContactHelpers = {
  getFormattedContactName: function (
    firstName: string,
    lastName: string,
    middleName?: string,
  ) {
    return [firstName, middleName, lastName].filter(Boolean).join(' ');
  },

  getPrimaryEmail: function (emails: IContactEmail[]): string {
    const primaryEmail = emails.find((email) => email.isPrimary);
    return primaryEmail?.address || '';
  },

  getPrimaryPhoneNumber: function (phoneNumbers: IContactPhone[]): string {
    const primaryPhoneNumber = phoneNumbers.find((phone) => phone.isPrimary);
    return primaryPhoneNumber?.number || '';
  },

  // Custom asynchronous validation function for array of phone numbers
  validatePhoneNumbers: async function (phoneNumbers: string[]) {
    try {
      // Call your API endpoint with the array of phone numbers
      const response = await clientGroupsAPI.validatePhoneNumbers(phoneNumbers);

      // Assuming your API returns an object with invalid phone numbers
      const invalidNumbers = response.data.reduce<string[]>((acc, next) => {
        if (!next.isValid) {
          acc.push(next.phoneNumber);
        }

        return acc;
      }, []);

      // Create an object to store errors for each phone number
      const errors = {} as Record<string, { errorMessage: string }>;

      // Loop through all phone numbers and set errors for invalid ones
      phoneNumbers.forEach((phoneNumber) => {
        if (invalidNumbers.includes(phoneNumber)) {
          errors[phoneNumber] = {
            errorMessage: i18n.t(
              'validation_phone_number.invalid_phone_number',
              { ns: 'custom_errors' },
            ),
          };
        }
      });

      return Object.keys(errors).length === 0 ? undefined : errors;
    } catch (error) {
      // If there's an error, return an undefined value
      return undefined;
    }
  },
};

export { ContactHelpers };
