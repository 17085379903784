import React from 'react';
import { connect } from 'react-redux';
import { Component } from 'react';
import { StateModel } from 'redux/reducers';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { AppInsightsService } from './service';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking
 * that requires this to be a Class Component rather than a Functional Component
 */

interface IProps {
  instrumentationKey: string | null;
}

interface IState {
  initialized: boolean;
}

class AppInsightsProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidUpdate() {
    // Disable azure app insights for localhost developing
    if (process.env.REACT_APP_STAGE === 'local') {
      return;
    }

    const { instrumentationKey } = this.props;
    const { initialized } = this.state;

    if (!initialized && !!instrumentationKey) {
      AppInsightsService.initialize(instrumentationKey);
      this.setState({ initialized: true });
    }
  }

  render(): JSX.Element {
    const { children } = this.props;
    return <>{children}</>;
  }
}

const mapStateToProps = (state: StateModel) => ({
  // Map Redux state to component props
  instrumentationKey: state.app.instrumentationKey,
});

export default withAITracking(
  AppInsightsService.reactPlugin,
  connect(mapStateToProps)(AppInsightsProvider as any) as any,
) as any;
