import React, { useEffect, useMemo } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import { ICurrency } from '../../../../../../typings/accounting/currency';
import { currenciesAPI } from '../../../../../../api/accounting/currenciesAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps<ICurrency>;

const CurrencySelect = (props: IProps) => {
  const { response, loading } = useFetch(
    () =>
      currenciesAPI.fetchCurrencies({
        page: 1,
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        isExternal: false,
        isAccount: true,
        isActive: true,
      }),
    [],
  );

  const options = useMemo<SelectOption<ICurrency>[]>(() => {
    if (!response) {
      return [];
    }

    return response.data.map((e) => ({
      id: e.id,
      label: `${e.name} ${e.isoCode}`,
      model: e,
    }));
  }, [response]);

  useEffect(() => {
    if (options.length === 1 && !props.value) {
      props.onChange && props.onChange(options[0].id as any);
    }
  }, [props.value, options]);

  return (
    <FormSelect
      {...props}
      disabled={props.disabled || options.length === 1}
      loading={loading}
      options={options}
    />
  );
};

export default CurrencySelect;
