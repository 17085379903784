import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { TransfersHelpers } from 'helpers/transfers/transfers';
import { TransactionTypes } from 'enums/approvalManagement/approvalManagement';
import { TransactionDocument } from 'typings/accounting/transaction';
import {
  TransactionSourceTypes,
  TransferApprovalStatuses,
  TransferTemplateTypes,
} from 'enums/accounting/transfers';

// components
import Link from '@common_components/Texts/Link';
import DocumentsValue from '../../DocumentsValue';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import TransactionStatus from 'components/Additional/Statuses/TransactionStatus';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

export type InternalTransactionSenderData = {
  type: TransactionSourceTypes;
  purpose: string;
  amount: string;
  postingDate: string;
  valueDate: string;
  requestedBy: string;
  status: TransferApprovalStatuses;
  workflowExpiresAt: number | null;
  template: { id: number; name: string; canAccessTemplate: boolean } | null;
  relatedDocuments: TransactionDocument[];
  transactionNumber: string;
  orderingCustomer: {
    accountName: string;
    accountNumber: string;
    address: string;
    reference: string;
  };
  beneficiary: {
    accountName: string;
    accountNumber: string;
    reference: string;
  };
};

interface IProps {
  journalEntryId: number | null;
  transaction: InternalTransactionSenderData;
  onUpload?: () => void;
}

const ValueWrapper = memo(({ children }) => {
  return (
    <EllipsisTooltip maxTextContainerWidth="500px" title={children}>
      {children}
    </EllipsisTooltip>
  );
});

const Sender = memo(({ transaction, journalEntryId, onUpload }: IProps) => {
  const { t } = useTranslation('accounts');
  const descriptionData = useMemo<DescriptionItem[]>(() => {
    let result: DescriptionItem[] = [];
    const {
      type,
      purpose,
      template,
      orderingCustomer,
      beneficiary,
      amount,
      postingDate,
      valueDate,
      requestedBy,
      relatedDocuments,
      transactionNumber,
    } = transaction;

    result = [
      {
        label: t(
          'transaction_details_modal.transaction_details.internal.transaction_number',
        ),
        description: transactionNumber,
      },
      {
        label: t('transaction_details_modal.transaction_details.internal.type'),
        description: t(`types.${TransactionTypes[type]}`),
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.purpose',
        ),
        description: purpose,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.ordering_customer',
        ),
        description: (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_name',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountName}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_number',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountNumber}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.address',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.address}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.from_account_reference',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.reference}</ValueWrapper>
                ),
              },
            ]}
          />
        ),
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.beneficiary',
        ),
        description: (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.transfer_template',
                ),
                hidden: !template,
                description: template?.canAccessTemplate ? (
                  <Link
                    to={{
                      pathname: RoutePaths.Transfers_TransferTemplates,
                      search: `?templateId=${template?.id}&templateType=${TransferTemplateTypes.Internal}`,
                    }}
                  >
                    {TransfersHelpers.getTransferTemplateName(
                      String(template?.id),
                      String(template?.name),
                    )}
                  </Link>
                ) : (
                  template?.name
                ),
              },
              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_name',
                ),
                description: (
                  <ValueWrapper>{beneficiary.accountName}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_number',
                ),
                description: (
                  <ValueWrapper>{beneficiary.accountNumber}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.to_account_reference',
                ),
                description: (
                  <ValueWrapper>{beneficiary.reference}</ValueWrapper>
                ),
              },
            ]}
          />
        ),
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.amount',
        ),
        description: amount,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.posting_date',
        ),
        description: postingDate,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.value_date',
        ),
        description: valueDate,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.requested_by',
        ),
        description: requestedBy,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.status',
        ),
        description: (
          <TransactionStatus
            transaction={{
              status: transaction.status,
              expiresAt: transaction.workflowExpiresAt || undefined,
            }}
          />
        ),
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.documents',
        ),
        description: (
          <DocumentsValue
            transactionDocuments={relatedDocuments}
            journalEntryId={journalEntryId}
            onUpload={onUpload}
          />
        ),
      },
    ];

    return result;
  }, [t, transaction, journalEntryId, onUpload]);

  return <DescriptionSection data={descriptionData} />;
});

const StyledInnerDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 250px !important;
    max-width: 250px !important;
  }
`;

export default Sender;
