import React from 'react';

// helpers
import { IFileItem } from '../../../../../redux/actions/upload';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import Camera from '../../../../../components/Additional/Camera';

const SecuritySelfieVerification = () => {
  const form = useFormikContext<FormValuesModel>();

  const handleSelfieUpload = async (file: IFileItem) => {
    await form.setFieldValue('selfieSrc', file.dataBaseId);
    form.handleSubmit();
  };

  const handleCameraClear = () => {
    form.setFieldValue('selfieSrc', '');
  };

  return (
    <Camera
      documentRelatedTo="reset_password"
      onUpload={handleSelfieUpload}
      onCameraClear={handleCameraClear}
    />
  );
};

export default SecuritySelfieVerification;
