import React, { memo } from 'react';

// helpers
import { TransfersHelpers } from 'helpers/transfers/transfers';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { transferTemplatesAPI } from '../../../../../../api/accounting/transferTemplatesAPI';
import { ITransferTemplateShortModel } from '../../../../../../typings/accounting/transfer';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export type InternalTransferTemplateAutocompleteProps =
  AutocompleteProps<ITransferTemplateShortModel>;

const InternalTransferTemplateAutocomplete = memo(
  (props: InternalTransferTemplateAutocompleteProps) => {
    const fetchOptions = async (searchQuery?: string) => {
      // TODO: update limit to default one when infinite scroll will be implemented
      const { data } = await transferTemplatesAPI.getInternalTransferTemplates({
        limit: 10000,
        page: 1,
        name: searchQuery,
      });

      return data.map((e) => ({
        id: e.id,
        label: TransfersHelpers.getTransferTemplateName(String(e.id), e.name),
        model: e,
      }));
    };

    return <FormAutocomplete {...props} fetchData={fetchOptions} />;
  },
);

export default InternalTransferTemplateAutocomplete;
