import React, { useMemo } from 'react';

// constants
import { TWO_COL_GRID_SIZES } from '../../../../../../constants/grids';
import {
  MAX_ADDRESSES_COUNT,
  MAX_PASSPORTS_COUNT,
} from '../../../../../../constants/onboarding';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { AddressItemModel, FormValuesModel, PassportItemModel } from '..';

// components
import FormField from '@core_components/FormField';
import AddButton from '../../../../../DesignSystem/Common/Buttons/AddButton';
import InfoTooltip from '../../../../../Tooltips/InfoTooltip';
import SectionIntro from '../../../../../DesignSystem/Common/Texts/SectionIntro';
import PassportForm from './PassportForm';
import AddressesForm from './AddressesForm';
import DivAlignCenter from '../../../../../Additional/DivAlignCenter';
import TrueFalseSelect from '../../../../FormComponents/SelectInputs/TrueFalseSelect';
import FormErrorTooltip from '../../../../FormErrorTooltip';
import AreYouPepTooltip from '../../../../../Tooltips/TemplateTooltips/AreYouPepTooltip';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import HideIfDisabledForm from '../../../../HideIfDisabledForm';
import CountrySelect, {
  IProps as CountrySelectProps,
} from '../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import NationalitySelect, {
  IProps as NationalitySelectProps,
} from '../../../../FormComponents/SelectInputs/CRM/NationalitySelect';
import { Col, Row } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const renderAddresses = (arrayHelper: FieldArrayRenderProps) => {
    const newAddressData: AddressItemModel = {
      isPrimary: false,
      type: null,
      country: null,
      city: '',
      street: '',
      state: '',
      postalCode: '',
    };

    return (
      <>
        {values.addresses.map((_, i) => (
          <AddressesForm
            key={i}
            index={i}
            onRemoveClick={() => arrayHelper.remove(i)}
          />
        ))}

        {values.addresses.length < MAX_ADDRESSES_COUNT && (
          <HideIfDisabledForm hideIfSubmitting>
            <AddButton onClick={() => arrayHelper.push(newAddressData)}>
              {t('identification.add_address_button')}
            </AddButton>
          </HideIfDisabledForm>
        )}
      </>
    );
  };

  const renderPassports = (arrayHelper: FieldArrayRenderProps) => {
    const canDeletePassports = values.passports.length > 1;
    const newPassport: PassportItemModel = {
      number: '',
      country: '',
      issuedAt: null,
      expirationDate: null,
      document: [],
    };

    return (
      <>
        {values.passports.map((_, i) => (
          <PassportForm
            key={i}
            index={i}
            onRemoveClick={
              canDeletePassports ? () => arrayHelper.remove(i) : undefined
            }
          />
        ))}

        {values.passports.length < MAX_PASSPORTS_COUNT && (
          <HideIfDisabledForm hideIfSubmitting>
            <AddButton onClick={() => arrayHelper.push(newPassport)}>
              {t('identification.add_passport_button')}
            </AddButton>
          </HideIfDisabledForm>
        )}
      </>
    );
  };

  const sectionTitle = useMemo(() => {
    const title = t('identification.title');

    if (values.disabledPep) {
      return (
        <DivAlignCenter>
          {title}
          <StyledInfoTooltip
            tooltipContent={t('identification.is_pep_disabled_tooltip')}
          />
        </DivAlignCenter>
      );
    }

    return title;
  }, [values.disabledPep]);

  return (
    <>
      <SectionIntro
        title={sectionTitle}
        description={t('identification.description_individual')}
      />
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={
              <DivAlignCenter>
                {t('identification.form_fields.individual.is_pep')}{' '}
                <AreYouPepTooltip />
              </DivAlignCenter>
            }
            name="isPEP"
            disabled={values.disabledPep}
            component={TrueFalseSelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          {values.isPEP && (
            <FormField
              label={t('identification.form_fields.individual.pep_information')}
              name="pepInformation"
              component={FormInputTextArea}
              disabled={values.disabledPep}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('identification.form_fields.individual.is_regulated')}
            name="isRegulated"
            component={TrueFalseSelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          {values.isRegulated && (
            <FormField<CountrySelectProps>
              label={t(
                'identification.form_fields.individual.regulated_in_countries',
              )}
              name="regulationCountry"
              component={CountrySelect}
              additionalProps={{ mode: 'multiple' }}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField<NationalitySelectProps>
            label={t('identification.form_fields.individual.nationality')}
            name="nationality"
            component={NationalitySelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('identification.passport_section_title')}
        description={t('identification.passport_section_desc')}
      />
      <FieldArray name="passports" render={renderPassports} />

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('identification.address_information_section_title')}
        description={t(
          'identification.address_information_for_individual_description',
        )}
      />
      <FormErrorTooltip<FormValuesModel> errorKey="addresses" />
      <FieldArray name="addresses" render={renderAddresses} />
    </>
  );
};

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

export default InnerForm;
