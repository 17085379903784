import React, { memo } from 'react';

// components
import ForgotUsername from 'modules/Auth/ResetUsername';

const ForgotUsernamePage = memo(() => {
  return <ForgotUsername />;
});

export default ForgotUsernamePage;
