import React, { useEffect, useState } from 'react';

// helpers
import { StateModel } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { ApplicationStatuses } from '../../enums/onboarding/crm';
import { StateModel as ApplicationsStateModel } from '../../redux/reducers/applications';
import {
  PageLayoutOptions,
  useMainLayoutContext,
} from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import StructureChart from '../../modules/AccountManagement/ClientProfile/StructureChart';
import AddNewNodeModal from '../../modules/AccountManagement/ClientProfile/StructureChart/InnerGraph/ModalDialogs/AddNewNodeModal';
import PendingApprovalTooltip from '../../components/Tooltips/TemplateTooltips/PendingApprovalTooltip';

const ClientProfile = () => {
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const [updateStructureChartTrigger, updateStructureChart] = useState({});
  const { activeApplicationStatus, activeApplication } = useSelector<
    StateModel,
    ApplicationsStateModel
  >(({ applications }) => applications);

  useEffect(() => {
    const config: PageLayoutOptions | null = {};
    const isApplicationInReview =
      activeApplicationStatus ===
        ApplicationStatuses.InAccountManagementReview ||
      activeApplicationStatus === ApplicationStatuses.InReview;

    if (!isApplicationInReview) {
      config['extra'] = () => (
        <AddNewNodeModal
          closeCallback={(wasSubmitted) =>
            wasSubmitted && updateStructureChart({})
          }
          clientGroupId={activeApplication?.clientGroupId as string}
          relationshipScopeId={
            activeApplication?.onboardingProcess.relationshipScopeId as string
          }
        />
      );
    }

    if (isApplicationInReview) {
      config['extra'] = null;
      if (
        activeApplicationStatus ===
        ApplicationStatuses.InAccountManagementReview
      ) {
        config['description'] = (
          <PendingApprovalTooltip approvalType="account_management" />
        );
      } else if (activeApplicationStatus === ApplicationStatuses.InReview) {
        config['description'] = (
          <PendingApprovalTooltip approvalType="internal" />
        );
      }
    }

    config && setPageLayoutOptions(config);

    return () => clearContext();
  }, [activeApplicationStatus, setPageLayoutOptions, clearContext]);

  return <StructureChart updateTrigger={updateStructureChartTrigger} />;
};

export default ClientProfile;
