import React from 'react';

//  components
import { default as PendingTransactionsContent } from '../../modules/Accounts/PendingTransactions';

const PendingTransactions = () => {
  return <PendingTransactionsContent />;
};

export default PendingTransactions;
