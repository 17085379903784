import React from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import Text from '@core_components/Text';

interface IProps {
  title: string;
  subTitle?: string;
}

const TitleSection = ({ title, subTitle }: IProps) => {
  return (
    <TitleWrapper>
      <Text variant="h4" color={colorsTheme.colorWhite} weight="semi-bold">
        {title}
      </Text>
      {subTitle && <Text variant="subtitle1">{subTitle}</Text>}
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.marginXXs};
`;

export default TitleSection;
