import React from 'react';

// helpers
import { RoutePaths } from 'routes/routes';
import { StateModel } from '../redux/reducers';
import { useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from '../redux/reducers/auth';

// components
import { Navigate } from 'react-router-dom';

const withPrivateRouteCheck = () => (Component: any) => {
  const WithPrivateRouteCheck = () => {
    const { isAuthorized } = useSelector<StateModel, AuthStateModel>(
      (store) => store.auth,
    );

    return !isAuthorized ? (
      <Navigate to={RoutePaths.Auth_Root} />
    ) : (
      <Component />
    );
  };

  return WithPrivateRouteCheck;
};

export default withPrivateRouteCheck;
