import React from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { feeScheduleAPI } from '../../../../api/accountManagement/feeScheduleAPI';

// components
import FeeScheduleTable from '../../../../components/Tables/TableTemplates/AccountManagement/FeeScheduleTable';

const FeeScheduleTab = () => {
  const { response, loading } = useFetch(
    () => feeScheduleAPI.fetchDefaultFeeSchedules(),
    [],
  );

  return (
    <FeeScheduleTable
      data={response?.data || []}
      total={response?.total || 0}
      loading={loading}
      current={1}
    />
  );
};

export default FeeScheduleTab;
