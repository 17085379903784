import React from 'react';

// helpers
import styled from 'styled-components';
import { RoutePaths } from 'routes/routes';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from 'redux/reducers/auth';

// components
import FooterBar from 'components/Layout/FooterBar';
import { Navigate, Outlet } from 'react-router-dom';

const AuthenticationLayout = () => {
  const { isAuthorized } = useSelector<StateModel, AuthStateModel>(
    (store) => store.auth,
  );

  if (isAuthorized) {
    return <Navigate to={RoutePaths.Root} />;
  }

  return (
    <Root>
      <Outlet />
      <FooterBarWrapper>
        <FooterBar transparent />
      </FooterBarWrapper>
    </Root>
  );
};

const Root = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => `
    @media screen and (max-width: ${theme.mobileScreen}) {
      overflow: hidden;
    }
  `}
`;

const FooterBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  z-index: -1;
`;

export default AuthenticationLayout;
