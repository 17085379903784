import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { StateModel } from '../../../redux/reducers';
import { IApplication } from 'typings/application/applications';
import { selectApplication } from 'redux/actions/applications';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as ApplicationStateModel } from '../../../redux/reducers/applications';

// components
import Text from '@core_components/Text';
import AsyncButton from '@common_components/Buttons/AsyncButton';
import ClientGroupDetails from 'components/Additional/DetailsSections/Connections/ClientGroupDetails';

const SelectApplicationCard = () => {
  const { t } = useTranslation(['onboarding', 'connections', 'common']);
  const dispatch = useDispatch();
  const [selectedClientGroup, setSelectedClientGroup] =
    useState<IApplication | null>(null);
  const { applications } = useSelector<StateModel, ApplicationStateModel>(
    (state) => state.applications,
  );

  const onSelect = (application: IApplication) => {
    setSelectedClientGroup(application);
  };

  const onContinue = () => {
    if (selectedClientGroup) {
      dispatch(selectApplication(selectedClientGroup, true));
    }
  };

  return (
    <>
      <StyledDescription variant="h5">
        {t('application.select_application.description')}
      </StyledDescription>
      {applications?.map((application, index) => {
        return (
          <StyledClientGroupDetails
            key={index}
            removeMargin
            clientGroup={{
              name: application.clientGroup.clientGroupName,
              type: application.crmItemType,
              entityType:
                application.onboardingProcess?.item?.keyRelationshipInstance
                  ?.name || '',
            }}
            isSelectedClientGroup={application._id === selectedClientGroup?._id}
            onClick={() => onSelect(application)}
          />
        );
      })}
      <StyledAsyncButton
        disabled={!selectedClientGroup}
        size="large"
        fullWidth={false}
        onClick={onContinue}
      >
        {t('continue', { ns: 'common' })}
      </StyledAsyncButton>
    </>
  );
};

const StyledDescription = styled(Text)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const StyledAsyncButton = styled(AsyncButton)`
  width: 200px;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.marginXs};
`;

const StyledClientGroupDetails = styled(ClientGroupDetails)<{
  isSelectedClientGroup: boolean;
}>`
  background: ${({ isSelectedClientGroup, theme }) =>
    isSelectedClientGroup ? theme.colorDarkL4 : theme.colorDark};
  transition: ${({ theme }) => theme.defaultTransition};
  border: solid 1px transparent;
  border-color: ${({ isSelectedClientGroup, theme }) =>
    isSelectedClientGroup ? theme.colorPrimary : 'transparent'};
  cursor: pointer;
  margin: ${({ theme }) => theme.marginXs} 0;

  &:hover {
    border-color: ${({ theme }) => theme.colorPrimary};
  }
`;

export default SelectApplicationCard;
