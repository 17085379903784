import React, { useMemo } from 'react';

// helpers
import { AutocompleteProps } from '@core_components/Autocomplete';
import { RelationshipHelpers } from '../../../../../../helpers/crm/relationship';
import { RelationshipTemplate } from 'typings/application/relationship-template';
import { relationshipTemplatesAPI } from '../../../../../../api/crm/relationshipTemplatesAPI';
import {
  OnboardingEntryTypes,
  RelationshipTrackTypes,
} from '../../../../../../enums/onboarding/crm';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface RelationshipTemplatesAutocompleteProps
  extends AutocompleteProps<RelationshipTemplate> {
  childId: string;
  parentType: OnboardingEntryTypes;
  relationshipScopeId: string;

  parentId?: string;
  trackType?: RelationshipTrackTypes;
  withoutOwnershipTemplates?: boolean;
}

const RelationshipTemplatesAutocomplete = ({
  childId,
  parentId,
  trackType,
  parentType,
  excludeElementIds,
  relationshipScopeId,
  withoutOwnershipTemplates,
  ...rest
}: RelationshipTemplatesAutocompleteProps) => {
  const formattedExcludeIds = useMemo(() => {
    if (excludeElementIds && excludeElementIds.length) {
      if (rest.value) {
        return excludeElementIds.filter((e) => e !== rest.value);
      } else {
        return excludeElementIds;
      }
    }
  }, [excludeElementIds, rest.value]);

  const fetchOptions = async (searchQuery?: string) => {
    const { data } =
      await relationshipTemplatesAPI.fetchAvailableRelationshipTemplatesForRelationship(
        {
          parentId,
          parentType,
          childId,
          relationshipScopeId,
          parentNameSearch: searchQuery,
          withoutOwnershipTemplates,
        },
      );

    return data.map((e) => ({
      id: e._id,
      model: e,
      label: RelationshipHelpers.getRelationshipName(
        { parentName: e.parentName, childName: e.childName },
        trackType || RelationshipTrackTypes.Parent,
      ),
    }));
  };

  return (
    <FormAutocomplete
      {...rest}
      fetchData={fetchOptions}
      excludeElementIds={formattedExcludeIds}
    />
  );
};

export default RelationshipTemplatesAutocomplete;
