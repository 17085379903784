import moment, { Moment } from 'moment';
import { FormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/UserProfile/AddTravelNotificationModal';
import { COUNTRY_CODES } from '../../constants/countryCodes';
import { FetchResponseModel } from '../../typings/common';
import { TravelNotificationModel } from '../../typings/profile/travelNotifications';

interface NewTripModel {
  itinerary: NewTripCountryModel[];
}

interface NewTripCountryModel {
  countryCode: string;
  countryName: string;
  departureDate: Moment;
  returnDate: Moment;
}

interface ResponseTravelNotificationModel {
  id: string;
  canEdit: boolean;
  createdAt: string;
  itinerary: NewTripCountryModel[];
}

const travelNotificationsAdapter = {
  generateNewTripBody: (formValues: FormValuesModel) => {
    const result: NewTripModel = { itinerary: [] };

    result.itinerary = formValues.countries.map(
      ({ country, departureDate, returnDate }) => ({
        countryCode: country as string,
        countryName: COUNTRY_CODES[country as string],
        departureDate: departureDate as Moment,
        returnDate: returnDate as Moment,
      }),
    );

    return result;
  },

  fetchTravelNotificationsAdapter: ({
    data,
    total,
  }: FetchResponseModel<ResponseTravelNotificationModel>) => {
    const result: FetchResponseModel<TravelNotificationModel> = {
      data: [],
      total,
    };

    result.data = data.map(({ id, itinerary, createdAt }) => {
      const countries = itinerary.map(
        ({ countryCode, departureDate, returnDate }) => ({
          country: countryCode,
          departureDate,
          returnDate,
        }),
      );

      const canEdit = moment().isSameOrBefore(
        countries[0].departureDate,
        'day',
      );

      return {
        id,
        canEdit,
        createdAt,
        countries,
      };
    });

    return result;
  },
};

export { travelNotificationsAdapter };
