import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { IOverdraftModel } from '../../typings/limitConfiguration/overdraft';
import { FetchResponseModel } from '../../typings/common';

export const overdraftAPI = {
  fetchOverdrafts: () => {
    return APIService.get<FetchResponseModel<IOverdraftModel>>(
      `${APIConfig.accountingApi}/accounts/overdraft`,
    ).then(({ data }) => data);
  },
};
