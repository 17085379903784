import React, { Component, ReactNode } from 'react';

// helpers
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { trackException } from 'services/azure-app-insights/service';

// components
import ErrorPage from './ErrorPage';
import ErrorHandlerService from './service';

interface IProps {
  children: ReactNode;
}

interface IState {
  error: any;
  hasError: boolean;
}

class ErrorHandlerProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  componentDidMount() {
    ErrorHandlerService.initialize();
  }

  componentWillUnmount() {
    ErrorHandlerService.stop();
  }

  componentDidCatch(error: any) {
    this.setState({ error });
  }

  removeError() {
    this.setState({ error: null, hasError: false });
  }

  static getDerivedStateFromError(error: any) {
    // Track page crash error
    trackException(SeverityLevel.Critical, error);
    return { hasError: true, error };
  }

  render(): JSX.Element {
    const { children } = this.props;
    const { hasError, error } = this.state;

    if (error || hasError) {
      return <ErrorPage removeError={this.removeError.bind(this)} />;
    }

    return <>{children}</>;
  }
}

export default ErrorHandlerProvider;
