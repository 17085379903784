import React, { memo, useCallback, useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { userAPI } from 'api/profile/userAPI';
import { message } from 'antd';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { userAPIAdapter } from 'apiAdapters/profile/userAPIAdapter';

// components
import ForgotUsernameForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Auth/ForgotUsernameForm';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';

const ForgotUsername = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues = useMemo<FormValuesModel>(() => {
    return {
      step: 'user_data',
      user: {
        name: '',
        email: '',
        dateOfBirth: null,
        clientGroup: '',
      },
      emailConfirmation: {
        otp: '',
      },
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: FormValuesModel) => {
      await userAPI.submitForgetUsernameRequest(
        userAPIAdapter.submitForgotUsernameRequest(values),
      );
      message.success(t('reset_username.success_submit_message'));
      navigate(RoutePaths.Auth_Root);
    },
    [t],
  );

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1201020': {
        formikHelpers.setFieldError(
          'emailConfirmation.otp',
          t('1201020', { ns: 'server_errors' }),
        );
        break;
      }

      case '1204004':
        {
          ErrorHandlerService.handleError(error);
          formikHelpers.setFieldValue('step', 'user_data');
        }
        break;

      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <ForgotUsernameForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSubmitError={handleOnSubmitError}
    />
  );
});

export default ForgotUsername;
