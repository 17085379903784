import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { DateHelpers } from 'helpers/date';
import { ApprovalStatuses } from '../../../enums/approvalManagement/approvalManagement';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import {
  IApprovalMatrixRecord,
  IApprovalGroupUser,
} from '../../../typings/accounting/transaction';

// components
import Text from '@core_components/Text';
import { List } from 'antd';

interface IProps {
  dataIndex: string;
  record: IApprovalMatrixRecord[];
  isOrText: boolean;
}

const ContentCell = ({ dataIndex, record, isOrText, ...rest }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderCol = (keyToShow: keyof IApprovalGroupUser) => {
    // We only need to render AND/OR/Group info in first column
    const isFirstCol = keyToShow === 'userFullName';
    const isStatusCol = keyToShow === 'approvalAction';

    return (
      <>
        {record.map((e, i: number) => {
          const shouldRenderAnd = i < record.length - 1;

          return (
            <div key={i}>
              <GroupTitleRow
                weight="semi-bold"
                isGroupName
                isMarginTop={i === 0}
              >
                {isFirstCol && e.groupNameTittle}
              </GroupTitleRow>

              <List
                size="small"
                dataSource={e.members}
                renderItem={(item: IApprovalGroupUser, i: number) => (
                  <StyledListItem
                    key={i}
                    color={
                      isStatusCol
                        ? getStatusTextColor(
                            item[keyToShow] as ApprovalStatuses,
                          )
                        : ''
                    }
                  >
                    {isStatusCol
                      ? renderStatusValue(
                          item[keyToShow] as ApprovalStatuses,
                          item.approvalDate,
                        )
                      : item[keyToShow]}
                  </StyledListItem>
                )}
              />

              {shouldRenderAnd && (
                <ANDTitle weight="semi-bold">
                  {isFirstCol && t('approval_matrix.and_separator')}
                </ANDTitle>
              )}
            </div>
          );
        })}

        {isOrText && isFirstCol && (
          <ORTextWrapper>
            <Text variant="body1" weight="semi-bold">
              {t('approval_matrix.or_separator')}
            </Text>
          </ORTextWrapper>
        )}
      </>
    );
  };

  const renderStatusValue = (status: ApprovalStatuses, date: string) => {
    if (status === ApprovalStatuses.Pending) {
      return '';
    }

    switch (status) {
      case ApprovalStatuses.Approved:
        return `${t('approval_matrix.statuses.approved')} - ${DateHelpers.formatDateToString(date, DEFAULT_DATE_TIME_FORMAT)}`;
      case ApprovalStatuses.Rejected:
        return `${t('approval_matrix.statuses.rejected')} - ${DateHelpers.formatDateToString(date, DEFAULT_DATE_TIME_FORMAT)}`;
      default:
        return '';
    }
  };

  const getStatusTextColor = (status: ApprovalStatuses) => {
    switch (status) {
      case ApprovalStatuses.Approved:
        return 'colorSuccess';
      case ApprovalStatuses.Rejected:
        return 'colorError';
      default:
        return '';
    }
  };

  const renderContentCell = (dataKey: string) => {
    switch (dataKey) {
      case 'group-info':
        return renderCol('userFullName');

      case 'status-info':
        return renderCol('approvalAction');

      case 'note-info':
        return renderCol('approvalReason');

      default:
        return null;
    }
  };
  return (
    <StyledTableCol {...rest}>{renderContentCell(dataIndex)}</StyledTableCol>
  );
};

const StyledTableCol = styled.td`
  padding: 0px !important;
`;

const GroupTitleRow = styled(Text)<{
  isGroupName?: boolean;
  isMarginTop?: boolean;
}>`
  max-width: 100%;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: ${({ theme, isMarginTop }) =>
    isMarginTop ? theme.paddingXl : ''};
  color: ${({ theme, isGroupName }) => (isGroupName ? theme.colorSuccess : '')};
`;

const ANDTitle = styled(GroupTitleRow)`
  padding-left: 36px;
`;

const ORTextWrapper = styled.div`
  position: absolute;
  bottom: -13px;
  left: 18px;
  width: 50px;
  text-align: center;
  background: ${({ theme }) => theme.colorDarkL2};
`;

const StyledListItem = styled(List.Item)`
  min-height: 45px;
  color: ${({ theme, color }: { color?: string; theme: any }) =>
    color ? theme[color] : ''} !important;
`;

export default ContentCell;
