interface ILang {
  [key: string]: string;
}

export const LocalizationNamespaces = [
  'account_management',
  'accounts',
  'auth',
  'common',
  'dashboard',
  'custom_errors',
  'form',
  'onboarding',
  'profile',
  'transfers',
  'server_errors',
  'invitation',
  'documents',
  'connections',
];

export const localizationLanguages: ILang = {
  en: 'English',
  it: 'Italiano',
  es: 'Español',
  fr: 'Français',
  he: 'עברית',
};
