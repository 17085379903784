import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import AddAccount from '../../modules/Accounts/AccountSummary/AddAccount';
import { default as AccountSummaryContent } from '../../modules/Accounts/AccountSummary/index';

const AccountSummary = () => {
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const [updateAccountsTrigger, updateAccounts] = useState({});
  const [hasFiatAccountCreateAccess, hasBlockchainAccountCreateAccess] =
    useUserAccess([
      'Accounts_FiatAccount_Create',
      'Accounts_BlockchainAccount_Create',
    ]);

  useEffect(() => {
    if (hasFiatAccountCreateAccess || hasBlockchainAccountCreateAccess) {
      setPageLayoutOptions((prev) => ({
        ...prev,
        extra: <AddAccount onCreate={() => updateAccounts({})} />,
      }));
    }

    return () => clearContext();
  }, [
    hasFiatAccountCreateAccess,
    hasBlockchainAccountCreateAccess,
    setPageLayoutOptions,
    clearContext,
  ]);

  return (
    <AccountSummaryContent updateAccountsTrigger={updateAccountsTrigger} />
  );
};

export default AccountSummary;
