import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ValidateStructureErrorModel } from '../../../../../api/onboarding/onboardingAPI';

// components
import WarningCard from './WarningCard';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Col, Row } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  errors: ValidateStructureErrorModel[] | null;
  continueCallback: () => Promise<void> | void;
}

const ValidateStructureModalDialog = ({
  errors,
  isVisible,
  closeCallback,
  continueCallback,
}: IProps) => {
  const { t } = useTranslation(['onboarding', 'common']);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formattedErrors = useMemo(() => {
    if (errors && errors.length) {
      const criticalErrors = errors.filter((e) => e.type == 'critical');
      if (criticalErrors.length) {
        return criticalErrors;
      } else {
        return errors;
      }
    }

    return [];
  }, [errors]);

  const canContinue = useMemo(() => {
    let hasCriticalError = false;

    if (errors) {
      hasCriticalError = errors.some((e) => e.type === 'critical');
    }

    return !hasCriticalError;
  }, [errors]);

  const handleContinueClick = async () => {
    setIsSubmitting(true);
    await continueCallback();
    setIsSubmitting(false);
  };

  return (
    <ModalDialog
      title={t('structure.validate_structure_modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      submitButtonProps={{
        text: t('continue', { ns: 'common' }),
        hidden: !canContinue,
        onClick: handleContinueClick,
        loading: isSubmitting,
        disabled: isSubmitting,
      }}
    >
      <Row gutter={[16, 16]}>
        {formattedErrors.map((e, i) => (
          <Col key={i} span={24}>
            <WarningCard error={e} />
          </Col>
        ))}
      </Row>
    </ModalDialog>
  );
};

export default ValidateStructureModalDialog;
