import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import SubmitButton from '../../../../../../Buttons/SubmitButton';
import TitleSection from '../TitleSection';
import FormInputPassword from '@common_components/Form/FormInputPassword';
import SetupPasswordField from '../../../../../FormComponents/SetupPasswordField';
import FormInputPhoneNumber from '@common_components/Form/FormInputPhoneNumber';
import { Card, Col, Row } from 'antd';

const UserInfoStep = () => {
  const { t } = useTranslation(['auth', 'common']);

  const { setFieldValue, values } = useFormikContext<FormValuesModel>();

  const onBack = () => {
    setFieldValue('step', values.step - 1);
  };

  return (
    <>
      <TitleWrapper>
        {values.entityDescription && (
          <DescriptionEntityWrapper variant="h4" color={colorsTheme.colorWhite}>
            {values.entityDescription}
          </DescriptionEntityWrapper>
        )}
        <TitleSection
          title={t('open_an_account.enter_credentials')}
          subTitle={t('open_an_account.enter_credentials_description')}
        />
      </TitleWrapper>

      <StyledCard>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <FormField
              name="firstName"
              component={FormInput}
              label={t('open_an_account.form_fields.first_name')}
            />
          </Col>
          <Col span={12}>
            <FormField
              name="lastName"
              component={FormInput}
              label={t('open_an_account.form_fields.last_name')}
            />
          </Col>
          <Col span={12}>
            <FormField
              name="email"
              component={FormInput}
              label={t('open_an_account.form_fields.email')}
            />
          </Col>
          <Col span={12}>
            <FormField
              name="phoneNumber"
              component={FormInputPhoneNumber}
              label={t('open_an_account.form_fields.phone')}
            />
          </Col>
          <Col span={12}>
            <FormField
              name="password"
              component={SetupPasswordField}
              label={t('open_an_account.form_fields.password')}
            />
          </Col>
          <Col span={12}>
            <FormField
              name="passwordConfirm"
              component={FormInputPassword}
              label={t('open_an_account.form_fields.confirm_password')}
            />
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <StyledButton size="large" type="bordered" onClick={onBack}>
              {t('back', { ns: 'common' })}
            </StyledButton>
          </Col>
          <Col>
            <SubmitButton type="primary">
              {t('save', { ns: 'common' })}
            </SubmitButton>
          </Col>
        </Row>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  background-color: ${({ theme }) => theme.colorDarkL2};
  border-radius: ${({ theme }) => theme.borderRadiusRegular};
`;

const StyledButton = styled(Button)`
  margin-right: ${({ theme }) => theme.paddingMd};
`;

const DescriptionEntityWrapper = styled(Text)`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const TitleWrapper = styled.div`
  backdrop-filter: blur(3px);
`;

export default UserInfoStep;
