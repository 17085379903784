import React, { memo, useState } from 'react';

// helpers ;
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { MessageModel } from '../../../../../../typings/messaging/messages';
import { MessagesHelpers } from '../../../../../../helpers/messages';

// components
import { Comment } from 'antd';
import MessageBody from './MessageBody';
import MessageAuthor from './MessageAuthor';
import MessageTimeBar from './MessageTimeBar';

interface IProps {
  message: MessageModel;
  currentUserId: string;
  isHighlighted?: boolean;
}

const ThreadMessage = memo(
  ({ message, isHighlighted, currentUserId }: IProps) => {
    const { t } = useTranslation('profile');
    const [isActive, setIsActive] = useState(isHighlighted);
    const isUnread = MessagesHelpers.isMessageNew(message, currentUserId);

    return (
      <StyledComment
        isHighlighted={isActive || isUnread}
        actions={[
          <span
            key="show_hide_actions"
            onClick={() => setIsActive((prev) => !prev)}
          >
            {isActive
              ? t('messages.view_message.hide_conversation')
              : t('messages.view_message.show_conversation')}
          </span>,
        ]}
        author={
          <MessageAuthor
            message={message}
            isActive={isActive}
            isUnread={isUnread}
          />
        }
        content={<MessageBody message={message} isActive={isActive} />}
        datetime={<MessageTimeBar message={message} />}
      />
    );
  },
);

const StyledComment = styled(Comment)<{ isHighlighted?: boolean }>`
  margin: 0px;
  padding: ${({ theme }) => `0px ${theme.paddingSm}`};
  border: none;
  border-bottom: ${({ theme }) => `1px solid ${theme.colorDarkL1}`};
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colorDarkL2 : theme.colorDarkD1};
  transition: ${({ theme }) => theme.defaultTransition};

  .ant-comment-inner {
    padding: ${({ theme }) => `${theme.paddingSm} 0px`};
  }

  .ant-comment-nested {
    padding-bottom: ${({ theme }) => theme.paddingSm};
  }

  .ant-comment-content-author {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ant-comment-content-author-name {
      color: ${({ theme }) => theme.colorWhite};
    }

    .ant-comment-content-author-time {
      padding-right: 0px;
    }
  }

  .ant-comment-content-detail {
    font-size: ${({ theme }) => theme.fontSizeXs};
  }
`;

export default ThreadMessage;
