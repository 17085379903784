import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { IEntity } from '../../../../../../../../../typings/application/entity';
import { onboardingAPI } from '../../../../../../../../../api/onboarding/onboardingAPI';
import { onboardingAPIAdapter } from '../../../../../../../../../apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from '../../../../../../../../../typings/onboarding/onboarding';

// components
import IdentificationForOrganizationForm, {
  AddressItemModel,
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/IdentificationForOrganizationForm';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  isViewOnly: boolean;
  onSave: () => void;
}

const Identification = ({ onboardingStatus, isViewOnly, onSave }: IProps) => {
  const { t } = useTranslation('common');

  const initialFormValues = useMemo<FormValuesModel>(() => {
    let addresses: AddressItemModel[] = [
      {
        isPrimary: true,
        type: null,
        country: null,
        city: '',
        street: '',
        state: '',
        postalCode: '',
      },
    ];

    const organizationData = onboardingStatus.item as IEntity;

    if (organizationData.addresses?.length) {
      addresses = organizationData.addresses.map((address) => ({
        _id: address._id,
        isPrimary: !!address.isPrimary,
        type: address.type || null,
        country: address.country || null,
        city: address.city,
        street: address.street,
        state: address.state,
        postalCode: address.postalCode,
      }));
    }

    return {
      submitActionType: null,
      isSave: false,
      isRegulated:
        typeof organizationData.isRegulated === 'boolean'
          ? organizationData.isRegulated
          : null,
      regulationCountry: organizationData.regulationCountry,
      canIssueBearerShares:
        typeof organizationData.canIssueBearerShares === 'boolean'
          ? organizationData.canIssueBearerShares
          : null,
      addresses: addresses,
    };
  }, [onboardingStatus]);

  const onSubmit = async (values: FormValuesModel) => {
    const formattedBody = onboardingAPIAdapter.submitIdentificationStep(values);
    await onboardingAPI.submitIdentificationStep(
      formattedBody,
      onboardingStatus.applicationId,
      onboardingStatus.crmItemId,
    );
    message.success(t('success_save'));
    onSave();
  };

  return (
    <IdentificationForOrganizationForm
      disabled={isViewOnly}
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
      showOnlySaveButton
    />
  );
};

export default Identification;
