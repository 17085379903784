import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { accountsAPI } from 'api/accounting/accountsAPI';
import { IAccountModel } from 'typings/onboarding/account';
import { AccountsHelpers } from 'helpers/accounts';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { BankAccountStatuses } from 'enums/accounts/accounts';
import { AccountProcessingTypes } from 'enums/accounting/transfers';

// components
import Text from '@core_components/Text';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import FormAutocomplete from '@common_components/Form/FormAutocomplete';
import WarningWithPopover from 'components/Tooltips/TemplateTooltips/WarningIconWithPopover';

export interface BankAccountAutocompleteProps
  extends AutocompleteProps<IAccountModel> {
  filterAccountsByProcessingType?: AccountProcessingTypes;
}

const BankAccountAutocomplete = ({
  filterAccountsByProcessingType,
  ...rest
}: BankAccountAutocompleteProps) => {
  const { t } = useTranslation(['transfers', 'custom_errors', 'form']);

  const fetchOptionsCallback = async () => {
    const { data } = await accountsAPI.fetchAccounts({
      limit: 10000,
      page: 1,
      status: BankAccountStatuses.Active,
      processingType: filterAccountsByProcessingType,
    });

    return data.map((e) => {
      const {
        accountNumber,
        accountName,
        currencyCode,
        balance,
        hasTransactionRules,
        hasWorkflowPending,
        isFrozen,
      } = e;
      const accountBalance = `${currencyCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(balance)}`;
      const name = AccountsHelpers.generateAccountFullName(
        accountName,
        accountNumber,
        currencyCode,
        balance,
      );

      return {
        disabled: !hasTransactionRules || isFrozen || hasWorkflowPending,
        id: e.accountNumber,
        model: e,
        label: (
          <DivAlignCenter>
            <EllipsisTooltip
              placement="right"
              overlayStyle={{ maxWidth: '300px' }}
              title={
                <>
                  <Text variant="body1">{accountNumber}</Text>
                  <Text variant="body1">{accountName}</Text>
                  <Text variant="body1">{accountBalance}</Text>
                </>
              }
            >
              {name}
            </EllipsisTooltip>
            {renderIconWarning({
              isFrozen,
              hasTransactionRules,
              hasWorkflowPending,
            })}
          </DivAlignCenter>
        ),
      };
    });
  };

  const renderIconWarning = ({
    isFrozen,
    hasWorkflowPending,
    hasTransactionRules,
  }: {
    isFrozen: boolean;
    hasWorkflowPending: boolean;
    hasTransactionRules: boolean;
  }) => {
    if (isFrozen) {
      return (
        <WarningWithPopover
          popoverContent={
            <Text variant="body1">
              {t('accounts.frozen_account_warning', { ns: 'custom_errors' })}
            </Text>
          }
        />
      );
    } else {
      if (hasWorkflowPending) {
        return (
          <WarningWithPopover
            popoverContent={
              <StyledWarningText variant="body1">
                {t('accounts.has_pending_workflow_warning', {
                  ns: 'custom_errors',
                })}
              </StyledWarningText>
            }
          />
        );
      } else if (!hasTransactionRules) {
        return (
          <WarningWithPopover
            popoverContent={
              <StyledWarningText variant="body1">
                {t('accounts.no_transaction_rule_warning', {
                  ns: 'custom_errors',
                })}
              </StyledWarningText>
            }
          />
        );
      }
    }
  };

  return <FormAutocomplete {...rest} fetchData={fetchOptionsCallback} />;
};

const StyledWarningText = styled(Text)`
  white-space: pre-line;
`;

export default BankAccountAutocomplete;
