import { PermissionedAccessModel } from './';

// Permission keys
export type AccountManagementPermissionKeys =
  | 'AccountManagement_View'
  | 'AccountManagement_TransactionRules_View'
  | 'AccountManagement_TransactionRules_ApprovalGroups_View'
  | 'AccountManagement_TransactionRules_ApprovalGroups_Create'
  | 'AccountManagement_TransactionRules_ApprovalGroups_Edit'
  | 'AccountManagement_TransactionRules_ApprovalRules_View'
  | 'AccountManagement_TransactionRules_ApprovalRules_Create'
  | 'AccountManagement_TransactionRules_ApprovalRules_Edit'
  | 'AccountManagement_TransactionRules_Workflow_ApproveReject'
  | 'AccountManagement_Rules_View'
  | 'AccountManagement_Rules_ApprovalGroups_View'
  | 'AccountManagement_Rules_ApprovalGroups_Create'
  | 'AccountManagement_Rules_ApprovalGroups_Edit'
  | 'AccountManagement_Rules_ApprovalRules_View'
  | 'AccountManagement_Rules_ApprovalRules_Create'
  | 'AccountManagement_Rules_ApprovalRules_Edit'
  | 'AccountManagement_Rules_Workflow_View'
  | 'AccountManagement_Rules_Workflow_ApproveReject'
  | 'AccountManagement_ClientProfile_View'
  | 'AccountManagement_ClientProfile_ClientDetails_View'
  | 'AccountManagement_UserManagement_View'
  | 'AccountManagement_UserManagement_EnableUser'
  | 'AccountManagement_UserManagement_Create'
  | 'AccountManagement_UserManagement_UpdateUser'
  | 'AccountManagement_LimitConfiguration_View'
  | 'AccountManagement_LimitConfiguration_Overdraft_View'
  | 'AccountManagement_ExternalServices_View'
  | 'AccountManagement_LimitConfiguration_FeeSchedule_View';

// Permission config
export const AccountManagementPermissions: Record<
  AccountManagementPermissionKeys,
  PermissionedAccessModel
> = {
  AccountManagement_View: {
    requiredAllPermissions: false,
    permissions: [
      // Transaction template page
      { permissionKey: 'Approval:TransactionTemplate:Read' },
      { permissionKey: 'Approval:TransactionTemplate:Create' },
      { permissionKey: 'Approval:TransactionGroup:Read' },
      { permissionKey: 'Approval:TransactionGroup:Create' },

      // Approval management template page
      { permissionKey: 'Approval:AccountManagementWorkflow:Read' },
      { permissionKey: 'Approval:AccountManagementTemplate:Read' },
      { permissionKey: 'Approval:AccountManagementTemplate:Create' },
      { permissionKey: 'Approval:AccountManagementGroup:Read' },
      { permissionKey: 'Approval:AccountManagementGroup:Create' },

      // User management page
      { permissionKey: 'CRM:ClientGroupUser:Read' },
      { permissionKey: 'CRM:ClientGroupUser:Create' },

      // Administration rules
      { permissionKey: 'Approval:AccountManagementGroup:Read' },
      { permissionKey: 'Approval:AccountManagementGroup:Create' },
      { permissionKey: 'Approval:AccountManagementTemplate:Read' },
      { permissionKey: 'Approval:AccountManagementTemplate:Create' },
      { permissionKey: 'Approval:AccountManagementWorkflow:Read' },
    ],
  },

  AccountManagement_TransactionRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:TransactionGroup:Read' },
      { permissionKey: 'Approval:TransactionGroup:Create' },
      { permissionKey: 'Approval:TransactionTemplate:Read' },
      { permissionKey: 'Approval:TransactionTemplate:Create' },
    ],
  },

  AccountManagement_TransactionRules_ApprovalGroups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:TransactionGroup:Read' },
      { permissionKey: 'Approval:TransactionGroup:Create' },
    ],
  },

  AccountManagement_TransactionRules_ApprovalGroups_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionGroup:Create' }],
  },

  AccountManagement_TransactionRules_ApprovalGroups_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionGroup:Update' }],
  },

  AccountManagement_TransactionRules_ApprovalRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:TransactionTemplate:Read' },
      { permissionKey: 'Approval:TransactionTemplate:Create' },
    ],
  },

  AccountManagement_TransactionRules_ApprovalRules_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionTemplate:Create' }],
  },

  AccountManagement_TransactionRules_ApprovalRules_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:TransactionTemplate:Update' }],
  },

  AccountManagement_TransactionRules_Workflow_ApproveReject: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Approval:TransactionWorkflow:ApproveReject' },
    ],
  },

  AccountManagement_Rules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:AccountManagementGroup:Read' },
      { permissionKey: 'Approval:AccountManagementGroup:Create' },
      { permissionKey: 'Approval:AccountManagementTemplate:Read' },
      { permissionKey: 'Approval:AccountManagementTemplate:Create' },
      { permissionKey: 'Approval:AccountManagementWorkflow:Read' },
    ],
  },

  AccountManagement_Rules_ApprovalGroups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:AccountManagementGroup:Read' },
      { permissionKey: 'Approval:AccountManagementGroup:Create' },
    ],
  },

  AccountManagement_Rules_ApprovalGroups_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementGroup:Create' }],
  },

  AccountManagement_Rules_ApprovalGroups_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementGroup:Update' }],
  },

  AccountManagement_Rules_ApprovalRules_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementTemplate:Read' }],
  },

  AccountManagement_Rules_ApprovalRules_Create: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Approval:AccountManagementTemplate:Create' },
    ],
  },

  AccountManagement_Rules_ApprovalRules_Edit: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Approval:AccountManagementTemplate:Update' },
    ],
  },

  AccountManagement_Rules_Workflow_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Approval:AccountManagementWorkflow:Read' }],
  },

  AccountManagement_Rules_Workflow_ApproveReject: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Approval:AccountManagementWorkflow:ApproveReject' },
    ],
  },

  AccountManagement_ClientProfile_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'CRM:Relationship:Read' },
      { permissionKey: 'CRM:RelationshipStructure:Read' },
    ],
  },

  AccountManagement_ClientProfile_ClientDetails_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'CRM:Relationship:Read' },
      { permissionKey: 'CRM:RelationshipStructure:Read' },
    ],
  },

  AccountManagement_UserManagement_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'CRM:ClientGroupUser:Read' },
      { permissionKey: 'CRM:ClientGroupUser:Create' },
    ],
  },

  AccountManagement_UserManagement_EnableUser: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ClientGroupUser:EnableUser' }],
  },

  AccountManagement_UserManagement_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ClientGroupUser:Create' }],
  },

  AccountManagement_UserManagement_UpdateUser: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ClientGroupUser:Update' }],
  },

  AccountManagement_LimitConfiguration_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:FeeSchedule:Read' },
      { permissionKey: 'Accounting:Account:Read' },
    ],
  },

  AccountManagement_LimitConfiguration_FeeSchedule_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:FeeSchedule:Read' }],
  },

  AccountManagement_LimitConfiguration_Overdraft_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Account:Read' }],
  },

  AccountManagement_ExternalServices_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:Circle:Connect' },
      { permissionKey: 'Accounting:Circle:Disconnect' },
    ],
  },
};
