export enum TransactionStatuses {
  Completed = 'completed',
  Pending = 'pending',
  Rejected = 'rejected',
  Processing = 'processing',
  Cancelled = 'cancelled',
  Scheduled = 'scheduled',
  Expired = 'expired',
}

export enum BankAccountStatuses {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  PendingApproval = 'PENDING_APPROVAL',
  Processing = 'PROCESSING',
  BankRejected = 'BANK_REJECTED',
}
