import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ConditionModel } from 'components/Forms/TemplateForms/Approval/TransactionRulesMatrixForm';
import {
  IApprovalGroupShort,
  IGroupParticipantShort,
} from 'typings/approvalManagement/accountManagement';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import { Col, Popover, Row } from 'antd';

interface IProps {
  record: ConditionModel;
  dataIndex: string;
  colIndex: number;
  isLastRow: boolean;
}

const ContentCell = ({ record, dataIndex, colIndex, isLastRow }: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);

  const renderGroupName = (
    approvalGroup: IApprovalGroupShort | null = null,
  ) => {
    if (!approvalGroup) {
      return '';
    }

    return (
      <Popover
        placement="bottom"
        content={renderPopoverContent(approvalGroup.participants)}
      >
        <StyledDottedText variant="body1">
          {approvalGroup.name}
        </StyledDottedText>
      </Popover>
    );
  };

  const renderPopoverContent = (
    participants: IGroupParticipantShort[] = [],
  ) => {
    const isMore = participants.length > 5;

    return (
      <>
        {participants.slice(0, 5).map(({ userFullName }, i) => (
          <div key={i}>{userFullName}</div>
        ))}

        {isMore && (
          <Text variant="body1">
            {t('approval_rules.bank_accounts.table.more_users', {
              number: participants.length - 5,
            })}
          </Text>
        )}
      </>
    );
  };
  const renderContent = () => {
    switch (dataIndex) {
      case 'condition':
        return (
          <>
            {record.conditions.map((e, i: number) => {
              const shouldRenderAnd = i < record.conditions.length - 1;
              const shouldRenderOr =
                !isLastRow && i === record.conditions.length - 1;
              return (
                <>
                  <Row key={i}>
                    <Col>
                      {`${t(
                        'approval_rules.bank_accounts.table.approval_group_name',
                      )}: `}
                      {renderGroupName(e.approvalGroupFull)}
                    </Col>

                    <Col span={24}>
                      {`${t(
                        'approval_rules.bank_accounts.table.required_number_of_approvers',
                      )}: `}
                      {!e.requiredApproversCount ? (
                        <Text variant="span" color={colorsTheme.colorError}>
                          {t('not_selected', { ns: 'common' })}
                        </Text>
                      ) : (
                        e.requiredApproversCount
                      )}
                    </Col>
                  </Row>

                  {shouldRenderAnd && (
                    <StyledANDText variant="body1" weight="semi-bold">
                      {t('approval_rules.bank_accounts.table.and')}
                    </StyledANDText>
                  )}

                  {shouldRenderOr && (
                    <ORTextWrapper>
                      <Text variant="body1" weight="semi-bold">
                        {t('approval_rules.bank_accounts.table.or')}
                      </Text>
                    </ORTextWrapper>
                  )}
                </>
              );
            })}
          </>
        );

      case 'limit': {
        const checkboxValue = record.limitStatuses[colIndex].isChecked;
        return (
          <AlignCenterWrapper>
            <Checkbox value={checkboxValue} disabled />
          </AlignCenterWrapper>
        );
      }

      default:
        return null;
    }
  };

  return <StyledColWrapper>{renderContent()}</StyledColWrapper>;
};

const StyledColWrapper = styled.td`
  padding: ${({ theme }) => theme.paddingXl} !important;
`;

const ORTextWrapper = styled.div`
  position: absolute;
  bottom: -13px;
  width: 50px;
  text-align: center;
  background: ${({ theme }) => theme.colorDarkL2};
`;

const StyledANDText = styled(Text)`
  padding: 8px 16px;
`;

const AlignCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDottedText = styled(Text)`
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px dotted ${theme.colorPrimary}`};
`;

export default ContentCell;
