import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from '../../../../../../../enums/onboarding/crm';
import {
  ExistingEntryModel,
  ExistingOwnershipModel,
  FormValuesModel,
} from '../..';

// components
import FormField from '@core_components/FormField';
import ExistingRelationshipSelect, {
  IProps as ExistingRelationshipSelectProps,
} from '../../../../../FormComponents/SelectInputs/CRM/ExistingRelationshipSelect';

const FromExistingEntry = () => {
  const { t } = useTranslation('account_management');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const handleExistingEntrySelect = (option?: ExistingOwnershipModel) => {
    if (option) {
      const newValue: ExistingEntryModel = {
        id: option.id,
        type: option.type,
        keyRelationshipTemplate: option.keyRelationshipTemplate,
        contact:
          option.type === OnboardingEntryTypes.Contact
            ? {
                firstName: option.contact?.firstName as string,
                lastName: option.contact?.lastName as string,
                middleName: option.contact?.middleName as string,
              }
            : null,

        organization:
          option.type === OnboardingEntryTypes.Organization
            ? {
                name: option.organization?.name as string,
              }
            : null,
      };

      setFieldValue('existingEntry', newValue);
    }
  };

  return (
    <>
      <FormField<ExistingRelationshipSelectProps>
        name="existingEntry.id"
        label={t('client_profile.ownership_modal.new.existing_entry')}
        component={ExistingRelationshipSelect}
        additionalProps={{
          entries: values.existingEntries,
          onSelect: (_, option) => handleExistingEntrySelect(option.model),
        }}
      />
    </>
  );
};

export default FromExistingEntry;
