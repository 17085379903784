import React from 'react';

// helpers
import { UserPermissionsValidationSchema } from '../../../../../validations/onboarding/personal-information';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
} from '../../../../../enums/onboarding/crm';

// components
import InnerForm from './InnerForm/InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

export interface FormValuesModel {
  accountPermissions: AccountUserPermissionTypes | null;
  adminPermissionType: AdministrationPermissionTypes | null;
  showLimitedPermission: boolean;
}

const RelationshipPermissionModalDialog = ({
  title,
  isVisible,
  onSubmit,
  closeCallback,
  initialValues,
  disabled,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      enableReinitialize
      disabled={disabled}
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={UserPermissionsValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          width={650}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default RelationshipPermissionModalDialog;
