import React, { createContext, useCallback, useState } from 'react';

// helpers
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { AnyObject, Maybe, ObjectSchema } from 'yup';
import { Formik, FormikHelpers, FormikProps } from 'formik';

// components
import RenderForm from './RenderForm';

export interface RequiredPropsForFormModel<Values> {
  initialValues: Values | null;
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => void | Promise<unknown>;

  disabled?: boolean;
  onSubmitError?: (
    error: ErrorFromServer,
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => void;
}

interface FormProps<Values> extends RequiredPropsForFormModel<Values> {
  // TODO: need to use SchemaOf<Values>, this will add an extra typescript protection
  // ScheamOf generates a new type (yup schema type) depends on the Values model
  // validationSchema?: SchemaOf<Values>;
  validationSchema?: any | (() => any);

  innerRef?: React.Ref<FormikProps<Values>>;

  isDisabledFields?: boolean;

  renderForm:
    | ((form: FormikProps<Values>) => React.ReactNode)
    | React.ReactNode
    | null;

  enableReinitialize?: boolean;

  confirmExitWithoutSaving?: boolean;
  onSave?: () => Promise<void>;

  // for styled components
  style?: { [key: string]: string | number };
  className?: string;
}

// Define a custom context that includes the validation schema
export const FormContext = createContext<{
  validationSchema: ObjectSchema<Maybe<AnyObject>> | null;
  throwOnSubmitError: boolean;
  setThrowOnSubmitError: null | ((value: boolean) => void);
}>({
  validationSchema: null,
  throwOnSubmitError: false,
  setThrowOnSubmitError: null,
});

// Custom Form component (using Formik library)
// This is a general component for all forms in application

// Additional notes:
// - to disable all fields during submit process just set isSubmitting value to true
//   use setSubmitting method for this (form helpers object),
function Form<Values = unknown>({
  enableReinitialize = false,
  confirmExitWithoutSaving = true,
  initialValues,
  validationSchema,
  disabled,
  innerRef,
  onSubmit,
  onSave,
  renderForm,
  onSubmitError,
  ...rest
}: FormProps<Values>) {
  const [throwOnSubmitError, setThrowOnSubmitError] = useState(false);
  // Wrapped onSubmit to handle an error
  // Note: Formik do not throw an error when it happened in "onSubmit"
  const handleSubmit = useCallback(
    async (values: Values, formikHelpers: FormikHelpers<Values>) => {
      try {
        await onSubmit(values, formikHelpers);
        return { success: true };
      } catch (error: any) {
        if (onSubmitError) {
          onSubmitError(error as ErrorFromServer, values, formikHelpers);
        } else {
          ErrorHandlerService.handleError(error);
        }
        if (throwOnSubmitError) {
          throw error;
        }
      }
    },
    [throwOnSubmitError, onSubmit, onSubmitError],
  );

  if (!renderForm) {
    return null;
  }

  return (
    <FormContext.Provider
      value={{ validationSchema, throwOnSubmitError, setThrowOnSubmitError }}
    >
      <Formik
        innerRef={innerRef}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues || ({} as any)}
        validationSchema={validationSchema}
        enableReinitialize={enableReinitialize}
      >
        {(form: FormikProps<Values>) => (
          <RenderForm
            {...rest}
            form={form}
            onSave={onSave}
            renderForm={renderForm}
            disabled={disabled}
            confirmExitWithoutSaving={confirmExitWithoutSaving}
          />
        )}
      </Formik>
    </FormContext.Provider>
  );
}

export default Form;
