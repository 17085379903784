import React, { useEffect } from 'react';

// Helpers & Utils
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import useUserAccess from '../../../../../../../hooks/useUserAccess';
import { CHECK_COUNTRY } from '../../../../../../../constants/regularExpressions';
import { useFormikContext } from 'formik';
import {
  INewInternationalTransferRecipientFormValues,
  INewTransferTemplateFormValues,
} from '../../../../../../../typings/accounting/transfer';

// Components
import Checkbox from '../../../../../../DesignSystem/Core/Checkbox';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import CountrySelect from '../../../../../../Forms/FormComponents/SelectInputs/CRM/CountrySelect';
import TrueFalseSelect from '../../../../../../Forms/FormComponents/SelectInputs/TrueFalseSelect';
import BankDetailsForm from './BankDetailsForm';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import DistrictSelect, {
  IProps as DistrictSelectProps,
} from '../../../../../../Forms/FormComponents/SelectInputs/Transfers/DistrictSelect';
import { Divider } from 'antd';

interface IProps {
  isViewOnly?: boolean;
  isEditMode?: boolean;
}

const WireTransferTemplateForm = ({ isViewOnly, isEditMode }: IProps) => {
  const { t } = useTranslation('transfers');
  const form =
    useFormikContext<
      INewTransferTemplateFormValues<INewInternationalTransferRecipientFormValues>
    >();
  const gridSizes = { xl: 4, lg: 4, md: 5, sm: 24, xs: 24 };

  const [hasBlockchainWireTransferTemplateAccess] = useUserAccess([
    'Transfers_BlockchainWireTransferTemplate_Create',
  ]);

  useEffect(() => {
    if (!form.dirty) {
      return;
    }

    if (!form.values.transferFields.useIntermediary) {
      form.setFieldValue('transferFields', {
        ...form.values.transferFields,
        intermediaryBank: {
          bankCodeType: undefined,
          bankCode: undefined,
          bankName: undefined,
          country: undefined,
          address: undefined,
        },
      });
    }
  }, [form.values.transferFields.useIntermediary]);

  useEffect(() => {
    if (!CHECK_COUNTRY.test(form.values.transferFields.country))
      form.setFieldValue('transferFields.beneficiaryDistrict', '');
  }, [form.values.transferFields.country]);

  return (
    <>
      {hasBlockchainWireTransferTemplateAccess && (
        <FormField
          name="transferFields.isCircle"
          component={TrueFalseSelect}
          disabled={isViewOnly || isEditMode}
          label={t(
            'transfer_templates.transfer_template_modal.form_fields.circle_template',
          )}
          labelCol={gridSizes}
        />
      )}

      <Divider orientation="left">
        {t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary',
        )}
      </Divider>

      <FormField
        name="transferFields.accountName"
        component={FormInput}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.account_name',
        )}
        disabled={isViewOnly || isEditMode}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.accountNumber"
        component={FormInput}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.account_number',
        )}
        disabled={isViewOnly || isEditMode}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.country"
        component={CountrySelect}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.country',
        )}
        disabled={isViewOnly || isEditMode}
        labelCol={gridSizes}
      />

      <FormField
        name="transferFields.address"
        component={FormInput}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.address',
        )}
        disabled={isViewOnly || isEditMode}
        labelCol={gridSizes}
        additionalProps={{ pull: gridSizes }}
      />

      <FormField
        name="transferFields.beneficiaryCity"
        component={FormInput}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_city',
        )}
        disabled={isViewOnly || isEditMode}
        labelCol={gridSizes}
        // TODO: investigate how pull is working here, and if we can remove it
        additionalProps={{ pull: gridSizes }}
      />

      <FormField<DistrictSelectProps>
        name="transferFields.beneficiaryDistrict"
        component={DistrictSelect}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_district',
        )}
        disabled={
          isViewOnly ||
          !CHECK_COUNTRY.test(form.values.transferFields.country) ||
          isEditMode
        }
        labelCol={gridSizes}
        // TODO: investigate how pull is working here, and if we can remove it
        additionalProps={
          {
            pull: gridSizes,
            country: form.values.transferFields.country,
          } as any
        }
      />

      <FormField
        name="transferFields.beneficiaryPostCode"
        component={FormInput}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_postcode',
        )}
        disabled={isViewOnly || isEditMode}
        labelCol={gridSizes}
        additionalProps={{ pull: gridSizes }}
      />

      <Divider orientation="left">
        {t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.beneficiary_bank',
        )}
      </Divider>
      <BankDetailsForm
        isViewOnly={isViewOnly}
        isEditMode={isEditMode}
        fieldBaseName="transferFields.beneficiaryBank"
      />

      <FormField
        name="transferFields.useIntermediary"
        component={Checkbox}
        disabled={isViewOnly || isEditMode}
        labelCol={gridSizes}
        additionalProps={{
          children: t(
            'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.use_intermediary_bank',
          ),
        }}
      />

      {form.values.transferFields.useIntermediary && (
        <>
          <Divider orientation="left">
            {t(
              'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.intermediary_bank',
            )}
          </Divider>
          <BankDetailsForm
            isViewOnly={isViewOnly}
            isEditMode={isEditMode}
            fieldBaseName="transferFields.intermediaryBank"
          />
        </>
      )}

      <FormField
        name="transferFields.reference"
        component={StyledTextArea}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.international_transfer_template.reference',
        )}
        labelCol={gridSizes}
        disabled={isViewOnly}
      />
    </>
  );
};

const StyledTextArea = styled(FormInputTextArea)`
  width: 100%;
  height: 100px;
`;

export default WireTransferTemplateForm;
