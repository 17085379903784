import React, { useEffect, useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { accountsAPI } from '../../../../../api/accounting/accountsAPI';
import { IAccountModel } from '../../../../../typings/onboarding/account';
import { useFormikContext } from 'formik';
import { AccountProcessingTypes } from '../../../../../enums/accounting/transfers';
import { NewInternalTransferModel } from '../../../../../typings/accounting/transfer';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import FormInputAmount from '@common_components/Form/FormInputAmount';
import FormErrorTooltip from '../../../FormErrorTooltip';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import AvailableBalanceText from './AvailableBalanceText';
import AllowedCurrenciesSelect, {
  AllowedCurrency,
} from './AllowedCurrenciesSelect';
import BankAccountAutocomplete, {
  BankAccountAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/Accounting/BankAccountAutocomplete';

interface IProps {
  transferType: 'internal' | 'wire' | 'blockchain';
  recipientCurrency?: { id: number; code: string };
  filterAccountsByProcessingType?: AccountProcessingTypes;
}

const TransferFromSideForm = ({
  transferType,
  recipientCurrency,
  filterAccountsByProcessingType,
}: IProps) => {
  const { t } = useTranslation(['transfers', 'form']);
  const { values, setFieldValue, initialValues } =
    useFormikContext<NewInternalTransferModel>();

  const isDisabledFields = !values.sender.accountNumber;

  // Set & Update processing type for account selected in from side
  useEffect(() => {
    async function updateProcessingTypeForFromAccount(
      accountNumber: string | null,
    ) {
      if (!accountNumber) {
        // Clean up value if from account was removed
        if (values.sender.processingType) {
          setFieldValue('sender.processingType', null);
        }
      } else {
        const { processingType } = await accountsAPI.getAccountProcessingType(
          accountNumber as string,
        );
        setFieldValue('sender.processingType', processingType);
      }
    }

    updateProcessingTypeForFromAccount(values.sender.accountNumber);
  }, [values.sender.accountNumber]);

  const allowedCurrenciesForTransfer = useMemo<AllowedCurrency[]>(() => {
    const result: { id: number; name: string }[] = [];
    const { sender } = values;

    if (sender.account) {
      result.push({
        id: sender.account.currencyId,
        name: sender.account.currencyCode,
      });
    }

    if (
      recipientCurrency &&
      recipientCurrency.id !== sender.account?.currencyId
    ) {
      result.push({
        id: recipientCurrency.id,
        name: recipientCurrency.code,
      });
    }

    return result;
  }, [values.sender.account, recipientCurrency]);

  const onAccountSelect = (account: IAccountModel | null) => {
    setFieldValue('sender.account', account);
  };

  return (
    <>
      <FormField<BankAccountAutocompleteProps>
        name="sender.accountNumber"
        label={t('internal_transfer.form_fields.from')}
        component={BankAccountAutocomplete}
        additionalProps={{
          filterAccountsByProcessingType,
          onSelect: (_, option) =>
            option.model && onAccountSelect(option.model),
          initialValue: values.sender.accountNumberInitialValue,
          onClear: () => onAccountSelect(null),
          refreshAutocompleteTrigger: initialValues,
        }}
      />

      <FormErrorTooltip errorKey="sender" />

      {values.sender.account && (
        <AvailableBalanceText gutterBottom account={values.sender.account} />
      )}

      <FormField
        disabled={isDisabledFields}
        name="transactionPurpose"
        label={
          <DivAlignCenter>
            <StyledText>
              {transferType === 'wire'
                ? t('international_transfer.form_fields.purpose')
                : t('internal_transfer.form_fields.purpose')}
            </StyledText>
            {transferType === 'wire' && (
              <InfoTooltip
                tooltipContent={t('international_transfer.tooltips.purpose')}
              />
            )}
          </DivAlignCenter>
        }
        component={FormInputTextArea}
      />

      <FormField
        disabled={isDisabledFields}
        name="amount"
        label={t('internal_transfer.form_fields.amount')}
        component={StyledFormAmountField}
        additionalProps={{ min: 0 }}
      />

      <FormField
        disabled={isDisabledFields}
        name="transactionCurrencyId"
        label={t('internal_transfer.form_fields.currency')}
        component={StyledAllowedCurrenciesSelect}
        placeholder={t('select_option', { ns: 'form' })}
        additionalProps={{ currencies: allowedCurrenciesForTransfer }}
      />

      <FormField
        disabled={isDisabledFields}
        name="valueDate"
        label={t('internal_transfer.form_fields.value_date')}
        component={StyledDatePicker}
        placeholder={t('select_date', { ns: 'form' })}
        additionalProps={{
          disabledDate: (current: any) =>
            current && current < moment().startOf('day'),
        }}
      />

      <FormField
        disabled={isDisabledFields}
        name="sender.reference"
        label={
          <DivAlignCenter>
            <StyledText>
              {transferType === 'wire'
                ? t('international_transfer.form_fields.from_account_reference')
                : t('internal_transfer.form_fields.from_account_reference')}
            </StyledText>
            {transferType === 'wire' && (
              <InfoTooltip
                tooltipContent={t(
                  'international_transfer.tooltips.from_account_reference',
                )}
              />
            )}
          </DivAlignCenter>
        }
        component={FormInput}
      />

      <FormField
        disabled={isDisabledFields}
        name="documents"
        label={t('internal_transfer.form_fields.documents')}
        component={DocumentsField}
        additionalProps={{ isMultiple: true }}
      />
    </>
  );
};

const StyledFormAmountField = styled(FormInputAmount)`
  width: 100%;
`;

const StyledAllowedCurrenciesSelect = styled(AllowedCurrenciesSelect)`
  width: 100%;
`;

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledText = styled(Text)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default TransferFromSideForm;
