import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from '../../../../../resources/theme/styled/colors';

// components
import IconSVG from '../../../Core/IconSVG';
import Button, { ButtonProps } from '../../../Core/Button';
import { ReactComponent as PlusIcon } from '../../../../../resources/icons/remix-icons/add-line.svg';

interface IProps extends ButtonProps {
  children: React.ReactNode;
}

const AddButton = memo(({ children, ...rest }: IProps) => {
  return (
    <Button type="bordered" {...rest}>
      <StyledIconSVG component={PlusIcon} color={colorsTheme.colorWhite} />
      {children}
    </Button>
  );
});

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default AddButton;
