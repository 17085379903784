import React, { useMemo, useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useTranslation from 'hooks/useTranslation';
import { message } from 'antd';
import {
  onboardingAPI,
  CreateUpdateOnboardingApprovalRule,
} from 'api/onboarding/onboardingAPI';

// components
import AddButton from '@common_components/Buttons/AddButton';
import OnboardingTransactionRulesModal, {
  FormValuesModel,
} from 'components/ModalDialogs/TemplateModalDialogs/Onboarding/OnboardingTransactionRulesModal';

interface IProps {
  onAction: () => void;
  applicationId: string;
}

const CreateApprovalRule = ({ applicationId, onAction }: IProps) => {
  const { t } = useTranslation('onboarding');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { response } = useFetch(
    () =>
      isModalVisible
        ? onboardingAPI.fetchApprovalGroups(
            {
              page: 1,
              limit: 100,
            },
            'onboarding-transaction',
          )
        : null,
    [isModalVisible],
  );

  const initialValues = useMemo((): FormValuesModel | null => {
    if (!isModalVisible || !response) {
      return null;
    }

    return {
      applicationId,
      bankAccounts: [],
      limits: [{ from: 0, to: undefined, toInfinite: true, isUsed: false }],
      conditions: [
        {
          conditions: [{ approvalGroup: '', requiredApproversCount: 0 }],
          limitStatuses: [{ isChecked: false }],
        },
      ],
      approvalGroups: response.data.map((e) => ({
        id: e._id,
        name: e.name,
        description: e.description,
        participants: e.participants.map((p) => ({
          userId: p.contactId,
          userFullName: p.name,
        })),
        isActive: true,
      })),
    };
  }, [isModalVisible, applicationId, response]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedData: CreateUpdateOnboardingApprovalRule = {
      type: 'onboarding-transaction',
      associatedAccountIds: values.bankAccounts,
      ruleSets: values.conditions.map((e) => ({
        items: e.conditions.map((c) => ({
          groupId: c.approvalGroup,
          minimumThreshold: c.requiredApproversCount,
        })),
      })),
      criteriaList: values.limits.map((e, limitIndex) => {
        const activeSetIndices = values.conditions.reduce<number[]>(
          (acc, next, conditionIndex) => {
            if (next.limitStatuses[limitIndex].isChecked) {
              acc.push(conditionIndex);
            }
            return acc;
          },
          [],
        );

        return {
          type: 'range',
          range: {
            min: e.from,
            max: e.toInfinite ? null : e.to || 0,
          },
          activeSetIndices,
        };
      }),
    };

    await onboardingAPI.createApprovalRule(formattedData);
    message.success(
      t('administration.transaction_rules.approval_rules.new.success_message'),
    );
  };

  const handleCloseCallback = (wasSubmitted?: boolean) => {
    setIsModalVisible(false);
    if (wasSubmitted) {
      onAction();
    }
  };

  return (
    <>
      <AddButton onClick={() => setIsModalVisible(true)}>
        {t('administration.transaction_rules.approval_rules.create_btn')}
      </AddButton>
      <OnboardingTransactionRulesModal
        title={t('administration.transaction_rules.approval_rules.new.title')}
        isVisible={isModalVisible}
        onSubmit={handleSubmit}
        closeCallback={handleCloseCallback}
        initialValues={initialValues}
      />
    </>
  );
};

export default CreateApprovalRule;
