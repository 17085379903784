import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FieldValidationRule } from '@core_components/FormField';

// components
import InputAmount, { InputAmountProps } from '@core_components/InputAmount';

interface FormInputAmountProps extends InputAmountProps {
  validationRules?: FieldValidationRule[];
}

const FormInputAmount = memo(
  ({ validationRules, ...rest }: FormInputAmountProps) => {
    const { t } = useTranslation('form');

    const placeholder = useMemo(() => {
      if (!validationRules || !validationRules.length) {
        return t('field_placeholders.input_optional');
      }

      const isRequired = validationRules.find((e) => e.type === 'required');

      if (isRequired) {
        return t('field_placeholders.input_required');
      } else {
        return t('field_placeholders.input_optional');
      }
    }, [validationRules]);

    return <InputAmount {...rest} placeholder={placeholder} />;
  },
);

export default FormInputAmount;
