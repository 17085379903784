import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { message } from 'antd';
import { getBadges } from 'redux/actions/app';
import { useDispatch } from 'react-redux';
import { accountManagementRulesAPI } from '../../../../api/accountManagement/accountManagementRulesAPI';

// components
import ApprovalGroupModal, {
  ApprovalGroupFormValuesModel,
} from '../../../../components/ModalDialogs/TemplateModalDialogs/Approval/ApprovalGroupModal';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasCreated?: boolean) => void;
}

const AddApprovalGroupModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('account_management');
  const dispatch = useDispatch();

  const initialFormValues: ApprovalGroupFormValuesModel = useMemo(() => {
    return { name: '', description: '', participants: [] };
  }, []);

  const handleSubmit = async (values: ApprovalGroupFormValuesModel) => {
    await accountManagementRulesAPI.createNewApprovalGroup({
      name: values.name,
      description: values.description as string,
      participants: values.participants,
    });
    dispatch(getBadges());

    message.success(t('success_submit_for_account_management_review_message'));
  };

  return (
    <ApprovalGroupModal
      approvalWorkflowType="account-management-transactions"
      title={t('approval_groups.approval_group_modal.new.title')}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default AddApprovalGroupModal;
