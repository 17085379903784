import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { transactionRulesAPI } from '../../../../../api/accounting/transactionRulesAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import InnerTable from './InnerTable';

interface IProps {
  updateTableTrigger?: any;
  onActionsClick: (key: string, ruleId: string) => void;
  hasEditAccess: boolean;
}

const TransactionRulesTable = ({
  updateTableTrigger,
  onActionsClick,
  hasEditAccess,
}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );

  const { response, loading } = useFetch(
    () =>
      transactionRulesAPI.fetchTransactionRules({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [updateTableTrigger, currentPage],
  );

  return (
    <InnerTable
      disabledActions={!isTwoFactorVerificationEnabled}
      data={response?.data || []}
      total={response?.total || 0}
      loading={loading}
      current={currentPage}
      onPaginationChange={setCurrentPage}
      onActionsClick={onActionsClick}
      hasEditAccess={hasEditAccess}
    />
  );
};

export default TransactionRulesTable;
