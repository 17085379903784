// 1 minutes
export const DEFAULT_TIMEOUT = 60 * 1000;
export const ONE_MINUTE = 60;
export const MAX_ADDRESSES_COUNT = 5;
export const MAX_ACCOUNTS_COUNT = 5;
export const MAX_ADDITIONAL_DOCUMENTS_COUNT = 5;
export const MAX_EMAILS_COUNT = 5;
export const MAX_PHONE_NUMBERS_COUNT = 5;
export const MAX_PASSPORTS_COUNT = 5;

// onboarding
export const RELATIONSHIP_TEMPLATE_ID = '5f11ed970febb3003f164ccf';
export const ADDITIONAL_FIELD_ROLE = 'Role';
