import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { NewThreadBodyModel } from '../../apiAdapters/messaging/clientMessagingAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  ThreadCategoryModel,
  MessagesStatusModel,
  ThreadWithLastMessageModel,
  MessageModel,
  ThreadModel,
} from '../../typings/messaging/messages';

export interface NewMessageBodyModel {
  body: string;
  attachments?: MessageAttachmentModelBody[];
}

export interface MessageAttachmentModelBody {
  documentId: string;
  contentType: string;
  fileId: string;
  name: string;
}

const clientMessagingAPI = {
  fetchMessagesStatus: () => {
    return APIService.get<MessagesStatusModel>(
      `${APIConfig.messagingApi}/threads/messages/count-new`,
    ).then(({ data }) => data);
  },

  fetchThreadCategories: () => {
    return APIService.get<ThreadCategoryModel[]>(
      `${APIConfig.messagingApi}/thread-categories`,
    ).then(({ data }) => data);
  },

  fetchInboxMessages: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ThreadWithLastMessageModel>>(
      `${APIConfig.messagingApi}/threads/inbox`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchSentMessages: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ThreadWithLastMessageModel>>(
      `${APIConfig.messagingApi}/threads/sent`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  createThread: (body: NewThreadBodyModel) => {
    return APIService.post<MessageModel>(
      `${APIConfig.messagingApi}/threads`,
      body,
    ).then(({ data }) => data);
  },

  readThread: (threadId: string) => {
    return APIService.patch(
      `${APIConfig.messagingApi}/threads/${threadId}/messages/set-read`,
    ).then(({ data }) => data);
  },

  fetchThreadById: (threadId: string) => {
    return APIService.get<ThreadModel>(
      `${APIConfig.messagingApi}/threads/${threadId}`,
    ).then(({ data }) => data);
  },

  fetchMessagesByThreadId: (threadId: string, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<MessageModel>>(
      `${APIConfig.messagingApi}/threads/${threadId}/messages`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  replyInThread: (threadId: string, body: NewMessageBodyModel) => {
    return APIService.post<{ message: MessageModel }>(
      `${APIConfig.messagingApi}/threads/${threadId}/messages`,
      {
        ...body,
        threadId,
      },
    ).then(({ data }) => data);
  },
};

export { clientMessagingAPI };
