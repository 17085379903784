import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { GraphNode } from '../../../../../../../Charts/GraphChart';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { colorsTheme } from '../../../../../../../../resources/theme/styled/colors';
import { IApplication } from '../../../../../../../../typings/application/applications';
import { onboardingAPI } from '../../../../../../../../api/onboarding/onboardingAPI';
import { OnboardingStatusModel } from '../../../../../../../../typings/onboarding/onboarding';

// components
import Tabs from '../../../../../../../Tabs/Tabs';
import IconSVG from '../../../../../../../DesignSystem/Core/IconSVG';
import ManageKYC from 'components/Additional/CRM/ManageKYC';
import LoadingWrapper from '../../../../../../../WrapperComponents/LoadingWrapper';
import Identification from './Tabs/Identification';
import GeneralInformation from './Tabs/GeneralInformation';
import CurrentOrganization from './CurrentOrganization';
import ApplicationDocumentation from './Tabs/ApplicationDocumentation';
import { ReactComponent as InfoIcon } from '../../../../../../../../resources/icons/remix-icons/information-line.svg';
import { ReactComponent as CheckIcon } from '../../../../../../../../resources/icons/remix-icons/check-line.svg';

interface IProps {
  graphNode: GraphNode;
  application: IApplication;
  isCurrentOrganization: boolean;
  updateNodeStatuses: () => void;
  updateStructureCallback: () => void;
  canSubmitForReview: boolean;
  allInformationFilled: boolean;
  isViewOnly?: boolean;
  canSendMessage?: boolean;
  disabledRegulated?: boolean;
  onSubmitForReview?: () => void;
  onAction?: (actionType: ActionKeys | null) => void;
}

type TabKeysForCurrentOrganization = 'applicationDocumentation';
type TabKeysForOrganization =
  | 'generalInformation'
  | 'identification'
  | 'applicationDocumentation';
type TabKeys = TabKeysForCurrentOrganization | TabKeysForOrganization;

const Organization = ({
  graphNode,
  application,
  isCurrentOrganization,
  canSubmitForReview,
  canSendMessage,
  isViewOnly,
  allInformationFilled,
  updateNodeStatuses,
  onSubmitForReview,
  onAction,
  updateStructureCallback,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [currentStep, setCurrentStep] = useState<TabKeys>('generalInformation');
  const [stepStatuses, setStepStatuses] = useState<Record<
    TabKeys,
    boolean
  > | null>(null);

  const { response: onboardingStatusResponse, loading: onboardingLoading } =
    useFetch(
      () =>
        onboardingAPI.fetchStatusForOnboardingItem(
          graphNode.id,
          application._id,
        ),
      [graphNode, application],
    );

  useEffect(() => {
    // init step statuses
    if (onboardingStatusResponse) {
      const { informationFilled } = onboardingStatusResponse;

      let statuses = null;

      if (isCurrentOrganization) {
        statuses = {
          applicationDocumentation: informationFilled.applicationDocumentation,
        } as Record<TabKeysForCurrentOrganization, boolean>;
      } else {
        statuses = {
          generalInformation: informationFilled.generalInformation,
          identification: informationFilled.identification,
          applicationDocumentation: informationFilled.applicationDocumentation,
        } as Record<TabKeysForOrganization, boolean>;
      }

      setStepStatuses(statuses as Record<TabKeys, boolean>);
      setCurrentStep('generalInformation');
    }
  }, [onboardingStatusResponse, isCurrentOrganization]);

  const handleStepSave = (savedStep: TabKeys, isCompleted: boolean) => {
    if (stepStatuses) {
      const stepStatusesCopy = { ...stepStatuses, [savedStep]: isCompleted };
      const nextNotCompletedStep = Object.keys(stepStatusesCopy).find(
        (key) => !stepStatusesCopy[key as TabKeys],
      );

      if (nextNotCompletedStep) {
        setStepStatuses(stepStatusesCopy);
        allInformationFilled && updateNodeStatuses();
        if (isCompleted) {
          setCurrentStep(nextNotCompletedStep as TabKeys);
        }
      } else {
        setStepStatuses(stepStatusesCopy);
        !allInformationFilled && updateNodeStatuses();
      }
    }
  };

  const handleOnAction = (
    currentStep: TabKeys,
    isCompleted: boolean,
    actionType: ActionKeys | null,
  ) => {
    switch (actionType) {
      case 'save': {
        handleStepSave(currentStep, isCompleted);
        break;
      }

      case 'save-back':
      case 'save-exit': {
        onAction && onAction(actionType);
        break;
      }
    }
  };

  const renderIcon = (key: TabKeys) => {
    if (!stepStatuses) {
      return;
    }

    return stepStatuses[key] ? (
      <StyledIconSVG component={CheckIcon} color={colorsTheme.colorPrimary} />
    ) : (
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
    );
  };

  const tabs = useMemo<
    { key: TabKeys; title: React.ReactNode; content: React.ReactNode }[]
  >(() => {
    if (!onboardingStatusResponse || isCurrentOrganization) {
      return [];
    }

    return [
      {
        key: 'generalInformation',
        title: (
          <>
            {t('applicant_information.section_title')}{' '}
            {renderIcon('generalInformation')}
          </>
        ),
        content: (
          <GeneralInformation
            onboardingStatus={onboardingStatusResponse}
            applicationId={application._id}
            isViewOnly={isViewOnly}
            onAction={(isCompleted: boolean, actionType: ActionKeys | null) =>
              handleOnAction('generalInformation', isCompleted, actionType)
            }
          />
        ),
      },

      {
        key: 'identification',
        title: (
          <>
            {t('identification.title')} {renderIcon('identification')}
          </>
        ),
        content: (
          <Identification
            onboardingStatus={onboardingStatusResponse}
            applicationId={application._id}
            isViewOnly={isViewOnly}
            onAction={(isCompleted: boolean, actionType: ActionKeys | null) =>
              handleOnAction('identification', isCompleted, actionType)
            }
          />
        ),
      },

      {
        key: 'applicationDocumentation',
        title: (
          <>
            {t('application_documentation.title')}{' '}
            {renderIcon('applicationDocumentation')}
          </>
        ),
        content: (
          <ApplicationDocumentation
            onAction={onAction}
            canSendMessage={canSendMessage}
            onboardingStatus={onboardingStatusResponse}
            isViewOnly={isViewOnly}
            onSave={(isCompleted) =>
              handleStepSave('applicationDocumentation', isCompleted)
            }
          />
        ),
      },
    ];
  }, [
    onboardingStatusResponse,
    stepStatuses,
    isCurrentOrganization,
    canSendMessage,
    isViewOnly,
  ]);

  return (
    <LoadingWrapper loading={onboardingLoading}>
      <ManageKYC
        onUpdate={updateStructureCallback}
        data={{
          nodeId: graphNode.id,
          onboardingProcessId: graphNode.onboardingProcess
            ? graphNode.onboardingProcess._id
            : null,
          isOnboardingProcessRemovable:
            graphNode.metadata.isOnboardingProcessRemovable,
          isOnboardingProcessCreatable:
            graphNode.metadata.isOnboardingProcessCreatable,
        }}
      />

      {isCurrentOrganization ? (
        <CurrentOrganization
          canSendMessage={canSendMessage}
          canSubmitForReview={canSubmitForReview}
          onboardingStatus={onboardingStatusResponse as OnboardingStatusModel}
          isViewOnly={isViewOnly}
          onSave={(isCompleted) =>
            handleStepSave('applicationDocumentation', isCompleted)
          }
          onAction={onAction}
          onSubmitForReview={onSubmitForReview}
        />
      ) : (
        <Tabs
          useChangeView
          tabs={tabs}
          activeKey={currentStep}
          defaultActiveKey={currentStep}
          onChange={setCurrentStep}
        />
      )}
    </LoadingWrapper>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin: 0 0 0 ${({ theme }) => theme.marginXXs} !important;
`;

export default Organization;
