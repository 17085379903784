import React, { useCallback } from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from '../../../../../../../enums/onboarding/crm';
import { IKeyRelationshipTemplate } from '../../../../../../../typings/application/key-relationship-templates';

// components
import FormField from '@core_components/FormField';
import ContactShortInfoForm from 'components/Forms/TemplateForms/CRM/ContactShortInfoForm';
import OrganizationShortInfoForm from 'components/Forms/TemplateForms/CRM/OrganizationShortInfoForm';
import EntityTypeCategoryAutocomplete, {
  EntityTypeCategoryAutocompleteProps,
} from '../../../../../FormComponents/Autocompletes/CRM/EntityTypeCategoryAutocomplete';

const FromNewEntry = () => {
  const { t } = useTranslation('account_management');
  const { values, setValues } = useFormikContext<FormValuesModel>();

  const handleTypeSelect = useCallback(
    (id: string, template: IKeyRelationshipTemplate) => {
      setValues({
        ...values,
        template: {
          id,
          visibleFor: template.visibleFor as OnboardingEntryTypes,
          template,
        },
        contact:
          template.visibleFor == 'contact'
            ? {
                firstName: '',
                lastName: '',
                middleName: '',
                isPEP: null,
                pepInformation: '',
                email: '',
                phone: '',
                isPermissionsSelectAvailable: true,
                adminPermissionType: null,
                isOnlineUser: null,
                accountPermissions: null,
              }
            : null,
        organization:
          template.visibleFor == 'organization'
            ? { name: '', isRegulated: null, regulatedCountries: [] }
            : null,
      });
    },
    [values, setValues],
  );

  const renderContent = () => {
    if (!values.template.id) {
      return;
    }

    switch (values.template.visibleFor) {
      case OnboardingEntryTypes.Contact:
        return <ContactShortInfoForm namePrefix="contact" />;

      case OnboardingEntryTypes.Organization:
        return <OrganizationShortInfoForm namePrefix="organization" />;
    }
  };

  return (
    <>
      <FormField<EntityTypeCategoryAutocompleteProps>
        name="template.id"
        label={t('client_profile.ownership_modal.new.relationship_type')}
        component={EntityTypeCategoryAutocomplete}
        additionalProps={{
          isVisible: true,
          clientGroupEntryType: values.clientGroupEntryType,
          onSelect: (newValue, option) =>
            option.model && handleTypeSelect(newValue as string, option.model),
        }}
      />

      {renderContent()}
    </>
  );
};

export default FromNewEntry;
