import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { StyledComponentProps } from '../../../typings/common';

// components
import Text from '@core_components/Text';
import DivAlignCenter from '../../Additional/DivAlignCenter';
import CertificationRequiredTooltip from '../../Tooltips/TemplateTooltips/CertificationRequiredTooltip';

type IProps = StyledComponentProps;

const CertificationRequiredLabel = (props: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <DivAlignCenter {...props}>
      <StyledText variant="body1" color={colorsTheme.colorWarning}>
        {`(${t('application_documentation.certification_required_tooltip.title')})`}
      </StyledText>
      <CertificationRequiredTooltip />
    </DivAlignCenter>
  );
};

const StyledText = styled(Text)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default CertificationRequiredLabel;
