import React, { useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { RequestModel } from '../..';
import { StateModel as PersonalDetailsStateModel } from 'redux/reducers/personalDetails';

// components
import Button from '@core_components/Button';
import ShareContactRequestModalDialog from './ShareContactRequestModalDialog';
import ShareContactWithoutKYCRequestModal from 'components/ModalDialogs/TemplateModalDialogs/Connections/ShareContactWithoutKYCRequestModal';

interface IProps {
  data: RequestModel;
  updateRequests: () => void;
}

const ViewClientGroupRequest = ({ data, updateRequests }: IProps) => {
  const { t } = useTranslation('connections');
  const [isRequestModalVisible, setIsKYCVerificationVisible] = useState(false);
  const { isVerified } = useSelector<StateModel, PersonalDetailsStateModel>(
    (state) => state.personalDetails,
  );

  return (
    <>
      <Button onClick={() => setIsKYCVerificationVisible(true)}>
        {t('requests.view_button')}
      </Button>
      {isVerified ? (
        <ShareContactRequestModalDialog
          data={data}
          updateRequests={updateRequests}
          isVisible={isRequestModalVisible}
          closeCallback={() => setIsKYCVerificationVisible(false)}
        />
      ) : (
        <ShareContactWithoutKYCRequestModal
          isVisible={isRequestModalVisible}
          closeCallback={() => setIsKYCVerificationVisible(false)}
        />
      )}
    </>
  );
};

export default ViewClientGroupRequest;
