export enum ApprovalStatuses {
  Pending,
  Approved,
  Rejected,
}

export enum ApprovalActions {
  Approve = 1,
  Reject,
}

export enum JournalEntryTypes {
  Manual = 1,
  InternalTransfer,
  WireTransfer,
  BlockchainTemplate,
  AccountFee,
  WireFee,
}

export enum TransactionTypes {
  InternalTransfer = 1,
  WireTransfer,
  BlockchainTemplate,
}

export enum ApprovalEntryTypes {
  TransferApprovalGroups = 'transfer-approval-groups',
  TransferApprovalRules = 'transfer-approval-rules',
}

export enum ApprovalChangeTypes {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}

export enum ModelValueChangeTypes {
  Nothing = 'nothing',
  Edit = 'edit',
  Add = 'add',
  Delete = 'delete',
}

// TODO: remove and get these statuses from back end
export enum AccountManagementApprovalStatuses {
  All,
  PendingApproval,
  Approved,
  Rejected,
}

export enum ApprovalWorkflowEntryTypes {
  TRANSACTION_APPROVAL_GROUP = 'TRANSACTION_APPROVAL_GROUP',
  TRANSACTION_APPROVAL_RULE = 'TRANSACTION_APPROVAL_RULE',
  ACCOUNT_MNGT_APPROVAL_GROUP = 'ACCOUNT_MNGT_APPROVAL_GROUP',
  ACCOUNT_MNGT_APPROVAL_RULE = 'ACCOUNT_MNGT_APPROVAL_RULE',
  ACCOUNT_MNGT_UPDATE_CLIENTGROUP = 'ACCOUNT_MNGT_UPDATE_CLIENTGROUP',
  ACCOUNT_MNGT_NEW_BANKACCOUNT = 'ACCOUNT_MNGT_NEW_BANKACCOUNT',
  ACCOUNT_MNGT_CIRCLE_INTEGRATION = 'ACCOUNT_MNGT_CIRCLE_INTEGRATION',
}

export enum ApprovalWorkflowChangeTypes {
  Create = 1,
  Edit,
  Delete,
}
