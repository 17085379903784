import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import TrueFalseSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseSelect';
import AreYouPepTooltip from 'components/Tooltips/TemplateTooltips/AreYouPepTooltip';
import FormInputPhoneNumber from '@common_components/Form/FormInputPhoneNumber';

interface IProps {
  fieldName: string;
}

const ContactDetails = memo(({ fieldName }: IProps) => {
  const { t } = useTranslation('connections');

  return (
    <>
      <FormField
        label={t(
          'requests.view_request_modal.share_contact_request.form_fields.first_name',
        )}
        name={`${fieldName}.firstName`}
        component={FormInput}
      />
      <FormField
        label={t(
          'requests.view_request_modal.share_contact_request.form_fields.middle_name',
        )}
        name={`${fieldName}.middleName`}
        component={FormInput}
      />
      <FormField
        label={t(
          'requests.view_request_modal.share_contact_request.form_fields.last_name',
        )}
        name={`${fieldName}.lastName`}
        component={FormInput}
      />
      <FormField
        label={t(
          'requests.view_request_modal.share_contact_request.form_fields.email',
        )}
        name={`${fieldName}.email`}
        component={FormInput}
      />
      <FormField
        label={t(
          'requests.view_request_modal.share_contact_request.form_fields.phone_number',
        )}
        name={`${fieldName}.phoneNumber`}
        component={FormInputPhoneNumber}
      />
      <FormField
        label={
          <DivAlignCenter>
            {t(
              'requests.view_request_modal.share_contact_request.form_fields.is_pep',
            )}
            <AreYouPepTooltip />
          </DivAlignCenter>
        }
        name={`${fieldName}.isPep`}
        component={TrueFalseSelect}
      />
    </>
  );
});

export default ContactDetails;
