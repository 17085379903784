import React, { Ref } from 'react';

// helpers
import { FormikHelpers, FormikProps } from 'formik';
import { IdentificationStepForIndividualShortValidationSchema } from '../../../../../validations/onboarding-new/identification';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel | null;
  onSubmit: (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;

  disabled?: boolean;
  showOnlySaveButton?: boolean;
  mainSectionTooltip?: React.ReactNode;
}

export interface FormValuesModel extends SubmitButtonsFormValuesModel {
  isPEP: boolean | null;
  pepInformation: string;
}

const IdentificationForIndividualShortForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  mainSectionTooltip,
  disabled,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      disabled={disabled}
      initialValues={initialFormValues}
      validationSchema={IdentificationStepForIndividualShortValidationSchema}
      renderForm={
        <>
          <InnerForm mainSectionTooltip={mainSectionTooltip} />
          <HideIfDisabledForm>
            <SubmitButtons showOnlySaveButton={showOnlySaveButton} />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default IdentificationForIndividualShortForm;
