import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from 'helpers/date';
import { IUserDevice } from '../../../../../typings/profile/security';
import { colorsTheme } from '../../../../../resources/theme/styled/colors';
import { SessionHelpers } from '../../../../../helpers/session';

// components
import Text from '@core_components/Text';
import Button from '../../../../DesignSystem/Core/Button';
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import StatusLabel, {
  LABEL_STATUSES,
} from '../../../../Typography/StatusLabel';
import { ReactComponent as ComputerIcon } from '../../../../../resources/icons/remix-icons/computer-line.svg';

interface IProps extends RequiredPropsForTableModel<IUserDevice> {
  onSignOut: (sessionId: string) => void;
}

const DevicesTable = ({ onSignOut, ...rest }: IProps) => {
  const { t } = useTranslation('profile');

  const renderStatus = (device: IUserDevice) => {
    if (!device.session?.deleted && device.session?._id) {
      return (
        <StatusLabel
          status={LABEL_STATUSES.success}
          content={t('security.devices.device_statuses.online')}
        />
      );
    }

    return (
      <StatusLabel
        status={LABEL_STATUSES.error}
        content={t('security.devices.device_statuses.offline')}
      />
    );
  };

  const renderSignOut = (device: IUserDevice) => {
    const sessionId = device.session?._id;
    if (sessionId && !device.session?.deleted) {
      return (
        <Button danger fullWidth onClick={() => onSignOut(sessionId)}>
          {t('security.devices.sign_out_device.sign_out_device_button')}
        </Button>
      );
    }
  };

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('security.devices.table.device_name'),
        key: 'device',
        render: (record: IUserDevice) => (
          <Text variant="body1">
            <StyledIconSVG
              component={ComputerIcon}
              color={colorsTheme.colorWhite}
            />
            {SessionHelpers.getDeviceName(record)}
          </Text>
        ),
      },
      {
        title: t('security.devices.table.last_session'),
        key: 'lastSession',
        render: (record: IUserDevice) =>
          record?.session?._id &&
          DateHelpers.formatDateToString(record?.session?.updatedAt, 'llll'),
      },
      {
        title: t('security.devices.table.status'),
        key: 'status',
        render: renderStatus,
      },
      {
        align: 'right',
        width: '300px',
        key: 'delete',
        render: renderSignOut,
      },
    ];
  }, [onSignOut]);

  return <Table columns={columns} {...rest} />;
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default DevicesTable;
