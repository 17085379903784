import React from 'react';

// helpers
import { createAddressValidationSchema } from '../../../../../validations/accounting/blockchainAddresses';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

export interface FormValuesModel {
  currency: string | null;
  network: string | null;
}

const AddBlockchainAddressModalDialog = ({
  title,
  isVisible,
  onSubmit,
  closeCallback,
  initialValues,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={createAddressValidationSchema}
      renderForm={
        <ModalDialog
          width={520}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default AddBlockchainAddressModalDialog;
