import React, { useMemo } from 'react';

import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../redux/reducers';
import { RoutePaths } from 'routes/routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from '../../../../../enums/profile/auth';
import { setTwoFactorVerificationData } from '../../../../../redux/actions/auth';
import { StateModel as AuthStateModel } from '../../../../../redux/reducers/auth';

// components
import Link from '@common_components/Texts/Link';
import { Collapse } from 'antd';

const CollapseOptions = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { twoFactorVerificationData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const isAuthenticatorEnabled = useMemo(() => {
    if (twoFactorVerificationData?.options) {
      return !!twoFactorVerificationData?.options.find(
        (e) => e.code === TwoFactorVerificationTypes.Authenticator && e.enabled,
      );
    }
  }, [twoFactorVerificationData?.options]);

  const changeToAuthenticator = () => {
    if (twoFactorVerificationData) {
      dispatch(
        setTwoFactorVerificationData({
          ...twoFactorVerificationData,
          type: TwoFactorVerificationTypes.Authenticator,
        }),
      );
    }
  };

  const navigateToAccountRecovery = () => {
    navigate(RoutePaths.Auth_AccountRecovery);
  };

  return (
    <StyledCollapse bordered={false}>
      <Collapse.Panel
        key={1}
        collapsible="header"
        header={t('two_factor_authentication.fido.unable_to_use_security_key')}
      >
        {isAuthenticatorEnabled && (
          <StyledLink onClick={changeToAuthenticator}>
            {t('two_factor_authentication.fido.use_authenticator_to_continue')}
          </StyledLink>
        )}
        <StyledLink onClick={navigateToAccountRecovery}>
          {t('two_factor_authentication.fido.lost_my_security_key')}
        </StyledLink>
      </Collapse.Panel>
    </StyledCollapse>
  );
};

const StyledCollapse = styled(Collapse)`
  margin-top: ${({ theme }) => theme.marginXs};
  width: 100%;
`;

const StyledLink = styled(Link)`
  display: block;
`;

export default CollapseOptions;
