import { ActionType } from '../actions/notifications';
import { IReduxAction } from '../../typings/common';

export interface StateModel {
  newMessagesCount: number;
}

const initialState: StateModel = {
  newMessagesCount: 0,
};

const notifications = (state = initialState, action: IReduxAction) => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SET_MESSAGES_STATUS:
      return {
        newMessagesCount: payload.newMessagesCount,
      };

    case ActionType.CLEAN_UP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default notifications;
