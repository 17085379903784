import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { travelNotificationsAPI } from '../../../../api/profile/travelNotificationsAPI';
import { TravelNotificationModel } from '../../../../typings/profile/travelNotifications';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import EditTravelNotificationRuleModal from './EditTravelNotificationModal';
import { default as TemplateTravelNotificationsTable } from '../../../../components/Tables/TableTemplates/User/TravelNotificationsTable';

interface IProps {
  updateTableTrigger?: any;
}

const TravelNotificationsTable = ({ updateTableTrigger }: IProps) => {
  const [currentPage, setPage] = useState(1);
  const [localUpdateTableTrigger, updateTable] = useState({});

  const [selectedElement, setSelectedElement] =
    useState<TravelNotificationModel | null>(null);

  const { response, loading: fetchTravelNotificationsLoader } = useFetch(
    () =>
      travelNotificationsAPI.fetchTravelNotifications({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [localUpdateTableTrigger, updateTableTrigger, currentPage],
  );

  const handleActionsClick = (key: string, notificationId: string) => {
    if (response) {
      switch (key) {
        case 'edit':
          {
            const notificationIndex = response.data.findIndex(
              (e) => e.id === notificationId,
            );
            setSelectedElement(response.data[notificationIndex]);
          }
          break;
      }
    }
  };

  const handleUpdated = (wasUpdated?: boolean) => {
    wasUpdated && updateTable({});
    setSelectedElement(null);
  };

  return (
    <>
      <TemplateTravelNotificationsTable
        total={response?.total || 0}
        current={currentPage}
        data={response?.data || []}
        loading={fetchTravelNotificationsLoader}
        onActionsClick={handleActionsClick}
        onPaginationChange={setPage}
      />

      <EditTravelNotificationRuleModal
        closeCallback={handleUpdated}
        isVisible={!!selectedElement}
        initialNotification={selectedElement}
      />
    </>
  );
};

export default TravelNotificationsTable;
