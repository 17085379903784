import React from 'react';

// helpers
import { RoutePaths } from 'routes/routes';
import { StateModel } from '../redux/reducers';
import { useSelector } from 'react-redux';
import { LocalStorageHelpers } from '../helpers/storages/localStorage';
import { StateModel as ApplicationsStateModel } from '../redux/reducers/applications';

// components
import { Navigate } from 'react-router-dom';

const withApplications = () => (Component: any) => {
  const WithApplications = () => {
    const { applications, activeApplication } = useSelector<
      StateModel,
      ApplicationsStateModel
    >((state) => state.applications);
    const applicationsCount = applications?.length || 0;
    const activeApplicationId = LocalStorageHelpers.getActiveApplication();

    const showClientGroupSelect =
      applicationsCount > 1 && !activeApplication && !activeApplicationId;

    if (location.pathname === RoutePaths.SelectApplicationPage) {
      return showClientGroupSelect ? (
        <Component />
      ) : (
        <Navigate to={RoutePaths.Root} />
      );
    } else if (showClientGroupSelect) {
      return <Navigate to={RoutePaths.SelectApplicationPage} />;
    }

    return <Component />;
  };

  return WithApplications;
};

export default withApplications;
