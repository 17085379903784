import React, { useRef } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';

// constants
import { VerificationDocumentPhotoTypes } from '../../../../../../enums/profile/accountRecovery';
import { ALLOWED_VERIFICATION_DOCUMENT_TYPES } from '../../../../../../constants/documents';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import DeleteButton from '../../../../../../components/DesignSystem/Common/Buttons/DeleteButton';

interface IProps {
  imageType: VerificationDocumentPhotoTypes;
  selectedImage: File | null;
  onSelect: (type: VerificationDocumentPhotoTypes, image: File) => void;
  onDeleteClick: (type: VerificationDocumentPhotoTypes) => void;
}

const UploadImageCard = ({
  imageType,
  selectedImage,
  onSelect,
  onDeleteClick,
}: IProps) => {
  const { t } = useTranslation(['common', 'auth', 'form']);
  const inputRef = useRef<any>();

  const onFileSelect = () => {
    if (inputRef.current && inputRef.current.files.length) {
      const file = inputRef.current.files[0];
      if (!file.size) {
        message.info(t('warnings.upload_empty_document', { ns: 'form' }));
      } else {
        onSelect(imageType, inputRef.current.files[0]);
      }
    }
  };

  const handleBrowseClick = () => {
    inputRef.current.click();
  };

  return (
    <WrapperCard selectedImage={selectedImage}>
      {!selectedImage ? (
        <>
          <Text variant="body1" weight="semi-bold">
            {t(
              `account_recovery.id_verification_step.upload_document_methods.upload.side_types.${imageType}`,
              { ns: 'auth' },
            )}
          </Text>
          <Link onClick={handleBrowseClick}>{t('browse')}</Link>
          <HiddenUploadInput
            ref={inputRef}
            type="file"
            accept={ALLOWED_VERIFICATION_DOCUMENT_TYPES}
            onChange={onFileSelect}
          />
        </>
      ) : (
        <>
          <SelectedImage src={URL.createObjectURL(selectedImage)} />
          <StyledDeleteButtonWrapper>
            <DeleteButton onClick={() => onDeleteClick(imageType)} />
          </StyledDeleteButtonWrapper>
        </>
      )}
    </WrapperCard>
  );
};

const HiddenUploadInput = styled.input`
  display: none;
`;

const WrapperCard = styled.div<{ selectedImage: File | null }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // 120px - width of the image block when the image is uploaded.
  // 140px - width of the image block when the image is not uploaded.
  // 150px - height of the image block when the image is uploaded.
  width: ${({ selectedImage }) => (selectedImage ? '120px' : '140px')};
  height: ${({ selectedImage }) => (selectedImage ? '150px' : '100%')};

  border: 1px dashed ${({ theme }) => theme.colorWhite};
  border-radius: 5px;
  overflow: hidden;
`;

const SelectedImage = styled.img`
  width: 100%;
  height: auto;
`;

const StyledDeleteButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default UploadImageCard;
