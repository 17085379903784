import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Col, Row } from 'antd';

// sections
import AccountDetails from './AccountDetails';
import WelcomeToProvaSection from './WelcomeToProvaSection';

const Dashboard = () => {
  return (
    <StyledRow gutter={[20, 20]}>
      <Col span={24}>
        <WelcomeToProvaSection />
      </Col>

      <Col span={24}>
        <AccountDetails />
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  .ant-col {
    .ant-card {
      margin: 0px !important;
    }
  }
`;

export default Dashboard;
