import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../hooks/useTranslation';
import { changeView } from '../../redux/actions/view';
import { RoutePaths } from 'routes/routes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colorsTheme } from '../../resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import IconSVG from '../../components/DesignSystem/Core/IconSVG';
import { Button } from 'antd';
import { ReactComponent as WarningIcon } from '../../resources/icons/remix-icons/error-warning-line.svg';

interface IProps {
  removeError: () => void;
}

const ErrorPage = ({ removeError }: IProps) => {
  const { t } = useTranslation('custom_errors');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onBackHomeClick = () => {
    removeError();
    dispatch(changeView(() => navigate(RoutePaths.Root)));
  };

  return (
    <StyledWrapper>
      <IconSVG
        component={WarningIcon}
        color={colorsTheme.colorWhite}
        size="extra-large"
      />

      <Text
        variant="h1"
        weight="bold"
        color={colorsTheme.colorWhite}
        gutterBottom
        gutterTop
      >
        {t('unexpected_error_page.title')}
      </Text>

      <StyledButton size="large" type="primary" onClick={onBackHomeClick}>
        {t('unexpected_error_page.go_home_btn')}
      </StyledButton>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background: ${({ theme }) => theme.colorDark};
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default ErrorPage;
