import React from 'react';

// helpers
import styled from 'styled-components';
import { darkTheme } from '../../../resources/theme/styled';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { StyledComponentProps } from '../../../typings/common';

// components
import IconSVG, { IconSizes } from '../../DesignSystem/Core/IconSVG';
import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../../resources/icons/remix-icons/information-line.svg';

export interface IProps extends StyledComponentProps {
  tooltipContent: React.ReactNode;
  color?: string;
  children?: any;
  placement?: TooltipPlacement;
  iconSize?: IconSizes;
}

const InfoTooltip = ({
  placement = 'bottom',
  tooltipContent,
  color = darkTheme.colorWarning,
  children,
  iconSize = 'small',
  ...rest
}: IProps) => {
  return (
    <Tooltip title={tooltipContent} placement={placement} {...rest}>
      {children ? (
        children
      ) : (
        <StyledIconSVG color={color} component={InfoIcon} size={iconSize} />
      )}
    </Tooltip>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default InfoTooltip;
