import { ActionType } from '../actions/app';
import { IReduxAction } from '../../typings/common';

export type BadgeModel = {
  pendingClientRequestsCount: number;
  pendingTransactionsCount: number;
  pendingAccountManagementWorkflowsCount: number;
};

export interface StateModel {
  isInitialized: boolean;
  lastActivityTimestamp: number;
  instrumentationKey: string | null;
  isWelcomeModalVisible: boolean;
  badges: BadgeModel;
}

// Initial state
export const initialState: StateModel = {
  isInitialized: false,
  lastActivityTimestamp: Date.now(),
  instrumentationKey: null,
  isWelcomeModalVisible: false,
  badges: {
    pendingTransactionsCount: 0,
    pendingAccountManagementWorkflowsCount: 0,
    pendingClientRequestsCount: 0,
  },
};

const app = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case ActionType.SET_INITIALIZED_STATUS:
      return {
        ...state,
        isInitialized: action.payload.status,
      };

    case ActionType.SET_ACTIVITY_TIMESTAMP:
      return {
        ...state,
        lastActivityTimestamp: action.payload.lastActivityTimestamp,
      };

    case ActionType.SET_INSTRUMENTATION_KEY:
      return {
        ...state,
        instrumentationKey: action.payload.instrumentationKey,
      };

    case ActionType.SET_BADGES:
      return {
        ...state,
        badges: action.payload.badges,
      };

    case ActionType.SET_WELCOME_MODAL_VISIBILITY_STATUS:
      return {
        ...state,
        isWelcomeModalVisible: action.payload.status,
      };

    case ActionType.CLEAN_UP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default app;
