import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { ContactModel } from '../../typings/application/contact';

const contactsAPI = {
  fetchContactById: (contactId: string) => {
    return APIService.get<ContactModel>(
      `${APIConfig.crmApi}/contacts/${contactId}`,
    ).then(({ data }) => data);
  },

  updateUserPhoneNumber: (phoneNumber: string) => {
    return APIService.post(`${APIConfig.crmApi}/users/phone/change-primary`, {
      phoneNumber,
    }).then(({ data }) => data);
  },
};

export { contactsAPI };
