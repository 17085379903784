import * as yup from 'yup';

// constants
import {
  NULLABLE_NUMBER_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
} from '../common';

const ApprovalGroupConditionValidationSchema = yup.object().shape({
  approvalGroupId: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  requiredApprovers: NULLABLE_NUMBER_VALIDATION_RULE.moreThan(0).required(
    'form:validation_messages.required',
  ),
});

const ApprovalConditionValidationSchema = yup.object().shape({
  approvalGroupConditions: yup
    .array()
    .of(ApprovalGroupConditionValidationSchema),
  actionStatuses: yup.object().shape({
    create: yup.boolean(),
    edit: yup.boolean(),
    delete: yup.boolean(),
  }),
});

export const ApprovalRuleValidationSchema = yup.object().shape({
  approvalConditions: yup.array().of(ApprovalConditionValidationSchema),
});
