import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import { getBadges } from 'redux/actions/app';
import { StateModel } from '../../redux/reducers';
import { ApplicationStatuses } from '../../enums/onboarding/crm';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveApplicationStatus } from '../../redux/actions/applications';
import {
  PageLayoutOptions,
  useMainLayoutContext,
} from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import AddUser from '../../modules/AccountManagement/UserManagement/AddUser';
import UsersTable from '../../modules/AccountManagement/UserManagement/UsersTable';
import ClientGroupActions from '../../modules/AccountManagement/ClientGroupActions';
import PendingApprovalTooltip from '../../components/Tooltips/TemplateTooltips/PendingApprovalTooltip';

const UserManagement = () => {
  const dispatch = useDispatch();
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const [updateTableTrigger, updateTable] = useState({});
  const [hasCreateAccess] = useUserAccess([
    'AccountManagement_UserManagement_Create',
  ]);
  const applicationStatus = useSelector<StateModel, ApplicationStatuses | null>(
    ({ applications }) => applications.activeApplicationStatus,
  );

  const handleTableUpdate = () => {
    updateTable({});
  };

  const onSubmitForReview = () => {
    updateTable({});
    dispatch(
      setActiveApplicationStatus(ApplicationStatuses.InAccountManagementReview),
    );
    dispatch(getBadges());
  };

  useEffect(() => {
    const config: PageLayoutOptions | null = {};
    const isApplicationInReview =
      applicationStatus === ApplicationStatuses.InAccountManagementReview ||
      applicationStatus === ApplicationStatuses.InReview;
    config['extra'] =
      hasCreateAccess && !isApplicationInReview ? (
        <AddUser onAdd={handleTableUpdate} />
      ) : null;
    if (isApplicationInReview) {
      if (applicationStatus === ApplicationStatuses.InAccountManagementReview) {
        config['description'] = (
          <PendingApprovalTooltip approvalType="account_management" />
        );
      } else if (applicationStatus === ApplicationStatuses.InReview) {
        config['description'] = (
          <PendingApprovalTooltip approvalType="internal" />
        );
      }
    }
    setPageLayoutOptions(config);
    return () => clearContext();
  }, [applicationStatus, setPageLayoutOptions, clearContext]);

  return (
    <>
      <UsersTable
        updateTableTrigger={updateTableTrigger}
        updateTable={handleTableUpdate}
      />
      <ClientGroupActions
        onSubmit={onSubmitForReview}
        updateTrigger={updateTableTrigger}
        onDiscardChanges={handleTableUpdate}
      />
    </>
  );
};

export default UserManagement;
