import React from 'react';

// helpers
import { IBank } from '../../../../../../typings/accounting/bank';
import { banksAPI } from '../../../../../../api/accounting/banksAPI';
import { AutocompleteProps } from '@core_components/Autocomplete';

// constants
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface BankAutocompleteProps extends AutocompleteProps<IBank> {
  bankCodeType: number;
}

const BankAutocomplete = ({ bankCodeType, ...rest }: BankAutocompleteProps) => {
  const fetchBanks = async (searchText: string) => {
    const { data } = await banksAPI.fetchBanks({
      offset: 0,
      bankCode: searchText,
      bankCodeTypeId: bankCodeType,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
    });

    return data.map((bank) => ({
      id: bank.bankCode,
      label: bank.bankCode,
      model: bank,
    }));
  };

  return <FormAutocomplete {...rest} fetchData={fetchBanks} />;
};

export default BankAutocomplete;
