// helpers
import { PermissionedAccessModel } from '.';

// Permission keys
export type ConnectionsPermissionKeys =
  | 'Connections_Requests_View'
  | 'Connections_Requests_Approve'
  | 'Connections_Requests_Reject';

// Permission config
export const ConnectionsPermissions: Record<
  ConnectionsPermissionKeys,
  PermissionedAccessModel
> = {
  Connections_Requests_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ClientRequest:Read' }],
  },

  Connections_Requests_Approve: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ClientRequest:Accept' }],
  },

  Connections_Requests_Reject: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ClientRequest:Reject' }],
  },
};
