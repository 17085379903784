import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';

// components
import WarningWithPopover from '../WarningIconWithPopover';

const TransactionProcessedByBankTooltip = memo(() => {
  const { t } = useTranslation('accounts');

  return (
    <WarningWithPopover
      popoverContent={t(
        'account_activity.transaction_being_processed_by_bank_tooltip',
      )}
    />
  );
});

export default TransactionProcessedByBankTooltip;
