import { ActionType } from '../actions/applications';
import { IReduxAction } from '../../typings/common';
import { IApplication } from '../../typings/application/applications';
import { ApplicationStatuses } from '../../enums/onboarding/crm';
import { ContactModel, IContactPhone } from '../../typings/application/contact';

export type AccountManagementApproversDataModel = {
  selected: number;
  min: number;
  max: number;
};

export interface StateModel {
  applicationsLoading: boolean;
  applications: IApplication[] | null;

  activeApplicationLoading: boolean;
  activeApplicationStatus: ApplicationStatuses | null;
  activeApplication: IApplication | null;

  accountManagementApprovers: AccountManagementApproversDataModel;
}

const initialState: StateModel = {
  applicationsLoading: false,
  applications: null,

  activeApplicationLoading: false,
  activeApplicationStatus: null,
  activeApplication: null,

  accountManagementApprovers: {
    selected: 0,
    min: 0,
    max: 0,
  },
};

const applications = (state = initialState, action: IReduxAction) => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.START_LOADING_APPLICATIONS:
      return {
        ...state,
        applicationsLoading: true,
      };

    case ActionType.SET_APPLICATIONS:
      return {
        ...state,
        applications: payload,
        applicationsLoading: false,
      };

    case ActionType.START_LOADING_ACTIVE_APPLICATION:
      return {
        ...state,
        activeApplicationLoading: true,
      };

    case ActionType.SET_ACTIVE_APPLICATION:
      return {
        ...state,
        activeApplication: payload,
        activeApplicationLoading: false,
        accountManagementApprovers: {
          selected:
            payload?.clientGroup?.initialNumberOfApprovers?.selected || 0,
          min: payload?.clientGroup?.initialNumberOfApprovers?.min || 0,
          max: payload?.clientGroup?.initialNumberOfApprovers?.max || 0,
        },
      };

    case ActionType.SET_ACTIVE_APPLICATION_STATUS:
      return {
        ...state,
        activeApplicationStatus: payload,
      };

    case ActionType.SET_ACTIVE_APPLICATION_PHONE_NUMBER: {
      const activeApplicationItem = state.activeApplication
        ?.model as ContactModel;

      if (activeApplicationItem.phoneNumbers.length) {
        activeApplicationItem.phoneNumbers[0].number = payload;
      } else {
        activeApplicationItem.phoneNumbers = [
          { number: payload, isPrimary: true } as IContactPhone,
        ];
      }

      return {
        ...state,
        activeApplication: {
          ...state.activeApplication,
          onboardingProcess: {
            ...state.activeApplication?.onboardingProcess,
            item: activeApplicationItem,
          },
        },
      };
    }

    case ActionType.SET_ONBOARDING_ACCOUNT_MANAGEMENT_DATA: {
      return {
        ...state,
        accountManagementApprovers: payload.accountManagementApprovers,
      };
    }

    case ActionType.CLEAN_UP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default applications;
