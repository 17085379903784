import { FormValuesModel } from '../../modules/Auth/AccountRecovery';

// constants
import {
  VerificationDocumentPhotoTypes,
  VerificationDocumentTypes,
} from '../../enums/profile/accountRecovery';
import { TwoFactorVerificationTypes } from '../../enums/profile/auth';

export interface NewAccountRecoveryRequestModel {
  phone: string;
  selfieSrc: string;
  types: TwoFactorVerificationTypes[];
  document: AccountRecoveryDocumentModel[];
  documentType: VerificationDocumentTypes;
}

interface AccountRecoveryDocumentModel {
  page: number;
  src: string;
}

export const accountRecoveryRequestAdapter = {
  requestAccountRecovery: (formValues: FormValuesModel) => {
    const { phone, snapshots, idVerificationType, types } = formValues;

    const documentsArray: { page: number; src: string }[] = [];

    if (snapshots) {
      Object.keys(snapshots).forEach((key) => {
        if (key !== VerificationDocumentPhotoTypes.Selfie) {
          const documentSrc =
            snapshots[key as keyof typeof VerificationDocumentPhotoTypes];

          documentsArray.push({
            page: documentsArray.length + 1,
            src: documentSrc,
          });
        }
      });
    }

    const result: NewAccountRecoveryRequestModel = {
      types,
      phone: phone.trim(),
      documentType: idVerificationType as VerificationDocumentTypes,
      document: documentsArray,
      selfieSrc: snapshots
        ? snapshots[VerificationDocumentPhotoTypes.Selfie]
        : '',
    };

    return result;
  },
};
