import React from 'react';

import ReactDOM from 'react-dom';

// Providers
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import AppInsightsProvider from './services/azure-app-insights';
import ErrorHandlerProvider from './services/error-handler';

// Redux
import StateHelper from './redux/StoreHelper';
import { StateModel } from './redux/reducers';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store/configureStore';

// New Theme styles
import { darkTheme } from './resources/theme/styled';
import { GlobalStyle } from './resources/theme/styled/global';

import './resources/fonts/fonts.scss';
import './resources/theme/antd/antd.less';
import './resources/theme/antd/antd.scss';

// Localization file
import './i18n';

import App from './App';
import UploadService from 'modules/Services/UploadService';

const store = configureStore({} as StateModel);
StateHelper.setStore(store);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <AppInsightsProvider>
          <ErrorHandlerProvider>
            <App />
            <GlobalStyle />
            <UploadService />
          </ErrorHandlerProvider>
        </AppInsightsProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
