import React from 'react';

// components
import AdditionalFieldsSection from './AdditionalFieldsSection';
import AdditionalDocumentsSection from './AdditionalDocumentsSection';

interface IProps {
  canSendMessage?: boolean;
}

const InnerForm = ({ canSendMessage }: IProps) => {
  return (
    <>
      <AdditionalFieldsSection canSendMessage={canSendMessage} />
      <AdditionalDocumentsSection />
    </>
  );
};

export default InnerForm;
