import React, { memo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { TransactionData } from '../..';

// components
import ApprovalMatrix from 'components/Tables/ApprovalMatrix';
import ReviewTransactionModal from './ReviewTransactionModal';
import { Button, Col, Divider, Row } from 'antd';

interface IProps {
  initialData: TransactionData;
  onReview?: () => void;
}

enum ACTION_TYPE {
  APPROVE = 1,
  REJECT,
}

const ApprovalSection = memo(({ initialData, onReview }: IProps) => {
  const buttonGridSizes = { xl: 6, lg: 8, md: 8, sm: 24, xs: 24 };
  const { t } = useTranslation(['accounts', 'common']);
  const [selectedAction, setSelectedAction] = useState<ACTION_TYPE | null>(
    null,
  );

  const handleCloseCallback = (wasReviewed?: boolean) => {
    if (!wasReviewed) {
      setSelectedAction(null);
    } else {
      onReview && onReview();
      setSelectedAction(null);
    }
  };

  if (!initialData.transactionApprovals) {
    return null;
  }

  return (
    <>
      <Divider orientation="left">
        {t('transaction_details_modal.transaction_details.approval_section')}
      </Divider>
      <ApprovalMatrix transactionApprovals={initialData.transactionApprovals} />

      {onReview && (
        <ButtonsWrapperRow gutter={[16, 16]} justify="end">
          <Col {...buttonGridSizes}>
            <StyledButton
              danger
              size="large"
              onClick={() => setSelectedAction(ACTION_TYPE.REJECT)}
            >
              {t('reject', { ns: 'common' })}
            </StyledButton>
          </Col>
          <Col {...buttonGridSizes}>
            <StyledButton
              size="large"
              onClick={() => setSelectedAction(ACTION_TYPE.APPROVE)}
            >
              {t('approve', { ns: 'common' })}
            </StyledButton>
          </Col>
        </ButtonsWrapperRow>
      )}

      <ReviewTransactionModal
        workflowId={initialData.approvalWorkflowId as string}
        handleModalClose={handleCloseCallback}
        selectedActionType={selectedAction}
      />
    </>
  );
});

const ButtonsWrapperRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm} !important;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ApprovalSection;
