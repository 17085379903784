import { AdditionalFieldHelpers } from '../../helpers/additionalField';
import { FormValuesModel as NewUserFormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/Onboarding/NewUserModalDialog';
import { FormValuesModel as AddNewNodeFormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/Onboarding/AddNewNodeModalDialog';
import { FormValuesModel as ContactInfoFormValuesModel } from '../../components/Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as OnboardingNodeFormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/Onboarding/EditNodeModalDialog';
import { FormValuesModel as OrganizationInfoFormValuesModel } from '../../components/Forms/TemplateForms/CRM/OrganizationShortInfoForm';
import {
  AdministrationPermissionTypes,
  OnboardingEntryTypes,
} from '../../enums/onboarding/crm';
import { FormValuesModel as RelationshipTemplateFormValuesModel } from '../../components/Forms/TemplateForms/CRM/RelationshipTemplateForm';
import { FormValuesModel as InitialRelationshipsFormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/Onboarding/SetupInitialRelationshipsModal';

import {
  FormValuesModel as OwnershipStructureFormValuesModel,
  OwnershipFormItem,
} from '../../components/ModalDialogs/TemplateModalDialogs/Onboarding/OwnershipStructureModalDialog';
import {
  AdditionalFieldAnswerModel,
  CreateRelationshipOptionModel,
  IContactCreateShortModel,
  IOrganizationUpdateShortModel,
  ModifyRelationshipsBodyModel,
  NewRelationshipModel,
  RelationshipMemberByIdModel,
  RelationshipMemberFromNewContactModel,
  RelationshipMemberFromNewOrganizationModel,
  RelationshipMemberModel,
  RemoveRelationshipOptionModel,
  UpdateRelationshipMemberContactModel,
  UpdateRelationshipMemberModel,
  UpdateRelationshipMemberOrganizationModel,
  UpdateRelationshipOptionModel,
} from '../../typings/relationships/relationships';

const generateCreateOperations = (
  relationships: RelationshipTemplateFormValuesModel[],
  parent: RelationshipMemberModel,
  child: RelationshipMemberByIdModel,
): CreateRelationshipOptionModel[] => {
  return [
    {
      type: 'create',
      payload: {
        parent,
        relationships: relationships.map((e) => ({
          relationshipTemplateId: e.id,
          child,
          additionalFields: e.additionalFields.map((e) => ({
            name: e.name,
            type: e.type,
            valueJSON: AdditionalFieldHelpers.formatToJSON(e.type, e.value),
            _id: e.id,
          })),
        })),
      },
    },
  ];
};

const getFormattedUpdateNodeData = (
  node: ContactInfoFormValuesModel | OrganizationInfoFormValuesModel,
  type: OnboardingEntryTypes,
  addUserAccessData?: boolean,
): UpdateRelationshipMemberModel => {
  switch (type) {
    case OnboardingEntryTypes.Contact: {
      const contact = node as ContactInfoFormValuesModel;

      const result: UpdateRelationshipMemberContactModel = {
        type: 'contact',
        model: {
          firstName: contact.firstName ? contact.firstName.trim() : '',
          middleName: contact.middleName ? contact.middleName.trim() : '',
          lastName: contact.lastName ? contact.lastName.trim() : '',
          isPEP: !!contact.isPEP,
          email: contact.email,
          phoneNumber: contact.phone,
          pepInformation: contact.isPEP
            ? String(contact.pepInformation).trim()
            : '',
        },
      };

      if (contact.isPermissionsSelectAvailable || addUserAccessData) {
        result.model = {
          ...result.model,
          onlineAccess: contact?.isOnlineUser
            ? {
                adminPermissionType:
                  !contact.adminPermissionType ||
                  contact.adminPermissionType ==
                    AdministrationPermissionTypes.LimitedAdmin
                    ? undefined
                    : contact.adminPermissionType,
                permission: contact.accountPermissions,
              }
            : null,
        };
      }

      return result;
    }

    case OnboardingEntryTypes.Organization: {
      const organization = node as OrganizationInfoFormValuesModel;

      const result: UpdateRelationshipMemberOrganizationModel = {
        type: 'organization',
        model: {
          legalName: String(organization?.name).trim(),
          isRegulated: !!organization?.isRegulated,
          regulationCountry: organization?.isRegulated
            ? organization?.regulatedCountries
            : [],
        },
      };

      return result;
    }
  }
};

const getFormattedCreateNodeData = (
  node: ContactInfoFormValuesModel | OrganizationInfoFormValuesModel,
  type: OnboardingEntryTypes,
  keyRelationshipTemplateId: string,
): IOrganizationUpdateShortModel | IContactCreateShortModel => {
  switch (type) {
    case OnboardingEntryTypes.Contact: {
      const contact = node as ContactInfoFormValuesModel;

      let result: IContactCreateShortModel = {
        firstName: contact.firstName ? contact.firstName.trim() : '',
        middleName: contact.middleName ? contact.middleName.trim() : '',
        lastName: contact.lastName ? contact.lastName.trim() : '',
        isPEP: !!contact.isPEP,
        pepInformation: contact.isPEP
          ? String(contact.pepInformation).trim()
          : '',
        email: contact.email ? contact.email.trim() : '',
        phoneNumber: contact.phone ? contact.phone.trim() : '',
      };

      if (contact.isPermissionsSelectAvailable) {
        result = {
          ...result,
          onlineAccess: contact?.isOnlineUser
            ? {
                adminPermissionType:
                  !contact.adminPermissionType ||
                  contact.adminPermissionType ==
                    AdministrationPermissionTypes.LimitedAdmin
                    ? undefined
                    : contact.adminPermissionType,
                permission: contact.accountPermissions,
              }
            : undefined,
        };
      }

      return result;
    }

    case OnboardingEntryTypes.Organization: {
      const organization = node as OrganizationInfoFormValuesModel;

      const result = {
        keyRelationshipTemplateId,
        legalName: String(organization?.name).trim(),
        isRegulated: !!organization?.isRegulated,
        regulationCountry: organization?.isRegulated
          ? organization?.regulatedCountries
          : [],
      };

      return result;
    }
  }
};

const getParentForShareholderRelationships = (
  ownershipRelationship: OwnershipFormItem,
): RelationshipMemberModel | UpdateRelationshipMemberModel => {
  let parent: RelationshipMemberModel;

  if (ownershipRelationship.type == OnboardingEntryTypes.Contact) {
    parent = {
      type: 'contact',
      model: {
        firstName: ownershipRelationship.contact?.firstName
          ? ownershipRelationship.contact.firstName.trim()
          : '',
        middleName: ownershipRelationship.contact?.middleName
          ? ownershipRelationship.contact.middleName.trim()
          : '',
        lastName: ownershipRelationship.contact?.lastName
          ? ownershipRelationship.contact.lastName.trim()
          : '',
        isPEP: !!ownershipRelationship.contact?.isPEP,
        pepInformation: ownershipRelationship.contact?.isPEP
          ? String(ownershipRelationship.contact?.pepInformation).trim()
          : '',
        email: ownershipRelationship.contact?.email || '',
        phoneNumber: ownershipRelationship.contact?.phone || '',
        onlineAccess: ownershipRelationship.contact?.isOnlineUser
          ? {
              adminPermissionType:
                !ownershipRelationship.contact.adminPermissionType ||
                ownershipRelationship.contact.adminPermissionType ==
                  AdministrationPermissionTypes.LimitedAdmin
                  ? undefined
                  : ownershipRelationship.contact.adminPermissionType,
              permission: ownershipRelationship.contact.accountPermissions,
            }
          : undefined,
      },
    } as RelationshipMemberFromNewContactModel;
  } else {
    parent = {
      type: 'organization',
      model: {
        legalName: String(ownershipRelationship.organization?.name).trim(),
        isRegulated: !!ownershipRelationship.organization?.isRegulated,
        regulationCountry: ownershipRelationship.organization?.isRegulated
          ? ownershipRelationship.organization?.regulatedCountries
          : [],
      },
    } as RelationshipMemberFromNewOrganizationModel;

    if (!ownershipRelationship.id) {
      parent.model.keyRelationshipTemplateId =
        ownershipRelationship.keyRelationshipTemplate.id;
    }
  }

  return parent;
};

const relationshipsAPIAdapter = {
  generateRequestBodyForCreateInitialRelationshipModal: (
    initialRelationshipsFormValues: InitialRelationshipsFormValuesModel,
  ): ModifyRelationshipsBodyModel => {
    const {
      isPEP,
      pepInformation,
      relationships,
      relationshipsFromContactId,
      relationshipsToOrganizationId,
    } = initialRelationshipsFormValues;

    const parent: RelationshipMemberModel = {
      id: relationshipsFromContactId,
      type: 'contact',
      model: {
        isPEP: !!isPEP,
        pepInformation: isPEP ? String(pepInformation).trim() : '',
      },
    };

    const child: RelationshipMemberModel = {
      id: relationshipsToOrganizationId,
      type: 'organization',
    };

    const createOperations: CreateRelationshipOptionModel[] =
      generateCreateOperations(relationships, parent, child);

    return { operations: createOperations };
  },

  generateRequestBodyForEditOwnershipStructure: (
    formValues: OwnershipStructureFormValuesModel,
    initialFormValues: OwnershipStructureFormValuesModel,
  ): ModifyRelationshipsBodyModel => {
    const { ownerships, relationshipTo, ownershipTemplate } = formValues;

    //--- Create operations ---
    const newOwnerships = ownerships.filter(
      (e) => !e.id && !e.isFromExistingEntry,
    );
    const createOperations: CreateRelationshipOptionModel[] = newOwnerships.map(
      (e) => {
        const parent: RelationshipMemberModel =
          getParentForShareholderRelationships(e) as RelationshipMemberModel;

        return {
          type: 'create',
          payload: {
            parent,
            relationships: [
              {
                child: relationshipTo,
                relationshipTemplateId: ownershipTemplate._id,
                additionalFields: ownershipTemplate.additionalFields.map(
                  (af) => ({
                    name: af.name,
                    type: af.type,
                    valueJSON: AdditionalFieldHelpers.formatToJSON(
                      af.type,
                      e.additionalFields[af.name].value,
                    ),
                    _id: af._id,
                  }),
                ),
              },
            ],
          },
        };
      },
    );

    //--- Create operations (from existing entries) ---
    const newOwnershipsFromExistingEntries = ownerships.filter(
      (e) => e.isFromExistingEntry,
    );
    const createOperationsFromExistingEntries: CreateRelationshipOptionModel[] =
      newOwnershipsFromExistingEntries.map((e) => {
        return {
          type: 'create',
          payload: {
            parent: {
              id:
                e.type === OnboardingEntryTypes.Contact
                  ? (e.contact?.id as string)
                  : (e.organization?.id as string),
              type: e.type,
            },
            relationships: [
              {
                child: relationshipTo,
                relationshipTemplateId: ownershipTemplate._id,
                additionalFields: ownershipTemplate.additionalFields.map(
                  (af) => ({
                    name: af.name,
                    type: af.type,
                    valueJSON: AdditionalFieldHelpers.formatToJSON(
                      af.type,
                      e.additionalFields[af.name].value,
                    ),
                    _id: af._id,
                  }),
                ),
              },
            ],
          },
        };
      });

    //--- Update operations ---
    const updateOperations: UpdateRelationshipOptionModel[] = ownerships.reduce<
      UpdateRelationshipOptionModel[]
    >((acc, next) => {
      if (next.id) {
        acc.push({
          type: 'update',
          payload: {
            relationshipId: next.relationshipId as string,
            additionalFields: ownershipTemplate.additionalFields.map((af) => ({
              name: af.name,
              type: af.type,
              valueJSON: AdditionalFieldHelpers.formatToJSON(
                af.type,
                next.additionalFields[af.name].value,
              ),
              _id: af._id,
            })),
          },
        });
      }
      return acc;
    }, []);

    //--- Remove operations ---
    const newOwnershipIds = formValues.ownerships.map((e) => e.relationshipId);
    const removeOperations: RemoveRelationshipOptionModel[] =
      initialFormValues.ownerships.reduce<RemoveRelationshipOptionModel[]>(
        (acc, next) => {
          if (
            next.relationshipId &&
            !newOwnershipIds.includes(next.relationshipId)
          ) {
            acc.push({
              type: 'remove',
              payload: {
                relationshipId: next.relationshipId as string,
              },
            });
          }
          return acc;
        },
        [],
      );

    return {
      operations: [
        ...createOperations,
        ...createOperationsFromExistingEntries,
        ...updateOperations,
        ...removeOperations,
      ],
    };
  },

  generateRequestBodyForNewUser: (
    formValues: NewUserFormValuesModel,
  ): ModifyRelationshipsBodyModel => {
    const { relationships, contact } = formValues;

    const newRelationships: NewRelationshipModel[] =
      relationships.map<NewRelationshipModel>((e) => {
        return {
          child: {
            id: e.child.id as string,
            type: e.child.type as OnboardingEntryTypes,
          },
          relationshipTemplateId: e.relationshipTemplate.id as string,
          additionalFields: e.additionalFields.map((af) => ({
            name: af.name,
            type: af.type,
            valueJSON: AdditionalFieldHelpers.formatToJSON(af.type, af.value),
            _id: af.id,
          })),
        };
      });

    const createOperation: CreateRelationshipOptionModel = {
      type: 'create',
      payload: {
        parent: {
          type: 'contact',
          model: {
            firstName: contact.firstName ? contact.firstName.trim() : '',
            middleName: contact.middleName ? contact.middleName.trim() : '',
            lastName: contact.lastName ? contact.lastName.trim() : '',
            isPEP: !!contact?.isPEP,
            pepInformation: contact?.isPEP
              ? String(contact?.pepInformation).trim()
              : '',
            phoneNumber: contact?.phone ? contact.phone : undefined,
            email: contact?.email ? contact.email : undefined,
            onlineAccess: contact?.isOnlineUser
              ? {
                  adminPermissionType:
                    !contact.adminPermissionType ||
                    contact.adminPermissionType ==
                      AdministrationPermissionTypes.LimitedAdmin
                      ? undefined
                      : contact.adminPermissionType,
                  permission: contact?.accountPermissions,
                }
              : undefined,
          },
        },
        relationships: [...newRelationships],
      },
    };

    return { operations: [createOperation] };
  },

  generateRequestBodyToCreateNode: (
    formValues: AddNewNodeFormValuesModel,
  ): ModifyRelationshipsBodyModel => {
    const { relationships, contact, organization, template } = formValues;

    const newRelationships: NewRelationshipModel[] =
      relationships.map<NewRelationshipModel>((e) => {
        return {
          child: {
            id: e.child.id as string,
            type: e.child.type as OnboardingEntryTypes,
          },
          relationshipTemplateId: e.relationshipTemplate.id as string,
          additionalFields: e.additionalFields.map((af) => ({
            name: af.name,
            type: af.type,
            valueJSON: AdditionalFieldHelpers.formatToJSON(af.type, af.value),
            _id: af.id,
          })),
        };
      });

    const createOperation: CreateRelationshipOptionModel = {
      type: 'create',
      payload: {
        parent:
          template.visibleFor == OnboardingEntryTypes.Contact
            ? {
                type: 'contact',
                model: {
                  firstName: contact?.firstName ? contact.firstName.trim() : '',
                  middleName: contact?.middleName
                    ? contact.middleName.trim()
                    : '',
                  lastName: contact?.lastName ? contact.lastName.trim() : '',
                  isPEP: !!contact?.isPEP,
                  pepInformation: contact?.isPEP
                    ? String(contact?.pepInformation).trim()
                    : '',
                  phoneNumber: contact?.phone ? contact.phone : undefined,
                  email: contact?.email ? contact.email : undefined,
                  onlineAccess: contact?.isOnlineUser
                    ? {
                        adminPermissionType:
                          !contact.adminPermissionType ||
                          contact.adminPermissionType ==
                            AdministrationPermissionTypes.LimitedAdmin
                            ? undefined
                            : contact.adminPermissionType,
                        permission: contact?.accountPermissions,
                      }
                    : undefined,
                },
              }
            : {
                type: 'organization',
                model: {
                  legalName: String(organization?.name).trim(),
                  isRegulated: !!organization?.isRegulated,
                  regulationCountry: organization?.isRegulated
                    ? organization?.regulatedCountries
                    : [],
                  keyRelationshipTemplateId: template.id as string,
                },
              },

        relationships: [...newRelationships],
      },
    };

    return { operations: [createOperation] };
  },

  generateRequestBodyForEditNode: (
    formValues: OnboardingNodeFormValuesModel,
    initialFormValues: OnboardingNodeFormValuesModel,
    addUserAccessData?: boolean,
  ): ModifyRelationshipsBodyModel => {
    const { relationships, disableNodeForm, keyRelationshipTemplateId } =
      formValues;
    // -- Create operations --
    let createOperations: CreateRelationshipOptionModel[] = [];
    const newRelationships = relationships.filter((e) => !e.id);

    if (newRelationships.length) {
      createOperations = newRelationships.map((e) => {
        return {
          type: 'create',
          payload: {
            parent: {
              id: e.parent.id as string,
              type: e.parent.type as OnboardingEntryTypes,
            },
            relationships: [
              {
                child: {
                  id: e.child.id as string,
                  type: e.child.type as OnboardingEntryTypes,
                },
                relationshipTemplateId: e.relationshipTemplate.id as string,
                additionalFields: e.additionalFields.map((af) => ({
                  name: af.name,
                  type: af.type,
                  valueJSON: AdditionalFieldHelpers.formatToJSON(
                    af.type,
                    af.value,
                  ),
                  _id: af.id,
                })),
              },
            ],
          },
        };
      });
    }

    // -- Update operations --
    let updateOperations: UpdateRelationshipOptionModel[] = [];
    if (formValues.relationships.length) {
      updateOperations = formValues.relationships.reduce<
        UpdateRelationshipOptionModel[]
      >((acc, next) => {
        if (next.id) {
          acc.push({
            type: 'update',
            payload: {
              relationshipId: next.id as string,
              additionalFields: next.additionalFields.reduce<
                AdditionalFieldAnswerModel[]
              >((acc, next) => {
                if (!next.isRemoved) {
                  acc.push({
                    name: next.name,
                    type: next.type,
                    valueJSON: AdditionalFieldHelpers.formatToJSON(
                      next.type,
                      next.value,
                    ),
                    _id: next.id,
                  });
                }
                return acc;
              }, []),
            },
          });
        }

        return acc;
      }, []);
    }

    // -- Update node --
    if (!disableNodeForm) {
      const node =
        formValues.entryType == OnboardingEntryTypes.Contact
          ? formValues.contact
          : formValues.organization;

      // To be able to update node data, we should send it in at least 1 update operation
      // TODO: this part needs to be updated according to the server updates, not the best solution
      if (node && formValues.entryType) {
        if (updateOperations.length) {
          updateOperations[0].payload.parent = getFormattedUpdateNodeData(
            node,
            formValues.entryType,
            addUserAccessData,
          );
        } else if (createOperations.length) {
          // TODO: add the ability to update node for create operations when API gets updated
          createOperations[0].payload.parent.model = getFormattedCreateNodeData(
            node,
            formValues.entryType,
            keyRelationshipTemplateId,
          );
        }
      }
    }

    // -- Delete operations --
    const relationshipIds = formValues.relationships.reduce<string[]>(
      (acc, next) => {
        if (next.id) {
          acc.push(next.id);
        }

        return acc;
      },
      [],
    );

    const deleteOperations: RemoveRelationshipOptionModel[] =
      initialFormValues.relationships.reduce<RemoveRelationshipOptionModel[]>(
        (acc, next) => {
          const isDeleted = next.id && !relationshipIds.includes(next.id);

          if (isDeleted) {
            acc.push({
              type: 'remove',
              payload: { relationshipId: next.id as string },
            });
          }

          return acc;
        },
        [],
      );

    return {
      operations: [
        ...createOperations,
        ...updateOperations,
        ...deleteOperations,
      ],
    };
  },
};

export { relationshipsAPIAdapter };
