import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { ApprovalGroupTemplateData } from '..';

// components
import { Table } from 'antd';

interface IProps {
  data: ApprovalGroupTemplateData;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('account_management');

  const tableColumns = useMemo(() => {
    return [
      { key: 'title', dataIndex: 'title', width: 170 },
      { key: 'value', dataIndex: 'value' },
    ];
  }, []);

  const tableData = useMemo(() => {
    return [
      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.name',
        ),
        value: data.groupName,
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.members',
        ),
        value: data.participants,
      },

      {
        title: t(
          'pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.description',
        ),
        value: data.description,
      },
    ];
  }, [data]);

  return (
    <Table
      bordered
      size="middle"
      showHeader={false}
      pagination={false}
      scroll={{ x: 'max-content' }}
      columns={tableColumns}
      dataSource={tableData}
    />
  );
};

export default InnerTemplate;
