import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { ITransferDocument } from './transfersAPI';
import { TransactionSourceTypes } from 'enums/accounting/transfers';
import { AccountManagementApprovalStatuses } from '../../enums/approvalManagement/approvalManagement';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  TransactionBase,
  IApprovalMatrixRecord,
  PendingTransaction,
  TransactionDocument,
} from '../../typings/accounting/transaction';

interface GetPendingTransactionsParamsModel extends FetchParamsModel {
  accountNumber: string;
  status: string;
}

interface IGetApprovedTransactionsParams extends FetchParamsModel {
  accountNumber: string;
  fromDate?: number;
  toDate?: number;
}

interface ITransactionApprovalsStatusResponse {
  id: string;
  createdAt: string;
  entryData: string;
  entryType: string;
  status: AccountManagementApprovalStatuses;
  statusName: string;
  templateCode: string;
  templateName: string;
  approvalProgressStateSets: { progressStateItems: IApprovalMatrixRecord[] }[];
}

export type WireTransferTemplateFromQuery = {
  id: number;
  templateName: string;
  templateTypeId: number;
  templateTypeName: string;
  templateTypeDescription: string;
  clientGroupId: number;
  isOnlyMe: boolean;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;
  beneficiaryCountry: string;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  beneficiaryAddress3: string;
  beneficiaryBankCodeId: number;
  beneficiaryBankCode: string;
  beneficiaryBankCodeType: number;
  beneficiaryBankName: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress1: string;
  beneficiaryBankAddress2: string;
  beneficiaryBankAddress3: string;
  intermediaryBankCodeId: number;
  intermediaryBankCode: string;
  intermediaryBankCodeType: number;
  intermediaryBankName: string;
  intermediaryBankCountry: string;
  intermediaryBankAddress1: string;
  intermediaryBankAddress2: string;
  intermediaryBankAddress3: string;
  reference: string;
  beneficiaryBankCodeTypeName: string;
  intermediaryBankCodeTypeName: string;
  beneficiaryCity: string;
  beneficiaryDistrict: string;
  beneficiaryPostCode: string;
  derivedFrom: string;
  processingType: string;
  beneficiaryBankCity: string;
  beneficiaryBankDistrict: string;
  beneficiaryBankPostCode: string;
  intermediaryBankCity: string;
  intermediaryBankDistrict: string;
  intermediaryBankPostCode: string;
};

export type InternalTransferPrefillDataFromQuery = {
  amount: number;
  currencyId: number;
  currencyIsoCode: string;
  purpose: string;
  fromAccountReference: string;
  beneficiaryReference: string;

  fromAccount: {
    accountNumber: string;
    accountName: string;
    currencyId: number;
    currencyIsoCode: string;
    balance: number;
    availableBalance: number;
  };

  beneficiaryAccount: {
    accountNumber: string;
    accountName: string;
    currencyId: number;
    currencyIsoCode: string;
    balance: number;
    availableBalance: number;
  };

  beneficiaryTemplate: {
    id: number;
    templateName: string;
    templateTypeId: number;
    templateTypeName: string;
    templateTypeDescription: string;
    clientGroupId: number;
    isOnlyMe: boolean;
    accountName: string;
    accountNumber: string;
    recipientReference: string;
    currencyIsoCode: string;
    currencyId: number;
  };
};

export type WireTransferPrefillDataFromQuery = {
  amount: number;
  currencyId: number;
  currencyIsoCode: string;
  purpose: string;
  fromAccountReference: string;
  fromAccount: {
    accountNumber: string;
    accountName: string;
    currencyIsoCode: string;
    currencyId: number;
    balance: number;
    availableBalance: number;
  };
  beneficiaryTemplate: {
    id: number;
    templateName: string;
    templateTypeId: number;
    templateTypeName: string;
    templateTypeDescription: string;
    clientGroupId: number;
    isOnlyMe: boolean;
    beneficiaryAccountName: string;
    beneficiaryAccountNumber: string;
    beneficiaryCountry: string;
    beneficiaryAddress1: string;
    beneficiaryAddress2: string;
    beneficiaryAddress3: string;
    beneficiaryBankCodeId: number;
    beneficiaryBankCode: string;
    beneficiaryBankCodeType: number;
    beneficiaryBankName: string;
    beneficiaryBankCountry: string;
    beneficiaryBankAddress1: string;
    beneficiaryBankAddress2: string;
    beneficiaryBankAddress3: string;
    intermediaryBankCodeId: number;
    intermediaryBankCode: string;
    intermediaryBankCodeType: number;
    intermediaryBankName: string;
    intermediaryBankCountry: string;
    intermediaryBankAddress1: string;
    intermediaryBankAddress2: string;
    intermediaryBankAddress3: string;
    reference: string;
    beneficiaryBankCodeTypeName: string;
    intermediaryBankCodeTypeName: string;
    beneficiaryCity: string;
    beneficiaryDistrict: string;
    beneficiaryPostCode: string;
    derivedFrom: string;
    processingType: string;
    beneficiaryBankCity: string;
    beneficiaryBankDistrict: string;
    beneficiaryBankPostCode: string;
    intermediaryBankCity: string;
    intermediaryBankDistrict: string;
    intermediaryBankPostCode: string;
  };
};

export type BankDataFromQuery = {
  code: string;
  codeType: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  branch: string;
  postCode: string;
  country: string;
  countryCode: string;
  district: string;
};

export type JournalEntryFromQuery = {
  sourceType: TransactionSourceTypes;
  sourceTypeName: string;
  statusId: number;
  statusName: string;
  purpose: string;
  currencyId: number;
  currencyIsoCode: string;
  amount: number;
  postingDate: number;
  valueDate: number;
  creationDate: number;
  requestedById: string;
  workflowId: string;
  workflowExpirationDate: number;
  repaired: boolean;
  isSender: boolean;
  isTemplateAccessible: boolean;
  requestedByName: string;
  relatedDocuments: TransactionDocument[];
  templateId: number;
  templateName: string;
  transactionNumber: string;
  senderReference: string;

  orderingCustomer: {
    accountName: string;
    accountNumber: string;
    senderReference: string;
    address1: string;
    address2: string;
    address3: string;
    sendingInstitution: BankDataFromQuery | null;
    orderingInstitution: BankDataFromQuery | null;
    senderCorrespondent: BankDataFromQuery | null;
  };

  beneficiaryCustomer: {
    accountName: string;
    accountNumber: string;
    countryCode: string;
    city: string;
    postCode: string;
    district: string;
    address1: string;
    address2: string;
    address3: string;
    beneficiaryReference: string;
    remittanceInformationLine1: string;
    remittanceInformationLine2: string;
    remittanceInformationLine3: string;
    remittanceInformationLine4: string;
    intermediaryInstitution: BankDataFromQuery | null;
    accountWithInstitution: BankDataFromQuery | null;
    repairedBeneficiaryAccountName: string;
    repairedBeneficiaryAccountNumber: string;
  };
};

const transactionsAPI = {
  fetchJournalEntries: (params: IGetApprovedTransactionsParams) => {
    return APIService.get<FetchResponseModel<TransactionBase>>(
      `${APIConfig.accountingApi}/journals/entries`,
      { params },
    ).then(({ data }) => data);
  },

  fetchPendingTransactions: (params: GetPendingTransactionsParamsModel) => {
    return APIService.get<FetchResponseModel<PendingTransaction>>(
      `${APIConfig.accountingApi}/transactions/pending`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchJournalEntryById: (transactionId: number) => {
    return APIService.get<JournalEntryFromQuery>(
      `${APIConfig.accountingApi}/transactions/journal-entry/${transactionId}`,
    ).then(({ data }) => data);
  },

  fetchTransactionById: (transactionId: number) => {
    return APIService.get<JournalEntryFromQuery>(
      `${APIConfig.accountingApi}/transactions/${transactionId}`,
    ).then(({ data }) => data);
  },

  fetchTransactionApprovalsStatus: (workflowId: string) => {
    return APIService.get<ITransactionApprovalsStatusResponse>(
      `${APIConfig.approvalApi}/transaction/workflows/${workflowId}/status`,
    ).then(({ data }) => data);
  },

  uploadDocumentToApprovedTransaction: (
    body: ITransferDocument[],
    journalEntryId: number,
  ) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/journals/entries/${journalEntryId}/documents/upload`,
      body,
    ).then(({ data }) => data);
  },

  cancelTransaction: (transactionId: number) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/transactions/${transactionId}/cancel`,
    ).then(({ data }) => data);
  },

  fetchInternalTransferPrefillData: (
    journalEntryId?: string,
    transactionId?: string,
  ) => {
    return APIService.get<InternalTransferPrefillDataFromQuery>(
      `${APIConfig.accountingApi}/transactions/internal/prefill`,
      {
        params: { journalEntryId, transactionId },
      },
    ).then(({ data }) => data);
  },

  fetchWireTransferPrefillData: (
    journalEntryId?: string,
    transactionId?: string,
  ) => {
    return APIService.get<WireTransferPrefillDataFromQuery>(
      `${APIConfig.accountingApi}/transactions/international/prefill`,
      {
        params: { journalEntryId, transactionId },
      },
    ).then(({ data }) => data);
  },
};

export { transactionsAPI };
