import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { useFormikContext } from 'formik';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FormValuesModel, TableColumnBaseModel } from '.';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import IconSVG from '@core_components/IconSVG';
import FormField from '@core_components/FormField';
import FormInputNumber from '@common_components/Form/FormInputNumber';
import { Row, Col, Radio } from 'antd';
import { ReactComponent as DeleteIcon } from 'resources/icons/remix-icons/delete-bin-6-line.svg';

interface IProps extends TableColumnBaseModel {
  isDisabled: boolean;
  disabledFrom: boolean;
  canAddLimit: boolean;
  deleteCallback: () => void;
  onAddLimitClick: () => void;
}

const HeaderCell = ({
  dataIndex,
  title,
  colIndex,
  isDisabled,
  disabledFrom,
  deleteCallback,
  onAddLimitClick,
  canAddLimit,
}: IProps) => {
  const { t } = useTranslation('account_management');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const handleRadioGroupChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setFieldValue(`limits.${colIndex}.toInfinite`, value === 'infinite');
  };

  const renderLimitsCell = () => {
    const isToInfinite = values.limits[colIndex].toInfinite;
    const isFirstColumn = colIndex === 0;

    return (
      <th style={{ position: 'relative', minWidth: 380 }}>
        <StyledTitleRow>
          <TitleWrapperCol flex="auto">
            <Text variant="body1">{title}</Text>
          </TitleWrapperCol>

          <StyledButtonsWrapper flex="160px">
            {!isDisabled && (
              <Button
                onClick={onAddLimitClick}
                disabled={!canAddLimit || isToInfinite}
              >
                {t(
                  'approval_rules.bank_accounts.approval_rule_modal.add_range_button',
                )}
              </Button>
            )}
            {!isDisabled && colIndex > 0 && (
              <IconSVG
                component={DeleteIcon}
                color={colorsTheme.colorError}
                onClick={() => deleteCallback()}
              />
            )}
          </StyledButtonsWrapper>
        </StyledTitleRow>

        <Col flex="auto" style={{ minWidth: 290 }}>
          <FormField
            name={`limits.${colIndex}.from`}
            label={t(
              'approval_rules.bank_accounts.approval_rule_modal.form_fields.from_range_value',
            )}
            labelCol={{}}
            disabled={disabledFrom || isDisabled || isFirstColumn}
            component={FormInputNumber}
            additionalProps={{ min: 0, style: { width: '100%' } }}
          />

          <Row>
            <Col flex="48px" style={{ lineHeight: '31px' }}>
              {`${t('approval_rules.bank_accounts.approval_rule_modal.form_fields.to_range_value')}:`}
            </Col>
            <Col flex="auto">
              <StyledRadioGroup
                value={isToInfinite ? 'infinite' : 'to-value'}
                disabled={isDisabled}
                onChange={handleRadioGroupChange}
              >
                <StyledRadio value="infinite">
                  {t(
                    'approval_rules.bank_accounts.approval_rule_modal.form_fields.and_above_range_option',
                  )}
                </StyledRadio>
                <StyledAmountRadio value="to-value">
                  <FormField
                    name={`limits.${colIndex}.to`}
                    disabled={isDisabled || isToInfinite}
                    component={FormInputNumber}
                    additionalProps={{ min: 0, style: { width: '100%' } }}
                  />
                </StyledAmountRadio>
              </StyledRadioGroup>
            </Col>
          </Row>
        </Col>
      </th>
    );
  };

  const renderContent = () => {
    // First column, where we need to render only title
    if (dataIndex === 'conditions') {
      return <th>{title}</th>;
    }

    return renderLimitsCell();
  };

  return <>{renderContent()}</>;
};

const StyledButtonsWrapper = styled(Col)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledRadio = styled(Radio)`
  margin-right: 0px;
  min-height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;

  span:nth-child(2) {
    padding-right: 0px;
    flex: 1;
  }
`;

const StyledAmountRadio = styled(StyledRadio)`
  align-items: flex-start;

  span:nth-child(1) {
    margin-top: 10px;
  }
`;

const StyledTitleRow = styled(Row)`
  margin-bottom: 10px;
  min-height: 32px;
`;

const TitleWrapperCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export default HeaderCell;
