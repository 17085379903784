import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface RangeOptionModel {
  min: number | null;
  max: number | null;
}

export type EstimatedAmountFundsRangeKeys =
  | 'amount1'
  | 'amount2'
  | 'amount3'
  | 'amount4'
  | 'amount5';

export const EstimatedAmountFundsRangeOptions: Record<
  EstimatedAmountFundsRangeKeys,
  RangeOptionModel
> = {
  amount1: {
    min: null,
    max: 50000,
  },
  amount2: {
    min: 50000,
    max: 249999,
  },
  amount3: {
    min: 250000,
    max: 499999,
  },
  amount4: {
    min: 500000,
    max: 999999,
  },
  amount5: {
    min: 1000000,
    max: null,
  },
};

export type IProps = SelectProps;

const EstimatedAmountFundsRangeSelect = (props: IProps) => {
  const { t } = useTranslation('common');

  const options = useMemo<SelectOption[]>(() => {
    function getOptionLabel(key: EstimatedAmountFundsRangeKeys) {
      const { min, max } = EstimatedAmountFundsRangeOptions[key];

      if (typeof min !== 'number' || max === min) {
        const formattedMaxValue = max?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

        return `${t('under')} ${formattedMaxValue}`;
      }

      if (typeof max !== 'number') {
        const formattedMinValue = min?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

        return `${formattedMinValue} ${t('or_more')}`;
      }

      const formattedMinValue = min?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      const formattedMaxValue = max?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return `${formattedMinValue} ${t('to')} ${formattedMaxValue}`;
    }

    return Object.keys(EstimatedAmountFundsRangeOptions).map((key) => ({
      id: key,
      label: getOptionLabel(key as EstimatedAmountFundsRangeKeys),
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
};

export default EstimatedAmountFundsRangeSelect;
