export interface IApiInstance {
  authPublicApi: string;
  authApi: string;
  crmApi: string;
  crmPublicApi: string;
  accountingApi: string;
  dmsApi: string;
  approvalApi: string;
  messagingApi: string;
  notificationsApi: string;
  serviceApi: string;
}

const dev: IApiInstance = {
  authPublicApi:
    'https://api-dev.tenetos.dev/facade-client/public/authentication',
  authApi: 'https://api-dev.tenetos.dev/facade-client/client/authentication',
  crmApi: 'https://api-dev.tenetos.dev/facade-client/client/crm',
  crmPublicApi: 'https://api-dev.tenetos.dev/facade-client/public/crm',
  approvalApi: 'https://api-dev.tenetos.dev/facade-client/client/approval',
  messagingApi: 'https://api-dev.tenetos.dev/facade-client/client/messaging',
  notificationsApi:
    'https://api-dev.tenetos.dev/facade-client/client/notification',
  accountingApi: 'https://api-dev.tenetos.dev/facade-client/client/accounting',
  dmsApi: 'https://api-dev.tenetos.dev/facade-client/client/documents',
  serviceApi: 'https://api-dev.tenetos.dev/facade-client/client/service',
};

const qa: IApiInstance = {
  authPublicApi:
    'https://api-qa.tenetos.dev/facade-client/public/authentication',
  authApi: 'https://api-qa.tenetos.dev/facade-client/client/authentication',
  crmApi: 'https://api-qa.tenetos.dev/facade-client/client/crm',
  crmPublicApi: 'https://api-qa.tenetos.dev/facade-client/public/crm',
  approvalApi: 'https://api-qa.tenetos.dev/facade-client/client/approval',
  messagingApi: 'https://api-qa.tenetos.dev/facade-client/client/messaging',
  notificationsApi:
    'https://api-qa.tenetos.dev/facade-client/client/notification',
  accountingApi: 'https://api-qa.tenetos.dev/facade-client/client/accounting',
  dmsApi: 'https://api-qa.tenetos.dev/facade-client/client/documents',
  serviceApi: 'https://api-qa.tenetos.dev/facade-client/client/service',
};

const stg: IApiInstance = {
  authPublicApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/public/authentication',
  authApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/client/authentication',
  crmApi: 'https://api-tenetos-stg.azure-api.net/facade-client/client/crm',
  crmPublicApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/public/crm',
  approvalApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/client/approval',
  messagingApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/client/messaging',
  notificationsApi:
    'https://api-dev.tenetos.dev/facade-client/client/notification',
  accountingApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/client/accounting',
  dmsApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/client/documents',
  serviceApi:
    'https://api-tenetos-stg.azure-api.net/facade-client/client/service',
};

const uat: IApiInstance = {
  authPublicApi:
    'https://api-uat.tenetos.dev/facade-client/public/authentication',
  authApi: 'https://api-uat.tenetos.dev/facade-client/client/authentication',
  crmApi: 'https://api-uat.tenetos.dev/facade-client/client/crm',
  crmPublicApi: 'https://api-uat.tenetos.dev/facade-client/public/crm',
  approvalApi: 'https://api-uat.tenetos.dev/facade-client/client/approval',
  messagingApi: 'https://api-uat.tenetos.dev/facade-client/client/messaging',
  notificationsApi:
    'https://api-uat.tenetos.dev/facade-client/client/notification',
  accountingApi: 'https://api-uat.tenetos.dev/facade-client/client/accounting',
  dmsApi: 'https://api-uat.tenetos.dev/facade-client/client/documents',
  serviceApi: 'https://api-uat.tenetos.dev/facade-client/client/service',
};

const prod: IApiInstance = {
  authPublicApi: 'https://api.tenet.ky/facade-client/public/authentication',
  authApi: 'https://api.tenet.ky/facade-client/client/authentication',
  crmApi: 'https://api.tenet.ky/facade-client/client/crm',
  crmPublicApi: 'https://api.tenet.ky/facade-client/public/crm',
  approvalApi: 'https://api.tenet.ky/facade-client/client/approval',
  messagingApi: 'https://api.tenet.ky/facade-client/client/messaging',
  notificationsApi: 'https://api.tenet.ky/facade-client/client/notification',
  accountingApi: 'https://api.tenet.ky/facade-client/client/accounting',
  dmsApi: 'https://api.tenet.ky/facade-client/client/documents',
  serviceApi: 'https://api.tenet.ky/facade-client/client/service',
};

let config: IApiInstance = stg;

switch (process.env.REACT_APP_STAGE) {
  case 'local':
    config = dev;
    break;

  case 'dev':
    config = dev;
    break;

  case 'qa':
    config = qa;
    break;

  case 'stg':
    config = stg;
    break;

  case 'uat':
    config = uat;
    break;

  case 'prod':
    config = prod;
    break;
}

export default {
  // Add common config values here
  ...config,
};
