import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { IBankCodeType, IBank } from '../../typings/accounting/bank';
import { FetchResponseModel } from '../../typings/common';

interface IFetchBanksParams {
  bankCode: string;
  bankCodeTypeId: number;
  limit: number;
  offset: number;
}

const banksAPI = {
  fetchBanks: (fetchBanksParams: IFetchBanksParams) => {
    return APIService.get(`${APIConfig.accountingApi}/banks/codes/suggest`, {
      params: fetchBanksParams,
    }).then<FetchResponseModel<IBank>>(({ data }) => data);
  },

  fetchBankCodeTypes() {
    return APIService.get(`${APIConfig.accountingApi}/banks/codes/types`).then<
      FetchResponseModel<IBankCodeType>
    >(({ data }) => data);
  },
};

export { banksAPI };
