import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../../../resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import Button from '../../../../../../components/DesignSystem/Core/Button';
import IconSVG from '../../../../../../components/DesignSystem/Core/IconSVG';
import { ReactComponent as ErrorIcon } from '../../../../../../resources/icons/remix-icons/error-warning-line.svg';

interface IProps {
  onRetryClick: () => void;
}

const ErrorWithRetryButton = ({ onRetryClick }: IProps) => {
  const { t } = useTranslation('auth');

  return (
    <>
      <StyledIconSVG
        component={ErrorIcon}
        color={colorsTheme.colorWhite}
        size="large"
      />

      <StyledText variant="body1" gutterBottom>
        {t('two_factor_authentication.fido.error_in_registration_procedure')}
      </StyledText>

      <Button size="large" fullWidth onClick={onRetryClick}>
        {t('two_factor_authentication.fido.retry_button')}
      </Button>
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

export default ErrorWithRetryButton;
