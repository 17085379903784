import React from 'react';

// helpers
import { FormValuesModel } from '..';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

// components
import TravelNotificationTable from './TravelNotificationsTable';

const InnerForm = () => {
  const { values } = useFormikContext<FormValuesModel>();

  const handleAddNewCountry = (arrayHelpers: FieldArrayRenderProps) => {
    const initialDepartureDate =
      values.countries[values.countries.length - 1].returnDate;

    arrayHelpers.push({
      country: null,
      departureDate: initialDepartureDate,
      returnDate: null,
    });
  };

  const renderFormFieldArray = (arrayHelpers: FieldArrayRenderProps) => {
    return (
      <TravelNotificationTable
        handleDelete={(index: number) => arrayHelpers.remove(index)}
        handleAdd={() => handleAddNewCountry(arrayHelpers)}
      />
    );
  };

  return (
    <FieldArray
      name="countries"
      validateOnChange
      render={renderFormFieldArray}
    />
  );
};

export default InnerForm;
