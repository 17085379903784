import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { approvalTransactionsAPI } from '../../../../../../../../api/approvalManagement/approvalTransactionsAPI';

// components
import ConfirmationWithReason, {
  FormValuesModel,
} from '../../../../../../ConfirmationWithReason';

enum ACTION_TYPE {
  APPROVE = 1,
  REJECT,
}

interface IProps {
  selectedActionType: ACTION_TYPE | null;
  workflowId?: string;
  handleModalClose: (wasSubmitted?: boolean) => void;
}

const ReviewTransactionModal = ({
  selectedActionType,
  workflowId,
  handleModalClose,
}: IProps) => {
  const { t } = useTranslation('accounts');

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return { reason: '' };
  }, []);

  const modalDialogTitle = useMemo(() => {
    return selectedActionType === ACTION_TYPE.APPROVE
      ? t('transaction_details_modal.pending.approve_confirmation_message')
      : t('transaction_details_modal.pending.reject_confirmation_message');
  }, [selectedActionType]);

  const handleSubmit = async (values: FormValuesModel) => {
    const { reason } = values;

    if (selectedActionType === ACTION_TYPE.APPROVE) {
      await approveTransaction(reason);
    } else {
      await rejectTransaction(reason);
    }
  };

  const approveTransaction = async (reason: string) => {
    if (!workflowId) {
      return;
    }

    await approvalTransactionsAPI.approveTransaction(workflowId, reason);
    message.success(
      t('transaction_details_modal.pending.success_approve_message'),
    );
  };

  const rejectTransaction = async (reason: string) => {
    if (!workflowId) {
      return;
    }

    await approvalTransactionsAPI.rejectTransaction(workflowId, reason);
    message.success(
      t('transaction_details_modal.pending.success_reject_message'),
    );
  };

  return (
    <ConfirmationWithReason
      title={modalDialogTitle}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      isVisible={!!selectedActionType}
      closeCallback={handleModalClose}
      submitButtonText={
        selectedActionType === ACTION_TYPE.APPROVE
          ? t('approve', { ns: 'common' })
          : t('reject', { ns: 'common' })
      }
    />
  );
};

export default ReviewTransactionModal;
