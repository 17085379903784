import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { OnboardingStatusModel } from 'typings/onboarding/onboarding';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';

// components
import AdministrationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/AdministrationForm';

interface IProps {
  onAction: (key: ActionKeys | null) => void;
  isViewOnly: boolean;
  onboardingData: OnboardingStatusModel;
}

const Administration = ({ isViewOnly, onboardingData, onAction }: IProps) => {
  const { t } = useTranslation(['server_errors', 'onboarding']);
  const initialFormValues = useMemo<FormValuesModel>(() => {
    return { isSave: null, submitActionType: null };
  }, []);

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.submitActionType === 'submit') {
      await onboardingAPI.submitAdministrationStep();
    }

    await onAction(values.submitActionType);
  };

  const handleOnSubmitError = (error: ErrorFromServer) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1011026':
        {
          const incompleteSections: string[] = [];
          const incompleteStepsFromResponse: Array<
            | 'transactionApprovalGroups'
            | 'transactionApprovalRules'
            | 'accountManagementApprovalGroups'
            | 'accountManagementApprovalRules'
          > = error.response?.data?.details?.incompleteSteps || [];

          if (
            incompleteStepsFromResponse.includes('transactionApprovalGroups') ||
            incompleteStepsFromResponse.includes('transactionApprovalRules')
          ) {
            incompleteSections.push(
              t('administration.transaction_rules.title', { ns: 'onboarding' }),
            );
          }

          if (
            incompleteStepsFromResponse.includes(
              'accountManagementApprovalGroups',
            ) ||
            incompleteStepsFromResponse.includes(
              'accountManagementApprovalRules',
            )
          ) {
            incompleteSections.push(
              t('administration.administration_rules.title', {
                ns: 'onboarding',
              }),
            );
          }

          ErrorHandlerService.displayErrorMessage(
            t(errorCode, { sections: incompleteSections.join(', ') }),
          );
        }
        break;

      default: {
        ErrorHandlerService.handleError(error);
      }
    }
  };

  return (
    <AdministrationForm
      disabled={isViewOnly}
      onSubmit={handleSubmit}
      onSubmitError={handleOnSubmitError}
      onboardingData={onboardingData}
      initialValues={initialFormValues}
    />
  );
};

export default Administration;
