import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { ApprovalEntryNewModel } from '../../../../../../../typings/approvalManagement/accountManagement';

// constants
import { ApprovalWorkflowEntryTypes } from '../../../../../../../enums/approvalManagement/approvalManagement';

// components
import SectionIntro from '../../../../../../../components/DesignSystem/Common/Texts/SectionIntro';
import BankAccount, { BankAccountTemplateData } from './Templates/BankAccount';
import ClientGroup, {
  ClientGroupTemplateDataModel,
} from './Templates/ClientGroup';
import ApprovalGroup, {
  ApprovalGroupTemplateData,
} from './Templates/ApprovalGroup';
import CircleService, {
  CircleServiceTemplateDataModel,
} from './Templates/CirlceService';
import TransactionRule, {
  TransactionRuleTemplateData,
} from './Templates/TransactionRule';
import AccountManagementApprovalRule, {
  AccountManagementApprovalRuleTemplateData,
} from './Templates/AccountManagementApprovalRule';

interface IProps {
  approvalEntry: ApprovalEntryNewModel;
}

const ChangeDifferenceSection = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderContent = () => {
    switch (approvalEntry.entry.type) {
      case ApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_GROUP:
      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_GROUP:
        return (
          <ApprovalGroup
            approvalEntry={
              approvalEntry as ApprovalEntryNewModel<ApprovalGroupTemplateData>
            }
          />
        );

      case ApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_RULE:
        return (
          <TransactionRule
            approvalEntry={
              approvalEntry as ApprovalEntryNewModel<TransactionRuleTemplateData>
            }
          />
        );

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_RULE:
        return (
          <AccountManagementApprovalRule
            approvalEntry={
              approvalEntry as ApprovalEntryNewModel<AccountManagementApprovalRuleTemplateData>
            }
          />
        );

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_NEW_BANKACCOUNT:
        return (
          <BankAccount
            approvalEntry={
              approvalEntry as ApprovalEntryNewModel<BankAccountTemplateData>
            }
          />
        );

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_UPDATE_CLIENTGROUP:
        return (
          <ClientGroup
            approvalEntry={
              approvalEntry as ApprovalEntryNewModel<ClientGroupTemplateDataModel>
            }
          />
        );

      case ApprovalWorkflowEntryTypes.ACCOUNT_MNGT_CIRCLE_INTEGRATION:
        return (
          <CircleService
            approvalEntry={
              approvalEntry as ApprovalEntryNewModel<CircleServiceTemplateDataModel>
            }
          />
        );
    }
  };

  return (
    <>
      <SectionIntro
        titleVariant="h4"
        title={t(
          'pending_approvals.approval_details_modal.changes_section.title',
        )}
      />
      {renderContent()}
    </>
  );
};

export default ChangeDifferenceSection;
