import React, { useEffect, useState } from 'react';

// Helpers & Utils
import useUserAccess from '../../hooks/useUserAccess';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// Components
import TransferTemplatesTable from '../../modules/Transfers/Templates/TransferTemplatesTable';
import AddTransferTemplateButton from '../../modules/Transfers/Templates/AddTransferTemplateButton';

const TransferTemplates = () => {
  const [updateTableTrigger, updateTable] = useState({});
  const [hasCreateTemplateAccess] = useUserAccess([
    'Transfers_TransferTemplates_Create',
  ]);
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();

  useEffect(() => {
    if (hasCreateTemplateAccess) {
      setPageLayoutOptions((prev) => ({
        ...prev,
        extra: () => (
          <AddTransferTemplateButton onAdd={() => updateTable({})} />
        ),
      }));
    }

    return () => clearContext();
  }, [hasCreateTemplateAccess, clearContext, setPageLayoutOptions]);

  return <TransferTemplatesTable updateTableTrigger={updateTableTrigger} />;
};

export default TransferTemplates;
