import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import FromNewEntry from './FromNewEntry';
import FromExistingEntry from './FromExistingEntry';
import TrueFalseSelect from '../../../../FormComponents/SelectInputs/TrueFalseSelect';

const InnerForm = () => {
  const { t } = useTranslation('account_management');
  const { values } = useFormikContext<FormValuesModel>();

  const renderContent = () => {
    if (values.isFromExistingEntry === null) {
      return null;
    }

    return values.isFromExistingEntry ? (
      <FromExistingEntry />
    ) : (
      <FromNewEntry />
    );
  };

  return (
    <>
      <FormField
        name="isFromExistingEntry"
        label={t('client_profile.ownership_modal.new.entry_type')}
        component={TrueFalseSelect}
      />
      {renderContent()}
    </>
  );
};

export default InnerForm;
