import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';

// components
import { Layout } from 'antd';

interface IProps {
  transparent?: boolean;
}

const FooterBar = ({ transparent = false }: IProps) => {
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <FooterWrapper transparent={transparent ? 1 : 0}>
      © {currentYear} Tenet Bank Ltd.
    </FooterWrapper>
  );
};

// Used a number for transparent prop because if I use boolean react shows warning in console
const FooterWrapper = styled(Layout.Footer)<{ transparent: number }>`
  color: ${({ theme }) => theme.colorLightD1};
  padding: ${({ theme }) => theme.containerPadding};
  margin-top: ${({ theme }) => theme.footerMargin};
  background: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.colorDarkD1};
`;

export default FooterBar;
