import React from 'react';

// helpers
import styled from 'styled-components';
import { colorsTheme } from '../../../resources/theme/styled/colors';
import { StyledComponentProps } from '../../../typings/common';

// components
import Text from '@core_components/Text';
import IconSVG from '../../DesignSystem/Core/IconSVG';
import DivAlignCenter from '../DivAlignCenter';
import { ReactComponent as SharedDataIcon } from '../../../resources/icons/remix-icons/share-line.svg';

interface IProps extends StyledComponentProps {
  text: string;
}

const SharedIconWithText = ({ text, ...rest }: IProps) => {
  return (
    <DivAlignCenter {...rest}>
      <StyledIconSVG
        component={SharedDataIcon}
        color={colorsTheme.colorPrimary}
        size="middle"
      />
      <Text color={colorsTheme.colorLight}>{text}</Text>
    </DivAlignCenter>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default SharedIconWithText;
