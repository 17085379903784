// helpers
import { PermissionedAccessModel } from '.';

// Permission keys
export type TransfersPermissionKeys =
  | 'Transfers_View'
  | 'Transfers_InternalTransfer_Create'
  | 'Transfers_WireTransfer_Create'
  | 'Transfers_BlockchainTransfer_Create'
  | 'Transfers_TransferTemplates_View'
  | 'Transfers_TransferTemplates_Create'
  | 'Transfers_InternalTransferTemplate_Create'
  | 'Transfers_WireTransferTemplate_Create'
  | 'Transfers_BlockchainTransferTemplate_Create'
  | 'Transfers_BlockchainWireTransferTemplate_Create';

// Permission config
export const TransfersPermissions: Record<
  TransfersPermissionKeys,
  PermissionedAccessModel
> = {
  Transfers_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:Transaction:Create' },
      // { permissionKey: 'Accounting:InternalTransferTemplate:Read' },
      // { permissionKey: 'Accounting:InternationalTransferTemplate:Read' },
      // { permissionKey: 'Accounting:BlockchainTransferTemplate:Read' },
      { permissionKey: 'Accounting:InternalTransferTemplate:Create' },
      { permissionKey: 'Accounting:InternationalTransferTemplate:Create' },
      { permissionKey: 'Accounting:BlockchainTransferTemplate:Create' },
    ],
  },

  Transfers_InternalTransfer_Create: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:Currency:Read' },
      { permissionKey: 'Accounting:Transaction:Create' },
    ],
  },

  Transfers_WireTransfer_Create: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:Currency:Read' },
      { permissionKey: 'Accounting:Transaction:Create' },
    ],
  },

  Transfers_BlockchainTransfer_Create: {
    requiredAllPermissions: true,
    flag: 'IS_CIRCLE_CONNECTED',
    permissions: [
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:Currency:Read' },
      { permissionKey: 'Accounting:Transaction:Create' },
    ],
  },

  Transfers_TransferTemplates_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:InternalTransferTemplate:Read' },
      { permissionKey: 'Accounting:InternationalTransferTemplate:Read' },
      { permissionKey: 'Accounting:BlockchainTransferTemplate:Read' },
      { permissionKey: 'Accounting:InternalTransferTemplate:Create' },
      { permissionKey: 'Accounting:InternationalTransferTemplate:Create' },
      { permissionKey: 'Accounting:BlockchainTransferTemplate:Create' },
    ],
  },

  Transfers_TransferTemplates_Create: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:InternalTransferTemplate:Create' },
      { permissionKey: 'Accounting:InternationalTransferTemplate:Create' },
      { permissionKey: 'Accounting:BlockchainTransferTemplate:Create' },
    ],
  },

  Transfers_InternalTransferTemplate_Create: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:InternalTransferTemplate:Create' },
    ],
  },

  Transfers_WireTransferTemplate_Create: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:InternationalTransferTemplate:Create' },
    ],
  },

  Transfers_BlockchainWireTransferTemplate_Create: {
    flag: 'IS_CIRCLE_CONNECTED',
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:InternationalTransferTemplate:Create' },
    ],
  },

  Transfers_BlockchainTransferTemplate_Create: {
    flag: 'IS_CIRCLE_CONNECTED',
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:BlockchainTransferTemplate:Create' },
    ],
  },
};
