import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../DesignSystem/Core/IconSVG';
import { Tooltip } from 'antd';
import { ReactComponent as DocumentReviewIcon } from '../../../../resources/icons/remix-icons/time-line.svg';

type ApprovalType = 'account_management' | 'internal';

interface IProps {
  approvalType: ApprovalType;
  showIcon?: boolean;
}

const PendingApprovalTooltip = memo(({ approvalType, showIcon }: IProps) => {
  const { t } = useTranslation('account_management');

  const title = t(`review_notification.${approvalType}.title`);
  const tooltipText = t(`review_notification.${approvalType}.description`);

  return (
    <Tooltip title={tooltipText} placement="bottom">
      {showIcon ? (
        <IconSVG
          component={DocumentReviewIcon}
          color={colorsTheme.colorWarning}
        />
      ) : (
        <StyledText variant="body1">{title}</StyledText>
      )}
    </Tooltip>
  );
});

const StyledText = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => theme.colorWarning};
`;

export default PendingApprovalTooltip;
