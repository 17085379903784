import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '../../../../../helpers/date';
import { colorsTheme } from '../../../../../resources/theme/styled/colors';
import { AccountsHelpers } from '../../../../../helpers/accounts';
import { TransactionBase } from '../../../../../typings/accounting/transaction';
import { JournalEntryTypes } from '../../../../../enums/approvalManagement/approvalManagement';
import { AccountProcessingTypes } from '../../../../../enums/accounting/transfers';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import DerivedFromCircleTooltip from '../../../../Tooltips/TemplateTooltips/DerivedFromCircleTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { ReactComponent as SyncIcon } from '../../../../../resources/icons/remix-icons/arrow-left-right-line.svg';

export type IProps = RequiredPropsForTableModel<TransactionBase>;

const TransfersTable = (props: IProps) => {
  const { t } = useTranslation('dashboard');

  const renderDescription = ({
    description,
    sourceId,
    derivedFrom,
  }: TransactionBase) => {
    if (!description) {
      return;
    }

    if (derivedFrom === AccountProcessingTypes.Circle) {
      return (
        <>
          {description}
          <DerivedFromCircleTooltip derivedEntryType="transaction" />
        </>
      );
    }

    if (sourceId !== JournalEntryTypes.Manual) {
      return <Text variant="body1">{description}</Text>;
    }

    return <Text variant="body1">{description}</Text>;
  };

  const renderAmount = (record: TransactionBase) => {
    const isIncrease = !record.debitAmount;
    const formattedAmount =
      AccountsHelpers.convertAmountBigIntToLocaleString(
        isIncrease ? record.creditAmount : record.debitAmount,
      ) || 0;
    return (
      <StyledAmountText
        variant="body1"
        color={isIncrease ? colorsTheme.colorPrimary : colorsTheme.colorError}
      >
        {`${isIncrease ? '+' : '-'} ${formattedAmount}`}
      </StyledAmountText>
    );
  };

  const columns = useMemo<TableColumnModel[]>(
    () => [
      {
        width: '50px',
        key: 'icon',
        render: () => (
          <IconCircleWrapper>
            <IconSVG component={SyncIcon} color={colorsTheme.colorWhite} />
          </IconCircleWrapper>
        ),
      },

      {
        width: '250px',
        title: t('last_transfers_section.table.date'),
        key: 'date',
        render: (record: TransactionBase) =>
          DateHelpers.formatTimestampDateToString(record.valueDate),
      },

      {
        title: t('last_transfers_section.table.description'),
        key: 'description',
        render: renderDescription,
      },

      {
        title: t('last_transfers_section.table.total'),
        key: 'total',
        align: 'right',
        render: renderAmount,
      },
    ],
    [],
  );

  return <Table columns={columns} {...props} />;
};

const IconCircleWrapper = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorDarkL2};
`;

const StyledAmountText = styled(Text)`
  text-align: end;
`;

export default TransfersTable;
