// helpers
import * as yup from 'yup';

// constants
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
} from '../../constants/global';
import {
  NULLABLE_NUMBER_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from '../common';

const BankAccountRequiredValidationSchema = yup.object().shape({
  purpose: STRING_VALIDATION_RULE.min(DESCRIPTION_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters_with_label',
    values: {
      label: 'onboarding:account_information.form_fields.purpose_of_account',
      number: DESCRIPTION_MIN_LENGTH,
    },
  })
    .max(DESCRIPTION_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'onboarding:account_information.form_fields.purpose_of_account',
        number: DESCRIPTION_MAX_LENGTH,
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'onboarding:account_information.form_fields.purpose_of_account',
      },
    }),

  type: yup
    .mixed()
    .nullable()
    .required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'onboarding:account_information.form_fields.account_type',
      },
    }),

  currencyId: NULLABLE_NUMBER_VALIDATION_RULE.typeError({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'onboarding:account_information.form_fields.currency' },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'onboarding:account_information.form_fields.currency' },
  }),

  initialDeposit: yup
    .mixed()
    .nullable()
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'common:value' },
    }),

  initialDepositOrigin: NULLABLE_STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'common:value' },
  }),

  jurisdictions: yup
    .array()
    .min(1, {
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'onboarding:account_information.form_fields.jurisdictions_title',
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'onboarding:account_information.form_fields.jurisdictions_title',
      },
    }),

  incomingWiresNumber: yup
    .mixed()
    .nullable()
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'common:value' },
    }),

  estimatedIncomingFunds: yup
    .mixed()
    .nullable()
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'common:value' },
    }),

  outgoingWiresNumber: yup
    .mixed()
    .nullable()
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'common:value' },
    }),

  estimatedOutgoingFunds: yup
    .mixed()
    .nullable()
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'common:value' },
    }),
});

const BankAccountOptionalValidationSchema = yup.object().shape({
  purpose: STRING_VALIDATION_RULE.min(DESCRIPTION_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters_with_label',
    values: {
      label: 'onboarding:account_information.form_fields.purpose_of_account',
      number: DESCRIPTION_MIN_LENGTH,
    },
  }).max(DESCRIPTION_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:account_information.form_fields.purpose_of_account',
      number: DESCRIPTION_MAX_LENGTH,
    },
  }),

  type: yup.mixed().nullable(),

  currencyId: NULLABLE_NUMBER_VALIDATION_RULE.typeError({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'onboarding:account_information.form_fields.currency' },
  }),

  initialDeposit: yup.mixed().nullable(),

  initialDepositOrigin: NULLABLE_STRING_VALIDATION_RULE,

  jurisdictions: yup.array(),

  incomingWiresNumber: yup.mixed().nullable(),

  estimatedIncomingFunds: yup.mixed().nullable(),

  outgoingWiresNumber: yup.mixed().nullable(),

  estimatedOutgoingFunds: yup.mixed().nullable(),
});

export const AccountInformationStepValidationSchema = yup.object().shape({
  submitActionType: yup.mixed().nullable(),
  isSave: yup.boolean().nullable().defined(),
  accounts: yup.array().when('isSave', {
    is: true,
    then: (schema) => schema.of(BankAccountOptionalValidationSchema),
    otherwise: (schema) => schema.of(BankAccountRequiredValidationSchema),
  }),
});
