import React from 'react';
import { LayoutVariants } from 'redux/reducers/settings';
import { PERMISSION_KEYS } from 'config/permissions';
import SignInPage from 'pages/Auth/SignInPage';
import AuthenticationLayout from 'modules/Layouts/AuthenticationLayout';
import TwoFactorAuthentication from 'pages/Auth/TwoFactorAuthentication';
import AccountRecoveryPage from 'pages/Auth/AccountRecoveryPage';
import ActivateAccountPage from 'pages/Auth/ActivateAccountPage';
import ResetPasswordPage from 'pages/Auth/ResetPasswordPage';
import ForgotUsernamePage from 'pages/Auth/ForgotUsernamePage';
import SignUpLayout from 'modules/Layouts/SignUpLayout';
import SignUpPage from 'pages/Auth/SignUpPage';
import InvitationVerifyPage from 'pages/Invitation/InvitationVerifyPage';
import InvitationPage from 'pages/Invitation/InvitationPage';
import MainLayout from 'modules/Layouts/MainLayout';
import DashboardPage from 'pages/Dashboard/DashboardPage';
import OnboardingPage from 'pages/Onboarding/OnboardingPage';
import AccountSummary from 'pages/Accounts/AccountSummary';
import AccountActivity from 'pages/Accounts/AccountActivity';
import PendingTransactions from 'pages/Accounts/PendingTransactions';
import VerificationPage from 'pages/Onboarding/VerificationPage';
import SectionDashboardPage from 'pages/AdditionalPages/SectionDashboardPage';
import InternalTransfer from 'pages/Transfers/InternalTransfer';
import WireTransfer from 'pages/Transfers/WireTransfer';
import BlockchainTransfer from 'pages/Transfers/BlockchainTransfer';
import TransferTemplates from 'pages/Transfers/TransferTemplates';
import UserManagement from 'pages/AccountManagement/UserManagement';
import ClientProfile from 'pages/AccountManagement/ClientProfile';
import ClientDetails from 'pages/AccountManagement/ClientDetails';
import TransactionRules from 'pages/AccountManagement/TransactionRules';
import AccountManagementRules from 'pages/AccountManagement/AccountManagementRules';
import LimitConfiguration from 'pages/AccountManagement/LimitConfiguration';
import ExternalServices from 'pages/AccountManagement/ExternalServices';
import ClientGroupsPage from 'pages/Connections/ClientGroupsPage';
import RequestsPage from 'pages/Connections/RequestsPage';
import PersonalDetailsPage from 'pages/Profile/PersonalDetailsPage';
import Messages from 'pages/Profile/Messages';
import MessageThread from 'pages/Profile/MessageThread';
import Security from 'pages/Profile/Security';
import ManageSecurityKeys from 'pages/Profile/ManageSecurityKeys';
import TravelNotifications from 'pages/Profile/TravelNotifications';
import AccountNotifications from 'pages/Profile/AccountNotifications';
import SelectApplicationPage from 'pages/Onboarding/SelectApplicationPage';
import ClientGroupReviewStatus from 'components/Additional/ClientGroupReviewStatus';
import { Navigate } from 'react-router-dom';

export enum RoutePaths {
  Auth_Root = '/auth',
  Auth_Root_StepTwo = '/auth/2fa',
  Auth_AccountRecovery = '/auth/account-recovery',
  StartOnboarding = '/start-onboarding',
  Auth_ActivateAccount = '/auth/activate-account',
  Auth_ResetPassword = '/auth/reset-password',
  Auth_ForgotUsername = '/auth/forgot-username',
  Invitation_Root = '/invitation',
  Invitation_Request = '/invitation/request',
  OnboardingPage = '/onboarding',
  VerificationPage = '/verification',
  SelectApplicationPage = '/select-application',
  UserProfile_Root = '/profile',
  UserProfile_Messages = '/profile/messages',
  UserProfile_Messages_Thread = '/profile/messages/thread',
  UserProfile_SignInAndSecurity = '/profile/sign-in-and-security',
  UserProfile_SignInAndSecurity_ManageSecurityKeys = '/profile/sign-in-and-security/manage-security-keys',
  UserProfile_TravelNotifications = '/profile/travel-notifications',
  UserProfile_AccountNotifications = '/profile/account-notifications',
  UserProfile_PersonalDetails = '/profile/personal-details',
  Root = '/',
  Accounts = '/accounts',
  Accounts_AccountSummary = '/accounts/account-summary',
  Accounts_AccountActivity = '/accounts/account-activity',
  Accounts_PendingTransactions = '/accounts/pending-transactions',
  Transfers = '/transfers',
  Transfers_InternalTransfer = '/transfers/internal-transfer',
  Transfers_WireTransfer = '/transfers/wire-transfer',
  Transfers_BlockchainTransfer = '/transfers/blockchain-transfer',
  Transfers_TransferTemplates = '/transfers/transfer-templates',
  AccountManagement = '/account-management',
  AccountManagement_UserManagement = '/account-management/user-management',
  AccountManagement_TransactionRules = '/account-management/transaction-rules',
  AccountManagement_ClientProfile = '/account-management/client-profile',
  AccountManagement_ClientProfile_ClientDetails = '/account-management/client-profile/client',
  AccountManagement_AccountManagementRules = '/account-management/account-management-rules',
  AccountManagement_LimitConfiguration = '/account-management/limit-configuration',
  AccountManagement_ExternalServices = '/account-management/external-services',
  Connections = '/connections',
  Connections_Requests = '/connections/requests',
  Connections_Client_Groups = '/connections/client-groups',
  // UserProfile_AccountSettings = '/profile/account-settings',
  // UserProfile_Help = '/profile/help',
}

export type Route = {
  path: RoutePaths;
  title: RouteTitleModel;
  component?: React.ReactNode;
  index?: boolean;
  access?: { layout?: LayoutVariants; permission?: PERMISSION_KEYS };
  children?: Route[];
  metadata?: {
    addToNavigation?: boolean;
    addToProfileMenu?: boolean;
    addToOnboardingProfileMenu?: boolean;
    notShowPageTitle?: boolean;
    notRenderContentInCard?: boolean;
    renderGoBackButton?: boolean;
    renderRouteComponentOnly?: boolean;
    appendToSidebarTitle?: React.ReactNode;
  };
};

export type RouteTitleModel = {
  key: string;
  ns: string;
};

const routes: Route[] = [
  {
    path: RoutePaths.Auth_Root,
    title: { key: 'signin.title', ns: 'auth' },
    component: <AuthenticationLayout />,
    children: [
      {
        index: true,
        path: RoutePaths.Auth_Root,
        title: { key: 'signin.title', ns: 'auth' },
        component: <SignInPage />,
      },

      {
        path: RoutePaths.Auth_Root_StepTwo,
        title: { key: 'two_factor_authentication.title', ns: 'auth' },
        component: <TwoFactorAuthentication />,
      },

      {
        path: RoutePaths.Auth_AccountRecovery,
        title: { key: 'account_recovery.title', ns: 'auth' },
        component: <AccountRecoveryPage />,
      },

      {
        path: RoutePaths.Auth_ActivateAccount,
        title: { key: 'activate_account.title', ns: 'auth' },
        component: <ActivateAccountPage />,
      },

      {
        path: RoutePaths.Auth_ResetPassword,
        title: { key: 'reset_password.title', ns: 'auth' },
        component: <ResetPasswordPage />,
      },

      {
        path: RoutePaths.Auth_ForgotUsername,
        title: { key: 'reset_username.page_title', ns: 'auth' },
        component: <ForgotUsernamePage />,
      },
    ],
  },

  {
    path: RoutePaths.StartOnboarding,
    title: { key: 'open_an_account.title', ns: 'auth' },
    component: <SignUpLayout />,
    children: [
      {
        index: true,
        path: RoutePaths.StartOnboarding,
        title: { key: 'open_an_account.title', ns: 'auth' },
        component: <SignUpPage />,
      },
    ],
  },

  {
    path: RoutePaths.Invitation_Root,
    title: { key: 'invitation_title', ns: 'invitation' },
    component: <AuthenticationLayout />,
    children: [
      {
        index: true,
        path: RoutePaths.Invitation_Root,
        title: { key: 'invitation_title', ns: 'invitation' },
        component: <InvitationVerifyPage />,
      },
      {
        path: RoutePaths.Invitation_Request,
        title: { key: 'invitation_title', ns: 'invitation' },
        component: <InvitationPage />,
      },
    ],
  },

  {
    path: RoutePaths.Root,
    title: { key: 'profile_title', ns: 'onboarding' },
    component: <MainLayout />,
    children: [
      {
        index: true,
        path: RoutePaths.Root,
        title: { key: 'page_title', ns: 'dashboard' },
        access: { layout: 'ApprovedClientGroup' },
        metadata: {
          addToNavigation: true,
          notShowPageTitle: true,
          notRenderContentInCard: true,
        },
        component: <DashboardPage />,
      },
      {
        path: RoutePaths.OnboardingPage,
        title: { key: 'onboarding_title', ns: 'onboarding' },
        access: { layout: 'OnboardingClientGroup' },
        component: <OnboardingPage />,
        metadata: {
          renderRouteComponentOnly: true,
        },
      },

      {
        path: RoutePaths.VerificationPage,
        title: { key: 'verification_title', ns: 'auth' },
        component: <VerificationPage />,
        metadata: {
          renderRouteComponentOnly: true,
        },
      },

      {
        path: RoutePaths.SelectApplicationPage,
        title: { key: 'select_application_title', ns: 'auth' },
        component: <SelectApplicationPage />,
        metadata: {
          renderRouteComponentOnly: true,
        },
      },

      {
        path: RoutePaths.Accounts,
        title: { key: 'page_title', ns: 'accounts' },
        access: { layout: 'ApprovedClientGroup', permission: 'Accounts_View' },
        metadata: { addToNavigation: true },
        component: <SectionDashboardPage />,
        children: [
          {
            path: RoutePaths.Accounts_AccountSummary,
            title: { key: 'account_summary.title', ns: 'accounts' },
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Accounts_AccountSummary_View',
            },
            metadata: { addToNavigation: true },
            component: <AccountSummary />,
          },

          {
            path: RoutePaths.Accounts_AccountActivity,
            title: { key: 'account_activity.title', ns: 'accounts' },
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Accounts_AccountActivity_View',
            },
            metadata: { addToNavigation: true },
            component: <AccountActivity />,
          },

          {
            path: RoutePaths.Accounts_PendingTransactions,
            title: { key: 'pending_transactions.title', ns: 'accounts' },
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Accounts_PendingTransactions_View',
            },
            metadata: { addToNavigation: true },
            component: <PendingTransactions />,
          },
        ],
      },

      {
        path: RoutePaths.Transfers,
        title: { key: 'page_title', ns: 'transfers' },
        component: <SectionDashboardPage />,
        access: { layout: 'ApprovedClientGroup', permission: 'Transfers_View' },
        metadata: { addToNavigation: true },
        children: [
          {
            path: RoutePaths.Transfers_InternalTransfer,
            title: { key: 'internal_transfer.title', ns: 'transfers' },
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Transfers_InternalTransfer_Create',
            },
            metadata: { addToNavigation: true },
            component: <InternalTransfer />,
          },

          {
            path: RoutePaths.Transfers_WireTransfer,
            title: { key: 'international_transfer.title', ns: 'transfers' },
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Transfers_WireTransfer_Create',
            },
            metadata: { addToNavigation: true },
            component: <WireTransfer />,
          },

          {
            path: RoutePaths.Transfers_BlockchainTransfer,
            title: { key: 'blockchain_transfer.title', ns: 'transfers' },
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Transfers_BlockchainTransfer_Create',
            },
            metadata: { addToNavigation: true },
            component: <BlockchainTransfer />,
          },

          {
            path: RoutePaths.Transfers_TransferTemplates,
            title: { key: 'transfer_templates.title', ns: 'transfers' },
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Transfers_TransferTemplates_View',
            },
            metadata: { addToNavigation: true },
            component: <TransferTemplates />,
          },
        ],
      },

      {
        path: RoutePaths.AccountManagement,
        title: { key: 'page_title', ns: 'account_management' },
        component: <SectionDashboardPage />,
        access: {
          layout: 'ApprovedClientGroup',
          permission: 'AccountManagement_View',
        },
        metadata: { addToNavigation: true },
        children: [
          {
            title: { key: 'user_management.title', ns: 'account_management' },
            path: RoutePaths.AccountManagement_UserManagement,
            component: <UserManagement />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'AccountManagement_UserManagement_View',
            },
            metadata: {
              addToNavigation: true,
              appendToSidebarTitle: <ClientGroupReviewStatus />,
            },
          },

          {
            path: RoutePaths.AccountManagement_ClientProfile,
            title: { key: 'client_profile.title', ns: 'account_management' },
            component: <ClientProfile />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'AccountManagement_ClientProfile_View',
            },
            metadata: {
              addToNavigation: true,
              appendToSidebarTitle: <ClientGroupReviewStatus />,
            },
            children: [
              {
                path: RoutePaths.AccountManagement_ClientProfile_ClientDetails,
                title: {
                  key: 'client_profile_details.title',
                  ns: 'account_management',
                },
                component: <ClientDetails />,
                access: {
                  layout: 'ApprovedClientGroup',
                  permission:
                    'AccountManagement_ClientProfile_ClientDetails_View',
                },
                metadata: { renderGoBackButton: true },
              },
            ],
          },

          {
            path: RoutePaths.AccountManagement_TransactionRules,
            title: { key: 'transaction_rules.title', ns: 'account_management' },
            component: <TransactionRules />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'AccountManagement_TransactionRules_View',
            },
            metadata: { addToNavigation: true },
          },

          {
            path: RoutePaths.AccountManagement_AccountManagementRules,
            title: {
              key: 'account_management_rules.title',
              ns: 'account_management',
            },
            component: <AccountManagementRules />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'AccountManagement_Rules_View',
            },
            metadata: { addToNavigation: true },
          },

          {
            path: RoutePaths.AccountManagement_LimitConfiguration,
            title: {
              key: 'limit_configuration.title',
              ns: 'account_management',
            },
            component: <LimitConfiguration />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'AccountManagement_LimitConfiguration_View',
            },
            metadata: { addToNavigation: true },
          },

          {
            path: RoutePaths.AccountManagement_ExternalServices,
            title: { key: 'external_services.title', ns: 'account_management' },
            component: <ExternalServices />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'AccountManagement_ExternalServices_View',
            },
            metadata: { addToNavigation: true },
          },
        ],
      },

      {
        path: RoutePaths.UserProfile_Root,
        title: { key: 'personal_details.title', ns: 'profile' },
        component: <Navigate to={RoutePaths.UserProfile_PersonalDetails} />,
        metadata: { addToProfileMenu: true },
        children: [
          {
            path: RoutePaths.UserProfile_PersonalDetails,
            title: { key: 'personal_details.title', ns: 'profile' },
            component: <PersonalDetailsPage />,
            metadata: { addToProfileMenu: true },
          },

          {
            path: RoutePaths.UserProfile_Messages,
            title: { key: 'messages.title', ns: 'profile' },
            component: <Messages />,
            access: { permission: 'Profile_Messages_View' },
            metadata: { addToProfileMenu: true },
            children: [
              {
                path: RoutePaths.UserProfile_Messages_Thread,
                title: { key: 'messages.message_thread.title', ns: 'profile' },
                component: <MessageThread />,
                metadata: { renderGoBackButton: true },
              },
            ],
          },

          {
            path: RoutePaths.UserProfile_SignInAndSecurity,
            title: { key: 'security.title', ns: 'profile' },
            component: <Security />,
            metadata: { addToProfileMenu: true, notRenderContentInCard: true },
            children: [
              {
                path: RoutePaths.UserProfile_SignInAndSecurity_ManageSecurityKeys,
                title: {
                  key: 'security.two_step_verification.security_key.manage_security_keys.title',
                  ns: 'profile',
                },
                component: <ManageSecurityKeys />,
                metadata: { renderGoBackButton: true },
              },
            ],
          },

          {
            path: RoutePaths.UserProfile_TravelNotifications,
            title: { key: 'travel_notifications.title', ns: 'profile' },
            component: <TravelNotifications />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Profile_TravelNotifications_View',
            },
            metadata: { addToProfileMenu: true },
          },

          {
            path: RoutePaths.UserProfile_AccountNotifications,
            title: { key: 'account_notifications.title', ns: 'profile' },
            component: <AccountNotifications />,
            access: {
              layout: 'ApprovedClientGroup',
              permission: 'Profile_AccountNotifications_View',
            },
            metadata: { addToProfileMenu: true, notRenderContentInCard: true },
          },

          // {
          //   title: 'Account Settings',
          //   path: RoutePaths.UserProfile_AccountSettings,
          //   layout: PageLayout,
          //   component: AccountSettings,
          //   layoutProps: { hideContentCard: true },
          // },

          // {
          //   title: 'Help and Support',
          //   path: RoutePaths.UserProfile_Help,
          //   layout: PageLayout,
          //   component: HelpSupport,
          //   layoutProps: { hideContentCard: true },
          // },
        ],
      },

      {
        path: RoutePaths.Connections,
        title: { key: 'page_title', ns: 'connections' },
        component: <SectionDashboardPage />,
        metadata: { addToNavigation: true, addToOnboardingProfileMenu: true },
        children: [
          {
            path: RoutePaths.Connections_Client_Groups,
            title: { key: 'client_groups.title', ns: 'connections' },
            component: <ClientGroupsPage />,
            metadata: {
              addToNavigation: true,
              notRenderContentInCard: true,
              addToOnboardingProfileMenu: true,
            },
          },

          {
            path: RoutePaths.Connections_Requests,
            title: { key: 'requests.title', ns: 'connections' },
            component: <RequestsPage />,
            access: { permission: 'Connections_Requests_View' },
            metadata: {
              addToNavigation: true,
              notRenderContentInCard: true,
              addToOnboardingProfileMenu: true,
            },
          },
        ],
      },
    ],
  },
];

export { routes };
