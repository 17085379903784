import { Dispatch } from 'redux';
import { ContactModel } from 'typings/application/contact';
import { StepStatus, StepStatusesModel } from 'redux/reducers/personalDetails';
import {
  ChangeRequestFromQuery,
  ChangeRequestStatuses,
  personalDetailsAPI,
} from 'api/profile/personalDetailsAPI';

// Actions
export enum ActionType {
  SET_LOADING = 'personal_details/SET_LOADING',
  SET_IS_VERIFIED_STATUS = 'personal_details/SET_VERIFIED_STATUS',
  SET_CONTACT = 'personal_details/SET_CONTACT',
  SET_CHANGE_REQUEST = 'personal_details/SET_CHANGE_REQUEST',
  SET_STEP_STATUSES = 'personal_details/SET_STEP_STATUSES',
  SET_CAN_SUBMIT_PERSONAL_DETAILS = 'personal_details/SET_CAN_SUBMIT_PERSONAL_DETAILS',
  CLEAN_UP = 'personal_details/CLEAN_UP',
}

// Helper methods
async function _loadingHelper(dispatch: Dispatch, body: () => Promise<void>) {
  dispatch({ type: ActionType.SET_LOADING, payload: { loading: true } });
  await body();
  dispatch({ type: ActionType.SET_LOADING, payload: { loading: false } });
}

// Action creators
export function setIsVerifiedStatus(isVerified: boolean) {
  return {
    type: ActionType.SET_IS_VERIFIED_STATUS,
    payload: { isVerified },
  };
}

export function setContact(contact: ContactModel | null) {
  return {
    type: ActionType.SET_CONTACT,
    payload: { contact },
  };
}

export function setCanSubmitPersonalDetails(
  canSubmitPersonalDetails: boolean | null,
) {
  return {
    type: ActionType.SET_CAN_SUBMIT_PERSONAL_DETAILS,
    payload: { canSubmitPersonalDetails },
  };
}

export function cleanUpPersonalDetailsState() {
  return {
    type: ActionType.CLEAN_UP,
    payload: null,
  };
}

export function setStepStatusesByChangeRequest(
  changeRequest: ChangeRequestFromQuery | null,
) {
  const result: StepStatusesModel = {
    sow: null,
    general: null,
    identification: null,
    applicationDocumentation: null,
  };

  if (
    changeRequest &&
    changeRequest.status === ChangeRequestStatuses.InProgress
  ) {
    switch (changeRequest.type) {
      case 'contact-get-kyc':
        {
          result.sow = changeRequest.changedSteps.sow
            ? StepStatus.Completed
            : StepStatus.InfoRequired;
          result.general = changeRequest.changedSteps.general
            ? StepStatus.Completed
            : StepStatus.InfoRequired;
          result.identification = changeRequest.changedSteps.identification
            ? StepStatus.Completed
            : StepStatus.InfoRequired;
          result.applicationDocumentation = changeRequest.changedSteps
            .verification
            ? StepStatus.Completed
            : StepStatus.InfoRequired;
        }
        break;

      case 'contact-update-kyc':
        {
          result.sow = changeRequest.changedSteps.sow
            ? StepStatus.Completed
            : null;
          result.general = changeRequest.changedSteps.general
            ? StepStatus.Completed
            : null;
          result.identification = changeRequest.changedSteps.identification
            ? StepStatus.Completed
            : typeof changeRequest.changedSteps.identification !== 'undefined'
              ? StepStatus.InfoRequired
              : null;
          result.applicationDocumentation = changeRequest.changedSteps
            .verification
            ? StepStatus.Completed
            : typeof changeRequest.changedSteps.verification !== 'undefined'
              ? StepStatus.InfoRequired
              : null;
        }
        break;
    }
  }

  return {
    type: ActionType.SET_STEP_STATUSES,
    payload: { stepStatuses: result },
  };
}
export function setChangeRequest(changeRequest: ChangeRequestFromQuery | null) {
  return function (dispatch: Dispatch) {
    const modifiedChangeRequest: ChangeRequestFromQuery | null = changeRequest
      ? {
          ...changeRequest,
          changes:
            changeRequest.status == ChangeRequestStatuses.InReview
              ? null
              : changeRequest.changes,
        }
      : null;
    dispatch({
      type: ActionType.SET_CHANGE_REQUEST,
      payload: { changeRequest: modifiedChangeRequest },
    });
    dispatch(setStepStatusesByChangeRequest(changeRequest));
  };
}

export function fetchPersonalDetails(skipLoading?: boolean) {
  return async function (dispatch: any) {
    if (skipLoading) {
      const response = await personalDetailsAPI.fetchCurrentContactDetails();
      dispatch(setIsVerifiedStatus(response.contact.approval.isKYCProvided));
      dispatch(setContact(response.contact));
      dispatch(setChangeRequest(response.activeClientChangeRequest));
      dispatch(
        setCanSubmitPersonalDetails(
          !response.isUpdatePersonalDetailsActionDisabled,
        ),
      );
    } else {
      _loadingHelper(dispatch, async () => {
        const response = await personalDetailsAPI.fetchCurrentContactDetails();
        dispatch(setIsVerifiedStatus(response.contact.approval.isKYCProvided));
        dispatch(setContact(response.contact));
        dispatch(setChangeRequest(response.activeClientChangeRequest));
        dispatch(
          setCanSubmitPersonalDetails(
            !response.isUpdatePersonalDetailsActionDisabled,
          ),
        );
      });
    }
  };
}
