import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormikHelpers, FormikProps } from 'formik';
import { ApprovalRuleTriggerTypeModel } from 'typings/approvalManagement/accountManagement';
import { ApprovalRuleValidationSchema } from 'validations/accountManagement/approvalRuleTemplate';

// components
import Button from '@core_components/Button';
import AddButton from '@common_components/Buttons/AddButton';
import SubmitButton from 'components/Buttons/SubmitButton';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import AccountManagementRulesMatrixForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Approval/AccountManagementRulesMatrixForm';
import { Col, Modal, Row } from 'antd';

export interface ApprovalRuleMatrixFormValuesModel extends FormValuesModel {
  id: string | null;
  isActive?: boolean;
}

interface IProps
  extends RequiredPropsForFormModel<ApprovalRuleMatrixFormValuesModel> {
  approvalRuleTriggerTypes: ApprovalRuleTriggerTypeModel[];
}

const ApprovalRuleMatrix = ({
  disabled,
  initialValues,
  approvalRuleTriggerTypes,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation('common');
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEditStatusChange = (
    newStatus: boolean,
    wasChanged: boolean,
    form: FormikProps<ApprovalRuleMatrixFormValuesModel>,
  ) => {
    if (newStatus) {
      setIsEditMode(newStatus);
    } else {
      if (wasChanged) {
        Modal.confirm({
          title: t('leave_form_without_saving.title', { ns: 'form' }),
          content: t('leave_form_without_saving.description', { ns: 'form' }),
          okText: t('yes', { ns: 'common' }),
          cancelText: t('no', { ns: 'common' }),
          onOk: () => {
            form.resetForm();
            setIsEditMode(newStatus);
          },
        });
      } else {
        form.resetForm({
          values: initialValues as ApprovalRuleMatrixFormValuesModel,
        });
        setIsEditMode(newStatus);
      }
    }
  };

  const handleSubmit = async (
    values: ApprovalRuleMatrixFormValuesModel,
    formikHelpers: FormikHelpers<ApprovalRuleMatrixFormValuesModel>,
  ) => {
    await onSubmit(values, formikHelpers);
    setIsEditMode(false);
  };

  return (
    <Form
      enableReinitialize
      disabled={!isEditMode || disabled}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={ApprovalRuleValidationSchema}
      confirmExitWithoutSaving
      renderForm={(form) => (
        <>
          {!disabled && !form.values.id && !isEditMode ? (
            <Col>
              <AddButton
                onClick={() => handleEditStatusChange(true, false, form)}
              >
                {t('create_new_button', { ns: 'common' })}
              </AddButton>
            </Col>
          ) : null}
          {!disabled ? (
            <StyledHeaderRow justify="end" gutter={[16, 16]}>
              {form.values.id && !isEditMode && (
                <Col>
                  <Button
                    onClick={() => handleEditStatusChange(true, false, form)}
                  >
                    {t('edit_button', { ns: 'common' })}
                  </Button>
                </Col>
              )}
              {isEditMode && (
                <>
                  <Col>
                    <Button
                      type="bordered"
                      onClick={() =>
                        handleEditStatusChange(false, form.dirty, form)
                      }
                    >
                      {t('cancel', { ns: 'common' })}
                    </Button>
                  </Col>
                  <Col>
                    <SubmitButton size="middle">
                      {t('save', { ns: 'common' })}
                    </SubmitButton>
                  </Col>
                </>
              )}
            </StyledHeaderRow>
          ) : null}
          {(!form.values.id && isEditMode) || form.values.id ? (
            <AccountManagementRulesMatrixForm
              triggerTypes={approvalRuleTriggerTypes}
            />
          ) : null}
        </>
      )}
    />
  );
};

const StyledHeaderRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default ApprovalRuleMatrix;
