import { ActionType } from 'redux/actions/personalDetails';
import { IReduxAction } from '../../typings/common';
import { ContactModel } from 'typings/application/contact';
import { ChangeRequestFromQuery } from 'api/profile/personalDetailsAPI';

export enum StepStatus {
  InfoRequired = 'info-required',
  Completed = 'completed',
}

export interface StepStatusesModel {
  sow: StepStatus | null;
  general: StepStatus | null;
  identification: StepStatus | null;
  applicationDocumentation: StepStatus | null;
}

export interface StateModel {
  loading: boolean;
  isVerified: boolean;
  contact: ContactModel | null;
  stepStatuses: StepStatusesModel;
  changeRequest: ChangeRequestFromQuery | null;
  canSubmitPersonalDetails: boolean | null;
}

// Initial state
export const initialState: StateModel = {
  loading: false,
  isVerified: false,
  contact: null,
  changeRequest: null,
  canSubmitPersonalDetails: null,
  stepStatuses: {
    sow: null,
    general: null,
    identification: null,
    applicationDocumentation: null,
  },
};

const personalDetails = (
  state = initialState,
  action: IReduxAction,
): StateModel => {
  switch (action.type) {
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case ActionType.SET_IS_VERIFIED_STATUS:
      return {
        ...state,
        isVerified: action.payload.isVerified,
      };

    case ActionType.SET_CONTACT:
      return {
        ...state,
        contact: action.payload.contact,
      };

    case ActionType.SET_CHANGE_REQUEST:
      return {
        ...state,
        changeRequest: action.payload.changeRequest,
      };

    case ActionType.SET_STEP_STATUSES:
      return {
        ...state,
        stepStatuses: action.payload.stepStatuses,
      };

    case ActionType.SET_CAN_SUBMIT_PERSONAL_DETAILS:
      return {
        ...state,
        canSubmitPersonalDetails: action.payload.canSubmitPersonalDetails,
      };

    case ActionType.CLEAN_UP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default personalDetails;
