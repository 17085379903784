import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../../../../hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';
import { ApprovalTemplateColumns } from '..';
import { ApprovalRuleSetBaseModel } from '../../../../../../../../../../../typings/approvalManagement/accountManagement';

// components
import Text from '@core_components/Text';
import Checkbox from '../../../../../../../../../../../components/DesignSystem/Core/Checkbox';
import { Col, Row } from 'antd';

type IProps = ColumnPropsModel;

export interface ColumnPropsModel {
  colIndex: number;
  rowIndex: number;
  dataIndex: ApprovalTemplateColumns;

  record: ApprovalRuleSetBaseModel;
  approvalTriggerId: number | null;
  rulesCount: number;
}

const TableContentCell = ({
  dataIndex,
  rowIndex,
  rulesCount,
  approvalTriggerId,
  record,
}: IProps) => {
  const { t } = useTranslation('account_management');

  const renderContent = (columnIndexKey: ApprovalTemplateColumns) => {
    let result = null;

    switch (columnIndexKey) {
      case ApprovalTemplateColumns.ApprovalGroupCondition:
        {
          const shouldRenderOR = rowIndex < rulesCount - 1;

          result = (
            <>
              {record.approvalRuleItems.map(
                ({ approvalGroup, minimunApprovalThreashold }, i) => {
                  const shouldRenderAND =
                    i < record.approvalRuleItems.length - 1;

                  return (
                    <>
                      <ApprovalGroupConditionWrapper key={`${rowIndex}-${i}`}>
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Text
                              variant="body1"
                              color={colorsTheme.colorWhite}
                              weight="semi-bold"
                            >
                              {`${t(
                                'pending_approvals.approval_details_modal.changes_section.account_management_rule_changes_template.approval_group_name',
                              )}: `}
                            </Text>
                            <Text variant="body1">{approvalGroup.name}</Text>
                          </Col>

                          <Col span={24}>
                            <Text
                              variant="body1"
                              color={colorsTheme.colorWhite}
                              weight="semi-bold"
                            >
                              {`${t(
                                'pending_approvals.approval_details_modal.changes_section.account_management_rule_changes_template.required_approvers',
                              )}: `}
                            </Text>{' '}
                            {minimunApprovalThreashold}
                          </Col>
                        </Row>
                      </ApprovalGroupConditionWrapper>

                      {shouldRenderAND && (
                        <AndContainer>{`${t(
                          'pending_approvals.approval_details_modal.changes_section.account_management_rule_changes_template.and',
                        )} `}</AndContainer>
                      )}
                    </>
                  );
                },
              )}

              {shouldRenderOR && (
                <ORContainer>
                  {t(
                    'pending_approvals.approval_details_modal.changes_section.account_management_rule_changes_template.or',
                  )}
                </ORContainer>
              )}
            </>
          );
        }
        break;

      case ApprovalTemplateColumns.ApprovalRuleTriggerTypes:
        {
          const isChecked =
            record.approvalThreasholds.find(
              (e) => e.threasholdMin === approvalTriggerId,
            )?.ticked || false;

          result = (
            <CheckboxWrapper>
              <Checkbox value={isChecked} />
            </CheckboxWrapper>
          );
        }
        break;
    }

    return result;
  };

  return <td>{renderContent(dataIndex)}</td>;
};

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ApprovalGroupConditionWrapper = styled.div`
  position: relative;
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

const AndContainer = styled.div`
  font-weight: 600;
  margin-left: ${({ theme }) => theme.marginSm};
`;

const ORContainer = styled.div`
  position: absolute;
  bottom: -16px;
  left: 15px;

  width: 55px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.tableCardBackground};
`;

export default TableContentCell;
