import APIConfig from '../../config/api';
import { IAddress } from 'typings/application/entity';
import { APIService } from '../axiosInstance';
import { AnswerModel } from 'api/onboarding/onboardingAPI';
import { FetchResponseModel } from 'typings/common';
import {
  ContactModel,
  IContactEmail,
  IContactPhone,
} from 'typings/application/contact';
import {
  EmailTypes,
  GenderTypes,
  OnboardingEntryTypes,
  PhoneNumberTypes,
} from '../../enums/onboarding/crm';
import {
  AnswerOperation,
  EmailAddress,
  PhoneNumber,
  RequestBodyAddressItemModel,
  RequestBodyPassportItemModel,
  SocialMediaModel,
} from 'typings/onboarding/onboarding';

export enum ChangeRequestStatuses {
  InProgress = 'in-progress',
  InReview = 'in-review',
}

export interface UpdatePersonalDetailsBodyModel {
  firstName: string;
  middleName: string;
  lastName: string;

  emails: {
    type: EmailTypes;
    address: string;
  }[];

  phoneNumbers: {
    type: PhoneNumberTypes;
    number: string;
  }[];

  socialMedia?: {
    type: string;
    path: string;
  }[];
}

export type ChangeRequestFromQuery = {
  _id: string;
  type: 'contact-get-kyc' | 'contact-update-kyc';
  status: ChangeRequestStatuses;
  entityId: string;
  verification: {
    review?: { comment: string; isValid: boolean };
  };
  changes: {
    contactKYCChange: {
      general: {
        firstName: string;
        middleName: string;
        lastName: string;
        gender: GenderTypes | null;
        countryOfBirth: string;
        dateOfBirth: string;
        socialMedia: SocialMediaModel[];
        phoneNumbers: IContactPhone[];
        emails: IContactEmail[];
      } | null;

      identification: {
        isPEP: boolean;
        pepInformation: string;
        isRegulated: boolean;
        regulationCountry: string[];
        nationality: string[];
        addresses: IAddress[];
        passports: {
          country: string;
          expirationDate: string;
          issuedAt: string;
          number: string;
        }[];
      } | null;

      sow: {
        sourceOfWealthDescription: string;
        sourceOfWealthCategories: string[];
        employmentInformation: {
          isSelfEmployed: boolean | null;
          employerName: string;
          occupation: string;
          natureOfBusiness: string;
          isBussinessUnderOwnName: boolean | null;
        };
      } | null;
    };
  } | null;
  changedSteps: {
    sow?: boolean;
    general?: boolean;
    verification?: boolean;
    identification?: boolean;
  };
};

export interface FetchPersonalDetailsResponse {
  contact: ContactModel;
  activeClientChangeRequest: ChangeRequestFromQuery | null;
  isUpdatePersonalDetailsActionDisabled: boolean;
}

export interface FetchVerificationDocumentsResponse {
  admin: FetchResponseModel<AnswerModel>;
  client: FetchResponseModel<AnswerModel>;
  clientChangeRequest?: FetchResponseModel<AnswerModel>;
  relationship_keyRelationshipTemplate?: FetchResponseModel<AnswerModel>;
}

export type SubmitKYCGeneralTabData = {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string | null;
  countryOfBirth: string;
  emails: EmailAddress[];
  gender: GenderTypes | null;
  phoneNumbers: PhoneNumber[];
  socialMedia: SocialMediaModel[];
};

export type SubmitKYCIdentificationTabData = {
  isPEP: boolean | null;
  pepInformation: string;
  isRegulated: boolean | null;
  nationality: string[];
  regulationCountry: string[];
  passports: RequestBodyPassportItemModel[];
  addresses: RequestBodyAddressItemModel[];
};

export type SubmitKYCSOWTabData = {
  sourceOfWealthDescription: string;
  sourceOfWealthCategories: string[];
  employmentInformation: {
    isSelfEmployed: boolean | null;
    employerName: string;
    occupation: string;
    natureOfBusiness: string;
    isBussinessUnderOwnName: boolean | null;
  } | null;
};

export type SubmitVerificationTabData = {
  clientChangeRequestId: string;
  itemId: string;
  itemType: OnboardingEntryTypes;
  operations: AnswerOperation[];
};

const personalDetailsAPI = {
  changePreferredLanguage: (newLangCode: string) => {
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  },

  updatePersonalDetails: (body: UpdatePersonalDetailsBodyModel) => {
    return APIService.put(`${APIConfig.crmApi}/users/profile`, body).then(
      ({ data }) => data,
    );
  },

  fetchCurrentContactDetails: () => {
    return APIService.get<FetchPersonalDetailsResponse>(
      `${APIConfig.crmApi}/users/contact-details`,
    ).then(({ data }) => data);
  },

  fetchVerificationDocuments: (
    crmItemId: string,
    clientChangeRequestId?: string,
  ) => {
    return APIService.get<FetchVerificationDocumentsResponse>(
      `${APIConfig.crmApi}/client-documentation-items/${crmItemId}/grouped`,
      {
        params: clientChangeRequestId
          ? { limit: 100, page: 1, clientChangeRequestId }
          : { limit: 100, page: 1 },
      },
    ).then((response) => response?.data);
  },

  initiateVerificationProcessForContact: () => {
    return APIService.post<ChangeRequestFromQuery>(
      `${APIConfig.crmApi}/client-change-requests`,
      {
        type: 'contact-get-kyc',
      },
    ).then(({ data }) => data);
  },

  initiateEditKYCDataProcessForContact: () => {
    return APIService.post<ChangeRequestFromQuery>(
      `${APIConfig.crmApi}/client-change-requests`,
      {
        type: 'contact-update-kyc',
      },
    ).then(({ data }) => data);
  },

  stopVerificationProcessAndDiscardChanges: (requestId: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/client-change-requests/${requestId}`,
    ).then(({ data }) => data);
  },

  submitKYCGeneralTab: (requestId: string, data: SubmitKYCGeneralTabData) => {
    return APIService.post<ChangeRequestFromQuery>(
      `${APIConfig.crmApi}/client-change-requests/${requestId}/kyc-contact/general`,
      data,
    ).then(({ data }) => data);
  },

  submitKYCIdentificationTab: (
    requestId: string,
    data: SubmitKYCIdentificationTabData,
  ) => {
    return APIService.post<ChangeRequestFromQuery>(
      `${APIConfig.crmApi}/client-change-requests/${requestId}/kyc-contact/identification`,
      data,
    ).then(({ data }) => data);
  },

  submitKYCSOWTab: (requestId: string, data: SubmitKYCSOWTabData) => {
    return APIService.post<ChangeRequestFromQuery>(
      `${APIConfig.crmApi}/client-change-requests/${requestId}/kyc-contact/sow`,
      data,
    ).then(({ data }) => data);
  },

  submitVerificationStep: (body: SubmitVerificationTabData) => {
    return APIService.patch(
      `${APIConfig.crmApi}/client-documentation-items/batch`,
      body,
    );
  },

  submitChangeRequest: (requestId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-change-requests/${requestId}/submit`,
    ).then(({ data }) => data);
  },
};

export { personalDetailsAPI };
