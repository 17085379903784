import { PermissionedAccessModel } from '.';

// Permission keys
export type ApplicationPermissionKeys = 'Application_Create';

// Permission config
export const ApplicationPermissions: Record<
  ApplicationPermissionKeys,
  PermissionedAccessModel
> = {
  Application_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'CRM:Application:Create' }],
  },
};
