import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from '../../../../../../enums/profile/auth';
import { StateModel as AuthStateModel } from '../../../../../../redux/reducers/auth';

// components
import { Select } from 'antd';

interface IProps {
  disabled: boolean;
  loading: boolean;
  value: TwoFactorVerificationTypes | undefined;
  onSelect: (value: TwoFactorVerificationTypes) => void | Promise<void>;
}

const AuthenticatorTypeSelect = ({
  disabled,
  loading,
  value,
  onSelect,
}: IProps) => {
  const { t } = useTranslation('profile');
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const options = useMemo(() => {
    const result = [];

    if (profileData) {
      if (profileData.isTwoFactorAuthenticationEnabled) {
        result.push({
          key: TwoFactorVerificationTypes.Authenticator,
          name: t('security.two_step_verification.authenticator.title'),
        });
      }

      if (profileData.isTwoFactorFidoEnabled) {
        result.push({
          key: TwoFactorVerificationTypes.Fido,
          name: t('security.two_step_verification.security_key.title'),
        });
      }
    }

    return result;
  }, [profileData]);

  return (
    <StyledSelect
      size="large"
      value={value}
      onSelect={(value) => onSelect(value as TwoFactorVerificationTypes)}
      loading={loading}
      disabled={disabled}
    >
      {options.map(({ key, name }) => (
        <Select.Option key={key} value={key}>
          {name}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default AuthenticatorTypeSelect;
