import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { PassportItemModel } from '../..';
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DeleteButton from '../../../../../../DesignSystem/Common/Buttons/DeleteButton';
import CountrySelect from '../../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import HideIfDisabledForm from '../../../../../HideIfDisabledForm';
import DocumentReviewStatus from 'components/Additional/CRM/DocumentReviewStatus';
import { Card, Col, Modal, Row } from 'antd';

interface IProps {
  index: number;
  onRemoveClick?: () => void;
}

const PassportForm = ({ index, onRemoveClick }: IProps) => {
  const { t } = useTranslation(['onboarding', 'common']);
  const [field] = useField<PassportItemModel>(`passports.${index}`);

  const handleOnUploadButtonClick = (triggerClickOnInputRef: () => void) => {
    Modal.confirm({
      title: t(
        'application_documentation.certification_required_tooltip.title',
      ),
      width: 600,
      icon: null,
      closable: true,
      maskClosable: true,
      content: (
        <div>
          <StyledSubTitle>
            {t('application_documentation.certification_required_tooltip.text')}
          </StyledSubTitle>
          <ul>
            <li>
              <Text>
                {t(
                  'application_documentation.certification_required_tooltip.item_1',
                )}
              </Text>
            </li>
            <li>
              <Text>
                {t(
                  'application_documentation.certification_required_tooltip.item_2',
                )}
              </Text>
            </li>
            <li>
              <Text>
                {t(
                  'application_documentation.certification_required_tooltip.item_3',
                )}
              </Text>
            </li>
            <li>
              <Text>
                {t(
                  'application_documentation.certification_required_tooltip.item_4',
                )}
              </Text>
            </li>
          </ul>
        </div>
      ),
      okText: t('confirm', { ns: 'common' }),
      async onOk() {
        triggerClickOnInputRef();
      },
    });
  };

  return (
    <StyledCard
      title={t('identification.passport_with_number', { number: index + 1 })}
      extra={
        <Row gutter={[16, 16]} justify="center" align="middle">
          {field.value.review ? (
            <Col>
              <DocumentReviewStatus reviewResult={field.value.review} />
            </Col>
          ) : null}
          <HideIfDisabledForm hideIfSubmitting>
            <Col>
              <DeleteButton onClick={onRemoveClick} />
            </Col>
          </HideIfDisabledForm>
        </Row>
      }
    >
      <Row gutter={[16, 16]} align="middle">
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'identification.form_fields.individual.passports.passport_number',
            )}
            name={`passports.${index}.number`}
            component={FormInput}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'identification.form_fields.individual.passports.country_of_passport',
            )}
            name={`passports.${index}.country`}
            component={CountrySelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'identification.form_fields.individual.passports.issue_date',
            )}
            name={`passports.${index}.issuedAt`}
            component={StyledDatePicker}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isAfter(new Date()),
            }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'identification.form_fields.individual.passports.expire_date',
            )}
            name={`passports.${index}.expirationDate`}
            component={StyledDatePicker}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isBefore(new Date()),
            }}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'identification.form_fields.individual.passports.document',
            )}
            name={`passports.${index}.document`}
            component={DocumentsField}
            additionalProps={{
              isMultiple: true,
              onUploadButtonClick: handleOnUploadButtonClick,
            }}
          />
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledCard = styled(Card)`
  margin: ${({ theme }) => `${theme.marginMd} 0`};

  .ant-card-head {
    border-bottom: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
  }

  .ant-card-extra {
    text-transform: none;
  }
`;

// From some reason theme object is empty when this component used in Modal
// so we need to use hardcoded value (15px = theme.marginMd)
const StyledSubTitle = styled(Text)`
  margin-top: 15px;
`;

export default PassportForm;
