import React, { Ref } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { FormikHelpers, FormikProps } from 'formik';
import { ApplicantInformationIndividualShortValidationSchema } from '../../../../../validations/onboarding-new/applicant-information';

// components
import Form from '@core_components/Form';
import SectionIntro from '../../../../DesignSystem/Common/Texts/SectionIntro';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import SubmitButtons, {
  SubmitButtonsFormValuesModel,
} from '../Components/SubmitButtons';
import ContactGeneralForm, {
  FormValuesModel as ContactGeneralFormValuesModel,
} from '../../CRM/ContactGeneralForm';

export interface FormValuesModel
  extends SubmitButtonsFormValuesModel,
    ContactGeneralFormValuesModel {}

type SubmitType = (
  values: FormValuesModel,
  helpers: FormikHelpers<FormValuesModel>,
) => Promise<void>;

interface IProps {
  formRef?: Ref<FormikProps<FormValuesModel>>;
  initialFormValues: FormValuesModel | null;
  onSubmit: SubmitType | null;
  disabled?: boolean;
  showOnlySaveButton?: boolean;
}

const ApplicantInformationShortForm = ({
  formRef,
  initialFormValues,
  onSubmit,
  showOnlySaveButton,
  disabled,
}: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <Form<FormValuesModel>
      innerRef={formRef}
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit ? onSubmit : console.log}
      initialValues={initialFormValues}
      disabled={disabled}
      validationSchema={ApplicantInformationIndividualShortValidationSchema}
      renderForm={
        <>
          <SectionIntro
            title={t('applicant_information.general.title')}
            description={t('applicant_information.description_contact')}
          />
          <ContactGeneralForm />
          <HideIfDisabledForm>
            <SubmitButtons
              showBackButton={false}
              showOnlySaveButton={showOnlySaveButton}
            />
          </HideIfDisabledForm>
        </>
      }
    />
  );
};

export default ApplicantInformationShortForm;
