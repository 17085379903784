import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import CurrencyAutocomplete, {
  CurrencyAutocompleteProps,
} from '../../../../../../Forms/FormComponents/Autocompletes/Accounting/CurrencyAutocomplete';

const CircleBankAccountForm = () => {
  const { t } = useTranslation('accounts');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <FormField
        label={t('account_summary.create_account.form_fields.account_name')}
        name="circleAccount.accountName"
        component={FormInput}
      />

      <FormField<CurrencyAutocompleteProps>
        disabled
        label={t('account_summary.create_account.form_fields.currency')}
        name="circleAccount.currencyId"
        component={CurrencyAutocomplete}
        additionalProps={{
          initialValue:
            values.circleAccount.predefinedCurrencyOption || undefined,
        }}
      />
    </>
  );
};

export default CircleBankAccountForm;
