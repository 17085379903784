import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { DateHelpers } from 'helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { TransferApprovalStatuses } from 'enums/accounting/transfers';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  warning: [
    TransferApprovalStatuses.PendingApprovals,
    TransferApprovalStatuses.Processing,
    TransferApprovalStatuses.InReview,
    TransferApprovalStatuses.AMLReview,
    TransferApprovalStatuses.PendingTransmit,
    TransferApprovalStatuses.Scheduled,
  ],
  success: [TransferApprovalStatuses.Completed],
  error: [
    TransferApprovalStatuses.Rejected,
    TransferApprovalStatuses.Failed,
    TransferApprovalStatuses.BankRejected,
  ],
  info: [TransferApprovalStatuses.Expired, TransferApprovalStatuses.Cancelled],
};

interface IProps {
  transaction: { status: TransferApprovalStatuses; expiresAt?: number };
}

const TransactionStatus = memo(({ transaction }: IProps) => {
  const { t } = useTranslation(['accounts', 'common']);

  return (
    <StatusLabel
      status={transaction.status}
      statusMap={STATUS_MAP}
      content={
        <>
          {t(
            `transaction_statuses.${TransferApprovalStatuses[transaction.status]}`,
          )}
          {transaction.status == TransferApprovalStatuses.PendingApprovals &&
          transaction.expiresAt
            ? `, ${t('expires', { ns: 'common' })} ${DateHelpers.formatTimestampDateToString(
                transaction.expiresAt,
                DEFAULT_DATE_TIME_FORMAT,
              )}`
            : ''}
        </>
      }
    />
  );
});

export default TransactionStatus;
