import React, { memo } from 'react';

// components
import AddOwnership from './AddOwnership';
import OwnershipList from './OwnershipList';
import TotalPercentage from './TotalPercentage';
import { Col, Row } from 'antd';

const InnerForm = memo(() => {
  return (
    <>
      <OwnershipList />
      <Row justify="end">
        <Col>
          <TotalPercentage />
        </Col>
      </Row>
      <AddOwnership />
    </>
  );
});

export default InnerForm;
