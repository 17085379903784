import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';

interface IProps {
  reasonFieldLabel?: string;
}

const InnerForm = ({ reasonFieldLabel }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <FormField
      label={reasonFieldLabel || t('reason')}
      name="reason"
      component={FormInputTextArea}
      additionalProps={{
        autoSize: { minRows: 6, maxRows: 6 },
      }}
    />
  );
};

export default InnerForm;
