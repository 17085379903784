import { DateHelpers } from 'helpers/date';
import { FormValuesModel as ForgotUsernameFormValues } from 'components/Forms/TemplateForms/Auth/ForgotUsernameForm';
import {
  ActivateUserBody,
  IActivateUserBody,
  ILoginBody,
  LoginBody,
  LoginUsernameBody,
  SubmitForgotUsernameRequestBody,
} from '../../api/profile/userAPI';

const userAPIAdapter = {
  loginUsername: async (username: string): Promise<LoginUsernameBody> => {
    return {
      username: username.trim(),
    };
  },

  login: async (formValues: LoginBody): Promise<ILoginBody> => {
    const { username, password } = formValues;

    return {
      username: username.trim(),
      password,
    };
  },

  activateUser: async (
    formValues: ActivateUserBody,
  ): Promise<IActivateUserBody> => {
    const { username, otpCode, password } = formValues;

    return {
      username: username.trim(),
      tempToken: otpCode,
      password,
    };
  },

  submitForgotUsernameRequest: (
    formValues: ForgotUsernameFormValues,
  ): SubmitForgotUsernameRequestBody => {
    const { emailConfirmation, user } = formValues;

    return {
      otp: emailConfirmation.otp,
      name: user.name.trim(),
      email: user.email.trim(),
      dateOfBirth: user.dateOfBirth
        ? DateHelpers.formatToYYYYMMDD(user.dateOfBirth)
        : '',
      clientGroupName: user.clientGroup.trim(),
    };
  },
};

export { userAPIAdapter };
