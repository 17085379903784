import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';

const InnerForm = () => {
  const { t } = useTranslation('profile');
  return (
    <FormField
      name="name"
      component={FormInput}
      label={t(
        'security.two_step_verification.security_key.edit_key.form_fields.name',
      )}
    />
  );
};

export default InnerForm;
