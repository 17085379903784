import React, { memo, useEffect, useMemo, useState } from 'react';

// helpers
import useTranslation from '../../hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { StateModel as SettingsStateModel } from 'redux/reducers/settings';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import Tabs from '../../components/Tabs/Tabs';
import SentTab from '../../modules/Profile/Messages/Tabs/SentTab';
import InboxTab from '../../modules/Profile/Messages/Tabs/InboxTab';
import SendMessage from '../../modules/Profile/Messages/SendMessage';
import NewMessagesBadge from '../../components/Additional/Messaging/NewMessagesBadge';

const Messages = memo(() => {
  const { t } = useTranslation('profile');
  const [updateTableTrigger, updateTable] = useState({});
  const { layoutVariant } = useSelector<StateModel, SettingsStateModel>(
    (state) => state.settings,
  );
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      extra: () => (
        <SendMessage
          onSent={onMessageSent}
          hideRecipientsField={layoutVariant !== 'ApprovedClientGroup'}
        />
      ),
    }));
    return () => clearContext();
  }, [setPageLayoutOptions, clearContext, layoutVariant]);

  const onMessageSent = () => {
    updateTable({});
  };

  const tabsProps = useMemo(
    () => ({
      defaultActiveKey: 'inbox',
      tabs: [
        {
          key: 'inbox',
          title: (
            <>
              {t('messages.inbox')}
              <NewMessagesBadge />
            </>
          ),
          content: <InboxTab updateMessagesTrigger={updateTableTrigger} />,
        },
        {
          key: 'sent',
          title: t('messages.sent'),
          content: <SentTab updateMessagesTrigger={updateTableTrigger} />,
        },
      ],
    }),
    [updateTableTrigger],
  );

  return <Tabs {...tabsProps} />;
});

export default Messages;
