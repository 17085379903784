import * as yup from 'yup';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  OTP_VALIDATION_RULE,
} from '../common';

export const InvitationCodeValidation = yup.object().shape({
  code: OTP_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'invitation:invitation_code.form_fields.code' },
  }),

  termsOfUse: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:warnings.terms_and_conditions_must_be_accepted',
  ).oneOf([true], 'form:warnings.terms_and_conditions_must_be_accepted'),
});
