import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { FormValuesModel, OwnershipFormItem } from '../..';

// components
import AddButton from '../../../../../../DesignSystem/Common/Buttons/AddButton';
import HideIfDisabledForm from '../../../../../../Forms/HideIfDisabledForm';
import AddOwnershipRelationshipForm from './AddOwnershipRelationshipForm';
import { Card } from 'antd';

const AddOwnership = () => {
  const { t } = useTranslation('account_management');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();
  const [isAddOwnershipVisible, setIsAddOwnershipVisible] = useState(false);

  const handleAddButtonClick = () => {
    setIsAddOwnershipVisible(true);
  };

  const handleNewRelationshipFormCancel = () => {
    setIsAddOwnershipVisible(false);
  };

  const handleAddRelationship = (newOwnership: OwnershipFormItem) => {
    const ownershipsCopy = [...values.ownerships, newOwnership];
    setFieldValue('ownerships', ownershipsCopy);
    setIsAddOwnershipVisible(false);
  };

  return (
    <>
      {!isAddOwnershipVisible && (
        <HideIfDisabledForm hideIfSubmitting>
          <AddButton onClick={handleAddButtonClick}>
            {t('client_profile.ownership_modal.new.add', {
              name: values.ownershipTemplate.parentName,
            })}
          </AddButton>
        </HideIfDisabledForm>
      )}

      {isAddOwnershipVisible && (
        <StyledCard
          title={t('client_profile.ownership_modal.new.add', {
            name: values.ownershipTemplate.parentName,
          })}
        >
          <AddOwnershipRelationshipForm
            onCancel={handleNewRelationshipFormCancel}
            onAdd={handleAddRelationship}
          />
        </StyledCard>
      )}
    </>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default AddOwnership;
