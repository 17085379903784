import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { securityAPI } from '../../../../api/profile/securityAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import Text from '@core_components/Text';
import DevicesTable from '../../../../components/Tables/TableTemplates/User/DevicesTable';
import { Modal } from 'antd';
import { CardWrapper } from 'components/Additional/CardWrapper';

interface IProps {
  updateViewTrigger: any;
  updateView: () => void;
}

const Devices = ({ updateViewTrigger, updateView }: IProps) => {
  const { t } = useTranslation(['profile', 'common']);
  const [currentPage, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      securityAPI.fetchUserDevices({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [updateViewTrigger, currentPage],
  );

  const handleSignOut = async (sessionId: string) => {
    Modal.confirm({
      content: t(
        'security.devices.sign_out_device.sign_out_confirmation_message',
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await securityAPI.deleteSession(sessionId);
        updateView();
      },
    });
  };

  return (
    <CardWrapper>
      <Text variant="h4" weight="semi-bold">
        {t('security.devices.title')}
      </Text>
      <DevicesTable
        data={response?.data || []}
        total={response?.total || 0}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        loading={loading}
        onSignOut={handleSignOut}
      />
    </CardWrapper>
  );
};

export default Devices;
