import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { IOverdraftModel } from '../../../../../typings/limitConfiguration/overdraft';

// components
import Link from '@common_components/Texts/Link';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<IOverdraftModel>;

const OverdraftTable = ({ ...rest }: IProps) => {
  const { t } = useTranslation('account_management');

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('overdraft.table.account_number'),
        width: '30%',
        key: 'accountNumber',
        render: (record: IOverdraftModel) => (
          <Link
            to={{
              pathname: RoutePaths.Accounts_AccountActivity,
              search: `?accountNumber=${record.accountNumber}`,
            }}
          >
            {record.accountNumber}
          </Link>
        ),
      },
      {
        title: t('overdraft.table.account_name'),
        width: '30%',
        key: 'accountName',
        render: (record: IOverdraftModel) => record.accountName,
      },
      {
        title: t('overdraft.table.currency'),
        width: '30%',
        key: 'currencyCode',
        render: (record: IOverdraftModel) => record.currencyCode,
      },
      {
        title: t('overdraft.table.overdraft'),
        width: '10%',
        key: 'overchargeAmount',
        render: (record: IOverdraftModel) => record.overchargeAmount,
      },
    ];
  }, []);

  return <Table {...rest} columns={columns} />;
};

export default OverdraftTable;
