import * as Yup from 'yup';
import { PASSWORD_VALIDATION_RULE, STRING_VALIDATION_RULE } from '../common';

export const ChangePasswordValidationSchema = Yup.object().shape({
  oldPassword: PASSWORD_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'profile:security.change_password.form_fields.old_password',
    },
  }),
  newPassword: PASSWORD_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'profile:security.change_password.form_fields.password' },
  }),
  confirmNewPassword: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('newPassword'), ''],
    {
      key: 'form:validation_messages.invalid_value_with_label',
      values: { label: 'auth:open_an_account.form_fields.confirm_password' },
    },
  ).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'profile:security.change_password.form_fields.confirm_password',
    },
  }),
});
