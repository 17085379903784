import APIConfig from '../../config/api';
import { IUserDevice, ISession } from '../../typings/profile/security';
import { APIService, AUTHORIZATION_HEADER } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface ISessionsParams extends FetchParamsModel {
  inactive: boolean;
}

interface IChangePasswordModel {
  oldPassword: string;
  password: string;
}

export interface GetAuthenticatorConfigResponse {
  qrCode: string;
  seedCode: string;
}

interface EnableAuthenticatorResponse {
  code: string;
  status: boolean;
}

interface FetchVerificationCodeResponse {
  code: string;
}

interface VeryficationEmailModel {
  email: string;
  otp: string;
}

// TODO: connect to real API
const securityAPI = {
  sendChangePasswordOTP: () => {
    return new Promise((resolve) => setTimeout(() => resolve(true), 1000));
  },

  resendChangePasswordOTP: () => {
    return new Promise((resolve) => setTimeout(() => resolve(true), 1000));
  },

  changePassword: (body: IChangePasswordModel) => {
    return APIService.post(
      `${APIConfig.authApi}/users/password/change`,
      body,
    ).then((response) => response.data);
  },

  fetchUserSessions: (params: ISessionsParams) => {
    return APIService.get<FetchResponseModel<ISession>>(
      `${APIConfig.authApi}/sessions`,
      { params },
    ).then((response) => response.data);
  },

  setSessionDuration: (seconds: number) => {
    return APIService.patch(`${APIConfig.authApi}/sessions/duration`, {
      seconds,
    }).then((response) => response.data);
  },

  deleteSession: (sessionId: string) => {
    return APIService.delete(`${APIConfig.authApi}/sessions/${sessionId}`).then(
      (response) => response.data,
    );
  },

  fetchUserDevices: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IUserDevice>>(
      `${APIConfig.authApi}/sessions/devices`,
      {
        params,
      },
    ).then((response) => response.data);
  },

  fetchAuthenticatorConfig: () => {
    return APIService.get<GetAuthenticatorConfigResponse>(
      `${APIConfig.authApi}/2fa`,
    ).then((response) => response.data);
  },

  enableAuthenticator: (otp: string) => {
    return APIService.post<EnableAuthenticatorResponse>(
      `${APIConfig.authApi}/2fa/verify`,
      { otp },
    ).then((response) => response.data);
  },

  disableAuthenticator: (otp: string) => {
    return APIService.post<boolean>(`${APIConfig.authApi}/2fa/disable`, {
      otp,
    }).then((response) => response.data);
  },

  fetchIdentityVerificationCode: (otp?: string) => {
    return APIService.post<FetchVerificationCodeResponse>(
      `${APIConfig.authApi}/users/identity-code`,
      { otp },
    ).then((response) => response.data);
  },

  verifyEmail: (values: VeryficationEmailModel) => {
    return APIService.patch(`${APIConfig.authApi}/email/confirm`, values).then(
      (response) => response.data,
    );
  },

  resendOTP: () => {
    return APIService.post<{ tempToken: { aliveTo: string } }>(
      `${APIConfig.authApi}/email/confirmation/resend`,
    ).then(({ data }) => data);
  },

  resendActivateEmailOTP: (username: string) => {
    return APIService.post(
      `${APIConfig.authPublicApi}/email/resend`,
      { username },
      {
        addGeolocationDataToHeaders: true,
      },
    ).then(({ data }) => data);
  },

  resendSMSOTPforResetPasswordProcess: (token: string) => {
    return APIService.get(`${APIConfig.authPublicApi}/sms/resend`, {
      headers: { [AUTHORIZATION_HEADER]: `Bearer ${token}` },
      addFingerprintToHeaders: true,
    }).then(({ data }) => data);
  },
};

export { securityAPI };
