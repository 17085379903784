import React from 'react';

// constants
import { TWO_COL_GRID_SIZES } from '../../../../../../constants/grids';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import SectionIntro from '../../../../../DesignSystem/Common/Texts/SectionIntro';
import TrueFalseSelect from '../../../../FormComponents/SelectInputs/TrueFalseSelect';
import CountrySelect, {
  IProps as CountrySelectProps,
} from '../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import { Col, Row } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        title={t('identification.title')}
        description={t('identification.description_entity')}
      />

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES} pull={0}>
          <FormField
            label={t(
              'identification.form_fields.organization.identification_questions.is_regulated',
            )}
            name="isRegulated"
            component={TrueFalseSelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          {values.isRegulated && (
            <FormField<CountrySelectProps>
              label={t(
                'identification.form_fields.organization.identification_questions.regulated_in_countries',
              )}
              name="regulationCountry"
              component={CountrySelect}
              additionalProps={{ mode: 'multiple' }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default InnerForm;
