import React, { memo, useMemo } from 'react';

// helpers
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { OnboardingEntryTypes } from '../../../../../../../enums/onboarding/crm';

// components
import ContactShortInfoForm from '../../../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';
import OrganizationShortInfoForm from '../../../../../../Forms/TemplateForms/CRM/OrganizationShortInfoForm';

const NodeInformationSection = memo(() => {
  const { values } = useFormikContext<FormValuesModel>();

  const NodeInformationContent = useMemo(() => {
    if (values.entryType === null) {
      return null;
    }

    switch (values.entryType) {
      case OnboardingEntryTypes.Contact:
        return (
          <ContactShortInfoForm
            namePrefix="contact"
            disabled={values.disableNodeForm}
            hideAccessFieldsWhenNotOnline
          />
        );

      case OnboardingEntryTypes.Organization:
        return (
          <OrganizationShortInfoForm
            namePrefix="organization"
            disabled={values.disableNodeForm}
          />
        );
    }
  }, [values.entryType, values.disableNodeForm]);

  return NodeInformationContent;
});

export default NodeInformationSection;
