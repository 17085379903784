import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from '../../../../enums/profile/auth';
import { StateModel as AuthStateModel } from '../../../../redux/reducers/auth';

// components
import Text from '@core_components/Text';
import SecurityKey from './SecurityKey';
import Authenticator from './Authenticator';
import ChangeDefaultAuthenticator from './ChangeDefaultAuthenticator';
import { CardWrapper } from 'components/Additional/CardWrapper';
import { Col, Divider, Row } from 'antd';

const TwoFactorAuthentication = () => {
  const { t } = useTranslation('profile');
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const canShowChangeDefaultAuthenticator =
    profileData?.isTwoFactorFidoEnabled ||
    profileData?.isTwoFactorAuthenticationEnabled;
  const isFIDOAuthenticatorDefault =
    profileData?.default2FAType === TwoFactorVerificationTypes.Fido &&
    profileData?.isTwoFactorFidoEnabled;
  const isAuthenticatorDefault =
    profileData?.default2FAType === TwoFactorVerificationTypes.Authenticator &&
    profileData?.isTwoFactorAuthenticationEnabled;

  return (
    <CardWrapper>
      <Row gutter={[16, 16]}>
        <TitleCol xl={18} lg={18} md={12} sm={24} xs={24}>
          <Text variant="h4" weight="semi-bold">
            {t('security.two_step_verification.title')}
          </Text>
          <Text variant="body1">
            {t('security.two_step_verification.description')}
          </Text>
        </TitleCol>

        {canShowChangeDefaultAuthenticator && (
          <Col xl={6} lg={6} md={12} sm={24} xs={24}>
            <Text variant="h4">
              {t('security.two_step_verification.default_authenticator')}
            </Text>
            <ChangeDefaultAuthenticator />
          </Col>
        )}
      </Row>

      <StyledDivider />

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Authenticator isDefault={isAuthenticatorDefault} />
        </Col>

        <Col span={24}>
          <SecurityKey isDefault={isFIDOAuthenticatorDefault} />
        </Col>
      </Row>
    </CardWrapper>
  );
};

const TitleCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const StyledDivider = styled(Divider)`
  margin: 0px !important;
`;

export default TwoFactorAuthentication;
