import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { StyledComponentProps } from '../../../../typings/common';

// components
import { Input as AntDInput } from 'antd';

export type InputSizes = 'large' | 'middle' | 'small';

export interface InputPasswordProps extends StyledComponentProps {
  name?: string;
  size?: InputSizes;
  value?: string;
  disabled?: boolean;
  placeholder?: string;

  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

const InputPassword = memo(
  ({
    value,
    onChange,
    onBlur,
    onFocus,
    disabled,
    placeholder,
    name,
    size,
    ...rest
  }: InputPasswordProps) => {
    const { t } = useTranslation('form');

    return (
      <AntDInput.Password
        size={size}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        placeholder={placeholder || t('enter_value')}
        {...rest}
      />
    );
  },
);

export default InputPassword;
