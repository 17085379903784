import React, { useEffect } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import useTranslation from '../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import Text from '@core_components/Text';
import Tabs from '../../components/Tabs/Tabs';
import ApprovalsTab from '../../modules/AccountManagement/AccountManagementRules/ApprovalsTab';
import ApprovalRulesTab from '../../modules/AccountManagement/AccountManagementRules/ApprovalRulesTab';
import ApprovalGroupsTab from '../../modules/AccountManagement/AccountManagementRules/ApprovalGroupsTab';
import RenderBadgeByRoutePath from 'components/Additional/RenderBadgeByRoutePath';

const AccountManagementRules = () => {
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const { t } = useTranslation('account_management');

  const [
    hasApprovalGroupsViewAccess,
    hasApprovalRulesViewAccess,
    hasApprovalWorkflowViewAccess,
  ] = useUserAccess([
    'AccountManagement_Rules_ApprovalGroups_View',
    'AccountManagement_Rules_ApprovalRules_View',
    'AccountManagement_Rules_Workflow_View',
  ]);

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      content: <Text>{t('account_management_rules.description')}</Text>,
    }));

    return () => clearContext();
  }, [setPageLayoutOptions, clearContext]);

  const tabs = [
    {
      key: 'approval-groups',
      title: t('approval_groups.title'),
      content: <ApprovalGroupsTab />,
      hidden: !hasApprovalGroupsViewAccess,
    },
    {
      key: 'approval-rules',
      title: t('approval_rules.title'),
      content: <ApprovalRulesTab />,
      hidden: !hasApprovalRulesViewAccess,
    },
    {
      key: 'pending-approvals',
      title: (
        <>
          {t('pending_approvals.title')}
          <RenderBadgeByRoutePath
            path={RoutePaths.AccountManagement_AccountManagementRules}
          />
        </>
      ),
      content: <ApprovalsTab />,
      hidden: !hasApprovalWorkflowViewAccess,
    },
  ];

  return <Tabs tabs={tabs} renderOnChange />;
};

export default AccountManagementRules;
