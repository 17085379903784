import React, { memo } from 'react';

// helpers
import { colorsTheme } from '../../../../../resources/theme/styled/colors';
import { StyledComponentProps } from 'typings/common';

// components
import IconSVG from '../../../Core/IconSVG';
import IconButton from '../../../Core/IconButton';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/remix-icons/delete-bin-6-line.svg';

interface IProps extends StyledComponentProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const DeleteButton = memo(({ onClick, ...rest }: IProps) => {
  return (
    <IconButton
      {...rest}
      icon={<IconSVG component={DeleteIcon} color={colorsTheme.colorError} />}
      type="bordered"
      danger
      onClick={onClick}
    />
  );
});

export default DeleteButton;
