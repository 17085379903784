import React, { useState } from 'react';

// components
import ChangePassword from '../../modules/Profile/Security/ChangePassword';
import Devices from '../../modules/Profile/Security/Devices';
import Sessions from '../../modules/Profile/Security/Sessions';
import SessionSettings from '../../modules/Profile/Security/SessionSettings';
import IdentityVerification from '../../modules/Profile/Security/IdentityVerification';
import TwoFactorAuthentication from '../../modules/Profile/Security/TwoFactorAuthentication';

const Security = () => {
  const [updateViewTrigger, setUpdateViewTrigger] = useState({});

  const updateView = () => {
    setUpdateViewTrigger({});
  };

  return (
    <>
      <TwoFactorAuthentication />

      <IdentityVerification />

      <ChangePassword />

      <Devices updateViewTrigger={updateViewTrigger} updateView={updateView} />

      <Sessions updateViewTrigger={updateViewTrigger} updateView={updateView} />

      <SessionSettings />
    </>
  );
};

export default Security;
