// * constants
const USER_ACCESS_TOKEN = 'token';
const USER_REFRESH_TOKEN = 'refresh_token';
const ACTIVE_APPLICATION_ID = 'active_application';
const AMOUNT_OF_ATTEMPTS_RESENDING_OTP_SMS =
  'amount_of_attempts_resending_otp_sms';
const AMOUNT_OF_ATTEMPTS_RESENDING_OTP_EMAIL =
  'amount_of_attempts_resending_otp_email';

const LocalStorageHelpers = {
  getAccessToken: function (): string {
    return localStorage.getItem(USER_ACCESS_TOKEN) || '';
  },

  setAccessToken: function (token: string) {
    localStorage.setItem(USER_ACCESS_TOKEN, token);
  },

  getRefreshToken: function (): string {
    return localStorage.getItem(USER_REFRESH_TOKEN) || '';
  },

  setRefreshToken: function (token: string) {
    localStorage.setItem(USER_REFRESH_TOKEN, token);
  },

  setAuthenticationTokens: function (
    accessToken: string,
    refreshToken: string,
  ) {
    localStorage.setItem(USER_ACCESS_TOKEN, accessToken);
    localStorage.setItem(USER_REFRESH_TOKEN, refreshToken);
  },

  getActiveApplication: function (): string {
    return localStorage.getItem(ACTIVE_APPLICATION_ID) || '';
  },

  setActiveApplication: function (applicationId: string) {
    localStorage.setItem(ACTIVE_APPLICATION_ID, applicationId);
  },

  getAttemptsOfResendingEmailOTP: function () {
    return localStorage.getItem(AMOUNT_OF_ATTEMPTS_RESENDING_OTP_EMAIL) || 0;
  },

  setAttemptsOfResendingEmailOTP: function (amount: number) {
    localStorage.setItem(
      AMOUNT_OF_ATTEMPTS_RESENDING_OTP_EMAIL,
      String(amount),
    );
  },

  getAttemptsOfResendingSmsOTP: function () {
    return localStorage.getItem(AMOUNT_OF_ATTEMPTS_RESENDING_OTP_SMS) || 0;
  },

  setAttemptsOfResendingSmsOTP: function (amount: number) {
    localStorage.setItem(AMOUNT_OF_ATTEMPTS_RESENDING_OTP_SMS, String(amount));
  },

  clear: function () {
    localStorage.clear();
  },
};

export { LocalStorageHelpers };
