import queryString from 'query-string';
import { changeView } from '../redux/actions/view';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface IProps {
  param: string;
  noParamRedirect?: string;
  noParamCallback?: () => void;
}

/**
 * @desc Custom hook to handle query parameter
 * @param param - query parameter name
 * @param noParamRedirect - redirection path if no parameter found in url
 * @param noParamCallback - callback to call if no parameter found in url
 * @example
 * url: http://localhost:3006/accounts/account?id=someId
 * usage: const accountId = useQueryParam({ param: "id" }); - accountId will be "someId"
 */
const useQueryParam = ({
  param,
  noParamRedirect,
  noParamCallback,
}: IProps): string => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => navigate(path, state)));
  const [paramValue, setParamValue] = useState('');

  useEffect(() => {
    const params: any = queryString.parse(location.search);

    if (!params || !params[param]) {
      noParamCallback && noParamCallback();
      noParamRedirect && historyPush(noParamRedirect);
    } else {
      setParamValue(params[param]);
    }
  }, [location.search]);

  return paramValue;
};

export default useQueryParam;
