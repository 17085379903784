import React from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { ApprovalEntryNewModel } from '../../../../../../../../../typings/approvalManagement/accountManagement';
import { ApprovalWorkflowChangeTypes } from '../../../../../../../../../enums/approvalManagement/approvalManagement';

// components
import SectionIntro from '../../../../../../../../../components/DesignSystem/Common/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export interface ApprovalGroupTemplateData {
  groupName: string;
  participants: string;
  description: string;
}

interface IProps {
  approvalEntry: ApprovalEntryNewModel<ApprovalGroupTemplateData>;
}

const ApprovalGroup = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderContent = () => {
    switch (approvalEntry.entry.changeType) {
      case ApprovalWorkflowChangeTypes.Create:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as ApprovalGroupTemplateData}
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Delete:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.removed')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as ApprovalGroupTemplateData}
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Edit:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.old')}
            />
            <InnerTemplate
              data={approvalEntry.changes.old as ApprovalGroupTemplateData}
            />

            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as ApprovalGroupTemplateData}
            />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default ApprovalGroup;
