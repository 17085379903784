import React, { useCallback, useEffect, useState } from 'react';

// helpers
import { StateModel } from '../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { ContactHelpers } from 'helpers/crm/contact';
import { approvalGroupsAPI } from '../../../../../../api/approvalManagement/approvalGroupsAPI';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { userManagementAPINew } from 'api/accountManagement/userManagementAPINew';
import { ApprovalWorkflowType } from 'components/ModalDialogs/TemplateModalDialogs/Approval/ApprovalGroupModal';
import { IGroupParticipantShort } from '../../../../../../typings/approvalManagement/accountManagement';
import { accountManagementRulesAPI } from '../../../../../../api/accountManagement/accountManagementRulesAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from 'constants/global';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

interface IProps {
  value: IGroupParticipantShort[];
  approvalWorkflowType: ApprovalWorkflowType;
  onChange: (newValue: IGroupParticipantShort[]) => void;
}

const ApprovalGroupParticipantsAutocomplete = ({
  approvalWorkflowType,
  value,
  onChange,
  ...rest
}: IProps) => {
  const [privateValue, setPrivateValue] = useState<string[]>(
    value ? value.map((e) => e.userId) : [],
  );
  const clientGroupId = useSelector<StateModel, string | undefined>(
    (state) => state.applications.activeApplication?.clientGroup?._id,
  );
  const [participantsArray, setParticipants] = useState<
    AutocompleteOption<IGroupParticipantShort>[]
  >([]);

  //  Reinitialize local value if the original value was cleared
  useEffect(() => {
    if (!value || (!value.length && privateValue.length)) {
      setPrivateValue([]);
    }
  }, [value]);

  const getParticipantsBasedOnWorkflowType = async (
    workflowType: ApprovalWorkflowType,
    searchQuery: string,
  ): Promise<AutocompleteOption<IGroupParticipantShort>[]> => {
    if (!clientGroupId) {
      return [];
    }

    switch (workflowType) {
      case 'transactions': {
        const response =
          await approvalGroupsAPI.fetchApprovalGroupParticipants();
        return response.data.map((e) => ({
          id: e.userId,
          model: e,
          label: e.userFullName,
        }));
      }

      case 'account-management-transactions': {
        const response =
          await accountManagementRulesAPI.fetchApprovalGroupParticipants();
        return response.data.map((e) => ({
          id: e.userId,
          model: e,
          label: e.userFullName,
        }));
      }

      case 'onboarding-approval-group': {
        const response = await userManagementAPINew.fetchClientGroupMembers(
          clientGroupId,
          {
            limit: AUTOCOMPLETE_RESULT_LIMIT,
            page: 1,
            type: OnboardingEntryTypes.Contact,
            isPossibleMemberOfInitialApprovalGroup: true,
            search: searchQuery,
          },
        );

        return response.data.map((e) => {
          const userFullName = ContactHelpers.getFormattedContactName(
            e.contact.firstName,
            e.contact.lastName,
            e.contact.middleName,
          );
          return {
            id: e.id,
            model: {
              userId: e.id,
              userFullName,
            },
            label: userFullName,
          };
        });
      }
    }
  };

  const handleChange = (newValue: string[]) => {
    let arrayCopy = value.slice();

    // delete item case
    if (newValue.length < arrayCopy.length) {
      arrayCopy = arrayCopy.filter((e) => newValue.includes(e.userId));
    } else {
      // take only new items
      const newItems = newValue.filter((el) => !privateValue.includes(el));
      // push new items to array
      newItems.forEach((newItemKey) => {
        const itemIndex = participantsArray.findIndex(
          (el) => el.id === newItemKey,
        );
        participantsArray[itemIndex].model &&
          arrayCopy.push(participantsArray[itemIndex].model);
      });
    }

    onChange(arrayCopy);
    setPrivateValue(newValue);
  };

  const fetchParticipants = useCallback(
    async (searchQuery) => {
      const options = await getParticipantsBasedOnWorkflowType(
        approvalWorkflowType,
        searchQuery,
      );
      setParticipants(options);
      return options;
    },
    [approvalWorkflowType],
  );

  return (
    <FormAutocomplete<IGroupParticipantShort>
      {...rest}
      mode="multiple"
      value={privateValue}
      onChange={handleChange as any}
      fetchData={fetchParticipants}
    />
  );
};

export default ApprovalGroupParticipantsAutocomplete;
