import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { FormValuesModel, RegisterSecurityKeySteps } from '..';

// components
import InitialStep from './InitialStep';
import RegisterKeyStep from './RegisterKeyStep';
import SetupNameForKeyStep from './SetupNameForKeyStep';

const InnerForm = memo(() => {
  const { values } = useFormikContext<FormValuesModel>();

  const renderStep = (step: RegisterSecurityKeySteps) => {
    switch (step) {
      case RegisterSecurityKeySteps.Initial:
        return <InitialStep />;
      case RegisterSecurityKeySteps.RegisterKey:
        return <RegisterKeyStep />;
      case RegisterSecurityKeySteps.SetupNameForKey:
        return <SetupNameForKeyStep />;
    }
  };

  return <ContentWrapper>{renderStep(values.currentStep)}</ContentWrapper>;
});

const ContentWrapper = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export default InnerForm;
