import * as yup from 'yup';
import {
  CRM_CONTACT_NAME_MAX_LENGTH,
  NAME_MAX_LENGTH,
} from '../../constants/global';
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from '../../enums/onboarding/crm';
import {
  EMAIL_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  NULLABLE_OBJECT_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  PHONE_ASYNC_VALIDATION_RULE,
} from '../common';

export const AdditionalFieldValidationSchema = yup.object().shape({
  value: yup
    .mixed()
    .when(['type'], (values, schema): any => {
      switch (values[0]) {
        case AdditionalFieldTypes.Percentage:
          return yup
            .number()
            .typeError('form:validation_messages.only_numbers')
            .min(0, {
              key: 'form:validation_messages.min_number_value_with_label',
              values: { label: 'common:percentage', number: 0 },
            })
            .max(100, {
              key: 'form:validation_messages.max_number_value_label',
              values: { label: 'common:percentage', number: 100 },
            });

        case AdditionalFieldTypes.Form:
        case AdditionalFieldTypes.Document:
          return yup.array().min(1, 'form:validation_messages.required');

        case AdditionalFieldTypes.String:
          return STRING_VALIDATION_RULE;

        default:
          return schema;
      }
    })
    .when('options.onboardingRequired', {
      is: true,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),
});

export const ContactShortInfoFormValidationSchema = yup.object().shape({
  firstName: CRM_NAME_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.individual.first_name',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.individual.first_name',
    },
  }),

  middleName: CRM_NAME_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.individual.middle_name',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }),

  lastName: CRM_NAME_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.individual.last_name',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.individual.last_name',
    },
  }),

  email: EMAIL_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.individual.email_address',
    },
  }),

  phone: PHONE_ASYNC_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.individual.phone_number',
    },
  }),

  isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'onboarding:identification.form_fields.individual.pep_title',
    },
  }),

  pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
    is: true,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: {
          label:
            'onboarding:identification.form_fields.individual.pep_information_title',
        },
      }),
  }),

  isOnlineUser: NULLABLE_BOOLEAN_VALIDATION_RULE.when(
    'isPermissionsSelectAvailable',
    {
      is: true,
      then: (schema) => schema.required('form:validation_messages.required'),
    },
  ),

  adminPermissionType: NULLABLE_STRING_VALIDATION_RULE.when('isOnlineUser', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  accountPermissions: NULLABLE_STRING_VALIDATION_RULE.when('isOnlineUser', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),
});

export const OrganizationShortInfoFormValidationSchema = yup.object().shape({
  name: CRM_NAME_VALIDATION_RULE.max(NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.organization.legal_name',
      number: NAME_MAX_LENGTH,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'onboarding:structure.relationships.form_fields.organization.legal_name',
    },
  }),

  isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'onboarding:identification.form_fields.organization.identification_questions.is_regulated_title',
    },
  }),

  regulatedCountries: yup
    .array()
    .of(STRING_VALIDATION_RULE)
    .when('isRegulated', {
      is: true,
      then: (schema) =>
        schema.min(1, {
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:identification.form_fields.organization.identification_questions.regulated_in_countries_title',
          },
        }),
    }),
});

export const RelationshipTemplateFormValidationSchema = yup.object().shape({
  id: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'common:relationship' },
  }),
  additionalFields: yup.array().of(AdditionalFieldValidationSchema),
});

export const RelationshipFormValidationSchema = yup.object().shape({
  id: NULLABLE_STRING_VALIDATION_RULE,

  parent: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE,
    type: NULLABLE_STRING_VALIDATION_RULE,
  }),

  child: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    type: NULLABLE_STRING_VALIDATION_RULE,
    label: STRING_VALIDATION_RULE,
  }),

  relationshipTemplate: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    childRelationshipLabel: STRING_VALIDATION_RULE,
  }),

  additionalFields: yup.array().of(AdditionalFieldValidationSchema),
});

export const EditNodeFormValidationSchema = yup.object().shape({
  clientGroupId: STRING_VALIDATION_RULE,
  activeScopeId: STRING_VALIDATION_RULE,
  clientUserId: NULLABLE_STRING_VALIDATION_RULE,

  entryType: NULLABLE_STRING_VALIDATION_RULE,

  contact: NULLABLE_OBJECT_VALIDATION_RULE.when('entryType', {
    is: OnboardingEntryTypes.Contact,
    then: () => ContactShortInfoFormValidationSchema,
  }),

  organization: NULLABLE_OBJECT_VALIDATION_RULE.when('entryType', {
    is: OnboardingEntryTypes.Organization,
    then: () => OrganizationShortInfoFormValidationSchema,
  }),

  relationships: yup.array().of(RelationshipFormValidationSchema),
});

export const AddNewNodeFormValidationSchema = yup.object().shape({
  clientGroupId: STRING_VALIDATION_RULE,
  activeApplicationScopeId: STRING_VALIDATION_RULE,

  template: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE.required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label:
          'onboarding:structure.relationships.form_fields.relationship_type',
      },
    }),
  }),

  contact: NULLABLE_OBJECT_VALIDATION_RULE.when('template.visibleFor', {
    is: OnboardingEntryTypes.Contact,
    then: () => ContactShortInfoFormValidationSchema,
  }),

  organization: NULLABLE_OBJECT_VALIDATION_RULE.when('template.visibleFor', {
    is: OnboardingEntryTypes.Organization,
    then: () => OrganizationShortInfoFormValidationSchema,
  }),

  relationships: yup
    .array()
    .of(RelationshipFormValidationSchema)
    .min(1, 'form:validation_messages.required'),
});
