import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import FormInputPhoneNumber from '@common_components/Form/FormInputPhoneNumber';

const InformationStep = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <FormField
        label={t('account_recovery.information_step.form_fields.email')}
        name="email"
        disabled
        component={FormInput}
      />
      <FormField
        label={t('account_recovery.information_step.form_fields.phone')}
        name="phone"
        component={FormInputPhoneNumber}
      />
    </>
  );
};

export default InformationStep;
