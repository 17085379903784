import React from 'react';

// helpers
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { IKeyRelationshipTemplate } from 'typings/application/key-relationship-templates';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import { keyRelationshipTemplatesAPI } from '../../../../../../api/crm/keyRelationshipTemplatesAPI';
import {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface EntityTypeCategoryAutocompleteProps
  extends AutocompleteProps<IKeyRelationshipTemplate> {
  isVisible?: boolean;
  clientGroupEntryType: OnboardingEntryTypes;
}

const EntityTypeCategoryAutocomplete = ({
  isVisible,
  clientGroupEntryType,
  ...rest
}: EntityTypeCategoryAutocompleteProps) => {
  const fetchTypeCategories = async (searchText: string) => {
    let result: AutocompleteOption<IKeyRelationshipTemplate>[] = [];

    const { data } =
      await keyRelationshipTemplatesAPI.fetchKeyRelationshipTemplates({
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        page: 1,
        search: searchText || undefined,
        isVisible,
      });

    result = data.map((typeCategory) => ({
      id: typeCategory._id,
      label: typeCategory.name,
      model: typeCategory,
    }));

    if (clientGroupEntryType === OnboardingEntryTypes.Contact) {
      result = result.filter(
        (e) => e.model?.visibleFor === clientGroupEntryType,
      );
    }

    return result;
  };

  return <FormAutocomplete {...rest} fetchData={fetchTypeCategories} />;
};

export default EntityTypeCategoryAutocomplete;
