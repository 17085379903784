import React from 'react';

// constants
import { TWO_COL_GRID_SIZES } from '../../../../../../constants/grids';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import SectionIntro from '../../../../../DesignSystem/Common/Texts/SectionIntro';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import TrueFalseSelect from '../../../../FormComponents/SelectInputs/TrueFalseSelect';
import AreYouPepTooltip from '../../../../../Tooltips/TemplateTooltips/AreYouPepTooltip';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import { Col, Row } from 'antd';

interface IProps {
  mainSectionTooltip?: React.ReactNode;
}

const InnerForm = ({ mainSectionTooltip }: IProps) => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        title={
          <>
            {t('identification.title')} {mainSectionTooltip}
          </>
        }
        description={t('identification.description_individual')}
      />

      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={
              <DivAlignCenter>
                {t('identification.form_fields.individual.is_pep')}{' '}
                <AreYouPepTooltip />
              </DivAlignCenter>
            }
            name="isPEP"
            component={TrueFalseSelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          {values.isPEP && (
            <FormField
              label={t('identification.form_fields.individual.pep_information')}
              name="pepInformation"
              component={FormInputTextArea}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default InnerForm;
