import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { TransactionTypes } from 'enums/approvalManagement/approvalManagement';
import { TransactionDocument } from 'typings/accounting/transaction';
import { TransactionSourceTypes } from 'enums/accounting/transfers';

// components
import DocumentsValue from '../../DocumentsValue';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

export type InternalTransactionRecipientData = {
  type: TransactionSourceTypes;
  amount: string;
  postingDate: string;
  valueDate: string;
  relatedDocuments: TransactionDocument[];
  transactionNumber: string;
  orderingCustomer: {
    accountName: string;
    accountNumber: string;
  };
  beneficiary: {
    accountName: string;
    accountNumber: string;
    reference: string;
  };
};

interface IProps {
  journalEntryId: number | null;
  transaction: InternalTransactionRecipientData;
  onUpload?: () => void;
}

const ValueWrapper = memo(({ children }) => {
  return (
    <EllipsisTooltip maxTextContainerWidth="500px" title={children}>
      {children}
    </EllipsisTooltip>
  );
});

const Recipient = memo(({ transaction, journalEntryId, onUpload }: IProps) => {
  const { t } = useTranslation('accounts');

  const descriptionData = useMemo<DescriptionItem[]>(() => {
    let result: DescriptionItem[] = [];
    const {
      type,
      orderingCustomer,
      beneficiary,
      amount,
      postingDate,
      valueDate,
      relatedDocuments,
      transactionNumber,
    } = transaction;

    result = [
      {
        label: t(
          'transaction_details_modal.transaction_details.internal.transaction_number',
        ),
        description: transactionNumber,
      },

      {
        label: t('transaction_details_modal.transaction_details.internal.type'),
        description: t(`types.${TransactionTypes[type]}`),
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.ordering_customer',
        ),
        description: (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_name',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountName}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_number',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountNumber}</ValueWrapper>
                ),
              },
            ]}
          />
        ),
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.beneficiary',
        ),
        description: (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_name',
                ),
                description: (
                  <ValueWrapper>{beneficiary.accountName}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.account_number',
                ),
                description: (
                  <ValueWrapper>{beneficiary.accountNumber}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transaction_details_modal.transaction_details.internal.to_account_reference',
                ),
                description: (
                  <ValueWrapper>{beneficiary.reference}</ValueWrapper>
                ),
              },
            ]}
          />
        ),
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.amount',
        ),
        description: amount,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.posting_date',
        ),
        description: postingDate,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.value_date',
        ),
        description: valueDate,
      },

      {
        label: t(
          'transaction_details_modal.transaction_details.internal.documents',
        ),
        description: (
          <DocumentsValue
            transactionDocuments={relatedDocuments}
            journalEntryId={journalEntryId}
            onUpload={onUpload}
          />
        ),
      },
    ];

    return result;
  }, [t, transaction, journalEntryId, onUpload]);

  return <DescriptionSection data={descriptionData} />;
});

const StyledInnerDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 250px !important;
    max-width: 250px !important;
  }
`;

export default Recipient;
