import React, { ReactNode } from 'react';

// helpers
import { ExistingOwnershipModel } from '../../../../Forms/TemplateForms/ClientProfile/OwnershipRelationshipForm';
import { IRelationshipTemplateModel } from '../../../../../typings/application/relationship-template';
import { ownershipStructureValidationSchema } from '../../../../../validations/accountManagement/clientProfile';
import {
  AdditionalFieldTypes,
  OnboardingEntryTypes,
} from '../../../../../enums/onboarding/crm';
import { FormValuesModel as ContactShortInfoFormValues } from 'components/Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as OrganizationShortInfoFormValues } from 'components/Forms/TemplateForms/CRM/OrganizationShortInfoForm';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface FormValuesModel {
  ownershipFieldKey: string;
  ownershipTemplate: IRelationshipTemplateModel;
  ownerships: OwnershipFormItem[];
  allowedEntriesForOwnerships: ExistingOwnershipModel[];
  clientGroupEntryType: OnboardingEntryTypes;
  relationshipScopeId: string;
  clientGroupId: string;
  relationshipTo: {
    id: string;
    type: OnboardingEntryTypes;
  };
}

export type OwnershipFormItem = {
  id?: string;
  relationshipId?: string;
  type: OnboardingEntryTypes;
  isFromExistingEntry?: boolean;

  keyRelationshipTemplate: {
    id: string;
    name: string;
  };

  additionalFields: Record<
    string,
    { value: string; isOwnershipField: boolean; type: AdditionalFieldTypes }
  >;

  contact: ContactShortInfoFormValues | null;
  organization: OrganizationShortInfoFormValues | null;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: ReactNode;
  isViewOnly?: boolean;
}

const OwnershipStructureModalDialog = ({
  title,
  isVisible,
  initialValues,
  isViewOnly,
  disabled,
  onSubmit,
  closeCallback,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      enableReinitialize
      disabled={disabled || isViewOnly}
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={ownershipStructureValidationSchema}
      renderForm={
        <ModalDialog
          title={title}
          width={1080}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons={isViewOnly}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default OwnershipStructureModalDialog;
