import React, {
  ReactNode,
  useState,
  isValidElement,
  cloneElement,
} from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { colorsTheme } from '../../../resources/theme/styled/colors';

// components
import Link from '@common_components/Texts/Link';
import IconSVG from '../../DesignSystem/Core/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { ReactComponent as ArrowDownIcon } from '../../../resources/icons/remix-icons/arrow-down-s-line.svg';

interface IProps {
  children: ReactNode;
  trigger?: ReactNode;
  showArrow?: boolean;
  showTriggerAfter?: boolean;
}

// Custom expandable container
const ExpandableWrapper = ({ trigger, children, showTriggerAfter }: IProps) => {
  const { t } = useTranslation('common');

  const [isExpanded, setExpanded] = useState(false);

  const renderTrigger = () => {
    const props = { onClick: () => setExpanded((isExpanded) => !isExpanded) };

    if (trigger && isValidElement(trigger)) {
      return cloneElement(trigger, props);
    }

    return (
      <CenterAlignWrapper>
        <Link {...props}>
          <DivAlignCenter>
            {t(isExpanded ? 'show_less' : 'show_more')}
            <StyledIconSVG
              component={ArrowDownIcon}
              color={colorsTheme.colorWhite}
              expanded={isExpanded}
            />
          </DivAlignCenter>
        </Link>
      </CenterAlignWrapper>
    );
  };

  return (
    <>
      {!showTriggerAfter && renderTrigger()}
      <ExpandContainer expanded={isExpanded}>{children}</ExpandContainer>
      {showTriggerAfter && renderTrigger()}
    </>
  );
};

const ExpandContainer = styled.div<{ expanded: boolean }>`
  width: 100%;
  overflow: hidden;
  height: ${({ expanded }) => (expanded ? '100%' : 0)};
`;

const CenterAlignWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconSVG = styled(IconSVG)<{ expanded: boolean }>`
  margin-left: ${({ theme }) => theme.marginXXs};
  transform: ${({ expanded }) =>
    expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export default ExpandableWrapper;
