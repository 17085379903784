import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import Button from '../../../../../components/DesignSystem/Core/Button';
import GetVerificationCodeModal from '../../../../../components/ModalDialogs/TemplateModalDialogs/UserProfile/GetVerificationCodeModal';

const GetVerificationCode = () => {
  const { t } = useTranslation('profile');

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <StyledButton
        size="large"
        type="bordered"
        onClick={() => setIsModalVisible(true)}
      >
        {t('security.identity_verification.get_code_button')}
      </StyledButton>

      <GetVerificationCodeModal
        isVisible={isModalVisible}
        closeCallback={() => setIsModalVisible(false)}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default GetVerificationCode;
