export enum RelationshipComparationTypes {
  Added = 'added',
  Removed = 'removed',
}

export interface IRelationshipScopes {
  active: string;
  approved: string;
}

export interface IndustryCodeModel {
  _id: string;
  code: string;
  _audit: {
    createdAt: string;
    createdBy: string;
    deleteDetails: any;
    isDeleted: boolean;
    updatedAt: string;
  };
}
