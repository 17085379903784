import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { getBadges } from 'redux/actions/app';
import { StateModel } from '../../../../redux/reducers';
import { onboardingAPI } from '../../../../api/onboarding/onboardingAPI';
import { updateActiveApplication } from '../../../../redux/actions/applications';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as ApplicationsStateModel } from '../../../../redux/reducers/applications';

// constants
import { ApplicationStatuses } from '../../../../enums/onboarding/crm';

// components
import InnerGraph from './InnerGraph';
import LoadingWrapper from '../../../../components/WrapperComponents/LoadingWrapper';
import ClientGroupActions from '../../ClientGroupActions';

interface IProps {
  updateTrigger: any;
}

const StructureChart = ({ updateTrigger }: IProps) => {
  const dispatch = useDispatch();
  const [updateTreeTrigger, updateTree] = useState({});
  const [relationshipScope, setRelationshipScope] = useState<{
    crmItemId: string;
    applicantContactId: string;
    active: string;
    approved?: string;
  } | null>(null);
  const { activeApplication, activeApplicationStatus } = useSelector<
    StateModel,
    ApplicationsStateModel
  >((state) => state.applications);

  useEffect(() => {
    // Set initial data
    if (!relationshipScope && activeApplication) {
      setRelationshipScope({
        applicantContactId: activeApplication.onboardingProcess.contactId,
        crmItemId: activeApplication.crmItemId,
        active: activeApplication.model.relationshipScopes?.active as string,
        approved: activeApplication.model.relationshipScopes?.approved,
      });
    }
  }, [activeApplication]);

  const isViewOnly = useMemo(
    () =>
      activeApplicationStatus ===
        ApplicationStatuses.InAccountManagementReview ||
      activeApplicationStatus === ApplicationStatuses.InReview,
    [activeApplicationStatus],
  );

  const { response, loading } = useFetch(
    () =>
      relationshipScope
        ? onboardingAPI.fetchStructureStepRelationships(
            relationshipScope.crmItemId,
            relationshipScope.active,
            relationshipScope.crmItemId,
            relationshipScope.applicantContactId,
            relationshipScope.approved,
            isViewOnly,
          )
        : null,
    [relationshipScope, updateTreeTrigger, updateTrigger, isViewOnly],
  );

  const onTreeModify = () => {
    updateTree({});
  };

  const handleChangesSubmit = () => {
    updateTree({});
    dispatch(updateActiveApplication(false, false));
    dispatch(getBadges());
  };

  return (
    <LoadingWrapper loading={loading}>
      {response && activeApplication && relationshipScope && (
        <>
          <InnerGraph
            graph={response}
            onModify={onTreeModify}
            isViewOnly={isViewOnly}
            activeScopeId={relationshipScope.active}
            activeApplication={activeApplication}
          />

          <ClientGroupActions
            onSubmit={handleChangesSubmit}
            updateTrigger={updateTreeTrigger}
            onDiscardChanges={(newScopeId, approvedScopeId) =>
              setRelationshipScope({
                crmItemId: relationshipScope?.crmItemId as string,
                applicantContactId:
                  relationshipScope?.applicantContactId as string,
                active: newScopeId,
                approved: approvedScopeId,
              })
            }
          />
        </>
      )}
    </LoadingWrapper>
  );
};

export default StructureChart;
