import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { AdditionalDocumentFormItemModel, FormValuesModel } from '../..';

// constants
import { colorsTheme } from 'resources/theme/styled/colors';
import { MAX_ADDITIONAL_DOCUMENTS_COUNT } from '../../../../../../../constants/onboarding';

// components
import Text from '@core_components/Text';
import AddButton from '../../../../../../DesignSystem/Common/Buttons/AddButton';
import SectionIntro from '../../../../../../DesignSystem/Common/Texts/SectionIntro';
import HideIfDisabledForm from '../../../../../HideIfDisabledForm';
import AdditionalDocumentFormItem from './AdditionalDocumentFormItem';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

const AdditionalDocumentsSection = () => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();

  const renderAdditionalDocuments = (arrayHelper: FieldArrayRenderProps) => {
    let content = null;
    const canRenderAddButton =
      values.additionalDocuments.length < MAX_ADDITIONAL_DOCUMENTS_COUNT;
    const initialDocument: AdditionalDocumentFormItemModel = {
      name: '',
      description: '',
      documents: [],
    };

    if (!values.additionalDocuments.length) {
      content = (
        <StyledNoDataMessage variant="body1" color={colorsTheme.colorLightD1}>
          {t(
            'application_documentation.additional_documents.no_additional_documents',
          )}
        </StyledNoDataMessage>
      );
    } else {
      content = values.additionalDocuments.map((e, i) => (
        <AdditionalDocumentFormItem
          key={i}
          fieldIndex={i}
          removeCallback={() => arrayHelper.remove(i)}
        />
      ));
    }

    return (
      <>
        {content}
        {canRenderAddButton && (
          <HideIfDisabledForm hideIfSubmitting>
            <AddButton onClick={() => arrayHelper.push(initialDocument)}>
              {t(
                'application_documentation.additional_documents.add_document_button',
              )}
            </AddButton>
          </HideIfDisabledForm>
        )}
      </>
    );
  };

  return (
    <>
      <SectionIntro
        title={t('application_documentation.additional_documents.title')}
        description={t(
          'application_documentation.additional_documents.description',
        )}
      />
      <FieldArray
        name="additionalDocuments"
        render={renderAdditionalDocuments}
      />
    </>
  );
};

const StyledNoDataMessage = styled(Text)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default AdditionalDocumentsSection;
