// ---------- Entities -------------
export enum OnboardingStatusStatuses {
  NotStarted = 'onboarding_not_started',
  PreApproved = 'onboarding_pre_approved',
  Approved = 'onboarding_approved',
  Rejected = 'onboarding_rejected',
  Pending = 'onboarding_pending',
  None = 'none',
}

export enum OnboardingStatuses {
  Submitted = 'submitted',
  NotSubmitted = 'not_submitted',
  InAccountManagementReview = 'in_account_management_review',
  InReview = 'in_review',
  Rejected = 'rejected',
  AdditionalInfoRequired = 'additional_info_required',
  Approved = 'approved',
}

export enum OnboardingEntryTypes {
  Contact = 'contact',
  Organization = 'organization',
}

// TODO: change name to a more detailed one
export enum AccountTypes {
  current = 'current',
  term = 'term',
}

export enum ApplicationStatuses {
  InReview = 'in_review',
  InAccountManagementReview = 'in_account_management_review',
  Reviewed = 'reviewed',
}

export enum ClientGroupStatuses {
  Onboarding = 'onboarding',
  InReview = 'in_review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum ClientGroupUserStatuses {
  Onboarding = 'onboarding',
  ReadyForReview = 'ready_for_review',
  AccountManagementReview = 'in_account_management_review',
  InReview = 'in_review',
  Approved = 'approved',
  Rejected = 'rejected',
  Invited = 'invited',
  Enabled = 'enabled',
  Deactivated = 'deactivated',
  InvitationExpired = 'invitation_expired',
}

export enum ClientGroupReviewStatuses {
  NotReviewed = 'not_reviewed',
  ManagerReview = 'manager_review',
  BusinessReview = 'business_review',
  ComplianceReview = 'compliance_review',
  Reviewed = 'reviewed',
}

export enum OnboardingReviewStatuses {
  ManagerReview = 'manager_review',
  BusinessReview = 'business_review',
  ComplianceReview = 'compliance_review',
}

export enum EntityTypes {
  Legal = 'legal',
  Trader = 'trade',
}

export enum OrganizationAddressTypes {
  RegisteredOffice = 'registered_office',
  BusinessAddress = 'business_address',
  MailingAddress = 'mailing_address',
}

export enum ContactAddressTypes {
  PermanentAddress = 'permanent_address',
  MailingAddress = 'mailing_address',
  ResidentalAddress = 'residential_address',
}

export enum EmailTypes {
  Work = 'work',
  Other = 'other',
  Personal = 'personal',
}

export enum PhoneNumberTypes {
  Home = 'home',
  Work = 'work',
  Mobile = 'mobile',
  Business = 'business',
  Fax = 'fax',
  Other = 'other',
}

export enum SourceTypes {
  Document = 'document',
  Link = 'link',
  String = 'string',
}

export enum AdditionalFieldTypes {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Select = 'select',
  Document = 'document',
  Percentage = 'percentage',
  NotProvided = 'not_provided',
  Form = 'form',
  Boolean = 'boolean',
}

export enum AssociationEntries {
  All = 'all',
  Organization = 'organization',
  Contact = 'contact',
  ClientGroup = 'clientGroup',
  JournalEntry = 'journalEntry',
}

export enum GenderTypes {
  Male = 'male',
  Female = 'female',
}

export enum SourceOfWealthCategories {
  IncomeFromProfession = 'income_from_profession',
  InvestmentIncome = 'investment_income',
  CompanyOwnership = 'company_ownership',
  CompanySale = 'company_sale',
  Inheritance = 'inheritance',
  PropertyRentalIncome = 'property_rental_income',
  PropertySale = 'property_sale',
}

export enum SocialMediaTypes {
  Twitter = 'Twitter',
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  WebSite = 'website',
}

export enum ComplianceRiskLevelTypes {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum AccountUserPermissionTypes {
  ViewOnly = 'view_only',
  InstructTransaction = 'instruct_transaction',
  AuthorizedSignatory = 'authorized_signatory',
}

export enum AdministrationPermissionTypes {
  Admin = 'admin',
  LimitedAdmin = 'limited_admin',
  None = 'none',
}

export enum RelationshipEntryTypes {
  Any = 'any',
  Contact = 'contact',
  Organization = 'organization',
}

export enum RelationshipTrackTypes {
  Parent = 'parent',
  Child = 'child',
}
