import { NewAccountModel } from '../../api/accounting/accountsAPI';
import { FormValuesModel } from '../../components/ModalDialogs/TemplateModalDialogs/Accounts/AddEditAccountModalDialog';
import {
  WireRangeKeys,
  WireRangeOptions,
} from '../../components/Forms/FormComponents/SelectInputs/Accounting/WireRangesSelect';
import {
  EstimatedAmountFundsRangeKeys,
  EstimatedAmountFundsRangeOptions,
} from '../../components/Forms/FormComponents/SelectInputs/Accounting/EstimatedAmountFundsRangeSelect';
import {
  InitialDepositRangeKeys,
  InitialDepositRangeOptions,
} from '../../components/Forms/FormComponents/SelectInputs/Accounting/InitialDepositRangeSelect';

export const accountsAdapter = {
  generateBodyForCreateFiatBankAccount: (
    values: FormValuesModel,
  ): NewAccountModel => {
    const { fiatAccount } = values;
    return {
      accountName: fiatAccount.accountName,
      currencyId: fiatAccount.currencyId as number,
      accountSupplementary: {
        type: fiatAccount.type,
        purpose: fiatAccount.purpose.trim(),
        initialDepositOrigin: fiatAccount.initialDepositOrigin || '',
        initialDepositFrom: InitialDepositRangeOptions[
          fiatAccount.initialDeposit as InitialDepositRangeKeys
        ].min as number,
        initialDepositTo: InitialDepositRangeOptions[
          fiatAccount.initialDeposit as InitialDepositRangeKeys
        ].max as number,
        incomingWiresNumberFrom: WireRangeOptions[
          fiatAccount.incomingWiresNumber as WireRangeKeys
        ].min as number,
        incomingWiresNumberTo: WireRangeOptions[
          fiatAccount.incomingWiresNumber as WireRangeKeys
        ].max as number,
        outgoingWiresNumberFrom: WireRangeOptions[
          fiatAccount.outgoingWiresNumber as WireRangeKeys
        ].min as number,
        outgoingWiresNumberTo: WireRangeOptions[
          fiatAccount.outgoingWiresNumber as WireRangeKeys
        ].max as number,
        estimatedIncomingFundsFrom: EstimatedAmountFundsRangeOptions[
          fiatAccount.estimatedIncomingFunds as EstimatedAmountFundsRangeKeys
        ].min as number,
        estimatedIncomingFundsTo: EstimatedAmountFundsRangeOptions[
          fiatAccount.estimatedIncomingFunds as EstimatedAmountFundsRangeKeys
        ].max as number,
        estimatedOutgoingFundsFrom: EstimatedAmountFundsRangeOptions[
          fiatAccount.estimatedOutgoingFunds as EstimatedAmountFundsRangeKeys
        ].min as number,
        estimatedOutgoingFundsTo: EstimatedAmountFundsRangeOptions[
          fiatAccount.estimatedOutgoingFunds as EstimatedAmountFundsRangeKeys
        ].max as number,
        jurisdictions: fiatAccount.jurisdictions,
      },
    };
  },
};
