import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { NotificationPreferenceModel } from '../../typings/profile/accountNotifications';

interface FetchNotificationsResponseModel {
  data: NotificationPreferenceModel[];
  id: string;
}
const accountNotificationsAPI = {
  fetchNotificationPreferences: () => {
    return APIService.get<FetchNotificationsResponseModel>(
      `${APIConfig.notificationsApi}/account/settings`,
    ).then((response) => response.data);
  },

  changeNotificationStatus: (updatedModel: NotificationPreferenceModel) => {
    return APIService.patch<NotificationPreferenceModel>(
      `${APIConfig.notificationsApi}/account/settings`,
      updatedModel,
    ).then((response) => response.data);
  },
};

export { accountNotificationsAPI };
