import React from 'react';

// helpers
import { RoutePaths, routes, Route as RouteType } from 'routes/routes';

// components
import { Navigate, Route, Routes } from 'react-router-dom';

const RouteRenderer = () => {
  const generateRoute = (
    route: RouteType,
    renderInChildren?: boolean,
  ): React.ReactElement => {
    if (!route.children) {
      return (
        <Route
          key={route.path}
          path={route.path}
          index={route.index}
          element={route.component}
        />
      );
    } else {
      return renderInChildren ? (
        <Route key={route.path} path={route.path} element={route.component}>
          {route.children.map((e) => generateRoute(e, false))}
        </Route>
      ) : (
        <>
          <Route key={route.path} path={route.path} element={route.component} />
          {route.children.map((e) => generateRoute(e, false))}
        </>
      );
    }
  };

  const renderRoutes = (routes: RouteType[]) => {
    return routes.map((e) => generateRoute(e, true));
  };

  return (
    <Routes>
      {renderRoutes(routes)}
      <Route
        key="not-found-route"
        path="*"
        element={<Navigate to={RoutePaths.Root} />}
      />
    </Routes>
  );
};

export default RouteRenderer;
