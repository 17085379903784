import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { colorsTheme } from '../../../../resources/theme/styled/colors';

// components
import IconSVG from '../../../DesignSystem/Core/IconSVG';
import { Popover } from 'antd';
import { ReactComponent as InfoIcon } from '../../../../resources/icons/remix-icons/information-line.svg';

const AreYouPepTooltip = () => {
  const { t } = useTranslation('onboarding');

  return (
    <Popover
      content={t('identification.is_pep_tooltip')}
      placement="rightTop"
      overlayStyle={{ maxWidth: '600px' }}
    >
      <StyledIconSVG component={InfoIcon} color={colorsTheme.colorWarning} />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.paddingXs};
`;

export default AreYouPepTooltip;
