import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { colorsTheme } from 'resources/theme/styled/colors';

// components
import Text from '@core_components/Text';
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { Popover } from 'antd';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';
import { ReactComponent as CloseIcon } from 'resources/icons/remix-icons/close-line.svg';

interface IProps {
  reviewResult: {
    isValid: boolean;
    comment: string;
  };
}

const DocumentReviewStatus = memo(({ reviewResult }: IProps) => {
  const { t } = useTranslation('onboarding');

  return reviewResult.isValid ? (
    <ApprovalStatus status="approved">
      <Text variant="body1" color={colorsTheme.colorPrimary} gutterBottom>
        <DivAlignCenter>
          <IconSVG component={CheckIcon} color={colorsTheme.colorPrimary} />{' '}
          {t('application_documentation.accepted_document')}
        </DivAlignCenter>
      </Text>
    </ApprovalStatus>
  ) : (
    <ApprovalStatus status="rejected">
      <Popover
        content={t('application_documentation.bank_comments', {
          comments: reviewResult.comment,
        })}
        placement="bottom"
      >
        <StyledText
          variant="body1"
          color={colorsTheme.colorWarning}
          gutterBottom
        >
          <DivAlignCenter>
            <IconSVG component={CloseIcon} color={colorsTheme.colorWarning} />{' '}
            {t('application_documentation.not_accepted_document')}
          </DivAlignCenter>
        </StyledText>
      </Popover>
    </ApprovalStatus>
  );
});

const ApprovalStatus = styled.span<{
  status: 'approved' | 'rejected' | 'new_field';
}>`
  cursor: ${({ status }) => (status === 'rejected' ? 'pointer' : 'default')};
  line-height: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme, status }) =>
    status === 'approved' ? theme.colorPrimaryD1 : theme.colorWarning};
  ${({ theme, status }) =>
    status === 'rejected' &&
    `border-bottom: 1px dashed ${theme.colorWarning};`};
`;

const StyledText = styled(Text)`
  width: fit-content;
`;

export default DocumentReviewStatus;
