import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { Moment } from 'moment';
import { FetchParamsModel } from '../../typings/common';
import { travelNotificationsAdapter } from '../../apiAdapters/profile/travelNotificationsAdapter';

interface NewTripBodyModel {
  countries: {
    country: string | null;
    departureDate: Moment | string | null;
    returnDate: Moment | string | null;
  }[];
}

const travelNotificationsAPI = {
  addTrip: (newTrip: NewTripBodyModel) => {
    const requestBody = travelNotificationsAdapter.generateNewTripBody(newTrip);
    return APIService.post(
      `${APIConfig.notificationsApi}/travel/settings`,
      requestBody,
    ).then((response) => response.data);
  },

  editTrip: (updatedTrip: NewTripBodyModel, tripId: string) => {
    const generatedCountries =
      travelNotificationsAdapter.generateNewTripBody(updatedTrip);
    const requestBody = { ...generatedCountries, id: tripId };
    return APIService.patch(
      `${APIConfig.notificationsApi}/travel/${tripId}/settings`,
      requestBody,
    ).then((response) => response.data);
  },

  fetchTravelNotifications: (params: FetchParamsModel) => {
    return APIService.get(`${APIConfig.notificationsApi}/travel/settings`, {
      params,
    }).then((response) => {
      return travelNotificationsAdapter.fetchTravelNotificationsAdapter(
        response.data,
      );
    });
  },
};

export { travelNotificationsAPI };
