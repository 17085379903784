import React from 'react';

// helpers
import { accountsAPI } from 'api/accounting/accountsAPI';
import { IAccountModel } from 'typings/onboarding/account';
import { AccountsHelpers } from 'helpers/accounts';
import { BankAccountStatuses } from 'enums/accounts/accounts';

// components
import Text from '@core_components/Text';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Autocomplete, { AutocompleteProps } from '@core_components/Autocomplete';

type BankAccountAutocompleteProps = AutocompleteProps<IAccountModel>;

const TransactionBankAccountAutocomplete = (
  props: BankAccountAutocompleteProps,
) => {
  const fetchOptionsCallback = async () => {
    const { data } = await accountsAPI.fetchAccounts({
      limit: 10000,
      page: 1,
      status: BankAccountStatuses.Active,
    });

    return data.map((e) => {
      const { accountNumber, accountName, currencyCode, balance } = e;
      const accountBalance = `${currencyCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(balance)}`;
      const name = AccountsHelpers.generateAccountFullName(
        accountName,
        accountNumber,
        currencyCode,
        balance,
      );

      return {
        id: e.accountNumber,
        model: e,
        label: (
          <DivAlignCenter>
            <EllipsisTooltip
              placement="right"
              overlayStyle={{ maxWidth: '300px' }}
              title={
                <>
                  <Text variant="body1">{accountNumber}</Text>
                  <Text variant="body1">{accountName}</Text>
                  <Text variant="body1">{accountBalance}</Text>
                </>
              }
            >
              {name}
            </EllipsisTooltip>
          </DivAlignCenter>
        ),
      };
    });
  };

  return <Autocomplete {...props} fetchData={fetchOptionsCallback} />;
};

export default TransactionBankAccountAutocomplete;
