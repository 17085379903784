import React, { useMemo } from 'react';

// helpers
import { IEntity } from '../../../../../../../../../typings/application/entity';
import { EntityTypes } from '../../../../../../../../../enums/onboarding/crm';
import { EntityHelpers } from '../../../../../../../../../helpers/crm/entity';

// components
import GeneralInformationShortForm, {
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/GeneralInformationShortForm';

interface IProps {
  organization: IEntity;
}

const GeneralInformation = ({ organization }: IProps) => {
  const initialFormValues = useMemo<FormValuesModel>(() => {
    const legalName = EntityHelpers.getEntityNameByNameType(
      organization.names,
      EntityTypes.Legal,
    );
    return {
      submitActionType: null,
      isSave: false,
      legalName,
    };
  }, [organization]);

  const handleOnSubmit = async () => {
    // TODO: discuss if submit is needed here
  };

  return (
    <GeneralInformationShortForm
      disabled
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
      showOnlySaveButton
    />
  );
};

export default GeneralInformation;
