import React from 'react';

// constants
import { ApprovalWorkflowChangeTypes } from '../../../../../../../../../enums/approvalManagement/approvalManagement';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import {
  ApprovalEntryNewModel,
  ApprovalRuleSetFullModel,
  IApprovalRuleAccount,
} from '../../../../../../../../../typings/approvalManagement/accountManagement';

// components
import SectionIntro from '../../../../../../../../../components/DesignSystem/Common/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export interface TransactionRuleTemplateData {
  id: string;
  accounts: IApprovalRuleAccount[];
  approvalRuleSets: ApprovalRuleSetFullModel[];
}

interface IProps {
  approvalEntry: ApprovalEntryNewModel<TransactionRuleTemplateData>;
}

const TransactionRule = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderContent = () => {
    switch (approvalEntry.entry.changeType) {
      case ApprovalWorkflowChangeTypes.Create:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as TransactionRuleTemplateData}
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Delete:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.removed')}
            />
            <InnerTemplate
              data={approvalEntry.changes.old as TransactionRuleTemplateData}
            />
          </>
        );

      case ApprovalWorkflowChangeTypes.Edit:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.old')}
            />
            <InnerTemplate
              data={approvalEntry.changes.old as TransactionRuleTemplateData}
            />

            <SectionIntro
              titleVariant="h5"
              title={t('pending_approvals.template_change_versions.new')}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as TransactionRuleTemplateData}
            />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default TransactionRule;
