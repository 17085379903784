import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { TWO_COL_GRID_SIZES } from '../../../../../constants/grids';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
} from '../../../../../enums/onboarding/crm';

// components
import FormField from '@core_components/FormField';
import TrueFalseSelect from '../../../FormComponents/SelectInputs/TrueFalseSelect';
import AccountPermissionSelect from '../../../FormComponents/SelectInputs/CRM/AccountPermissionSelect';
import AdministrationPermissionsSelect from 'components/Forms/FormComponents/SelectInputs/CRM/AdministrationPermissionsSelect';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  isOnlineUser: boolean | null;
  accountPermissions: AccountUserPermissionTypes | null;
  adminPermissionType: AdministrationPermissionTypes | null;
  disabledIsOnlineUser?: boolean;
};

interface IProps {
  namePrefix: string;
  disabled?: boolean;
  hideAccessFieldsWhenNotOnline?: boolean;
}

const OnlineAccessForm = ({
  namePrefix,
  disabled,
  hideAccessFieldsWhenNotOnline,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const [field] = useField<FormValuesModel>(namePrefix);

  const canShowAccessFields = hideAccessFieldsWhenNotOnline
    ? field.value.isOnlineUser
    : true;

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            disabled={field.value.disabledIsOnlineUser || disabled}
            name={`${namePrefix}.isOnlineUser`}
            label={t(
              'structure.relationships.form_fields.individual.is_online_user',
            )}
            component={TrueFalseSelect}
          />
        </Col>
      </Row>

      {canShowAccessFields && (
        <Row gutter={[16, 0]}>
          <Col {...TWO_COL_GRID_SIZES}>
            <FormField
              name={`${namePrefix}.accountPermissions`}
              label={t(
                'structure.relationships.form_fields.individual.permissions',
              )}
              component={AccountPermissionSelect}
              disabled={!field.value?.isOnlineUser || disabled}
            />
          </Col>
          <Col {...TWO_COL_GRID_SIZES}>
            <FormField
              name={`${namePrefix}.adminPermissionType`}
              label={t(
                'structure.relationships.form_fields.individual.administrator',
              )}
              component={AdministrationPermissionsSelect}
              additionalProps={{ hideLimitedPermission: true }}
              disabled={!field.value?.isOnlineUser || disabled}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default OnlineAccessForm;
