import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { AccountsHelpers } from '../../../../../../../../../../helpers/accounts';
import { IApprovalRuleAccount } from '../../../../../../../../../../typings/approvalManagement/accountManagement';
import { TransactionRuleTemplateData } from '..';

// components
import Text from '@core_components/Text';
import TransactionApprovalRulesMatrix from '../../../../../../../../../../components/Tables/TransactionApprovalRulesMatrix';
import { Tag } from 'antd';

interface IProps {
  data: TransactionRuleTemplateData;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('account_management');

  const renderAccounts = (accounts: IApprovalRuleAccount[]) => {
    return accounts.map((e, i) => (
      <Tag key={i}>
        {AccountsHelpers.generateAccountFullName(
          e.bankAccountName,
          e.bankAccountNumber,
          e.bankAccountCurrency,
        )}
      </Tag>
    ));
  };

  return (
    <>
      <SectionWrapper>
        <Text variant="h5">
          {`${t('pending_approvals.approval_details_modal.changes_section.transaction_rule_changes_template.bank_accounts')}:`}
        </Text>
        {renderAccounts(data.accounts)}
      </SectionWrapper>

      <SectionWrapper>
        <Text variant="h5">
          {`${t('pending_approvals.approval_details_modal.changes_section.transaction_rule_changes_template.approval_rule')}:`}
        </Text>
        <TransactionApprovalRulesMatrix transactionRule={data} />
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.section`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default InnerTemplate;
