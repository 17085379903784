import { ContactModel } from 'typings/application/contact';
import { OnboardingEntryTypes } from '../enums/onboarding/crm';
import {
  EdgeStatuses,
  GraphEdge,
  GraphNode,
} from '../components/Charts/GraphChart';

const GraphHelpers = {
  getCountOfAvailableEdges: function (edges: GraphEdge[], edgeId: string) {
    return edges.reduce((acc: number, edge: GraphEdge) => {
      if (edge.source === edgeId && edge.status !== EdgeStatuses.Deleted) {
        acc += 1;
      }

      return acc;
    }, 0);
  },

  isNodeItemFilled: function (node: GraphNode) {
    let isFilled = false;
    const { entryType, onboardingProcess, model, hasOwnershipRelationships } =
      node;

    if (!onboardingProcess) {
      return true;
    }

    switch (entryType) {
      case OnboardingEntryTypes.Contact:
        {
          const contact = model as ContactModel;
          const { informationFilled } = onboardingProcess;
          isFilled =
            hasOwnershipRelationships || contact.isPEP
              ? informationFilled.generalInformation &&
                informationFilled.identification &&
                informationFilled.employmentHistory &&
                informationFilled.applicationDocumentation
              : informationFilled.generalInformation &&
                informationFilled.identification &&
                informationFilled.applicationDocumentation;
        }
        break;

      case OnboardingEntryTypes.Organization:
        {
          const { informationFilled } = onboardingProcess;
          isFilled =
            informationFilled.generalInformation &&
            informationFilled.identification &&
            informationFilled.applicationDocumentation;
        }
        break;
    }

    return isFilled;
  },
};

export { GraphHelpers };
