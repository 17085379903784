import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from 'helpers/utils';
import { DateHelpers } from 'helpers/date';
import { AccountsHelpers } from '../../../../../helpers/accounts';
import { TransactionStatuses } from 'enums/accounts/accounts';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { PendingTransaction } from '../../../../../typings/accounting/transaction';
import { TransferApprovalStatuses } from 'enums/accounting/transfers';

// components
import Link from '@common_components/Texts/Link';
import Checkbox from '@core_components/Checkbox';
import DocumentLink from '../../../../Typography/DocumentLink';
import TransactionStatus from 'components/Additional/Statuses/TransactionStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import TransactionBankAccountAutocomplete from 'components/Forms/FormComponents/Autocompletes/Accounting/TransactionBankAccountAutocomplete';

interface IProps extends RequiredPropsForTableModel<PendingTransaction> {
  onActionsClick: (key: string, id: number) => void;
  onSelect?: (
    records: { record: PendingTransaction; checked: boolean }[],
  ) => void;
  isSelectAllChecked?: boolean;
  selectedTransactions?: Record<string, PendingTransaction>;
  filters: Record<string, any>;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

const PendingTransactionsTable = ({
  onActionsClick,
  onSelect,
  filters,
  selectedTransactions,
  isSelectAllChecked,
  ...rest
}: IProps) => {
  const { t } = useTranslation('accounts');

  const renderDescription = ({ id, description }: PendingTransaction) => {
    if (!description) return;

    return (
      <Link onClick={() => onActionsClick('view', id)}>{description}</Link>
    );
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    const result: TableColumnModel[] = [
      {
        title: t('pending_transactions.table.requested_date'),
        key: 'requested_date',
        render: (record: PendingTransaction) =>
          DateHelpers.formatTimestampDateToString(record.creationDate),
      },
      {
        title: t('pending_transactions.table.value_date'),
        key: 'value_date',
        render: (record: PendingTransaction) =>
          DateHelpers.formatTimestampDateToString(record.valueDate),
      },
      {
        title: t('pending_transactions.table.transaction_status'),
        key: 'status',
        render: (record: PendingTransaction) => (
          <TransactionStatus
            transaction={{
              status: record.statusId,
              expiresAt: record.workflowExpirationDate || undefined,
            }}
          />
        ),
        filteredValue: filters.status,
        filterSearch: true,
        filters: Array.from(enumToMap(TransactionStatuses)).map((e) => ({
          text: t(`pending_transactions.table.filter_bar.statuses.${e[1]}`),
          value: e[1],
        })),
      },
      {
        title: t('pending_transactions.table.from_account'),
        key: 'from_account',
        width: 400,
        render: (record: PendingTransaction) =>
          AccountsHelpers.generateAccountFullName(
            record.fromAccountName,
            record.fromAccountNumber,
            record.fromCurrencyISOCode,
          ),
        mobileCardProps: { hideFromMainSection: true },
        filteredValue: filters.fromAccount,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledTransactionBankAccountAutocomplete
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        title: t('pending_transactions.table.description'),
        key: 'description',
        width: 350,
        render: renderDescription,
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('pending_transactions.table.documents'),
        key: 'documents',
        render: (record: PendingTransaction) =>
          record.relatedDocuments.map(({ dmsId, linkToDownload, name }) => (
            <div key={dmsId}>
              <DocumentLink
                text={name}
                fileId={linkToDownload}
                documentId={dmsId}
              />
            </div>
          )),
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('pending_transactions.table.transaction_type'),
        key: 'type',
        mobileCardProps: { isCardTitle: true, hide: true },
        render: (record: PendingTransaction) => record.sourceTypeName,
      },
      {
        title: t('pending_transactions.table.currency'),
        key: 'currency',
        render: (record: PendingTransaction) => record.currencyIsoCode,
      },
      {
        title: t('pending_transactions.table.amount'),
        key: 'amount',
        render: (record: PendingTransaction) =>
          record.amount
            ? AccountsHelpers.convertAmountBigIntToLocaleString(record.amount)
            : '',
      },
    ];

    if (onSelect) {
      result.unshift({
        title: (
          <Checkbox
            value={isSelectAllChecked}
            onChange={() => {
              const transactionsWithPendingReviewStatus = rest.data.filter(
                (e) =>
                  e.statusId === TransferApprovalStatuses.PendingApprovals &&
                  !e.isAlreadyApproved,
              );

              if (transactionsWithPendingReviewStatus.length) {
                const formattedTransactions =
                  transactionsWithPendingReviewStatus.map((transaction) => ({
                    record: transaction,
                    checked: !isSelectAllChecked,
                  }));

                onSelect(formattedTransactions);
              }
            }}
          />
        ),
        key: 'select_checkbox',
        render: (record: PendingTransaction) => (
          <Checkbox
            disabled={
              record.statusId !== TransferApprovalStatuses.PendingApprovals ||
              (record.statusId == TransferApprovalStatuses.PendingApprovals &&
                record.isAlreadyApproved)
            }
            value={
              selectedTransactions ? !!selectedTransactions[record.id] : false
            }
            onChange={(value) =>
              onSelect([{ record, checked: !!value.target.checked }])
            }
          />
        ),
      });
    }

    return result;
  }, [
    onSelect,
    selectedTransactions,
    onActionsClick,
    isSelectAllChecked,
    filters,
  ]);

  return <Table columns={tableColumns} {...rest} />;
};

const StyledTransactionBankAccountAutocomplete = styled(
  TransactionBankAccountAutocomplete,
)`
  width: 100%;
`;

export default PendingTransactionsTable;
