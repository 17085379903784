import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FormikProps } from 'formik';
import { IOnboardingAccount } from 'typings/onboarding/account';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { newTransactionRuleSchema } from 'validations/accountManagement/transactionRules';
import { FormValuesModel as TransactionRulesMatrixFormValuesModel } from 'components/Forms/TemplateForms/Approval/TransactionRulesMatrixForm';

// components
import Button from '@core_components/Button';
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface FormValuesModel extends TransactionRulesMatrixFormValuesModel {
  bankAccounts: string[];
  applicationId: string;
  initialBankAccounts?: AutocompleteOption<IOnboardingAccount>[];
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  onDelete?: (form: FormikProps<FormValuesModel>) => void;
}

const OnboardingTransactionRulesModal = ({
  title,
  isVisible,
  initialValues,
  onSubmit,
  closeCallback,
  onDelete,
}: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Form
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={newTransactionRuleSchema}
      renderForm={(form) => (
        <ModalDialog
          width="80%"
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          extendedFooterButtonsPosition="before"
          extendedFooterButtons={
            onDelete ? (
              <Button size="large" danger onClick={() => onDelete(form)}>
                {t('delete', { ns: 'common' })}
              </Button>
            ) : undefined
          }
        >
          <LoadingWrapper loading={!initialValues}>
            {initialValues ? <InnerForm /> : null}
          </LoadingWrapper>
        </ModalDialog>
      )}
    />
  );
};

export default OnboardingTransactionRulesModal;
