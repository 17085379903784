import React, { useContext, useMemo } from 'react';

// helpers
import { ActionKeys } from 'components/Forms/TemplateForms/Onboarding/Components/SubmitButtons';
import { ContactModel } from '../../../../../../../../../../typings/application/contact';
import { VerificationContext } from 'modules/Onboarding/Organization/Steps/ApplicationDocumentation';

// components
import IdentificationForIndividualShortForm, {
  FormValuesModel,
} from '../../../../../../IdentificationForIndividualShortForm';

interface IProps {
  contact: ContactModel;
  onAction?: (actionType: ActionKeys | null) => void;
}

const Identification = ({ contact, onAction }: IProps) => {
  const context = useContext(VerificationContext);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      submitActionType: null,
      isSave: false,
      isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
      pepInformation: contact.pepInformation || '',
    };
  }, [contact]);

  const handleSubmit = async (values: FormValuesModel) => {
    onAction && onAction(values.submitActionType);
  };

  return (
    <IdentificationForIndividualShortForm
      disabled
      showOnlySaveButton
      formRef={context?.refState}
      onSubmit={handleSubmit}
      initialFormValues={initialFormValues}
    />
  );
};

export default Identification;
