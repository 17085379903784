import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../../redux/reducers';
import { securityAPI } from '../../../../../../api/profile/securityAPI';
import { FormValuesModel } from '../../../../../../components/ModalDialogs/TemplateModalDialogs/Profile/EnableAuthenticatorModal';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from '../../../../../../enums/profile/auth';
import { StateModel as AuthStateModel } from '../../../../../../redux/reducers/auth';
import {
  setAuthenticatorEnabledStatus,
  setDefaultAuthenticator,
} from '../../../../../../redux/actions/auth';

// components
import ConfirmChangeModal from '../../../ConfirmChangeModal';
import EnableAuthenticatorModal from '../EnableAuthenticatorModal';
import { message } from 'antd';
import { default as TemplateManageAuthenticatorModal } from '../../../../../../components/ModalDialogs/TemplateModalDialogs/Profile/ManageAuthenticatorModal';

interface IProps {
  isVisible: boolean;
  closeCallback: () => void;
}

export enum AuthenticatorActionEnum {
  CHANGE_DEVICE = 1,
  DISABLE,
}

const ManageAuthenticatorModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('profile');

  const dispatch = useDispatch();
  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const [isConfirmActionModal, setIsConfirmActionModal] = useState(false);
  const [isEnableAuthenticatorModal, setisEnableAuthenticatorModal] =
    useState(false);
  const [selectedAction, setSelectedAction] =
    useState<AuthenticatorActionEnum | null>(null);

  const handleActionClick = (action: AuthenticatorActionEnum) => {
    setSelectedAction(action);
    setIsConfirmActionModal(true);
  };

  const handleConfirmChangeModalClose = (
    action: AuthenticatorActionEnum | null,
    isSuccess?: boolean,
  ) => {
    if (!action) {
      return;
    }

    if (isSuccess) {
      dispatch(setAuthenticatorEnabledStatus(false));
      if (
        profileData?.default2FAType ===
          TwoFactorVerificationTypes.Authenticator &&
        profileData.isTwoFactorFidoEnabled
      ) {
        dispatch(setDefaultAuthenticator(TwoFactorVerificationTypes.Fido));
      }

      if (selectedAction === AuthenticatorActionEnum.DISABLE) {
        message.success(
          t(
            'security.two_step_verification.authenticator.success_disable_message',
          ),
        );
      }
    }

    setIsConfirmActionModal(false);
    setSelectedAction(null);
    closeCallback();
  };

  const handleEnableAuthenticatorModalClose = (wasEnabled?: boolean) => {
    setisEnableAuthenticatorModal(false);

    if (wasEnabled) {
      closeCallback();
    }
  };

  const confirmCallback = async (otpCode: string) => {
    switch (selectedAction) {
      case AuthenticatorActionEnum.CHANGE_DEVICE:
        await securityAPI.disableAuthenticator(otpCode);
        setisEnableAuthenticatorModal(true);
        break;

      case AuthenticatorActionEnum.DISABLE:
        await securityAPI.disableAuthenticator(otpCode);
        break;
    }
  };

  const initialFormValues: FormValuesModel = useMemo(
    () => ({
      code: null,
    }),
    [],
  );

  return (
    <>
      <TemplateManageAuthenticatorModal
        isVisible={isVisible}
        closeCallback={closeCallback}
        handleActionClick={handleActionClick}
      />

      {!!selectedAction && (
        <ConfirmChangeModal
          isVisible={isConfirmActionModal}
          closeCallback={(isSuccess?: boolean) =>
            handleConfirmChangeModalClose(selectedAction, isSuccess)
          }
          callbackMethod={confirmCallback}
        />
      )}

      <EnableAuthenticatorModal
        initialFormValues={initialFormValues}
        isVisible={isEnableAuthenticatorModal}
        closeCallback={handleEnableAuthenticatorModalClose}
      />
    </>
  );
};

export default ManageAuthenticatorModal;
