import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { message } from 'antd';
import { RoutePaths } from 'routes/routes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { invitationAPI } from '../../../api/invitation/invitation';
import { setInvitationCode } from '../../../redux/actions/auth';

// components
import Text from '@core_components/Text';
import InvitationVerifyForm, {
  FormValuesModel,
} from '../../../components/Forms/TemplateForms/Invitation/InvitationVerifyForm';

const InvitationVerify = () => {
  const { t } = useTranslation('invitation');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      code: '',
      termsOfUse: null,
    };
  }, []);

  const handleSubmit = async (values: FormValuesModel) => {
    const { isValid } = await invitationAPI.verifyCode(values);

    if (!isValid) {
      message.error(t('invitation_code.wrong_code_message'));
    } else {
      dispatch(setInvitationCode(values.code));
      navigate(RoutePaths.StartOnboarding);
    }
  };

  return (
    <>
      <StyledTitle variant="h5">{t('invitation_code.title')}</StyledTitle>
      <InvitationVerifyForm
        onSubmit={handleSubmit}
        initialValues={initialFormValues}
      />
    </>
  );
};

const StyledTitle = styled(Text)`
  display: block;
  text-align: center;
`;

export default InvitationVerify;
