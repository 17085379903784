import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { DateHelpers } from 'helpers/date';
import { ContactModel } from '../../../../../../../../../typings/application/contact';
import { onboardingAPI } from '../../../../../../../../../api/onboarding/onboardingAPI';
import { OnboardingHelpers } from '../../../../../../../../../helpers/crm/onboarding';
import { onboardingAPIAdapter } from '../../../../../../../../../apiAdapters/onboarding/onboardingAPIAdapter';
import { OnboardingStatusModel } from '../../../../../../../../../typings/onboarding/onboarding';

// constants
import { SocialMediaTypes } from '../../../../../../../../../enums/onboarding/crm';

// components
import ApplicantInformationForm, {
  FormValuesModel,
} from '../../../../../../../../../components/Forms/TemplateForms/Onboarding/ApplicantInformationForm';

interface IProps {
  onboardingStatus: OnboardingStatusModel;
  isViewOnly: boolean;
  onSave: () => void;
}

const ApplicantInformation = ({
  onboardingStatus,
  isViewOnly,
  onSave,
}: IProps) => {
  const { t } = useTranslation('common');

  const initialFormValues = useMemo<FormValuesModel>(() => {
    const item = onboardingStatus.item as ContactModel;

    let emails = [];
    if (item.emails.length) {
      emails = item.emails.map((e) => ({
        type: e.type,
        value: e.address,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));
    } else {
      emails = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    let phoneNumbers = [];
    if (item.phoneNumbers.length) {
      phoneNumbers = item.phoneNumbers.map((e) => ({
        type: e.type,
        value: e.number,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
      }));
    } else {
      phoneNumbers = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
        },
      ];
    }

    return {
      isSave: false,
      submitActionType: null,
      firstName: item.firstName || '',
      middleName: item.middleName || '',
      lastName: item.lastName || '',
      dateOfBirth: item.dateOfBirth
        ? DateHelpers.formatDateToUTC(item.dateOfBirth)
        : null,
      countryOfBirth: item.countryOfBirth || '',
      gender: item.gender || null,
      emails,
      phoneNumbers,
      socialMedia: {
        twitterLink: item.socialMedia
          ? OnboardingHelpers.findSocialMediaByType(
              SocialMediaTypes.Twitter,
              item.socialMedia,
            )
          : '',
        facebookLink: item.socialMedia
          ? OnboardingHelpers.findSocialMediaByType(
              SocialMediaTypes.Facebook,
              item.socialMedia,
            )
          : '',
        linkedInLink: item.socialMedia
          ? OnboardingHelpers.findSocialMediaByType(
              SocialMediaTypes.LinkedIn,
              item.socialMedia,
            )
          : '',
      },
    };
  }, [onboardingStatus]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAPIAdapter.submitApplicantInformationIndividualStep(values);
    await onboardingAPI.submitApplicantInformationIndividualStep(
      formattedBody,
      onboardingStatus.applicationId,
      onboardingStatus.crmItemId,
    );
    message.success(t('success_save'));
    onSave();
  };

  return (
    <ApplicantInformationForm
      showOnlySaveButton
      disabled={isViewOnly}
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default ApplicantInformation;
