import React from 'react';

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

// components
import { Divider } from 'antd';

const DividerBetweenSections = () => {
  const breakpoint = useBreakpoint();

  return !breakpoint.xl ? (
    <DividerWrapper>
      <Divider />
    </DividerWrapper>
  ) : null;
};

const DividerWrapper = styled.div`
  width: 100%;
  padding: 0px 32px;
`;

export default DividerBetweenSections;
