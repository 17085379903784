import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { TransferTemplateStatuses } from 'enums/accounting/templates';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  info: [TransferTemplateStatuses.Archived],
  warning: [],
  success: [TransferTemplateStatuses.Active],
  error: [],
};

interface IProps {
  status: TransferTemplateStatuses;
}

const TransferTemplateStatus = ({ status }: IProps) => {
  const { t } = useTranslation();

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={TransferTemplateStatuses[status]}
      content={t(
        `transfer_templates.statuses.${TransferTemplateStatuses[status]}`,
      )}
    />
  );
};

export default TransferTemplateStatus;
