import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ActionKeys } from '../Components/LastStepSubmitButtons';
import { IApplication } from '../../../../../typings/application/applications';

// components
import ContentForm from './ContentForm';
import SectionIntro from '@common_components/Texts/SectionIntro';

interface IProps {
  application: IApplication;
  disabled?: boolean;
  canSendMessage?: boolean;
  onAction?: (key: ActionKeys | null) => void;
}

const ApplicationDocumentationWithRelationships = ({
  application,
  disabled,
  onAction,
  canSendMessage,
}: IProps) => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      <SectionIntro
        title={t('application_documentation.title')}
        description={t('application_documentation.description')}
      />
      <ContentForm
        application={application}
        isViewOnly={disabled}
        onAction={onAction}
        canSendMessage={canSendMessage}
      />
    </>
  );
};

export default ApplicationDocumentationWithRelationships;
