import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { IUserDevice } from '../typings/profile/security';
import { fingerprintConfig } from '../constants/fingerprinitng';
import { sha256, sortObjectKeys } from './utils';
import { ISessionInfoGeolocation } from '../api/profile/userAPI';

export type GeolocationGrantStatus = 'granted' | 'denied' | 'other-no-access';

const SessionHelpers = {
  generateFingerprint: async function (): Promise<string> {
    const FingerprintHelpers = await FingerprintJS.load();
    const { components } = await FingerprintHelpers.get();

    const fingerprintValues: { [key: string]: unknown } = {};
    const componentKeys = Object.keys(fingerprintConfig.components) as Array<
      keyof typeof components
    >;

    componentKeys.map((key) => {
      if (fingerprintConfig.components[key] && components[key]) {
        fingerprintValues[key] = components[key].value;
      }
    });

    const fingerprint = await sha256(
      JSON.stringify(sortObjectKeys(fingerprintValues)),
    );

    return fingerprint;
  },

  getUserGeolocation: async function (): Promise<
    | { status: GeolocationGrantStatus; data: ISessionInfoGeolocation | null }
    | undefined
  > {
    const status = await SessionHelpers.getGeolocationStatus();
    const nullGeolocation = { accuracy: 1000.0, latitude: 0.0, longitude: 0.0 };

    if (status !== 'denied') {
      return new Promise((resolve) => {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              status: 'granted',
              data: {
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude || undefined,
                altitudeAccuracy: position.coords.altitudeAccuracy || undefined,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            });
          },
          () => {
            resolve({ status: 'other-no-access', data: nullGeolocation });
          },
          { timeout: 10000 },
        );
      });
    } else {
      return { status, data: nullGeolocation };
    }
  },

  getDeviceName: function (device: IUserDevice): string {
    const clientVersion = device.client?.version
      ? `v.${device.client?.version}`
      : '';
    return `${device.os?.name || '-'} | ${device.client?.name || '-'} ${clientVersion}`;
  },

  getGeolocationStatus: async function (): Promise<GeolocationGrantStatus> {
    try {
      const permissionState = await window.navigator.permissions.query({
        name: 'geolocation',
      });
      if (permissionState.state === 'granted') {
        return 'granted';
      } else if (permissionState.state === 'denied') {
        return 'denied';
      } else {
        // Geolocation permission status is "prompt" or "default".
        // Means the user has not yet made a decision about the permission, or the permission hasn't been requested yet.
        return 'other-no-access';
      }
    } catch (e) {
      return 'other-no-access';
    }
  },
};

export { SessionHelpers };
