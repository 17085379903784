const Base64Helpers = {
  bufferToBinaryString: function (buf: BufferSource): string {
    const binstr = Array.prototype.map
      .call(buf, function (ch) {
        return String.fromCharCode(ch);
      })
      .join('');

    return binstr;
  },

  bufferToBase64: function (arr: BufferSource): string {
    const binstr = this.bufferToBinaryString(arr);
    return btoa(binstr);
  },

  binaryStringToBuffer: function (binstr: string): BufferSource {
    let buf: any;

    if ('undefined' !== typeof Uint8Array) {
      buf = new Uint8Array(binstr.length);
    } else {
      buf = [];
    }

    Array.prototype.forEach.call(binstr, function (ch, i) {
      buf[i] = ch.charCodeAt(0);
    });

    return buf;
  },

  base64ToBuffer: function (base64: string) {
    const binstr = atob(base64);
    const buf = this.binaryStringToBuffer(binstr);
    return buf;
  },
};

export { Base64Helpers };
