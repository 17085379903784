import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  IApprovalGroupShort,
  IGroupParticipantShort,
  INewTransactionApprovalGroup,
} from '../../typings/approvalManagement/accountManagement';

interface FetchApprovalGroupsParams extends FetchParamsModel {
  isActiveOnly?: boolean;
}

const approvalGroupsAPI = {
  // TODO: add pagination
  fetchApprovalGroups: (params: FetchApprovalGroupsParams) => {
    return APIService.get<FetchResponseModel<IApprovalGroupShort>>(
      `${APIConfig.approvalApi}/transaction/groups`,
      { params },
    ).then(({ data }) => data);
  },

  fetchApprovalGroupParticipants: () => {
    return APIService.get<FetchResponseModel<IGroupParticipantShort>>(
      `${APIConfig.approvalApi}/transaction/groups/participants`,
    ).then(({ data }) => data);
  },

  fetchApprovalGroupById: (groupId: string) => {
    return APIService.get<IApprovalGroupShort>(
      `${APIConfig.approvalApi}/transaction/groups/${groupId}`,
    ).then(({ data }) => data);
  },

  addTransactionApprovalGroup: (newGroup: INewTransactionApprovalGroup) => {
    return APIService.post(
      `${APIConfig.approvalApi}/transaction/groups`,
      newGroup,
    ).then(({ data }) => data);
  },

  updateApprovalGroup: (updatedGroup: IApprovalGroupShort) => {
    return APIService.patch(
      `${APIConfig.approvalApi}/transaction/groups/${updatedGroup.id}`,
      updatedGroup,
    ).then(({ data }) => data);
  },
};

export { approvalGroupsAPI };
