import APIConfig from '../../config/api';
import { IEntity } from '../../typings/application/entity';
import { APIService } from '../axiosInstance';
import { IClientGroup } from '../../typings/application/client-group';
import { ContactModel } from '../../typings/application/contact';

export interface IClientGroupProfileResponse {
  clientGroup: IClientGroup;
  directClient: ContactModel | IEntity;
  isStructureChanged: boolean;
}

interface DiscardClientProfileResponse {
  active: string;
  approved: string;
}

interface PhoneNumberValidationResponseModel {
  data: {
    phoneNumber: string;
    isValid: boolean;
  }[];
}

export type DashboardStepKeys = 'first_user_added' | 'first_account_created';

const clientGroupsAPI = {
  submitForReview: (clientGroupId = 'my') => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/submit`,
    );
  },

  fetchProfile: (clientGroupId: string) => {
    return APIService.get<IClientGroupProfileResponse>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/profile`,
    ).then(({ data }) => data);
  },

  discardClientProfileChanges: (clientGroupId: string) => {
    return APIService.post<{ data: DiscardClientProfileResponse }>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/rollback`,
    ).then<DiscardClientProfileResponse>(({ data }) => data.data);
  },

  fetchClientGroupDashboardStatuses: () => {
    return APIService.get<{
      map: { first_user_added: boolean; first_account_created: boolean };
    }>(
      `${APIConfig.crmApi}/client-groups/events?types=first_user_added,first_account_created`,
    ).then<{ first_user_added: boolean; first_account_created: boolean }>(
      ({ data }) => data.map,
    );
  },

  updateStepForDashboardStep: (type: DashboardStepKeys) => {
    return APIService.post(`${APIConfig.crmApi}/client-groups/events`, {
      type,
    }).then(({ data }) => data);
  },

  validatePhoneNumbers: (phoneNumbers: string[]) => {
    return APIService.post<PhoneNumberValidationResponseModel>(
      `${APIConfig.crmPublicApi}/phone/validate`,
      phoneNumbers,
    ).then(({ data }) => data);
  },
};

export { clientGroupsAPI };
