import { createGlobalStyle } from 'styled-components';

// TODO: use values from theme
export const GlobalStyle = createGlobalStyle<{ theme?: any }>`
  html {
    font-size: 14px;
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #c6cccf;
    }
 
    a:hover{
      filter: brightness(85%); /* Darken the color by 15% */
    }
  }

  body {
    background-color: ${({ theme }) => theme.colorDark};
    letter-spacing: .8px;
  }
`;
